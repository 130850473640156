export const fetchableAdditionalMaterialsQuery = `
query 
    {
      fetchableBenefitDocuments(
        coverageType: Dental
        language: En
      ) {
        documentId: id
        documentLanguage: language
        documentType: type
        coverageType: coverageType
        documentName: name
        documentUrl: url
      }
    }
`