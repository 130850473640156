/**
 * Action List
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Props as AvatarProps } from "elements/avatar"
import {Button, Checkbox, Avatar, Icon, IconTypes, ExternalLinkModal} from "elements"
import { LinkEvent } from "@/utils/analytics"

/**
 * Types
 * -----------------------------------------------------------------------------
 */

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
    readonly name: string
    readonly items: ReadonlyArray<ActionItem>
    readonly variant?: "default" | "checkbox"
    readonly className?: string
}

export interface ActionItem {
    // Base props
    readonly name: string
    readonly label: string
    readonly description?: string

    // Visual props
    readonly avatar?: AvatarProps
    readonly iconRight?: IconTypes
    readonly isActive?: boolean
    readonly isDisabled?: boolean
    readonly boldLabel?: boolean

    // Interactive props
    readonly onClick?: VoidFunction
    readonly href?: string
    readonly target?: string

    readonly node?: React.ReactNode

    // Analytics props
    readonly analytics?: Partial<LinkEvent>
    readonly leaveSitePopup?: boolean
}

/**
 * Context State
 * -----------------------------------------------------------------------------
 */
const Context = React.createContext<Props>(undefined)

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const ActionList: React.FC<Props> = props => {
    /**
     * Template
     */
    return (
        <Context.Provider value={props}>
            <div data-test={props.name} className={props.className || ""}>
                <ul className="list-none px-none mb-none">
                    {props.items.map((item, index) => (
                        <li
                            key={index}
                            className="border-b border-muted last:border-b-none"
                        >
                            <ActionItem {...item} />
                            {item.node && (
                                <React.Fragment>{item.node}</React.Fragment>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </Context.Provider>
    )
}

/**
 * Subcomponent: ActionItem
 * -----------------------------------------------------------------------------
 */
const ActionItem = (props: ActionItem) => {
    /**
     * Context
     */
    const { variant } = React.useContext(Context)
    const [modal, setModal] = React.useState(false)
    const toggleModal = () => setModal(!modal)

    /**
     * Template: Content
     */
    const Content = () => (
        <div className="flex space-x-xs items-center">
            {props.avatar && <Avatar size="small" {...props.avatar} />}
            <div className="flex-1 text-left">
                {props.boldLabel && (
                    <span
                        className={`font-bold ${
                            props.href
                                ? "text-primary dark:text-accent"
                                : "text-dark-300"
                        }`}
                    >
                        {props.label}
                    </span>
                )}
                {!props.boldLabel && (
                    <span
                        className={`font-semibold ${
                            props.href ? "text-primary dark:text-accent" : ""
                        }`}
                    >
                        {props.label}
                    </span>
                )}

                {props.description && (
                    <div className="text-muted">
                        <small>{props.description}</small>
                    </div>
                )}
            </div>

            {/* Default indicator */}
            {props.isActive && variant !== "checkbox" && (
                <Icon
                    type="check-circle"
                    className="text-primary-vivid dark:text-accent"
                />
            )}

            {/* Checkbox indicator */}
            {variant === "checkbox" && (
                <Checkbox
                    name={props.name + "checkbox"}
                    value={props.isActive}
                    setValue={() => false}
                    onClick={props.onClick}
                />
            )}

            {props.iconRight && (
                <Icon
                    type={props.iconRight}
                    size={20}
                    className="text-primary-vivid dark:text-accent"
                />
            )}
        </div>
    )

    /**
     * Template: w/Checkbox
     */
    if (variant === "checkbox") {
        return (
            <div className="py-xs px-xxs">
                <Content />
            </div>
        )
    }

    /**
     * Template: Button
     */
    return (
        <Button
            name={props.name}
            variant="none"
            className="block w-full py-xs px-xxs"
            onClick={props.leaveSitePopup? toggleModal : props.onClick}
            href={props.leaveSitePopup? "" : props.href}
            target={props.target}
            disabled={props.isDisabled}
            label={props.label}
            analytics={props.analytics}
        >
            <Content />
            {props.leaveSitePopup && (
                <ExternalLinkModal
                    name={
                        props.name + "-modal"
                    }
                    isVisible={modal}
                    href={props.href}
                    target={"_blank"}
                />
            )}
        </Button>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default ActionList
