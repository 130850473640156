/**
 * Hsa Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import { http } from "utils"
import { hsaQuery } from "./queries"

/**
 * Get HSA data
 * -----------------------------------------------------------------------------
 */
export const fetchHsa = createAsyncThunk(
    "hsa/fetchHsa", // Reducer name
    async (retries: number, { dispatch }) => {
        /**
        * Set loading state
        */
        const { hsa } = store.getState()
        dispatch(actions.receiveHsa({ ...hsa, isLoading: true }))
 
        /**
        * Fetch hsa (from ADIF user model)
        */
        const res = await http.query("/api/adif/user-model", hsaQuery)

        /**
          * Handle request errors
          */
        if (res.error) {
            dispatch(
                actions.receiveHsa({
                    ...hsa,
                    isLoading: false,
                    isInitialized: true,
                    errorMessage: "Could not retrieve hsa data",
                }),
            )
            return
        }

        /***
         * Handle retries
         * 
         * sometimes a success response returns empty data
         * and we end up missing the HSA card
         */
        const canRetry = retries > 0;
        if (res.status === 200 && res.data === "" && canRetry) {
            return dispatch(fetchHsa(retries - 1))
        }
 
        /**
         * Transform hsa data
         */
        const coverages = res.data?.data?.member?.coverages || [];
        const excludedProductIds = ["WMW32909", "WMW32910", "WMW32911", "WMW32912", "WMW32913", "WMW32914", "WMW32923", "WMW32924", "WMW32925", "WMW32926", "WMW32927", "WMW32928"]
        // only show cost overview hsa card if coverage check passes
        const showHSACard = coverages.some((c) => c.hsaProduct) 
                        && !excludedProductIds.includes(coverages[0].productId) // hide if productId matches list of excluded IDs
                        && res.data?.data?.member?.relationship === "SB"
                        && !!res.data?.data?.member?.preferences?.heqShare
        const items = {
            showHSACard,
            heqBalance: res.data?.data?.member?.heqBalance,
            heqShare: res.data?.data?.member?.preferences?.heqShare,
            hsaDisplayBalance: res.data?.data?.member?.preferences?.hsaDisplayBalance?.setting
        }

        /**
          * Update state
          */
        dispatch(
            actions.receiveHsa({
                ...hsa,
                ...items,
                isLoading: false,
                isInitialized: true,
            }),
        )
    },
)

/** 
 * Reset HSA
 * 
 * data may become stale if changes are made on account settings page
 */
export const resetHsaData = createAsyncThunk(
    "hsa/reset", // Reducer name
    async (params: void, { dispatch }) => {
        /**
          * Reset state
          */
        dispatch(actions.resetHsa())
    }
)

/**
 * Update Show Balance Popup
 */
export const updateShowBalancePopup = createAsyncThunk(
    "hsa/showBalancePopup", // Reducer name
    async (show: boolean, { dispatch }) => {
        /**
        * Set loading state
        */
        const { hsa } = store.getState()

        /**
          * Update state
          */
        dispatch(
            actions.receiveHsa({
                ...hsa,
                showBalancePopup: show
            }),
        )
    }
)

/**
 * Update Show Claims Popup
 */
export const updateShowClaimsPopup = createAsyncThunk(
    "hsa/showClaimsPopup", // Reducer name
    async (show: boolean, { dispatch }) => {
        /**
        * Set loading state
        */
        const { hsa } = store.getState()

        /**
          * Update state
          */
        dispatch(
            actions.receiveHsa({
                ...hsa,
                showClaimsPopup: show,
                showBalancePopup: false
            }),
        )
    }
)