import { ApolloClient, ApolloQueryResult } from "@apollo/client"
import gql from "@/utils/gql"
import { CacheStatus, Claim } from "./types"
import { IconTypes } from "elements"

/**
 * Helpers
 * -----------------------------------------------------------------------------
 */
export const ClaimStatuses = {
    C: "Completed",
    I: "In-process",
    P: "Pending",
    A: "Adjusted",
    S: "Submitted",
}

/**
 * Claim type helper for icons, labels, etc...
 * Ex: "M" = { label: "medical", icon: "stethoscope" }
 * -----------------------------------------------------------------------------
 */
export interface ClaimMeta {
    readonly label: string
    readonly icon: IconTypes
}
const ClaimTypeMeta: Record<Claim["claimType"], ClaimMeta> = {
    M: { label: "medical", icon: "stethoscope" },
    V: { label: "vision", icon: "glasses" },
    R: { label: "rx", icon: "rx" },
    D: { label: "dental", icon: "tooth" },
}
export const getClaimTypeMeta = (claimType?: Claim["claimType"]): ClaimMeta => {
    return ClaimTypeMeta[claimType]
}

/**
 * Filter duplicate providers
 */
export const filterDuplicateProviders = (items: ReadonlyArray<Claim>) => {
    return items
        .map(item => {
            return item.providerName !== null ? item.providerName : ""
        })
        .filter((value, index, array) => {
            return array.indexOf(value) === index && value !== ""
        })
}

/**
 *
 * @param apolloClient
 * @param query
 * @returns
 */
export const retryClaimsInquiry = async <TCacheShape, T>(
    apolloClient: ApolloClient<TCacheShape>,
    query: string,
): Promise<ApolloQueryResult<T>> => {
    return gql.query({
        apolloClient,
        queryString: query,
        queryVariables: {},
        retryConfig: {
            maxRetries: 3,
            retryDelay: 5000,
            retryCondition: <T>(response: ApolloQueryResult<T>) =>
                !!response?.error?.networkError ||
                response.data?.["claimsInquiry"]?.claims?.cacheStatus ===
                    CacheStatus.IN_PROGRESS,
        },
    })
}

/**
 *
 * @param apolloClient
 * @param query
 * @param claimNumbers
 * @returns
 */
export const retryClaimDetailsInquiry = async <TCacheShape, T>(
    apolloClient: ApolloClient<TCacheShape>,
    query: string,
    claimNumbers: readonly string[],
): Promise<ApolloQueryResult<T>> => {
    return gql.query({
        apolloClient,
        queryString: query,
        queryVariables: {
            claimNumbers: claimNumbers,
        },
        retryConfig: {
            maxRetries: 3,
            retryDelay: 5000,
            retryCondition: <T>(response: ApolloQueryResult<T>) =>
                !!response?.error?.networkError ||
                response.data?.["claimsInquiry"]?.claimLines?.cacheStatus ===
                    CacheStatus.IN_PROGRESS,
        },
    })
}
