import * as React from "react"
import { SVGProps } from "react"
const SvgMoneyCircle = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Dollar sign inside circle"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9 15.5617C11.2192 17.9658 15 16.7193 15 14.4042C15 12.6233 13.3562 12.0001 12.2877 12.0001H11.7123C10.6438 12.0001 9 11.3768 9 9.59593C9 7.19181 12.7808 6.03428 15 8.4384M12.2877 18.5V17.3425M12.2877 6.74658V5.5M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgMoneyCircle
