/**
 * Timeline Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import { rxQuantityQuery, treatmentByIdQuery } from "./queries"
import { combineStrengths, findSelectedStrength, humanReadableQuantityUnit, transformTreatmentApiData } from "./utils"
import http from "utils/http"
import { rxDrugStrengthQuery } from "../primis/drug-search/queries"
import { getTreatmentNameParts } from "@/components/rx/rx_utils"

/**
 * Get Treatment Item data
 * -----------------------------------------------------------------------------
 */
export const fetchTreatmentById = createAsyncThunk(
    "treatments/fetchTreatment", // Reducer name

    async (treatmentId: string, { dispatch }) => {
        /**
         * Set loading state
        */
        if (treatmentId?.length == 0) {
             return dispatch(actions.updateTreatments({ isItemLoading: false, isItemInitialized: true }))
        }
        dispatch(actions.updateTreatments({ isItemLoading: true }))

        /**
         * Request treatment data from digital-first-information-service
         */
        const res = await http.query(
            "/api/digital-first-information-service/graphql",
            treatmentByIdQuery(treatmentId),
        )

        // console.log("fetchTreatmentById res >>>  " + res.data)

        /**
         * Handle request errors
         */
        if (res.error) {
            console.error(res.error)
            return dispatch(
                actions.updateTreatments({
                    isItemLoading: false,
                    isItemInitialized: true,
                    errorMessage: "Could not retrieve treatment data",
                }),
            )
        }

        /**
         * Transform treatments data
         */
        const treatment = transformTreatmentApiData(res.data.data?.rxTreatment)

        /**
         * Add/Update the Item in the treatments array
         */
        const { treatments } = store.getState()

        const exists = treatments.items.find( item => item.id == treatmentId )
        const items = exists ?
            treatments.items.map( item => item.id === treatmentId ? treatment : item )
            :
            treatments.items.concat([treatment])

        /**
         * Update items state
         */
        dispatch(
            actions.updateTreatments({
                items: items,
                isItemLoading: false,
                isItemInitialized: true,
            }),
        )
    },
)

/**
 * Get Treatment Item data
 * -----------------------------------------------------------------------------
 */
export const fetchTreatmentAndStrength = createAsyncThunk(
    "treatments/fetchTreatmentAndStrength", // Reducer name

    async (treatmentId: string, { dispatch }) => {
        /**
         * Set loading state
        */
        dispatch(actions.updateTreatments({ isItemLoading: true }))

        /**
         * Request treatment data from digital-first-information-service
         */
        const res1 = await http.query(
            "/api/digital-first-information-service/graphql",
            treatmentByIdQuery(treatmentId),
        )

        // console.log("fetchTreatmentAndStrength res1 >>>  " + res1.data)

        /**
         * Handle request errors
         */
        if (res1.error) {
            console.error(res1.error)
            return dispatch(
                actions.updateTreatments({
                    isItemLoading: false,
                    isItemInitialized: true,
                    errorMessage: "Could not retrieve treatment data",
                }),
            )
        }

        /**
         * Transform treatments data
         */
        const treatment = transformTreatmentApiData(res1.data.data?.rxTreatment)

        /**
         * Add/Update the Item in the treatments array
         */
        const { treatments } = store.getState()

        // Get Strengths
        const [treatmentName] = getTreatmentNameParts(treatment?.primaryName)
        const query2 = rxDrugStrengthQuery(
            treatmentName
        )

        const res2 = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            query2,
        )

        // console.log("fetchTreatmentAndStrength res2 >>>  " + res2.data)

        /**
         * Handle request errors
         */
        if (res2.error) {
            console.error(res2.error)
            return dispatch(
                actions.updateTreatments({
                    isItemLoading: false,
                    isItemInitialized: true,
                    errorMessage: "Could not retrieve medication drug search data",
                }),
            )
        }

        const strengths =  res2.data.data?.rxDrugSearch
        const newTreatment = {...treatment, strengths: strengths}

        /**
         * Update items state
        */
        const exists = treatments.items.find( item => item.id == treatmentId )
        const items = exists ?
            treatments.items.map( item => item.id === treatmentId ? newTreatment : item )
            :
            treatments.items.concat([newTreatment])

        /**
         * Update items state
         */
        dispatch(
            actions.updateTreatments({
                items: items,
                isItemLoading: false,
                isItemInitialized: true,
            }),
        )
    },
)

export const fetchDrugStrengths = createAsyncThunk(
    "treatments", // Reducer name

    async (params: ReadonlyArray<string>, { dispatch }) => {
        const [treatmentName, treatmentId] = params

        /**
         * Set loading state
         */
        dispatch(actions.updateTreatments({ isItemLoading: true }))

        const query = rxDrugStrengthQuery(
            treatmentName
        )

        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            query,
        )

        // console.log("fetchDrugStrengths res >>>  " + res.data)

        /**
         * Handle request errors
         */
        if (res.error) {
            console.error(res.error)
            return dispatch(
                actions.updateTreatments({
                    isItemLoading: false,
                    isItemInitialized: true,
                    errorMessage: "Could not retrieve medication drug search data",
                }),
            )
        }

        const strengths =  res.data.data?.rxDrugSearch

        /**
         * Update items state
        */

        const { treatments } = store.getState()
        const treatment = treatments.items.find( item => item.id == treatmentId )
        const newTreatment = {...treatment, strengths: strengths}
        const items = treatments.items.map( item => item.id === newTreatment.id ? newTreatment : item )

        dispatch(
            actions.updateTreatments({
                items: items,
                isItemLoading: false,
                isItemInitialized: true,
            }),
        )
    },
)

//Commenting this method as no it's no longer being consumed
// export const fetchQuantity = createAsyncThunk(
//     "estimatedCosts", // Reducer name

//     async (params: ReadonlyArray<string>, { dispatch }) => {
//         const [ndc, treatment_id] = params

//         /**
//          * Set loading state
//          */
//         dispatch(actions.updateTreatments({ isItemLoading: true }))

//         const query = rxQuantityQuery(
//             ndc,
//         )

//         const res = await http.query(
//             "/api/janus/digital-first-information-service/graphql",
//             query,
//         )

//         // console.log("fetchQuantity res >>>  " + res.data)

//         /**
//          * Handle request errors
//          */
//         if (res.error) {
//             console.error(res.error)
//             return dispatch(
//                 actions.updateEstimatedCosts({
//                     isItemLoading: false,
//                     isItemInitialized: true,
//                     errorMessage: "Could not retrieve estimated costs data",
//                 }),
//             )
//         }

//         const { treatments } = store.getState()
//         const treatment = treatments.items.find(item => item.id === treatment_id)
//         const quantityDetails = res.data.data.rxDrugNdcSearch
//         const strengths = quantityDetails.map(rxDrugNdcSearch => {
//             const quantity = rxDrugNdcSearch.quantities.find(item => item.qtyCode === "usualmthlyqty")
//             const quantityUnit = humanReadableQuantityUnit(quantity?.qtyUnit)
//             const packaging = rxDrugNdcSearch.quantities.find(item => item.qtyCode === "packaging")
//             const packageQuantity = packaging?.qtyVal
//             const packageUnit = packaging?.qtyUnit
//             const dose = rxDrugNdcSearch.quantities.find(item => item.qtyCode === "usualdoseqty")
//             const amount = rxDrugNdcSearch.quantities.find(item => item.qtyCode === "amount")
//             const selectedStrength = findSelectedStrength(treatment, rxDrugNdcSearch)

//             const newStrength = {
//                 ...selectedStrength,
//                 quantity: quantity?.qtyVal ? parseFloat(quantity?.qtyVal).toString() : "",
//                 quantityUnit: quantityUnit || "",
//                 packageQuantity: packageQuantity,
//                 packageUnit: packageUnit,
//                 dose: dose?.qtyVal,
//                 doseUnit: dose?.qtyUnit,
//                 amount: amount?.qtyVal,
//                 amountUnit: amount?.qtyUnit,
//             }
//             return newStrength
//         })

//         const newStrengths = combineStrengths(treatment, strengths)
//         const newTreatment = {...treatment, strengths: newStrengths}
//         const items = treatments.items.map( item => item.id === treatment_id ? newTreatment : item)

//         /**
//          * Update items state
//          */
//         dispatch(
//             actions.updateTreatments({
//                 items: items,
//                 isItemLoading: false,
//                 isItemInitialized: true,
//             }),
//         )
//     },
// )
