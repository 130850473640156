/**
 * pager reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import {
    createStatefulSlice,
    SliceState,
} from "store/util/create-stateful-slice"
import { Service, WidgetConfig } from "@pgr/web-sdk-widget"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export type Telemedicine = "MDLIVE" | "DOCTORONDEMAND"

export type Language = "en-US"

export interface ApiKeys {
    readonly pager_env: string
    readonly android: string
    readonly ios: string
    readonly web: string
}

export interface Pager {
    readonly apiKeys: ApiKeys
    readonly language: Language
    readonly authCode: string
    readonly userAddress: WidgetConfig["userAddress"]
    readonly services: ReadonlyArray<Service>
    readonly initialized: boolean
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<Pager> = {
    status: "pristine",
    initialized: false,
    apiKeys: null,
    language: null,
    authCode: null,
    userAddress: null,
    services: [],
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const pager = createStatefulSlice({
    name: "pager",
    initialState,
    reducers: {
        receivePager: (
            state: SliceState<Pager>,
            action: PayloadAction<Pager>,
        ) => {
            return {
                ...state,
                status: "completed",
                ...action.payload,
            }
        },
        resetPager: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const pagerActions = { ...pager.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default pager.reducer
