/* eslint-disable no-console */
/**
 * Benefit Document Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import { http } from "utils"
import { benefitProductIdQuery, benefitDocumentsMDPSQuery, mpsBenefitProductIdQuery } from "./queries"
import { transformBenefitDocumentsApiData } from "./utils"
import Features from "@/utils/features"

/**
 * Get benefit documents data
 * -----------------------------------------------------------------------------
 */
export const fetchBenefitDocuments = createAsyncThunk(
    "documents/fetchBenefitDocuments", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.benefitDocumentsInfoStatus("pending"))

        const { app } = store.getState()
        const { [Features.MPS_BENEFIT_PRODUCTID_QUERY_ENABLED]: mpsBenefitProductIdQueryEnabled } = app.features

        /**
         * Fetch Regence user data
         */
        // eslint-disable-next-line functional/no-let
        let benefits = []
        const mdpsRes = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            benefitDocumentsMDPSQuery,
        )

        if ( mpsBenefitProductIdQueryEnabled ) {
            const mpsRes = await http.query(
                "/api/janus/digital-first-information-service/graphql",
                mpsBenefitProductIdQuery,
            )

            const error = mdpsRes.error || mdpsRes.data?.errors || mpsRes.error || mpsRes.data?.errors;
            if (error) {
                console.error(error)
                dispatch(
                    actions.benefitDocumentsInfoError({ response: mdpsRes || mpsRes }),
                )
                return
            }
            // Finding subscriber in the memberships
            const subscriber = mpsRes.data?.data?.primaryPolicy?.memberships.find(membership => membership.primaryCoverage.suffix === "0")

            benefits = subscriber?.primaryCoverage?.primaryEligibility.map(eligibility => {
                return {
                    productId: eligibility.productId,
                    category: eligibility.category
                }
            }) || []
        } else {
            const userRes = await http.query(
                "/api/adif/user-model",
                benefitProductIdQuery,
            )

            const error = mdpsRes.error || mdpsRes.data?.errors || userRes.error || userRes.data?.errors;
            if (error) {
                console.error(error)
                dispatch(
                    actions.benefitDocumentsInfoError({ response: mdpsRes || userRes }),
                )
                return
            }

            benefits = userRes.data?.data?.enrollment?.family?.subscriber?.benefits.map(benefit => {
                return {
                    productId: benefit.productId,
                    category: benefit.coverageCategory
                }
            }) || []
        }
        /**
         * Transform Regence data
         */
        const benefitDocumentData = mdpsRes.data?.data || {};

        const {
            benefitDocuments,
            productId
        } = transformBenefitDocumentsApiData(benefitDocumentData, benefits);

        /**
         * Update state
         */
        dispatch(
            actions.benefitDocumentsInfo({
                benefitDocuments,
                productId
            }),
        )
    },
)
