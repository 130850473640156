/* Retrieves medications by treatment identifier (aka Pharmacy rx API treatments)
    for http://dfw/research/medications/{id}
    */

export const treatmentByIdQuery = function (treatmentId: string) {
    return `
        query rxTreatmentById{
            rxTreatment(id: ${treatmentId}) {
              id
              primaryName
              secondaryName
              cost
              costFrequency
              costDescription
              commonSideEffectList
              severeSideEffectList
              deliveryMethodName
              treatmentCategoryName
              treatmentCategoryDesc
              referenceNdc
              rxConditionTreatments {
                edges {
                  node {
                    id
                    grade
                    clinicalSummary
                    effectiveness
                    safety
                    rxCondition {
                      id
                      name
                      fullName
                      rxConditionTreatments(first: 2){
                        edges{
                          node{
                            id
                            grade
                            rxTreatment{
                              id
                              primaryName
                              secondaryName
                              cost
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
          }
     }`
}

export const rxQuantityQuery = (
  ndc?: string,
) => {
  return `
  query ndcSearch {
    rxDrugNdcSearch(ndc: "${ndc}") {
      ndc
      name
      doseForm
      quantities {
        qtyCode
        qtyVal
        qtyUnit
      }
    }
  }`
}
