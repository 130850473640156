/**
 * Account reducer
 * -----------------------------------------------------------------------------
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { Profile, Plan, EmailActivation, ActivationType } from "./types"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Account {
    /**
     * Init states
     */
    readonly isInitialized: boolean
    readonly isLoading: boolean
    readonly isAuthenticated: boolean
    readonly errorMessage?: string

    /**
     * Account props
     */
    readonly profile: Profile
    readonly family: {
        readonly selectedMembershipId?: string
        readonly requesterMembershipId?: string

    }
    readonly plan: Plan
    readonly emailActivation: EmailActivation
}

interface Email {
    readonly email: string
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: Account = {
    isInitialized: false,
    isLoading: false,
    isAuthenticated: false,
    errorMessage: undefined,
    profile: {
        id: "",
        firstName: "",
        lastName: "",
        relationship: "",
        email: "",
        subscriberId: "",
        groupId: "",
        username: "",
        audience: "",
        region: "",
        emailVerificationStatus: "",
        smsVerificationStatus: ""
    },
    family: { },
    plan: { memberId: "", groupNumber: "", suffix: "", brandCode: "", usableDentalPlanName: ""},
    emailActivation: { status: ActivationType.Empty }
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const account = createSlice({
    name: "account",
    initialState,
    reducers: {
        receiveAccount: (state: Account, action: PayloadAction<Account>) => {
            return action.payload
        },
        resetAccount: () => {
            return initialState
        },
        receiveEmailActivationStatus: (state: Account, action: PayloadAction<Account>) => {
            return {
                ...state,
                emailActivation: { status: action.payload.emailActivation.status}
            }
        },
        updateProfileEmail: (state: Account, action: PayloadAction<Email>) => {
            return {
                ...state,
                profile: {...state.profile, email:  action.payload.email }
            }
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const accountActions = { ...account.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default account.reducer
