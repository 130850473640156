import * as React from "react"
import { SVGProps } from "react"
const SvgClockAlarm = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Alarm clock"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M16.1 18.9L18.1 22.9M8.09998 18.9L6.09998 22.9M12.1 7.89999V11.4858C12.1 11.751 12.2053 12.0054 12.3929 12.1929L14.1 13.9M2.39998 8.59999C1.59998 7.79999 1.09998 6.69999 1.09998 5.39999C1.09998 2.89999 3.09998 0.899994 5.59998 0.899994C6.79998 0.899994 7.99998 1.39999 8.79998 2.19999M15.4 2.29999C16.2 1.49999 17.3 0.999994 18.6 0.999994C21.1 0.999994 23.1 2.99999 23.1 5.49999C23.1 6.69999 22.6 7.89999 21.8 8.69999M20.1 11.9C20.1 16.3183 16.5183 19.9 12.1 19.9C7.6817 19.9 4.09998 16.3183 4.09998 11.9C4.09998 7.48172 7.6817 3.89999 12.1 3.89999C16.5183 3.89999 20.1 7.48172 20.1 11.9Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgClockAlarm
