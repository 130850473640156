/**
 * Renewal reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import {
    createStatefulSlice,
    SliceState,
} from "store/util/create-stateful-slice"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Renewal {
    readonly step: RenewalSteps
    readonly submitted: "pending" | "submitted" | "canceled" | "error"
    readonly address: {
        readonly addressChanged?: string
        readonly street: string
        readonly city: string
        readonly state: string
        readonly zip: string
        readonly county: string
    }
    readonly applicants: ReadonlyArray<Applicant>
    readonly review: Review
    readonly currentPlan: CurrentPlan
    readonly fastTrackPlan?: FastTrackResponse
    readonly renewalInfo: RenewalInfo
    readonly region: string
    readonly subregion: string
}

export type RenewalSteps = "address" | "applicants" | "plan" | "pcp" | "review"

export interface PrimaryCareProvider {
    readonly providerName: string
    readonly medicalGroupName: string
    readonly address: Address
}

export interface Applicant {
    readonly familyMemberId: string
    readonly relationship: "SB" | "SP" | "DP" | "DE"
    readonly gender: "male" | "female" | "nonbinary"
    readonly dateOfBirth: string
    readonly age: number
    readonly firstName?: string
    readonly tobaccoUse: string
    readonly isRemoved: boolean
    readonly isEligibleForMedicareByAge: boolean
    readonly hasPrimaryCareProvider?: string // "yes" | "no"
    readonly primaryCareProvider?: PrimaryCareProvider
}

export interface Address {
    readonly street: string
    readonly city: string
    readonly state: string
    readonly zip: string
}

export interface Product {
    readonly id: string
    readonly name?: string
}

export interface RenewalInfo {
    readonly isIndividualMember: boolean
    readonly isDiscontinuedProduct: boolean
    readonly isDiscontinuedCounty: boolean
    readonly renewalInteractionState:
        | "SUBMITTED"
        | "NOT_SUBMITTED"
        | "CANCELLED"
    readonly renewalWindow:
        | "PREENROLLMENT"
        | "ENROLLMENT"
        | "OUTSIDE_ENROLLMENT_PERIOD"
    readonly renewedProduct?: Product
    readonly shopMarketPlaceURL: string
}

export interface CurrentPlan {
    readonly openEnrollmentStartDate: string
    readonly openEnrollmentEndDate: string
    readonly coverageEndDate: string
    readonly currentYear: string
    readonly nextYear: string
    readonly product: Product
    readonly brand: {
        readonly code: "BCBSO" | "ASUR" | "BCBSU" | "BSW" | "BSI" | "BSH"
        readonly family: "BLU" | "ASUR" | "BSH"
    }
}

export interface ThirdPartyPayer {
    readonly isSelected?: boolean
    readonly name?: string
    readonly payerType?:
        | "PARENT_GUARDIAN_RELATIVE"
        | "ICHRA"
        | "QSEHRA"
        | "EMPLOYER"
        | "OTHER"
}

export interface SelfEmployment {
    readonly isSelected?: boolean
    readonly businessName?: string
}

export interface PaymentInfo {
    readonly billingAddress: Address
    readonly thirdPartyPayer?: ThirdPartyPayer
    readonly selfEmployment?: SelfEmployment
}

export interface CommunicationPreferences {
    readonly phoneNumber: string
    readonly email?: string
    readonly address: Address
}

export interface HsaPreference {
    readonly isSelected: boolean
    readonly ssn?: string
}

export interface Review {
    readonly paymentInfo: PaymentInfo
    readonly communicationPreferences: CommunicationPreferences
    readonly hsaPreference: HsaPreference
}

/**
 * Form data to POST to FastTrack
 * https://www.shop.regence.com/plan.action
 */
export interface FastTrackForm {
    readonly brand?: "REGENCE" | "ASURIS"
    readonly childOnlyApp?: boolean
    readonly county: string
    readonly effectiveDate?: string // YYYY-MM-DD
    readonly today?: string // YYYY-MM-DDT12:00:00.000
    readonly productId?: string
    readonly year?: string // YYYY
    readonly zipCode: string
    readonly applicants: ReadonlyArray<{
        readonly age: number
        readonly birthDate: string // YYYY-MM-DD
        readonly gender: "male" | "female" | "nonbinary"
        readonly relationship: "SB" | "SP" | "DP" | "DE"
        readonly tobaccoUse: string // "true" | "false"
        readonly type?: "applicants"
    }>
}

export interface FastTrackResponse {
    readonly cancel?: "true"
    readonly useReenrollingPlan?: "true" | "false"
    readonly productId?: string
    readonly planName?: string
    readonly networkName?: string
    readonly serviceArea?: string
    readonly county?: string
}

/**
 * Renewal review and e-sign data to POST to MRS
 * MRS mutation: renewIndividualPlan
 */
export interface ReviewRenewal {
    readonly productId: string
    readonly brandCode: "BCBSO" | "ASUR" | "BCBSU" | "BSW" | "BSI" | "BSH"
    readonly brandFamily: "BLU" | "ASUR" | "BSH"
    readonly residentialAddress: Address
    readonly billingAddress: Address
    readonly thirdPartyPayer?: ThirdPartyPayer
    readonly selfEmployment?: SelfEmployment
    readonly hsaPreference: HsaPreference
    readonly communicationPreferences: CommunicationPreferences
    readonly serviceArea: string
    readonly county: string
    readonly planMembers: ReadonlyArray<{
        readonly remove: boolean
        readonly familyMemberId: string
        readonly isTobaccoUser: boolean
        readonly primaryCareProvider: PrimaryCareProvider
    }>
}

/**
 * Renewal confirmation data to POST to MRS
 * MRS mutation: renewIndividualPlan
 */
export interface ConfirmRenewal {
    readonly productId: string
    readonly serviceArea: string
    readonly county: string
    readonly hasChangedAddress: boolean
    readonly residentialAddress: Address
    readonly planMembers: ReadonlyArray<{
        readonly remove: boolean
        readonly familyMemberId: string
        readonly isTobaccoUser: boolean
    }>
}

/**
 * Renewal cancel data to POST to MRS
 * MRS mutation: cancelIndividualRenewal
 */
export interface CancelRenewal {
    readonly serviceArea: string
    readonly county: string
    readonly residentialAddress: Address
}

export interface RenewalFaq {
    readonly label: string
    readonly content: string
    readonly contentOregon: string
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<Renewal> = {
    status: "pristine",
    step: "address",
    submitted: "pending",
    address: {
        addressChanged: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        county: "",
    },
    applicants: [],
    review: {
        paymentInfo: {
            thirdPartyPayer: {
                isSelected: false,
                name: "",
                payerType: null,
            },
            selfEmployment: {
                isSelected: false,
                businessName: "",
            },
            billingAddress: {
                street: "",
                city: "",
                state: "",
                zip: "",
            },
        },
        communicationPreferences: {
            phoneNumber: "",
            address: {
                street: "",
                city: "",
                state: "",
                zip: "",
            },
        },
        hsaPreference: {
            isSelected: false,
        },
    },
    currentPlan: {
        openEnrollmentStartDate: "",
        openEnrollmentEndDate: "",
        coverageEndDate: "",
        currentYear: "",
        nextYear: "",
        product: {
            id: "",
        },
        brand: {
            code: null,
            family: null,
        },
    },
    renewalInfo: {
        isIndividualMember: false,
        isDiscontinuedProduct: false,
        isDiscontinuedCounty: false,
        renewalInteractionState: null,
        renewalWindow: "OUTSIDE_ENROLLMENT_PERIOD",
        renewedProduct: null,
        shopMarketPlaceURL: "",
    },
    region: "",
    subregion: "",
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const renewal = createStatefulSlice({
    name: "renewal",
    initialState,
    reducers: {
        receiveRenewal: (
            state: SliceState<Renewal>,
            action: PayloadAction<Renewal>,
        ) => {
            return { status: "completed", ...action.payload }
        },
        receiveRenewalStep: (
            state: SliceState<Renewal>,
            action: PayloadAction<RenewalSteps>,
        ) => {
            return { ...state, step: action.payload }
        },
        resetRenewal: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const renewalActions = { ...renewal.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default renewal.reducer
