import * as React from "react"
import { SVGProps } from "react"
const SvgRx = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Rx" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M19.4 20.5002L8.30002 9.40017H4.90002H9.30002C9.30002 9.40017 12.1 9.40017 12.1 6.60017C12.1 3.80017 9.40002 3.80017 9.40002 3.80017H5.15002C5.01195 3.80017 4.90002 3.9121 4.90002 4.05017V14.9002M10.5 20.5002L19.4 11.6002"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgRx
