/**
 * Onlife reducer
 * -----------------------------------------------------------------------------
 */
import { createSlice } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { updateWellness } from "../account-settings/actions"
import { OnlifeIncentives, OnlifeJourney } from "./types"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Onlife {
    // Base props
    readonly isOnlifeUserModelDataLoading: boolean
    readonly isMessageCountFailure: boolean
    readonly isJourneyFailure: boolean
    readonly isIncentivesFailure: boolean
    readonly errorMessage?: string

    // Onlife props
    readonly isMedicare: boolean
    readonly isAuthorizationRequired: boolean
    readonly messageCount: number
    readonly journey: OnlifeJourney
    readonly incentives: OnlifeIncentives

    // Authorization modal props
    readonly isWellnessAuthorizationContentLoading: boolean
    readonly wellnessAuthorizationContent: string
    readonly isAuthorizationPreferenceSaving: boolean
    readonly isAttemptingSSO: boolean
    readonly isShowAuthorizationModal: boolean
    readonly isAuthorizationModalDismissedThisSession: boolean
    readonly isSSOOnAuthorizationAgreement: boolean
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: Onlife = {
    isOnlifeUserModelDataLoading: false,
    isMessageCountFailure: false,
    isJourneyFailure: false,
    isIncentivesFailure: false,
    errorMessage: undefined,
    isMedicare: false,
    isAuthorizationRequired: false,
    journey: {
        "incentiveSymbol": "",
        "incentiveType": 0,
        "incentiveValue": 0,
        "isIncentiveAssociated": false,
        "journeyCardName": "",
    },
    incentives: {
        "potentialIncentives": 0,
        "earnedIncentives": 0,
    },
    messageCount: 0,
    isWellnessAuthorizationContentLoading: false,
    wellnessAuthorizationContent: "",
    isAuthorizationPreferenceSaving: false,
    isAttemptingSSO: false,
    isShowAuthorizationModal: false,
    isAuthorizationModalDismissedThisSession: false,
    isSSOOnAuthorizationAgreement: false,
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const onlife = createSlice({
    name: "onlife",
    initialState,
    reducers: {
        ssoButtonClicked: (state: Onlife) => {
            if (state.isAuthorizationRequired && !state.isAuthorizationModalDismissedThisSession) {
                return {
                    ...state,
                    isShowAuthorizationModal: true,
                    isAttemptingSSO: true,
                }
            } else {
                window.open("/sso/onlife/default", "_blank")
                return state
            }
        },
        dismissOnlifeAuthorizationModal: (state: Onlife) => {
            if (state.isAttemptingSSO) {
                window.open("/sso/onlife/default", "_blank")
            }

            return {
                ...state,
                isShowAuthorizationModal: false,
                isAuthorizationModalDismissedThisSession: true,
                isAttemptingSSO: false,
            }
        },
        resetOnlife: () => {
            return initialState
        },
    },
    extraReducers: (builder) => {
        builder
            /**
             * Onlife user-model fetch
             * -----------------------------------------------------------------------------
             */
            .addCase(actions.fetchOnlifeUserModelData.pending, (state: Onlife) => {
                return {
                    ...state,
                    isOnlifeUserModelDataLoading: true,
                }
            })
            .addCase(actions.fetchOnlifeUserModelData.fulfilled, (state: Onlife, { payload }) => {
                return {
                    ...state,
                    isMessageCountFailure: payload.isMessageCountFailure,
                    isJourneyFailure: payload.isJourneyFailure,
                    isIncentivesFailure: payload.isIncentivesFailure,
                    isMedicare: payload.isMedicare,
                    isAuthorizationRequired: payload.isAuthorizationRequired,
                    journey: payload.journey,
                    incentives: payload.incentives,
                    messageCount: payload.messageCount,
                    isOnlifeUserModelDataLoading: false,
                }
            })
            .addCase(actions.fetchOnlifeUserModelData.rejected, (state: Onlife, action) => {
                return {
                    ...state,
                    isOnlifeUserModelDataLoading: false,
                    errorMessage: action.payload || action.error.message
                }
            })
            /**
             * Wellness authorization content fetch
             * -----------------------------------------------------------------------------
             */
            .addCase(actions.fetchWellnessAuthorizationContent.pending, (state: Onlife) => {
                return {
                    ...state,
                    isWellnessAuthorizationContentLoading: true,
                }
            })
            .addCase(actions.fetchWellnessAuthorizationContent.fulfilled, (state: Onlife, { payload }) => {
                return {
                    ...state,
                    isWellnessAuthorizationContentLoading: false,
                    wellnessAuthorizationContent: payload,
                }
            })
            .addCase(actions.fetchWellnessAuthorizationContent.rejected, (state: Onlife, action) => {
                return {
                    ...state,
                    isWellnessAuthorizationContentLoading: false,
                    errorMessage: action.payload || action.error.message // TODO: Display some error
                }
            })
            /**
             * Onlife preference save
             * -----------------------------------------------------------------------------
             */
            .addCase(updateWellness.pending, (state: Onlife) => {
                return {
                    ...state,
                    isAuthorizationPreferenceSaving: true
                }
            })
            .addCase(updateWellness.fulfilled, (state: Onlife) => {
                if (state.isAttemptingSSO) {
                    window.open("/sso/onlife/default", "_blank")
                }

                return {
                    ...state,
                    isShowAuthorizationModal: false,
                    isAttemptingSSO: false,
                    isAuthorizationPreferenceSaving: false,
                    isAuthorizationRequired: false,
                }
            })
            .addCase(updateWellness.rejected, (state: Onlife) => {
                // TODO: Make auth save failures visible to the user
                return {
                    ...state,
                    isAuthorizationPreferenceSaving: false,
                    errorMessage: "Could not update wellness authorization preference"
                }
            })
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const onlifeActions = { ...onlife.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default onlife.reducer
