/* eslint-disable functional/immutable-data */
/**
 * Markdown
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { marked } from "marked"
import DOMPurify from "dompurify"
import { Link } from "elements"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props extends React.HTMLAttributes<HTMLDivElement> {
    readonly content: string
    readonly config?: Record<string, ReadonlyArray<string>>
    readonly renderer?: marked.Renderer
    readonly linkNameString?: string
    /**
     * if there are externl links in styx, we set this flag so that the link
     * can go through the external warning modal.
     */
    readonly isStyxContent?: boolean
}
interface ContainerProps {
    readonly children: string
    readonly tag: string
    readonly args?: Record<string, string>
}

// This is a container for non-link elements from the markdown that either contain
// or are siblings to a link element.
const Container: React.FC<ContainerProps> = ({ children, tag, args = [] }) => {
    switch (tag) {
        case "p":
            return (
                <p {...args} dangerouslySetInnerHTML={{ __html: children }} />
            )
        case "strong":
            return (
                <strong
                    {...args}
                    dangerouslySetInnerHTML={{ __html: children }}
                />
            )
        case "ul":
            return (
                <ul {...args} dangerouslySetInnerHTML={{ __html: children }} />
            )
        case "li":
            return (
                <li {...args} dangerouslySetInnerHTML={{ __html: children }} />
            )
        case "h1":
            return (
                <h1 {...args} dangerouslySetInnerHTML={{ __html: children }} />
            )
        case "h2":
            return (
                <h2 {...args} dangerouslySetInnerHTML={{ __html: children }} />
            )
        case "h3":
            return (
                <h3 {...args} dangerouslySetInnerHTML={{ __html: children }} />
            )
        case "h4":
            return (
                <h4 {...args} dangerouslySetInnerHTML={{ __html: children }} />
            )
        case "table":
            return (
                <table
                    {...args}
                    dangerouslySetInnerHTML={{ __html: children }}
                />
            )
        case "br":
            return <br />

        default:
            return (
                <div {...args} dangerouslySetInnerHTML={{ __html: children }} />
            )
    }
}

/**
 * Component
 * -----------------------------------------------------------------------------
 * Takes a string of markdown content to process and returns the rendered markdown as HTML
 * If the content string generates a link, the link is replaced with our Link component
 * so that external links are detected and the modal to inform member that they are
 * leaving our site is triggered (and analytics are applied).
 *
 * props:
 * linkNameString (optional): a string used to give links a name for analytics
 */
const Markdown: React.FC<Props> = props => {
    /**
     * Define template variables
     */
    const trimmed = props?.content.trim() || ""
    const parsed = marked.parse(trimmed, {
        gfm: true,
        renderer: props.renderer,
    })
    const sanitized = DOMPurify.sanitize(parsed, props.config || undefined)
    const replaced =
        sanitized.indexOf("&nbsp;") > -1
            ? sanitized.replaceAll("&nbsp;", " ")
            : sanitized

    // if the content is from styx including external links, then we are not doing strict sanitization
    const DOMPurifyConfig = props.isStyxContent
        ? sanitized
        : DOMPurify.sanitize(replaced, {
              ALLOWED_TAGS: ["&amp;"],
          })
    const parser = new DOMParser()
    const document = parser.parseFromString(DOMPurifyConfig, "text/html")
    const linksinbody = document.body.getElementsByTagName("a")
    const bodychildren = document.body.children
    const bodyParentTag = "div"
    const childElementArray = []
    if (linksinbody?.length) {
        // there at least one link in the sanitized HTML, we're going to find them and replace them with a <Link> component
        // iterate over the children
        // eslint-disable-next-line functional/no-let
        for (let i = 0; i < bodychildren.length; i++) {
            // identify any links that are children of this top-level child
            const mylinks = bodychildren[i].getElementsByTagName("a")
            // first handle if there are any body children that are a link
            if (bodychildren[i].tagName === "A") {
                // Safari on ios will make top-level links out of phone numbers so render those as is
                if (bodychildren[i].getAttribute("href").startsWith("tel:")) {
                    return (
                        <div
                            className={props.className || ""}
                            dangerouslySetInnerHTML={{ __html: sanitized }}
                        />
                    )
                }
                return convertHTMLLinkToReactLink(
                    bodychildren[i].outerHTML,
                    props.linkNameString,
                )
            }
            // if there are links in this top-level child, split it up and replace the link,
            else if (mylinks.length) {
                childElementArray.push(
                    replaceLinks(
                        bodychildren[i],
                        props.linkNameString,
                        props.isStyxContent,
                    ),
                )
            } else {
                // this bodychild has no link so we can just render it with no changes.
                const argNames = bodychildren[i].getAttributeNames()
                const args = {}
                argNames.forEach(
                    argName =>
                        (args[argName] = bodychildren[i].getAttribute(argName)),
                )
                childElementArray.push(
                    <Container
                        children={bodychildren[i].innerHTML}
                        tag={bodychildren[i].tagName.toLowerCase()}
                        args={args}
                    />,
                )
            }
        }
        // all the children have been processed and pushed onto childElementArray.
        // Now create the top element with the children to be rendered
        const parentElementElement = React.createElement(
            bodyParentTag,
            { className: props.className || "" },
            childElementArray,
        )
        /**
         * Template
         */
        return <React.Fragment>{parentElementElement}</React.Fragment>
    } else {
        // no links in the markdown string
        return (
            <div
                className={props.className || ""}
                dangerouslySetInnerHTML={{ __html: sanitized }}
            />
        )
    }
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Markdown

function createElementWrappingLink(
    mycollection: HTMLCollection,
    i: number,
    linkNameString: string,
) {
    const wrappingelement = mycollection[i].tagName
    const childArray = []

    if (mycollection[i].childElementCount > 1) {
        // there is more than one child so after stripping the wrapping element tags,  use each child string to split and add part 1, then the child to the childArray
        // then do it again on part 2 (split on child, add part 1 and then the child and again if there are more children)
        // make a function that takes the string (with no wrapping html tags), and the child and returns an array of elements and the remaining string (part2)
        // eslint-disable-next-line functional/no-let
        for (let l = 0; l < mycollection[i].childElementCount; l++) {
            const thischild = mycollection[i].children.item(l)
            if (thischild.tagName === "A") {
                // eslint-disable-next-line functional/immutable-data
                childArray.push(
                    convertHTMLLinkToReactLink(
                        thischild.outerHTML,
                        linkNameString,
                    ),
                )
            } else {
                const argNames = thischild.getAttributeNames()
                const args = {}
                argNames.forEach(
                    argName =>
                        (args[argName] = thischild.getAttribute(argName)),
                )
                childArray.push(
                    <Container
                        children={thischild.innerHTML}
                        tag={thischild.tagName.toLowerCase()}
                        args={args}
                    />,
                )
            }
        }
    } else {
        const thischild = mycollection[i].children.item(0)
        const splitparts = mycollection[i].innerHTML.split(thischild.outerHTML)
        splitparts.forEach((part, index) => {
            childArray.push(part)
            if (index < splitparts.length - 1) {
                if (thischild.tagName === "A") {
                    childArray.push(
                        convertHTMLLinkToReactLink(
                            thischild.outerHTML,
                            linkNameString,
                        ),
                    )
                } else {
                    const argNames = thischild.getAttributeNames()
                    const args = {}
                    argNames.forEach(
                        argName =>
                            (args[argName] = thischild.getAttribute(argName)),
                    )
                    childArray.push(
                        <Container
                            children={thischild.innerHTML}
                            tag={thischild.tagName.toLowerCase()}
                            args={args}
                        />,
                    )
                }
            }
        })
    }
    return React.createElement(wrappingelement.toLowerCase(), {}, childArray)
}

// Takes an HTML link string and returns a Link element with analytics and modal if an external link.
function convertHTMLLinkToReactLink(
    htmlLink: string,
    linkNameString: string,
    /**
     * helper flag to allow sso links as part of external link redirection through warning modal
     */
    isStyxContent = false, // setting the default value of flag to false to avoid any code conflicts
) {
    const newparser = new DOMParser()
    const parsedLink = newparser.parseFromString(htmlLink, "text/html")
    const linkElement = parsedLink.getElementsByTagName("a").item(0)
    // if isStyxContent is true, href is returned without parsing the link
    const href = isStyxContent
        ? linkElement.href
        : linkElement.href.replace(
              /https:\/\/account(-dev|-uat)?.regence.com|https:\/\/account(-dev|-uat)?.asuris.com|https:\/\/account(-dev|-uat)?.bridgespanhealth.com|http:\/\/localhost:3000/,
              "",
          )
    const hrefasurl = new URL(linkElement.href)
    const isExternalLink = href.startsWith("/")
        ? false
        : !/^www\.regence\.com|www\.asuris\.com|www\.bridgespanhealth\.com/.test(
              hrefasurl.hostname,
          )
    const analyticsLinkText = linkElement.textContent
        .replaceAll(/[\s]/g, "")
        .toLowerCase()
    const linkName = linkNameString
        ? `${linkNameString?.concat(analyticsLinkText)}-link`
        : `${analyticsLinkText}-link`
    return (
        <Link
            name={linkName}
            href={href}
            target={linkElement.target}
            isExternal={isExternalLink}
            analytics={{
                data_analytics_id: linkName,
                link_url: linkElement.href,
                link_text: linkElement.target,
            }}
        >
            {linkElement.textContent}
        </Link>
    )
}

/**
 * replaceLinks
 * Takes an html element that contains one or more links, recursively parses
 * the element and it's children, and returns an array of elements with the
 * links replaced by Link elements giving analytics and a modal for external
 * links.
 * @param originalElement
 * @param linkNameString - A string used as a prefix for the Link name and analytics
 * @returns array of elements that will be the children of
 */
function replaceLinks(
    originalElement: Element,
    linkNameString: string,
    /**
     * helper flag to allow sso links as part of external link redirection through warning modal
     */
    isStyxContent = false, // setting the default value of flag to false to avoid any code conflicts
) {
    const array = []
    const children = originalElement.children
    const parentTag = originalElement.tagName.toLowerCase()
    // check each child for the link
    // eslint-disable-next-line functional/no-let
    let stringToConsume = originalElement.innerHTML
    // eslint-disable-next-line functional/no-let
    for (let i = 0; i < originalElement.childElementCount; i++) {
        if (stringToConsume) {
            const parts1 = stringToConsume.split(children.item(i).outerHTML)
            // if the children have children, then call replaceLinks again on the child
            if (children.item(i).childElementCount > 1) {
                if (children.item(i).children.item(0).childElementCount) {
                    array.push(replaceLinks(children.item(i), linkNameString))
                    stringToConsume = parts1[1]
                } else {
                    for (
                        // eslint-disable-next-line functional/no-let
                        let x = 0;
                        x < children.item(i).childElementCount;
                        x++
                    ) {
                        if (stringToConsume) {
                            const parts =
                                originalElement.innerHTML.split(stringToConsume)
                            if (parts.length > 0) {
                                array.push(parts[0])
                            }
                            array.push(
                                replaceLinks(children.item(i), linkNameString),
                            )
                            stringToConsume = parts[1]
                        } else break
                    }
                }
            } else if (children.item(i).childElementCount === 1) {
                // here this child has exactly one child so we can pass it to a
                // function to be broken down into parts and the child replaced
                // if it is a link.
                if (children.item(i).children.item(0).childElementCount) {
                    array.push(replaceLinks(children.item(i), linkNameString))
                } else {
                    const result = createElementWrappingLink(
                        children,
                        i,
                        linkNameString,
                    )
                    array.push(result)
                }
                stringToConsume = parts1[1]
            } else {
                // This child has no children of their own so split the original string on this child,
                // push the first part, then the child (which must be a link)
                // Finally, update the originalStringToConsume for the next iteration
                array.push(parts1[0])
                if (children[i].outerHTML.startsWith("<a")) {
                    array.push(
                        convertHTMLLinkToReactLink(
                            children[i].outerHTML,
                            linkNameString,
                            isStyxContent,
                        ),
                    )
                } else {
                    const argNames = children.item(i).getAttributeNames()
                    const args = {}
                    argNames.forEach(
                        argName =>
                            (args[argName] = children
                                .item(i)
                                .getAttribute(argName)),
                    )
                    array.push(
                        <Container
                            children={children[i].innerHTML}
                            tag={children[i].tagName.toLowerCase()}
                            args={args}
                        />,
                    )
                }
                stringToConsume = parts1[1]
            }
        } else {
            break
        }
    }
    // after processing the children, there may still be some text from the body to add
    const cleanup = originalElement.innerHTML.split(
        children.item(originalElement.childElementCount - 1).outerHTML,
    )
    array.push(cleanup[1])
    // finally, create an element of the parent type with the array as the children
    const newArray = React.createElement(parentTag.toLowerCase(), {}, array)
    return newArray
}
