/**
 * Resources reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import {
    createStatefulSlice,
    SliceState,
} from "store/util/create-stateful-slice"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export type Category = "behavioralHealth"
    | "medicareResources"

export type CategoryId = "behavioral_health"
    | "medicare_resources"

export type SubcategoryId =
    | "support_for_mental_health"
    | "support_tools_for_behavioral_health"
    | "support_for_youth_and_teens"
    | "support_for_substance_use_disorder"
    | "in-network-virtual-care"
    | "public_resources"
    | "eating_disorders"
    | "in-home_care"
    | "supplemental_benefits"
    | "health_wellness"
    | "discounts"
    | "health_support_programs"

export interface CTAAction {
    readonly type: "call" | "chat" | "link"
    readonly title: string
    readonly description?: string
    readonly label?: string
    readonly url?: string
    readonly availability?: string
}
export interface Pill {
    readonly label: string
    readonly icon?: string // Icon name configured in pillsCollection. So front can refer which icon should be used. 
}


export interface Resource {
    readonly categories?: ReadonlyArray<CategoryId>
    readonly subcategories?: ReadonlyArray<SubcategoryId>
    readonly id: string
    readonly title: string
    readonly comment?: string
    readonly description: string
    readonly url: string
    readonly external: boolean
    readonly isExternalOnClick?: boolean
    readonly showExternalMessage: boolean
    readonly onClick?: VoidFunction
    readonly cta?: {
        readonly title: string
        readonly labels?: ReadonlyArray<string>
        readonly description?: string
        readonly actions: ReadonlyArray<CTAAction>
    }
    readonly subDescription?: string
    readonly enabled?: boolean
    readonly contact?: string

    // Styles for experiments page
    readonly className?: string
    readonly cardClassName?: string

    //pills
    readonly pills?: ReadonlyArray<Pill>
}

export interface ResourceSubcategory {
    readonly categories: ReadonlyArray<CategoryId>
    readonly id: SubcategoryId
    readonly title: string
    readonly description: string
    readonly resources?: ReadonlyArray<Resource>
    readonly link?: {
        readonly name: string
        readonly url: string
    }
    readonly external?: boolean
    readonly showExternalMessage?: boolean
    readonly enabled?: boolean
    readonly isAction?: boolean
}

export interface ResourceCategory {
    readonly id: CategoryId
    readonly label: string
    readonly items?: ReadonlyArray<ResourceSubcategory>
    readonly quickLinks?: ReadonlyArray<Resource>
    readonly actionCards?: ReadonlyArray<ResourceSubcategory>
}

export type Resources = {
    readonly [key in Category]: ResourceCategory
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<Resources> = {
    status: "pristine",
    behavioralHealth: null,
    medicareResources: null,
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const resources = createStatefulSlice({
    name: "resources",
    initialState,
    reducers: {
        receiveResources: (
            state: SliceState<Resources>,
            action: PayloadAction<Resources>,
        ) => {
            return {
                status: "completed",
                ...action.payload,
            }
        },
        resetResources: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const resourcesActions = { ...resources.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default resources.reducer
