/**
 * Timeline Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import { OpportunityByTreatmentIdQuery } from "./queries"
import http from "utils/http"
import { transformOpportunityApiData } from "./utils"

export const fetchOpportunityByTreatment= createAsyncThunk(
    "opportunities/fetchOpportunityByTreatment", // Reducer name
    
    async (params: ReadonlyArray<any>, { dispatch }) => { // eslint-disable-line
        const [opportunityIds, treatmentId] = params
       
        /**
         * Check parameters 
        */
        if (!treatmentId) {
            return dispatch(
                actions.updateTreatments({
                    isItemLoading: false,
                    isItemInitialized: true,
                    errorMessage: "Missing treatment id",
                }),
            )
        }
        
        /**
         * Set loading state
        */
        const query = OpportunityByTreatmentIdQuery(opportunityIds, treatmentId)

        /**
         * Request opportunity data from digital-first-information-service
         */
        const res = await http.query(
            "/api/digital-first-information-service/graphql",
            query,
        )

        /**
         * Handle request errors
         */
        if (res.error) {
            console.error(res.error)
            return dispatch(
                actions.updateTreatments({
                    isItemLoading: false,
                    isItemInitialized: true,
                    errorMessage: "Could not retrieve treatment data",
                }),
            )
        }

        /**
         * Update items state
         */
        const { opportunities, insights } = store.getState()
        const raw = res.data.data.rxOpportunities[0]
        const insight = insights.items.find(item => {
            const url = new URL(item.action.route)
            const urlParams = new URLSearchParams(url.search)
            const oppOnInsight = urlParams.get("opportunity_id")
            if (String(oppOnInsight) === String(raw.id)) {
                return item
            }
        })
        const opportunity = transformOpportunityApiData(raw, treatmentId, insight)
        const exists = opportunities.items.find(item => item.id == opportunity.id)
        const items = exists ?
            opportunities.items.map( item => item.id === opportunity.id ? opportunity : item )
            :
            opportunities.items.concat([opportunity])

        dispatch(
            actions.updateOpportunities({
                items: items,
                isItemLoading: false,
                isItemInitialized: true,
            }),
        )
    },
)
