interface ResultMap {
    readonly feature: ReadonlyMap<string, boolean>
}

export type ConsumerRulesResult = Record<string, boolean>

export interface ValidConsumerRulesResponseData {
    readonly result: ResultMap
    readonly errors: ReadonlyArray<ReadonlyMap<string, unknown>>
}

export interface NewConsumerRulesResponseData {
    readonly result: Record<string, ConsumerRulesResult>
    readonly errors?: unknown
}

export const CONSUMER_RULES = {
    DASHBOARD_SPOTLIGHT: {
        CATEGORY: "dashboard",
        NAME: "dashboard-spotlight",
    },
    NAVIGATION_SPOTLIGHT: {
        CATEGORY: "dashboard",
        NAME: "navigation-spotlight",
    },
    PHARMACY_FEEDBACK: {
        CATEGORY: "pharmacy-hub",
        NAME: "pharmacy-feedback",
    },
    EXPRESS_SCRIPT: {
        CATEGORY: "feature",
        NAME: "express-script",
    },
    RX_COVERAGE_ACTIVE: {
        CATEGORY: "rx-coverage-state",
        NAME: "active",
    },
    RX_PCOMP: {
        CATEGORY: "feature",
        NAME: "rx-pcomp",
    },
    RX_PCOMP_01_CABINET: {
        CATEGORY: "feature",
        NAME: "rx-pcomp-01-cabinet",
    },
    RX_PCOMP_02_RESEARCH_MEDS: {
        CATEGORY: "feature",
        NAME: "rx-pcomp-02-research-meds",
    },
    RX_PCOMP_03: {
        CATEGORY: "feature",
        NAME: "rx-pcomp-03",
    },
    RX_PCOMP_04: {
        CATEGORY: "feature",
        NAME: "rx-pcomp-04",
    },
    RX_PCOMP_05_LOCATION: {
        CATEGORY: "feature",
        NAME: "rx-pcomp-05-location",
    },
    RX_PRIME_COVERAGE: {
        CATEGORY: "feature",
        NAME: "rx-prime-coverage",
    },

    RX_PRIME_COVERAGE_RESEARCH: {
        CATEGORY: "feature",
        NAME: "rx-prime-coverage-research",
    },

    PREMIUM_PAYMENTS: {
        CATEGORY: "costs",
        NAME: "premium-payment",
    },

    PREMIUM_PAYMENT_ELIGIBLE: {
        CATEGORY: "navigation",
        NAME: "premium-payment",
    },

    NATIVE_PAYMENTS: {
        CATEGORY: "native",
        NAME: "claims-costs/payments",
    },

    CLAIMS_COSTS__PAYMENTS__AMOUNT__PAST_DUE: {
        CATEGORY: "claims-costs/payments",
        NAME: "amount/past-due",
    },

    CLAIMS_COSTS__PAYMENTS__AMOUNT__NEW_CHARGE: {
        CATEGORY: "claims-costs/payments",
        NAME: "amount/new-charges",
    },

    CLAIMS_COSTS__PAYMENTS__AMOUNT__TOTAL_DUE: {
        CATEGORY: "claims-costs/payments",
        NAME: "amount/total-due",
    },

    CLAIMS_COSTS__PAYMENTS__AMOUNT__TOTAL_DUE_PENDING: {
        CATEGORY: "claims-costs/payments",
        NAME: "amount/total-due-pending",
    },

    CLAIMS_COSTS__PAYMENTS__AMOUNT__TOTAL_DUE_INVOICE_PENDING: {
        CATEGORY: "claims-costs/payments",
        NAME: "amount/total-due-invoice-pending",
    },

    CLAIMS_COSTS__PAYMENTS__BUTTON__MAKE_PAYMENT: {
        CATEGORY: "claims-costs/payments",
        NAME: "button/make-payment",
    },

    CLAIMS_COSTS__PAYMENT_HISTORY__BUTTON__MAKE_PAYMENT: {
        CATEGORY: "claims-costs/payment-history",
        NAME: "button/make-payment",
    },

    CLAIMS_COSTS__PAYMENTS__BUTTON__MANAGE_PAYMENT: {
        CATEGORY: "claims-costs/payments",
        NAME: "button/manage-payment",
    },

    CLAIMS_COSTS__PAYMENTS__BUTTON__SET_UP_AUTOPAY: {
        CATEGORY: "claims-costs/payments",
        NAME: "button/set-up-autopay",
    },

    CLAIMS_COSTS__PAYMENTS__BUTTON__CUSTOMER_SERVICE: {
        CATEGORY: "claims-costs/payments",
        NAME: "button/customer-service",
    },

    CLAIMS_COSTS__PAYMENTS__LINK__CUSTOMER_SERVICE: {
        CATEGORY: "claims-costs/payments",
        NAME: "link/customer-service",
    },

    CLAIMS_COSTS__PAYMENTS__MESSAGE__PAYMENT_INFO: {
        CATEGORY: "claims-costs/payments",
        NAME: "message/payment-info",
    },

    CLAIMS_COSTS__PAYMENTS__ALERT__PAST_DUE_WITH_DATE: {
        CATEGORY: "claims-costs/payments",
        NAME: "alert/past-due-with-date",
    },

    CLAIMS_COSTS__PAYMENTS__ALERT__PAST_DUE: {
        CATEGORY: "claims-costs/payments",
        NAME: "alert/past-due",
    },

    CLAIMS_COSTS__PAYMENTS__ALERT__PAST_DUE_WITH_AUTOPAY_AND_DATE: {
        CATEGORY: "claims-costs/payments",
        NAME: "alert/past-due-with-autopay-and-date",
    },

    CLAIMS_COSTS__PAYMENTS__ALERT__PAST_DUE_WITH_AUTOPAY: {
        CATEGORY: "claims-costs/payments",
        NAME: "alert/past-due-with-autopay",
    },

    CLAIMS_COSTS__PAYMENTS__PAYMENT_PENDING: {
        CATEGORY: "claims-costs/payments",
        NAME: "alert/payment-pending",
    },

    CLAIMS_COSTS__PAYMENTS__AUTOPAY_PENDING: {
        CATEGORY: "claims-costs/payments",
        NAME: "alert/autopay-pending",
    },

    CLAIMS_COSTS__PAYMENTS__ALERT__TERMINATED_AUTOPAY_ELIGIBLE: {
        CATEGORY: "claims-costs/payments",
        NAME: "alert/terminated-autopay-eligible",
    },

    CLAIMS_COSTS__PAYMENTS__ALERT__TERMINATED: {
        CATEGORY: "claims-costs/payments",
        NAME: "alert/terminated",
    },

    CLAIMS_COSTS__PAYMENTS__ALERT__SSA: {
        CATEGORY: "claims-costs/payments",
        NAME: "alert/ssa",
    },

    CLAIMS_COSTS__PAYMENTS__ALERT__AUTOPAY_SCHEDULED: {
        CATEGORY: "claims-costs/payments",
        NAME: "alert/autopay-scheduled",
    },

    COVERAGE_PENDING_ONLY: {
        CATEGORY: "member-coverage-state",
        NAME: "pending/only",
    },
    COVERAGE_MEDICAL_ACTIVE: {
        CATEGORY: "coverage/medical",
        NAME: "active",
    },
    AUDIENCE_MEDICARE: {
        CATEGORY: "audience",
        NAME: "medicare",
    },

    WELLNESS_AVAILABLE: {
        CATEGORY: "product/wellness",
        NAME: "available",
    },

    WELLNESS_AUTHORIZATION: {
        CATEGORY: "product/wellness",
        NAME: "authorization",
    },

    MA_HMO: {
        CATEGORY: "line-of-business",
        NAME: "ma-hmo",
    },

    MA_HMO_PPO: {
        CATEGORY: "preferences/sharing-privacy",
        NAME: "provider-access-auth",
    },

    MA_PPO: {
        CATEGORY: "line-of-business",
        NAME: "ma-ppo",
    },

    MEDIGAP: {
        CATEGORY: "line-of-business",
        NAME: "medigap",
    },

    MEDICARE_PDP: {
        CATEGORY: "line-of-business",
        NAME: "medicare-part-d/deprecated",
    },

    MEDICARE_PART_D: {
        CATEGORY: "line-of-business",
        NAME: "medicare-part-d",
    },

    M3P_WIPRO_PAYMENT_PORTAL: {
        CATEGORY: "web/pharmacy",
        NAME: "m3p-wipro-payment-portal",
    },

    ROX: {
        CATEGORY: "line-of-business",
        NAME: "rox",
    },

    MEDICAL_FIND_CARE: {
        CATEGORY: "find-care",
        NAME: "medical",
    },

    DENTAL_FIND_CARE: {
        CATEGORY: "find-care",
        NAME: "dental",
    },

    VISION_FIND_CARE: {
        CATEGORY: "find-care",
        NAME: "vision",
    },

    PHARMACY_FIND_CARE: {
        CATEGORY: "find-care",
        NAME: "pharmacy",
    },

    BEHAVIORAL_HEALTH_FIND_CARE: {
        CATEGORY: "find-care",
        NAME: "behavioral-health",
    },

    VISION_BENEFITS_GENERIC: {
        CATEGORY: "vision/vsp",
        NAME: "generic",
    },

    VISION_BENEFITS_PEBB: {
        CATEGORY: "vision/vsp",
        NAME: "pebb",
    },

    VISION_BENEFITS_SEBB: {
        CATEGORY: "vision/vsp",
        NAME: "sebb",
    },

    VISION_BENEFITS_TAB: {
        CATEGORY: "benefit/tab",
        NAME: "vision",
    },

    PHARMACY_BENEFITS_TAB: {
        CATEGORY: "benefit/tab",
        NAME: "pharmacy",
    },

    MEDICAL_BENEFITS_TAB: {
        CATEGORY: "benefit/tab",
        NAME: "medical",
    },

    HCA_SMARTHEALTH_EARNED: {
        CATEGORY: "hca/smarthealth",
        NAME: "incentive/earned",
    },

    HCA_SMARTHEALTH_NOT_EARNED: {
        CATEGORY: "hca/smarthealth",
        NAME: "incentive/not-earned",
    },

    HCA_SMARTHEALTH_OTHER: {
        CATEGORY: "hca/smarthealth",
        NAME: "incentive/other",
    },

    HCA_GENERAL: {
        CATEGORY: "employer/group",
        NAME: "hca",
    },

    CIS: {
        CATEGORY: "line-of-business",
        NAME: "cis",
    },

    DISPATCH_HEALTH: {
        CATEGORY: "resource",
        NAME: "dispatch-health",
    },

    M3P_WIPRO: {
        CATEGORY: "resource",
        NAME: "m3p-wipro",
    },

    DISPATCH_HEALTH_URL_ID: {
        CATEGORY: "resources/dispatch-health",
        NAME: "url/region-id",
    },

    DISPATCH_HEALTH_URL_UT: {
        CATEGORY: "resources/dispatch-health",
        NAME: "url/region-ut",
    },

    DISPATCH_HEALTH_URL_WA: {
        CATEGORY: "resources/dispatch-health",
        NAME: "url/region-wa",
    },

    DISPATCH_HEALTH_URL_OR: {
        CATEGORY: "resources/dispatch-health",
        NAME: "url/region-or",
    },

    DISPATCH_HEALTH_URL_ASURIS: {
        CATEGORY: "resources/dispatch-health",
        NAME: "url/brand-asuris",
    },

    DISPATCH_HEALTH_URL_BSH: {
        CATEGORY: "resources/dispatch-health",
        NAME: "url/brand-bridgespan",
    },

    RESOURCE__WINFERTILITY: {
        CATEGORY: "resources",
        NAME: "winfertility",
    },

    RESOURCE_WINFERTILITY__URL_ID: {
        CATEGORY: "resources/winfertility",
        NAME: "url/region-id",
    },

    RESOURCE_WINFERTILITY__URL_OR: {
        CATEGORY: "resources/winfertility",
        NAME: "url/region-or",
    },

    RESOURCE_WINFERTILITY__URL_UT: {
        CATEGORY: "resources/winfertility",
        NAME: "url/region-ut",
    },

    RESOURCE_WINFERTILITY__URL_WA: {
        CATEGORY: "resources/winfertility",
        NAME: "url/region-wa",
    },

    RESOURCE_WINFERTILITY__URL_ASURIS: {
        CATEGORY: "resources/winfertility",
        NAME: "url/asuris",
    },

    DISPATCH_HEALTH_FIND_CARE: {
        CATEGORY: "find-care",
        NAME: "dispatch-health",
    },

    HCA_PHARMACY_FIND_CARE: {
        CATEGORY: "find-care",
        NAME: "pharmacy/hca",
    },

    SEBB_VISION_FIND_CARE: {
        CATEGORY: "find-care",
        NAME: "vision/sebb",
    },

    RESOURCE_HCA_SMARTHEALTH: {
        CATEGORY: "resource",
        NAME: "hca/smarthealth",
    },
    SELF_GUIDED_EMOTIONAL_HEALTH: {
        CATEGORY: "resource",
        NAME: "self-guided-emotional-health",
    },
    RESOURCE_MDLIVE_PCP: {
        CATEGORY: "resource",
        NAME: "mdlive/pcp",
    },

    RESOURCE_MDLIVE_NO_BH: {
        CATEGORY: "resource",
        NAME: "mdlive/no-behavioral-health",
    },

    RESOURCE_MDLIVE_BH: {
        CATEGORY: "resource",
        NAME: "mdlive/behavioral-health",
    },

    RESOURCE_MDLIVE: {
        CATEGORY: "resource",
        NAME: "mdlive",
    },

    RESOURCE_HINGE_HEALTH: {
        CATEGORY: "resource",
        NAME: "hinge-health",
    },

    RESOURCE_EXPERT_OPINION: {
        CATEGORY: "resource",
        NAME: "expert-opinion",
    },

    RESOURCE_DOCTOR_ON_DEMAND: {
        CATEGORY: "resource",
        NAME: "doctor-on-demand",
    },

    RESOURCE_ASK_A_DOCTOR: {
        CATEGORY: "resource",
        NAME: "ask-a-doctor",
    },

    RESOURCE_ADVICE_24: {
        CATEGORY: "resource",
        NAME: "advice-24",
    },

    RESOURCE_PREVENTATIVE_CARE: {
        CATEGORY: "resource",
        NAME: "preventative-care",
    },

    RESOURCE_EMPOWER: {
        CATEGORY: "resource",
        NAME: "empower",
    },

    DASHBOARD_BANNER__PREMIUM_PAYMENT_V2: {
        CATEGORY: "dashboard/banner",
        NAME: "premium-payment-v2",
    },

    DASHBOARD__BANNER_CONTINUITY_OF_CARE: {
        CATEGORY: "dashboard",
        NAME: "banner/continuity-of-care",
    },

    DASHBOARD_CARD_EMPOWER: {
        CATEGORY: "dashboard/card",
        NAME: "empower",
    },

    DASHBOARD_LANGUAGE_SPANISH: {
        CATEGORY: "dashboard/language",
        NAME: "spanish",
    },

    DASHBOARD_DOCUMENTS__SPANISH_DENTAL: {
        CATEGORY: "dashboard/documents",
        NAME: "spanish/dental",
    },

    DASHBOARD_DOCUMENTS__SPANISH_MEDICAL: {
        CATEGORY: "dashboard/documents",
        NAME: "spanish/medical",
    },

    RESOURCE_RESEARCH_RX: {
        CATEGORY: "resource",
        NAME: "research-rx",
    },

    RESOURCE_SILVER_AND_FIT: {
        CATEGORY: "resource",
        NAME: "silver-and-fit",
    },

    RESOURCE_CARE_MANAGEMENT: {
        CATEGORY: "resource",
        NAME: "care-management",
    },

    RESOURCE_BABYWISE: {
        CATEGORY: "resource",
        NAME: "babywise",
    },

    RESOURCE_BUMP_TO_BABY: {
        CATEGORY: "resource",
        NAME: "bump-to-baby",
    },

    RESOURCE_PALLIATIVE_CARE: {
        CATEGORY: "resource",
        NAME: "palliative-care",
    },

    RESOURCE_CONDITION_MANAGER: {
        CATEGORY: "resource",
        NAME: "condition-manager",
    },

    RESOURCE_GENDER_AFFIRMING_CARE: {
        CATEGORY: "resource",
        NAME: "gender-affirming-care",
    },

    RESOURCE_TELEHEALTH: {
        CATEGORY: "resource",
        NAME: "telehealth",
    },

    RESOURCE_ESTIMATE_COSTS: {
        CATEGORY: "resource",
        NAME: "estimate-costs",
    },

    RESOURCE_ABLE_TO: {
        CATEGORY: "resource",
        NAME: "able-to",
    },

    RESOURCE_WINCO_EMPLOYEE_BENEFITS: {
        CATEGORY: "resource",
        NAME: "winco/employee-benefits",
    },

    RESOURCE_WA_SUBSTANCE_ABUSE: {
        CATEGORY: "resource",
        NAME: "wa/substance-abuse",
    },

    RESOURCE_ASANTE_EAP: {
        CATEGORY: "resource",
        NAME: "asante/eap",
    },

    RESOURCE_MEDICAL_SUPPLIES: {
        CATEGORY: "resource",
        NAME: "medical-supplies",
    },

    RESOURCE_UMP_HEALTH_PEBB: {
        CATEGORY: "resource",
        NAME: "ump-health/pebb",
    },

    RESOURCE_UMP_HEALTH_SEBB: {
        CATEGORY: "resource",
        NAME: "ump-health/sebb",
    },

    RESOURCE_UMP_BENEFITS_PEBB: {
        CATEGORY: "resource",
        NAME: "ump-benefits/pebb",
    },

    RESOURCE_UMP_BENEFITS_SEBB: {
        CATEGORY: "resource",
        NAME: "ump-benefits/sebb",
    },

    // this consumer rule will always return false after 1/1/2025 and can be deleted after that date
    RESOURCE_OVER_THE_COUNTER: {
        CATEGORY: "resource",
        NAME: "medadvantage-otc-incentives",
    },
    // this consume rule will return false until 1/1/2025
    RESOURCE_MEDADVANTAGE_INCENTIVES_OTC: {
        CATEGORY: "resource",
        NAME: "medadvantage-incentives-otc",
    },
    // this consume rule will return false until 1/1/2025
    RESOURCE_MEDADVANTAGE_INCENTIVES: {
        CATEGORY: "resource",
        NAME: "medadvantage-incentives",
    },

    RESOURCE_MENTAL_HEALTH_PROVIDER: {
        CATEGORY: "resource",
        NAME: "mental-health-provider",
    },

    RESOURCE_HUNTSMAN_MENTAL_HEALTH_PROVIDER: {
        CATEGORY: "resource",
        NAME: "huntsman-mental-health-provider",
    },

    RESOURCE_SUICIDE_CRISIS_LIFELINE: {
        CATEGORY: "resource",
        NAME: "suicide-crisis-lifeline",
    },

    RESOURCE_FIND_RIGHT_CARE: {
        CATEGORY: "resource",
        NAME: "find-right-care",
    },

    RESOURCE_NEDA: {
        CATEGORY: "resource",
        NAME: "neda",
    },

    PEBB_24_HOUR_NURSELINE: {
        CATEGORY: "resource",
        NAME: "uw-24-hour-nurseline/pebb",
    },

    SEBB_24_HOUR_NURSELINE: {
        CATEGORY: "resource",
        NAME: "uw-24-hour-nurseline/sebb",
    },

    HSA_AVAILABLE: {
        CATEGORY: "product/hsa",
        NAME: "available",
    },

    ESTIMATE_COSTS_VSP: {
        CATEGORY: "costs/tab",
        NAME: "vsp",
    },

    SUPPORT_PEBB: {
        CATEGORY: "employer/group",
        NAME: "hca/pebb",
    },

    SUPPORT_SEBB: {
        CATEGORY: "employer/group",
        NAME: "hca/sebb",
    },

    ALERT_WA_MANDATE: {
        CATEGORY: "alert",
        NAME: "wa-mandate",
    },

    CHATBOT_AVAILABLE: {
        CATEGORY: "product/chatbot",
        NAME: "available",
    },

    COBROWSE_AVAILABLE: {
        CATEGORY: "product/cobrowse",
        NAME: "available",
    },

    FORTIVE_LIVONGO_HYPERTENSION: {
        CATEGORY: "resource",
        NAME: "fortive/livongo-hypertension",
    },

    FORTIVE_VIRTA_DIABETES: {
        CATEGORY: "resource",
        NAME: "fortive/virta-diabetes",
    },

    FORTIVE_PROGENY_INFERTILITY: {
        CATEGORY: "resource",
        NAME: "fortive/progeny-infertility",
    },

    FORTIVE_SPRING_HEALTH: {
        CATEGORY: "resource",
        NAME: "fortive/spring-health",
    },
    FOOTER__ABLE_TO: {
        CATEGORY: "web/footer",
        NAME: "able-to",
    },

    PRIMARY_CARE_PROVIDER: {
        CATEGORY: "preferences/contact",
        NAME: "pcp",
    },

    ACCOUNT_SETTINGS_MEDICAL_PAPERLESS: {
        CATEGORY: "toggle",
        NAME: "paperless/medical-eob",
    },

    ACCOUNT_SETTINGS_PHARMACY_PAPERLESS: {
        CATEGORY: "toggle",
        NAME: "paperless/pharmacy-eob",
    },

    ACCOUNT_SETTINGS_ANOC_PAPERLESS: {
        CATEGORY: "toggle",
        NAME: "paperless/anoc",
    },

    ACCOUNT_SETTINGS_SSO_AUTHORIZATION: {
        CATEGORY: "preferences/sharing-privacy",
        NAME: "sso",
    },

    ACCOUNT_SETTINGS_WELLNESS_AUTHORIZATION: {
        CATEGORY: "preferences/sharing-privacy",
        NAME: "wellness/authorization",
    },

    ACCOUNT_SETTINGS_INTEROP_ELIGIBLE: {
        CATEGORY: "interop",
        NAME: "eligible",
    },

    ACCOUNT_SETTINGS_FAMILY_SHARING: {
        CATEGORY: "account-settings",
        NAME: "family-sharing",
    },

    ACCOUNT_SETTINGS_SIDEBAR_CHANGE_ADDRESS: {
        CATEGORY: "account-settings/account-information",
        NAME: "sidebar/change-address",
    },

    ACCOUNT_SETTINGS__TAB__DATA_AND_PRIVACY: {
        CATEGORY: "account-settings/tab",
        NAME: "data-and-privacy",
    },

    ACCOUNT_SETTINGS__DATA_AND_PRIVACY__MARKETING_AUTHORIZATION: {
        CATEGORY: "account-settings/data-and-privacy",
        NAME: "marketing-authorization",
    },

    ACCOUNT_SETTINGS__DATA_AND_PRIVACY__SSO_AUTHORIZATION: {
        CATEGORY: "account-settings/data-and-privacy",
        NAME: "sso-authorization",
    },

    ACCOUNT_SETTINGS__DATA_AND_PRIVACY__WELLNESS_AUTHORIZATION: {
        CATEGORY: "account-settings/data-and-privacy",
        NAME: "wellness-authorization",
    },

    ACCOUNT_SETTINGS__DATA_AND_PRIVACY__INTEROP_AUTHORIZATION: {
        CATEGORY: "account-settings/data-and-privacy",
        NAME: "interop-authorization",
    },

    ACCOUNT_SETTINGS__DATA_AND_PRIVACY__SIDEBAR__FAMILY_SHARING: {
        CATEGORY: "account-settings/data-and-privacy",
        NAME: "sidebar/family-sharing",
    },

    ACCOUNT_SETTINGS__DATA_AND_PRIVACY__SIDEBAR__SHARE: {
        CATEGORY: "account-settings/data-and-privacy",
        NAME: "sidebar/share",
    },

    ACCOUNT_SETTINGS__FINANCE__HIDE_PAYMENT_HISTORY: {
        CATEGORY: "account-settings/finance",
        NAME: "hide-payment-history",
    },

    ACCOUNT_SETTINGS__FINANCE__SIDEBAR__MAKE_PAYMENT: {
        CATEGORY: "account-settings/finance",
        NAME: "sidebar/make-payment",
    },

    ACCOUNT_SETTINGS_PAPERLESS_PREFERENCE: {
        CATEGORY: "account-settings/paperless",
        NAME: "preference-permission",
    },

    COVERED_SERVICES__CHECK_BENEFITS__BANNER_CONTINUITY_OF_CARE: {
        CATEGORY: "covered-services/check-benefits",
        NAME: "banner/continuity-of-care",
    },

    COVERED_SERVICES__CHECK_BENEFITS__TAB__DENTAL: {
        CATEGORY: "covered-services/check-benefits",
        NAME: "tab/dental",
    },
    COVERED_SERVICES__CHECK_BENEFITS__TAB__HEARING: {
        CATEGORY: "covered-services/check-benefits",
        NAME: "tab/hearing",
    },
    COVERED_SERVICES__CHECK_BENEFITS__TAB__MEDICAL: {
        CATEGORY: "covered-services/check-benefits",
        NAME: "tab/medical",
    },
    COVERED_SERVICES__CHECK_BENEFITS__TAB__PHARMACY: {
        CATEGORY: "covered-services/check-benefits",
        NAME: "tab/pharmacy",
    },
    COVERED_SERVICES__CHECK_BENEFITS__TAB__VISION: {
        CATEGORY: "covered-services/check-benefits",
        NAME: "tab/vision",
    },
    COVERED_SERVICES__CHECK_BENEFITS__QUICK__ACTION: {
        CATEGORY: "web/dashboard",
        NAME: "check-benefits/quick-action",
    },
    COVERED_SERVICES__CHECK_BENEFITS__LAYOUT: {
        CATEGORY: "web/covered-services",
        NAME: "check-benefits/layout",
    },
    COVERED_SERVICE__YOUR_BENEFITS_VSP_HCA_SEBB: {
        CATEGORY: "covered-service/your-benefits",
        NAME: "vsp/hca-sebb",
    },
    PRIME_PBM: {
        CATEGORY: "buy-up",
        NAME: "enhanced-medications/prime-pbm",
    },

    NON_PRIME_PBM: {
        CATEGORY: "buy-up",
        NAME: "enhanced-medications/non-prime-pbm",
    },

    CHANGE_ADDRESS_BILLING_LEVEL_I: {
        CATEGORY: "account-settings",
        NAME: "change-address/individual",
    },

    ASK_A_PHARMACIST: {
        CATEGORY: "feature",
        NAME: "ask-a-pharmacist",
    },

    FIND_CARE__GET_CARE_NOW__ASK_A_PHARMACIST: {
        CATEGORY: "find-care",
        NAME: "get-care-now/ask-a-pharmacist",
    },

    EAP_CAMBIA: {
        CATEGORY: "resource",
        NAME: "eap/cambia-employee",
    },

    EAP_REGENCE_SMALL_GROUP: {
        CATEGORY: "resource",
        NAME: "eap/regence-one-to-fifty-fi",
    },

    EAP_ASURIS_SMALL_GROUP: {
        CATEGORY: "resource",
        NAME: "eap/asuris-one-to-fifty-fi",
    },

    EAP_REGENCE_LARGE_GROUP: {
        CATEGORY: "resource",
        NAME: "eap/regence-fifty-one-plus",
    },

    EAP_ASURIS_LARGE_GROUP: {
        CATEGORY: "resource",
        NAME: "eap/asuris-fifty-one-plus",
    },

    IAP_REGENCE: {
        CATEGORY: "resource",
        NAME: "iap/regence-individual",
    },

    IAP_ASURIS: {
        CATEGORY: "resource",
        NAME: "iap/asuris-individual",
    },
    JOINT_ADMIN_ENABLED: {
        CATEGORY: "feature",
        NAME: "dfw-joint-admin-enabled",
    },
    /* Navigation: Claims & costs -> Estimate costs */
    NAV__HOME: {
        CATEGORY: "navigation",
        NAME: "home",
    },
    NAV__YOUR_CARE: {
        CATEGORY: "navigation",
        NAME: "your-care",
    },
    NAV__YOUR_CARE__TIMELINE: {
        CATEGORY: "navigation",
        NAME: "your-care/timeline",
    },
    NAV__YOUR_CARE__DOCTORS_AND_PROVIDERS: {
        CATEGORY: "navigation",
        NAME: "your-care/doctors-and-providers",
    },
    NAV__YOUR_CARE__MEDICATIONS: {
        CATEGORY: "navigation",
        NAME: "your-care/medications",
    },
    NAV__COVERED_SERVICES: {
        CATEGORY: "navigation",
        NAME: "covered-services",
    },
    NAV__COVERED_SERVICES__CHECK_BENEFITS: {
        CATEGORY: "navigation",
        NAME: "covered-services/check-benefits",
    },
    NAV__COVERED_SERVICES__FIND_CARE: {
        CATEGORY: "navigation",
        NAME: "covered-services/find-care",
    },
    NAV__COVERED_SERVICES__RESOURCES: {
        CATEGORY: "navigation",
        NAME: "covered-services/resources",
    },
    NAV__COVERED_SERVICES__FORMS_DOCUMENTS: {
        CATEGORY: "navigation",
        NAME: "covered-services/forms-documents",
    },
    NAV__CLAIMS_COSTS: {
        CATEGORY: "navigation",
        NAME: "claims-costs",
    },
    NAV__CLAIMS_COSTS__OVERVIEW: {
        CATEGORY: "navigation",
        NAME: "claims-costs/overview",
    },
    NAV__CLAIMS_COSTS__CLAIMS: {
        CATEGORY: "navigation",
        NAME: "claims-costs/claims",
    },
    NAV__CLAIMS_COSTS__PAYMENTS: {
        CATEGORY: "navigation",
        NAME: "claims-costs/payments",
    },
    NAV__CLAIMS_COSTS__ESTIMATE_COSTS: {
        CATEGORY: "navigation",
        NAME: "claims-costs/estimate-costs",
    },
    NAV__CLAIMS_COSTS__EOB_STATEMENTS: {
        CATEGORY: "navigation",
        NAME: "claims-costs/eob-statements",
    },
    NAV__CLAIMS_COSTS__TAX_FORMS: {
        CATEGORY: "navigation",
        NAME: "claims-costs/tax-forms",
    },
    NAV__SUPPORT: {
        CATEGORY: "navigation",
        NAME: "support",
    },
    /* Medical benefits (and claims, and ???) sidebar cards */
    BENEFITS_MEDICAL__SIDEBAR_ESTIMATE_COSTS: {
        CATEGORY: "benefits/medical",
        NAME: "sidebar/estimate-costs",
    },
    SUPPORT__SIDEBAR_ESTIMATE_COSTS: {
        CATEGORY: "support",
        NAME: "sidebar/estimate-costs",
    },
    SUPPORT_DENTAL_BENEFIT_QUESTIONS: {
        CATEGORY: "support",
        NAME: "dental/usable",
    },
    BENEFITS_MEDICAL_RESOURCE_PAPERLESS_LETTERS: {
        CATEGORY: "benefits/medical",
        NAME: "resource/paperless-letters",
    },
    RESOURCE_DOCTOR_ON_DEMAND_VPC: {
        CATEGORY: "resource",
        NAME: "doctor-on-demand-vpc",
    },
    VIRTUAL_CARE_OPTIONS_GOV_PRO_TELEPHONE_LINE: {
        CATEGORY: "virtual-care-options",
        NAME: "gov-pro-telephonic-line",
    },
    VIRTUAL_CARE_OPTIONS_DOD_TELEPHONE_LINE: {
        CATEGORY: "virtual-care-options",
        NAME: "dod-telephonic-line",
    },
    VIRTUAL_CARE_OPTIONS_NURSE_CHAT_TELEPHONE_LINE: {
        CATEGORY: "virtual-care-options",
        NAME: "nurse-chat-telephonic-line",
    },
    VIRTUAL_CARE_OPTIONS_OPTUM_TELEPHONE_LINE: {
        CATEGORY: "virtual-care-options",
        NAME: "optum-telephonic-line",
    },
    VIRTUAL_CARE_OPTIONS_DOCTOR_ON_DEMAND: {
        CATEGORY: "virtual-care-options",
        NAME: "doctor-on-demand",
    },
    VIRTUAL_CARE_OPTIONS_NURSE_CHAT: {
        CATEGORY: "web/virtual-care-options",
        NAME: "nurse-chat",
    },
    MDLIVE_VENDOR: {
        CATEGORY: "virtual-care-options/telehealth",
        NAME: "mdlive",
    },
    MDLIVE_TELEH: {
        CATEGORY: "virtual-care-options/telehealth",
        NAME: "mdlive/behavioral-health",
    },
    MDLIVE_TELEX: {
        CATEGORY: "virtual-care-options/telehealth",
        NAME: "mdlive/no-behavioral-health",
    },
    MDLIVE_TELEV: {
        CATEGORY: "virtual-care-options/telehealth",
        NAME: "mdlive/pcp",
    },
    BEHAVIORAL_HEALTH: {
        CATEGORY: "virtual-care-options/telehealth",
        NAME: "mdlive/behavioral-health",
    },
    PAGER_TELEMEDICINE_MDLIVE_VENDOR: {
        CATEGORY: "product/pager-telemedicine",
        NAME: "mdlive-vendor",
    },
    PAGER_TELEMEDICINE_DOD_VENDOR: {
        CATEGORY: "product/pager-telemedicine",
        NAME: "dod-vendor",
    },
    COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_HMO_PLUS: {
        CATEGORY: "covered-services/check-benefits",
        NAME: "resources/rx-plan-type-hmo-plus",
    },
    COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_HMO: {
        CATEGORY: "covered-services/check-benefits",
        NAME: "resources/rx-plan-type-hmo",
    },
    COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_ALIGN: {
        CATEGORY: "covered-services/check-benefits",
        NAME: "resources/rx-plan-type-align",
    },
    COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_CLASSIC: {
        CATEGORY: "covered-services/check-benefits",
        NAME: "resources/rx-plan-type-classic",
    },
    COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_PRIMARY: {
        CATEGORY: "covered-services/check-benefits",
        NAME: "resources/rx-plan-type-primary",
    },
    COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_ENHANCED: {
        CATEGORY: "covered-services/check-benefits",
        NAME: "resources/rx-plan-type-enhanced",
    },
    COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_BASIC: {
        CATEGORY: "covered-services/check-benefits",
        NAME: "resources/rx-plan-type-basic",
    },
    COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_CORE: {
        CATEGORY: "covered-services/check-benefits",
        NAME: "resources/rx-plan-type-core",
    },
    COVERED_SERVICES__FIND_CARE__GET_IMMEDIATE_CARE___URGENT_CARE_NEAR_YOU: {
        CATEGORY: "covered-services/find-care",
        NAME: "get-immediate-care/find-urgent-care-near-you",
    },

    COVERED_SERVICES__FIND_CARE__GET_IMMEDIATE_CARE___EMERGENCY_ROOM_NEAR_YOU: {
        CATEGORY: "covered-services/find-care",
        NAME: "get-immediate-care/find-emergency-room-near-you",
    },

    COVERED_SERVICES_FIND_CARE__RECENT_PROVIDERS: {
        CATEGORY: "covered-services/find-care",
        NAME: "recent-providers",
    },
    FIND_CARE_V2_ENABLED: {
        CATEGORY: "feature",
        NAME: "dfw-find-care-v2-enabled",
    },
    FEATURE_BEHAVIORAL_HEALTH_ENABLED: {
        CATEGORY: "feature",
        NAME: "dfw-behavioral-health-enabled",
    },
    AMERIBEN: {
        CATEGORY: "employer/group",
        NAME: "ameriben",
    },
    JOINT_ADMIN: {
        CATEGORY: "joint-admin",
        NAME: "eligible",
    },
    JOINT_ADMIN_WITH_MEMBER_CARD: {
        CATEGORY: "joint-admin",
        NAME: "membercard",
    },
    TAX_INFORMATION_IRS_DOCUMENT: {
        CATEGORY: "costs",
        NAME: "tax-forms/form-1095-b",
    },
    FEATURE_NATIVE_CLAIM_SUBMISSION_ENABLED: {
        CATEGORY: "feature",
        NAME: "dfw-claim-submission-enabled",
    },
    FEATURE_NATIVE_HIDE_MESSAGE_CHAIN_ENABLED: {
        CATEGORY: "feature",
        NAME: "dfw-hide-reply-chain-enabled",
    },
    FEATURE_NATIVE_APPEND_PREVIOUS_MSGS_ENABLED: {
        CATEGORY: "feature",
        NAME: "dfw-msg-ctr-append-prev-msgs",
    },
    COSTS_OVERVIEW__SUBMIT_CLAIM_USE_MEDICARE_URL: {
        CATEGORY: "web/costs",
        NAME: "overview/submit-claim-use-medicare-url",
    },
    COSTS_OVERVIEW__SHOW_SUBMIT_CLAIMS_CARD: {
        CATEGORY: "web/costs",
        NAME: "overview/show-submit-claims-card",
    },
    COSTS_NATIVE_CLAIMS_DETAIL__SHOW_SUBMIT_CLAIMS_CARD: {
        CATEGORY: "web/costs",
        NAME: "native-claims-detail/show-submit-claims-card",
    },
    COSTS_NATIVE_CLAIMS__SHOW_SUBMIT_CLAIMS_CARD: {
        CATEGORY: "web/costs",
        NAME: "native-claims/show-submit-claims-card",
    },
    CLAIMS_DETAIL__SHOW_SUBMIT_CLAIMS_CARD: {
        CATEGORY: "web/claims",
        NAME: "claims-detail/show-submit-claims-card",
    },
    CLAIMS__SHOW_SUBMIT_CLAIMS_CARD: {
        CATEGORY: "web/claims",
        NAME: "claims/show-submit-claims-card",
    },
    USABLE_EOB_DENTAL: {
        CATEGORY: "claims-costs/eob-statements",
        NAME: "usable",
    },
    USABLE_CLAIMS_COSTS_OVERVIEW: {
        CATEGORY: "claims-costs/overview",
        NAME: "usable",
    },
    COVERED_SERVICES_CHECKED_BENEFITS_DENTAL_USABLE: {
        CATEGORY: "covered-services/checked-benefits",
        NAME: "dental/usable",
    },
    COVERED_SERVICES_FIND_CARE_DENTAL_USABLE: {
        CATEGORY: "covered-services/find-care",
        NAME: "dental/usable",
    },
    COVERED_SERVICES_FIND_CARE_VSP: {
        CATEGORY: "vsp-coverage-state",
        NAME: "active",
    },
    COVERED_SERVICES_FIND_CARE__FIND_A_PROVIDER_VISION: {
        CATEGORY: "covered-services/find-care",
        NAME: "find-a-provider/vision",
    },
    COVERED_SERVICES_FIND_CARE__FIND_A_PROVIDER_OTHER_VISION_BENEFITS: {
        CATEGORY: "covered-services/find-care",
        NAME: "find-a-provider/other-vision-benefits",
    },
    COVERED_SERVICES_FIND_CARE__FIND_A_PROVIDER_VSP_BENEFITS: {
        CATEGORY: "covered-services/find-care",
        NAME: "find-a-provider/vsp-benefits",
    },
    YOUR_CARE_TIMELINE_USABLE: {
        CATEGORY: "your-care/timeline",
        NAME: "usable",
    },
    WEB__CARE__YOUR_PROVIDER: {
        CATEGORY: "web/care",
        NAME: "your-provider",
    },
    WEB__CARE__YOUR_CARE_HISTORY__ITEM: {
        CATEGORY: "web/care",
        NAME: "your-care-history/item",
    },
    WEB__CARE__YOUR_CARE_HISTORY__LIST: {
        CATEGORY: "web/care",
        NAME: "your-care-history/list",
    },
    WEB__CARE__CARE_GAPS__HELP_CARD: {
        CATEGORY: "web/care",
        NAME: "care-gaps/help-card",
    },
    CARE_RESOURCES__HEADER_HIDE_PREGNANCY_TAB: {
        CATEGORY: "web/care",
        NAME: "care-resources/header-hide-pregnancy-tab",
    },
    CARE_RESOURCES__TAB__MEDICARE: {
        CATEGORY: "web/care-resources",
        NAME: "tab/medicare",
    },
    SUPPORT__SIDEBAR_FIND_CARE_CARD: {
        CATEGORY: "support/sidebar",
        NAME: "find-care-card",
    },
    DASHBOARD__FIND_CARE_BUTTON: {
        CATEGORY: "web/dashboard",
        NAME: "find-care-button",
    },
    DASHBOARD__SPOTLIGHT_CARD: {
        CATEGORY: "web/dashboard",
        NAME: "spotlight-card",
    },
    DASHBOARD__ADVICE24_CARD: {
        CATEGORY: "web/dashboard",
        NAME: "advice24-card",
    },
    DASHBOARD__RESOURCE_LIST_CARD: {
        CATEGORY: "web/dashboard",
        NAME: "resource-list-card",
    },
    DASHBOARD_QUICK_LINK_USABLE: {
        CATEGORY: "dashboard/find-a-doctor",
        NAME: "usable",
    },
    DASHBOARD_DENTAL_BENEFITS_ACCUMS: {
        CATEGORY: "dashboard/accums-benefits",
        NAME: "usable",
    },
    DASHBOARD_QUICK_ACTION__MESSAGE_CENTER: {
        CATEGORY: "dashboard/quick-action",
        NAME: "message-center",
    },
    DASHBOARD__FIND_CARE_CARD: {
        CATEGORY: "web/dashboard",
        NAME: "quick-action/find-care-card",
    },
    DASHBOARD__USABLE_DENTAL_CARD: {
        CATEGORY: "web/dashboard",
        NAME: "card/usable-dental",
    },
    DASHBOARD__BEHAVIORAL_HEALTH_CARD: {
        CATEGORY: "web/dashboard",
        NAME: "card/behavioral-health",
    },
    BENEFITS__USABLE_DENTAL_CARD: {
        CATEGORY: "web/benefits",
        NAME: "card/usable-dental",
    },
    BENEFITS__VISION_CARD: {
        CATEGORY: "web/benefits",
        NAME: "card/vision",
    },
    BENEFITS__MEDICARE_VSP_CARD: {
        CATEGORY: "web/benefits",
        NAME: "card/medicare-vsp",
    },
    BENEFITS__DISCLAIMER_USE_MEDICARE_URL: {
        CATEGORY: "web/benefits",
        NAME: "layout/disclaimer-use-medicare-url",
    },
    BENEFITS__MEDICAL_INDY_RESOURCES_CARD: {
        CATEGORY: "web/benefits",
        NAME: "medical/indy-resources-card",
    },
    BENEFITS__MEDICAL_MEDICARE_RESOURCES_CARD: {
        CATEGORY: "web/benefits",
        NAME: "medical/medicare-resources-card",
    },
    BENEFITS__MEDICAL_SHOW_MEDICARE_BOOKLET: {
        CATEGORY: "web/benefits",
        NAME: "medical/show-medicare-booklet",
    },
    BENEFITS__PHARMACY_INDY_RESOURCES_CARD: {
        CATEGORY: "web/benefits",
        NAME: "pharmacy/indy-resources-card",
    },
    BENEFITS__PHARMACY_MEDICARE_RESOURCES_CARD: {
        CATEGORY: "web/benefits",
        NAME: "pharmacy/medicare-resources-card",
    },
    BENEFITS__DENTAL_RESOURCES_CARD: {
        CATEGORY: "web/benefits",
        NAME: "dental/resources-card",
    },
    BENEFITS__MEDIGAP_RESOURCES_CARD: {
        CATEGORY: "web/benefits",
        NAME: "medigap/resources-card",
    },
    DF_PRIME_RX_OUTBOUND_SSO_ENABLED: {
        CATEGORY: "feature",
        NAME: "df-primerx-outbound-sso-enabled",
    },
    DF_HSQTEST_OUTBOUND_SSO_ENABLED: {
        CATEGORY: "feature",
        NAME: "df-hsqtest-outbound-sso-enabled",
    },
    DF_HSQ_OUTBOUND_SSO_ENABLED: {
        CATEGORY: "feature",
        NAME: "df-hsq-outbound-sso-enabled",
    },
    DF_MODA_OUTBOUND_SSO_ENABLED: {
        CATEGORY: "feature",
        NAME: "df-moda-outbound-sso-enabled",
    },
    DF_ABACUS_OUTBOUND_SSO_ENABLED: {
        CATEGORY: "feature",
        NAME: "df-abacus-outbound-sso-enabled",
    },
    DF_HEALTHEQUITY_OUTBOUND_SSO_ENABLED: {
        CATEGORY: "feature",
        NAME: "df-healthequity-outbound-sso-enabled",
    },
    DF_MDLIVE_OUTBOUND_SSO_ENABLED: {
        CATEGORY: "feature",
        NAME: "df-mdlive-outbound-sso-enabled",
    },
    DF_INSTAMED_OUTBOUND_SSO_ENABLED: {
        CATEGORY: "feature",
        NAME: "df-instamed-outbound-sso-enabled",
    },
    DF_VSP_OUTBOUND_SSO_ENABLED: {
        CATEGORY: "feature",
        NAME: "df-vsp-outbound-sso-enabled",
    },
    DF_UCD_OUTBOUND_SSO_ENABLED: {
        CATEGORY: "feature",
        NAME: "df-ucd-outbound-sso-enabled",
    },
    DF_ONLIFE_OUTBOUND_SSO_ENABLED: {
        CATEGORY: "feature",
        NAME: "df-onlife-outbound-sso-enabled",
    },
    DF_CVS_OUTBOUND_SSO_ENABLED: {
        CATEGORY: "feature",
        NAME: "df-cvs-outbound-sso-enabled",
    },
    DF_DOD_OUTBOUND_SSO_ENABLED: {
        CATEGORY: "feature",
        NAME: "df-dod-outbound-sso-enabled",
    },
    DF_ESI_OUTBOUND_SSO_ENABLED: {
        CATEGORY: "feature",
        NAME: "df-esi-outbound-sso-enabled",
    },
    DF_BEYONDWELL_OUTBOUND_SSO_ENABLED: {
        CATEGORY: "feature",
        NAME: "df-beyondwell-outbound-sso-enabled",
    },
    DF_WIPROM3P_OUTBOUND_SSO_ENABLED: {
        CATEGORY: "feature",
        NAME: "df-wiprom3p-outbound-sso-enabled",
    },
    RESOURCE_VIRTUAL_CARE: {
        CATEGORY: "resource",
        NAME: "virtual-care",
    },
    ACCOUNT_PERSONAL_INFORMATION: {
        CATEGORY: "web/preferences",
        NAME: "personal-information",
    },

    ACCOUNT_PERSONAL_INFORMATION_INDIVIDUAL: {
        CATEGORY: "web/settings-my-account-information",
        NAME: "personal-information/individual-context",
    },
    ACCOUNT__SHOW_MEDICARE_ADDRESS_CARD: {
        CATEGORY: "web/my-account",
        NAME: "personal-information/show-medicare-address-card",
    },
    ACCOUNT__SHOW_ADDRESS_CARD: {
        CATEGORY: "web/my-account",
        NAME: "personal-information/show-address-card",
    },
    ACCOUNT__SHOW_SMS_CLAIMS_ALERTS_CARD: {
        CATEGORY: "web/my-account",
        NAME: "preferences/show-sms-claims-alerts-toggle-card",
    },
    ACCOUNT__SHOW_MEDICARE_CONTACT_PREFERENCES_CARD: {
        CATEGORY: "web/my-account",
        NAME: "preferences/show-medicare-contact-preferences-card",
    },
    ACCOUNT__FAMILY_SHARING_USE_BRIDGESPAN_AUTH_URL: {
        CATEGORY: "web/my-account",
        NAME: "family-sharing/use-bridgespan-auth-url",
    },
    ACCOUNT__FAMILY_SHARING_USE_ASURIS_NON_MEDICARE_AUTH_URL: {
        CATEGORY: "web/my-account",
        NAME: "family-sharing/use-asuris-non-medicare-auth-url",
    },
    ACCOUNT__FAMILY_SHARING_USE_ASURIS_MEDICARE_AUTH_URL: {
        CATEGORY: "web/my-account",
        NAME: "family-sharing/use-asuris-medicare-auth-url",
    },
    ACCOUNT__FAMILY_SHARING_USE_REGENCE_NON_MEDICARE_AUTH_URL: {
        CATEGORY: "web/my-account",
        NAME: "family-sharing/use-regence-non-medicare-auth-url",
    },
    ACCOUNT__FAMILY_SHARING_USE_REGENCE_MEDICARE_AUTH_URL: {
        CATEGORY: "web/my-account",
        NAME: "family-sharing/use-regence-medicare-auth-url",
    },
    ACCOUNT_PERSONAL_INFORMATION__SIDEBAR__PRIVACY_POLICY: {
        CATEGORY: "account/personalInformation",
        NAME: "sidebar/privacy-policy",
    },

    MPS_ONLIFE_QUERY_ENABLED: {
        CATEGORY: "feature",
        NAME: "mps-onlife-query-enabled",
    },

    MPS_BENEFIT_PRODUCTID_QUERY_ENABLED: {
        CATEGORY: "feature",
        NAME: "mps-benefit-productid-query-enabled",
    },

    MPS_TEALIUM_DATA_QUERY_ENABLED: {
        CATEGORY: "feature",
        NAME: "mps-tealium-data-query-enabled",
    },

    FOOTER_HINGE_HEALTH: {
        CATEGORY: "footer",
        NAME: "hinge-health",
    },

    FOOTER_SELF_GUIDED_EMOTIONAL_HEALTH: {
        CATEGORY: "web/footer",
        NAME: "self-guided-emotional-health",
    },

    QUALTRICS_SURVEY_ENABLED: {
        CATEGORY: "covered-services/find-care",
        NAME: "survey/qualtrics",
    },
    ESTIMATE_COSTS_MEDICAL_ACTIVE: {
        CATEGORY: "dfis-medical-coverage-state",
        NAME: "active",
    },
    ESTIMATE_COSTS_KYRUUS_REWARDS: {
        CATEGORY: "web/kyruus",
        NAME: "rewards",
    },
    HEALTH_HISTORY_RECORDS_MEDICAL_ACTIVE: {
        CATEGORY: "dfis-medical-coverage-state",
        NAME: "active",
    },

    CVS_CHECK_COST_CARD_LINK: {
        CATEGORY: "web/pharmacy",
        NAME: "check-cost-card/cvs-link",
    },
    CVS_FIND_PHARMACY_CARD_LINK: {
        CATEGORY: "web/pharmacy",
        NAME: "find-pharmacy-card/cvs-link",
    },
    CVS_FIND_CARE_PHARMACY_LINK: {
        CATEGORY: "web/find-care",
        NAME: "cvs-pharmacy-link",
    },
    CVS_RETIRED_CAMPAIGNS_LINK: {
        CATEGORY: "web/campaigns",
        NAME: "retired-campaigns/cvs-link",
    },
    CVS_SORT_OPTIONS: {
        CATEGORY: "web/conditions",
        NAME: "cvs-sort-options",
    },
    CONDITIONS_TREATMENT_PANEL_SHOW_AVG_COST: {
        CATEGORY: "web/conditions",
        NAME: "treatments-panel/show-avg-cost",
    },
    PCOMP_CONDITION_TREATMENT_SHOW_AVG_COST: {
        CATEGORY: "web/conditions",
        NAME: "pcomp-treatment-card/show-avg-cost",
    },
    PHARMACY_CVS: {
        CATEGORY: "fortive",
        NAME: "cvs-pbm",
    },
    PHARMACY_SHOW_PCOMP_MEDICATION_CARD: {
        CATEGORY: "web/pharmacy",
        NAME: "show-pcomp-medication-card",
    },
    PHARMACY_SHOW_FORTIVE_MEDICATION_CARD: {
        CATEGORY: "web/pharmacy",
        NAME: "show-fortive-medication-card",
    },
    PHARMACY_PCOMP_FIND_PHARMACY_CARD_SEARCH: {
        CATEGORY: "web/pharmacy",
        NAME: "pcomp-find-pharmacy-card/pharmacy-search",
    },
    PHARMACY_PCOMP_FIND_PHARMACY_CARD_CVS: {
        CATEGORY: "web/pharmacy",
        NAME: "pcomp-find-pharmacy-card/cvs-link",
    },
    PHARMACY_CVS_HOME_DELIVERY_LINK: {
        CATEGORY: "web/pharmacy",
        NAME: "home-delivery/cvs-link",
    },
    CVS_TIMELINE_YOU_PAID_UNKNOWN: {
        CATEGORY: "web/timeline",
        NAME: "medication/you-paid-unknown",
    },
    BENEFITS_LAYOUT_SHOW_CONTENT: {
        CATEGORY: "web/benefits",
        NAME: "layout-show-benefits",
    },
    TREATMENT_DETAILS_CONNECT_WITH_PHARMACIST: {
        CATEGORY: "web/medications",
        NAME: "treatment-detail/ask-pharmacist-link",
    },
    CAMPAIGNS_SHOW_ASK_PHARMACIST_CARD: {
        CATEGORY: "web/campaigns",
        NAME: "show-ask-pharmacist-card",
    },
    CONDITION_DETAIL_SHOW_CONNECT_PHARMACIST_CARD: {
        CATEGORY: "web/conditions",
        NAME: "show-connect-with-pharmacist-card",
    },
    CONDITION_COMPARE_FETCH_RX_COVERAGE: {
        CATEGORY: "web/conditions",
        NAME: "fetch-rx-coverage",
    },
    BENEFITS_PHARMACY_RESEARCH_MEDICATIONS_CARD: {
        CATEGORY: "web/benefits",
        NAME: "card/research-medications",
    },
    DASHBOARD_INSIGHTS_CARD_RX_ENHANCED: {
        CATEGORY: "web/dashboard",
        NAME: "card/insights-rx",
    },
    HEADER_INSIGHTS_POPOVER_RX_ENHANCED: {
        CATEGORY: "web/header",
        NAME: "popover/insights-rx",
    },
    INSIGHTS_A1C: {
        CATEGORY: "web/insights",
        NAME: "a1c",
    },
    INSIGHTS_DIABETES_EYE_EXAM: {
        CATEGORY: "web/insights",
        NAME: "diabetes-eye-exam",
    },
    INSIGHTS_KIDNEY_HEALTH: {
        CATEGORY: "web/insights",
        NAME: "kidney-health",
    },
    RESEARCH_ASK_PHARMACIST_CARD: {
        CATEGORY: "web/research",
        NAME: "card/ask-pharmacist",
    },
    RESEARCH_CARD_DETAILS_TIER_COVERAGE: {
        CATEGORY: "web/research",
        NAME: "card-details/tier-coverage",
    },
    RESEARCH_CARD_DETAILS_TIER_COVERAGE_RESEARCH: {
        CATEGORY: "web/research",
        NAME: "card-details/tier-coverage-research",
    },
    RESEARCH_CARD_DETAILS_ASK_PHARMACIST_CARD: {
        CATEGORY: "web/research",
        NAME: "card-details/ask-pharmacist",
    },
    RESEARCH_CARD_DETAILS_PHARMACIST_LINK: {
        CATEGORY: "web/research",
        NAME: "card-details/pharmacist-link",
    },
    RESEARCH_CARD_TIER_COVERAGE: {
        CATEGORY: "web/research",
        NAME: "card/tier-coverage",
    },
    PHARMACY_MEDICATION_HISTORY_PAGE: {
        CATEGORY: "web/pharmacy",
        NAME: "page/medication-history",
    },
    PHARMACY_PAGE_RX_ENHANCED: {
        CATEGORY: "web/pharmacy",
        NAME: "page/rx-enhanced",
    },
    PHARMACY_PAGE_PRIME_ENABLED: {
        CATEGORY: "web/pharmacy",
        NAME: "page/prime-enabled",
    },
    PHARMACY_PAGE_MEDICINE_CABINET_CARD: {
        CATEGORY: "web/pharmacy",
        NAME: "card/medicine-cabinet",
    },
    RESOURCE_OMADA: {
        CATEGORY: "resource",
        NAME: "omada",
    },
    RESOURCE_EXPERIAN: {
        CATEGORY: "resource",
        NAME: "experian",
    },
    ONBOARDING__GO_PAPERLESS_SHOW_MEDICARE_CARD: {
        CATEGORY: "web/onboarding",
        NAME: "go-paperless/show-medicare-card",
    },
    ONBOARDING__GO_PAPERLESS_USE_MEDICARE_PAGE_TITLE: {
        CATEGORY: "web/onboarding",
        NAME: "go-paperless/use-medicare-page-title",
    },
    FEATURE_VSP_GRAPH_ENABLED: {
        CATEGORY: "feature",
        NAME: "dfw-vsp-graph-enabled",
    },
    DISCOUNTS_CARD_HEARING_AIDS: {
        CATEGORY: "resource",
        NAME: "discounts/hearing-aids",
    },
    RESOURCE__HEALTH_SUPPORT_PROGRAMS__CARE_MANAGEMENT: {
        CATEGORY: "resource",
        NAME: "health-support-programs/care-management",
    },
    RESOURCE__HEALTH_SUPPORT_PROGRAMS__CONDITION_MANAGER: {
        CATEGORY: "resource",
        NAME: "health-support-programs/condition-manager",
    },
    RESOURCE__HEALTH_SUPPORT_PROGRAMS__PALLIATIVE_CARE: {
        CATEGORY: "resource",
        NAME: "health-support-programs/palliative-care",
    },
    IN_HOME_CARE_CARD_DOCTOR_ON_DEMAND: {
        CATEGORY: "resource",
        NAME: "in-home/doctor-on-demand",
    },
    IN_HOME_CARE_CARD_URGENT_CARE: {
        CATEGORY: "resource",
        NAME: "in-home/urgent-care",
    },
    IN_HOME_CARE_CARD_HEALTH_ASSESSMENT: {
        CATEGORY: "resource",
        NAME: "in-home/health-assessment",
    },
    IN_HOME_CARE_CARD_ADVANTMED: {
        CATEGORY: "resource",
        NAME: "in-home/advantmed",
    },
    RESOURCE_MEDICARE_HEARING_AIDS: {
        CATEGORY: "resource",
        NAME: "medadvantage-hearing-aids",
    },
    RESOURCE_MEDICARE_BENEFITS: {
        CATEGORY: "resource",
        NAME: "medadvantage-benefits",
    },
    DATADOG_RUM_USERID_ENABLED: {
        CATEGORY: "web/datadog",
        NAME: "rum/userid",
    },
    FEATURE_INCONTACT_DFIS_QUERY_ENABLED: {
        CATEGORY: "feature",
        NAME: "incontact-dfis-query-enabled",
    },
    RESOURCE_MA_HEALTHY_REWARDS_PROGRAM: {
        CATEGORY: "resource",
        NAME: "medadvantage-healthy-rewards-program",
    },
    RESOURCE_MA_ALTERNATIVE_MEDICINE_BENEFITS: {
        CATEGORY: "resource",
        NAME: "medadvantage-alternative-medicine-benefits",
    },
    RESOURCE_MEDICARE_PREVENTIVE_CARE: {
        CATEGORY: "resource",
        NAME: "medicare-preventive-care",
    },
    DASHBOARD_MEDICARE_DISCOUNTS_RESOURCES: {
        CATEGORY: "web/dashboard",
        NAME: "card/medicare-resources",
    },
    DASHBOARD_ADVANTAGES_DISCOUNTS: {
        CATEGORY: "web/dashboard",
        NAME: "card/advantages-discounts",
    },
    IS_CLAIMS_SUBMISSION_AVAILABLE: {
        CATEGORY: "member",
        NAME: "claim-submission/available",
    },
    VISION_BENEFITS_V2_ENABLED: {
        CATEGORY: "feature",
        NAME: "dfw-your-benefits-vision-v2-enabled",
    },
    FEATURE_DFW_MRS_ZIPINFO_QUERY_ENABLED: {
        CATEGORY: "feature",
        NAME: "dfw-mrs-zipinfo-query-enabled",
    },
    FIND_CARE_VIRTUAL_CARE_BEHAVIORAL_HEALTH: {
        CATEGORY: "care/find-care",
        NAME: "virtual-care/behavioral-health-link",
    },
    PREGNANCY_RESOURCE_CARD: {
        CATEGORY: "resource",
        NAME: "pregnancy",
    },
    RESOURCE_STRIVE: {
        CATEGORY: "resource",
        NAME: "strive",
    },
    RESOURCE_MA_DIABETES_PREVENTION: {
        CATEGORY: "resource",
        NAME: "medadvantage-diabetes-prevention",
    },
}
