import * as React from "react"
import { SVGProps } from "react"
const SvgFlag = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Flag" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M3 2V22M13 12H3V3H13V12ZM13 12L9 15M13 12V7H19L17 11L19 15H9M13 12H9V15"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgFlag
