/**
 * GraphQL query to fetch service type
 */
export const fetchReimbursementDetailsQuery = () => `
query{
    claimSubmission{
        eftPermission
        reimbursementDetails{
            eft{
                bankName
                accountType
                accountNumber
                routingNumber
                accountHolderName
            }
        }
    }
}`

export const fetchReimbursementOptionsQuery = (suffix: string, claimType:string) => `
query{
    claimSubmission{
        reimbursementDetails{
            options(patientMemberSuffix:"${suffix}",claimType:"${claimType}"){
                isAllowedToChange
                isAllowedToViewAddress
                optionTypes
                availableOptions
                defaultSelectedOption
                hasEFTDetails
            }
        }
    }
}`

export const saveEFTDetailsMutationQuery = `
mutation AddEFTDetails($input: EftDetailsInput!){
    addEFTDetails(input:$input){
        code
        message 
        status
    }
}
`

export const deleteEFTDetailsMutationQuery = `
mutation{
    deleteEFTDetails{
        code,
        status,
        message
    }
}
`
