/**
 * Toggle
 * -----------------------------------------------------------------------------
 * Note: Next.js and Storybook don't like the name `Switch`, not sure
 * why, so we'll just call it `Toggle`. Coolzville?
 */
import React from "react"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props extends React.HTMLAttributes<HTMLDivElement> {
    readonly name: string
    readonly value: boolean
    readonly setValue: (value: boolean) => void
    readonly inverted?: boolean
    readonly disabled?: boolean
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Toggle: React.FC<Props> = props => {
    /**
     * Methods
     */
    const onChange = () => {
        props.setValue(!props.value)
        // TODO: Event analytics
    }

    /**
     * Define template variables
     */
    const { value, inverted, disabled } = props
    const colors = value
        ? inverted
            ? "bg-accent"
            : "bg-primary dark:bg-accent"
        : inverted
        ? "bg-light-200 bg-opacity-50"
        : "bg-gray-100 dark:bg-dark-50"

    const notAllowed = disabled ? "cursor-not-allowed" : ""

    /**
     * Template
     */
    return (
        <button
            className={`h-sm w-xxl p-none rounded-circle relative focus-offset transition-colors duration-200 ${notAllowed} ${colors} ${props.className}`}
            onClick={onChange}
            disabled={disabled}
        >
            <div
                className={`h-xs w-xs rounded-circle shadow absolute top-none transition-transform duration-200 bg-white`}
                style={{
                    transform: `translate(${props.value ? 36 : 4}px, 4px)`,
                }}
            />
        </button>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Toggle
