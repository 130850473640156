import * as React from "react"
import { SVGProps } from "react"
const SvgCheckCircleAlt = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Checkmark inside circle"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M15.5528 4.79443C16.0468 5.04142 16.6474 4.84119 16.8944 4.34721C17.1414 3.85324 16.9412 3.25256 16.4472 3.00557L15.5528 4.79443ZM21.8487 10.6838C21.674 10.1598 21.1077 9.87667 20.5838 10.0513C20.0598 10.226 19.7767 10.7923 19.9513 11.3162L21.8487 10.6838ZM9.70711 10.2929C9.31658 9.90237 8.68342 9.90237 8.29289 10.2929C7.90237 10.6834 7.90237 11.3166 8.29289 11.7071L9.70711 10.2929ZM12 14L11.2929 14.7071C11.6834 15.0976 12.3166 15.0976 12.7071 14.7071L12 14ZM21.7071 5.70711C22.0976 5.31658 22.0976 4.68342 21.7071 4.29289C21.3166 3.90237 20.6834 3.90237 20.2929 4.29289L21.7071 5.70711ZM16.4472 3.00557C15.0927 2.32831 13.5367 2 12 2V4C13.2633 4 14.5073 4.27169 15.5528 4.79443L16.4472 3.00557ZM12 2C6.44772 2 2 6.44772 2 12H4C4 7.55228 7.55228 4 12 4V2ZM2 12C2 17.5523 6.44772 22 12 22V20C7.55228 20 4 16.4477 4 12H2ZM12 22C17.5523 22 22 17.5523 22 12H20C20 16.4477 16.4477 20 12 20V22ZM22 12C22 11.7467 22.0103 11.1687 21.8487 10.6838L19.9513 11.3162C19.9897 11.4313 20 11.6533 20 12H22ZM8.29289 11.7071L11.2929 14.7071L12.7071 13.2929L9.70711 10.2929L8.29289 11.7071ZM12.7071 14.7071L21.7071 5.70711L20.2929 4.29289L11.2929 13.2929L12.7071 14.7071Z"
            fill="currentColor"
        />
    </svg>
)
export default SvgCheckCircleAlt
