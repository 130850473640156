/**
 * Benefits Utilities
 * -----------------------------------------------------------------------------
 *
 */
import {
    BenefitData,
    BenefitServiceResponse,
    BenefitTier,
    BenefitAccumulator,
    BenefitLimit,
} from "./types"

/**
 * Transform `benefits-accumulator-service` data
 * -----------------------------------------------------------------------------
 *
 */
export const transformBenefitsAPIData = (
    data: BenefitServiceResponse,
): BenefitData => {
    return {
        benefits: data?.benefits?.edges?.reduce((acc, { node: benefit }) => {
            return {
                ...acc,
                [benefit.membershipId]: {
                    patientId: benefit.patientId,
                    medical: benefit.medical,
                    pharmacy: benefit.pharmacy,
                    vision: benefit.vision,
                    dental: benefit.dental,
                    hearing: benefit.hearing,
                },
            }
        }, {}),
        benefitsByMembershipId: data?.benefits?.edges?.reduce((acc, { node: benefit }) => {
            return {
                ...acc,
                [benefit.membershipId]: {
                    patientId: benefit.patientId,
                    medical: benefit.medical,
                    pharmacy: benefit.pharmacy,
                    vision: benefit.vision,
                    dental: benefit.dental,
                    hearing: benefit.hearing,
                },
            }
        }, {}),
    }
}

/**
 * Extracts copys from given tiered benefits
 * If tierNumber is `ALL` or not specified
 *  then copays from all tiers are flattened and returned
 *  This is the typical case with 2 tier (In and Out Network) benefits
 * Else
 *  copys from matching tier are returned
 * @param benefitTiers
 * @param tierNumber
 * @returns
 */
export const extractCopays = (
    benefitTiers: ReadonlyArray<BenefitTier>,
    tierNumber = "ALL",
): ReadonlyArray<BenefitAccumulator> => {
    if (tierNumber === "ALL") {
        return benefitTiers.flatMap(
            benefitTier => benefitTier.benefitTierDetails.copays || [],
        )
    } else {
        const benefitTier = benefitTiers.find(
            benefitTier => benefitTier.tierNumber === tierNumber,
        )
        return benefitTier ? benefitTier.benefitTierDetails.copays : []
    }
}

/**
 * Extracts coinsurances from given tiered benefits
 * If tierNumber is `ALL` or not specified
 *  then coinsurances from all tiers are flattened and returned
 *  This is the typical case with 2 tier (In and Out Network) benefits
 * Else
 *  coinsurances from matching tier are returned
 * @param benefitTiers
 * @param tierNumber
 * @returns
 */
export const extractCoinsurances = (
    benefitTiers: ReadonlyArray<BenefitTier>,
    tierNumber = "ALL",
): ReadonlyArray<BenefitAccumulator> => {
    if (tierNumber === "ALL") {
        return benefitTiers.flatMap(
            benefitTier => benefitTier.benefitTierDetails.coinsurances || [],
        )
    } else {
        const benefitTier = benefitTiers.find(
            benefitTier => benefitTier.tierNumber === tierNumber,
        )
        return benefitTier ? benefitTier.benefitTierDetails.coinsurances : []
    }
}

/**
 * Extracts benefitLimits from given tiered benefits
 * If tierNumber is `ALL` or not specified
 *  then benefitLimits from all tiers are flattened and returned
 *  This is the typical case with 2 tier (In and Out Network) benefits
 * Else
 *  benefitLimits from matching tier are returned
 * @param benefitTiers
 * @param tierNumber
 * @returns
 */
export const extractBenefitLimits = (
    benefitTiers: ReadonlyArray<BenefitTier>,
    tierNumber = "ALL",
): ReadonlyArray<BenefitLimit> => {
    if (tierNumber === "ALL") {
        return benefitTiers.flatMap(
            benefitTier => benefitTier.benefitTierDetails.benefitLimits || [],
        )
    } else {
        const benefitTier = benefitTiers.find(
            benefitTier => benefitTier.tierNumber === tierNumber,
        )
        return benefitTier ? benefitTier.benefitTierDetails.benefitLimits : []
    }
}

/** 
 * Filter Benefits
 * @param benefits
 * @param keywords
 * @returns
 */
export const filterBenefits = (
    benefits: ReadonlyArray<BenefitAccumulator>,
    keywords: ReadonlyArray<string>,
): ReadonlyArray<BenefitAccumulator> => {
    return benefits.filter(
        benefit => benefitsByKeyword(benefit, keywords)
    )
}

function benefitsByKeyword(
    benefit: BenefitAccumulator,
    keywords: ReadonlyArray<string>
) {
    let found = false                           // eslint-disable-line
    keywords.map(keyword => {
        if (benefit.memberAccumulatorDescription.toLowerCase()?.includes(keyword.trim())) {
            found = true
        }
    })
    return found
}
