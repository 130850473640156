/**
 * Claim services reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface ClaimService {
    readonly claimServiceType: ReadonlyArray<string>
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<ClaimService> = {
    status: "pristine",
    claimServiceType: [],
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const claimServiceType = createStatefulSlice({
    name: "claimService",
    initialState,
    reducers: {
        receiveClaimService: (
            state: SliceState<ClaimService>,
            action: PayloadAction<ClaimService>,
        ) => {
            return {
                status: "completed",
                claimServiceType: action.payload.claimServiceType || [],
            }
        },
        resetClaimService: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const claimServiceActions = { ...claimServiceType.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default claimServiceType.reducer
