import { getEnvironment } from "./env"
import { store } from "@/store"

export const insertQualtricsScript = () => {
    /**
     * Application state
     */
    const { account } = store.getState()

    /**
     * Methods
     */
    const env = getEnvironment()
    const isDebug = () => !["uat", "prd"].includes(env)
    const debugLog = (...args) => {
        if (isDebug()) {
            // eslint-disable-next-line no-console
            console.log(...args)
        }
    }

    const groupId = account.plan.groupNumber
    const subscriberId = account.plan.memberId
    const suffix = account.plan.suffix?.padStart(2, "0")
    const extRef = groupId + "-" + subscriberId + "-" + suffix

    if (typeof globalThis.QSI === "undefined") {
        // eslint-disable-next-line
        globalThis.QSI = {}
        // eslint-disable-next-line
        globalThis.QSI.config = {
            externalReference: extRef,
        }
    }
    debugLog(
        "[Qualtrics] start loading. External reference:",
        globalThis.QSI.config.externalReference,
    ) // eslint-disable-line

    const fjs = document.getElementsByTagName("script")[0]
    let js = document.getElementsByTagName("script")[0] // eslint-disable-line
    js = document.createElement("script")
    js.type = "text/javascript" // eslint-disable-line

    /* eslint-disable */
    js.innerHTML =
        "  (function(){var g=function(e,h,f,g){\n" +
        '  this.get=function(a){for(var a=a+"=",c=document.cookie.split(";"),b=0,e=c.length;b<e;b++){for(var d=c[b];" "==d.charAt(0);)d=d.substring(1,d.length);if(0==d.indexOf(a))return d.substring(a.length,d.length)}return null};\n' +
        '  this.set=function(a,c){var b="",b=new Date;b.setTime(b.getTime()+6048E5);b="; expires="+b.toGMTString();document.cookie=a+"="+c+b+"; path=/; "};\n' +
        '  this.check=function(){var a=this.get(f);if(a)a=a.split(":");else if(100!=e)"v"==h&&(e=Math.random()>=e/100?0:100),a=[h,e,0],this.set(f,a.join(":"));else return!0;var c=a[1];if(100==c)return!0;switch(a[0]){case "v":return!1;case "r":return c=a[2]%Math.floor(100/c),a[2]++,this.set(f,a.join(":")),!c}return!0};\n' +
        '  this.go=function(){if(this.check()){var a=document.createElement("script");a.type="text/javascript";a.src=g;document.body&&document.body.appendChild(a)}};\n' +
        '  this.start=function(){var t=this;"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",function(){t.go()},!1):window.attachEvent&&window.attachEvent("onload",function(){t.go()}):t.go()};};\n' +
        '  try{(new g(100,"r","QSI_S_ZN_6ETX9GRsDLNtyjI","https://zn6etx9grsdlntyji-regence.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_6ETX9GRsDLNtyjI")).start()}catch(i){}})();\n'
    /* eslint-enable */

    fjs.parentNode.insertBefore(js, fjs)

    // div
    const fdiv = document.getElementsByTagName("div")[0]
    let div_js = document.getElementsByTagName("div")[0] // eslint-disable-line
    div_js = document.createElement("div")
    div_js.id = "ZN_6ETX9GRsDLNtyjI" // eslint-disable-line

    fdiv.parentNode.insertBefore(div_js, fdiv)
}

/**
 * Function that initializes the global data needed for tealium.
 * Will be called:
 * - When app is loaded for first time
 * - When member signed in
 * - When member reloads a page
 * @param isAuthenticated Boolean indicating to init data with authenticated context or not
 */
export const initQualtricsScript = async (isAuthenticated: boolean) => {
    /**
     * When authenticated, load the data needed for Qualtrics
     */
    if (isAuthenticated) {
        insertQualtricsScript()
    }
}
