/**
 * Conditions reducer
 * -----------------------------------------------------------------------------
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { Condition } from "./types"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Conditions {
    // Base props
    readonly isInitialized: boolean
    readonly isLoading: boolean
    readonly isItemInitialized: boolean
    readonly isItemLoading: boolean
    readonly errorMessage?: string

    // Resource props
    readonly items: ReadonlyArray<Condition>
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: Conditions = {
    isInitialized: false,
    isLoading: false,
    isItemInitialized: false,
    isItemLoading: false,
    errorMessage: undefined,
    items: [],
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const conditions = createSlice({
    name: "conditions",
    initialState,
    reducers: {
        updateConditions: (
            state: Conditions,
            action: PayloadAction<Conditions>,
        ) => {
            return {
                ...state,
                ...action.payload,
            }
        },
        resetConditions: () => {
            return initialState
        },
    },
})


/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const conditionsActions = { ...conditions.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default conditions.reducer
