import * as React from "react"
import { SVGProps } from "react"
const SvgQuestionCircle = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Question mark with circle"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11.9976 17.7962C11.9271 17.7962 11.87 17.7389 11.87 17.6682C11.87 17.5975 11.9271 17.5402 11.9976 17.5402"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M11.9975 17.5401C12.068 17.5401 12.1251 17.5974 12.1251 17.6681C12.1251 17.7388 12.068 17.7961 11.9975 17.7961"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M11 14.6002C11 15.1525 11.4477 15.6002 12 15.6002C12.5523 15.6002 13 15.1525 13 14.6002H11ZM8.17693 8.81556C7.96451 9.32537 8.20559 9.91084 8.71539 10.1233C9.22519 10.3357 9.81067 10.0946 10.0231 9.58479L8.17693 8.81556ZM12.4974 13.155L12.3181 12.1712L12.4974 13.155ZM22 12.0002C22 17.523 17.5228 22.0002 12 22.0002V24.0002C18.6274 24.0002 24 18.6276 24 12.0002H22ZM12 22.0002C6.47715 22.0002 2 17.523 2 12.0002H0C0 18.6276 5.37258 24.0002 12 24.0002V22.0002ZM2 12.0002C2 6.47734 6.47715 2.00018 12 2.00018V0.000183105C5.37258 0.000183105 0 5.37277 0 12.0002H2ZM12 2.00018C17.5228 2.00018 22 6.47734 22 12.0002H24C24 5.37277 18.6274 0.000183105 12 0.000183105V2.00018ZM12 8.20018C13.1262 8.20018 14 9.13067 14 10.2002H16C16 8.06969 14.2738 6.20018 12 6.20018V8.20018ZM14 10.2002C14 11.1254 13.2479 12.0017 12.3181 12.1712L12.6767 14.1388C14.5107 13.8045 16 12.138 16 10.2002H14ZM10.0231 9.58479C10.3821 8.72326 11.1395 8.20018 12 8.20018V6.20018C10.2605 6.20018 8.81796 7.2771 8.17693 8.81556L10.0231 9.58479ZM11 13.7002V14.6002H13V13.7002H11ZM12.3181 12.1712C11.6813 12.2873 11 12.837 11 13.7002H13C13 13.8466 12.9376 13.9618 12.8734 14.0297C12.8135 14.0931 12.7443 14.1265 12.6767 14.1388L12.3181 12.1712Z"
            fill="currentColor"
        />
    </svg>
)
export default SvgQuestionCircle
