/* eslint-disable no-console */
/**
 * Claim Submission FAQ Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions } from "store"
import http from "utils/http"
import { transformClaimSubmissionFaq } from "./utils"

/**
 * Get claim submission faq data
 * -----------------------------------------------------------------------------
 */

export const fetchClaimSubmissionFaq = createAsyncThunk(
    "claimSubmissionFaq/fetchClaimSubmissionFaq", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.claimSubmissionFaqStatus("pending"))

        /**
         * Request claim submission faq styx data
         */
        const fragmentId = "gWX7q8JDCEh11xmMyiYGxm"
        const config = { params: { id: fragmentId } }
        const res = await http.get("/api/managed-content-blocks", config).then(({ data }) => {
            if (data && data.length > 0) {
                return data[0]
            }
        })
        
        
        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.claimSubmissionFaqError({
                    response: res,
                }),
            )
        }

        const claimSubmissionFaq = transformClaimSubmissionFaq(res?.data?.tabsAndPanels)
        
        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimSubmissionFaq({
                claimSubmissionFaq: claimSubmissionFaq || [],
            }),
        )
    },
)