import { PharmacistInquirySlice } from "@/store/rx/pharmacist-inquiries/reducer";
import { formatCase } from "@/utils";

export const askAPharmacistMutation = () => {
    return `
    mutation CreatePharmacistInquiry($input: RxCreateAskAPharmacistMutationInput!) {
      createAskAPharmacist(input: $input) {
        jobId
        errors
      }
    }
    `
}

export const getQueryVariables = (aapParams: PharmacistInquirySlice) => {
    const formattedMsg = formatAAPMessage(aapParams)
    return {
        input: {
            message: formattedMsg,
            subject: aapParams.topic?.topicText,
            useUnencryptedEmail: stringToBoolean(aapParams.useUnencryptedEmail),
            email: aapParams.email,
            referer: aapParams.referer,
        }
    }
}

const stringToBoolean = (str):boolean => {
    return str === "true" || str === 1 || str === "1";
}

const formatAAPMessage = (slice: PharmacistInquirySlice): string => {
    return `Topic: ${ slice.topic?.topicText }
        Asking about: ${ slice.selectedSubjectName }
        Asking about ID: ${ slice.selectedSubjectId }
        Email: ${ slice.email }
        Treatments: ${ formatMedicationsList(slice.selectedMedications) }
        Write-in Condition(s): ${ getNAString(slice.writeInCondition) }
        Additional OTC meds: ${ getNAString(slice.additionalOTCMeds) }
        Anything else we should know?: ${ getNAString(slice.additionalInfo) }
        Selected Condition: ${ getNAString(slice.selectedCondition) }
        Stated Effectiveness: ${(slice.effectiveness) ? formatCase(slice.effectiveness.replace(/-/g, " "), "titlecase") 
        : "Not Specified"}
        `
}

const getNAString= (element: string): string => {
    if(element === undefined || element === null || element === "") {
        return "Not Specified"
    }
    return element
}

const formatMedicationsList = (medications): string => {
    if (!medications) return "Not Specified"
    return medications.map((med) => `${ med.displayName }`).join(",")
}