/**
 * TaxForm reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./action"
import { TaxFaq } from "./types"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface TaxFaqs {
    readonly taxFormsFaq: ReadonlyArray<TaxFaq>
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<TaxFaqs> = {
    status: "pristine",
    taxFormsFaq: [],
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const taxFormsFaq = createStatefulSlice({
    name: "taxFormsFaq",
    initialState,
    reducers: {
        receiveTaxFormsFaq: (
            state: SliceState<TaxFaqs>,
            action: PayloadAction<TaxFaqs>,
        ) => {
            return {
                status: "completed",
                taxFormsFaq: action.payload.taxFormsFaq || [],
            }
        },
        resetTaxFormsFaq: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const taxFaqActions = { ...taxFormsFaq.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default taxFormsFaq.reducer