/**
 * Alert
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Button, Icon, IconTypes } from "elements"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props extends React.HTMLAttributes<HTMLDivElement> {
    readonly name: string
    readonly variant: "default" | "success" | "info" | "warning" | "error" | "notAllowed" | "banner"
    readonly children: React.ReactNode
    readonly hideIcon?: boolean
    readonly onDismiss?: VoidFunction
    readonly childrenClassName?: string
    readonly alignItems?: "center" | "start" | "end" | "stretch"
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Alert: React.FC<Props> = props => {
    /**
     * Component state
     */
    const [dismissed, setDismissed] = React.useState(false)

    /**
     * Methods
     */
    const onDismiss = () => {
        if (props.onDismiss) {
            props.onDismiss()
            setDismissed(true)
        }
    }

    /**
     * Define template variables
     */
    const variants: {
        readonly [key: string]: {
            readonly iconType?: IconTypes
            readonly iconClass: string
            readonly bgClass: string
            readonly borderClass: string
        }
    } = {
        default: {
            iconType: "question-circle",
            iconClass: "text-gray-300 dark:text-gray-200",
            bgClass: "bg-light-50 dark:bg-dark-100",
            borderClass: "border-light-200 dark:border-dark-50",
        },
        success: {
            iconType: "check-circle",
            iconClass: "text-success-vivid dark:text-success-100",
            bgClass: "bg-success-50 dark:bg-success-300",
            borderClass: "border-success",
        },
        info: {
            iconType: "flag",
            iconClass: "text-info-vivid dark:text-info-100",
            bgClass: "bg-info-50 dark:bg-info-300",
            borderClass: "border-info",
        },
        globalInfo: {
            iconType: "lightbulb",
            iconClass: "text-info-vivid dark:text-info-100",
            bgClass: "bg-info-50 dark:bg-info-300",
            borderClass: "border-info",
        },        
        banner: {
            iconType: "money-circle",
            iconClass: "text-info-vivid dark:text-info-100",
            bgClass: "bg-info-50 dark:bg-info-300",
            borderClass: "border-info",
        },
        brand: {
            iconType: "lightbulb",
            iconClass: "text-info-vivid dark:text-info-100",
            bgClass: "bg-info-50 dark:bg-info-300",
            borderClass: "border-info",
        },
        warning: {
            iconType: "warning",
            iconClass: "text-warning-vivid dark:text-warning-100",
            bgClass: "bg-warning-50 dark:bg-warning-300",
            borderClass: "border-warning",
        },
        error: {
            iconType: "x-circle",
            iconClass: "text-error-vivid dark:text-error-100",
            bgClass: "bg-error-50 dark:bg-error-300",
            borderClass: "border-error",
        },
        notAllowed: {
            iconType: "no-symbol",
            iconClass: "text-warning-300 dark:text-warning-100",
            bgClass: "bg-warning-50 dark:bg-warning-300",
            borderClass: "border-warning",
        },
    }
    const values = variants[props.variant]
    const alignItems = props.alignItems ? props.alignItems : "center"

    /**
     * Template
     */
    return dismissed ? null : (
        <div
            className={`flex items-${alignItems} shadow-sm ${values.bgClass} ${values.borderClass} py-xs px-sm rounded-sm border-b-xxl ${props.className}`}
            data-test={props.name}
        >
            {values.iconType && !props.hideIcon && (
                <div className={`mr-xs ${values.iconClass}`}>
                    <Icon
                        type={values.iconType}
                        className="transform -translate-y-two"
                    />
                </div>
            )}
            <div className={props.childrenClassName}>{props.children}</div>
            {props.onDismiss && (
                <div className="flex-auto text-right ml-sm -mr-xxxs">
                    <Button
                        name={props.name + "-dismiss-button"}
                        icon="x"
                        size="small"
                        variant="link"
                        onClick={onDismiss}
                        className={values.iconClass}
                    />
                </div>
            )}
        </div>
    )
}
/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Alert
