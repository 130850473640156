/**
 * Checkbox
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Icon } from "elements"
import { tagEvent, LinkEvent } from "@/utils/analytics"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props extends React.HTMLAttributes<HTMLDivElement> {
    readonly name: string
    readonly value: boolean
    readonly setValue?: (value: boolean) => void
    readonly onClick?: VoidFunction
    readonly label?: string
    readonly inverted?: boolean
    readonly customStyles?: string
    readonly reverse?: boolean
    readonly border?: boolean
    readonly analytics?: Partial<LinkEvent>
    readonly dataAnalyticsId?: Record<string, string>
    readonly borderError?: boolean
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Checkbox: React.FC<Props> = props => {
    /**
     * Refs
     */
    const ref = React.useRef<HTMLInputElement>(null)

    /**
     * Methods
     */
    const handleOnChange = (e: React.FormEvent<HTMLInputElement>) => {
        if (e.currentTarget.type === "checkbox") {
            // Update value
            props.setValue(e.currentTarget.checked)

            // Analytics
            handleAnalytics()
        }
    }

    const toggleHiddenInput = () => {
        ref.current.checked = !ref.current.checked // eslint-disable-line

        // Analytics
        handleAnalytics()

        // For ActionList and other "controlled" elements
        if (props.onClick) props.onClick()

        // required for FilterByCard
        if (props.setValue) {
            props.setValue(ref.current.checked)
            if (props.dataAnalyticsId) {
                if (ref.current.checked) {
                    // TODO: Replace instances using `dataAnalyticsId`
                    tagEvent({
                        tealium_event: "link",
                        data_analytics_id: `checked-${props.dataAnalyticsId.checked}`,
                        link_text: props.dataAnalyticsId.checked,
                        link_url: "",
                    })
                } else {
                    tagEvent({
                        tealium_event: "link",
                        data_analytics_id: `unchecked-${props.dataAnalyticsId.checked}`,
                        link_text: props.dataAnalyticsId.unchecked,
                        link_url: "",
                    })
                }
            }
        }
    }

    const handleAnalytics = () => {
        // Analytics
        const value = ref.current.checked ? "checked" : "unchecked"

        tagEvent({
            tealium_event: "link",
            data_analytics_id: props.name + "-" + value,
            link_text: props.label || "",
            link_url: "",
            ...props.analytics,
        })
    }

    /**
     * Template
     */
    return (
        <div
            className={`${props.border
                ? "border-t border-light-100 dark:border-dark-50"
                : ""
                } min-w-[24px] min-h-[24px]`}
        >
            <label htmlFor={props.name}>
                {/* Native checkbox (hidden) */}
                <div
                    className={
                        props.reverse
                            ? "flex flex-row-reverse justify-between items-center py-xs"
                            : "inline"
                    }
                >
                    <div className={props.reverse ? "" : "relative"}>
                        <input
                            ref={ref}
                            type="checkbox"
                            id={props.name}
                            name={props.name}
                            checked={props.value}
                            onChange={handleOnChange}
                            data-test={props.name}
                            className="opacity-0 absolute top-none left-none"
                            tabIndex={-1}
                        />

                        {/* Custom checkbox */}
                        <button
                            onClick={toggleHiddenInput}
                            className={`w-sm h-sm  rounded-two text-transparent sibling-checked:text-white 
                            ${props.borderError
                                    ? "border border-error-200"
                                    : "border border-light-200"
                                } 
                            ${props.inverted
                                    ? "bg-transparent dark:bg-transparent sibling-checked:bg-accent sibling-checked:border-accent"
                                    : "bg-white dark:bg-dark-200 sibling-checked:bg-primary dark:sibling-checked:bg-accent sibling-checked:border-primary dark:sibling-checked:border-accent"
                                } ${props.inverted
                                    ? "focus-offset-inverted"
                                    : "focus-offset"
                                }${props.reverse
                                    ? ""
                                    : " absolute top-none left-none"
                                }`}
                            tabIndex={props.tabIndex}
                            type="button" // prevent from submitting forms :)
                        >
                            <Icon
                                type="check"
                                size={24}
                                className="transform -translate-x-one"
                            />
                        </button>
                    </div>
                    {/* Label (Optional) */}
                    {props.label && (
                        <div
                            className={
                                props.customStyles !== undefined
                                    ? props.customStyles
                                    : `text-base normal-case tracking-normal select-none inline ${props.reverse ? "mr-xs" : "ml-lg"
                                    } ${props.inverted ? "text-white" : ""}`
                            }
                        >
                            {props.label}
                        </div>
                    )}
                </div>
            </label>
        </div>
    )
}

/**
 * Export Component
 * -----------------------------------------------------------------------------
 */
export default Checkbox
