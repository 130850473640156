/* eslint-disable no-console */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions } from "store"
import http from "utils/http"
import { fetchDuplicateClaimValidationQuery } from "./queries"

/**
 * Get claim date validation service
 * -----------------------------------------------------------------------------
 */

export const fetchDuplicateClaimValidation = createAsyncThunk(
    "duplicateClaimValidationService/fetchDuplicateClaimValidationService", // Reducer name
    async (
        payloadValue,
        { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.duplicateClaimValidationServiceStatus("pending"))
        /**
         * Request claim date validation service
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            fetchDuplicateClaimValidationQuery(
                payloadValue
            )
        )
        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.duplicateClaimValidationServiceError({
                    response: res,
                }),
            )
        }
        
        const duplicateClaimValidationResponse = res.data?.data?.claimSubmission
        /**
         * Update state
         */
        dispatch(
            actions.receiveDuplicateClaimValidationService({
                duplicateClaimValidation: duplicateClaimValidationResponse || {
                    isDuplicateClaim: true
                },
            }),
        )
    },
)

