/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable functional/immutable-data */
import React from "react"
import { Markdown } from "@/elements"
import { Button, Card } from "heartwood-component-library"

/**
 * Props that the react-error-boundary passes to a FallbackComponent
 * see https://www.npmjs.com/package/react-error-boundary
 */
interface Props {
    readonly error?: any
    readonly resetErrorBoundary?: () => any
}

/**
 * Use this as FallbackComponent with react-error-boundary, ie:
 *
 * <ErrorBoundary FallbackComponent={ErrorFallback}>
 *     <MyComponent>
 *         ...
 *     </MyComponent>
 * </ErrorBoundary>
 */
export const ErrorFallback: React.FC<Props> = ({
    error,
    /**
     * If not provided (used outside of react-error-boundary component), defaults to full page refresh
     */
    resetErrorBoundary,
}: Props) => {
    if (!resetErrorBoundary) {
        /* eslint-disable functional/immutable-data */
        resetErrorBoundary = () => {
            window.location.reload()
        }
    }

    return (
        <React.Fragment>
            <div className="mt-lg flex items-center justify-center">
                <div className="w-[640px]">
                    <Card
                        name="sign-in-error-card"
                        radius="sm"
                        className="flex-initial shadow-none md:shadow-md bg-transparent md:bg-white"
                    >
                        <h4>Something went wrong</h4>
                        <Markdown content={"An Application Error occurred."} />
                        <Button
                            name="reset-error-link"
                            label="Try again"
                            variant="primary"
                            className="px-xs"
                            onClick={resetErrorBoundary}
                        />
                    </Card>
                </div>
            </div>
        </React.Fragment>
    ) as any
}

interface ErrorBoundaryProps {
    readonly children: any
}

interface ErrorBoundaryState {
    readonly hasError: boolean
    readonly error: any
}

/**
 * This is a raw ErrorBoundary component.
 * Please prefer to use react-error-boundary's ErrorBoundary component instead
 *
 * Most likely we will remove this. Just want to experiment/observe in DEV/UAT first.
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    readonly state = { hasError: false, error: null }

    static getDerivedStateFromError(error) {
        return { hasError: true, error }
    }

    componentDidCatch(error, info) {
        error.message = "ErrorBoundary: " + error.message
        error.stack = "ErrorBoundary: " + error.stack
        console.error(error, info)

        // potentially allow the default error boundary to handle this, after we log it. not sure how stable this is.
        // throw error
    }

    render() {
        if (this.state.hasError) {
            return <ErrorFallback error={this.state.error} />
        }
        return this.props.children
    }
}
