/**
 * GraphQL query to fetch member cards
 */
export const fileUploadParamsQuery = `
query{
    claimSubmission{
      fileUplaodParams{
        maxClaimsFileuploads
        maxTotalClaimsUploadSize
      }
    }
}
`
