export const conditionsQuery = `
query rxConditions {
  rxConditions{
    nodes {
      id
      name
      fullName
    }
  }
}
`

export const childrenOfConditionQuery = (parentConditionId: string) => {
    return `
    query childrenOfCondition {
      rxConditionChildren(parentConditionId: ${parentConditionId}) {
        id
        name
        fullName
        ancestry
      }
    }
`
}

export const conditionByIdQuery = function (conditionId: string) {
    return `
    query rxConditions {
      rxCondition(id: ${conditionId}) {
        id
        fullName
        name,
        ancestry,
        description,
        visibleState,
        modelStringSynonyms,
        rxConditionTreatments {
          totalCount
          edges {
            node {
              id,
              score,
              grade,
              clinicalSummary,
              effectiveness,
              safety,
              rxTreatment {
                id,
                primaryName,
                secondaryName,
                cost,
                costFrequency,
                costDescription,             
                commonSideEffectList,
                severeSideEffectList,
                deliveryMethodName,
                treatmentCategoryName,
                treatmentCategoryDesc,
                referenceNdc
              }
            }
          }
        }
        rxTreatments {
          totalCount
          edges {
            node {
              id,
              primaryName,
              secondaryName,
              cost,
              costFrequency,
              deliveryMethodName,
              treatmentCategoryName,
              treatmentCategoryDesc,
              referenceNdc
            }
          }
        }
      }
    }
  `
}
