import * as React from "react"
import { SVGProps } from "react"
const SvgPlant = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Plant" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M12.0039 11.9264C12.0039 11.9264 14.6753 9.70417 14.6753 6.96373C14.6753 4.2224 12.0039 2.00018 12.0039 2.00018C12.0039 2.00018 9.33337 4.22329 9.33337 6.96373C9.33337 9.70417 12.0039 11.9264 12.0039 11.9264ZM12.0039 11.9264L12.0041 22.0002M11.9995 22.0002C11.9995 22.0002 16.5131 22.0419 19.2752 19.2748C22.0363 16.5193 21.9999 11.9984 21.9999 11.9984C21.9999 11.9984 17.4818 11.9557 14.7198 14.7184C11.9577 17.481 11.9995 22.0002 11.9995 22.0002ZM11.9995 22.0002C11.9995 22.0002 7.48141 22.0419 4.71936 19.2748C1.95732 16.5193 1.99998 11.9984 1.99998 11.9984C1.99998 11.9984 6.51718 11.9557 9.27923 14.7184C12.0413 17.481 11.9995 22.0002 11.9995 22.0002Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgPlant
