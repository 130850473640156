import * as React from "react"
import { SVGProps } from "react"
const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Download" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M22 17C22 16.4477 21.5523 16 21 16C20.4477 16 20 16.4477 20 17H22ZM4 17C4 16.4477 3.55228 16 3 16C2.44772 16 2 16.4477 2 17H4ZM16.7071 12.2071C17.0976 11.8166 17.0976 11.1834 16.7071 10.7929C16.3166 10.4024 15.6834 10.4024 15.2929 10.7929L16.7071 12.2071ZM12 15.5L11.2929 16.2071C11.6834 16.5976 12.3166 16.5976 12.7071 16.2071L12 15.5ZM8.70711 10.7929C8.31658 10.4024 7.68342 10.4024 7.29289 10.7929C6.90237 11.1834 6.90237 11.8166 7.29289 12.2071L8.70711 10.7929ZM11 15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15H11ZM13 2C13 1.44772 12.5523 1 12 1C11.4477 1 11 1.44772 11 2H13ZM20 17V20.6H22V17H20ZM20 20.6C20 21.3477 19.3477 22 18.6 22V24C20.4523 24 22 22.4523 22 20.6H20ZM18.6 22H5.4V24H18.6V22ZM5.4 22C4.65228 22 4 21.3477 4 20.6H2C2 22.4523 3.54772 24 5.4 24V22ZM4 20.6V17H2V20.6H4ZM15.2929 10.7929L11.2929 14.7929L12.7071 16.2071L16.7071 12.2071L15.2929 10.7929ZM12.7071 14.7929L8.70711 10.7929L7.29289 12.2071L11.2929 16.2071L12.7071 14.7929ZM13 15V2H11V15H13Z"
            fill="currentColor"
        />
    </svg>
)
export default SvgDownload
