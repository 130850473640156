import * as React from "react"
import { SVGProps } from "react"
const SvgInhaler = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Inhaler" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M15.2273 6.05688L16.5292 3L21.512 5.21297L19.5 9.98171M6.3 15.5V14.3699H10.0432L14.1386 5.09091L20.1061 10.5975L15.8937 21.1212H6.3V20M6.3 15.5H3V20H6.3M6.3 15.5V20"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgInhaler
