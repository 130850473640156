import * as React from "react"
import { SVGProps } from "react"
const SvgCameraPlus = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Camera with plus sign"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M20 12V7C20 5.9 19.1 5 18 5H15.5C14.7 5 14 4.3 14 3.5C14 2.7 13.3 2 12.5 2H8.5C7.7 2 7 2.7 7 3.5C7 4.3 6.3 5 5.5 5H3C1.9 5 1 5.9 1 7V17C1 18.1 1.9 19 3 19H13M20 16V19M20 19V22M20 19H23M20 19H17M14 11.5C14 13.433 12.433 15 10.5 15C8.567 15 7 13.433 7 11.5C7 9.567 8.567 8 10.5 8C12.433 8 14 9.567 14 11.5Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgCameraPlus
