/**
 * Claims reducer
 * -----------------------------------------------------------------------------
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { Claim } from "./types"
import { format } from "date-fns"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Claims {
    // Base props
    readonly isInitialized: boolean
    readonly isLoading: boolean
    readonly errorMessage?: string

    // Claims props
    readonly items: ReadonlyArray<Claim>
    readonly claimFilters: ClaimFilters
}

interface ClaimFilters {
    // Search by claim number
    readonly claimNumber: string
     // Sort by date/provider
    readonly sortBy: string
    // Family member
    readonly members: ReadonlyArray<string>
    // Datepicker
    readonly startRange: string
    readonly endRange: string
    // Categories
    readonly medical: boolean
    readonly pharmacy: boolean
    readonly dental: boolean
    // Providers
    readonly providers: ReadonlyArray<string>
    // Status
    readonly completed: boolean
    readonly pending: boolean
    readonly adjusted: boolean
    // Accordion opened states
    readonly sortByOpened: boolean
    readonly memberOpened: boolean
    readonly dateOpened: boolean
    readonly categoryOpened: boolean
    readonly providerOpened: boolean
    readonly statusOpened: boolean
}

/**
 * Initial states
 * -----------------------------------------------------------------------------
 */
export const initialState: Claims = {
    isInitialized: false,
    isLoading: false,
    errorMessage: undefined,
    items: [],
    claimFilters: { 
        claimNumber: "",
        sortBy: "date",
        members: [],
        startRange: "",
        endRange: format(new Date(), "MM/dd/yyyy"),
        medical: false,
        pharmacy: false,
        dental: false,
        providers: [],
        completed: false,
        pending: false,
        adjusted: false,
        sortByOpened: true,
        memberOpened: false,
        dateOpened: false,
        categoryOpened: false,
        providerOpened: false,
        statusOpened: false,
    }
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const claims = createSlice({
    name: "claims",
    initialState,
    reducers: {
        receiveClaims: (state: Claims, action: PayloadAction<Claims>) => {
            return action.payload
        },
        resetClaims: () => {
            return initialState
        },
        receiveClaimFilters: (state: Claims, action: PayloadAction<ClaimFilters>) => {
            return {
                ...state,
                claimFilters: action.payload
            }
        },
        resetClaimFilters: (state: Claims, action: PayloadAction<ClaimFilters>) => {
            return {
                ...state,
                claimFilters: {
                    claimNumber: "",
                    sortBy: "date",
                    members: [],
                    startRange: "",
                    endRange: format(new Date(), "MM/dd/yyyy"),
                    medical: false,
                    pharmacy: false,
                    dental: false,
                    providers: [],
                    completed: false,
                    pending: false,
                    adjusted: false,
                    sortByOpened: true,
                    memberOpened: false,
                    dateOpened: false,
                    categoryOpened: false,
                    providerOpened: false,
                    statusOpened: false,
                }
            }
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const claimsActions = { ...claims.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default claims.reducer
