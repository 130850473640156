import * as React from "react"
import { SVGProps } from "react"
const SvgApple = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Apple" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M11.9984 21.2893C11.7695 21.2893 11.544 21.3399 11.3404 21.4371L9.42443 22.3434C9.18518 22.4663 8.77202 22.5188 8.5 22.494C5.77006 22.1556 3.92955 18.494 3.16557 14.1529C2.48359 10.2495 3.71856 3.91813 10.4654 7.62066C11.1566 8 11.4723 8.53949 12 8.5C12.5277 8.53949 12.8434 8 13.5346 7.62066C20.2814 3.91813 21.5164 10.2495 20.8344 14.1529C20.0704 18.494 18.2299 22.1556 15.5 22.494C15.228 22.5188 14.8148 22.4663 14.5756 22.3434L12.6596 21.4371C12.456 21.3399 12.2305 21.2893 12.0016 21.2893M13.8837 4.65932C13.857 5.63705 13.5497 6.58647 12.9984 7.39441C12.9208 7.54877 12.8015 7.6783 12.6541 7.76828C12.5066 7.85826 12.3368 7.9051 12.1641 7.90348C11.9914 7.90186 11.8225 7.85185 11.6768 7.75911C11.531 7.66638 11.4142 7.53464 11.3395 7.37885C10.7748 6.56783 10.4503 5.61395 10.4033 4.62679C10.4289 3.64876 10.736 2.69887 11.2879 1.891C11.3657 1.73695 11.4851 1.60775 11.6325 1.51801C11.78 1.42827 11.9496 1.38157 12.1222 1.38319C12.2948 1.38481 12.4634 1.43468 12.6092 1.52717C12.7549 1.61966 12.8718 1.75107 12.9468 1.90655C13.5113 2.71797 13.836 3.67196 13.8837 4.65932Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgApple
