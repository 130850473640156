/**
 * Transform digital-first-information-service response for Redux
 * -----------------------------------------------------------------------------
 */

import { Coverage } from "./types"

export const transformCoverageApiData = (
    cers: ReadonlyArray<Coverage>,
): ReadonlyArray<Coverage> => {
    const transformedCers = cers.map(cer => ({...cer, ndc: cer.ndc?.replace(/-/g, "")}))
    return transformedCers
}

export const transformRxCoverageApiData = (
    cers: ReadonlyArray<Coverage>,
    membershipId: string,
): ReadonlyArray<Coverage> => {
    const transformedCers = cers.map(cer => ({...cer, ndc: cer.ndc?.replace(/-/g, ""), membershipId: membershipId}))
    return transformedCers
}
