import * as React from "react"
import { SVGProps } from "react"
const SvgLightbulb = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Lightbulb"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M8.99999 18H15M15 17.1V19.9C15 21.5 13.8 23 12.2 23.1C10.4 23.1 8.99999 21.7 8.99999 20V16.9C8.99999 15.5 8.49999 14.2 7.39999 13.2C5.89999 12 4.89999 10 4.99999 7.80002C5.09999 4.10002 8.19999 1.10002 11.8 1.00002C15.8 0.900024 19 4.10002 19 8.00002C19 10.1 18.1 12 16.6 13.3C15.5 14.2 15 15.6 15 17.1Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgLightbulb
