import { transformConditionTreatmentsApiData } from "../condition-treatments/utils"
import { transformTreatmentsApiData } from "../treatments/utils"
import { Condition } from "./types"

export const transformConditionWithChildren = (
    condition: Condition,
    childConditions: ReadonlyArray<Condition>,
): Condition => {
    return {
        ...condition,
        childConditions: childConditions,
    }
}
export const transformConditionsApiData = (
    conditions: ReadonlyArray<any>, // eslint-disable-line
): ReadonlyArray<Condition> => {
    return conditions?.map(condition => {
        const item = condition.node || condition
        return transformConditionApiData(item)
    })
}

export const transformConditionApiData = (
    condition: any, // eslint-disable-line
): Condition => {
    return {
        id: condition.id,
        fullName: condition.fullName,
        name: condition.name,
        ancestry: condition.ancestry,
        description: condition.description,
        visibleState: condition.visibleState,
        modelStringSynonyms: condition.modelStringSynonyms,
        rxConditionTreatments: condition.rxConditionTreatments?.edges
            ? transformConditionTreatmentsApiData(
                condition.rxConditionTreatments?.edges,
            )
            : undefined,
        rxTreatments: condition.rxTreatments?.edges
            ? transformTreatmentsApiData(condition.rxTreatments?.edges)
            : undefined,
    }
}
