import * as React from "react"
import { SVGProps } from "react"
const SvgFacility = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Building" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M18 20V16H12V20M6 8H8M6 12H8M6 16H8M12 8H14M12 12H14M18 3H2V21H22V12H18V3Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgFacility
