import * as React from "react"
import { SVGProps } from "react"
const SvgArrowPath = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Arrow pointing in a circle"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M21.9984 4.03766V10.2599M21.9984 10.2599H15.9984M21.9984 10.2599L17.3687 5.7384C16.4187 4.75217 15.2677 4.00032 14.0007 3.53573C9.31372 1.8184 4.17272 4.3664 2.51672 9.22699C0.860718 14.0865 3.31772 19.419 8.00472 21.1363C12.6907 22.8537 17.8327 20.3057 19.4887 15.4451"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgArrowPath
