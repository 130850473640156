/**
 * Session reducer
 * -----------------------------------------------------------------------------
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Session {
    readonly isInitialized: boolean
    readonly isLoading: boolean
    readonly errorMessage?: string
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: Session = {
    isInitialized: false,
    isLoading: false,
    errorMessage: undefined,
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const session = createSlice({
    name: "session",
    initialState,
    reducers: {
        receiveSession: (state: Session, action: PayloadAction<Session>) => {
            return action.payload
        },
        resetSession: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const sessionActions = { ...session.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default session.reducer
