/**
 * Application Strings
 * -----------------------------------------------------------------------------
 *
 * Docs:
 * Plurals - https://www.i18next.com/translation-function/plurals
 * Interpolation - https://www.i18next.com/translation-function/interpolation
 * Context - https://www.i18next.com/translation-function/context
 */

/**
 * English
 * -----------------------------------------------------------------------------
 */
export const en = {
    common: {
        yes: "Yes",
        no: "No",
        city: "City",
        state: "State",
        zipCode: "ZIP Code",
        cityError: "City is required.",
        stateError: "State is required.",
        zipCodeError: "ZIP code is required.",
        zipCodeLengthError: "ZIP code must be a 5 digit number.",
        phoneContact: `<p>Make sure you have your Member ID card handy when you call us.</p><p>Mon. - Fri., 5:00 a.m. - 8:00 p.m. PT.<br />Sat. 8:00 a.m. - 4:30 p.m. PT.</p><strong><a href="tel:18882325763">1 (888) 232-5763, TTY: 771</a></strong>`,
        renewalBanner: {
            renewalImportantNotice: "Important member notice",
            renewalPlanDiscontinueTitle: "Your plan is being discontinued",
            renewalPlanChangingTitle: "We're renewing your coverage",
            shopBcbsLabel: "Shop BlueCross BlueShield plans",
            shopMarketplaceLabel: "Shop Marketplace plans",
        },
    },
    dashboard: {
        title: "Welcome to your plan",
        dashboardlight: {
            quickActions: {
                title: "New quick links to important tools",
                content:
                    "Access the most commonly used health plan tools, now conveniently listed across the top of the page.",
            },
            annualCosts: {
                title: "Cost overview at a glance",
                content:
                    "Keep an eye on your health care spending throughout the year with new deductible and out-of-pocket cost breakdowns.",
            },
            quickAccess: {
                title: "Care options when you need them",
                content:
                    "Save time on your health care journey by accessing all of your care options directly from the home page.",
            },
            modal: {
                title: "Hi",
                content: {
                    title: "Designed with you in mind",
                    body: "We know it's important to get the most out of your health plan, that's why we redesigned our home page with you in mind. Enjoy improved navigation, a comprehensive cost overview and access to care all in one place.",
                },
            },
            button: {
                dismiss: {
                    label: "Dismiss",
                },
                confirm: {
                    label: "See what's changed",
                },
            },
        },
        navigationSpotlight: {
            claimNavigation: {
                title: "Faster access to important features",
                content: "Now locate all of your claims with ease.",
            },
            pharmacyNavigation: {
                title: "Pharmacy tools all in one place",
                content:
                    "See your medication history, pharmacy claims, cost-saving resources and more.",
            },
            careNavigation: {
                title: "Your care options at a glance",
                content:
                    "Review your care history and explore additional resources included in your health plan.",
            },
            modal: {
                title: "Hi",
                content: {
                    title: "Navigating your health plan just got easier",
                    body: "We've improved our website's navigation so you can quickly find plan information and resources when you need them.",
                },
            },
            button: {
                dismiss: {
                    label: "Dismiss",
                },
                confirm: {
                    label: "See what's changed",
                },
            },
        },
        quickActions: {
            default: {
                benefits: {
                    title: "View my benefits",
                    subtitle: "See what your plan covers",
                },
                doctor: {
                    title: "Find a doctor",
                    subtitle: "Look for providers in your network",
                    subtitleMedigap: "Look for providers",
                },
                memberId: {
                    title: "View my member ID",
                    subtitle: "View and order member ID cards",
                },
                messages: {
                    title: "Message center",
                    subtitle: "View secure messages from {{capitalizedBrand}}",
                },
            },
            v2: {
                findCare: {
                    label: "Get care now or find a provider",
                },
                memberId: {
                    label: "View member ID card",
                },
                benefits: {
                    label: "See my benefits",
                },
                eob: {
                    label: "View EOB statements",
                },
            },
            regence: "$t(dashboard.quickActions.default)",
            asuris: "$t(dashboard.quickActions.default)",
            bridgespan: {
                benefits: {
                    title: "See my benefits",
                    subtitle: "Know what your plan covers",
                },
                doctor: {
                    title: "Find a doctor",
                    subtitle: "Look for in-network providers",
                    subtitleMedigap: "Look for providers",
                },
                memberId: {
                    title: "View member ID card",
                    subtitle: "See a digital version of your card",
                },
                messages: {
                    title: "Read messages",
                    subtitle: "Check your secure inbox",
                },
            },
        },
        premiumPaymentBanner: {
            titles: {
                paymentDue: "Pay your premium",
                paymentPending: "Payment pending",
                premiumPayment: "Premium payment",
                paymentPastDue: "Premium is past due please pay now",
                coverageTerminated: "Coverage has ended",
                socialSecurity: "Current premium",
            },
            messages: {
                coverageWasTerminated: "Your plan is no longer active",
                paymentDisclaimer:
                    "If you've recently made a payment, it may take up to 48 hours to be updated",
                customerService: "Please contact customer service",
                willBeTermed:
                    "Coverage will be terminated unless payment is received",
                invoicePending: "Invoice is pending",
                autopay: "Automatic payment of ",
                paymentPending: "You paid on ",
                socialSecurity:
                    "Your monthly premium is processed via social security",
                checkBalance:
                    "To check your balance please see your mailed invoice or contact customer service with any further questions.",
            },
        },
        claims: {
            card: {
                title: "Recent claims",
                viewMoreButton: {
                    label: "View more",
                },
            },
            emptyState: {
                label: "Claims submitted by your provider will appear here",
            },
        },
        pharmacyClaims: {
            card: {
                title: "Recent prescription claims",
                viewMoreButton: {
                    label: "See all claims",
                },
            },
            emptyState: {
                label: "You have no pharmacy claims. If you've filled a prescription recently, it may take up to 48 hours to appear here.",
            },
        },
        expressScript: {
            header: "Medications",
            title: "Express Scripts",
            description:
                "Review your pharmacy benefits on the ExpressScripts website. See what's covered, check drug costs, or find a pharmacy near you.",
        },
        medications: {
            card: {
                title: "Medications",
            },
            currentEmptyState: {
                label: "Your current medications will appear here",
            },
            pastEmptyState: {
                label: "Your past medications will appear here",
            },
        },
        recentMedications: {
            title: "Recent medications",
            viewMoreButton: {
                label: "View more",
            },
            emptyState: {
                label: "Recent medications will appear here",
            },
        },
        insights: {
            emptyState: {
                label: "Helpful tips and recommendations will appear here",
            },
            readMore: {
                label: "Read more",
            },
            campaignTitle: {
                label: "Message from licensed pharmacists",
            },
            campaignSubtitle: {
                label: `You could save by switching to a lower cost medication.
                        Licensed pharmacists have put together a list of savings options for you below.
                        Ask your doctor if switching is right for you.`,
            },
        },
        resources: {
            viewMoreButton: {
                label: "View more",
            },
            emptyState: {
                label: "Resources available will appear here",
            },
        },
        annualCostsUsable: {
            card: {
                title: "Your annual costs",
                body: "Visit our dental partner’s website to see your deductible and out-of-pocket maximums.",
                button: {
                    label: "View dental benefits",
                },
            },
        },
        annualCosts: {
            card: {
                title: "Your annual costs",
                button: {
                    label: "View more",
                },
            },
            annualCostItem: {
                popover: {
                    deductible:
                        "Your deductible is the fixed amount you are required to meet before your health plan begins to pay for covered services in a plan year. Some covered services and medications do not apply toward a deductible.",
                    maximumAmount:
                        "This is the maximum amount you pay for covered services in a plan year. If this limit is reached, your health plan will pay 100% of all covered health care costs for the remainder of the year.",
                },
            },
        },
        quickAccess: {
            card: {
                title: "Quick access to care",
            },
            urgentCare: {
                title: "Find urgent care near you",
                description:
                    "Get professional care for non-life-threatening medical needs.",
            },
            advice24: {
                noCost: {
                    label: "No cost to you",
                },
                careLink: {
                    title: "Connect with a nurse 24/7",
                    description:
                        "Connect with a registered nurse 24 hours a day, 7 days a week with Advice24.",
                },
            },
            nurseLine: {
                title: "Need care now?",
                body: "Talk to a nurse using the UW 24-hour nurse line 1 (888) 402-4237",
            },
            behavioralHealth: {
                careLink: {
                    title: "Find behavioral health support",
                    description:
                        "Get support for your mental health, from virtual therapy to self-guided programs.",
                },
            },
            telehealth: {
                careLink: {
                    title: "Explore your telehealth options",
                    description:
                        "Find information about virtual, chat, telephone, and mobile care options.",
                },
            },
            dispatchHealth: {
                careLink: {
                    title: "Get urgent care at home",
                    description:
                        "Get medical care from health professionals in the comfort of your home with DispatchHealth.",
                },
            },
        },
        wellness: {
            beyondWell: {
                card: {
                    title: "BeyondWell",
                    subtitle: "Your personalized well-being experience.",
                    goTo: "Go to BeyondWell",
                    bodyHeading: "Work towards wellness with BeyondWell",
                    body: "Get a snapshot of your health and customize your well-being journey with easy-to-use tools, helpful resources and motivating challenges.",
                    signIn: "Sign in to learn more about BeyondWell and track your progress",
                },
            },
            smartHealth: {
                card: {
                    title: "SmartHealth",
                    subtitle: "Your personalized well-being experience.",
                    goTo: "Go to SmartHealth",
                    credit: "Wellness credit",
                    bodyHeading: "Work towards wellness with SmartHealth",
                    body: "The SmartHealth program supports you on your journey toward living well through tips, tools, and wellness incentives.",
                    signIn: "Sign in to learn more about SmartHealth and track your progress",
                },
            },
            onLife: {
                card: {
                    incentives: {
                        getRewarded: "Get rewarded for being healthy",
                        keepGoing: "Keep going. You've got this.",
                        greatJob: "Great job. Keep it up!",
                    },
                    subtitle: "Your personalized well-being experience.",
                    rewards: "Rewards",
                    nextStep: "Your next step",
                    messages: "Unread messages",
                    body: "Take the guesswork out of well-being with our guided, step-by-step experience designed to help you get—and stay—on the path to better health.",
                    learnMoreButton: {
                        label: "Learn more",
                    },
                },
            },
            healthyBenefits: {
                card: {
                    title: "Healthy Benefits",
                    subtitle: "Healthy Eating & Weight Management",
                    viewForm: "View reimbursement form",
                    bodyHeading: "Thrive with Healthy Benefits",
                    body: "The CIS Healthy Benefits program provides financial assistance for participating in approved healthy eating or weight management programs.",
                    learnMore: "Learn more about the program",
                },
            },
            hingeHealth: {
                card: {
                    title: "Hinge Health",
                    subtitle: "Back, joint and muscle health",
                    goTo: "Go to Hinge Health",
                    body: "Access tools to improve back, joint and muscle health from the comfort of your home.",
                    learnMore: "Learn more about the program",
                },
            },
        },
        emptyState: {
            default: "Nothing to show here yet...",
        },
    },
    navigation: {
        home: "Home",
        claims: "Claims",
        coverage: "Coverage",
        yourBenefits: "Your benefits",
        yourEOBs: "Your EOBs",
        costs: "Costs",
        overview: "Overview",
        estimateCosts: "Estimate costs",
        payments: "Premium payments",
        care: "Care",
        findCare: "Find care",
        yourCareHistory: "Your care history",
        yourProviders: "Your providers",
        careResources: "Care resources",
        pharmacy: "Pharmacy",
        documents: "Documents",
        formsDocuments: "Forms & documents",
        taxInformation: "Tax information",
        contactUs: "Contact us",
        inSpanish: "En Español",
    },
    experiments: {
        experiments: "Experiments",
        theme: "Theme",
        darkMode: "Dark Mode",
        chatbot: "Chatbot",
        chatBtn: "Launch chat",
        renewalFeature: "Plan renewal feature",
        renewalBtn: "Visit plan renewal",
        language: "Language",
        serviceFaults: "Simulate BAS service outage",
    },
    jointAdmin: {
        estimateCostsTitle: "Estimate costs",
        estimateCostsBody:
            "Find information about medical treatments and their costs",
        providerSearchTitle: "Provider search",
        providerSearchBody: "Look for providers in your area",
        submitClaimTitle: "Submit a claim",
        submitClaimBody: "Fill out an online form.",
        learnMore: "Learn More",
        generalInfoTitle: "General information",
        generalInfoBody:
            "For questions regarding claims, benefits and Explanation of Benefits please contact Member Customer Service on the back of your ID card.",
        gotoSite: "Go to {{site}}",
        websiteSupportTitle: "Website support",
        websiteSupportBody: "For website support please call: 1 (888) 427-0470",
    },
    feedbackCard: {
        thankYou: "Thank you!",
        thankYouBody:
            "Your feedback is a valuable part of how we improve your experience.",
    },
    "feedback-card": {
        label: "Did you find this information helpful?",
        options: [
            {
                label: "Yes",
                title: "What do you like about it?",
                values: [
                    "Feels relevant",
                    "I can act on this",
                    "Timely",
                    "Taught me something new",
                    "Another reason",
                ],
            },
            {
                label: "No",
                title: "What can we improve about this insight?",
                values: [
                    "Doesn't feel relevant",
                    "Too difficult to do",
                    "I've already done this",
                    "I already know this",
                    "Something else",
                ],
            },
        ],
    },
    careGaps: {
        notAvailable:
            "We're sorry, but this insight is not currently available.",
        error: "There was an error loading this content",
        pleaseContact: "Please contact",
        support: "customer support",
        moreInformation: "for more information",
        timelineLabel: "See care timeline",
        benefitsLabel: "See benefits",
        yourCoverage: "Your coverage",
        yourCare: "Your care",
    },
    planRenewal: {
        title: "Plan renewal",
        common: {
            title: "Plan renewal",
            edit: "Edit",
            faqs: [
                {
                    label: "More questions? Contact us",
                    content:
                        "Visit the <a href={{contactUs}} target='_blank' data-test='contact-us-link' title='contact us'>contact us</a> page. Make sure you have your Member ID card handy when you call us.",
                },
            ],
            subscriber: "Subscriber",
            spouse: "Spouse",
            domesticPartner: "Domestic Partner",
            dependant: "Dependant",
        },
        removeRenewalProgressModal: {
            title: "You may lose progress",
            body: "When you exit the renewal form in the middle of a section or go back to a completed section, some progress may be lost.",
            cancelBtn: "Cancel",
            proceedBtn: "Proceed",
        },
        addressForm: {
            title: "Residential address",
            description:
                "Has your address changed? If yes, make updates as needed. If any of your information has changed, your rates may change for {{nextYear}}.",
            yourAddressOnFile: "Your address on file:",
            yes: "Yes, my address has changed",
            no: "No, my address has not changed",
            hasAddressChanged: "Has your address changed?",
            invalidServiceArea: `It looks like you've moved out of our coverage area. If that's correct, we cannot renew your coverage online. **Please call us at the number on the back of your member ID card to renew**.<br/><br/>If you haven't moved, please double check the information you typed in and try again.`,
            moreInfoRequired:
                "<strong>More information needed:</strong> Please verify your county to proceed.",
            completedLabel: "Verify your address",
            coverageArea: "our coverage area",
            formButtonLabel: "Continue",
            streetAddress: "Street address",
            streetAddressError: "Residential street address is required.",
            city: "City",
            cityError: "City is required.",
            state: "State",
            stateError: "State is required.",
            stateReadOnly: "To update the state, please update your ZIP code",
            county: "County",
            countyError: "County is required.",
            countyServiceError: "This county is outside of the service area.",
            zipCode: "ZIP Code",
            zipCodeError: "ZIP code is required.",
            zipCodeLengthError: "$t(common.zipCodeLengthError)",
            zipCodeServiceError:
                "The ZIP code was not found or is outside of our service areas.",
            addressChangeError: "Has your address changed is required.",
            faqsTitle: "Common questions",
            faqs: [
                {
                    label: "If my address changes, why would my rates change?",
                    content:
                        "Rates are based in part on where you live. Depending where you have moved, your rates may be affected.",
                },
            ],
        },
        applicantsForm: {
            title: "Member information",
            description:
                "Let us know the tobacco use of each member. Whether or not someone uses tobacco may affect your rates.",
            completedLabel: "Verify tobacco use",
            primaryMember: "Primary member",
            tobaccoUse: "Tobacco use",
            tobaccoUser: "Tobacco user",
            removedMembers: "Removed members",
            nonTobaccoUser: "Non-tobacco user",
            tobaccoUserError: "Selection is required.",
            removeMemberBtn: "Remove this member",
            addMemberBtn: "Add this member back",
            formSubmitBtn: "Continue to plan selection",
            faqsTitle: "Common questions",
            faqs: [
                {
                    label: "How do I remove or change the primary subscriber?",
                    content:
                        "You cannot change the primary subscriber. Instead, the new primary subscriber needs to apply for new insurance coverage for {{nextYear}}, including all the people who need to be covered. You can apply for new coverage from {{openEnrollmentStartDate}} through {{openEnrollmentEndDate}}. In the meanwhile, you will remain covered on your {{currentYear}} plan with the current primary subscriber until {{coverageEndDate}}.",
                },
                {
                    label: "How do I add a dependent?",
                    content: `Unfortunately, you cannot add a dependent through the renewal process. You'll need to <a href="https://www.shop.{{brandName}}.com/individual/spa/" target="_blank">shop for a new plan</a>, add your new dependent, and submit a new application. Adding a dependent will affect your premium. In the meanwhile, you will remain covered on your {{currentYear}} plan through {{coverageEndDate}}.`,
                },
                {
                    label: "What is a tobacco user?",
                    content:
                        "A tobacco user is someone who has lawfully used tobacco (in any form, but excluding any religious or ceremonial use) on average four or more times per week within the last six months.",
                },
                // why can't I change tobacco use for some members of my family? becasue they are under 21
            ],
        },
        planForm: {
            title: "Verify or choose plan",
            completedLabel: "Shop and compare",
            description: "Listening for plan selection ...",
            relaunchBtn: "Relaunch plan selection",
        },
        primaryCareProvider: {
            title: "Primary care provider selection",
            description:
                "When selecting a plan you should also designate a primary care provider (PCP).",
            descriptionOregon:
                "Please name a Primary Care Provider (PCP). If no PCP is named within 90 days, we are required by law to assign one to you.",
            needDoctorTitle: "Need a doctor?",
            needDoctorContent:
                "If you don't have a PCP, use our online tool to <0>find a doctor</0> who is local to you and within network. Make a note of the name, medical group (if any), and address so you can fill it in below.",
            pcpInformationTitle: "PCP Information",
            pcpInformationContent:
                "List your choice of provider, their address and medical group name (if known).",
            completedLabel: "Verify PCP information",
            continueBtn: "Continue",
            providerInfo: "Provider information",
            pcpNoLabel: "I'm not going to name a PCP at this time.",
            pcpDependentNoLabel:
                "No, I will not designate a PCP for this dependent at this time.",
            pcpYesLabel: "I have a PCP or want to <0>find a doctor</0>.",
            pcpYesLabelDependant:
                "This dependant either has a PCP or I have selected a new one. I have typed their PCP information below.",
            pcpErrorMessage: "Please make a selection.",
            pcpNoMessage:
                "Thanks for letting us know. If you don't have a PCP or you don't want to name a PCP now, you can always do so later.",
            pcpName: "Primary care provider name",
            providerNameError: "Primary care provider name is required.",
            medicalGroup: "Medical group name (if known)",
            streetAddress: "PCP Street address",
            city: "City",
            state: "State",
            zipCode: "ZIP Code",
            addressError: "PCP Street address is required.",
            cityError: "$t(common.cityError)",
            stateError: "$t(common.stateError)",
            zipCodeError: "$t(common.zipCodeError)",
            zipCodeLengthError: "$t(common.zipCodeLengthError)",
            faqsTitle: "Common questions",
            faqs: [
                {
                    label: "Who is a primary care provider?",
                    content:
                        "Your PCP is the doctor you see for check-ups and other common medical problems. Your health plan is designed to work best when you have a PCP to coordinate your care with other providers. They work as a team to make sure you receive the best health care possible.<br /><br />A PCP can be a medical doctor (M.D.) or a doctor of osteopathic medicine (O.D.) or someone who specializes in:<ul><li>Family Practice</li><li>General Practice</li><li>Pediatrics</li><li>Internal Medicine</li><li>Geriatrics</li><li>Adult Medicine</li><li>OB/GYN</li><li>Gynecology</li><li>Obstetrics</li><li>Women's Health</li></ul>A PCP can also be a women's health care practitioner or any physician's assistant (P.A.), nurse practitioner (N.P.), or advanced nurse practitioner (A.R.N.P.) if their main specialty is one of those listed above and they are working under the license of an M.D. or O.D.",
                },
            ],
            faqsOregon: [
                {
                    label: "Who is a primary care provider?",
                    content:
                        "Your PCP is the doctor you see for check-ups and other common medical problems. Your health plan is designed to work best when you have a PCP to coordinate your care with other providers. They work as a team to make sure you receive the best health care possible.<br /><br />A PCP can be a medical doctor (M.D.) or a doctor of osteopathic medicine (O.D.) or someone who specializes in:<ul><li>Family Practice</li><li>General Practice</li><li>Pediatrics</li><li>Internal Medicine</li><li>Geriatrics</li><li>Adult Medicine</li><li>OB/GYN</li><li>Gynecology</li><li>Obstetrics</li><li>Women's Health</li></ul>A PCP can also be a women's health care practitioner or any physician's associate (P.A.), nurse practitioner (N.P.), or advanced nurse practitioner (A.R.N.P.) if their main specialty is one of those listed above and they are working under the license of an M.D. or O.D.",
                },
            ],
        },
        reviewForm: {
            paymentInfo: {
                paymentTitle: "Step 1: Payment information{{asterisk}}",
                payerDescription:
                    "Is any third-party payer including employers, providers, not-for-profit agencies paying for any portion of this policy? <strong>Note: </strong>we do not accept third-party payments from employers, providers, and not-for-profit agencies unless required by law.",
                payerDescriptionID:
                    "Is an Individual Coverage Health Reimbursement Arrangement (ICHRA) or Qualified Small Employer Health Reimbursement Arrangement (QSEHRA) paying for any portion of this policy?",
                yes: "$t(common.yes)",
                no: "$t(common.no)",
                selfEmployed:
                    "Are you self-employed and planning to pay your premium with your business account?",
                businessName: "Name of your business",
                payerName: "Payer name",
                payerType: "Payer type",
                payerTypeDescription:
                    "If you indicate an ICHRA/QSEHRA as a payer, you must provide documentation of your ICHRA/QSEHRA arrangement.",
                billingCheckBox:
                    "Billing address is same as residential address",
                billingAddress: "Billing address",
                city: "$t(common.city)",
                state: "$t(common.state)",
                zipCode: "$t(common.zipCode)",
                addressError: "Billing address is required.",
                cityError: "$t(common.cityError)",
                stateError: "$t(common.stateError)",
                zipCodeError: "$t(common.zipCodeError)",
                zipCodeLengthError: "$t(common.zipCodeLengthError)",
                payerError:
                    "Third party contributor is required if a third-party payer is paying for any portion of this policy.",
                payerTypeError: "Third party payer type is required.",
                businessNameError:
                    "Business name is required if self-employed.",
            },
            communicationPrefs: {
                communicationTitle:
                    "Step 2: Communication preferences{{asterisk}}",
                communicationDescription:
                    "This contact information only applies to this renewal process and will not update your profile information. To update your profile information for your account, please contact customer support.",
                phone: "Contact phone number",
                email: "Email",
                mailingCheckBox:
                    "Mailing address is same as residential address",
                mailingAddress: "Mailing address",
                city: "$t(common.city)",
                state: "$t(common.state)",
                zipCode: "$t(common.zipCode)",
                phoneError: "Contact phone number is required.",
                phoneInvalid: "Contact phone number is invalid.",
                emailError: "Email address is invalid",
                addressError: "Mailing address is required.",
                cityError: "$t(common.cityError)",
                stateError: "$t(common.stateError)",
                zipCodeError: "$t(common.zipCodeError)",
                zipCodeLengthError: "$t(common.zipCodeLengthError)",
            },
            hsaPref: {
                hsaTitle: "Step 3: HSA{{asterisk}}",
                hsaDescription:
                    "If you selected a Health Savings Account (HSA) qualified health plan:",
                hsaDescription1:
                    "The HSA option is an insurance plan which can be paired with a health savings financial account.",
                hsaDescription2:
                    "To take advantage of the pre-tax savings offered by your HSA financial account from day one, we recommend you open your account by your health plan effective date.",
                hsaDescription3:
                    "An HSA option offers its most value when you set up its health savings account with a financial institution. You can use our preferred partner, Health Equity {{registered}}, or use any other institution.",
                hsaYes: "Yes, I authorize {{brandName}} to share my eligibility and claims information with HealthEquity for the purposes of establishing and administering my HealthEquity Health Savings Account (Social Security number must be provided). Terms and conditions of the health savings financial account will be mailed with your HealthEquity HSA Visa Card.",
                hsaNo: "No, do not share my information with HealthEquity. I have/will open my own HSA financial account.",
                ssnError:
                    "Social security number is required if you wish for us to share info with Health Equity.",
                ssnInvalid: "Social security number is invalid.",
            },
            title: "Review & e-sign",
            chosenPlan: "You have chosen to renew with this plan:",
            description:
                "Make updates as needed. If any of your information has changed, your rates may change for {{nextYear}}.",
            esignButton: "E-sign and continue",
            invalidError: "Please correct the invalid fields above.",
            completedLabel: "Contact and Payment",
            faqsTitle: "Common questions",
        },
        confirmation: {
            title: "We have received your renewal",
            description:
                "<p style='margin-bottom:26px;'>Thank you for choosing to renew with {{brand}}. You will be notified of the eligibility decision within 7-10 business days. Notification may come through postal mail or email, depending on your communication preference.</p>" +
                "<p>Your current coverage will continue through Dec. 31, {{currentYear}}. If you are eligible, your new coverage will begin on Jan. 1, {{nextYear}}.</p>",
            reEnrollingDescription:
                "<p style='margin-bottom:26px;'>Thank you for continuing your coverage with {{brand}} in {{nextYear}}.</p>" +
                "<p>Your {{currentYear}} coverage will continue through Dec. 31, {{currentYear}}. Your {{nextYear}} coverage will begin on Jan. 1.</p>",
            buttonLabel: "Go to your member dashboard",
            selectedPlan: "Selected plan",
            applicants: "People on this plan",
        },
        cancel: {
            title: "You have decided not to continue your coverage",
            description:
                "<p style='margin-bottom:26px;'>We’re sorry to lose you as a member.</p>" +
                "<p>Your {{currentYear}} coverage will continue through {{coverageEndDate}}. You can change your mind at any time through {{openEnrollmentEndDate}}. Just visit your Member dashboard to shop for a new plan.</p>",
            buttonLabel: "Go to your member dashboard",
        },
        submissionError: {
            title: "An error occurred",
            description:
                "<p style='margin-bottom:26px;'>We were unable to send your renewal request. Please refresh the page to try again.</p>" +
                "<p>If you still experience issues after refreshing this form and trying again, please <a href='/support' target='_self'>contact us</a> .</p>",
        },
    },
    planRenewalBanner: {
        renewalIneligibleModal: {
            title: "Members on application are over 65",
            body: `It looks like this renewal includes an applicant who will be 65 or older on the effective date.<br /><br />To continue with this renewal, please contact customer service using the phone number on the back of your Member ID.`,
            cancelBtn: "Dismiss",
        },
        preOeDiscontinued: {
            notice: "$t(common.renewalBanner.renewalImportantNotice)",
            title: "$t(common.renewalBanner.renewalPlanDiscontinueTitle)",
            description:
                "As of Jan. 1, {{nextYear}}, we will no longer offer health insurance plans in your ZIP code. This does not affect your {{currentYear}} coverage, which remains in place through the end of the year.",
            subject: "What do I need to know?",
            subjectDescription:
                "<p style='margin-bottom:26px;'>Open Enrollment starts {{openEnrollmentStartDate}}, {{currentYear}}. At that time, you can shop directly with a different health insurer or visit the health insurance marketplace.</p>" +
                "<p>You can still sign into your {{brand}} home page to see your claims information until 18 months from the date your coverage ends.</p>",
            optionUrls: {
                shopBcbsPlans: "$t(common.renewalBanner.shopBcbsLabel)",
                shopMarketplacePlans:
                    "$t(common.renewalBanner.shopMarketplaceLabel)",
            },
        },
        discontinuedNoAltPlans: {
            notice: "$t(common.renewalBanner.renewalImportantNotice)",
            title: "$t(common.renewalBanner.renewalPlanDiscontinueTitle)",
            description:
                "As of Jan. 1, {{nextYear}}, we will no longer offer health insurance plans in your ZIP code. This does not affect your current coverage, which remains in place through the end of {{currentYear}}.",
            subject: "What do I need to know?",
            subjectDescription:
                "You can still sign into your {{brand}} home page to see your claims information until 18 months from the date your coverage ends.",
            optionUrls: {
                shopBcbsPlans: "$t(common.renewalBanner.shopBcbsLabel)",
                shopMarketplacePlans:
                    "$t(common.renewalBanner.shopMarketplaceLabel)",
            },
        },
        preOePlanRecommended: {
            notice: "$t(common.renewalBanner.renewalImportantNotice)",
            title: "$t(common.renewalBanner.renewalPlanChangingTitle)",
            description:
                "<p style='margin-bottom:26px;'>Our plans are changing for {{nextYear}}. You should have received a letter in the mail from us with details about your new plan. <strong>If the plan works for you, we'll automatically renew your coverage. For your new plan to be effective, please be sure to pay your first premium for the year.</strong></p>" +
                "<p>Your premium may change for {{nextYear}}.",
            subject: "What are my options?",
            subjectDescription:
                "<p style='margin-bottom:26px;'><strong>If the plan works for you, you don't need to do anything. We'll automatically renew your coverage. For your new plan to be effective, please be sure to pay your first premium of the year.</strong></p>" +
                "<p style='margin-bottom:26px;'>If you don’t receive a letter, then on or after {{openEnrollmentStartDate}} call <a href='/support' target='_self'>Customer Support</a> and we’ll help you renew your coverage.</p>" +
                "<p>If you want to consider other plans we offer, you’ll be able to do that, too. Just return to this page on or after {{openEnrollmentStartDate}} and we’ll show you how.</p>",
        },
        planRecommendedNoPlanChosen: {
            notice: "$t(common.renewalBanner.renewalImportantNotice)",
            title: "$t(common.renewalBanner.renewalPlanChangingTitle)",
            description:
                "<p style='margin-bottom:26px;'>Our plans are changing for {{nextYear}}. You should have received a letter in the mail from us with details about your new plan. <strong>If the plan works for you, we’ll automatically renew your coverage. For your new plan to be effective, please be sure to pay your first premium for the year.</strong></p>" +
                "<p>Your premium may change for {{nextYear}}.</p>",
            subject: "What are my next steps?",
            subjectDescription:
                "<p style='margin-bottom:26px;'><strong>If the plan works for you, you don't need to do anything. We'll automatically renew your coverage. For your new plan to be effective, please be sure to pay your first premium of the year.</strong></p>" +
                "<p style='margin-bottom:26px;'>If you haven’t received a letter, call <a href='/support' target='_self'>Customer Support</a> and we’ll help you renew your coverage.</p>",
            subjectDescriptionLinkBlock: {
                firstBlock: "If you want to ",
                secondBlock:
                    ", you can do that now through {{openEnrollmentEndDate}}, {{currentYear}}.",
            },
            optionUrls: {
                chooseDifferentPlan: "shop for other plans",
            },
        },
        planChosen: {
            notice: "Plan renewal confirmed",
            title: "Thank you for choosing a {{nextYear}} plan",
            description:
                "<p style='margin-bottom:26px;'>You have chosen: <strong>{{renewedProductName}}</strong></p>" +
                "<p>We’re glad you’ve decided to renew with {{brand}}.</p>",
            subject: "You’re all set!",
            subjectDescription:
                "<p style='margin-bottom:26px;'>We have received your renewal. If we need additional information to determine your eligibility, we’ll be in touch. The plan you have chosen should begin on Jan. 1, {{nextYear}}, but <strong>to be sure your plan goes into effect, please pay your first premium of the year</strong>.</p>" +
                "<p>You are covered by your {{currentYear}} plan through {{openEnrollmentEndDate}}, {{currentYear}}.</p>",
            renewalUrl: {
                chooseDifferentPlan: "Choose a different plan",
            },
        },
        chosenToCancel: {
            notice: "$t(common.renewalBanner.renewalImportantNotice)",
            title: "You have decided not to continue your coverage",
            description: "You can still choose a new plan for {{nextYear}}.",
            subject: "Your coverage ends on {{coverageEndDate}}",
            subjectDescription:
                "<p style='margin-bottom:26px;'>You can still choose a {{brand}} plan for {{nextYear}} through {{openEnrollmentEndDate}}, {{currentYear}}.</p>" +
                "<p>You can still sign into your home page to see your claims information until 18 months from the date your coverage ends.</p>",
            renewalUrl: {
                shopNewPlan: "Shop for a new plan",
            },
        },
    },
    myAccount: {
        accountInformation: {
            addressMessage: {
                isRoxOrBSH:
                    "Contact your state insurance exchange to change your address.",
                isCCA: "Contact Member Services for help in changing your address.",
                isNotSB:
                    "Only the subscriber of this plan can change your mailing address.",
                isContactEmployer:
                    "Please contact your benefit administrator or employer to update your address.",
            },
            myAddresses: {
                mailingAddress: "<0>Mailing address</0>",
                residentialAddress: "<0>Residential address</0>",
                billingAddress:
                    "<0>Billing address</0> - Only needed if premium statement should be sent to an address different from Mailing address.",
                medicareDisclaimer:
                    "To satisfy Medicare guidelines, please notify us of any changes to your physical, mailing or billing address below. Any changes reported to Medicare and not your plan will result in an attempt to verify your current information",
            },
            medicareChangeAddressMessages: {
                infoMessage:
                    "If you have items that are currently in transit, they will be delivered to your previous address. If you want to order new member ID cards to your new address, please wait three to five business days for the new address to be processed first. If there is a typo in the new address you entered, please enter the correct address below and the previously entered new address will be replaced with this one.",
            },
            accountSettingsMessages: {
                emailSaved: `Email address saved.`,
                emailInvalid: `Please enter a valid email address.`,
                emailUnchanged: `This email address is unchanged.`,
                emailInUse: `This email is already in use. For your privacy and protection, please use a different email address.`,
                usernameMinLength: `8 characters minimum`,
                usernameSpecialChar: `No special characters or spaces (~\`!@#$%^&*()+={}|[]\\:";'<>,.?/)`,
                usernameUnchanged: `New Username is same as current username`,
                usernameTooltip: `Username may not include any of the following special characters or spaces ~ \` ! @ # $ % ^ & * ( ) + = { } | [ ] : \\ " ; ' < > , . ? /`,
                usernameContainsBannedWords: `Username may not include forbidden words`,
            },
            passwordChangeMessages: {
                changeSuccessful: {
                    title: "Success!",
                    message: "Your password has been successfully changed.",
                },
                changeFailed: {
                    title: "Something went wrong!",
                    message:
                        "Your password could not be updated. Please make sure that your current password and new password are valid and try again.",
                },
                error: {
                    passwordDidntChange:
                        "Your new password is the same as the current password.",
                    passwordsDontMatch: "Passwords do not match",
                    currentPasswordIncorrect: "Password is incorrect.",
                },
                passwordChangeHelp: {
                    passwordMinSize: "Password must include 10+ characters",
                    bannedWords: `Password cannot include words {{brandName}} or password`,
                    bannedInformation: `Password cannot include personal information (e.g. name or birthdate)`,
                    passwordStrength: `Password is `,
                },
            },
            emailVerificationMessages: {
                instructions: {
                    title: `Check your email`,
                    message: `We want to protect your privacy and make sure we’re sending
                            messages to the right email. We’ve sent a verification code to
                            <b>{{obfuscatedEmail}}</b>. Enter the code to confirm your email.`,
                },
                resend: {
                    title: `Email sent`,
                    message: `We resent you an email. Use the most recently sent email to continue.`,
                },
                changeEmail: {
                    title: `Check your email!`,
                    message: `Check your inbox and follow the instructions to verify your email.
                            If you don’t see an email within a few minutes, check your spam folder.`,
                },
                activationFailed: {
                    title: `Please try again`,
                    message: `The activation code you entered is either incorrect or expired.
                            Please try again or click "Resend code" to receive a new one. `,
                },
                linkInstructions: {
                    title: `Check your email`,
                    message: `Follow the instructions we sent to <b>{{obfuscatedEmail}}</b>
                            confirm your new email address. If you don't receive an
                            email within a few minutes, check your spam folder.`,
                },
                unexpectedError:
                    "An unexpected error occurred, please try again.",
            },
            personalInformationMessages: {
                description:
                    "Providing your personal information is optional and your answers are not shared with other members on your plan. This information does not affect your eligibility, plan choices, or access to programs.",
                employerGroupToolTip:
                    " If you would like to make a change to your name, please contact your HR department.",
                exchangeTooltip:
                    "If you would like to make a change to your name, please contact Customer Support.",
            },
        },
    },
    advice24Dialog: {
        call: "Call",
        tty: "TTY",
        chatDisclaimer:
            "By using Advice24 chat services you are agreeing to our third party privacy policy.",
        learnMore: "Learn more",
    },
    signin: {
        demoDisclaimer:
            "Please note that you must have a valid test id to log into this production simulation environment. If you do not have one, please contact your sales representative or relationship manager. This demonstration environment will provide a limited set of features and functionality available in the production environment. No personal health information will be displayed.",
    },

    insights: {
        currentEmpty: "Your current insights will appear here.",
        dismissedEmpty: "Your recently dismissed insights will appear here.",
    },

    insightLandingPage: {
        support: {
            card: {
                title: "Support between doctor visits",
                subtitle:
                    "Get personalized, one-on-one support managing diabetes and your overall wellbeing.",
            },
            omada: {
                careLink: {
                    title: "Omada",
                    description:
                        "Personalized care team to improve your overall health. Gain energy, lose weight.Get support for your mental health, from virtual therapy to self-guided programs.",
                },
            },
            advice24: {
                careLink: {
                    title: "Advice24",
                    description:
                        "Connect with a registered nurse 24 hours a day, 7 days a week with Advice24.",
                },
            },
            behavioralHealth: {
                careLink: {
                    title: "Behavioral Health",
                    description:
                        "Get support for your mental health, from virtual therapy to self-guided programs.",
                },
            },
            telehealth: {
                careLink: {
                    title: "Telehealth",
                    description:
                        "Find information about virtual, chat, telephone, and mobile care options.",
                },
            },
            button: {
                label: "See all care resources",
            },
        },
        // aic-insights-covered component
        gotYouCoveredCard: {
            title: "We've got you covered",
            subtitle:
                "Use a covered provider and lab to avoid any surprise bills.",
            officeVisit: {
                text: "Office visit (preventive care)",
                pctCovered: "100% covered",
            },
            button: {
                label: "Estimate lab costs",
            },
        },
    },
    findCare: {
        pills: {
            coverageVaries: "Coverage varies",
            inNetwork: "In network",
            noCost: "No cost to you",
        },
        planYourCare: {
            findAProvider: {
                title: "Find a provider",
                description:
                    "Find a provider or facility or check your network",
                findAProviderModal: {
                    modalTitle: "What type of provider are you looking for?",
                    medical: "Medical",
                    behavioralHealth: "Behavioral health",
                    dental: "Dental",
                    vision: "Vision",
                },
            },
            findPharmacies: {
                title: "Find pharmacies near you",
                description:
                    "See pharmacies in your area and home delivery options",
            },
        },
        virtualCare: {
            title: "Virtual care",
            mdLiveVendor: {
                title: "Schedule a doctor's appointment",
                description:
                    "Live video chat 24/7 with doctors, psychiatrists, and licensed therapists via MDLive",
            },
            doctorOnDemand: {
                title: "Schedule a doctor's appointment",
                description:
                    "Live video chat 24/7 with doctors, psychiatrists, and licensed therapists via Doctor On Demand.",
            },
            behavioralHealthFindCare: {
                title: "Find behavioral health support",
                description: "Access virtual therapy and self-guided programs.",
            },
            askAPharmacistFindCare: {
                title: "Ask a pharmacist",
                description: "Connect with a licensed pharmacist.",
            },
        },
        getImmediateCare: {
            title: "Get immediate care",
            advice24: {
                title: "Connect with a nurse 24/7",
                description:
                    "Connect with a registered nurse 24 hours a day, 7 days a week with Advice24.",
            },
            findCareUrgentCareNearYou: {
                title: "Find urgent care near you",
                description:
                    "Get professional care for non-life-threatening medical needs.",
            },
            urgentCareAtHome: {
                title: "Get urgent care at home",
                description:
                    "Get medical care from health professionals in the comfort of your home with DispatchHealth.",
            },
            findCareEmergencyCareNearYou: {
                title: "Find an emergency room near you",
                description: "For life- or limb-threatening health conditions.",
            },
        },
        recentProviders: {
            title: "Recent providers",
        },
    },
    premiumPayment: {
        payYourPremiumHeader: "Pay your premium",
        pastAmt: "Past due amount",
        newCharge: "New charges",
        totalAmt: "Total amount due",
        totalAmtPending: "Pending",
        totalAmtInvoicepending: "Invoice Pending",
        makePaymentButton: "Make a payment",
        managePaymentButton: "Manage payments",
        setupAutoPayButton: "Set up autopay",
        setupAutoPayHeader: "Sign up for automatic payments",
        setupAutoPayDesc:
            "Set up and manage automatic monthly payments, or make a one-time payment through InstaMed, our online payment partner.",
        moreInfoAboutPayment:
            "If you’ve recently made a payment, it may take up to 48 hours to be updated.",
        custServiceButton: "Customer Service",
        messagePaymentPastDue: "Payment is past due",
        messagePaymentPastDueBodyWithDate:
            "Coverage will be terminated on {{messagePayByDate}} if payment is not received",
        messagePaymentPastDueBody:
            "Coverage will be terminated unless payment is received",
        messagePendingPayment:
            "Your payment from {{messagePaymentDate}} is being processed",
        messagePendingPaymentAutopay: "Automatic payment is being processed",
        messageCoverageTerminated: "Your plan is no longer active",
        messageContactCustService: "Please contact customer service",
        messagePaymentViaSocialSecurity:
            "Your payment is being processed via Social Security",
        messageAutopayScheduled: "Automatic payment scheduled",
        messageAutopayNotScheduled: "Automatic payment not processed",
        due: "Due ",
        makePremiumPaymentHeader: "Make a premium payment",
        makePremiumPaymentBody:
            "Learn more about your premium and how to manage or make a secure payment through InstaMed, our online payment partner.",
    },
    chat: {
        onChat: {
            popupError:
                "Your browser may be blocking pop-ups. Please add this site to your exception list.",
            startError: "Could not start chat. Please call 1-888-367-2116",
        },
    },
    errorCard: {
        description:
            "We're sorry, we're experiencing technical difficulties at the moment.",
        supportLinkLabel: "Contact customer service here",
    },
    onboarding: {
        checkYourEmail: {
            title: "Check your email",
            anotherEmail: "We sent another email",
            body: "To protect your personal information, we need to verify your email address by sending a security code to ",
            inputLabel: "Security code",
            cantFindEmail: "Can't find the email? Check your spam folder or ",
            resendEmail: "resend the email.",
            wrongEmailAddress:
                "Wrong email address? <0> Change your email address.<0>",
            verifyButton: "Verify my email",
            incorrectCodeError: "Incorrect code",
            incorrectCodeErrorFinal: "Incorrect code final",
            requiredFieldError: "Required field",
            unexpectedError: "An unexpected error occurred. Please try again.",
            resendSuccessMessage: "Check your email",
            recheckYourInfo:
                "Recheck your information: This code does not match. You can try again, or <0>contact us</0> for help.",
            titleMultipleIncorrectCode:
                "You entered the incorrect code too many times",
            sendNewCodeButton: "Send a new code",
            bodyMultipleIncorrectCode: "We can send a new security code to ",
            errorBodyMultipleIncorrectCode:
                "We are unable to validate your email. Please contact at: ",
        },
        checkYourText: {
            title: "Check your text messages",
            body: "To protect your personal information, we need to verify your mobile number by sending a security code to ",
            inputLabel: "Security code",
            resendText: "Didn’t get the message?  <0>Resend the message.</0>",
            wrongMobileNumber:
                "Wrong mobile number?  <0>Change your mobile number.</0>",
            changeMyMind:
                "I changed my mind.  <0>I don’t want to get texts.</0>",
            verifyButton: "Verify my mobile number",
            sendNewCodeButton: "Send a new code",
            resendSuccessMessage: "Check your text messages",
            recheckYourInfo:
                "Recheck your information: This code does not match. You can try again, or <0>contact us</0> for help.",
            securityCodeExpired:
                "Security code expired: The security code you entered has expired. Please <0>request another one.</0>",
            incorrectCodeError: "Incorrect code",
            expiredCodeError: "Expired code",
            requiredFieldError: "Required field",
            incorrectCodeErrorFinal: "Incorrect code final",
            unexpectedError: "An unexpected error occurred. Please try again.",
            titleMultipleIncorrectCode:
                "You entered the incorrect code too many times",
            bodyMultipleIncorrectCode: "We can send a new security code to ",
            bodyMultipleIncorrectCodeTitle: "We sent another text message",
            errorBodyMultipleIncorrectCode:
                "We are unable to validate your mobile number. Please contact at: ",
        },
        goPaperless: {
            title: "Go paperless",
            titleMedicare: "Go paperless Medicare",
            bodySection1:
                "Save trees by getting documents like EOBs (explanation of benefits), summary of benefits and renewal information electronically and not through U.S. Mail.",
            bodySection2:
                "Selecting Yes, I’ll go paperless shows that I have agreed to the <0>Statement of Consent of Electronic Delivery</0>.",
            bodySection3:
                "You can change the email your paperless documents are delivered to at any time in the my account.",
            noPaperlessButton: "No, send me paper copies",
            goPaperlessButton: "Yes, I want to go paperless",
            unexpectedError: "An unexpected error occurred. Please try again.",
            bodyMedicareSection1:
                "Choose which documents you’d like to receive via paperless delivery. Selecting paperless delivery means you’ll get the document electronically instead of through the mail and that I have agreed to the <0>Statement of Consent of Electronic Delivery</0>.",
            bodyMedicareSection2:
                "You can change the email your paperless documents are delivered to at any time in the my account.",
            medicalEobTitle: "Medical Explanation of Benefits (EOB)",
            medicalEobBody:
                "Receive your EOB statements for medical claims online instead of by mail. EOBs include details about the services you received from a doctor or health care facility, how much {{brandName}} covered and how much you owe to your provider. You may change this option at any time to receive your medical EOBs by mail.",
            phamacyEobTitle: "Pharmacy Explanation of Benefits (EOB)",
            pharmacyEobBody:
                "Receive your EOB statements for pharmacy claims online instead of by mail. Pharmacy EOBs include details about medications prescribed, how much {{brandName}} covered and how much you owe to your provider. You may change this option at any time to receive your pharmacy EOBs by mail.",
            anocTitle: "Annual Notice of Changes (ANOC)",
            anocBody:
                "Receive your Annual Notice of Changes document—which lists yearly changes in your health plan coverage, costs and service area—by email instead of by mail before October 1st of each year. You may change this option at any time to receive your ANOC by mail.",
            savePreferenceButton: "Save preferences",
        },
        KeepInformed: {
            title: "We keep you informed",
            heading:
                "Stay up to date with the latest news, tips, and updates about your health insurance.",
            body: "* Cost-saving tips\n* Personalized health insights\n* Changes to your plan or benefits\n* Important safety information like medication recalls",
            termsAndPrivacyText:
                "You agree that we or our authorized representative can contact you at the number by autodialer, recorded or artificial voice, or text. Message & data rates may apply. Consent is not required to purchase or utilize goods or services. ",
            unexpectedError: "An unexpected error occurred. Please try again.",
            savePreferencesButton: `Complete Registration`,
            modal: {
                modalTitle: "Are you sure you want to leave?",
                modalMessage:
                    "You can finish creating your account by logging in with your username and password.",
                modalButton: {
                    ButtonCancel: "Cancel",
                    ButtonYes: "Yes, leave",
                },
            },
        },
        CommunicationPreferences: {
            title: `Contact preferences`,
            newsAndUpdatesTitle: {
                title: `News & updates`,
                message: `Get news and updates of programs and wellness tools; plus tips to help you get the most out of your healthcare plan.  Your information is never shared with others.`,
            },
            feedBackTitle: {
                title: `Feedback`,
                message: `Your experience as a member is important to us.  Occasionally you may be invited to participate in surveys, interviews or focus groups via the channel you select.  Participants of focus groups may be compensated for their time.`,
            },
            userInfoTitle: {
                title: "Emails will be sent to:",
                titleWithPhone: "Emails and text messages will be sent to:",
            },
            CommunicationPreferencesImage: {
                title: "",
                alt: "",
            },
            unexpectedError: "An unexpected error occurred. Please try again.",
            savePreferencesButton: `Save preferences`,
            modal: {
                modalTitle: "Are you sure you want to leave?",
                modalMessage:
                    "You can finish creating your account by logging in with your username and password.",
                modalButton: {
                    ButtonCancel: "Cancel",
                    ButtonYes: "Yes, leave",
                },
            },
        },
        changeYourMobile: {
            title: "Change your mobile number",
            inputLabel: "Mobile number",
            cancelButton: "Cancel",
            resendCode: "Resend code",
            phoneNumberError: "Invalid mobile number",
            requiredFieldError: "Required field",
            unexpectedError: "An unexpected error occurred. Please try again.",
            phoneNumberSameError:
                "Your new phone number is the same as the current phone number.",
            phoneNumberAlreadyUse:
                "This number is already in use for another account. If you do not believe this number should be associated with another account, please contact <0>Customer Service</0>.",
        },
        modalDontWantText: {
            modalHeader: "Are you sure you don’t want texts?",
            modalBody:
                "We will not send texts to you and not save the mobile number you provided.",
            modalCancelButton: "Cancel",
            modalConfirmButton: "I don’t want texts",
        },
        changeYourEmail: {
            title: "Change your email",
            inputLabel: "Email",
            cancelBtnText: "Cancel",
            resendCodeBtnText: "Resend code",
            emailFormatError: "Email address is invalid",
            requiredFieldError: "Required field",
            unexpectedError: "An unexpected error occurred. Please try again.",
            emailInUseError:
                "This email is already in use. For your privacy and protection, please use a different email address.",
        },
        welcome: {
            welcomePage: {
                title: "Welcome to {{brandName}}!",
                body: "We’re so glad you’re here! Your login has been created and you now have the ability to manage your health care on the website and mobile app.",
                button: "Go to the dashboard",
            },
        },
        modal: {
            modalHeader: "Are you sure you want to leave?",
            modalBody:
                "You can finish creating your account by logging in with your username and password.",
            modalCancelButton: "Cancel",
            modalConfirmButton: "Yes, leave",
        },
        graphics: {
            imageAlt: "Check your email image graphic",
            textImageAlt: "Check your text image graphic",
            goPaperlessImageAlt: "Go paperless image graphic",
            CommunPrefImageAlt: "Communication preferences image graphic",
        },
    },
    loadingScreen: {
        defaultExtendedLoadingMessage: "Loading is taking longer than usual...",
    },
    costs: {
        kyruusRewardsModal: {
            header: "Get rewarded",
            disclaimer:
                "You are now leaving the {{brandName}} website. Once you select “Continue”, the new site’s terms, conditions, and privacy policy will apply.",
            continueButton: "Continue",
            cancelButton: "Cancel",
            rewardsTitle: "Rewards",
            rewardsDescription:
                "You will receive rewards on a gift card to major retailers after your claim is processed, usually 60-90 days after your visit. Rewards range between $25 and $100 depending on the service or procedure. Check out <0>rewardable services ↗</0> and <1>FAQ ↗</1> for more details.",
            link1: "rewardable services ↗",
            link2: "FAQ ↗",
        },
    },
}

/**
 * Spanish
 * -----------------------------------------------------------------------------
 */
export const es = {
    common: {},
    navigation: {
        //
        // NOTE: Keep this commented until we release multi-language
        //
        // home: "Inicio",
        // yourCare: "Tu cuidado",
        // timeline: "Cronología",
        // doctorsProviders: "Médicos y proveedores",
        // medications: "Medicamentos",
        // coveredServices: "Servicios cubiertos",
        // checkBenefits: "Consultar beneficios",
        // findCare: "Encuentra cuidado",
        // resources: "Recursos",
        // formsDocuments: "Formularios y documentos",
        // claimsCosts: "Reclamaciones y costos",
        // overview: "General",
        // claims: "Reclamar",
        // payments: "Pagos",
        // estimateCosts: "Costo estimado",
        // eobStatements: "EDB declaraciones",
        // taxForms: "Formularios de impuesto",
        // support: "Apoyo",
        // contactUs: "Contacta con nosotras",
    },
    experiments: {
        experiments: "Experimentos",
        theme: "Tema",
        darkMode: "Modo Oscuro",
        chatbot: "Bot conversacional",
        chatBtn: "Chat de lanzamiento",
        renewalFeature: "Función de renovación de planes",
        renewalBtn: "Renovación del plan de visita",
        language: "Idioma",
    },
    feedbackCard: {
        thankYou: "¡Gracias!",
        thankYouBody:
            "Sus comentarios son una parte valiosa de cómo mejoramos su experiencia.",
    },
    "feedback-card": {
        label: "¿Le ha resultado útil esta información?",
        options: [
            {
                label: "Sí",
                title: "Cuéntanos qué te gustó",
                values: [
                    "Se siente relevante",
                    "Very actionable",
                    "Muy accionable",
                    "Me enseñó algo nuevo",
                    "Algo más",
                ],
            },
            {
                label: "No",
                title: "Cuéntanos qué no te gustó de él",
                values: [
                    "No se siente relevante",
                    "Muy dificil de hacer",
                    "Ya he hecho esto",
                    "Ya se esto",
                    "Algo más",
                ],
            },
        ],
    },
    careGaps: {
        // notAvailable: "Lo sentimos, pero esta insight no está disponible actualmente.",
        // error: "Hubo un error al cargar este contenido",
        // pleaseContact: "Por favor contactar",
        // support: "Atención al cliente",
        // moreInformation: "para más información",
    },
}
