import * as React from "react"
import { SVGProps } from "react"
const SvgArrowOpposites = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Arrow pointed up and arrow pointed down"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11 6.25L7 2.25M7 2.25L3 6.25M7 2.25V15.75"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13 17.75L17 21.75M17 21.75L21 17.75M17 21.75L17 8.25"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgArrowOpposites
