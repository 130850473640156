import * as React from "react"
import { SVGProps } from "react"
const SvgPerson = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Person" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M13.917 1.37821L14.2997 0.454335L13.917 1.37821ZM16.623 7.91121L15.6991 7.52852L16.623 7.91121ZM10.09 10.6172L10.4727 9.69333L10.09 10.6172ZM7.384 4.08421L6.46012 3.70152L7.384 4.08421ZM3 23H2C2 23.5523 2.44772 24 3 24V23ZM21 23V24C21.5523 24 22 23.5523 22 23H21ZM13.5343 2.30209C15.5753 3.14753 16.5446 5.48748 15.6991 7.52852L17.5469 8.2939C18.815 5.23238 17.3612 1.72249 14.2997 0.454335L13.5343 2.30209ZM15.6991 7.52852C14.8537 9.56956 12.5137 10.5388 10.4727 9.69333L9.70731 11.5411C12.7688 12.8092 16.2787 11.3554 17.5469 8.2939L15.6991 7.52852ZM10.4727 9.69333C8.43165 8.84789 7.46243 6.50794 8.30788 4.4669L6.46012 3.70152C5.19197 6.76304 6.64579 10.2729 9.70731 11.5411L10.4727 9.69333ZM8.30788 4.4669C9.15332 2.42587 11.4933 1.45665 13.5343 2.30209L14.2997 0.454335C11.2382 -0.813816 7.72827 0.639999 6.46012 3.70152L8.30788 4.4669ZM4 23C4 18.5523 7.55228 15 12 15V13C6.44772 13 2 17.4477 2 23H4ZM12 15C16.4477 15 20 18.5523 20 23H22C22 17.4477 17.5523 13 12 13V15ZM21 22H3V24H21V22Z"
            fill="currentColor"
        />
    </svg>
)
export default SvgPerson
