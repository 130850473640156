/**
 * App reducer
 * -----------------------------------------------------------------------------
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { BrandStrings, Features } from "./types"
import { MemberBrandString } from "@/utils/env"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface App {
    /**
     * Init states
     */
    readonly isInitialized: boolean
    readonly isLoading: boolean
    readonly isAuthenticated: boolean
    readonly errorMessage?: string
    readonly isDfwAuth: boolean

    /**
     * EMS Features flags
     */
    readonly emsFeatureFlags: Record<string, boolean>

    /**
     * Configuration
     */
    readonly features: Features
    readonly darkMode: "system" | "light" | "dark"
    readonly activityTimeoutMs?: number

    /**
     * Environment
     */
    readonly brand:
        | "regence"
        | "cambia"
        | "asuris"
        | "aesthetic"
        | "regence-legacy"
        | "bridgespan"
    readonly environment: "dev" | "uat" | "prd"
    readonly isDemo: true | false

    /**
     * Development flags
     */
    readonly flags: ReadonlyArray<string>

    /**
     * Care gaps campaign ecid
     */
    readonly ecid: string

    /**
     * Global modals
     */
    readonly modals: ReadonlyArray<string>

    /**
     * User account meta
     */
    readonly userMeta?: {
        readonly brand?: MemberBrandString
        readonly brandCode?: string
        readonly region?: string
        readonly audience?:
            | "individual"
            | "employer"
            | "producer"
            | "provider"
            | "medicare"
            | "generic"
        readonly employerGroupName?: string

        // pending onboarding status
        readonly pending?: {
            readonly email: boolean
            readonly sms: boolean
            readonly paperless: boolean
            readonly communication: boolean
        }
    }

    /**
     * Brand Strings
     */
    readonly brandStrings: BrandStrings

    readonly datadog?: {
        readonly applicationId: string
        readonly clientToken: string
        readonly service: string
        readonly env: string
        readonly version: string
        readonly sessionSampleRate: number
        readonly sessionReplaySampleRate: number
        readonly trackUserInteractions: boolean
        readonly trackResources: boolean
        readonly trackLongTasks: boolean
    }
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: App = {
    isInitialized: false,
    isLoading: false,
    isAuthenticated: false,
    errorMessage: undefined,
    isDfwAuth: false,
    activityTimeoutMs: undefined,
    features: {},
    darkMode: "system",
    brand: "regence",
    environment: "dev",
    isDemo: false,
    ecid: undefined,
    flags: [],
    modals: [],
    brandStrings: {
        name: "",
        hsaMessage: "",
    },
    emsFeatureFlags: {},
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const app = createSlice({
    name: "app",
    initialState,
    reducers: {
        receiveApp: (state: App, action: PayloadAction<App>) => {
            return { ...state, ...action.payload }
        },
        resetApp: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const appActions = { ...app.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default app.reducer
