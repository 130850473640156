import * as React from "react"
import { SVGProps } from "react"
const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Calendar" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M23 9H1M6 1V5.7M18 1V5.7M20.6 23H3.4C2.1 23 1 21.9 1 20.6V4.4C1 3.84772 1.44772 3.4 2 3.4H22C22.5523 3.4 23 3.84772 23 4.4V20.6C23 21.9 21.9 23 20.6 23Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgCalendar
