/**
 * IFrame
 * -----------------------------------------------------------------------------
 */
import React, { Fragment } from "react"
import { Card, Spinner } from "elements"
import { NextRouter, useRouter } from "next/router"
import IframeResizer from "iframe-resizer-react"
import { hideElement } from "utils/element"
import { formatCase } from "utils"
import { useSelector } from "store"
import throttle from "lodash/fp/throttle"
import { tagEvent } from "utils/analytics"

export const IFRAME_ACTIVITY_EVENT_TYPE = "adif.iframe_activity"

const iframeActivityEventListener: EventListenerOrEventListenerObject = _ => {
    document.dispatchEvent(new Event(IFRAME_ACTIVITY_EVENT_TYPE))
}

const throttledIframeActivityEventListener = throttle(
    5000, // invoke at most once per 5 seconds
    iframeActivityEventListener,
)

const IFRAME_ACTIVITY_EVENT_TYPES = [
    "keydown",
    "mousedown",
    "mousemove",
    "visibilitychange",
    "wheel",
]

export const addIframeActivityEventListeners = (iframe: HTMLIFrameElement) => {
    IFRAME_ACTIVITY_EVENT_TYPES.forEach(eventType => {
        iframe.contentWindow.addEventListener(
            eventType,
            throttledIframeActivityEventListener,
        )
    })
}

export const removeIframeActivityEventListeners = (
    iframe: HTMLIFrameElement,
) => {
    IFRAME_ACTIVITY_EVENT_TYPES.forEach(eventType => {
        iframe.contentWindow.removeEventListener(
            eventType,
            throttledIframeActivityEventListener,
        )
    })
}

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props extends React.HTMLAttributes<HTMLIFrameElement> {
    readonly src: string
    readonly title: string
    readonly hideBreadcrumbs?: boolean
    readonly heightCalculationMethod?:
        | "max"
        | "min"
        | "bodyOffset"
        | "bodyScroll"
        | "documentElementOffset"
        | "documentElementScroll"
        | "grow"
        | "lowestElement"
        | "taggedElement"
    readonly useIframeTitle?: boolean
    readonly scrollable?: boolean
    
}

const updateAnchors = (iframe: HTMLIFrameElement, router: NextRouter) => {
    if (iframe.contentDocument !== null) {
        const iframeDoc = iframe.contentDocument
            ? iframe.contentDocument
            : iframe.contentWindow.document
        const baseTags = iframeDoc.getElementsByTagName("base")
        if (baseTags.length > 0) {
            const iframeBasePath = baseTags[0]?.href || ""
            const anchors = Array.from(iframeDoc.getElementsByTagName("a"))
            anchors.forEach(anchor => {
                const plainHref = anchor.href.replace(iframeBasePath, "")
                if (!plainHref.startsWith("#")) {
                    // Update all hyperlinks except in page document fragment links
                    if (anchor.target !== "_blank") {
                        // eslint-disable-next-line functional/immutable-data
                        anchor.target = "_parent"
                        // ensure all links open in parent window (exception: links with target _blank)
                        anchor.addEventListener("click", function (event) {
                            event.preventDefault()
                            anchor.href && router.push(anchor.href)
                        })
                    }
                }
                /**
                 * All in page links starting with # will be handled via iFrameResizer.inPageLinks = true
                 * This lib handles navigating(auto scrolling) to correct element.
                 *
                 * This plugin works when testing both dfw and adif in deployed or local env.
                 *
                 * Observed that when dfw is running locally but connecting to deployed adif via webproxy.ts,
                 * this lib fails.
                 * Workaround: Run adif in local and use its url in webproxy.ts
                 */
            })
        }
    }
}

const hideSpinner = () => {
    /* eslint-disable */
    const iframeLoader = document.getElementById(
        "iframeLoader",
    ) as HTMLDivElement
    iframeLoader.hidden = true

    /* eslint-disable */
}

/**
 * This is to resolve the issue with empty anchor list even when Iframe load is complete.
 * There is no specific event which will indicate the DOM is ready for inspection in Iframe.
 * So this function keeps checking for anchor links in the iframe upto 10sec.
 * If found, it updates the anchors, else waits till 10 second before removing the timer.
 * @param iframe
 * @param router
 */
export const pollAndUpdateAnchors = (
    iframe: HTMLIFrameElement,
    router: NextRouter,
) => {
    const iframeDoc = iframe.contentDocument
        ? iframe.contentDocument
        : iframe.contentWindow.document
    let checkedTime = 0
    let maxLookupTime = 20
    const anchorLookupTimer = setInterval(() => {
        let iFrameErrorBody = iframeDoc.getElementsByTagName("customiFrameErrorBody")
        if (iFrameErrorBody.length != 0) {
            router.reload() 
        }
        let allAnchors = iframeDoc.getElementsByTagName("a")
        if (allAnchors.length != 0) {
            updateAnchors(iframe, router)
            clearInterval(anchorLookupTimer)
            return
        }
        if (checkedTime == maxLookupTime) {
            console.error("[iframe] Failed to update anchor links")
            clearInterval(anchorLookupTimer)
            return
        }
    }, 500)
}

const handleGlobalClick = event => {
    const modalType = event.target.dataset.modal
    if (modalType && modalType === "medicare") {
        /**
         * For styx configured modal in iframe, get the scrollY position from parent window.
         * Use that to position modal on screen.
         */
        const iframe = document.getElementById(
            "iframeElement",
        ) as HTMLIFrameElement
        if (iframe) {
            const modalContainer = iframe.contentDocument.querySelector(
                "[data-styxponent-module='@adif/cambia-modal-medicare']",
            )
            modalContainer.setAttribute("style", "display:none;")
            setTimeout(() => {
                const modal: HTMLDivElement = iframe.contentDocument.querySelector(
                    "div.Modal",
                )
                const scrolly = window.parent.scrollY + 32
                modal.setAttribute("style", `margin-top:${scrolly}px;`)
                modalContainer.setAttribute("style", "display:block;")
            }, 500)
        }
    }
}

export const tagFormsDocumentsAnalytics = () => {
    const iframe = document.getElementById(
        "iframeElement",
    ) as HTMLIFrameElement
    if (iframe.contentDocument !== null) {
        const iframeDoc = iframe.contentDocument
            ? iframe.contentDocument
            : iframe.contentWindow.document
        setTimeout(() => {
            const tabs = iframeDoc.querySelectorAll('.tabs')
            if(tabs[0]){
                for (let i = 0; i < tabs[0].children.length; i++) {
                    // get tab
                    const child = tabs[0].children[i]
                    
                    // get tab name
                    const childName = child.querySelectorAll('.bounce-fix')[0].innerHTML.replace(/&amp;/g, '&');
                    
                    // attach Tealium event to current tab
                    child.addEventListener("click", function (event) {
                        event.preventDefault()
                        tagEvent({
                            tealium_event: "link",
                            data_analytics_id: "medicare-forms-documents-tab-" + `${i+1}`,
                            link_text: childName,
                            link_url: "",
                        })
                    })
                }
            }
        }, 3000)
    }
}

const onLoadIframe = (
        event: React.FormEvent, 
        router: NextRouter, 
        useIframeTitle: boolean
    ) => {
    const iframe = event.currentTarget as HTMLIFrameElement
    iframe.contentWindow.document.addEventListener("click", handleGlobalClick)
    let title = iframe.contentWindow.document.title        
    iframe.title = title
    if (useIframeTitle) {
        document.title = title
    }

    pollAndUpdateAnchors(iframe, router)
    hideSpinner()

    addIframeActivityEventListeners(iframe)
}

const removeListeners = (router: NextRouter) => {
    const iframe = document.getElementById("iframeElement") as HTMLIFrameElement
    if (iframe) {
        iframe.contentWindow.removeEventListener("click", handleGlobalClick)
        iframe.contentWindow.removeEventListener("click", event => {
            pollAndUpdateAnchors(iframe, router)
        })
        removeIframeActivityEventListeners(iframe)
    }
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const IFrame: React.FC<Props> = props => {
    /**
     * Define template values
     */
    const { 
        src, 
        title, 
        onLoad, 
        heightCalculationMethod, 
        scrollable,
        hideBreadcrumbs,
        useIframeTitle = true
    } = props // no more console errors
    const router = useRouter()

    const { brand } = useSelector(state => state.app)
    const pageTitle = title + (brand ? " | " + formatCase(brand, "capitalize") : "")

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        return () => removeListeners(router)
    }, [])

    /**
     * Template
     */
    return (
        <Fragment>
            <div id="iframeLoader" style={{ textAlign: "center" }}>
                <Card
                    name="iframe-card"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Spinner className="text-align:center" />
                </Card>
            </div>

            <div id="iframeContainer">
                <IframeResizer
                    id="iframeElement"
                    src={src}
                    title={pageTitle}
                    className="border-0"
                    style={{
                        top: 0,
                        left: 0,
                        height: 0,
                        width: "100%",
                    }}
                    onLoad={event => {
                        onLoadIframe(event, router, useIframeTitle)
                        hideBreadcrumbs && hideElement(event.currentTarget, `div[data-styxponent-module="@adif/cambia-breadcrumbs"]`)
                        if (typeof onLoad == "function") {
                            // this is custom onLoad function that each page can pass to do additional onload handling
                            onLoad(event)                            
                        }
                    }}
                    log={false}
                    autoResize={true}
                    scrolling={scrollable ? scrollable : false}
                    inPageLinks={true}
                    heightCalculationMethod={
                        heightCalculationMethod
                            ? heightCalculationMethod
                            : "max"
                    } // handle CSS styles that causes content to flow outside the body
                />
            </div>
        </Fragment>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default IFrame
