import * as React from "react"
import { SVGProps } from "react"
const SvgHeartCheck = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Heart with checkmark inside"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M17.2431 2.50015C16.6798 2.49585 16.1177 2.58495 15.5781 2.76577C14.7815 3.03274 14.0563 3.49209 13.4617 4.10653L12 5.49898L10.5383 4.10653C9.94346 3.4922 9.21818 3.03301 8.42139 2.76627C7.62461 2.49952 6.77879 2.43274 5.95264 2.57134C5.12649 2.70995 4.34331 3.05003 3.6667 3.56397C2.99009 4.07791 2.43913 4.75121 2.05859 5.52919C1.56184 6.55666 1.39026 7.7222 1.56856 8.85797C1.74687 9.99374 2.26585 11.0411 3.05077 11.8493L12 21.5L20.9492 11.8464C21.7342 11.0382 22.2531 9.99082 22.4314 8.85505C22.4901 8.4812 22.5109 8.10413 22.4947 7.72982M9 10L12 13L21 4"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgHeartCheck
