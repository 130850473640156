import { FileUploadParam } from "./types"

export const transformFileUploadParams = (
    data: any, // eslint-disable-line
): FileUploadParam => {
    return {
        maxClaimsFileuploads: data?.maxClaimsFileuploads,
        maxTotalClaimsUploadSize: data?.maxTotalClaimsUploadSize
    }
}
