import * as React from "react"
import { SVGProps } from "react"
const SvgTouchid = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Touch ID" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M19.5 3.75021C17.475 1.95021 14.85 0.900208 12 0.900208C7.35002 0.900208 3.45002 3.67521 1.65002 7.65021M22.875 15.0752C23.175 14.1002 23.25 13.1252 23.25 12.0752C23.25 10.0502 22.725 8.10021 21.75 6.45021M0.75 11.3252C0.75 11.5502 0.75 11.8502 0.75 12.0752C0.75 13.1252 0.9 14.1002 1.125 15.0752M15.3 19.4252C15.15 20.7752 14.925 22.2752 14.775 23.1752M7.27502 22.2002C8.32502 17.8502 8.17502 12.0752 8.17502 12.0752C8.17502 9.97521 9.90002 8.25021 12 8.25021C14.1 8.25021 15.825 9.97521 15.825 12.0752C15.825 12.0752 15.825 14.3252 15.6 15.6002M12 12.0752C12 12.0752 11.925 15.2252 11.625 18.3002M9.75 4.95021C10.425 4.72521 11.25 4.57521 12 4.57521C16.125 4.57521 19.5 7.95021 19.5 12.0752C19.5 12.0752 19.5 17.3252 18.75 21.0752M3.75 19.5752C4.5 16.5752 4.5 12.0752 4.5 12.0752C4.5 10.4252 5.025 8.85021 6 7.57521"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgTouchid
