import * as React from "react"
import { SVGProps } from "react"
const SvgGear = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Gear" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M23 14V10H21C20.3 10 19.7 9.6 19.5 8.9C19.2 8.3 19.4 7.5 19.8 7.1L21.2 5.7L18.4 2.9L17 4.3C16.5 4.8 15.8 4.9 15.2 4.6C14.4 4.3 14 3.7 14 3V1H10V3C10 3.7 9.6 4.3 8.9 4.5C8.3 4.8 7.6 4.7 7.1 4.2L5.6 2.8L2.8 5.6L4.2 7C4.7 7.5 4.8 8.2 4.5 8.8C4.3 9.6 3.7 10 3 10H1V14H3C3.7 14 4.3 14.4 4.5 15.1C4.8 15.7 4.6 16.5 4.2 16.9L2.8 18.3L5.6 21.1L7 19.7C7.5 19.2 8.2 19.1 8.8 19.4C9.6 19.7 10 20.3 10 21V23H14V21C14 20.3 14.4 19.7 15.1 19.5C15.7 19.2 16.5 19.4 16.9 19.8L18.3 21.2L21.1 18.4L19.7 17C19.2 16.5 19.1 15.8 19.4 15.2C19.7 14.6 20.2 14.1 20.9 14.1L23 14Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgGear
