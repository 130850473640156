import router from "next/router"
import Routes from "../../router"
import { Treatment } from "@/store/rx/treatments/types"
import { formatCase, formatCurrency } from "@/utils"
import { EstimatedCost } from "@/store/rx/primis/estimated-costs/types"
import { DrugStrength } from "@/store/rx/primis/drug-search/types"
import { getEnvironment } from "@/utils/env"

export const startRoute = () => {
    return navigationRoute(router.query?.start?.toString())
}

export const backRoute = () => {
    const term = router.query?.back?.toString() || router.query?.start?.toString()
    const start = "?start=" + startTerm()
    if (term === "campaign") {
        return navigationRoute(term) + "&start=" + startTerm()
    } else if (term === "medication") {
        return navigationRoute(term) + start
    } else if (term === "condition") {
        return navigationRoute(term) + start
    } else if (term === "research") {
        return navigationRoute(term) + start
    } else {
        return navigationRoute(term)
    }
}

const navigationRoute = (term: string) => {
    if (term == null) {
        return Routes.home()
    } else if (term === "home") {
        return Routes.home()
    } else if (term === "condition") {
        return Routes.browseCondition(router.query?.id?.toString())
    } else if (term === "medication") {
        return Routes.browseMedication(router.query?.treatmentId?.toString())
    } else if (term === "compare") {
        return Routes.browseConditionCompare(router.query?.conditionId?.toString())
    } else if (term === "medications") {
        return Routes.medications()
    } else if (term === "pharmacy") {
        return Routes.medications()
    } else if (term === "research") {
        return Routes.researchMedications()
    } else if (term === "care") {
        return Routes.findCare()
    } else if (term === "benefits") {
        return Routes.pharmacyBenefits()
    } else if (term === "resources") {
        return Routes.resources()
    } else if (term === "costs") {
        return Routes.costEstimates()
    } else if (term === "conditions") {
        return Routes.browseConditions()
    } else if (term === "7a659bee-4fdf-41e0-96cb-c9d139d88248") {
        return Routes.managingYourDiabetes()
    } else if (term === "campaign") {
        return (
            Routes.browseCampaign(router.query.campaignId.toString()) +
            "?opportunity_id=" +
            router.query.opportunityId.toString()
        )
    } else if (term?.includes("care-gaps")) {
        return term
    } else {
        return Routes.home()
    }
}

export const startTerm = () => {
    if (router.query?.start?.length) {
        return router.query.start
    } else {
        return queryTerm(router.pathname)
    }
}

export const backTerm = () => {
    return queryTerm(router.pathname)
}

const queryTerm = (pathname: string) => {
    if (pathname === "/your-care/medications") {
        return "medications"
    } else if (pathname === "/research/medications/[id]") {
        return "medication"
    } else if (pathname === "/research/conditions/[id]") {
        return "condition"
    } else if (pathname === "/campaign/[id]") {
        return "campaign"
    } else if (pathname === "/covered-services/check-benefits/pharmacy") {
        return "benefits"
    } else if (pathname === "/claims-and-costs/estimate-costs") {
        return "costs"
    } else if (pathname === "/research") {
        return "research"
    } else if (pathname === "/research/conditions") {
        return "conditions"
    } else if (pathname === "/research/conditions/[id]/compare") {
        return "compare"
    } else if (pathname === "/pharmacy") {
        return "medications"
    } else if (pathname === "/coverage/your-benefits/pharmacy") {
        return "benefits"
    } else if (pathname === "/costs/estimate-costs") {
        return "costs"
    } else if (pathname === "/") {
        return "home"
    } else {
        return "home"
    }
}

// Removes Focus Trap when navigating from an open popover
export const escapeFocusTrap = (elementId: string) => {
    if (document.getElementById(elementId)) {
        document.getElementsByName("dismiss-button")[0].click()
    }
}

export const getOrderedStrengths = (treatment: Treatment) => {
    const matchingStrengths = getMatchingStrengths(treatment)
    const doseForms = matchingStrengths?.map(strength => strength.doseForm).filter((value, index, array) => array.indexOf(value) === index)
    const orderedStrengths = doseForms?.map((form) => {
        const strengthsByDoseForm = matchingStrengths.filter(str => str.doseForm === form)
        const ordered =  strengthsByDoseForm.sort((a, b) => (a.name > b.name) ? 1 : -1)
        // const ordered =  strengthsByDoseForm.sort((a, b) => (parseInt(a.strength) > parseInt(b.strength) ? 1 : -1))
        return ordered
    }).flat()
    return orderedStrengths
}

const getMatchingStrengths = (treatment: Treatment) => {
    const [primaryName, secondaryName] = getTreatmentNameParts(treatment?.primaryName)
    const singularSecondaryName = secondaryName.slice(-1) === "s" ? secondaryName.slice(0, -1) : secondaryName
    const matchesByPrimary = treatment?.strengths?.filter(strength => strength?.name?.toLowerCase()?.split(" ")?.includes(primaryName?.toLowerCase()))
    const matchesBySecondary = matchesByPrimary?.filter(strength =>
        strength?.doseForm?.toLowerCase()?.split(" ")?.includes(singularSecondaryName?.toLowerCase()) ||
        strength?.name?.toLowerCase()?.split(" ")?.includes(singularSecondaryName?.toLowerCase()),
    )
    const bestMatches = matchesBySecondary?.length ? matchesBySecondary : matchesByPrimary
    return bestMatches
}

export const findDefaultStrength = (
    treatment: Treatment,
) => {
    const orderedStrengths = getOrderedStrengths(treatment)
    return orderedStrengths?.[0]
}

export const getTreatmentNameParts = (
    name: string
) => {
    const nameParts = name?.split(" ")
    const primaryName = nameParts?.length > 0 ? nameParts[0] : ""
    const secondaryName = nameParts?.length > 1 ? nameParts[1] : ""
    return [primaryName, secondaryName]
}

export const formatRxCost = (
    estimatedCost: EstimatedCost
) => {
    const tc =  Number(estimatedCost?.totalCost)
    const pp = Number(estimatedCost?.planPaid)
    const pc =  Number(estimatedCost?.patientTotalCost)
    if ((tc > 0 && tc < 10) || (pp > 0 && pp < 10) || (pc > 0 && pc < 10)) {
        const totalCost = estimatedCost?.totalCost ? "$" + tc.toFixed(2) : "unavailable"
        const planPaid = estimatedCost?.planPaid ? "$" + pp.toFixed(2) : "unavailable"
        const patientPaid = estimatedCost?.patientTotalCost ? "$" + pc.toFixed(2) : "unavailable"
        return [totalCost, planPaid, patientPaid]
    } else {
        const totalCost = estimatedCost?.totalCost ? formatCurrency(estimatedCost?.totalCost) : "unavailable"
        const planPaid = estimatedCost?.planPaid ? formatCurrency(estimatedCost?.planPaid) : "unavailable"
        const patientPaid = estimatedCost?.patientTotalCost ? formatCurrency(estimatedCost?.patientTotalCost) : "unavailable"
        return [totalCost, planPaid, patientPaid]
    }
}

export const pluralizeUnit = (
    unit: string,
    quantity: string
) => {
    switch(unit?.toLowerCase()) {
        case "box":
            return parseInt(quantity) > 1 ? "Boxes" : "Box"
        case "plas cont":
            return parseInt(quantity) > 1 ? "Plastic Containers" : "Plastic Container"
        default:
            return parseInt(quantity) > 1 ? unit + "s" : unit
    }
}

export const buildStrengthLabels =(
    treatment: Treatment,
    selectedStrength: DrugStrength
) => {
    // When we call medication knowledge with the NDC for a medication strength,
    // we may get back results that include multiple amounts (e.g., 14 or 60 puffs) for the same strength.
    // There will be a unique NDC for each result with a different amount.
    //
    // Even though they results have different amounts, they have the same name,
    // so when we search for strengths by name (e.g., Advair Diskus 100-50 Mcg),
    // we get an array that includes entries with different amounts.
    const strengths = treatment?.strengths?.filter(str => { if (str.name === selectedStrength?.name) { return str } })
    // Then we can find any NDCs that do not correspond to the original NDC.
    const strengthsWithAmounts = strengths?.filter(str => str.ndc !== selectedStrength?.ndc)
    const strengthsWithAmountsNdcs = strengthsWithAmounts?.map(str => str.ndc)
    // Then we get all the strengths.
    const orderedStrengths = getOrderedStrengths(treatment)
    // When we create the drop down labels for the strengths, we filter out the strengthsWithAmounts
    // because they have the same name as the selected strength.
    // Each strength should only appear once in the drop down (e.g., Advair Diskus 100-50 Mcg).
    // There is a separate drop down for different amounts (14 or 60 puffs).
    const strengthLabels = orderedStrengths?.map(strength => {
        if (!strengthsWithAmountsNdcs.includes(strength?.ndc)) {
            const capitalizedStrength = formatCase(strength?.name, "titlecase")
            return { label: capitalizedStrength, value: strength.ndc }
        }
    }).filter(label => label)
    // Sometimes we get duplicates so we filter them out
    const uniqueStrengthLabels = strengthLabels?.reduce((accumulator, current) => {
        if (!accumulator.find((item) => item.label === current.label)) {
            accumulator.push(current)
        }
        return accumulator
    }, []);
    return uniqueStrengthLabels
}

export const quantityOrPackage =(
    selectedStrength: DrugStrength
) => {
    const doseFormArray = selectedStrength?.doseForm?.toLowerCase()?.split(" ")
    const tabOrCap = doseFormArray?.includes("tablet") || doseFormArray?.includes("capsule")
    return tabOrCap && selectedStrength?.quantity?.length !== 0
}

export const getUniqueAmounts = (
    strengths: ReadonlyArray<DrugStrength>
) => {
    const unique = strengths?.filter((val, index, arr) =>
        index === arr.findIndex((item) => (item.amount === val.amount && item.packageUnit === val.packageUnit))
    )
    return unique
}

export const findDuplicates = (
    strengths: ReadonlyArray<DrugStrength>
) => {
    const amounts = strengths?.map(str => str.amount)
    const duplicates = amounts?.filter((item, index) => amounts.indexOf(item) !== index)
    return duplicates?.length > 0
}

export const getAmountLabels = (
    uniqueAmounts: ReadonlyArray<DrugStrength>,
    duplicateAmounts: boolean,
) => {
    const basicAmountLabels = uniqueAmounts?.map(amt => {
        const amountUnits = getReadableUnits(amt?.amountUnit)
        return { label: `${Number(amt.amount).toString()} ${amountUnits}`, value: amt.ndc }
    })

    const duplicateAmountLabels = uniqueAmounts?.map(amt => {
        const unit = pluralizeUnit(amt.packageUnit, amt.amount)
        const amountUnits = getReadableUnits(amt?.amountUnit)
        return { label: `${Number(amt.amount).toString()} ${amountUnits} (${unit})`, value: amt.ndc }
    })

    return duplicateAmounts ? duplicateAmountLabels : basicAmountLabels
}

export const getDisplayQuantity = (
    strength: DrugStrength,
    quantity: string,
    period: string,
    unit: string,
    useQuantity: boolean,
) => {
    const amountUnits = getReadableUnits(strength?.amountUnit)
    const displayAmount = strength?.amount ? ` - ${Number(strength?.amount).toString()} ${amountUnits}` : ""
    const quantityAndPeriod = `${quantity} per ${period}`
    const packageQuantityAndUnit = `${quantity} ${unit} ${displayAmount}`
    const displayQuantity = useQuantity ? quantityAndPeriod : packageQuantityAndUnit
    return ", " + displayQuantity
}

export const phoneNumberFormat = (text: string) => {
    // Formatting the phone number as a string
    const stringWithDigits = text.replace(/\D/g, "")
    const formattedPhoneNumber = stringWithDigits.replace(
        /^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/,
        (_, g1, g2, g3) => {
            return "(" + g1 + ") " + g2 + "-" + g3
        },
    )
    return formattedPhoneNumber
}

export const getReadableUnits = (
    unit: string,
) => {
    switch(unit?.toLowerCase()) {
        case "ea":
            return "Each"
        default:
            return unit?.toLowerCase()
    }
}

export const getOpportunityIds = (routes: ReadonlyArray<string>) => {
    const opportunityIds = routes
        .map(route => {
            if (!route) {
                return null
            }
            try {
                const url = new URL(route)
                const urlParams = new URLSearchParams(url.search)
                const id: string = urlParams.get("opportunity_id")
                return id
            } catch (err) {
                console.error(
                    "failed to parse opportunityId from insight route",
                )
                return null
            }
        })
        .filter(id => id)

    return opportunityIds
}

/**
 * String helpers
 */
export const capitalize = (value: string) => {
    if (!value) return ""
    return formatCase(value, "titlecase")
}

/**
 * DeepLink helper
 * 
 * Example usage:
 * buildDeepLink("dev", "Regence", "Spokane", "WA", 
 * "glycosylated_hemoglobin_test", "Blood Sugar Management Test", 
 * 100, "ENCOUNTER", "ENCOUNTER") )
 */

export const buildDeepLink = (env: string, brand: string, city: string, state: string, key: string, query: string, radius: string, searchCategory: string, searchType: string) => {
    const brandName = brand === "bridgespan" ? "bridgespanhealth" : brand
    const brandPrefix = brand.charAt(0).toUpperCase() + brand.slice(1,)
    const hsqEnv = env !== "prd" ? "providerstest" : "providers"
    const hsqDeepLinkUrl = "https://" + hsqEnv + "." + brandName + ".com/auth/realms/" + brandPrefix + "-Medical/protocol/saml/clients/hsq?RelayState="
    const uri = "/healthsparq/#/one/auth/search/isPromotionSearch=true&key=" + key + "&location=" + city + ", " + state + "&page=1&query=" + query + "&radius=" + radius + "&searchCategory=" + searchCategory + "&searchType=" + searchType 

    return hsqDeepLinkUrl + encodeURIComponent(uri)
}