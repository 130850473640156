/**
 * Resources Actions
 * -----------------------------------------------------------------------------
 */
import { serviceConfig } from "@/utils/pager"
import PagerWidget, { WidgetConfig } from "@pgr/web-sdk-widget"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import { http } from "utils"

/**
 * Get resources data
 * -----------------------------------------------------------------------------
 */
export const fetchPager = createAsyncThunk(
    "pager/fetchPager", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.pagerStatus("pending"))

        /**
         * Fetch pager
         */
        const res = await http.get("/api/chat/pager")

        /**
         * Handle request errors
         */
        if (res.error) {
            return dispatch(actions.pagerError({ response: res }))
        }

        /**
         * Define pager data
         */
        const data = res.data || {}

        /**
         * Update state
         */
        dispatch(actions.receivePager(data))
    },
)

export const initializePager = createAsyncThunk(
    "pager/initializePager", // Reducer name
    async (params: void, { dispatch }) => {
        const {
            pager: data,
            app: { brand: brandName },
        } = store.getState()

        const config: WidgetConfig = {
            apiKey: data.apiKeys.web,
            authCode: data.authCode,
            userAddress: data.userAddress,
            services: [...data.services],
            language: data.language,
            visibility: "onEncounterOnly",
            appearance: {
                stylesheet: "/pager.css",
                notifSounds: true,
                tenant: `${brandName}`,
                font: {
                    name: "sans-serif",
                    size: "16px",
                },
                colors: {
                    primary: `${
                        brandName === "asuris" ? "#507D40" : "#0073B5"
                    }`,
                    secondary: `${
                        brandName === "asuris" ? "#507D40" : "#EDF4F8"
                    }`,
                },
                initButton: {
                    text: "Your nurse chat",
                    icon: "/chat.svg",
                    height: "5.3rem",
                    width: "17rem",
                    hide: false,
                    bottom: "1rem",
                    right: "1rem",
                    borderRadius: "24px",
                },
                chat: {
                    hideNewChatBtn: true,
                    bubble: {
                        background: `${
                            brandName === "asuris" ? "#F1F4F0" : "#E8F4FA"
                        }`,
                        borderRadius: "16px",
                        spaceBottom: "16px",
                        clusterify: false,
                    },
                    header: {
                        fontSize: "21px",
                        background: `${
                            brandName === "asuris" ? "#37562C" : "#003958"
                        }`,
                        senderTitle: "Chatting with:",
                        senderTitleFallback: "Chat",
                    },
                    sender: {
                        bot: {
                            background: "none",
                        },
                        position: "top",
                    },
                    widgetMessage: {
                        background: `${
                            brandName === "asuris" ? "#F1F4F0" : "#E8F4FA"
                        }`,
                    },
                },
                panel: {
                    height: "60vh",
                    bottom: "1rem",
                    right: "1rem",
                    width: "366px",
                },
            },
        }

        try {
            const client = await window?.pagerWidget?.getClient()
            if (!client || client?.isDestroyed){
                // eslint-disable-next-line functional/immutable-data
                window.pagerWidget = await PagerWidget.init(config)
            } else {
                console.debug("Pager already initialized")
            }
            dispatch(actions.receivePager({ initialized: true }))
        } catch (e) {
            console.error("Pager initialize error", e)
            dispatch(actions.pagerError({ initialized: false }))
        }
    },
)

export const startPagerChat = createAsyncThunk(
    "pager/startPagerChat", // Reducer name
    async (params: void, { dispatch }) => {
        const { pager } = store.getState()

        try {
            const client = await window.pagerWidget?.getClient()
            if(!pager.initialized || !client || client?.isDestroyed){
                await dispatch(actions.fetchPager())
                await dispatch(actions.initializePager())
            }

            const existingEncounter =
                await window.pagerWidget?.getCurrentEncounter()
            if (!existingEncounter || existingEncounter?.isDestroyed) {
                const newEncounter = await window.pagerWidget?.createEncounter(
                    serviceConfig.encounterConfig,
                )
            } else {
                console.debug("Pager encounter already in progress")
            }
        } catch (e) {
            console.error("Pager createEncounter error", e)
            // TODO: sometimes it's a expected error like "must maccept terms of service 400"
        }
    },
)