/**
 * Window Storage
 * -----------------------------------------------------------------------------
 */

import { getEnvironment } from "./env"

/**
 * Get item from window session storage
 */
export const getItem = (name: string) => {
    return JSON?.parse(window?.sessionStorage?.getItem(name))
}

/**
 * Set item to window session storage
 */
export const setItem = (name: string, value: unknown) => {
    window?.sessionStorage?.setItem(name, JSON?.stringify(value))
}

/**
 * Method to do window session storage data cleanup required before sign-out
 */
export const cleanupSessionStorage = () => {
    window?.sessionStorage?.clear()
}

/**
 * Get item from window local storage
 */
export const getLocalStorageItem = (name: string) => {
    return JSON?.parse(window?.localStorage?.getItem(name))
}

/**
 * Set item to window local storage
 */
export const setLocalStorageItem = (name: string, value: unknown) => {
    window?.localStorage?.setItem(name, JSON?.stringify(value))
}

/**
 * Method to do window local storage data cleanup required before sign-out
 */
export const cleanupLocalStorage = () => {
    const whiteListKeys = ["username", "THEME", "DARK_MODE"]

    // Don't remove the development sign-in keys from local storage for non-prod environment
    if (getEnvironment() !== "prd") {
        for (const k in window?.localStorage) {
            if (!whiteListKeys.includes(k)) window?.localStorage?.removeItem(k)
        }
    } else {
        window?.localStorage?.clear()
    }
}
