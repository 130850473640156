/**
 * Date validation services reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface DuplicateClaimValidation {
    readonly duplicateClaimValidation: {
          readonly isDuplicateClaim: boolean
    }
}
/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<DuplicateClaimValidation> = {
    status: "pristine",
    duplicateClaimValidation: {
        isDuplicateClaim: true
    },
}
/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const duplicateClaimValidation = createStatefulSlice({
    name: "duplicateClaimValidationService", 
    initialState,
    reducers: {
        receiveDuplicateClaimValidationService: (
            state: SliceState<DuplicateClaimValidation>,
            action: PayloadAction<DuplicateClaimValidation>,
        ) => {
            return {
                status: "completed",
                duplicateClaimValidation: action.payload.duplicateClaimValidation || { 
                    isDuplicateClaim: true
                },
            }
        },
        resetDuplicateClaimValidationService: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const duplicateClaimValidationServiceActions = { ...duplicateClaimValidation.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default duplicateClaimValidation.reducer
