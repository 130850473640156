/**
 * Alerts Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import http from "utils/http"
import { Alert } from "./reducer"
import { mpsQuery } from "./queries"
import { getAlertDismissalStatusFromCookie } from "@/utils/cookies"

/**
 * Initialize global alerts state
 * -----------------------------------------------------------------------------
 */
export const fetchAlerts = createAsyncThunk(
    "alerts/fetchAlerts", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.alertsStatus("loading"))

        /**
         * Fetch global alerts
         */
        const { data } = await http.get(`/api/global-alerts`)
        const items: readonly Alert[] = data || []

        /**
         * Initialize the alerts dismissible status.
         */
        items.forEach(({ alertId, type }) => {
            if (alertId?.length) {
                getAlertDismissalStatusFromCookie(alertId, type)
            }
        })

        /**
         * Update state
         */
        dispatch(actions.receiveAlerts({ items })) // TODO: rename to globalAlerts
    },
)

export const fetchMemberPolicyServiceStatus = createAsyncThunk(
    "alerts/fetchMemberPolicyServiceStatus", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.alertsStatus("loading"))

        /**
         * Fetch MPS Response extension by querying subscriberId
         */

        const { data } = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            mpsQuery,
        )
        const mpsData = data || []
        /**
         * Update state
         */
        dispatch(actions.receiveMPSData({ mpsData })) // TODO: rename to globalAlerts
    },
)
