import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import { PharmacistInquirySlice } from "@/store/rx/pharmacist-inquiries/reducer";
import http from "@/utils/http";
import { askAPharmacistMutation, getQueryVariables } from "@/store/rx/pharmacist-inquiries/queries";

export const submitPharmacistInquiry = createAsyncThunk(
    "rx/ask_a_pharmacist/submitPharmacistInquiry",
    async (params: PharmacistInquirySlice, { dispatch }) => {
        const pharmacistInquiry = store.getState().pharmacistInquiry
        const memberId = store.getState().account.plan.memberId
        const suffix = pharmacistInquiry?.selectedSubjectId
        const updatedInquiry = {...pharmacistInquiry, selectedSubjectId: `${memberId}-${suffix}`}

        dispatch(
            actions.updatePharmacistInquiry({
                ...pharmacistInquiry,
                isInitialized: true,
                isLoading: true
            })
        )

        const query = askAPharmacistMutation()
        const queryVars = getQueryVariables(updatedInquiry)

        const res = await http.post(
            "/api/janus/digital-first-information-service/graphql", {
                query: query,
                variables: queryVars
            }
        )

        if (res.error) {
            console.log("------- GRAPHQL ERROR -------") // eslint-disable-line
            console.log(res.error) // eslint-disable-line
            // TODO: we'll need some better janus/DataDoggy logging for this if it fails
            return dispatch(
                actions.updatePharmacistInquiry({
                    ...store.getState().pharmacistInquiry,
                    isLoading: false,
                    isInitialized: true,
                    errorMessage: "Could not execute request",
                }),
            )
        }

        const jobId = res.data.data.createAskAPharmacist.jobId
        console.log(" --------- JOB ID --------", jobId); // eslint-disable-line

        dispatch(
            actions.updatePharmacistInquiry({
                ...store.getState().pharmacistInquiry,
                jobId: jobId,
                isInitialized: true,
                isLoading: false
            })
        )
    }
)

export const startPharmacistInquiry = createAsyncThunk(
    "rx/ask_a_pharmacist/startPharmacistInquiry",
    async (params: PharmacistInquirySlice, { dispatch }) => {
        const { oppId, email, message } = params
        const pharmacistInquiry = store.getState().pharmacistInquiry
        const query = askAPharmacistMutation()

        const queryVars =
        {
            input: {
                message: message,
                subject: "AAP from campaign. Opportunity Id: " + oppId,
                useUnencryptedEmail: false,
                email: email,
                referer: undefined,
            }
        }

        const res = await http.post(
            "/api/janus/digital-first-information-service/graphql",
            {
                query: query,
                variables: queryVars
            }
        )

        if (res.error) {
            console.log(res.error) // eslint-disable-line
            return dispatch(
                actions.updatePharmacistInquiry({
                    ...pharmacistInquiry,
                    isLoading: false,
                    isInitialized: true,
                    errorMessage: "Could not execute GraphQL request",
                }),
            )
        }

        const jobId = res.data.data.createAskAPharmacist.jobId
        dispatch(
            actions.updatePharmacistInquiry({
                ...pharmacistInquiry,
                jobId: jobId,
                isInitialized: true,
                isLoading: false
            })
        )
    }
)
