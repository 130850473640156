/**
 * Treatments reducer
 * -----------------------------------------------------------------------------
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { Treatment } from "./types"
 
/**
  * Types
  * -----------------------------------------------------------------------------
  */

export interface Treatments {
     // Base props
     readonly isInitialized: boolean
     readonly isLoading: boolean
     readonly isItemInitialized: boolean
     readonly isItemLoading: boolean
     readonly errorMessage?: string

     // Resource props
     readonly items: ReadonlyArray<Treatment>
 }
 
/**
  * Initial state
  * -----------------------------------------------------------------------------
  */

export const initialState: Treatments = {
    isInitialized: false,
    isLoading: false,
    isItemInitialized: false,
    isItemLoading: false,
    errorMessage: undefined,
    items: [],
}
 
/**
  * Reducer slice
  * -----------------------------------------------------------------------------
  */
const treatments = createSlice({
    name: "treatments",
    initialState,
    reducers: {
        receiveTreatments: (
            state: Treatments,
            action: PayloadAction<Treatments>,
        ) => {
            return action.payload
        },
        updateTreatments: (
            state: Treatments,
            action: PayloadAction<Treatments>,
        ) => {
            return {
                ...state,
                ...action.payload
            }
        },
        resetTreatments: () => {
            return initialState
        },
    },
})
 
/**
  * Export reducer actions
  * -----------------------------------------------------------------------------
  */
export const treatmentsActions = { ...treatments.actions, ...actions }
 
/**
  * Export reducer
  * -----------------------------------------------------------------------------
  */
export default treatments.reducer
 