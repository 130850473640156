export const premiumPayRegenceQuery = `
 query { 
        member { 
            enrollment { 
                   isMedicare
                   premium { 
                            payments { 
                                    eftFlag 
                                    totalAmountDue 
                                    dueDate 
                                    pastDueAmount 
                                    } 
                              } 
                   } 
            } 
        } 
`