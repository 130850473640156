/**
 * MyAccountSettings reducer
 */

import { SliceState, createStatefulSlice } from "@/store/util/create-stateful-slice"
import * as actions from "./actions"
import * as additionalActions from "../account-settings/actions"
import { EmailActivation, ActivationType, Profile , ValidationStatus, SMS, MemberFamilyShareInfo, LettersAndNotices, AddressTypes, Address, CommunicationPreferences } from "./types"
import { PayloadAction } from "@reduxjs/toolkit"

/**
 * Types
 */
type StatusType = "pristine" | "pending" | "completed" | "error"
export interface MyAccount {
    readonly status: StatusType
    readonly profile: Profile
    readonly emailActivation: EmailActivation
    readonly passwordValidation: ValidationStatus
    readonly consumerSettings: ConsumerSettings
    readonly communicationPreferences: CommPreferences
    readonly hsaPreferences: HsaPreferences
    readonly subscriptionsPreferences: SubscriptPreferences
    readonly lettersNotices: LettersAndNotices

}
interface Email {
    readonly email: string
}
interface ConsumerSettings {
    readonly marketing: { readonly programId: string, readonly setting: boolean, readonly confirmationText: string }
    readonly sso: { readonly programId: string, readonly setting: boolean, readonly confirmationText: string }
    readonly wellness: { readonly programId: string, readonly setting: boolean, readonly confirmationText: string }
    readonly providerAccessAuth: { readonly programId: string, readonly setting: boolean, readonly confirmationText: string }
}
export interface CommPreferences {
    readonly isEOBSetting: boolean
    readonly isMedicalSetting: boolean
    readonly isPrimeSetting: boolean
    readonly isAnocSetting: boolean
}
export interface HsaPreferences {
    readonly isbalance: boolean
    readonly isClaims: boolean
    readonly isInfo: boolean
}
interface SubscriptPreferences {
    readonly claims: PreferenceOptions
    readonly news: PreferenceOptions
    readonly feedback: PreferenceOptions
}
interface PreferenceOptions {
    readonly email?: boolean
    readonly sms?: boolean
}

/**
 * Initial state
 */
export const initialState: MyAccount = {
    status: "pristine",
    profile: {
        firstName: "",
        lastName: "",
        username: "",
        email: "",
        phone: {
            activated: false,
            number: ""
        },
        address: {
            homeAddress: {
                address1: "",
                address2: "",
                city: "",
                state: "",
                zip: "",
            },
            billingAddress: {
                address1: "",
                address2: "",
                city: "",
                state: "",
                zip: "",
            },
            mailingAddress: {
                address1: "",
                address2: "",
                city: "",
                state: "",
                zip: "",
            }
        },
        familySharing: {
            show: false,
            familyShareInfoList: [
                {
                    authorizedDate: "",
                    authorizedTime: "",
                    authorized: false,
                    firstName: "",
                    lastName: "",
                    expiryDate: "",
                    age: "",
                    suffix: ""
                }
            ]
        }
    },
    emailActivation: { status: ActivationType.Empty },
    passwordValidation: {
        strength: null,
        risks: []
    },
    consumerSettings: {
        marketing: { programId: "", setting: false, confirmationText: "" },
        sso: { programId: "", setting: false, confirmationText: "" },
        wellness: { programId: "", setting: false, confirmationText: "" },
        providerAccessAuth: { programId: "", setting: false, confirmationText: "" },
    },
    communicationPreferences: {
        isEOBSetting: null,
        isMedicalSetting: null,
        isPrimeSetting: null,
        isAnocSetting: null,
    },
    hsaPreferences: {
        isbalance: null,
        isClaims: null,
        isInfo: null,
    },
    subscriptionsPreferences: {
        claims: {
            sms: null,
        },
        news: {
            email: null,
            sms: null,
        },
        feedback: {
            email: null,
            sms: null,
        },
    },
    lettersNotices: {
        letterList: []
    }
}

/**
 * Reducer slice
 */
const myAccount = createStatefulSlice({
    name: "myAccount",
    initialState,
    reducers: {
        receiveMyAccountSettings: (
            state: MyAccount,
            action: PayloadAction<MyAccount>,
        ) => {
            return action.payload
        },
        updateMyAccountProfileEmail: (
            state: SliceState<MyAccount>, 
            action: PayloadAction<Email>
        ) => {
            return {
                ...state,
                profile: {...state.profile, email:  action.payload.email }
            }
        },
        receiveMyAccountEmailActivationStatus: (
            state: SliceState<MyAccount>,
            action: PayloadAction<MyAccount>,
        ) => {
            return {
                ...state,
                emailActivation: { status: action.payload.emailActivation.status }
            }
        },
        receiveMyAccountPasswordValidationResult: (
            state: SliceState<MyAccount>,
            action: PayloadAction<ValidationStatus>,
        ) => {
            return {
                ...state,
                passwordValidation: action.payload
            }
        },
        receiveMemberConsumerSettings: (
            state: SliceState<MyAccount>,
            action: PayloadAction<ConsumerSettings>,
        ) => {
            return {
                ...state,
                status: "completed",
                consumerSettings: action.payload,
            }
        },
        receiveMemberCommunicationPreference: (
            state: SliceState<MyAccount>,
            action: PayloadAction<CommPreferences>,
        ) => {
            return {
                ...state,
                status: "completed",
                communicationPreferences: action.payload
            }
        },
        receiveCommunicationPreference: (
            state: SliceState<MyAccount>,
            action: PayloadAction<CommunicationPreferences>,
        ): MyAccount => {
            // Existing state data.
            const existingProfile = state.profile
            const existingPreference = existingProfile?.preferences
            const existingCommPreference = existingPreference?.communicationPreferences

            // Return updated state.
            return {
                ...state,
                status: "completed",
                profile: {
                    ...existingProfile,
                    preferences: {
                        ...existingPreference,
                        communicationPreferences: {
                            ...existingCommPreference,
                            ...action.payload
                        }
                    }
                }
            }
        },
        receiveMemberPhoneNumber: (
            state: SliceState<MyAccount>,
            action: PayloadAction<SMS>,
        ) => {
            return {
                ...state,
                profile: {...state.profile, phone:  {
                    ...state.profile.phone,
                    activated: action.payload.activated,
                    number: action.payload.number
                } },
            }
        },
        receiveMemberFamilyShareInfoList: (state: SliceState<MyAccount>, action: PayloadAction<ReadonlyArray<MemberFamilyShareInfo>>) => {
            return {
                ...state,
                profile: { ...state.profile,familySharing: {
                    ...state.profile.familySharing,
                    familyShareInfoList: action.payload
                }
                }
            }
        },
        receiveMemberHsaPreference: (state: SliceState<MyAccount>, action: PayloadAction<HsaPreferences>) => {
            return {
                ...state,
                hsaPreferences: action.payload
            }
        },
        receiveMemberClaimsSubscriptionPreference: (
            state: SliceState<MyAccount>,
            action: PayloadAction<PreferenceOptions>,
        ) => {
            return {
                ...state,
                subscriptionsPreferences: {
                    ...state.subscriptionsPreferences,
                    claims: action.payload,
                }
            }
        },
        receiveMemberNewsSubscriptionPreference: (
            state: SliceState<MyAccount>,
            action: PayloadAction<PreferenceOptions>,
        ) => {
            return {
                ...state,
                subscriptionsPreferences: {
                    ...state.subscriptionsPreferences,
                    news: action.payload,
                }
            }
        },
        receiveMemberFeedbackSubscriptionPreference: (
            state: SliceState<MyAccount>,
            action: PayloadAction<PreferenceOptions>,
        ) => {
            return {
                ...state,
                subscriptionsPreferences: {
                    ...state.subscriptionsPreferences,
                    feedback: action.payload,
                }
            }
        },
        receiveLetterNotices: (
            state: SliceState<MyAccount>,
            action: PayloadAction<LettersAndNotices>,
        ) => {
            return {
                ...state,
                lettersNotices: action.payload,
            }
        },
        receiveMemberMedicareAddress: (state: SliceState<MyAccount>, action: PayloadAction<Address>) => {
            return {
                ...state,
                profile: { ...state.profile, address: {
                    ...state.profile.address,
                    mailingAddress: action.payload
                }
                }
            }
        },
        receiveMemberMedicareResidentialAddress: (state: SliceState<MyAccount>, action: PayloadAction<Address>) => {
            return {
                ...state,
                profile: { ...state.profile, address: {
                    ...state.profile.address,
                    homeAddress: action.payload
                }
                }
            }
        },
        receiveMemberMedicareBillingAddress: (state: SliceState<MyAccount>, action: PayloadAction<Address>) => {
            return {
                ...state,
                profile: { ...state.profile, address: {
                    ...state.profile.address,
                    billingAddress: action.payload
                }
                }
            }
        },
    }
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const myAccountSettingsActions = { ...myAccount.actions, ...actions, ...additionalActions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default myAccount.reducer
