/**
 * Format string case
 * -----------------------------------------------------------------------------
 * formatCase("hello there", "uppercase")   => "HELLO THERE"
 * formatCase("hello there", "lowercase")   => "hello there"
 * formatCase("hello there", "capitalize")  => "Hello there"
 * formatCase("hello there", "titlecase")   => "Hello There"
 */
export const formatCase = (
    value: string | undefined,
    transform: "uppercase" | "lowercase" | "capitalize" | "titlecase" | "none",
) => {
    if (!value) {
        return value
    }
    switch (transform) {
        case "lowercase":
            return value.toLowerCase()
        case "uppercase":
            return value.toUpperCase()
        case "capitalize": {
            const lowercase = value.toLowerCase()
            return lowercase.charAt(0).toUpperCase() + lowercase.slice(1)
        }
        case "titlecase": {
            const lowercase = value.toLowerCase()
            return lowercase.replace(/\w\S*/g, t => {
                return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase()
            })
        }
        default:
            return value // input cast to string
    }
}

/**
 * Removes the hyphen (-) or underscore (_), and capitalize any letters
 * -----------------------------------------------------------------------------
 */
export const clearAndUpper = (text: string) => {
    return text.replace(/[-_]/, "").toUpperCase()
}

/**
 * Formats snakeCase and kebab-case to camelCase
 * -----------------------------------------------------------------------------
 * toCamelCase("hello-there")   => "helloThere"
 * toCamelCase("hello_there")   => "helloThere"
 */
export const toCamelCase = (text: string) => {
    return text.replace(/(-\w|_\w)/g, clearAndUpper)
}

/**
 * Formats kebab-case to PascalCase
 * -----------------------------------------------------------------------------
 * toPascalCase("hello-there")  => "HelloThere"
 */
export const toPascalCase = (text: string) => {
    return text.replace(/(^\w|-\w)/g, clearAndUpper)
}

/**
 * Formats camelCase and PascalCase to kebab-case
 * -----------------------------------------------------------------------------
 * toKebabCase("helloThere") => "hello-there"
 * toKebabCase("HelloThere") => "hello-there"
 */
export const toKebabCase = (text: string) => {
    return text
        .replace(/([A-Z])([A-Z])/g, "$1-$2") // AB to a-b
        .replace(/([a-z])([A-Z])/g, "$1-$2") // aB to a-b
        .replace(/\s+/g, "-")
        .toLowerCase()
}

/**
 * Convert given `true-false` string values to booleans
 * -----------------------------------------------------------------------------
 *
 * trueFalseStringToBoolean("true")     =>  true
 * trueFalseStringToBoolean("TRUE")     =>  true
 * trueFalseStringToBoolean("false")    =>  false
 * trueFalseStringToBoolean("FALSE")    =>  false
 * trueFalseStringToBoolean(null)       =>  false
 * trueFalseStringToBoolean(undefined)  =>  false
 */
export const trueFalseStringToBoolean = (str: string): boolean => {
    return str?.toLowerCase() === "true"
}

/**
 * Removes special characters and alphabets from string
 * -----------------------------------------------------------------------------
 * toNumber("12@$34ww567er*89") => "123456789"
 * 
 */
/**
 * Remove non-digit characters from string
 * -----------------------------------------------------------------------------
 *
 * removeNonDigitValues("abc123") => "123"
 * removeNonDigitValues("123")    => "123"
 * removeNonDigitValues("")       => ""
 * removeNonDigitValues("./123")  => "123"
 */
export const removeNonDigitValues = (value: string) => {
    if (!value) return value

    // remove any non-digit values
    const number = value.replace(/[^\d]/g, "")
    return number
}
/**
 * Remove non-digit characters from string except forward slash
 * -----------------------------------------------------------------------------
 *
 * formatDate("abc123") => "123"
 * formatDate("123")    => "123"
 * formatDate("")       => ""
 * formatDate("./123")  => "/123"
 */
export const formatDate = (value: string) => {
    if (!value) return value

    // remove any non-digit values
    const number = value.replace(/[^\d/]/g, "")
    return number
}
/**
 * Obfuscate an email address
 * -----------------------------------------------------------------------------
 *
 * obfuscateEmail("testuser@regence.com") => "t******r@r*****e.com"
 * obfuscateEmail("") => null
 * obfuscateEmail("test") => null
 */
export const censorWord = (str: string) => {
    if (!str) return str
    return str[0] + "*".repeat(str.length - 2) + str.slice(-1)
}
export const obfuscateEmail = (email: string) => {
    if (email && email.indexOf("@") > -1) {
        const arr = email.split("@")
        const emailName = arr[0]
        const emailDomain = arr[1].split(".")
        const domainName = emailDomain[0]
        const domainEnd = emailDomain[1]
        return (
            censorWord(emailName) +
            "@" +
            censorWord(domainName) +
            "." +
            domainEnd
        )
    } else {
        return null
    }
}

export const toNumber = (str: string) => {
    return Number(str.replace(/[^.\d]/g, ""))
}

/**
 * Convert given `yes-no` string values to booleans
 * -----------------------------------------------------------------------------
 *
 * yesNoStringToBoolean("yes")      =>  true
 * yesNoStringToBoolean("YES")      =>  true
 * yesNoStringToBoolean("Yes")      =>  true
 * yesNoStringToBoolean("No")       =>  false
 * yesNoStringToBoolean("NO")       =>  false
 * yesNoStringToBoolean(null)       =>  false
 * yesNoStringToBoolean(undefined)  =>  false
 * 
 */
export const yesNoStringToBoolean = (str: string): boolean => {
    return str?.toLowerCase()?.trim() === "yes"
}

/**
 * Obfuscate an phone number 
 * -----------------------------------------------------------------------------
 *
 * obfuscatePhoneNumber("1234567890") => "***-***-7890"
 * obfuscatePhoneNumber("") => null
 * obfuscatePhoneNumber("ascdhkjl") => null
 * obfuscatePhoneNumber("1234") => "1234"
 */
export const obfuscatePhoneNumber = (value: string) => {
    if (!value) return value

    // remove any non-digit values
    const phoneNumber = value.replace(/[^\d]/g, "")
    const phoneNumberLength = phoneNumber.length

    // return fully formatted phone number
    if(phoneNumberLength === 10){
        return `***-***-${phoneNumber.slice(6, 10)}`
    }else{
        return null
    }
}