/**
 * GraphQL query to fetch member cards
 */
 export const fetchableIdCardsQuery =
`query {
  fetchableIdCards {
    birthDate
    firstName
    lastName
    orderDetail {
      orderedDate
    }
    suffix
    url
  }
}`

/**
 * GraphQL mutation to order member card for given memberId
 */
export const memberCardOrderMDPSQuery = 
`mutation($suffixList: [String!]!) {
  OrderMemberCard(suffixList: $suffixList) {
    orderedDate
    suffix
  }
}`