
export const insightsRegenceQuery = (includeDismissed: string) => `
query Insights {
    insights(includeDismissed: ${includeDismissed}) {
      totalCount
      familyMembers {
        membershipId
        firstName
        lastName
        birthDate
        currentAge
        relationship
        disclosureAllowed
        suffix
      }
      pageInfo {
        endCursor
        hasNextPage
        startCursor
      }
      edges {
        ... on InsightEdge {
          cursor
          node {
            ... on ContentInsight {
              type
              insightId
            }
            ... on InfoInsight {
              analyticsTitle
              category
              icon
              insightId
              subtitle
              title
              type
              member {
                membershipId
              }
              action {
                ... on ContentInsightAction {
                  type
                  impressionId
                  label
                }
                ... on ContentInsightChatAction {
                  chat
                }
                ... on ContentInsightTabAction {
                  tab
                }
                ... on MedSavvyWebViewAction {
                  route
                }
                ... on MedSavvySwitchMedsAction {
                  campaignId
                }
                ... on ContentInsightNavigationAction {
                  route
                }
                ... on ContentInsightUrlAction {
                  url
                }
              }
              isNew
              startDate
              endDate
              dismissed
              dismissedAt
            }
          }
        }
      }
    }
  }
`

export const giveCardFeedback = `
mutation GiveInsightFeedback($input: InsightFeedbackInput!) {
    createInsightFeedback(input: $input) {
        insightId
        impressionId
        isDismissed
    }
}
`
