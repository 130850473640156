import { Insight } from "./types"

/**
 * Mapping known mismatched icon name to the correct icon type
 */
const iconName = {
    "checkmark": "check-circle",
    "question": "question-circle",
    "inhaler-alt" : "inhaler"
}

export const transformInsightsApiData = (
    insights: ReadonlyArray<any>, // eslint-disable-line
): ReadonlyArray<Insight> => {
    return insights.map(insight => {
        return {
            membershipId: insight.node.member.membershipId,
            insightId: insight.node.insightId,
            impressionId: insight.node.impressionId,
            type: insight.node.type,
            patientInitials: insight.node.patientInitials,
            title: insight.node.title,
            subtitle: insight.node.subtitle,
            analyticsTitle: insight.node.analyticsTitle,
            category: insight.node.category,
            icon: iconName[insight.node.icon] || insight.node.icon,
            member: insight.node.member ? { membershipId: insight.node.member.membershipId } : undefined,
            action: insight.node.action
                ? {
                    type: insight.node.action.type,
                    impressionId: insight.node.action.impressionId,
                    label: insight.node.action.label,
                    chat: insight.node.action.chat,
                    tab: insight.node.action.tab,
                    route: insight.node.action.route,
                    campaignId: insight.node.action.campaignId,
                    url: insight.node.action.url,
                }
                : undefined,
            isNew: (insight.node.isNew == undefined ? false : insight.node.isNew),
            startDate: insight.node.startDate,
            endDate: insight.node.endDate,
            dismissed: (insight.node.dismissed == undefined ? false : insight.node.dismissed),
            dismissedAt: insight.node.dismissedAt,
        }
    })
}
