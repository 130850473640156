import { createSelector } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import { useMemo } from "react"

import { State } from "../store"

const selectBrand = (state: State) => state.app.brand
const selectFeatures = (state: State) => state.app.features

/**
 * Utility "factory" function to support the useFeatureByName hook.
 *
 * See "selector ... used in multiple component instances and depends on the component's props"
 * in [this doc](https://react-redux.js.org/api/hooks#using-memoizing-selectors)
 * for more details.
 */
const makeFeatureByNameSelector = () =>
    createSelector(
        selectFeatures,
        selectBrand,
        (_: State, featureName: string) => featureName,
        (features, brand, featureName: string) =>
            features[featureName] === true,
    )

/**
 * Subscribe to `state.app.features` within the Redux store.
 *
 */
export const useFeatures = () => {
    const features = useSelector(selectFeatures)
    return features
}

/**
 * Determines if a specific feature is enabled based on current
 * Redux store. This hook is useful in optimized components, as a re-render should
 * only occur when `state.app.features["myFeatureName"]` changes.
 *
 * Returns `true` or `false` for RIHC tenants.
 */
export const useFeatureByName = (featureName: string) => {
    /**
     * Use makeFeatureSelector and useMemo to ensure
     * memoization is maintained when passing in featureName
     * to the selector.
     *
     * See [here](https://github.com/reduxjs/reselect#sharing-selectors-with-props-across-multiple-component-instances)
     * for more info.
     */
    const featureByNameSelector = useMemo(makeFeatureByNameSelector, [])

    const feature = useSelector<State, boolean>(state =>
        featureByNameSelector(state, featureName),
    )

    return feature
}
