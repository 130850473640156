/**
 * Action Sheet
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Item } from "./action_menu"
import { Props as ButtonProps } from "./button"
import { Button, Card, ActionMenu, IconTypes } from "elements"
import { useKeyPress } from "utils"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props extends React.HTMLAttributes<HTMLDivElement> {
    readonly name: string
    readonly items: ReadonlyArray<Item>
    readonly icon?: IconTypes
    readonly align?: "left" | "right"
    readonly direction?: "down" | "up"
    readonly type?: "icon" | "select"

    // Button props
    readonly label?: ButtonProps["label"]
    readonly labelClass?: ButtonProps["labelClass"]
    readonly variant?: ButtonProps["variant"]
    readonly size?: ButtonProps["size"]
    readonly iconNode?: ButtonProps["iconNode"]
    readonly iconSize?: ButtonProps["iconSize"]
    readonly analytics?: ButtonProps["analytics"]
    readonly buttonClassName?: string
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const ActionSheet: React.FC<Props> = props => {
    /**
     * Hooks
     */
    useKeyPress("Escape", () => setShowActions(false))

    /**
     * Component state
     */
    const [showActions, setShowActions] = React.useState(false)

    /**
     * Refs
     */
    const ref = React.useRef<HTMLDivElement>(null)

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        window.addEventListener("mousedown", onMouseDown)
        return () => {
            window.removeEventListener("mousedown", onMouseDown)
        }
    }, [])

    /**
     * Methods
     */
    const onMouseDown = (event: MouseEvent) => {
        if (!ref.current?.contains(event.target as Node)) {
            setShowActions(false)
        }
    }

    /**
     * Define template variables
     */
    const icon = props.type !== "select" ? props.icon || "ellipsis" : undefined
    const iconRight =
        props.type === "select"
            ? showActions
                ? "chevron-up-sm"
                : "chevron-down-sm"
            : undefined
    const positionClass =
        props.direction === "up"
            ? props.size === "small"
                ? "bottom-lg"
                : "bottom-xl"
            : props.size === "small"
                ? "top-lg"
                : "top-xl"

    /**
     * Template
     */
    return (
        <div ref={ref} className={"inline relative " + props.className}>
            <Button
                name={props.name + "-toggle"}
                analytics={props.analytics}
                id={props.name + "-toggle"}
                label={props.label}
                labelClass={props.labelClass}
                icon={icon as IconTypes}
                iconRight={iconRight as IconTypes}
                iconNode={props.iconNode}
                iconSize={props.iconSize}
                variant={props.variant || "secondary"}
                size={props.size || "medium"}
                className={!props.variant ? `${props.buttonClassName} border-none` : `${props.buttonClassName}`}
                onClick={() => setShowActions(!showActions)}
            />

            {showActions && (
                <Card
                    name={props.name + "-card"}
                    className={`absolute z-0 -mt-xxxs w-max min-w-[240px] overflow-hidden shadow-lg ${positionClass} ${props.align === "right" ? "right-none" : "left-none"
                        }`}
                    size="small"
                >
                    <div className="-my-xs -mx-md">
                        <ActionMenu
                            name={props.name + "-options"}
                            items={props.items.map(item => {
                                return {
                                    ...item,
                                    onClick: () => {
                                        setShowActions(false)
                                        item.onClick()
                                    },
                                }
                            })}
                        />
                    </div>
                </Card>
            )}
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default ActionSheet
