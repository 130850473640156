/**
 * Currency Utilities
 * -----------------------------------------------------------------------------
 */

/**
 * Format Currency
 * https://stackoverflow.com/a/16233919/922593
 */
export const formatCurrency = (
    value?: number | string,
    hidePennies?: boolean,
) => {
    const formmater = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: hidePennies ? 0 : undefined,
        maximumFractionDigits: hidePennies ? 0 : undefined,
    })

    const number = typeof value === "string" ? parseInt(value) : value

    return formmater.format(number || 0)
}
