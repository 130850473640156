export const premiumPayDFISQuery = `
query {
       premium {
              onlinePaymentEligible
              payments {
                     subscriberId
                     groupId
                     pastDueAmount
                     newChargeAmount
                     totalAmountDue
                     isPaymentPastDue
                     paymentDate
                     dueDate
                     paybyDate
                     paymentEligibleFlag
                     pendingPaymentFlag
                     printStatementId
                     coverageTerminated
                     eftFlag 
                     exclusionReason
                     invoicePendingFlag
              }
              history {
                     yearToDatePaidAmount
                     paymentHistoryDetails {
                            receiptDate
                            receiptAmount
                            reasonCode
                            paymentId
                            paymentType
                            pendingPaymentFlag
                            subscriberId
                            groupId
                     }
              }
       }
} 
`
