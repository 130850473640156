/**
 * Element Utilities
 * -----------------------------------------------------------------------------
 */

export const hideElement = (parentElement: HTMLElement | HTMLIFrameElement, selector: string) => {
    // eslint-disable-next-line functional/no-let
    let selectedElement = null

    if ("contentDocument" in parentElement) {
        selectedElement = parentElement.contentDocument.querySelector(selector) as HTMLElement
    }

    if (selectedElement) {
        // eslint-disable-next-line functional/immutable-data
        selectedElement.style.display = "none"
    }
}