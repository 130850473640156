/**
 * Event Card
 * -----------------------------------------------------------------------------
 */
import React from "react"
import Features from "@/utils/features"
import { useFeatures, useSelector } from "store"
import { Card, Avatar, Icon, IconTypes, Shimmer, AvatarStack, Link } from "elements"
import Pill, { Props as PillProps } from "elements/pill"
import { separateDate } from "utils/date"
import { LinkEvent } from "utils/analytics"
import { Opportunity } from "@/store/rx/opportunities/types"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    // Detail props
    readonly name: string
    readonly icon: IconTypes
    readonly title?: string
    readonly subtitle?: ReadonlyArray<string>
    readonly details?: ReadonlyArray<string>
    readonly date?: Date | string
    readonly status?: PillProps
    readonly adjusted?: PillProps
    readonly type?: PillProps
    readonly coverage?: PillProps
    readonly initials?: string
    readonly opportunity?: Opportunity
    readonly medication_id?: string

    // Dropdown content
    readonly children?: React.ReactNode

    // Interactive props
    readonly href?: string
    readonly onClick?: VoidFunction
    readonly stopPropagation?: boolean
    readonly preventDefault?: boolean
    readonly analytics?: Partial<LinkEvent>

    // Visual props
    readonly className?: string
    readonly shimmer?: boolean
    readonly showInitials?: boolean
    readonly disableShadow?: boolean
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const EventCard: React.FC<Props> = props => {
    /**
     * Component state
     */
    const [visible, setVisible] = React.useState(false)
    const app = useSelector(state => state.app)
    const brand = app.brand
    const isBSH = brand === "bridgespan"

    /**
     * Methods
     */
    const onClick = () => {
        // Handle `onClick` prop
        if (props.onClick) props.onClick()

        // Toggle child contents
        if (props.children) setVisible(!visible)
    }

    /**
     * Define template values
     */
    const { day, month, year } = separateDate(props.date || new Date())
    const detailStyle = isBSH ? "text-sm mt-xxs font-bold" : "text-sm mt-xxs"
    const campaignRoute = props.opportunity?.route
    const campaignMessage = props.opportunity?.campaignMessage
    const coverageLabel = props.coverage?.label || "coverage loading ..."

    /**
     * Template
     */
    return (
        <div className={`${campaignRoute ? "shadow rounded-sm" : ""}`}>
            <Card
                name={props.name}
                href={props.href}
                onClick={onClick}
                stopPropagation={props.stopPropagation}
                preventDefault={props.preventDefault}
                disablePadding={true}
                disableShadow={props.disableShadow}
                className={`overflow-hidden ${props.className || ""}`}
                analytics={props.analytics}
            >
                <div className="md:flex">
                    {/* Date */}
                    {props.date && (
                        <div className="bg-gray-25 dark:bg-dark-100 px-sm py-xs md:py-sm">
                            <div
                                className="text-center"
                                style={{ minWidth: "36px" }}
                            >
                                {!props.shimmer && (
                                    <React.Fragment>
                                        <h6 className="text-left mb-none md:hidden">
                                            {month} {day}, {year}
                                        </h6>
                                        <h6 className="mb-none font-normal hidden md:block">
                                            {month}
                                        </h6>
                                        <h4 className="mb-none  hidden md:block">
                                            {day}
                                        </h4>
                                        <div className="text-sm  hidden md:block">
                                            {year}
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    )}
                    <div className="flex-1">
                        <div className="px-sm py-sm lg:flex items-center">
                            <div className="flex justify-between lg:block mb-sm lg:mb-none">
                                {props.showInitials ? (
                                    <AvatarStack
                                        className="mr-sm"
                                        items={[
                                            { icon: props.icon },
                                            {
                                                label: props.initials,
                                                variant: "primary",
                                                size: "medium",
                                            },
                                        ]}
                                    />
                                ) : (
                                    <Avatar
                                        icon={props.icon}
                                        shimmer={props.shimmer}
                                        className="mr-sm"
                                    />
                                )}
                                {/* Arrow: Mobile-only */}
                                <div className="lg:hidden">
                                    {props.children && (
                                        <Icon
                                            type={
                                                visible
                                                    ? "chevron-up-sm"
                                                    : "chevron-down-sm"
                                            }
                                            className="text-link"
                                        />
                                    )}
                                    {props.href && (
                                        <Icon
                                            type="chevron-right-sm"
                                            className="text-link"
                                        />
                                    )}
                                </div>
                            </div>

                            <div className="flex-1 lg:flex items-center">
                                {/* Titles + Additional Content */}
                                <div className="flex-1 pr-sm">
                                    {props.title && (
                                        <div className="mb-xxs">
                                            <strong>
                                                <Shimmer
                                                    placeholder={props.title}
                                                    value={props.title}
                                                    isLoading={props.shimmer}
                                                />
                                            </strong>
                                        </div>
                                    )}
                                    {props.subtitle?.length > 0 &&
                                        props.subtitle.map((subtitle, index) => (
                                            <div
                                                className="text-md mb-xxs"
                                                key={index}
                                            >
                                                <Shimmer
                                                    placeholder={subtitle}
                                                    value={subtitle}
                                                    isLoading={props.shimmer}
                                                />
                                            </div>
                                        ))}
                                    {props.status && (
                                        <Pill
                                            label={props.status.label}
                                            variant={props.status.variant}
                                            shimmer={props.shimmer}
                                            inverted={props.status?.inverted}
                                        />
                                    )}
                                    {props.adjusted && (
                                        <Pill
                                            className="ml-xxs"
                                            label={props.adjusted.label}
                                            variant={props.adjusted.variant}
                                            shimmer={props.shimmer}
                                            inverted={props.adjusted?.inverted}
                                        />
                                    )}
                                    {props.type && (
                                        <Pill
                                            className="ml-xxs"
                                            label={props.type.label}
                                            variant={props.type.variant}
                                            shimmer={props.shimmer}
                                            inverted={props.type?.inverted}
                                        />
                                    )}
                                    {/* Medication coverage */}
                                    {props.coverage && (
                                        <Pill
                                            className={
                                                props.coverage.className || "ml-xxs"
                                            }
                                            dataTest="coverage-pill"
                                            label={coverageLabel}
                                            variant={props.coverage.variant}
                                            iconLeading={props.coverage.iconLeading}
                                            size={props.coverage.size}
                                            shimmer={props.coverage.shimmer}
                                        />
                                    )}
                                    {props.children && (
                                        <div className={visible ? "" : "hidden"}>
                                            <div className="border-t border-muted my-sm" />
                                            {props.children}
                                        </div>
                                    )}
                                </div>

                                {/* Details */}
                                {props.details && (
                                    <div className="lg:text-right lg:pr-sm mt-sm lg:-mt-xxs">
                                        {props.details.map((detail, index) => (
                                            <div
                                                className={detailStyle}
                                                key={index}
                                            >
                                                <Shimmer
                                                    placeholder={detail}
                                                    value={detail}
                                                    isLoading={props.shimmer}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                            {/* Arrow: Desktop-only */}
                            <div className="hidden lg:block">
                                {props.children && (
                                    <Icon
                                        type={
                                            visible
                                                ? "chevron-up-sm"
                                                : "chevron-down-sm"
                                        }
                                        className="text-link"
                                    />
                                )}
                                {props.href && (
                                    <Icon
                                        type="chevron-right-sm"
                                        className="text-link"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
            {campaignRoute && (
                <div
                    className="bg-success-50 mb-sm px-xs py-xxs flex items-center justify-between rounded-b-sm"
                    data-test="medication-card-insight"
                >
                    <div className="flex items-center">
                        <Icon type="check-circle-alt" variant="success" className="mr-xxs" />
                        {campaignMessage}
                    </div>
                    <Link
                        name="medication-card-insight-link"
                        data-test="medication-card-insight-link"
                        href={campaignRoute || ""}
                        analytics={{
                            medication_id: props.medication_id,
                        }}
                    >
                        Learn more
                    </Link>
                </div>
            )
            }
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default EventCard
