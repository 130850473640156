export const pharmaciesQuery = (
    firstName: string,
    lastName: string,
    memberId: string,
    dateOfBirth: string,
    zipCode: string,
) => {
    return `
  query pharmaciesQuery {
    rxPharmacies(
      firstName: "${firstName}",
      lastName: "${lastName}",
      memberId: "${memberId}",
      dateOfBirth: "${dateOfBirth}",
      zipCode: "${zipCode}",
    )
    {
      id
      name
      phoneNumber
      address {
        line
        city
        state
        postalCode
      }
      hoursOfOperation {
        daysOfWeek
        allDay
        openingTime
        closingTime
      }
      accessibilityItems
      additionalServices {
          code
          display
      }
      languages
      url
    }
  }
`
}
