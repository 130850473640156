import * as React from "react"
import { SVGProps } from "react"
const SvgCardPerson = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Identification card"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M15 12H19M15 15H19M15 9H19M1 19V5C1 3.89543 1.89543 3 3 3H21C22.1046 3 23 3.89543 23 5V19C23 20.1046 22.1046 21 21 21H3C1.89543 21 1 20.1046 1 19ZM8.00003 11C7.19771 11 6.50003 10.3333 6.50003 9.51852C6.50003 8.66667 7.19771 8 8.00003 8C8.80236 8 9.50004 8.66667 9.50004 9.51852C9.50004 10.3704 8.83724 11 8.00003 11ZM11 16C11 14.3478 9.65307 13 8.02041 13C6.38776 13 5 14.3478 5 16H11Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgCardPerson
