export const rxSearchStartsWithQuery = function (suggestTerm: string) {
    return `
      query suggest {
        rxSuggest(suggestTerm: "${suggestTerm}") {
          rxTreatments {
            id
            primaryName
            secondaryName
          }
          rxConditions {
            id
            fullName
          }
        }
      }
    `
}