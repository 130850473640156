import { DFISExtensions, PremiumPaymentInfoItem } from "./types"
import { separateDate } from "@/utils/date"
import { isBefore, addDays, subDays } from "date-fns"

export const transformPremiumPayApiData = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    extensions: DFISExtensions // eslint-disable-line
): PremiumPaymentInfoItem => {

    const paymentHistory = data?.history.paymentHistoryDetails.map((item) => {
        return {
            receiptDate: item.receiptDate,
            receiptAmount: item.receiptAmount,
            reasonCode: item.reasonCode,
            paymentId: item.paymentId,
            paymentType: item.paymentType,
            pendingPaymentflag: item.pendingPaymentFlag,
            subscriberId: item.subscriberId,
            groupId: item.groupId,
            // extract payment year
            paymentYear: separateDate(item.receiptDate)?.year,
        }
    })
    return {
        onlinePaymentEligible: data?.onlinePaymentEligible,
        premiumPayment: {
            subscriberId: data?.payments.subscriberId,
            groupId: data?.payments.groupId,
            eftFlag: data?.payments.eftFlag,
            totalAmountDue: data?.payments.totalAmountDue,
            pastDueAmount: data?.payments.pastDueAmount,
            newChargeAmount: data?.payments.newChargeAmount,
            pendingPaymentFlag: data?.payments.pendingPaymentFlag,
            printStatementId: data?.payments.printStatementId,
            dueDate: data?.payments.dueDate,
            paymentEligibleFlag: data?.payments.paymentEligibleFlag,
            exclusionReason: data?.payments.exclusionReason,
            paybyDate: data?.payments.paybyDate,
            paymentDate: data?.payments.paymentDate,
            coverageTerminated: data?.payments.coverageTerminated,
            invoicePendingFlag: data?.payments.invoicePendingFlag,
            isPaymentPastDue: data?.payments.isPaymentPastDue,
        },
        premiumPaymentHistory: {
            yearToDatePaidAmount: data?.history.yearToDatePaidAmount,
            paymentHistory: paymentHistory,
        },
        extensions : extensions
    }
}


