/**
 * Accumulators Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions } from "store"
import http from "utils/http"
import { transformAccumulatorsAPIData } from "./utils"
import { accumulatorsQuery } from "./queries"

/**
 * Get accumulators data
 * -----------------------------------------------------------------------------
 */
export const fetchAccumulators = createAsyncThunk(
    "accumulators/fetchAccumulators", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.accumulatorsStatus("pending"))

        /**
         * Request accumulators data
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            accumulatorsQuery(),
        )

        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.accumulatorsError({
                    response: res,
                }),
            )
        }

        /**
         * Transform accumulators data
         */
        const transformed = transformAccumulatorsAPIData(res.data?.data)
        const viewableMembers = transformed.familyMembers.filter(member=> member.isSharingAuthorized === true) 
        /**
         * Update state
         */
        dispatch(
            actions.receiveAccumulators({
                accumulators: transformed.accumulators || {},
                family: {
                    items: viewableMembers  || [],
                },
            }),
        )
    },
)
