/* eslint-disable functional/no-let */
/**
 * App Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import Router from "next/router"
import { actions } from "store"
import http from "utils/http"
import { getEnvironment, getBrand, isLocalDevelopment, isDemoEnvironment } from "utils/env"
import { initAnalyticsData } from "utils/analytics"
import { initQualtricsScript } from "@/utils/qualtrics"
import { getAdifRedirect } from "utils/redirects"
import { BrandStrings } from "./types"
import { CONSUMER_RULES } from "@/utils/consumer-rules"
import Features, {
    consumerFeatureConfig,
    displayFeatureConfig,
    featureToggleConfig,
} from "@/utils/features"
import {
    transformDisplayRulesToFeatures,
    transformConsumerRulesToFeatures,
    transformAppModelFeatureTogglesToFeatures,
    transformOverrideFeatureFlagsToFeatures,
    checkOnboarding,
} from "@/store/app/utils"
import { datadogRum } from "@datadog/browser-rum"
import { signoutHandler } from "@/utils/sign-out"

/**
 * Initialize application
 * -----------------------------------------------------------------------------
 */
export const initialize = createAsyncThunk(
    "app/initialize", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(
            actions.receiveApp({
                isInitialized: false,
                isAuthenticated: false,
                isLoading: true,
            }),
        )

        /**
         * Define brand and environment
         */
        const host = window.location.host
        const environment = getEnvironment(host)
        const isDemo = isDemoEnvironment(host)
        const urlBrand = getBrand(host)
        const userBrand = localStorage.getItem("THEME")
        const brand = environment !== "prd" ? userBrand || urlBrand : urlBrand
        const brandStrings = getBrandStrings(brand)

        /**
         * Define default dark mode preference
         */
        const userDarkMode = localStorage.getItem("DARK_MODE")
        const darkMode = userDarkMode || "system"

        /**
         * Define development flags
         */
        const queryParams = new URLSearchParams(window.location.search)
        const _flags = queryParams.get("flags")
        const flags = _flags ? _flags.split(",") : []
        const ecid = queryParams.get("ecid")

        const datadog = await getDatadogRUMConfig()

        /**
         * Set app environment
         */
        dispatch(
            actions.receiveApp({
                datadog,
                environment,
                isDemo,
                brandStrings,
                darkMode,
                brand,
                flags,
                ecid,
            }),
        )

        /**
         * Request initialize data
         */
        const endpoint = v2InitializeEndpoint()

        const { data: initializeResponseData, error: initializeError } = await http.get(endpoint)
        if (initializeError) {
            // return because below we check response values not safely
            return dispatch(
                actions.receiveApp({
                    isLoading: false,
                    isInitialized: true,
                    isAuthenticated: false,
                    errorMessage: initializeError.message,
                }),
            )
        }

        // assign a default value for pending
        // eslint-disable-next-line functional/immutable-data
        initializeResponseData.userMeta.pending = initializeResponseData?.userMeta?.pending || {}

        let featuresData: Record<string, boolean> = {}

        if (initializeResponseData.isAuthenticated) {
            const body = {
                consumerRulesConfig: consumerFeatureConfig,
                displayRulesConfig: displayFeatureConfig,
                appModelFlagsConfig: featureToggleConfig,
            }

            const featuresEndpoint = v2FeaturesEndpoint()
            const { data: featuresResponseData, error: featuresError } = await http.post(featuresEndpoint, body, {
                timeout: 35000,
            })

            if (featuresError) {
                await dispatch(
                    actions.receiveApp({
                        isLoading: false,
                        isInitialized: true,
                        isAuthenticated: false,
                        errorMessage: featuresError.message,
                    }),
                )

                signoutHandler()
                return
            }
            const {
                displayRules,
                consumerRules,
                appModelFlags, //the cookie is done in DFARS and need transformation here
            } = featuresResponseData

            const displayRulesData =
                transformDisplayRulesToFeatures(displayRules)
            const consumerRulesData =
                transformConsumerRulesToFeatures(consumerRules)
            const appModelResponseData =
                transformAppModelFeatureTogglesToFeatures(appModelFlags)
            const overrideFeatureFlagsData =
                transformOverrideFeatureFlagsToFeatures()

            featuresData = {
                ...displayRulesData,
                ...consumerRulesData,
                ...appModelResponseData,
                ...overrideFeatureFlagsData,
            }

            // update datadog rum session with janusId if enabled
            const isRumSetUserEnabled = consumerRulesData?.[Features.DATADOG_RUM_USERID_ENABLED]
            if (isRumSetUserEnabled) {
                const getJanusClaimsResp = await http.get("/api/janus-wt")

                if(!getJanusClaimsResp?.error && getJanusClaimsResp?.data?.id){
                    datadogRum.setUser({
                        id: getJanusClaimsResp.data.id,
                        tenant: getJanusClaimsResp.data.tenant,
                    })
                } else {
                    // log error
                    console.warn("Failed to set RUM janusID", getJanusClaimsResp.error)
                }
            }
        }
        //Add a call to get feature flags from EMS
        const { data: emsFeatureFlagsData, error: emsFeatureFlagsError } = await getEmsFeatureFlags()

        let emsFeatureFlags: Record<string,boolean>
        
        if (emsFeatureFlagsError) {
            // Set EMS Feature flags object to empty when there is an error from EMS
            emsFeatureFlags = {}
        } else {
            // Return the list of feature flags received from EMS
            emsFeatureFlags = emsFeatureFlagsData
        }
        
        /**
         * Validate route access and conditionally redirect
         */
        if (initializeResponseData.isAuthenticated) {
            const redirect = await getAdifRedirect({
                router: Router,
                userMeta: initializeResponseData.userMeta,
                features: featuresData,
            })
            if (redirect) return Router.push(redirect)
        }

        /**
         * Validate user has completed onboarding
         */
        if (initializeResponseData.isAuthenticated && initializeResponseData.userMeta) {
            const isJointAdmin = featuresData?.[Features.JOINT_ADMIN]
            const isAllowedPaperlessPrefs = featuresData?.[Features.ACCOUNT_SETTINGS_PAPERLESS_PREFERENCE]
            //if onboarding is pending, redirect to onboarding
            await checkOnboarding(
                initializeResponseData,
                featuresData?.dfwMyAccountEnabled,
                isJointAdmin,
                isAllowedPaperlessPrefs
            )
        }

        /**
         * Define default dark mode preference
         */

        /**
         * Update state
         */
        await dispatch(
            actions.receiveApp({
                ...initializeResponseData,
                features: featuresData,
                emsFeatureFlags,
            }),
        )

        /**
         * Request important "first fetch" data
         * // TODO: those this need to be blocking await?
         */
        if (initializeResponseData.isAuthenticated) {
            const isQualtricsSurveyEnabled = featuresData?.[Features.QUALTRICS_SURVEY_ENABLED]

            await dispatch(actions.getAccount())
            await dispatch(actions.fetchMemberSettings())
            await dispatch(actions.fetchLetterNotices())
            initAnalyticsData(initializeResponseData.isAuthenticated)

            // Needs to clear QSI on page load
            if (globalThis?.QSI) {
                // eslint-disable-next-line functional/immutable-data
                globalThis.QSI = undefined
            }

            // Needs to clear _qsie on page load
            if (globalThis?._qsie) {
                // eslint-disable-next-line functional/immutable-data
                globalThis._qsie = undefined
            }

            // Initialize qualtrics script if eligible for user
            if (isQualtricsSurveyEnabled) {
                initQualtricsScript(true)
            }
        }

        /**
         * Init complete
         */
        dispatch(
            actions.receiveApp({
                isLoading: false,
                isInitialized: true,
            }),
        )
    },
)

/**
 * Get list of Feature flags from EMS
 */
export const getEmsFeatureFlags = async () => {
    try {
        return http.get("/api/janus/experience-management-service/feature-flags")
    } catch (err) {
        console.error(
            `Failed to fetch feature flags from experience-management-service - received ${err.response?.status || err.status}`,
            err,
        )
        return {error:err , data: undefined}
    }
}

export const getDatadogRUMConfig = async () => {
    const { error, data } = await http.get("/api/env")

    if (error) {
        return null
    }

    return data?.datadog?.rum
}

export const getBrandStrings = (brand: string): BrandStrings => {
    if (brand === "regence") {
        return {
            name: "regence",
            hsaMessage:
                "Balance does not include pending charges and may not be up-to-date.",
        }
    } else if (brand === "asuris") {
        return {
            name: "asuris",
            hsaMessage:
                "Balance does not include pending charges and may not be up-to-date.",
        }
    } else if (brand === "bridgespan") {
        return {
            name: "bridgespan",
            hsaMessage:
                "Balance does not include pending charges and may not be up-to-date.",
        }
    } else {
        return {
            name: "",
            hsaMessage: "",
        }
    }
}

export const resetDfwAuth = createAsyncThunk(
    "app/initialize", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set the dfwAuth in the state
         */
        dispatch(
            actions.receiveApp({
                isDfwAuth: false,
            }),
        )
    },
)

export const getAdvantmedRule = async (zip: string) => {
    const body = {
        rules: [{
            category: CONSUMER_RULES.IN_HOME_CARE_CARD_ADVANTMED.CATEGORY,
            name: CONSUMER_RULES.IN_HOME_CARE_CARD_ADVANTMED.NAME, 
        }],
        data: {
            "location/zip": zip,
            "mps-home-address/zip": zip,
        }
    }
    return await http.post("/api/janus/experience-management-service/experience-rules", body)
}

/**
 * DFG endpoint is not available to DFW local development.
 * Local development will be using the janus proxy for calling DFARS.
 */
const v2InitializeEndpoint = () => {
    return isLocalDevelopment(window?.location?.host)
        ? "/api/janus/digital-first-api-routing-service/initialize"
        : "/v2/api/initialize"
}

/**
 * DFG endpoint is not available to DFW local development.
 * Local development will be using the janus proxy for calling DFARS.
 */
const v2FeaturesEndpoint = () => {
    return isLocalDevelopment(window?.location?.host)
        ? "/api/janus/digital-first-api-routing-service/features"
        : "/v2/api/features"
}
