/**
 * Card
 * -----------------------------------------------------------------------------
 */
import React from "react"
import Button, { Props as ButtonProps } from "elements/button"
import ActionMenu, { Item } from "elements/action_menu"
import ActionSheet from "elements/action_sheet"
import Clickable, { ClickableProps } from "elements/clickable"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Props
    extends Omit<React.HTMLAttributes<HTMLDivElement>, "onClick">,
    ClickableProps {
    // Content
    readonly name: string
    readonly children: React.ReactNode

    // Header props
    readonly title?: string
    readonly buttons?: ReadonlyArray<ButtonProps>

    // Visual props
    readonly size?: "small" | "medium" | "large"
    readonly inverted?: boolean
    readonly disableShadow?: boolean
    readonly disablePadding?: boolean
    readonly backgroundColor?: string
    readonly titleClass?: string
    readonly titleTextSize?: string
    readonly verticalPadding?: string

    // Modal props
    readonly onDismiss?: VoidFunction
    readonly menuItems?: ReadonlyArray<Item>
    readonly modalButton?: Item
    readonly noBorder?: boolean
    readonly noDismiss?: boolean
    readonly overflowYScroll?: boolean

    // External link modal props
    readonly isExternal?: boolean
    readonly isExternalOnClick?: boolean
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Card = (props: Props) => {
    /**
     * Styles
     */
    const base = ""
    const color = props.inverted
        ? `${props.backgroundColor || "bg-primary-300"} text-white`
        : `${props.backgroundColor || "bg-white"} dark:bg-dark-200`
    const padding = props.disablePadding ? "py-[0px] px-[0px]" : "py-sm px-sm"
    const verticalPadding = props.verticalPadding
    const shadow = props.disableShadow ? "" : "shadow"
    const noBorder = props.noBorder ? "" : "border-b border-muted"

    const sizes = {
        small: "rounded-two",
        medium: "rounded-sm",
        large: "rounded",
    }
    const size = sizes[props.size || "medium"]
    const className = `${base} ${color} ${padding} ${verticalPadding} ${shadow} ${size} ${props.className || ""
        }`

    /**
     * Define template variables
     */
    const hasMenu = props.menuItems?.length
    const hasModalButton = Boolean(props.modalButton)

    /**
     * Template
     */
    return (
        <Clickable
            className={className || ""}
            name={props.name}
            id={props.id}
            href={props.href}
            target={props.target}
            onClick={props.onClick}
            analytics={props.analytics}
            stopPropagation={props.stopPropagation}
            preventDefault={props.preventDefault}
            isExternal={props.isExternal}
            isExternalOnClick={props.isExternalOnClick}
        >
            {(props.title || props.buttons || props.onDismiss) && (
                <div
                    className={`${noBorder} ${props.titleClass ||
                        "-mx-xxs px-xxs -mt-xxs mb-xs pb-xxs"
                        }`}
                >
                    <div className="flex items-center">
                        <h5
                            className={`mb-none flex-1  ${hasMenu || hasModalButton
                                    ? "order-2 md:order-1"
                                    : ""
                                } ${hasMenu || (hasModalButton && props.onDismiss)
                                    ? "text-center md:text-left"
                                    : ""
                                } ${props.titleTextSize}`}
                            tabIndex={0}
                        >
                            {props.title}
                        </h5>

                        {props.onDismiss && !props.noDismiss && (
                            <Button
                                name={props.name + "-dismiss-button"}
                                icon="x"
                                variant="link"
                                size="small"
                                onClick={() => props.onDismiss()}
                                className={
                                    hasMenu || hasModalButton
                                        ? "order-1 md:order-2"
                                        : ""
                                }
                            />
                        )}

                        {props.buttons && (
                            <div className="order-2">
                                {props.buttons.map((button, index) => (
                                    <Button
                                        {...button}
                                        variant="link"
                                        inverted={props.inverted}
                                        className="w-auto h-auto ml-xs"
                                        key={index}
                                    />
                                ))}
                            </div>
                        )}

                        {/* Menu (Mobile) */}
                        {hasMenu && (
                            <div className="block md:hidden order-3">
                                <ActionSheet
                                    name={props.name + "-menu-sheet"}
                                    items={props.menuItems}
                                    align="right"
                                    size="small"
                                    iconSize={24}
                                />
                            </div>
                        )}

                        {/* Modal Button (Mobile) */}
                        {hasModalButton && (
                            <div className="block md:hidden order-3">
                                <Button
                                    name="mobile-modal-button"
                                    label={props.modalButton?.label}
                                    onClick={props.modalButton?.onClick}
                                    variant="link"
                                    className="pl-none pr-none"
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className={`flex space-x-xxl h-full ${props.overflowYScroll ? "overflow-y-scroll overflow-x-hidden" : ""}`}>
                <div className="flex-1 min-w-none max-w-full">
                    {props.children}
                </div>

                {/* Menu (Tablet/Desktop) */}
                {hasMenu && (
                    <div className="hidden md:block">
                        <ActionMenu
                            name={props.name + "-menu"}
                            items={props.menuItems}
                            appearance="sidebar"
                        />
                    </div>
                )}

                {/* Modal Button (Tablet/Desktop) */}
                {hasModalButton && (
                    <div className="hidden md:block">
                        <Button
                            name="mobile-modal-button"
                            label={props.modalButton?.label}
                            onClick={props.modalButton?.onClick}
                            variant="link"
                            className="pl-none pr-none"
                        />
                    </div>
                )}
            </div>
        </Clickable>
    )
}

/**
 * Subcomponent: Content
 * -----------------------------------------------------------------------------
 */
export const Content = (props: {
    readonly children: React.ReactNode
    readonly className?: string
}) => {
    const base = "-mx-sm px-sm"
    const className = `${base} ${props.className || ""}`
    return <div className={className}>{props.children}</div>
}

/**
 * Subcomponent: Footer
 * -----------------------------------------------------------------------------
 */
export const Footer = (props: {
    readonly children: React.ReactNode
    readonly className?: string
}) => {
    const base = "border-t border-muted -mx-sm -my-sm mt-xs px-sm py-xs"
    const className = `${base} ${props.className || ""}`
    return <div className={className}>{props.children}</div>
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
Card.Content = Content // eslint-disable-line
Card.Footer = Footer // eslint-disable-line
export default Card
