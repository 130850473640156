/**
 * Benefit Redux shape
 * -----------------------------------------------------------------------------
 */
export interface MemberBenefit {
    readonly membershipId: string
    readonly subscriberId: string
    readonly patientId: string
    readonly groupId: string
    readonly suffix: string
    readonly medical: {
        readonly benefitTiers: ReadonlyArray<BenefitTier>
    }
    readonly pharmacy: {
        readonly benefitTiers: ReadonlyArray<BenefitTier>
    }
    readonly dental: {
        readonly benefitTiers: ReadonlyArray<BenefitTier>
    }
    readonly vision: {
        readonly benefitTiers: ReadonlyArray<BenefitTier>
    }
    readonly hearing: {
        readonly benefitTiers: ReadonlyArray<BenefitTier>
    }
}

export interface BenefitData {
    readonly benefits: { readonly [membershipId: string]: MemberBenefit }
    readonly benefitsByMembershipId: { readonly [memberIdentifier: string]: MemberBenefit }
}

/**
 * Benefit Service API Response
 * -----------------------------------------------------------------------------
 * Mock data shape returned from:
 * GET: /api/janus/benefits-accumulator-service/graphql
 */
export interface BenefitServiceResponse {
    readonly benefits: {
        readonly familyMembers: ReadonlyArray<{
            readonly membershipId: string,
            readonly firstName: string,
            readonly lastName: string,
            readonly birthDate: string,
            readonly currentAge: string,
            readonly relationship: string,
            readonly disclosureAllowed: string,
            readonly suffix: string,
          }>
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly membershipId: string
                readonly subscriberId: string
                readonly patientId: string
                readonly groupId: string
                readonly suffix: string
                readonly medical: {
                    readonly benefitTiers: ReadonlyArray<BenefitTier>
                }
                readonly pharmacy: {
                    readonly benefitTiers: ReadonlyArray<BenefitTier>
                }
                readonly dental: {
                    readonly benefitTiers: ReadonlyArray<BenefitTier>
                }
                readonly vision: {
                    readonly benefitTiers: ReadonlyArray<BenefitTier>
                }
                readonly hearing: {
                    readonly benefitTiers: ReadonlyArray<BenefitTier>
                }
            }
        }>
    }
}

export type MeasurementCode = "AMOUNT" | "PERCENT" | "UNITS"

export interface BenefitTier {
    readonly tierNumber: string
    readonly tierDescription: string
    readonly benefitTierDetails: {
        readonly copays: ReadonlyArray<BenefitAccumulator>
        readonly coinsurances: ReadonlyArray<BenefitAccumulator>
        readonly benefitLimits: ReadonlyArray<BenefitLimit>
    }
}

export interface BenefitAccumulator {
    readonly memberAccumulatorDescription: string
    readonly benefitAmount: string
    readonly measurementCode: MeasurementCode
}

export interface BenefitLimit {
    readonly memberAccumulatorDescription: string
    readonly periodInd: string
    readonly measurementCode: MeasurementCode
    readonly limitAmountTotal: string
    readonly limitMetIndividual: string
    readonly limitMetFamily: string
}

export interface Benefit {
    readonly memberAccumulatorDescription: string
    readonly benefitType: string
    readonly benefitAmount: string
    readonly periodInd: string
    readonly measurementCode: MeasurementCode
    readonly limitAmountTotal: string
    readonly limitMetIndividual: string
    readonly limitMetFamily: string
    readonly accumAcctNum: string
}

export interface DashboardBenefit {
    readonly label: string
    readonly value: string
}

export enum BenefitsType {
    Default = "DEFAULT",
    Diabetes = "DIABETES",
    Cardiac = "CARDIAC",
    Hypertension = "HYPERTENSION",
    Asthma = "ASTHMA",
    Copd = "COPD",
}
