/**
 * GraphQL query to fetch member cards
 */
export const fetchDocumentQuery = `
query {
    fetchableDocuments(type:irs) {
        id
        date
        desc
        name
        url
    }
} 
`
