/* eslint-disable no-console */
/**
 * Dental Benefits Additional Material Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions } from "store"
import http from "utils/http"
import {fetchableAdditionalMaterialsQuery} from "./queries";

/**
 * Get additional Material data
 * -----------------------------------------------------------------------------
 */
export const fetchAdditionalMaterials = createAsyncThunk(
    "additionalMaterials/fetchAdditionalMaterials", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.additionalMaterialsStatus("pending"))

        /**
         * Request additional materials data
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            fetchableAdditionalMaterialsQuery,
        )

        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.additionalMaterialsError({
                    response: res,
                }),
            )
        }

        const docs = res.data?.data?.fetchableBenefitDocuments || []

        /**
         * Update state
         */
        dispatch(
            actions.receiveAdditionalMaterials({
                additionalMaterials: docs || [],
            }),
        )
    },
)
