/**
 * Member Cards reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { MemberCard } from "./types"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface MemberCards {
    readonly memberCards: ReadonlyArray<MemberCard>
    readonly memberCardItemStore: {
        readonly [suffix: string]: {
            readonly pdfData: Blob
            readonly href: string
            readonly isLoading: boolean
            readonly noDocumentFoundError: boolean
            readonly memberCardQueryError: boolean
        }
    }
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<MemberCards> = {
    status: "pristine",
    memberCards: [],
    memberCardItemStore: {},
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const memberCards = createStatefulSlice({
    name: "memberCards",
    initialState,
    reducers: {
        receiveMemberCards: (
            state: SliceState<MemberCards>,
            action: PayloadAction<MemberCards>,
        ) => {
            return {
                status: "completed",
                memberCards: action.payload.memberCards || [],
                memberCardItemStore: action.payload.memberCardItemStore || {},
            }
        },
        resetMemberCards: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const memberCardsActions = { ...memberCards.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default memberCards.reducer
