/**
 * Overlay Modal
 * -----------------------------------------------------------------------------
 */
import React from "react"
import ReactDOM from "react-dom"
import { createFocusTrap, FocusTrap } from "focus-trap"
import { Button } from "elements"
import { useEscapeModal } from "utils/window"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly name: string
    readonly children: React.ReactNode
    readonly isVisible: boolean
    readonly onDismiss: VoidFunction
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Overlay: React.FC<Props> = props => {
    /**
     * Hooks
     */
    const escapeModal = useEscapeModal("overlay", props.onDismiss)

    /**
     * State
     */
    const [root, setRoot] = React.useState<HTMLElement>()
    const [focusTrap, setFocusTrap] = React.useState<FocusTrap>()

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        // Setup a "Focus Trap" – prevent tabbing out of modal
        if (props.isVisible) {
            setFocusTrap(() => {
                const trap = createFocusTrap("#overlay")
                trap.activate()
                return trap
            })
        } else {
            if (focusTrap) focusTrap.deactivate()
        }

        // Listen for `escape` to dismiss
        if (props.isVisible && props.onDismiss) {
            escapeModal.addListener()
        }
        return () => {
            escapeModal.removeListener()
        }
    }, [props.isVisible]) // eslint-disable-line

    /**
     * Define portal DOM root
     */
    React.useEffect(() => {
        setRoot(document.getElementById("root-overlay"))
    }, [])

    /**
     * Template
     */
    const template = props.isVisible ? (
        <div
            className="fixed z-overlay min-h-screen w-screen bg-primary-200 bg-opacity-90 flex items-center justify-center"
            id="overlay"
            role="dialog"
            aria-modal={true}
        >
            <div
                className="text-center text-white"
                style={{ maxWidth: "300px" }}
            >
                {props.children}
                <Button
                    name={props.name + "-dismiss-button"}
                    label="Dismiss"
                    inverted={true}
                    onClick={props.onDismiss}
                />
            </div>
        </div>
    ) : null
    return root ? ReactDOM.createPortal(template, root) : null
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Overlay
