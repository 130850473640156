import { IconTypes, PictogramProps } from "heartwood-component-library"
import http from "./http"

/*
   Styx Fragment IDS
*/
export const FRAGMENT_IDS = {
    UMP_PEBB_CUSTOMER_SERVICE: "5rcTmuzmSwJo81AwM4tWDn",
    UMP_SEBB_CUSTOMER_SERVICE: "eRiofLoked5g2eEH1p8jKn",
    DEFAULT_CUSTOMER_SERVICE: "wvnEU7Ar1SoaBN6DZiAQwf",
    EOB_FAQ_FRAGMENT: "vu4BSQjp3FfqfDxiYUUxBT",
    BENEFITS_LAYOUT: "qTvhyDnT8REFEFbaawoeEU",
    BENEFITS_MEDICARE_RESOURCES_SIDEBAR_CARD: "5kqH1kT5TJw1KrX4SExW2e",
    BENEFITS_VISION_ACTION_CARDS: "jtQFXSwtubhaHWsFEGjVzb",
    BENEFITS_VISION_FAQ:"2ioKhDQBbVjwvp9Xa5nWK8",
    USEABLE_DENTAL: "cwTL95dmG52339EVxeskCM",
    USEABLE_SUPPORT: "9b5FocpSp54euHEAvWHdUN",
    VISION_SUPPORT: "oZvismeJCknnv1Sp37KcZa",
    DENTAL_SUPPORT: "mBCuxZPeoJLrD987g1BXTS",
    PHARMACY_HUB_FAQ: "qAbNjdbx96wGbFkQbq7z4g",
    PHARMACY_FIND_A_PHARMACY: "p4fCuHgyaecsZ8vmbedT6u",
    PHARMACY_CHECK_COSTS_AND_COVERAGE: "c55NzvRpPnGMhF6jx8Xtcj",
    PHARMACY_EXPLORE_HOME_DELIVERY: "jNY2KPxr9XTG9SGaZB1S4x",
    PHARMACY_MEDICINE_CABINET_CHECK: "kYDZgK2TcPGxUcPiBQrzTf",
    PHARMACY_ASK_A_PHARMACIST: "dXWgvbkiUmjsMzJvomRJiJ",
    PHARMACY_RESEARCH_MEDICATIONS: "qUup3cZeZdxswW1EcPPXU3",
    PHARMACY_EMPTY_STATE: "wS7jPe2UVTjTei6Q8pxaKi",
    PHARMACY_UNAUTHORIZED_STATE: "gR6QPSsswtsx7FQDyX1iPU",
    PHARMACY_MEDICATIONS: "qj8heXJG2VnKKsPwspAeZ7",
    PHARMACY_RECENT_CLAIMS: "fA9q8QddEPZqsdHx3my9Xp",
    PHARMACY_FEEDBACK: "dkX9eipBV33yULUa2HUPyf",
    OPIOIDS_EDUCATION: "owddWTJEr4RyUbx64RtVxv",
    A1C_COMMON_QUESTIONS: "wRnYSccMenk4ktUJ9Ezka1",
    A1C_INSIGHT_DETAIL: "tZt5LRottkKnFkfZvfnJ9h",
    A1C_INSIGHT: "gMNSkT3NthJ8EgAip3qCfq",
    DIABETES_EYE_EXAM_INSIGHT: "seAHALgtySXWQFHtfufsrD",
    KIDNEY_HEALTH_INSIGHT: "jKDkU1Xmq6yvU7MKmRdPjW",
}

export type ManagedContentStatus = "pristine" | "loading" | "completed" | "error"

export interface StyxFragment<T> {
    readonly fragmentId: string
    readonly content?: ReadonlyArray<T>
}

export interface ManagedContent {
    readonly header?: string,
    readonly sections?: ReadonlyArray<ManagedSection>
}

export interface ManagedSection {
    readonly title?: string,
    readonly bodyText?: string,
}

/*
 * Types for Fetch multiple content fragments
 */
export interface PictogramInfo {
    readonly regence?: PictogramProps["regence"],
    readonly asuris?: PictogramProps["asuris"],
    readonly bridgespan?: PictogramProps["bridgespan"],
    readonly regenceLegacy?: PictogramProps["regence"],
}

export interface LinksInfo {
    readonly link?: {
        readonly label?: string,
        readonly url?: string,
        readonly icon?: IconTypes,
        readonly external?: boolean | false,
        readonly modal?: boolean | false,
    },
}

export interface ContentBlock {
    readonly title?: string,
    readonly description?: string,
    readonly markdown?: string,
    readonly category?: string,
    readonly id?: string,
    readonly pictograms?: ReadonlyArray<PictogramInfo>,
    readonly links?: ReadonlyArray<LinksInfo>,
}

export interface fragmentContent {
    readonly fragment?: ReadonlyArray<ContentBlock>,
    readonly fragmentStatus: ManagedContentStatus,
}

/**
 * Fetch single content fragment
 */
export const fetchManagedContentFragment = (fragmentId, setStatus, setFragment) => {
    setStatus("loading")
    const config = { params: { id: fragmentId } }
    try {
        http.get("/api/managed-content", config).then(({ error, data }) => {
            if (!data || error) {
                setStatus("error")
                return
            } else if (data.length > 0) {
                if (data[0].isValid === false) {
                    setStatus("error")
                    return
                }
                // single content with sections
                const item = data?.[0]?.data
                setFragment(item)
                setStatus("completed")
                return
            }
            // Error due to missing required content data for display
            setStatus("error")
        })
    } catch (err) {
        setStatus("error")
    }
}

/**
 * Fetch multiple content fragments
 */
export const fetchManagedContentMultiFragment = (fragmentId, setStatus, setFragment) => {
    setStatus("loading")
    const config = { params: { id: fragmentId } }
    try {
        http.get("/api/managed-content", config).then(({ error, data }) => {
            if (!data || error) {
                setStatus("error")
                return
            } else if (data.length === 0) {
                // multiple sections
                const items = data.map(d => d?.data?.content?.[0])
                setFragment(items)
                setStatus("completed")
                return
            }else if (data.length > 0) {
                // multiple content
                const items = data.map(d => d?.data?.content)?.[0]
                setFragment(items)
                setStatus("completed")
                return
            }
            // Error due to missing required content data for display
            setStatus("error")
        })
    } catch (err) {
        setStatus("error")
    }
}

/**
 * Fetch a single content fragment with multiple objects
 * Returns an array of managed-content objects.
 */
export const fetchManagedContentArray = (fragmentId, setStatus, setFragment) => {
    setStatus("loading")
    const config = { params: { id: fragmentId } }
    try {
        http.get("/api/managed-content", config).then(({ error, data }) => {
            if (!data || error) {
                setStatus("error")
                return
            } else if (data.length > 0) {
                setFragment(data)
                setStatus("completed")
                return
            }
            // Error due to missing required content data for display
            setStatus("error")
        })
    } catch (err) {
        setStatus("error")
    }
}    

/**
 * Fetch multiple content sections
 */
export const fetchManagedContentSections = (fragmentId, setStatus, setFragment) => {
    setStatus("loading")
    const config = { params: { id: fragmentId } }
    try {
        http.get("/api/managed-content", config).then(({ error, data }) => {
            if (!data || error) {
                setStatus("error")
                return
            } else if (data.length > 0) {
                // multiple content sections
                const sections = data?.[0]?.data?.sections
                setFragment(sections)
                setStatus("completed")
                return
            }
            // Error due to missing required content data for display
            setStatus("error")
        })
    } catch (err) {
        setStatus("error")
    }
}
