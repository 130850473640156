import { formatCurrency } from "@/utils"
import { transformConditionTreatmentsApiData } from "../condition-treatments/utils"
import { Treatment } from "./types"

export const transformTreatmentsApiData = (
    treatments: ReadonlyArray<any>, // eslint-disable-line
): ReadonlyArray<Treatment> => {
    return treatments?.map(treatment => {
        const item = treatment.node || treatment
        return transformTreatmentApiData(item)
    })
}

export const transformTreatmentApiData = (
    treatment: any, // eslint-disable-line
): Treatment => {
    return {        
        id: treatment?.id,
        primaryName: treatment?.primaryName,
        secondaryName: treatment?.secondaryName,
        commonSideEffects: (treatment?.commonSideEffectList?.split(",") || []),
        severeSideEffects: (treatment?.severeSideEffectList?.split(",") || []),
        averageCost: treatment?.cost,
        costFrequency: treatment?.costFrequency,
        costDescription: treatment?.costDescription,         
        commonSideEffectList: treatment?.commonSideEffectList,
        severeSideEffectList: treatment?.severeSideEffectList,
        deliveryMethodName: treatment?.deliveryMethodName,
        treatmentCategoryName: treatment?.treatmentCategoryName,
        treatmentCategoryDesc: treatment?.treatmentCategoryDesc,
        referenceNdc: treatment?.referenceNdc,
        rxConditionTreatments: treatment?.rxConditionTreatments?.edges ? transformConditionTreatmentsApiData(treatment?.rxConditionTreatments?.edges) : undefined,
    }
}

/**
 * A simple wrapper for formatCurrency 
 * to handle any NaN, undefined, null, or blank values that come in from GraphQl fields
 * 
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/isNaN
 */
export const cleanFormatCurrency = (val) =>{
    if (isNaN(val) || val == undefined || val === "" || val !== val ) {
        return "--"; // TODO: change to blank later
    }
    return formatCurrency(val);
}

export const humanReadableQuantityUnit = (unit: string) => {
    if (unit === "mo") {
        return "month"
    } else if (unit === "d") {
        return "day"
    } else {
        return unit
    }
}

export const findSelectedStrength = (
    treatment: Treatment, 
    rxDrugNdcSearch: any, // eslint-disable-line
) => {
    const matchingStrength = treatment.strengths.find(strength => strength.ndc === rxDrugNdcSearch.ndc)
    if (typeof matchingStrength == "undefined") {
        const newStrength = {
            doseForm: rxDrugNdcSearch.doseForm,
            name: rxDrugNdcSearch.name,
            ndc: rxDrugNdcSearch.ndc,
        }
        return newStrength
    } else {
        return matchingStrength
    }
}

export const combineStrengths = (
    treatment: Treatment, 
    strengths: any, // eslint-disable-line
) => {
    const ndcs = treatment.strengths.map(str => str.ndc)
    const newStrengths = strengths.filter(str => {if (!ndcs.includes(str.ndc)) {return str}})
    const combinedStrengths = [...treatment.strengths, ...newStrengths]
    const updatedStrengths = combinedStrengths.map(item => {
        const item2 = strengths.find(i2 => i2.ndc === item.ndc)
        const correctItem = item2 ? item2 : item
        return correctItem
    })
    return updatedStrengths
}
