import * as React from "react"
import { SVGProps } from "react"
const SvgGlobe = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Globe" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M23.0003 12.5C23.0003 12.5 22.0003 13.5 21.0003 13.5C20.0003 13.5 19.5003 13.5 18.5003 13L15.5003 11.5C15.5003 11.5 13.5003 11 12.5003 10.5C11.5003 10 10.9849 8.97796 11.0003 8.5C11.0387 7.31172 11.5003 6.5 12.5003 6C13.5003 5.5 15.0003 5.5 16.5003 5.5C17.7241 5.5 18.615 4.83445 19.4446 4.31779M5 4.17084C7.88435 4.96253 8.23619 8.28012 7.89134 9.31819C7.42022 10.7364 5.41793 11.0909 4.24011 11.6818C4.24011 11.6818 2.25359 12.5124 1 14.1734M9.54721 14.8969C9.9855 14.4529 10.875 14.0738 12.177 14.009C13.8608 13.9253 15.1132 14.4166 16.1218 15.7848C16.7694 16.6634 17.0231 17.3523 16.9984 18.4485C16.9822 19.1631 16.9039 19.6 16.5601 20.2242C15.9586 21.316 14.4507 22 13.9302 22C13.4097 22 13.0536 21.556 13.0536 20.6681C13.0536 19.7802 12.9934 18.8618 12.177 18.4484C11.3607 18.035 10.8621 18.4484 9.98551 18.0045C9.10891 17.5605 9.10891 17.1166 9.02177 16.6726C8.93464 16.2287 9.10891 15.3408 9.54721 14.8969ZM1 12C1 14.9174 2.15893 17.7153 4.22183 19.7782C6.28473 21.8411 9.08262 23 12 23C14.9174 23 17.7153 21.8411 19.7782 19.7782C21.8411 17.7153 23 14.9174 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1C9.08262 1 6.28473 2.15893 4.22183 4.22183C2.15893 6.28473 1 9.08262 1 12Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgGlobe
