import { transformConditionTreatmentsApiData } from "../condition-treatments/utils"
import { transformOpportunityApiData } from "../opportunities/utils"
import { transformTreatmentsApiData } from "../treatments/utils"
import { Campaign } from "./types"

export const transformOpportunityCampaignsApiData = (
    campaigns: ReadonlyArray<any>, // eslint-disable-line
): ReadonlyArray<Campaign> => {
    return campaigns.map(campaign => {
        const item = campaign.node || campaign
        return transformCampaignApiData(item, true)
    })
}

export const transformCampaignsApiData = (
    campaigns: ReadonlyArray<any>, // eslint-disable-line
): ReadonlyArray<Campaign> => {
    return campaigns.map(campaign => {
        const item = campaign.node || campaign
        return transformCampaignApiData(item, false)
    })
}

export const transformCampaignApiData = (
    campaign: any, // eslint-disable-line
    full: boolean
): Campaign => {
    return {
        id: campaign.id,
        guid: campaign.guid,
        title: campaign.title,
        subtitle: campaign.subtitle,
        campaignTitle: campaign.campaignTitle,
        name: campaign.name,
        description: campaign.description,
        landingPageText: campaign.landingPageText,
        action: campaign.action,
        actionUrl: campaign.actionUrl,
        bannerText: campaign.bannerText,
        landingPageMessage: campaign.landingPageMessage,
        visibleState: campaign.visibleState,
        categoryKey: campaign.categoryKey,
        published: campaign.published,
        coverage: campaign.coverage,
        disclaimer: campaign.disclaimer,
        benefitKeywords: campaign.benefitKeywords,
        rxTreatments: campaign.rxTreatments?.edges
            ? transformTreatmentsApiData(
                campaign.rxTreatments.edges,
            )
            : undefined,
        rxConditionTreatments: campaign.rxConditionTreatments?.edges
            ? transformConditionTreatmentsApiData(
                campaign.rxConditionTreatments.edges,
            )
            : undefined,
        rxOpportunity: campaign.rxOpportunity
            ? transformOpportunityApiData(campaign.rxOpportunity, null, null)
            : undefined,
        fullFat: full
    }
}
