export const vspInfoQuery = `
query {  
    member {
        enrollment {
            vsp {
                mid
                fimIdpNm
            }
        }
    }

}
`
export const vspInfoFromDFISQuery = `
query {
    vsp {
      membershipId
      firstName
      lastName
      dateOfBirth
      mid
      fimIdpNm
      relationship
    }
  }  
`