/**
 * TaxForm reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { FileUploadParam } from "./types"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"


/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface FileUploadParams {
    readonly fileUploadParameters: FileUploadParam
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<FileUploadParams> = {
    status: "pristine",
    fileUploadParameters: undefined
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const FileUploadParameters = createStatefulSlice({
    name: "fileUploadParam",
    initialState,
    reducers: {
        receiveFileUploadParam: (
            state: SliceState<FileUploadParams>,
            action: PayloadAction<FileUploadParams>,
        ) => {
            return {
                status: "completed",
                fileUploadParameters: action.payload.fileUploadParameters || undefined,
            }
        },
        resetFileUploadParam: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const fileUploadParamActions = { ...FileUploadParameters.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default FileUploadParameters.reducer
