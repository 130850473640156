import * as React from "react"
import { SVGProps } from "react"
const SvgShareAlt = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Share" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M22 10.0002C22 9.4479 21.5523 9.00018 21 9.00018C20.4477 9.00018 20 9.4479 20 10.0002H22ZM4 10.0002C4 9.4479 3.55228 9.00018 3 9.00018C2.44772 9.00018 2 9.4479 2 10.0002H4ZM15.2929 5.70729C15.6834 6.09781 16.3166 6.09781 16.7071 5.70729C17.0976 5.31677 17.0976 4.6836 16.7071 4.29308L15.2929 5.70729ZM12 1.00018L12.7071 0.293076C12.3166 -0.097448 11.6834 -0.097448 11.2929 0.293076L12 1.00018ZM7.29289 4.29308C6.90237 4.6836 6.90237 5.31677 7.29289 5.70729C7.68342 6.09781 8.31658 6.09781 8.70711 5.70729L7.29289 4.29308ZM11 14.5002C11 15.0525 11.4477 15.5002 12 15.5002C12.5523 15.5002 13 15.0525 13 14.5002H11ZM20 10.0002V20.6002H22V10.0002H20ZM20 20.6002C20 21.3479 19.3477 22.0002 18.6 22.0002V24.0002C20.4523 24.0002 22 22.4525 22 20.6002H20ZM18.6 22.0002H5.4V24.0002H18.6V22.0002ZM5.4 22.0002C4.65228 22.0002 4 21.3479 4 20.6002H2C2 22.4525 3.54772 24.0002 5.4 24.0002V22.0002ZM4 20.6002V10.0002H2V20.6002H4ZM16.7071 4.29308L12.7071 0.293076L11.2929 1.70729L15.2929 5.70729L16.7071 4.29308ZM11.2929 0.293076L7.29289 4.29308L8.70711 5.70729L12.7071 1.70729L11.2929 0.293076ZM11 1.00018V14.5002H13V1.00018H11Z"
            fill="currentColor"
        />
    </svg>
)
export default SvgShareAlt
