import * as React from "react"
import { SVGProps } from "react"
const SvgSun = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Sun" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M1 12.0002H2M4.20001 4.2002L4.90001 4.9002M12 1.00018V2.00018M19.8 4.2002L19.1 4.9002M23 12.0002H22M19.8 19.8002L19.1 19.1002M12 23.0002V22.0002M4.20001 19.8002L4.90001 19.1002M18 12.0002C18 15.3139 15.3137 18.0002 12 18.0002C8.68629 18.0002 6 15.3139 6 12.0002C6 8.68647 8.68629 6.00018 12 6.00018C15.3137 6.00018 18 8.68647 18 12.0002Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgSun
