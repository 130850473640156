/**
 * Clickable
 * -----------------------------------------------------------------------------
 * Have a `<div>` element conditionally behave as a button or anchor tag,
 * helpful for our `Card` and `Pill` elements.
 */
import React from "react"
import Link from "elements/link"
import { LinkEvent, tagEvent } from "utils/analytics"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface ClickableProps {
    readonly name?: string
    readonly onClick?: VoidFunction
    readonly isExternalOnClick?: boolean
    readonly href?: string
    readonly target?: string
    readonly analytics?: Partial<LinkEvent>
    readonly stopPropagation?: boolean
    readonly preventDefault?: boolean
    readonly isExternal?: boolean
}

export interface Props
    extends Omit<React.HTMLAttributes<HTMLDivElement>, "onClick">,
    ClickableProps {
    readonly children: React.ReactNode
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Clickable = (props: Props) => {
    /**
     * Refs
     */
    const ref = React.useRef<HTMLDivElement>(null)

    /**
     * Methods
     */
    const onClick = (
        event:
            | React.MouseEvent<HTMLDivElement>
            | React.MouseEvent<HTMLAnchorElement>,
    ) => {
        // Conditionally stop parent events
        if (props.stopPropagation) event.stopPropagation()
        if (props.preventDefault) event.preventDefault()

        // Handle `onClick` prop
        if (props.onClick) props.onClick()
    }


    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (
            event.key === " " ||
            event.key === "Spacebar" ||
            event.key === "Enter"
        ) {
            event.preventDefault()
            if (props.onClick) props.onClick()
        }
    }

    /**
     * Template: Link Card
     */
    if (props.href || (props.isExternal && props.isExternalOnClick)) {
        return (
            <Link
                name={props.name}
                href={props.href}
                target={props.target}
                className={props.className + " text-default"}
                appearance="none"
                onClick={onClick}
                analytics={props.analytics}
                isExternal={props.isExternal}
                isExternalOnClick={props.isExternalOnClick}
            >
                {props.children}
            </Link>
        )
    }

    /**
     * Template: Div Button
     *
     * Note: Trick the DOM into treating this as a button,
     * but without the button-inside-a-button issues.
     * https://benfrain.com/converting-divs-into-accessible-pseudo-buttons/
     */
    if (props.onClick) {
        const buttonClass = `${props.className} block focus-offset transform active:translate-y-one cursor-pointer`

        /**
         * Click event
         */
        const handleOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
            tagEvent({
                tealium_event: "link",
                data_analytics_id: props.name,
                link_text: "", // link_text should be set in the analytics props
                link_url: "", // link_url should be set in the analytics props
                ...props.analytics,
            })
            onClick(event)
        }
        return (
            <div
                data-test={props.name}
                ref={ref}
                onClick={handleOnClick}
                className={buttonClass}
                role="button"
                aria-pressed="false"
                tabIndex={0}
                onKeyDown={handleKeyDown}
            >
                {props.children}
            </div>
        )
    }

    /**
     * Template: Default Div
     */
    return (
        <div
            className={props.className}
            id={props.id}
            data-test={props.name}
            onClick={onClick}
        >
            {props.children}
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Clickable
