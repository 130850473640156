import * as React from "react"
import { SVGProps } from "react"
const SvgMoneyCloud = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Dollar sign inside a cloud"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9.25 15.0137C11.2842 17.048 14.75 15.9932 14.75 14.0343C14.75 12.5274 13.2432 12 12.2637 12H11.7363C10.7568 12 9.25 11.4726 9.25 9.96577C9.25 7.93152 12.7158 6.95207 14.75 8.98631M12.2637 17.5V16.5205M12.2637 7.55479V6.5M22.5013 12.0013C22.5014 11.2577 22.2647 10.5334 21.8254 9.93341C21.3862 9.33341 20.7672 8.8889 20.0583 8.66433C20.4016 8.00417 20.5256 7.25179 20.4122 6.51639C20.2989 5.78099 19.9541 5.10086 19.428 4.57471C18.9018 4.04857 18.2217 3.70379 17.4863 3.59044C16.7509 3.47708 15.9985 3.60105 15.3383 3.94433C15.114 3.2352 14.6696 2.61597 14.0696 2.17645C13.4695 1.73694 12.7451 1.5 12.0013 1.5C11.2576 1.5 10.5331 1.73694 9.9331 2.17645C9.33308 2.61597 8.88867 3.2352 8.66433 3.94433C8.00417 3.60105 7.25179 3.47708 6.51639 3.59044C5.78099 3.70379 5.10086 4.04857 4.57471 4.57471C4.04857 5.10086 3.70379 5.78099 3.59044 6.51639C3.47708 7.25179 3.60105 8.00417 3.94433 8.66433C3.2352 8.88867 2.61597 9.33308 2.17645 9.9331C1.73694 10.5331 1.5 11.2576 1.5 12.0013C1.5 12.7451 1.73694 13.4695 2.17645 14.0696C2.61597 14.6696 3.2352 15.114 3.94433 15.3383C3.60105 15.9985 3.47708 16.7509 3.59044 17.4863C3.70379 18.2217 4.04857 18.9018 4.57471 19.428C5.10086 19.9541 5.78099 20.2989 6.51639 20.4122C7.25179 20.5256 8.00417 20.4016 8.66433 20.0583C8.88867 20.7675 9.33308 21.3867 9.9331 21.8262C10.5331 22.2657 11.2576 22.5027 12.0013 22.5027C12.7451 22.5027 13.4695 22.2657 14.0696 21.8262C14.6696 21.3867 15.114 20.7675 15.3383 20.0583C15.9985 20.4016 16.7509 20.5256 17.4863 20.4122C18.2217 20.2989 18.9018 19.9541 19.428 19.428C19.9541 18.9018 20.2989 18.2217 20.4122 17.4863C20.5256 16.7509 20.4016 15.9985 20.0583 15.3383C20.7672 15.1138 21.3862 14.6693 21.8254 14.0693C22.2647 13.4693 22.5014 12.7449 22.5013 12.0013Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgMoneyCloud
