/**
 * Renewal Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import Router from "next/router"
import http from "utils/http"
import {
    renewalCancelMutation,
    renewalConfirmationMutation,
    renewalQuery,
    renewalReviewMutation,
} from "./queries"
import { FastTrackResponse, Renewal, RenewalSteps, Review } from "./reducer"
import {
    defineRenewalInitializeData,
    transformRenewalCancelBody,
    transformRenewalConfirmBody,
    transformRenewalReviewBody,
    transformFastTrackBody,
    clearProgressAfterStep,
    transformFastTrackParams,
    isPlanStepValid,
    renewalProgress,
    isPlanCancelled,
    isPlanReenrolling,
    clearQueryParams,
} from "@/store/renewal/utils"
import { ENVIRONMENT_PREFIXES, getEnvironment } from "utils/env"

/**
 * Initialize with existing member data
 * -----------------------------------------------------------------------------
 */
export const initializeRenewal = createAsyncThunk(
    "renewal/initializeRenewal", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Update status
         */
        dispatch(actions.renewalStatus("pending"))

        const cacheRes = await http.get("/api/renewal/cache")
        // TODO: could inspect cacheRes for cacheRes.error etc, if it affects state
        const renewal = cacheRes.data?.data
        // console.log("renewal from cache", renewal)

        // check if the cache is in pristine state
        if (!renewal || Object.keys(renewal).length < 1) {
            /**
             * Fetch membership renewals from digital-first-information-service
             * Here familyMemberId is mapped to memberShipId from MPS in MRS
             */
            const res = await http.query(
                "/api/janus/digital-first-information-service/graphql",
                renewalQuery,
            )
            if (res.error || res.data?.errors?.length > 0) {
                console.error("Failed to get MBS data")
                return dispatch(actions.renewalError({ response: res }))
            }

            /**
             * Define data
             */
            const renewal = {
                ...store.getState().renewal,
                ...defineRenewalInitializeData(res),
                status: "completed",
            }
            const { pending } = renewalProgress(renewal)
            const step = pending[0]

            /**
             * Update state
             */
            return dispatch(
                actions.receiveRenewal({
                    ...renewal,
                    step,
                }),
            )
        } else {
            // hydrate redux with existing state
            const { pending, completed } = renewalProgress(renewal)
            if (pending[0] !== "plan") {
                // hydrate from cache with current state
                // console.log("dispatching existing renewal state", renewal)
                // eslint-disable-next-line functional/no-let
                let step = pending[0]
                const { fastTrackPlan, submitted } = renewal
                if (
                    fastTrackPlan?.useReenrollingPlan === "false" &&
                    submitted === "error"
                ) {
                    // Show the review step for renewal form re-submission
                    step = "review"
                }
                return dispatch(
                    actions.receiveRenewal({
                        ...renewal,
                        step: step,
                        submitted: "pending",
                        status: "completed",
                    }),
                )
            } else {
                // console.log("processing plan", pending[0])
                // plan selection step handles query params, cance, and renew same pla flows
                // eslint-disable-next-line functional/no-let
                let fastTrackPlan = renewal?.fastTrackPlan
                //console.log("fast track plan from cache", fastTrackPlan)
                // TODO: when query params are present outside of plan step they are not cleared. Is this an issue? They aren't usedd when cache already contains plan data
                if (!isPlanStepValid(fastTrackPlan)) {
                    //console.log("no exisitng plan in cache", fastTrackPlan)
                    try {
                        /**
                         * Handle FastTrack query params
                         */
                        const fastTrackQuery: FastTrackResponse =
                            Object.fromEntries(
                                new URLSearchParams(
                                    decodeURIComponent(window.location.search),
                                ),
                            )

                        if (!isPlanStepValid(fastTrackQuery)) {
                            //throw new Error("Fast Track response query is invalid",)
                            console.error(
                                "Fast Track response query is invalid, let's try that again",
                            )
                            /**
                             * Clear query params after saving to cache
                             */
                            clearQueryParams(Router)
                            return dispatch(
                                actions.receiveRenewal({
                                    ...renewal,
                                    step: completed[completed.length - 1],
                                    status: "completed",
                                }),
                            )
                        }

                        /**
                         * Update Redux/Redis with FastTrack plan data
                         */
                        const fastTrackRenewal = {
                            ...renewal,
                            fastTrackPlan: fastTrackQuery,
                        }

                        try {
                            //console.log("updating cache with plan", fastTrackQuery)
                            const res = await http.post("/api/renewal/cache", {
                                data: fastTrackRenewal,
                            })
                        } catch (err) {
                            console.error(
                                "Failed to save renewal plaan to cache",
                            )
                            // TODO: could dispatch initializeRenewal again as retry, but it's really not a big deal
                            //return dispatch(
                            //    actions.receiveRenewal({
                            //        ...renewal,
                            //        status: "error",
                            //    }),
                            //)
                        }

                        fastTrackPlan = fastTrackQuery
                        /**
                         * Clear query params after saving to cache
                         */
                        clearQueryParams(Router)
                    } catch (err) {
                        // TODO: retry on failure
                        console.error(
                            "Failed to process update cache with fasttrack response",
                        )
                        return dispatch(
                            actions.receiveRenewal({
                                ...renewal,
                                status: "error",
                            }),
                        )
                    }
                }

                //console.log("dispatching plan pending", fastTrackPlan)
                /**
                 * Hydrate state with new plan info
                 */
                await dispatch(
                    actions.receiveRenewal({
                        ...renewal,
                        fastTrackPlan,
                        status: "pending",
                    }),
                )

                // handle processing of fast track response and handle errors
                if (isPlanCancelled(fastTrackPlan)) {
                    // console.log("dispatching plan cancellation", fastTrackPlan)
                    /**
                     * Conditional: Mutate for `canceled`
                     */
                    return dispatch(actions.receiveRenewalCancellation())
                } else if (isPlanReenrolling(fastTrackPlan)) {
                    //console.log("dispatching plan confirmation", fastTrackPlan)
                    /**
                     * Conditional: Mutate for `useReenrollingPlan`
                     */
                    return dispatch(actions.receiveRenewalConfirmation())
                } else {
                    const newRenewal = {
                        ...renewal,
                        fastTrackPlan,
                    }

                    const { pending } = renewalProgress(newRenewal)
                    const step = pending[0]

                    //console.log("dispatched plan review", newRenewal, step)

                    return dispatch(
                        actions.receiveRenewal({
                            ...newRenewal,
                            step,
                            status: "completed",
                        }),
                    )
                }
            }
        }
    },
)

/**
 * Initialize with member data from Membership Renewal Service (Banner)
 * -----------------------------------------------------------------------------
 */
export const initializeRenewalMRS = createAsyncThunk(
    "renewal/initializeRenewalMRS", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Update status
         */
        dispatch(actions.renewalStatus("pending"))

        /**
         * Fetch membership renewals from digital-first-information-service
         * Here familyMemberId is mapped to memberShipId from MPS in MRS
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            renewalQuery,
        )
        if (res.error || res.data?.errors?.length > 0) {
            console.error("Failed to get MBS data")
            return dispatch(actions.renewalError({ response: res }))
        }

        dispatch(
            actions.receiveRenewal({
                ...store.getState().renewal,
                ...defineRenewalInitializeData(res),
                status: "completed",
            }),
        )
    },
)

/**
 * Receive renewal data for Address
 * -----------------------------------------------------------------------------
 */
export const receiveRenewalAddress = createAsyncThunk(
    "renewal/receiveRenewalAddress", // Reducer name
    async (renewal: Partial<Renewal>, { dispatch }) => {
        const oldRenewal = store.getState().renewal
        const updatedRenewal = { ...oldRenewal, ...renewal }

        /**
         * Update status
         */
        dispatch(actions.renewalStatus("pending"))

        /**
         * Save renewal data to redis cache
         */
        const res = http.post("/api/renewal/cache", { data: updatedRenewal })
        // could inspect res for res.error etc, if you add await above

        dispatch(
            actions.receiveRenewal({
                ...updatedRenewal,
                status: "completed",
            }),
        )
    },
)

/**
 * Receive renewal data for Address
 * -----------------------------------------------------------------------------
 */
export const receiveReview = createAsyncThunk(
    "renewal/receiveReview", // Reducer name
    async (review: Partial<Review>, { dispatch }) => {
        const oldRenewal = store.getState().renewal
        const updatedRenewal = {
            ...oldRenewal,
            review: { ...oldRenewal.review, ...review },
        }

        /**
         * Update status
         */
        dispatch(actions.renewalStatus("pending"))

        dispatch(
            actions.receiveRenewal({
                ...updatedRenewal,
                status: "completed",
            }),
        )
    },
)

/**
 * Receive renewal data for Applicants
 * -----------------------------------------------------------------------------
 */
export const receiveRenewalApplicants = createAsyncThunk(
    "renewal/receiveRenewalApplicants", // Reducer name
    async (renewal: Partial<Renewal>, { dispatch }) => {
        const oldRenewal = store.getState().renewal
        const updatedRenewal = { ...oldRenewal, ...renewal }

        /**
         * Update status
         */
        // this causes the form to disappear, and the form needs to remain after this for it to be submitted (to fast track)
        // TODO: is this still true with fast track redux method?
        // dispatch(actions.renewalStatus("pending"))

        /**
         * Save renewal data to redis cache
         * TODO: await this, error check; there's a problem submitting the form when await is here, though (look into async form validation in react)
         */
        const res = await http.post("/api/renewal/cache", {
            data: updatedRenewal,
        })

        if (res.error) {
            // if error trying to save right before FastTrack, we cannot proceed (after FastTrack, we wouldn't have their data)
            // return dispatch(actions.renewalError({ response: res }))
        }

        dispatch(
            actions.receiveRenewal({
                ...updatedRenewal,
                status: "completed",
            }),
        )
    },
)

/**
 * Launch FastTrack - POST
 * -----------------------------------------------------------------------------
 * NOTE: Procedurally create and submit a POST form
 */
export const launchFastTrack = createAsyncThunk(
    "renewal/launchFastTrack", // Reducer name
    async () => {
        /**
         * Define and transform `renewal` data for FastTrack
         */
        const renewal = store.getState().renewal
        const body = JSON.stringify(transformFastTrackBody(renewal))

        /**
         * Define FastTrack endpoint for environment
         */
        const prefix = ENVIRONMENT_PREFIXES[getEnvironment()].fastTrack
        const endpoint = `https://${prefix}.shop.regence.com/widget.action`

        /**
         * Create and append <form> to the DOM
         */
        const form = document.createElement("form")
        form.setAttribute("method", "POST")
        form.setAttribute("action", endpoint)
        const input = document.createElement("input")
        input.setAttribute("type", "hidden")
        input.setAttribute("name", "name")
        input.setAttribute("value", body)
        form.appendChild(input)
        document.body.appendChild(form)

        /**
         * Submit <form>
         */
        form.submit()
    },
)

/**
 * Launch FastTrack - GET
 * -----------------------------------------------------------------------------
 */
export const launchFastTrackGet = createAsyncThunk(
    "renewal/launchFastTrackGet", // Reducer name
    async () => {
        /**
         * Define and transform `renewal` data for FastTrack
         */
        const renewal = store.getState().renewal
        const params = transformFastTrackParams(renewal)

        /**
         * Define FastTrack endpoint for environment
         */
        const app = store.getState().app
        const brand = app.brand === "asuris" ? "asuris" : "regence"
        const prefix = ENVIRONMENT_PREFIXES[getEnvironment()].fastTrack
        const endpoint = `https://${prefix}.shop.${brand}.com/individual/spa/plans`

        /**
         * Navigate to FastTrack with query params
         */
        window.location.href = endpoint + "?" + params // eslint-disable-line
    },
)

/**
 * Receive renewal data for PCP Selection
 * -----------------------------------------------------------------------------
 */
export const receiveRenewalPcpSelection = createAsyncThunk(
    "renewal/receiveRenewalPcpSelection", // Reducer name
    async (renewal: Partial<Renewal>, { dispatch }) => {
        const oldRenewal = store.getState().renewal
        const updatedRenewal = { ...oldRenewal, ...renewal }

        /**
         * Update status
         */
        dispatch(actions.renewalStatus("pending"))

        /**
         * Save renewal data to redis cache
         */
        const res = http.post("/api/renewal/cache", { data: updatedRenewal })
        // could inspect res for res.error etc, if you add await above

        dispatch(
            actions.receiveRenewal({
                ...updatedRenewal,
                status: "completed",
            }),
        )
    },
)

/**
 * Receive renewal data for Review & e-sign
 * -----------------------------------------------------------------------------
 */
export const receiveRenewalReviewAndEsign = createAsyncThunk(
    "renewal/receiveRenewalReviewAndEsign", // Reducer name
    async (renewal: Partial<Renewal>, { dispatch }) => {
        const oldRenewal = store.getState().renewal
        const updatedRenewal = { ...oldRenewal, ...renewal }

        /**
         * Update status
         */
        dispatch(actions.renewalStatus("pending"))

        const mutationVariables = {
            input: transformRenewalReviewBody(updatedRenewal),
        }

        /**
         * Save Indy renewal form data to digital-first-information-service MRS
         */
        const res = await http.post(
            "/api/janus/digital-first-information-service/graphql",
            {
                query: renewalReviewMutation,
                variables: mutationVariables,
            },
        )

        if (
            res.error ||
            res.data?.errors?.length > 0 ||
            res.data?.data?.renewIndividualPlan?.resultStatus !== "Ok"
        ) {
            /**
             * Save renewal form data to redis cache and update renewal state if renewal form data save to DFIS failed
             */
            // TODO: no await?
            // Save the submitted error state in cache so it could be use on page refresh to identified the review step for display
            const cacheRes = http.post("/api/renewal/cache", {
                data: {
                    ...updatedRenewal,
                    submitted: "error",
                },
            })
            return dispatch(
                actions.receiveRenewal({
                    ...updatedRenewal,
                    submitted: "error",
                    status: "completed",
                }),
            )
        }

        /**
         * Update state with `submitted` status
         */
        dispatch(
            actions.receiveRenewal({
                ...store.getState().renewal,
                submitted: "submitted",
                status: "completed",
            }),
        )

        dispatch(actions.clearRenewalCache())
    },
)

/**
 * Clear only cache (server cache)
 * -----------------------------------------------------------------------------
 */
export const clearRenewalCache = createAsyncThunk(
    "renewal/clearRenewalCache", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * TODO: If resetting state to initialState is needed, separate it out from this action.
         *       Renewal redux state still needed for the confirmation screen.
         */

        /**
         * Remove renewal cache from redis
         */
        const res = await http.destroy("/api/renewal/cache")
        // could inspect res for res.error etc, if we need to react to any errors (probably not, TTL takes care of any errors eventually)
    },
)

/**
 * Receive renewal data for Indy Renewal confirmation
 * -----------------------------------------------------------------------------
 */
export const receiveRenewalConfirmation = createAsyncThunk(
    "renewal/receiveRenewalConfirmation", // Reducer name
    async (params: void, { dispatch }) => {
        const renewal = store.getState().renewal

        /**
         * Update status
         */
        dispatch(actions.renewalStatus("pending"))

        const mutationVariables = {
            input: transformRenewalConfirmBody(renewal),
        }

        /**
         * Save Indy Renewal confirmation data to digital-first-information-service MRS
         */
        const res = await http.post(
            "/api/janus/digital-first-information-service/graphql",
            {
                query: renewalConfirmationMutation,
                variables: mutationVariables,
            },
        )

        if (
            res.error ||
            res.data?.errors?.length > 0 ||
            res.data?.data?.reenrollIndividualPlan?.resultStatus !== "Ok"
        ) {
            return dispatch(
                actions.receiveRenewal({
                    ...renewal,
                    submitted: "error",
                    status: "completed",
                }),
            )
        }

        /**
         * Update state with `submitted` status
         */
        dispatch(
            actions.receiveRenewal({
                ...store.getState().renewal,
                submitted: "submitted",
                status: "completed",
            }),
        )

        dispatch(actions.clearRenewalCache())
    },
)

/**
 * Receive renewal data for cancelling Indy Renewal
 * -----------------------------------------------------------------------------
 */
export const receiveRenewalCancellation = createAsyncThunk(
    "renewal/receiveRenewalCancellation", // Reducer name
    async (params: void, { dispatch }) => {
        const renewal = store.getState().renewal

        /**
         * Update status
         */
        dispatch(actions.renewalStatus("pending"))

        const mutationVariables = {
            input: transformRenewalCancelBody(renewal),
        }

        /**
         * Save Indy Renewal cancellation data to digital-first-information-service MRS
         */
        const res = await http.post(
            "/api/janus/digital-first-information-service/graphql",
            {
                query: renewalCancelMutation,
                variables: mutationVariables,
            },
        )

        if (
            res.error ||
            res.data?.errors?.length > 0 ||
            res.data?.data?.cancelIndividualRenewal?.resultStatus !== "Ok"
        ) {
            // could inspect cacheRes for res.error etc, if we need to react to any errors (probably not, TTL takes care of any errors eventually)
            return dispatch(
                actions.receiveRenewal({
                    ...renewal,
                    submitted: "error",
                    status: "completed",
                }),
            )
        }

        /**
         * Update state with `canceled` status
         */
        dispatch(
            actions.receiveRenewal({
                ...store.getState().renewal,
                submitted: "canceled",
                status: "completed",
            }),
        )

        dispatch(actions.clearRenewalCache())
    },
)

/**
 * Clear Indy Renewal progress after a given step
 * -----------------------------------------------------------------------------
 */
export const clearRenewalAfterStep = createAsyncThunk(
    "renewal/clearRenewalAfterAddress", // Reducer name
    async (step: RenewalSteps, { dispatch }) => {
        if (step === "address") {
            dispatch(actions.clearRenewalAfterAddress())
        } else if (step === "applicants") {
            dispatch(actions.clearRenewalAfterApplicants())
        } else if (step === "plan") {
            dispatch(actions.clearRenewalAfterPlan())
        }
    },
)

/**
 * Clear Indy Renewal progress after step: Address
 * -----------------------------------------------------------------------------
 */
export const clearRenewalAfterAddress = createAsyncThunk(
    "renewal/clearRenewalAfterAddress", // Reducer name
    async (params: void, { dispatch }) => {
        const renewal = store.getState().renewal
        const updatedRenewal = clearProgressAfterStep(renewal, "address")

        const cacheRes = http.post("/api/renewal/cache", {
            data: updatedRenewal,
        })
        dispatch(
            actions.receiveRenewal({
                ...updatedRenewal,
            }),
        )
    },
)

/**
 * Clear Indy Renewal progress after step: Applicants
 * -----------------------------------------------------------------------------
 */
export const clearRenewalAfterApplicants = createAsyncThunk(
    "renewal/clearRenewalAfterApplicants", // Reducer name
    async (params: void, { dispatch }) => {
        const renewal = store.getState().renewal
        const updatedRenewal = clearProgressAfterStep(renewal, "applicants")

        const cacheRes = http.post("/api/renewal/cache", {
            data: updatedRenewal,
        })
        dispatch(
            actions.receiveRenewal({
                ...updatedRenewal,
            }),
        )
    },
)

/**
 * Clear Indy Renewal progress after step: Plan
 * -----------------------------------------------------------------------------
 */
export const clearRenewalAfterPlan = createAsyncThunk(
    "renewal/clearRenewalAfterPlan", // Reducer name
    async (params: void, { dispatch }) => {
        const renewal = store.getState().renewal
        const updatedRenewal = clearProgressAfterStep(renewal, "plan")

        const cacheRes = http.post("/api/renewal/cache", {
            data: updatedRenewal,
        })
        dispatch(
            actions.receiveRenewal({
                ...updatedRenewal,
            }),
        )
    },
)
