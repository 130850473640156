import * as React from "react"
import { SVGProps } from "react"
const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Checkmark"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6 12L10 15.5L18.5 7"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgCheck
