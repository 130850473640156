import * as React from "react"
import { SVGProps } from "react"
const SvgHouse = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="House" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M4 15V23H20V15M1 12.9L12 2L23 13"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgHouse
