import * as React from "react"
import { SVGProps } from "react"
const SvgMessageBubbleVideo = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Message bubble with video"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3 18.2C1.7 16.5 1 14.3 1 12C1 5.9 5.9 1 12 1C18.1 1 23 5.9 23 12C23 18.1 18.1 23 12 23C10.3 23 8.7 22.6 7.3 21.9L2 23L2.9 18.3M5 8H15V16.5H5V8ZM19 16L15 14.1V10.9L19 9V16Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgMessageBubbleVideo
