/**
 * Search-like reducer
 * -----------------------------------------------------------------------------
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { SearchLikeResult } from "./types"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface SearchLikeResults {
    // Base props
    readonly isInitialized: boolean
    readonly isLoading: boolean
    readonly errorMessage?: string

    // Resource props
    readonly item: SearchLikeResult
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SearchLikeResults = {
    isInitialized: false,
    isLoading: false,
    errorMessage: undefined,
    item: undefined,
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const searchLikeResults = createSlice({
    name: "search",
    initialState,
    reducers: {
        receiveSearchLikeResults: (
            state: SearchLikeResults,
            action: PayloadAction<SearchLikeResults>,
        ) => {
            return action.payload
        },
        resetSearchLike: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const searchLikeActions = { ...searchLikeResults.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default searchLikeResults.reducer
