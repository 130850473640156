/**
 * Insights Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import { http } from "utils"
import { insightsRegenceQuery, giveCardFeedback } from "./queries"
import { transformInsightsApiData } from "./utils"
import { Insight } from "store/insights/types"
import { daysDifference } from "@/utils/rx/utilities"

/**
 * Get insights data
 * -----------------------------------------------------------------------------
 */
export const fetchInsights = createAsyncThunk(
    "insights/fetchInsights", // Reducer name
    async (params: boolean, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.insightsStatus("pending"))
        const includeDismissed = params ? "true" : "false"

        /**
         * Fetch insights (from digital-first-information-service)
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            insightsRegenceQuery(includeDismissed),
        )

        /**
         * Handle request errors
         */
        if (res.error) {
            return dispatch(
                actions.insightsError({
                    response: res,
                }),
            )
        }

        /**
         * Transform insights data
         */
        const data = res.data?.data?.insights?.edges || []
        const allitems = transformInsightsApiData(data)
        // 30 days dismissed is filtered in store/action => .filter(x => daysDifference(x.dismissedAt, today) <= 30)
        const today = new Date()
        const items =  allitems?.filter(x => !x.dismissed || daysDifference(x.dismissedAt, today) <= 30)?.map(x => x)
        const members = res.data?.data?.insights.familyMembers || []

        /**
         * Update state
         */
        dispatch(
            actions.receiveInsights({
                items,
                members,
            }),
        )
    },
)

export const dismissInsight = createAsyncThunk(
    "insights/dismissInsight", // Reducer name
    async (insight: Insight, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.insightsStatus("pending"))
        const { insights } = store.getState()

        const mutationVariables = {
            input: {
                insightId: insight.insightId,
                impressionId: insight.action.impressionId,
                isDismissed: true,
            },
        }

        /**
         * Send mutation to digital-first-information
         */
        const res = await http.post(
            "/api/janus/digital-first-information-service/graphql",
            {
                query: giveCardFeedback,
                variables: mutationVariables,
            },
        )
        if (res?.error) {
            console.error("ERROR: Dismiss insight card error: " + res?.error) // eslint-disable-line
        }

        /**
         * Handle request errors
         */

        /**
         * TODO: Error Page for Insights
         * -----------------------------------------------------------------------------
         * actions.insightsPageError({
         *  response: res,
         * })
         */

        /**
         * Clear any previous error state for this child
         */

        // commented out since this action was removed here:
        // https://github.com/cambiahealth/digital-first-web/commit/4b36f3725e11e573f9687eb4d3b78a16ff738233#diff-9587d0db9b2325969c3a651caf5a17646ce12eeb8e758be31f960a59ed8dadc1

        // dispatch(
        //     actions.insightsChildStatus({
        //         target: "insights",
        //         id: insight.insightId,
        //         status: "completed",
        //     }),
        // )

        /**
         * Update state to hide the dismissed insight from the display
         */

        const items = insights.items.filter(item => item.insightId !== insight.insightId)
        const members = insights.members || []

        dispatch(
            actions.receiveInsights({
                items: items,
                members: members,
            }),
        )
        /**
         * Update state
         */
        dispatch(actions.insightsStatus("pristine"))
    },
)

export const clickInsight = createAsyncThunk(
    "insights/clickInsight", // Reducer name
    async (insight: Insight, { dispatch }) => {
        /**
         * Set loading state
        */
        dispatch(actions.insightsStatus("pending"))

        const mutationVariables = {
            input: {
                insightId: insight.insightId,
                impressionId: insight.action.impressionId,
                isDismissed: false,
            },
        }

        /**
         * Send mutation to digital-first-information
         */
        const res = await http.post(
            "/api/janus/digital-first-information-service/graphql",
            {
                query: giveCardFeedback,
                variables: mutationVariables,
            },
        )
        if (res?.error) {
            console.error("ERROR: Click insight card error: " + res?.error) // eslint-disable-line
        }

        /**
         * Update state
         */
        dispatch(actions.insightsStatus("pristine"))
    },
)