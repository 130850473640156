/**
 * Link
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { default as NextLink, LinkProps } from "next/link"
import { tagEvent, LinkEvent } from "utils/analytics"
import { ExternalLinkModal } from "elements"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
type Anchor = Omit<
    React.HTMLAttributes<HTMLAnchorElement>,
    "onMouseEnter" | "onTouchStart" // Incompatible with `LinkProps`
>
interface Props extends Anchor, LinkProps {
    readonly name: string
    readonly href: string
    readonly target?: string
    readonly children: string | React.ReactNode
    readonly onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
    readonly unstyled?: boolean // deprecated: use `appearance`
    readonly appearance?: "default" | "none"
    readonly analytics?: Partial<LinkEvent>
    readonly isExternal?: boolean
    readonly showModal?: string
    readonly isExternalOnClick?: boolean
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Link: React.FC<Props> = props => {
    /**
     * Component state
     */
    const [modal, setModal] = React.useState(false)

    /**
     * Methods
     */
    const handleAnalytics = () => {
        const text = typeof props.children === "string" ? props.children : ""

        tagEvent({
            tealium_event: "link",
            data_analytics_id: props.name,
            link_text: text,
            link_url: props.href || "",
            ...props.analytics,
        })
    }
    const handleOnClick = (event: React.MouseEvent<HTMLAnchorElement>, isExternalOnClick?: boolean) => {
        handleAnalytics()
        if (onClick && !isExternalOnClick) onClick(event)
    }

    /**
     * Define template variables
     */
    const {
        onClick,
        children,
        appearance,
        unstyled,
        isExternal,
        isExternalOnClick,
        ...rest
    } = props
    const base = "focus-offset cursor-pointer transform active:translate-y-one"
    const appearances = {
        default: "underline text-primary hover:opacity-80",
        none: "no-underline block",
    }
    const _appearance = appearances[appearance || unstyled ? "none" : "default"]
    const className = `${base} ${_appearance} ${props.className || ""}`

    /**
     * Template: External link
     */
    if (isExternal) {
        return (
            <React.Fragment>
                <a
                    {...rest}
                    href="#"
                    onClick={e => {
                        e.preventDefault()
                        setModal(true)
                        handleOnClick(e, isExternalOnClick)
                    }}
                    data-test={props.name}
                    data-analytics-id={props.name}
                    data-analytics-event="dfwEvent"
                    className={className}
                    id={props.name}
                >
                    {children}
                </a>
                <ExternalLinkModal
                    name={props.name + "-modal"}
                    isVisible={modal}
                    onDismiss={() => setModal(false)}
                    onClick={isExternalOnClick ? onClick : null}
                    href={props.href}
                    target={props.target}
                />
            </React.Fragment>
        )
    }

    /**
     * Template: Default
     */
    return (
        <NextLink {...rest} legacyBehavior={true}>
            <a
                {...rest}
                className={className}
                onClick={handleOnClick}
                data-test={props.name}
                data-analytics-id={props.name}
                data-analytics-event="dfwEvent"
                target={props?.target}
            >
                {children}
                {isExternal && <div>Hi</div>}
            </a>
        </NextLink>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Link
