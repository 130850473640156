import * as React from "react"
import { SVGProps } from "react"
const SvgCamera = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Camera" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
        <path
            d="M20.7 21C22 21 23 19.9 23 18.6V9C23 7.6 22 6.6 20.7 6.6H17.8C16.8 6.6 16.1 5.8 16.1 4.8C16.1 3.8 15.3 3 14.4 3H9.7C8.7 3 8 3.8 8 4.8C8 5.8 7.2 6.6 6.3 6.6H3.3C2 6.5 1 7.6 1 9V18.6C1 19.9 2 21 3.3 21H20.7Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgCamera
