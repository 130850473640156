import { ClaimSubmissions } from "./reducer"

export const transformClaimSubmissionFaq = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
): ClaimSubmissions => {
    const faqs = data?.[0].panel?.questionsAndAnswers
    return faqs.map(d => {
        return {
            question : d.question.content,
            answer: d.answer.content
        }
    })
}