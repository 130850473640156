/* import as early as possible in the FE code execution */

// save a reference
const originalConsoleError = console.error

// override console.error for production
// eslint-disable-next-line functional/immutable-data
globalThis.console.error = (...args) => {
    if (isUat() || isPrd()) {
        // either filter object args, or omit the log statement entirely in UAT/PRD
        originalConsoleError(...maskArgs(args))
    } else {
        originalConsoleError(...args)
    }
}

/**
 * mask object-type arguments (objects and arrays)
 */
const maskArgs = (args: ReadonlyArray<unknown>): ReadonlyArray<unknown> => {
    return args.map(arg => {
        return typeof arg !== "object"
            ? arg
            : "[Masked object]"
    })
}

/**
 * filter out object-type arguments (objects and arrays)
 */
const filterArgs = (args: ReadonlyArray<unknown>): ReadonlyArray<unknown> => {
    return args.filter(arg => typeof arg !== "object")
}

/**
 * Environment logic - concise, minimal
 * Avoid importing utils/env.ts to avoid side effects occurring prior to our console override.
 */

type Environments = "dev" | "sit" | "uat" | "prd"
const getEnvironment = (): Environments => {
    const host = window?.location?.host

    // For server (w/o defined `host`)
    if (!host && typeof window === "undefined") {
        return (process.env.JANUS_ENVIRONMENT as Environments) || "dev"
    }

    if (/(localhost|local|dev|ngrok)/.test(host)) {
        return "dev"
    } else if (/(sit)/.test(host)) {
        return "sit"
    } else if (/(uat|test|demo)/.test(host)) {
        return "uat"
    } else {
        return "prd"
    }
}

const isUat = () => {
    return getEnvironment() === "uat"
}

const isPrd = () => {
    return getEnvironment() === "prd"
}