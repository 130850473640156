import * as React from "react"
import { SVGProps } from "react"
const SvgPills = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Pills" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M15.3 6.60021L14.5929 5.8931L15.3 6.60021ZM6.90001 15.0002L7.60712 15.7073L6.90001 15.0002ZM13 16.0002C12.4477 16.0002 12 16.4479 12 17.0002C12 17.5525 12.4477 18.0002 13 18.0002V16.0002ZM21 18.0002C21.5523 18.0002 22 17.5525 22 17.0002C22 16.4479 21.5523 16.0002 21 16.0002V18.0002ZM3.30712 14.2931C2.49764 13.4836 2.49764 12.2168 3.30712 11.4073L1.89291 9.9931C0.302381 11.5836 0.302381 14.1168 1.89291 15.7073L3.30712 14.2931ZM3.30712 11.4073L11.7071 3.00731L10.2929 1.5931L1.89291 9.9931L3.30712 11.4073ZM11.7071 3.00731C12.5166 2.19784 13.7834 2.19784 14.5929 3.00731L16.0071 1.5931C14.4166 0.00257647 11.8834 0.00257647 10.2929 1.5931L11.7071 3.00731ZM14.5929 3.00731C15.4024 3.81679 15.4024 5.08362 14.5929 5.8931L16.0071 7.30731C17.5976 5.71679 17.5976 3.18363 16.0071 1.5931L14.5929 3.00731ZM14.5929 5.8931L6.19291 14.2931L7.60712 15.7073L16.0071 7.30731L14.5929 5.8931ZM6.19291 14.2931C5.38343 15.1026 4.11659 15.1026 3.30712 14.2931L1.89291 15.7073C3.48343 17.2978 6.01659 17.2978 7.60712 15.7073L6.19291 14.2931ZM13 18.0002H21V16.0002H13V18.0002ZM11 17.0002C11 20.3525 13.6477 23.0002 17 23.0002V21.0002C14.7523 21.0002 13 19.2479 13 17.0002H11ZM17 23.0002C20.3523 23.0002 23 20.3525 23 17.0002H21C21 19.2479 19.2477 21.0002 17 21.0002V23.0002ZM23 17.0002C23 13.6479 20.3523 11.0002 17 11.0002V13.0002C19.2477 13.0002 21 14.7525 21 17.0002H23ZM17 11.0002C13.6477 11.0002 11 13.6479 11 17.0002H13C13 14.7525 14.7523 13.0002 17 13.0002V11.0002ZM6.09288 7.20731L10.3929 11.5073L11.8071 10.0931L7.50709 5.7931L6.09288 7.20731Z"
            fill="currentColor"
        />
    </svg>
)
export default SvgPills
