/**
 * Message Center reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { InboxMessage, SentMessage, MessageDetail, MessageTopic } from "./types"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"

/**
 * Types
 * -----------------------------------------------------------------------------
 */

export interface MessageCenter {
    readonly messageDetails: MessageDetails
    readonly resultStatus: string
    readonly inbox: InboxMessages
    readonly sent: SentMessages
    readonly messages: MessageTopics
}
export interface InboxMessages {
    readonly inboxMessages: ReadonlyArray<InboxMessage>
    readonly unreadMsgsCount: number
}

export interface SentMessages {
    readonly sentMessages: ReadonlyArray<SentMessage>
}

export interface MessageTopics {
    readonly messageTopics: ReadonlyArray<MessageTopic>
}

export interface MessageDetails{
    readonly messageDetails: ReadonlyArray<MessageDetail>
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<MessageCenter> = {
    status: "pristine",
    messageDetails: { messageDetails: []},
    inbox: { inboxMessages: [], unreadMsgsCount: 0 },
    sent: { sentMessages: [] },
    messages: { messageTopics: [] },
    resultStatus: "",
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const messageCenter = createStatefulSlice({
    name: "messageCenter",
    initialState,
    reducers: {
        receiveInboxMessages: (
            state: SliceState<MessageCenter>,
            action: PayloadAction<MessageCenter>,
        ) => {
            return {
                ...state,
                status: "completed",
                inbox: action.payload.inbox || {
                    inboxMessages: [],
                    unreadMsgsCount: 0,
                },
            }
        },
        receiveSentMessages: (
            state: SliceState<MessageCenter>,
            action: PayloadAction<MessageCenter>,
        ) => {
            return {
                ...state,
                status: "completed",
                sent: action.payload.sent || {
                    sentMessages: [],
                },
            }
        },
        receiveMessageDetails: (
            state: SliceState<MessageCenter>,
            action: PayloadAction<MessageCenter>,
        ) => {
            return {
                ...state,
                status: "completed",
                messageDetails: action.payload.messageDetails || {
                    messageDetails: [],
                },
            }
        },
        receiveMessageTopics: (
            state: SliceState<MessageCenter>,
            action: PayloadAction<MessageCenter>,
        ) => {
            return {
                ...state,
                status: "completed",
                messages: action.payload.messages || {
                    messageTopics: [],
                },
            }
        },
        receiveCreateMessage: (
            state: SliceState<MessageCenter>,
            action: PayloadAction<MessageCenter>,
        ) => {
            return {
                ...state,
                status: "completed", 
                resultStatus: action.payload.resultStatus || "MISSING_STATUS",
            }
        },
        receiveMessageUpdateStatus: (
            state: SliceState<MessageCenter>,
            action: PayloadAction<MessageCenter>,
        ) => {
            return {
                ...state,
                status: "completed",
                resultStatus: action.payload.resultStatus || "MISSING_STATUS",
            }
        },
        receiveDeleteMessage: (
            state: SliceState<MessageCenter>,
            action: PayloadAction<MessageCenter>,
        ) => {
            return {
                ...state,
                status: "completed",
                resultStatus: action.payload.resultStatus
            }
        },
        resetMessageCenter: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const messageCenterActions = { ...messageCenter.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default messageCenter.reducer
