/**
 * Icons
 * -----------------------------------------------------------------------------
 */
import React from "react"
import * as Icons from "./icons/components"
import { IconTypes as CurrentTypes } from "./icons/components/types"
import { IconTypes as LegacyTypes } from "./icons/legacy/types"
import { remapped } from "./icons/legacy/adapter"
import { toPascalCase } from "utils/string"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export type IconTypes = CurrentTypes | LegacyTypes

export interface Props {
    readonly type: IconTypes
    readonly size?: number
    readonly className?: string
    readonly variant?: "default" | "success" | "error"
    readonly badge?: string
    readonly strokeSize?: "sm" | "md" | "lg" | "xl"

    // Accessibility props
    readonly alt?: string
    readonly ariaLabel?: string
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Icon: React.FC<Props> = props => {
    /**
     * Define SVG component
     */
    const legacyFilename = toPascalCase(remapped[props.type] || "warning")
    const filename = toPascalCase(props.type || "warning")
    const SVG = Icons[filename] || Icons[legacyFilename] || Icons.Warning
    if (!SVG) return <div>icon</div>

    /**
     * Styles
     */
    const base = "inline"
    const stroke = props.strokeSize ? "stroke-" + props.strokeSize : ""
    const variants = {
        default: "text-inherit",
        success: "text-success",
        error: "text-error-200",
    }
    const variant = variants[props.variant || "default"]
    const styles = `${base} ${stroke} ${variant} ${props.className || ""}`
    const altText = props.alt ? props.alt : ""
    const ariaLabel = props.ariaLabel ? props.ariaLabel : ""

    /**
     * Template
     */
    return (
        <SVG
            width={props.size || 24}
            height={props.size || 24}
            className={styles}
            aria-label={ariaLabel}
            alt={altText}
        />
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Icon
