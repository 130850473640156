export const paperlessQuery = (
    fromDate: string,
    toDate: string,
) => `
query {
        fetchableDocuments (type: letter, fromDate: "${fromDate}", toDate: "${toDate}") {
            id
            date
            name
            desc
            url
        }
}
`
