export interface Claim {
    readonly adjusted: boolean
    readonly chargedAmount: number
    readonly claimDetails: ReadonlyArray<ClaimDetail>
    readonly claimId: string
    readonly claimNotCoveredAmount: number
    readonly claimNumber: string
    readonly claimNumberHash: string
    readonly claimNumberAdjustedFrom?: string
    readonly claimNumberAdjustedTo?: string
    readonly claimPaidAmount: number
    readonly claimServiceEndDate: string
    readonly claimServiceStartDate: string
    readonly claimStatusCode: "C" | "I" | "P" | "A"
    readonly claimStatusDescription: string
    readonly claimType: "M" | "D" | "R" | "V"
    readonly dateOfBirth: string
    readonly daysSuppliedPaid: number
    readonly deaNumber?: string
    readonly diagnosisType: string
    readonly directionOfPay: string
    readonly drugCode?: string
    readonly drugName?: string
    /**
     * AuthZ V3 based membership identifier
     */
    readonly membershipId: string
    /**
     * Legacy ELF based deprecated family member identifier.
     * Adding here for type compatibility
     * 
     * @deprecated
     */
    readonly familyMemberId: string    
    /**
     * Legacy ELF based deprecated family member identifier.
     * Added for backword compatibility with component using it explicitly
     */
    readonly legacyFamilyMemberId?: string    
    readonly familyStructureId: string
    readonly firstName: string
    readonly groupId: string
    readonly groupName: string
    readonly itsIndicator: string
    readonly lastName: string
    readonly memberCoinsuranceAmount: number
    readonly memberCopayAmount: number
    readonly memberDeductibleAmount: number
    readonly negotiatedAmount: number
    readonly otherInsurancePaidAmount: number
    readonly patientAccountNumber: string
    readonly payeeIndicator?: string
    readonly paymentNumber: string
    readonly pharmacyName?: string
    readonly pharmacyNumber?: string
    readonly prescriptionNumber?: string
    readonly processedDate: string
    readonly providerId: string
    readonly providerName: string
    readonly quantityPaid: number
    readonly receivedDate: string
    readonly subscriberId: string
    readonly totalPatientResponsibilityAmount: number
    readonly uniqueMemberIdentifier?: string
    readonly memberNameVisible?: boolean
    readonly appliesToOutOfPocketMax: number
    readonly isClaimDetailsInitialized?: boolean
}

export interface ClaimDetail {
    readonly amaProcedureDescription: string
    readonly billedAmount: number
    readonly claimDetailExplanationList: ReadonlyArray<{
        readonly explanationCode: string
        readonly explanationDescription: string
    }>
    readonly claimId: string
    readonly claimLineId: string
    readonly claimLineNotCoveredAmount: number
    readonly claimLinePaidAmount: number
    readonly claimLineServiceEndDate: string
    readonly claimLineServiceStartDate: string
    readonly cobAmount: number
    readonly coinsuranceAmount: number
    readonly copayAmount: number
    readonly deductibleAmount: number
    readonly diagnosisCode: string
    readonly diagnosisDescription: string
    readonly lineNumber: string
    readonly negotiatedRate: number
    readonly patientResponsibilityAmount: number
    readonly procedureCode: string
    readonly procedureDescription?: string
    readonly procedureType: string
    readonly typeOfService: string
    readonly unitsOfService: string
}

export interface SortByFilter {
    readonly sortBy: string
    readonly setSortBy: (val: string) => void
    readonly sortByOpened: boolean
}

export interface ClaimNumberFilter {
    readonly claimNumber: string
    readonly setClaimNumber: (val: string) => void
}

export interface DateFilter {
    readonly startRange: string
    readonly setStartRange: (val: string) => void
    readonly endRange: string
    readonly setEndRange: (val: string) => void
    readonly dateOpened: boolean
    readonly formattedDate: string
    readonly fromDateObject: Date
}

export interface CategoryFilter {
    readonly dental: boolean
    readonly setDental: (val: boolean) => void
    readonly medical: boolean
    readonly setMedical: (val: boolean) => void
    readonly pharmacy: boolean
    readonly setPharmacy: (val: boolean) => void
    readonly categoryOpened: boolean
}

export interface ProviderFilter {
    // eslint-disable-next-line functional/prefer-readonly-type
    readonly providers: ReadonlyArray<string>
    // eslint-disable-next-line functional/prefer-readonly-type
    readonly setProviders: (val: string[]) => void
    readonly providerOpened: boolean
    readonly filteredItems: ReadonlyArray<string>
}

export interface StatusFilter {
    readonly completed: boolean
    readonly setCompleted: (val: boolean) => void
    readonly inProcess: boolean
    readonly setInProcess: (val: boolean) => void
    readonly pending: boolean
    readonly setPending: (val: boolean) => void
    readonly submitted: boolean
    readonly setSubmitted: (val: boolean) => void    
    readonly adjusted: boolean
    readonly setAdjusted: (val: boolean) => void
    readonly statusOpened: boolean
}

export interface MemberFilter {
    // eslint-disable-next-line functional/prefer-readonly-type
    readonly members: ReadonlyArray<string>
    // eslint-disable-next-line functional/prefer-readonly-type
    readonly setMembers: (val: string[]) => void
    readonly memberOpened: boolean
}

/**
 * Enum representing cache load status
 */
export enum CacheStatus {
    IN_PROGRESS = "LOADING",
    COMPLETED = "COMPLETED",
    FAILED = "FAILED",
}
