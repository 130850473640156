/**
 * Typeahead Input
 * -----------------------------------------------------------------------------
 * TODO:
 * [ ] Enforce select from list
 * [ ] Show result count in tab
 * [ ] Change selected tab if disabled
 */
import React from "react"
import Input, { Props as InputProps } from "elements/input"
import { Card, Button, ActionMenu, Tabs } from "elements"
import { Item as TabItem } from "elements/tabs"
import { useKeyPress } from "utils/window"
import { useDebounce } from "utils/event"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
type Options = ReadonlyArray<string>

type GroupedOptions = ReadonlyArray<{
    readonly label: string
    readonly items: Options
}>

interface Props extends InputProps {
    readonly options: Options | GroupedOptions

    readonly variant?: "default" | "rounded"
    readonly isLoading?: boolean

    readonly onSearch?: VoidFunction
    readonly onSelectOption?: (option: string) => void

    readonly enableFiltering?: boolean
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Typeahead: React.FC<Props> = props => {
    /**
     * Hooks
     */
    useKeyPress("Escape", () => {
        document.getElementById(props.name).blur()
        setVisible(false)
    })

    /**
     * Component state
     */
    const [visible, setVisible] = React.useState(false) // dropdown visible
    const [ignore, setIgnore] = React.useState(false) // ignore after selecting
    const [listening, setListening] = React.useState(false) // listen for change
    const [tab, setTab] = React.useState("") // selected tab

    /**
     * Refs
     */
    const ref = React.useRef<HTMLDivElement>(null)

    /**
     * Methods
     */
    const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        if (props.onFocus) {
            // Pass along focus event
            props.onFocus(event)
        }
        if (_options.length) {
            // Show options if they exist
            setVisible(true)
        }
        if (hasSearchValue && onSearch && !listening) {
            // Search for results if initial value exists
            setVisible(true)
            onSearch()
        }
        setListening(true)
    }

    const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (event.relatedTarget && !event.currentTarget.contains(event.relatedTarget as Node)) {
            setVisible(false)
            setListening(false)
        }
    }

    const onMouseDown = (event: MouseEvent) => {
        if (!ref.current?.contains(event.target as Node)) {
            setVisible(false)
            document.getElementById(props.name)?.blur()
        }
    }


    const onClickOption = (option: string) => {
        setVisible(false)
        setIgnore(true)
        props.setValue(option)
        if (onSelectOption) {
            onSelectOption(option)
        }
    }

    const onClickClear = () => {
        props.setValue("")
        document.getElementById(props.name).focus()
    }
    
    const onHandleSearch = () => {
        if (ignore) {
            setIgnore(false)
        } else if (onSearch && hasSearchValue && listening) {
            setVisible(true)
            onSearch()
        }
    }

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        if (tabItems) {
            setTab("0") // set default tab (conditionally)
        }
        
        window.addEventListener("mousedown", onMouseDown)
        return () => window.removeEventListener("mousedown", onMouseDown)
    }, [])

    /**
     * Lifecycle: When value changes (debounced)
     */
    const debouncedValue = useDebounce(props.value, 300)
    React.useEffect(() => onHandleSearch(), [debouncedValue])

    /**
     * Lifecycle: When value changes (raw)
     */
    React.useEffect(() => {
        if (!hasSearchValue && onSearch) setVisible(false)
    }, [props.value])

    /**
     * Define template variables
     */
    const {
        isLoading,
        options,
        enableFiltering,
        onSearch,
        onSelectOption,
        className,
        ...inputProps
    } = props
    const hasSearchValue = props.value?.length > 2

    /**
     * Define behavior/layout
     */
    const isGrouped = typeof options[0] === "object"
    const isFilterable = enableFiltering && !onSearch

    /**
     * Define a unified options array
     */
    const unified: GroupedOptions = isGrouped
        ? (options as GroupedOptions)
        : [{ label: "single", items: options as Options }]

    /**
     * Define filtered options
     */
    const filtered = unified.map(group => {
        return {
            label: group.label,
            items: group.items.filter(option => {
                return option?.toLowerCase().includes(props.value?.toLowerCase())
            }),
        }
    })
    const results = isFilterable ? filtered : unified

    /**
     * Define tab items
     */
    const tabItems: ReadonlyArray<TabItem> = results.map((group, index) => {
        return {
            name: props.name + "-tab-" + index,
            label: group.label + " (" + group.items.length + ")",
            value: index.toString(),
            onClick: () => setTab(index.toString()),
            disabled: !group.items.length,
        }
    })

    /**
     * Define option items
     */
    const selected = results[tab]?.items || []
    const _options = onSearch ? (hasSearchValue ? selected : []) : selected

    /**
     * Template
     */
    return (
        <div className={`relative ${className}`} ref={ref} onBlur={onBlur}>
            {/* Input field */}
            <Input {...inputProps} onFocus={onFocus} hideLabel={props.hideLabel} />

            {/* Clear button */}
            {!!props.value && props.variant != "rounded" && (
                <Button
                    name={props.name + "-clear-button"}
                    icon="times-circle"
                    variant="link"
                    className="absolute right-none -bottom-two"
                    loading={isLoading}
                    onClick={onClickClear}
                />
            )}
            {!!props.value && props.variant == "rounded" && (
                <div className="absolute right-xxs top-none h-full flex items-center">
                    <Button
                        name={props.name + "-clear-button"}
                        icon="times-circle"
                        variant="link"
                        loading={isLoading}
                        onClick={onClickClear}
                    />
                </div>
            )}
            {/* Options card */}
            {visible && (
                <Card
                    name={props.name + "-options-card"}
                    className="absolute z-header mt-xxxs w-full px-none py-xxs max-h-[230px] overflow-y-auto"
                >
                    <React.Fragment>
                        {/* Tabs */}
                        {isGrouped && (
                            <Tabs
                                name="default-tab-example"
                                selected={tab}
                                items={tabItems}
                                className="-mt-xxs mb-xxs border-b border-light-200 dark:border-dark-50"
                            />
                        )}

                        {/* Options */}
                        {!isLoading && (
                            <ActionMenu
                                name={props.name + "-options"}
                                items={_options.map(option => {
                                    return {
                                        label: option,
                                        onClick: () => onClickOption(option),
                                    }
                                })}
                            />
                        )}

                        {/* Searching */}
                        {isLoading && (
                            <ActionMenu
                                name={props.name + "-loading"}
                                items={[
                                    { shimmerLabel: "Waiting on results..." },
                                    { shimmerLabel: "Waiting on results..." },
                                    { shimmerLabel: "Waiting on results..." },
                                ]}
                            />
                        )}

                        {/* No results */}
                        {!_options.length && !isLoading && (
                            <div className="text-center py-xxs opacity-70">
                                No matches found
                            </div>
                        )}
                    </React.Fragment>
                </Card>
            )}
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Typeahead
