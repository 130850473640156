export const rxSearchLikeQuery = function (searchTerm: string) {
    return `
      query search {
        rxSearch(searchTerm: "${searchTerm}") {
          rxTreatments {
            id
            primaryName
            secondaryName
            referenceNdc
          }
          rxConditions {
            id
            fullName
          }
        }
      }
    `
}

