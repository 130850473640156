import { BenefitsType } from "./types"

/**
 * GraphQL query to fetch benefits
 */
export const benefitsQuery = (
    benefitsType: BenefitsType = BenefitsType.Default,
) => `
query Query {
  benefits (type: ${benefitsType}) {
    totalCount
    familyMembers {
      membershipId
      firstName
      lastName
      birthDate
      currentAge
      relationship
      disclosureAllowed
      suffix
    }
    edges {
      cursor
      node {
        patientId
        membershipId
        memberId
        groupId
        suffix
        medical {
          ...benefitInfo
        }
        dental {
          ...benefitInfo
        }
        vision {
          ...benefitInfo
        }
        pharmacy {
          ...benefitInfo
        }
        hearing {
          ...benefitInfo
        }
      }
    }
  }
}

fragment benefitInfo on BenefitsByCategory {
  benefitTiers {
    tierNumber
    tierDescription
    benefitTierDetails {
      copays {
        accumAcctNum
        memberAccumulatorDescription
        benefitAmount
        measurementCode
      }
      coinsurances {
        accumAcctNum
        memberAccumulatorDescription
        benefitAmount
        measurementCode
      }
      benefitLimits {
        accumAcctNum
        memberAccumulatorDescription
        periodInd
        measurementCode
        limitAmountTotal
        limitMetIndividual
        limitMetFamily
      }
    }
  }
}
`
