/**
 * Progress Bar
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { useSelector, useFeatures } from "@/store"
import Features from "@/utils/features"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props extends React.HTMLAttributes<HTMLDivElement> {
    readonly name: string
    readonly items: ReadonlyArray<{
        readonly value: number
        readonly className?: string
    }>
    readonly size?: "small" | "medium" | "large"
    readonly inverted?: boolean
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const ProgressBar = (props: Props) => {
    /**
     * State
     */
    const [widths, setWidths] = React.useState(props.items.map(() => "0%"))

    const app = useSelector(state => state.app)
    const brand = app.brand
    const isBridgespan = brand === "bridgespan"

    /**
     * Lifecycle: Animate progress bar items
     */
    React.useEffect(() => {
        const wait = setTimeout(() => {
            setWidths(props.items.map(item => item.value + "%"))
        }, 50)
        return () => clearTimeout(wait)
    }, [props.items])

    /**
     * Styles
     */
    const base = "rounded-circle overflow-hidden flex"
    const color = props.inverted
        ? "bg-primary dark:bg-primary-100"
        : isBridgespan
        ? "bg-light-50 dark:bg-dark-300"
        : "bg-light-100 dark:bg-dark-300"
    const sizes = {
        small: "h-[8px]",
        medium: "h-[12px]",
        large: "h-[17px]",
    }
    const size = sizes[props.size || "medium"]
    const className = `${base} ${color} ${size} ${props.className || ""}`

    /**
     * Define template variables
     */
    const defaultColors = [
        isBridgespan && props.inverted ? "bg-primary-50" :
        props.inverted ? "bg-light-100" : "bg-primary dark:bg-primary-100",
        "bg-secondary dark:bg-secondary-100",
        "bg-warning dark:bg-warning",
        "bg-success dark:bg-success",
    ]
    const itemColors = props.items.map((_, index) => {
        return defaultColors[props.items.length - (index + 1)]
    })

    /**
     * Template
     */
    return (
        <div data-test={props.name} className={className}>
            {props.items.map((item, index) => {
                const background = item.className || itemColors[index]
                return (
                    <div
                        className={`${background} h-full transition-width duration-700`}
                        style={{ width: widths[index] }}
                        key={index}
                    />
                )
            })}
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default ProgressBar
