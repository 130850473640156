/**
 * Accordion
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Icon, IconTypes } from "elements"
import { Props as ButtonProps } from "./button"
import { tagEvent } from "@/utils/analytics"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Props extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Unique name for tests/analytics
     */
    readonly name: string

    /**
     * Items
     */
    readonly items: ReadonlyArray<{
        readonly name: string
        readonly label: React.ReactNode | string
        readonly iconLabel?: readonly [string, string?] | readonly string[]
        readonly content:
        | React.ReactNode
        | string
        | ((close: VoidFunction) => React.ReactNode)
        readonly button?: Pick<ButtonProps, "className">
        readonly onOpen?: (e: React.MouseEvent<HTMLButtonElement>) => void
        readonly onClose?: (e: React.MouseEvent<HTMLButtonElement>) => void
    }>

    /**
     * Allow multiple items to be open
     */
    readonly multiple?: boolean

    /**
     * Add space in between object with multiple items
     */
    readonly spaceBetween?: boolean

    /**
     * Visual options
     */
    readonly inverted?: boolean
    readonly indented?: boolean
    readonly buttonClassName?: string

    /**
     * Override icons – (ex. ["arrowdown", "arrowup"])
     */
    readonly icons?: readonly [IconTypes, IconTypes]
    readonly iconNodes?: readonly [React.ReactNode, React.ReactNode]
    

    /**
     * Item indexes open/active by default – (ex. [0, 3])
     */
    readonly initialActive?: ReadonlyArray<number>

    /**
     * Header
     */
    readonly header?: React.ReactNode | string
    readonly mobileHeader?: React.ReactNode | string
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Accordion: React.FC<Props> = props => {
    /**
     * State
     */
    const [active, setActive] = React.useState(props.initialActive || [])
    const [heights, setHeights] = React.useState([])

    /**
     * Refs
     */
    const refs = React.useRef([]) // Item contents, used to animate height

    /**
     * Methods
     */
    const onClick = (index: number, item) => {
        if (active.includes(index)) {
            setActive(active.filter(item => item !== index)) // Hide
            tagEvent({
                tealium_event: "link",
                data_analytics_id: `details_closed-${item.name}`,
                link_text: item.name,
                link_url: "",
            })

            if (item.onClose) {
                item.onClose()
            }
        } else {
            setActive(props.multiple ? [...active, index] : [index]) // Show
            tagEvent({
                tealium_event: "link",
                data_analytics_id: `details_opened-${item.name}`,
                link_text: item.name,
                link_url: "",
            })

            if (item.onOpen) {
                item.onOpen()
            }
        }
    }
    const isActive = (index: number) => {
        return !!active.includes(index)
    }

    /**
     * Lifecycle
     * Height will relcalculate when the data changes, but not when the viewport is changed
     * i.e. when the user resizes the screen, the accordion height will not update
     */
    React.useEffect(() => {
        if (refs.current.length && props.items.length) {
            setHeights(refs.current.map(ref => ref?.scrollHeight))
        }
    }, [refs, props.items])

    /**
     * On window resize, recalculate and set heights.
     */
    React.useEffect(() => {
        const resizeListener = () => {
            setHeights(refs.current.map(ref => ref?.scrollHeight))
        }
        window.addEventListener("resize", resizeListener)
        return () => {
            window.removeEventListener("resize", resizeListener)
        }
    }, [refs])

    /**
     * Define template values
     */
    const icons = [
        props.icons ? props.icons[0] : ("chevron-up-sm" as IconTypes),
        props.icons ? props.icons[1] : ("chevron-down-sm" as IconTypes),
    ]

    /**
     * Template
     */
    return (
        <div className={`${props.className}`}>
            {props.items.map((item, index) => {
                if (!item) return null
                return (
                    <div
                        key={index}
                        className={` ${
                            props.indented ? "" : "border-b last:border-none"
                        } ${
                            props.inverted
                                ? "border-light-100 border-opacity-50"
                                : "border-light-100 dark:border-dark-50"
                        } ${props.multiple && props.spaceBetween && "mb-md"}`}
                    >
                        {/* Web view Header */}
                        {props.header && (
                            <div className="bg-gray-25 pt-xxs pb-xs px-sm hidden md:block h-full absolute">
                                {props.header}
                            </div>
                        )}

                        {/* Mobile view header */}
                        {props.mobileHeader ? (
                            <h6 className="bg-gray-25 md:hidden sm:block p-xs mb-none">
                                {props.mobileHeader}
                            </h6>
                        ) : (
                            ""
                        )}

                        <button
                            data-test={item.name}
                            id={`${item.name}-button`}
                            aria-controls={`${item.name}-content`}
                            aria-expanded={isActive(index)}
                            className={`w-full py-xxs flex justify-between focus-offset ${
                                props.buttonClassName ?? ""
                            }  ${item.button?.className ?? ""} ${
                                !props.mobileHeader && "items-center"
                            }`}
                            onClick={() => onClick(index, item)}
                        >
                            {/* Label */}
                            <div className="flex-1 text-left">{item.label}</div>

                            {/* Icon labels */}
                            {item.iconLabel?.length && (
                                <div className="hidden md:inline text-primary-vivid dark:text-accent pl-xxs">
                                    {isActive(index) && item.iconLabel[1]
                                        ? item.iconLabel[1]
                                        : item.iconLabel[0]}
                                </div>
                            )}

                            {/* Icons */}
                            <div className="ml-xxs md:pb-none">
                                {!props.iconNodes && (
                                    <Icon
                                        type={
                                            isActive(index)
                                                ? icons[0]
                                                : icons[1]
                                        }
                                        ariaLabel={`${props.name}-control`}
                                        className={
                                            props.inverted
                                                ? "text-white"
                                                : "text-primary-vivid dark:text-accent"
                                        }
                                    />
                                )}
                                {props.iconNodes &&
                                    (isActive(index)
                                        ? props.iconNodes[0]
                                        : props.iconNodes[1])}
                            </div>
                        </button>
                        {/* Content */}
                        <div
                            ref={e => (refs.current[index] = e)} // eslint-disable-line
                            id={`${item.name}-content`}
                            role="region"
                            aria-labelledby={`${item.name}-button`}
                            className={`overflow-hidden transition-all duration-300 ${
                                isActive(index) ? "visible" : "invisible"
                            }${
                                props.indented
                                    ? " pl-xxs ml-xxs md:pl-sm md:ml-md"
                                    : ""
                            }`}
                            style={{
                                // Use pre-measured content height and set active
                                // max-height to produce heart-warming animations.
                                maxHeight: isActive(index)
                                    ? heights[index]
                                    : "0px",
                            }}
                        >
                            {/* Content with `close` action */}
                            {typeof item.content === "function" &&
                                item.content(() => onClick(index, item))}

                            {/* Content with basic string/node */}
                            {typeof item.content !== "function" && item.content}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Accordion
