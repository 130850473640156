/**
 * Account Settings Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import { http } from "utils"
import { CommPreferences, HsaPreferences } from "../member-settings/reducer"
import {
    Address,
    MemberFamilyShareInfo,
    UpdateCommPreferences,
    medicareMemberAddress
} from "../member-settings/types"
import {
    activateSMSQuery,
    cancelEmailVerificationQuery,
    cancelSMSActivationQuery,
    claimsSaveQuery,
    cpsSaveQuery,
    createSMSQuery,
    fetchCommunicationPreferencesQuery,
    fetchConsumerSettingsQuery,
    getFamilyInfoQuery,
    hsaPreferecesSaveQuery,
    medicareChangeAddress,
    paperlessPreferenceSaveQuery,
    removeSMSQuery,
    saveEmailQuery,
    updateMailingAddressQuery,
    updateMemberPasswordQuery,
    updatePreferenceQuery,
    updatePreferencesQuery,
    updateShareInfoQuery,
    usernameUpdateQuery,
    validateMemberPasswordQuery
} from "./queries"
import {
    PasswordChangeAttributes,
    PasswordValidateAttributes,
    ValidationStatus
} from "./types"
import { formatPhoneDashes } from "./utils"

interface SaveEmail {
    readonly email: string
    readonly validatedEmail: string
    readonly isDFW: boolean
}

interface UpdatePasswordResponse {
    readonly responseStatus?: string
    readonly errorCodes?: boolean
}

interface CreateSMS {
    readonly number: string
    readonly duplicate: boolean
    readonly noText: boolean
}

interface ActivateSMS {
    readonly code: string
    readonly number: string
}

interface DeleteSMS {
    readonly phoneNumber: string
}

interface saveConsumerSetting {
    readonly value: boolean
    readonly confirmationText: string
}

interface savePreferences {
    readonly code: string
    readonly value: boolean
    readonly showClaimsPopup?: boolean,
}

/**
 * Fetch consumer settings
 * -----------------------------------------------------------------------------
 */
export const fetchConsumerSettings = createAsyncThunk(
    "account-settings/fetchConsumerSetting", // Reducer name
    async (params: void, { dispatch }) => {
        try {
            /**
             * Set loading state
             */
            dispatch(actions.myAccountStatus("pending"))

            /**
             * Request Marketing consumer setting
             */
            const res = await http.query(
                "/api/janus/digital-first-information-service/graphql",
                fetchConsumerSettingsQuery,
            )

            if (res.error) {
                return dispatch(actions.myAccountError({ response: res }))
            }

            const marketing = res?.data?.data?.myAccount?.preferences?.consumerSettings.marketing
            const sso = res?.data?.data?.myAccount?.preferences?.consumerSettings.sso
            const providerAccessAuth = res?.data?.data?.myAccount?.preferences?.consumerSettings?.providerAccessAuth
            const wellness = res?.data?.data?.myAccount?.preferences?.consumerSettings.wellness

            /**
             * Update state
             */
            dispatch(
                actions.receiveMemberConsumerSettings({
                    marketing: marketing,
                    sso: sso,
                    wellness: wellness,
                    providerAccessAuth: providerAccessAuth,
                }),
            )
        } catch (err) {
            console.error("Failed to retrieve consumer settings", err)
        }
    },
)

/**
 * Update My Account Settings
 * -----------------------------------------------------------------------------
 */
export const updatePaperlessPreferences = createAsyncThunk(
    "member-settings/updatePaperlessPreferences",
    async (params: savePreferences, { dispatch }) => {
        /**
         * Request to update preferences
         */
        try {
            const { myAccount } = store.getState()
            // eslint-disable-next-line functional/no-let
            let paperlessPreferences: CommPreferences;
            // filter eob, medical, prime and anoc preferences based on preference code
            switch(params.code) {
                case "paperless-eob":
                    paperlessPreferences = {
                        ...myAccount.communicationPreferences,
                        isEOBSetting : params.value
                    }
                    break;
                case "paperless-medical":
                    paperlessPreferences = {
                        ...myAccount.communicationPreferences,
                        isMedicalSetting: params.value,
                    }
                    break;
                case "paperless-prime":
                    paperlessPreferences = {
                        ...myAccount.communicationPreferences,
                        isPrimeSetting: params.value,
                    }
                    break;
                case "anoc-email":
                    paperlessPreferences = {
                        ...myAccount.communicationPreferences,
                        isAnocSetting: params.value
                    }
                    break;    
                default: 
                    paperlessPreferences = myAccount.communicationPreferences
                    break;
            }

            const url = "/api/janus/digital-first-information-service/graphql"
            const query = paperlessPreferenceSaveQuery
            const res = await http.mutation(url, query, {
                code: params.code,
                value: params.value,
            })
            if (!res || res?.error || res?.status !== 200 || res?.data?.errors) {
                console.error(
                    `Failed to set ${params.code} preference`,
                    res?.error,
                    res?.status,
                    res?.data?.errors[0]?.message,
                )
            } else {
                /**
                 * Update state
                 */
                dispatch(actions.receiveMemberCommunicationPreference(paperlessPreferences))
            }
            return Promise.resolve(res)
        } catch (err) {
            console.error("Failed to set paperless preference", params.code, err)
        }
    },
)

/**
 * Update Username
 * -----------------------------------------------------------------------------
 */
export const updateUsername = createAsyncThunk(
    "member-settings/updateUsername",
    async (username: string, { dispatch }): Promise<string> => {
        /**
         * Request to update Username
         */
        // eslint-disable-next-line
        let payload = "MISSING_STATUS"
        try {
            const url = "/api/janus/digital-first-information-service/graphql"
            const query = usernameUpdateQuery
            const res = await http.mutation(url, query, {
                newUsername: username,
            })
            if (!res || res?.error || res?.status !== 200 || res?.data?.errors) {
                console.error(
                    "Failed to update username ",
                    res?.data?.errors[0]?.message,
                    res?.error,
                    res?.status,
                )
            }
  
            payload =
                res.data &&
                res.data?.data?.updateUsername &&
                res.data?.data?.updateUsername?.success
                    ? "SUCCESS"
                    : "MISSING_STATUS"
  
        } catch (err) {
            console.error("Failed to update username ", username, err)
        }

        // Refresh session with updated username
        if (payload === "SUCCESS") {
            try {
                const res = await http.post("/api/username/change", {
                    username: username,
                })
                
                if (!res || res?.error || res?.status !== 200 || res?.data?.errors) {
                    console.error(
                        "Failed to refresh session token with new username ",
                        res?.data?.errors[0]?.message,
                        res?.error,
                        res?.status,
                    )
                }

                payload = res.status === 200 
                    ? "SUCCESS" 
                    : "MISSING_STATUS"

            } catch (err) {
                console.error("Failed to refresh session token with new username ", err)
            }
        }
        return Promise.resolve(payload)
    },
)

/**
 * Update member password
 * -----------------------------------------------------------------------------
 */
export const updateMemberPassword = createAsyncThunk(
    "account-settings/updateMemberPassword",
    async (
        params: PasswordChangeAttributes,
        { dispatch },
    ): Promise<UpdatePasswordResponse> => {
        /**
         * Request to update member password
         */
        try {
            const url = "/api/janus/digital-first-information-service/graphql"
            const query = updateMemberPasswordQuery
            const res = await http.mutation(url, query, {
                currentPassword: params.currentPassword,
                newPassword: params.newPassword
            })

            //TODO, what to do when not 200?
            if (!res || res?.error || res?.status !== 200 || res?.data?.errors) {
                // actions.showPasswordChangeFailureModal({show: true})
                console.error(
                    "Failed to update member password",
                    res?.data?.errors[0]?.message,
                    res?.error,
                    res?.status,
                )
            }
            const responseStatus =
                res.data &&
                res.data?.data?.updatePassword &&
                res.data?.data?.updatePassword?.success
                    ? "SUCCESS"
                    : "PASSWORD_NOT_CHANGED"

            const errorCodes = res.data?.data?.updatePassword.success
            const response: UpdatePasswordResponse = {
                responseStatus,
                errorCodes,
            }

            return Promise.resolve(response)
        } catch (err) {
            console.error("Failed to update member password", err)
        }
    },
)

/**
 * Update member password
 * -----------------------------------------------------------------------------
 */
export const validateMemberPassword = createAsyncThunk(
    "member-settings/validateMemberPassword",
    async (params: PasswordValidateAttributes, { dispatch }) => {
        try {

            const url = "/api/janus/digital-first-information-service/graphql"
            const query = validateMemberPasswordQuery
            const res = await http.mutation(url, query, { password: params.newPassword })
            /**
             * Handle request errors
             */
            if (!res || res?.error || res?.status !== 200) {
                console.error(
                    "Failed to validate password!",
                    res?.status,
                )
                dispatch(actions.myAccountError({ response: res }))
            }

            const __typename =
                res.data?.data &&
                res.data?.data?.riskAnalysis &&
                res.data?.data?.riskAnalysis?.__typename
                    ? res.data?.data?.riskAnalysis?.__typename
                    : ""
            const strength =
                res.data?.data &&
                res.data?.data?.riskAnalysis &&
                res.data?.data?.riskAnalysis?.strength
                    ? res.data?.data?.riskAnalysis?.strength
                    : ""
            const risks =
                res.data?.data &&
                res.data?.data?.riskAnalysis &&
                res.data?.data?.riskAnalysis?.risks?.map(risk => risk)
            const response: ValidationStatus = {
                strength,
                risks,
                __typename,
            }

            /**
             * Update state
             */
            dispatch(
                actions.receiveMyAccountPasswordValidationResult({
                    strength,
                    risks,
                }),
            )
        } catch (err) {
            console.error("Failed to update member password", err)
        }
    },
)

export const updateEmail = createAsyncThunk(
    "account-settings/updateEmail",
    async (params: SaveEmail, { dispatch }) => {
        /**
         * Request to update email address
         */
        if(params.email){
            try {
                const url = "/api/janus/digital-first-information-service/graphql"
                const query = saveEmailQuery
                const res = await http.mutation(url, query, {email: params.email})
                if (!res || res?.error || res?.status !== 200 || res?.data?.errors) {
                    console.error(
                        "Failed to update email",
                        res?.error,
                        res?.data?.errors[0]?.message,
                        res?.status,
                        params.email,
                    )
                }
                if (!res?.data?.errors && res?.data?.data?.updateEmail?.success === true) {
                    dispatch(
                        actions.updateMyAccountProfileEmail(
                            {email: params.email}
                        ))
                    dispatch(
                        actions.receiveMyAccountEmailActivationStatus({
                            emailActivation: {
                                status: "",
                            },
                        }),
                    )
                }
                return Promise.resolve(res)
            } catch (err) {
                console.error("Failed to update email", params.email, err)
            }
        }
    },
)

/**
 * Cancel Email Address Verification
 * -----------------------------------------------------------------------------
 */
export const cancelEmailVerification = createAsyncThunk(
    "member-settings/cancelEmailVerification", // Reducer name
    async (params: void, { dispatch }) => {
        try {
            const url = "/api/janus/digital-first-information-service/graphql"
            const query = cancelEmailVerificationQuery
            const res = await http.mutation(url, query, {})

            if (!res || res?.error || res?.status !== 200 || res?.data?.errors) {
                console.error(
                    "Failed to cancel Email verification",
                    res?.data?.errors[0]?.message,
                    res?.error,
                    res?.status,
                )
            }
            await dispatch(actions.fetchMemberSettings()) 
            return Promise.resolve(res)
        } catch (err) {
            console.error("Failed to cancel Email verification", err)
        }
    },
)

/**
 * Update SMS data
 * -----------------------------------------------------------------------------
 */
export const updateSMS = createAsyncThunk(
    "member-settings/updateSMS", // Reducer name
    async (params: CreateSMS, { dispatch }) => {
        try {
            const number = formatPhoneDashes(params.number)
            const url = "/api/janus/digital-first-information-service/graphql"
            const query = createSMSQuery
            const variables = {
                number: number,
            }

            const res = await http.mutation(url, query, variables)

            if (!res || res?.error || res?.status !== 200) {
                console.error(
                    "Failed to update SMS number",
                    res?.error,
                    res?.status,
                )
            }
            return Promise.resolve(res)
        } catch (err) {
            console.error("Failed to update SMS number", err)
        }
    },
)

/**
 * Update SMS Activation data
 * -----------------------------------------------------------------------------
 */
export const updateSMSActivation = createAsyncThunk(
    "member-settings/updateSMSActivation", // Reducer name
    async (params: ActivateSMS, { dispatch }) => {
        try {
            const url = "/api/janus/digital-first-information-service/graphql"
            const query = activateSMSQuery
            const variables = {
                code: params.code,
            }

            const res = await http.mutation(url, query, variables)

            if (!res || res?.error || res?.status !== 200) {
                console.error(
                    "Failed to activate SMS number",
                    res?.error,
                    res?.status,
                )
            }
            return Promise.resolve(res)
        } catch (err) {
            console.error("Failed to activate SMS number", err)
        }
    },
)

/**
 * Cancel SMS Activation data
 * -----------------------------------------------------------------------------
 */
export const cancelSMSActivation = createAsyncThunk(
    "member-settings/cancelSMSActivation", // Reducer name
    async () => {
        try {
            const url = "/api/janus/digital-first-information-service/graphql"
            const query = cancelSMSActivationQuery

            const res = await http.mutation(url, query, {})

            if (!res || res?.error || res?.status !== 200) {
                console.error(
                    "Failed to cancel SMS activation",
                    res?.error,
                    res?.status,
                )
            }
            return Promise.resolve(res)
        } catch (err) {
            console.error("Failed to cancel SMS activation", err)
        }
    },
)

/**
 * Delete SMS number data
 * -----------------------------------------------------------------------------
 */
export const deleteSMSNumber = createAsyncThunk(
    "member-settings/deleteSMSNumber", // Reducer name
    async (params: DeleteSMS, { dispatch }) => {
        try {
            const number = formatPhoneDashes(params.phoneNumber)
            const url = "/api/janus/digital-first-information-service/graphql"
            const query = removeSMSQuery
            const variables = {
                phoneNumber: number,
            }

            const res = await http.mutation(url, query, variables)

            if (!res || res?.error || res?.status !== 200) {
                console.error(
                    "Failed to remove SMS number",
                    res?.error,
                    res?.status,
                )
            } else {
                dispatch(
                    actions.receiveMemberPhoneNumber({
                        activated: "",
                        number: "",
                    }),
                )
            }
            return Promise.resolve(res)
        } catch (err) {
            console.error("Failed to remove SMS number", err)
        }
    },
)

export const updateHsa = createAsyncThunk(  
    "account-settings/hsaPreference",
    async (params: savePreferences, { dispatch }) => {
        /**
         * Get HSA current state
         */
        const { hsa, myAccount } = store.getState()

        const { balance, shareHealthInfo, shareClaims } = myAccount.profile.preferences.hsaPreferences
        const preferences = {
            isbalance: myAccount.hsaPreferences.isbalance !== null ? myAccount.hsaPreferences?.isbalance : balance.setting === true,
            isInfo: myAccount.hsaPreferences.isInfo !== null ? myAccount.hsaPreferences?.isInfo : shareHealthInfo.setting === true,
            isClaims: myAccount.hsaPreferences.isClaims !== null ? myAccount.hsaPreferences.isClaims : shareClaims.setting === true,
        }

        /**
         * Request to update HSA preference
         */
        try {
            // eslint-disable-next-line functional/no-let
            let hsaPreferences: HsaPreferences;
            // filter hsaBalance, hsashareClaims and hsashareHealthInfo preferences based on preference code
            switch(params.code) {
                case "hsa-balance":
                    hsaPreferences = {
                        ...preferences,
                        isbalance : params.value
                    }
                    break;
                case "hsa-claims-sharing":
                    hsaPreferences = {
                        ...preferences,
                        isClaims: params.value,
                    }
                    break;
                case "hsa-elig-sharing":
                    if(!params.value) {
                        hsaPreferences = {
                            ...preferences,
                            isClaims: false,
                            isInfo: false,
                        }
                    } else {
                        hsaPreferences = {
                            ...preferences,
                            isInfo: params.value,
                        }
                    }
                    break;   
                default:
                    hsaPreferences = preferences
                    break;
            }
            const url = "/api/janus/digital-first-information-service/graphql"
            const query = hsaPreferecesSaveQuery
            const res = await http.mutation(url, query, { 
                code: params.code,
                value: params.value,
            })
            if (!res || res?.error || res?.status !== 200 || res?.data?.errors) {
                console.error(
                    "Failed to update HSA preferences",
                    res?.error,
                    res?.status,
                    params.code,
                    res?.data?.errors[0]?.message,
                )
            } else {
                /**
                 * Update state Member Settings
                 */
                dispatch(
                    actions.receiveMemberHsaPreference(hsaPreferences),
                )

                const popups = params.value ? {
                    showBalancePopup: false,
                    showClaimsPopup: params.code === "hsa-balance" ? (hsaPreferences.isInfo && !hsaPreferences.isClaims && params.showClaimsPopup) : false,
                } : {}
                const hsaValues = params.code === "hsa-balance" ? {
                    hsaDisplayBalance: params.value
                } : {
                    heqShare: params.value ? "E" : "N"
                }
                
                // Update Claims overview - HSA balance/HEQ Share card
                dispatch(
                    actions.receiveHsa({
                        ...hsa,
                        ...popups,
                        ...hsaValues,
                        isLoading: false,
                    }),
                )
            }
        } catch (err) {
            console.error("Failed to update HSA preferences", params.code, err)
        }
    },
)

export const updateMarketing = createAsyncThunk(
    "account-settings/updateMarketing",
    async (params: saveConsumerSetting, { dispatch }) => {
        /**
         * Request to update Marketing Authorization preference
         */
        try {
            // Get current state
            const { myAccount } = store.getState()
            const sso = myAccount.consumerSettings.sso
            const wellness = myAccount.consumerSettings.wellness
            const providerAccessAuth = myAccount.consumerSettings?.providerAccessAuth
            const marketingCode = myAccount.consumerSettings?.marketing?.programId

            // Define parameters (on vs off)
            const marketingOn = {
                code: marketingCode,
                value: true,
                confirmation_text: params.confirmationText,
                HIPAA_version: "v2.0",
            }
            const marketingOff = { code: marketingCode, value: false, confirmation_text: "" }

            // const query = params.value ? marketingOnQuery : marketingOffQuery
            const marketingParams = params.value ? marketingOn : marketingOff

            const url = "/api/janus/digital-first-information-service/graphql"
            const res = await http.mutation(url, updatePreferenceQuery, marketingParams)
            // Error handling
            if (!res || res?.error || res?.status !== 200) {
                console.error(
                    "Failed to update Marketing Authorization preference",
                    res?.error,
                    res?.status,
                    params,
                )
            } else {
                /**
                 * Update state if there are no errors
                 */
                dispatch(
                    actions.receiveMemberConsumerSettings({
                        marketing: {
                            programId: marketingCode,
                            setting: params.value,
                            confirmationText: params.confirmationText,
                        },
                        sso: sso,
                        wellness: wellness,
                        providerAccessAuth: providerAccessAuth,
                    }),
                )
            }
        } catch (err) {
            console.error(
                "Failed to update Marketing Authorization preference",
                params,
                err,
            )
        }
    },
)

export const updateProviderAccessAuth = createAsyncThunk(
    "account-settings/updateProviderAccessAuth",
    async (params: saveConsumerSetting, { dispatch }) => {
        /**
         * Request to update Provider Access Authorization preference
         */
        try {
            // Get current state
            const { myAccount } = store.getState()
            const sso = myAccount.consumerSettings.sso
            const wellness = myAccount.consumerSettings.wellness
            const marketing = myAccount.consumerSettings?.marketing
            const providerCode = myAccount.consumerSettings?.providerAccessAuth?.programId


            // Define parameters (on vs off)
            const providerAuthOn = {
                code: providerCode,
                value: true,
                confirmation_text: params.confirmationText,
                HIPAA_version: "v2.0",
            }
            const providerAuthOff = { code: providerCode, value: false, confirmation_text: "" }

            const providerAuthParams = params.value ? providerAuthOn : providerAuthOff

            const url = "/api/janus/digital-first-information-service/graphql"
            const res = await http.mutation(url, updatePreferenceQuery, providerAuthParams)
            // Error handling
            if (!res || res?.error || res?.status !== 200) {
                console.error(
                    "Failed to update Provider Access Authorization preference",
                    res?.error,
                    res?.status,
                    params,
                )
            } else {
                /**
                 * Update state if there are no errors
                 */
                dispatch(
                    actions.receiveMemberConsumerSettings({
                        providerAccessAuth: {
                            programId: providerCode,
                            setting: params.value,
                            confirmationText: params.confirmationText,
                        },
                        sso: sso,
                        wellness: wellness,
                        marketing: marketing,
                    }),
                )
            }
        } catch (err) {
            console.error(
                "Failed to update Marketing Authorization preference",
                params,
                err,
            )
        }
    },
)

export const updateSSO = createAsyncThunk(
    "account-settings/updateSSO",
    async (params: saveConsumerSetting, { dispatch }) => {
        /**
         * Request to update SSO Authorization preference
         */
        try {
            // Get current state
            const { myAccount } = store.getState()
            const marketing = myAccount.consumerSettings.marketing
            const wellness = myAccount.consumerSettings.wellness
            const providerAccessAuth = myAccount.consumerSettings?.providerAccessAuth
            const ssoCode = myAccount.consumerSettings?.sso?.programId

            // Define parameters (on vs off)
            const ssoOn = {
                code: ssoCode,
                value: true,
                confirmation_text: params.confirmationText,
            }
            const ssoOff = { code: ssoCode, value: false, confirmation_text: "" }

            // const query = params.value ? ssoOnQuery : ssoOffQuery
            const ssoParams = params.value ? ssoOn : ssoOff

            const url = "/api/janus/digital-first-information-service/graphql"
            const res = await http.mutation(url, updatePreferenceQuery, ssoParams)
            // Error handling
            if (!res || res?.error || res?.status !== 200) {
                console.error(
                    "Failed to update SSO Authorization preference",
                    res?.error,
                    res?.status,
                    params,
                )
            } else {
                /**
                 * Update state if there are no errors
                 */
                dispatch(
                    actions.receiveMemberConsumerSettings({
                        marketing: marketing,
                        sso: {
                            programId: ssoCode,
                            setting: params.value,
                            confirmationText: params.confirmationText,
                        },
                        wellness: wellness,
                        providerAccessAuth: providerAccessAuth,
                    }),
                )
            }
        } catch (err) {
            console.error(
                "Failed to update SSO Authorization preference",
                params,
                err,
            )
        }
    },
)

export const updateWellness = createAsyncThunk(
    "account-settings/updateWellness",
    async (params: saveConsumerSetting, { dispatch }) => {
        /**
         * Request to update Wellness Authorization preference
         */
        try {
            // Get current state
            const { myAccount } = store.getState()
            const marketing = myAccount.consumerSettings.marketing
            const sso = myAccount.consumerSettings.sso
            const providerAccessAuth = myAccount.consumerSettings?.providerAccessAuth
            const wellnessCode = myAccount.consumerSettings?.wellness?.programId

            // Define parameters (on vs off)
            const wellnessOn = {
                code: wellnessCode,
                value: true,
                confirmation_text: params.confirmationText,
            }
            const wellnessOff = { code: wellnessCode, value: false, confirmation_text: "" }

            // const query = params.value ? wellnessOnQuery : wellnessOffQuery
            const wellnessParams = params.value ? wellnessOn : wellnessOff

            const url = "/api/janus/digital-first-information-service/graphql"
            const res = await http.mutation(url, updatePreferenceQuery, wellnessParams)
            // Error handling
            if (!res || res?.error || res?.status !== 200) {
                console.error(
                    "Failed to update Wellness Authorization preference",
                    res?.error,
                    res?.status,
                    params,
                )
            } else {
                /**
                 * Update state if there are no errors
                 */
                dispatch(
                    actions.receiveMemberConsumerSettings({
                        marketing: marketing,
                        sso: sso,
                        providerAccessAuth:providerAccessAuth,
                        wellness: {
                            programId: wellnessCode,
                            setting: params.value,
                            confirmationText: params.confirmationText,
                        },
                    }),
                )
            }
        } catch (err) {
            console.error(
                "Failed to update Wellness Authorization preference",
                params,
                err,
            )
        }
    },
)

export const updateMedicareAddress = createAsyncThunk(
    "member-settings/updateMedicareAddress",
    async (params: medicareMemberAddress, {dispatch}) => {
        /**
         * Request to update medicare address
         */
        try {
            const address = {
                addressType: params.addressType,
                addressLine1: params.addressLine1,
                addressLine2: params.addressLine2,
                city: params.city,
                state: params.state,
                zip: params.zip,
                temporaryMove: params.temporaryMove,
                moveDate: params.moveDate,
                additionalAddressType1: params.additionalAddressType1,
                additionalAddressType2: params.additionalAddressType2,
            }
            const url = "/api/janus/digital-first-information-service/graphql"
            const res = await http.mutation(url, medicareChangeAddress, address)

            // Error handling
            if (!res || res?.data.errors || res?.status !== 200) {
                console.error(
                    "Failed to update medicare address",
                    res?.error,
                    res?.status,
                    params,
                )
            } else {
                //The actual update takes times to process and go through so a refresh will show the old one
                //That is how the current change address works as well
                if (address.addressType === "mailing") {
                    dispatch(actions.receiveMemberMedicareAddress({
                        address1: address.addressLine1,
                        address2: address.addressLine2,
                        city: address.city,
                        state: address.state,
                        zip: address.zip,
                    }))
                }
                if (address.addressType === "residential") {
                    dispatch(actions.receiveMemberMedicareResidentialAddress({
                        address1: address.addressLine1,
                        address2: address.addressLine2,
                        city: address.city,
                        state: address.state,
                        zip: address.zip,
                    }))
                }
                if (address.addressType === "billing") {
                    dispatch(actions.receiveMemberMedicareBillingAddress({
                        address1: address.addressLine1,
                        address2: address.addressLine2,
                        city: address.city,
                        state: address.state,
                        zip: address.zip,
                    }))
                }
            }
            return Promise.resolve(res)
        } catch (err) {
            console.error(
                "Failed to update medicare address",
                params,
                err,
            )
        }
    },
)

export const updateMailingAddress = createAsyncThunk(
    "member-settings/updateMailingAddress",
    async (params: Address, { dispatch }) => {
        /**
         * Request to update mailing address
         */

        try{
            const url = "/api/janus/digital-first-information-service/graphql"
            const query = updateMailingAddressQuery
            const address = {
                addressLine1: params.address1,
                addressLine2: params.address2,
                city: params.city,
                state: params.state,
                zip: params.zip,
            }
            const res = await http.mutation(url, query, address)
            if (!res || res?.data.errors || res?.status !==200) {
                console.error("Failed to update mailing address", res?.error, res?.status, params)
            }
        }catch(err){
            console.error("Failed to update mailing address", params, err)
        }
    },
)

export const updateShareInfo = createAsyncThunk(
    "member-settings/updateShareInfo",
    async (params: MemberFamilyShareInfo, {dispatch}) => {
        /**
         * Request to update share info
         */

        try {
            const url = "/api/janus/digital-first-information-service/graphql"
            const query = updateShareInfoQuery
            const variables = {
                authorized: params.authorized,
                suffix: params.suffix,
            }

            const res = await http.mutation(url, query, variables)
            if (!res || res?.error || res?.status !==200) {
                console.error("Failed to update share info", res?.error, res?.status, params)
            }
            return Promise.resolve(res)
        } catch(err) {
            console.error("Failed to update share info", params, err)
        }

    }
)

export const getFamilyInfo = createAsyncThunk(
    "member-settings/getFamilyInfo",
    async (params: void, {dispatch}) => {
        /**
         * Request to get family info
         */

        try {
            const url = "/api/janus/digital-first-information-service/graphql"
            const res = await http.query(url, getFamilyInfoQuery)
            if (!res || res?.error || res?.status !==200) {
                console.error("Failed to get family info", res?.error, res?.status, params)
            }
            const family = res?.data?.data?.myAccount?.familySharing?.familyShareInfo
            return Promise.resolve(family)
        } catch(err) {
            console.error("Failed to get family info", params, err)
        }

    }
)

export const updateCPSPreference = createAsyncThunk(
    "member-settings/updateCPSPreference",
    async (params: savePreferences, { dispatch }) => {
        const { myAccount } = store.getState()
        const preferences = myAccount.subscriptionsPreferences
        /**
         * Request to update cps preference
         */
        try {
            const url = "/api/janus/digital-first-information-service/graphql"
            const query = cpsSaveQuery
            const res = await http.mutation(url, query, {
                code: params.code,
                value: params.value
            })
            if (!res || res?.error || res?.status !== 200) {
                console.error(
                    "Failed to set CPS preference",
                    res?.error,
                    res?.status,
                    params,
                )
            }
        } catch (err) {
            console.error("Failed to set CPS preference", params, err)
        }

        /**
         * Update state
         */
        if ( params.code === "news-updates-sms" ) {
            dispatch(actions.receiveMemberNewsSubscriptionPreference({
                sms: params.value,
                email: preferences.news.email
            }))
        } else if ( params.code === "feedback-sms" ) {
            dispatch(actions.receiveMemberFeedbackSubscriptionPreference({
                sms: params.value,
                email: preferences.feedback.email
            }))
        } else if ( params.code === "news-updates-email" ) {
            dispatch(actions.receiveMemberNewsSubscriptionPreference({
                email: params.value,
                sms: preferences.news.sms
            }))
        } else if ( params.code === "feedback-email" ) {
            dispatch(actions.receiveMemberFeedbackSubscriptionPreference({
                email: params.value,
                sms: preferences.feedback.sms
            }))
        } else if ( params.code === "claim-alerts-sms" ) {
            dispatch(actions.receiveMemberClaimsSubscriptionPreference({
                sms: params.value
            }))
        }
    },
)


//
// Fetch from my-account-presentation-service
// /**
//   * Setting Communication Preference
//  * -----------------------------------------------------------------------------

// **/
export const fetchCommunicationPreferences = createAsyncThunk(
    "account-settings/fetchCommunicationPreferences", // Reducer name
    async (params: void, { dispatch }) => {
        try {
            /**
             * Set loading state
             */
            dispatch(actions.myAccountStatus("pending"))

            const res = await http.query(
                "/api/janus/digital-first-information-service/graphql",
                fetchCommunicationPreferencesQuery,
            )

            if (res.error) {
                return dispatch(actions.myAccountError({ response: res }))
            }

            const communicationPreferences =
                res?.data?.data.myAccount?.preferences?.communicationPreferences

            /**
             * Update state
             */
            dispatch(actions.receiveCommunicationPreference(communicationPreferences))
        } catch (err) {
            console.error("Failed to retrieve communication preferences", err)
        }
    },
)

export const updateCommunicationPreferences = createAsyncThunk(
    "account-settings/updatePreferencesQuery ",
    async (params: UpdateCommPreferences, { dispatch }) => {
        /**
         * Request to update Communication Preference
         */

        try {
            // Get current state
            const { myAccount, app } = store.getState()
            const isAsuris = app.brand === "asuris"
            const feedbackEmail = {
                code: params.feedback.email.programId,
                value: params.feedback.email.setting,
            }
            const feedbackSms = !isAsuris && {
                code: params.feedback.sms.programId,
                value: params.feedback.sms.setting,
            }
            const newsUpdatesEmail = {
                code: params.newsUpdates.email.programId,
                value: params.newsUpdates.email.setting,
            }
            const newsUpdatesSms = !isAsuris && {
                code: params.newsUpdates.sms.programId,
                value: params.newsUpdates.sms.setting,
            }
            const url = "/api/janus/digital-first-information-service/graphql"
            const hasPhoneNumber = myAccount?.profile?.phone?.activated === true

            const preferenceCalls = hasPhoneNumber && !isAsuris
                ? [
                      http.mutation(url, updatePreferencesQuery, feedbackEmail),
                      http.mutation(url, updatePreferencesQuery, feedbackSms),
                      http.mutation(
                          url,
                          updatePreferencesQuery,
                          newsUpdatesEmail,
                      ),
                      http.mutation(
                          url,
                          updatePreferencesQuery,
                          newsUpdatesSms,
                      ),
                  ]
                : [
                      http.mutation(url, updatePreferencesQuery, feedbackEmail),
                      http.mutation(
                          url,
                          updatePreferencesQuery,
                          newsUpdatesEmail,
                      ),
                  ]

            const res = await Promise.all(preferenceCalls)

            // Error handling
            const errors = []
            if (res){
                  res.map((response, index)=> {
                if( res[index].error){
                    errors.push(res[index].error)
                }
                if( !res[index].data?.data?.updatePreference?.success){
                    errors.push(res[index])
                }
            })
            }

            if (errors.length > 0) {
                console.error("Failed to get info", errors)
                return "Failed"
            } else {
                /**
                 * Update state if there are no errors
                 */
                dispatch(actions.receiveCommunicationPreference(params))
                return "Success"
            }
        } catch (err) {
            console.error(
                "Failed to updatecommunication Authorization preference",
                params,
                err,
            )
        }
    },
) 