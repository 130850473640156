/**
 * Benefits reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { MemberBenefit } from "@/store/benefits/types"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface CareGapsBenefits {
    readonly benefits: { readonly [familyMemberId: string]: MemberBenefit }
    readonly benefitsByMembershipId: { 
        readonly [membershipId: string]: MemberBenefit 
    }
    readonly family: {
        readonly items: ReadonlyArray<{
            readonly membershipId?: string,
            readonly firstName?: string,
            readonly lastName?: string,
            readonly birthDate?: string,
            readonly currentAge?: string,
            readonly relationship?: string,
            readonly disclosureAllowed?: string,
            readonly suffix?: string,
          }>
        readonly selected?: string
        readonly selectedMembershipId?: string
        readonly requesterMembershipId?: string
    }
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<CareGapsBenefits> = {
    status: "pristine",
    benefits: {},
    benefitsByMembershipId: {},
    family: { items: [] },
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const careGapsBenefits = createStatefulSlice({
    name: "careGapsBenefits",
    initialState,
    reducers: {
        receiveCareGapsBenefits: (
            state: SliceState<CareGapsBenefits>,
            action: PayloadAction<CareGapsBenefits>,
        ) => {
            return {
                status: "completed",
                benefits: action.payload.benefits || {},
                benefitsByMembershipId: action.payload.benefitsByMembershipId || {},
                family: action.payload.family || { items: []},
            }
        },
        resetCareGapsBenefits: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const careGapsBenefitsActions = { ...careGapsBenefits.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default careGapsBenefits.reducer
