import * as React from "react"
import { SVGProps } from "react"
const SvgDocument = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Paper" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M15 1V5C15 6.10457 15.8954 7 17 7H21M4.8 1H14.8759C15.3438 1 15.7968 1.16403 16.1563 1.46356L20.2804 4.90031C20.7364 5.2803 21 5.84319 21 6.43675V20.8C21 22.01 20.19 23 19.2 23H4.8C3.81 23 3 22.01 3 20.8V3.2C3 1.99 3.81 1 4.8 1Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgDocument
