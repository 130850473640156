/**
 * Recent Claims reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"
import * as actions from "./actions"
import { RecentClaim } from "./types"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface RecentClaims {
    // Claims props
    readonly items: ReadonlyArray<RecentClaim>
}

/**
 * Initial states
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<RecentClaims> = {
    status: "pristine",
    items: [],
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const recentClaims = createStatefulSlice({
    name: "recentClaims",
    initialState,
    reducers: {
        receiveRecentClaims: (
            state: SliceState<RecentClaims>,
            action: PayloadAction<RecentClaims>,
        ) => {
            return {
                status: "completed",
                items: action.payload.items || [],
            }
        },
        resetRecentClaims: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const recentClaimsActions = { ...recentClaims.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default recentClaims.reducer
