/**
 * Image Viewer
 * -----------------------------------------------------------------------------
 */
import React from "react"
import ReactDOM from "react-dom"
import { createFocusTrap, FocusTrap } from "focus-trap"
import { Button } from "elements"
import { useEscapeModal, useBodyScroll } from "utils/window"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props extends React.HTMLAttributes<HTMLDivElement> {
    readonly name: string
    readonly src: string
    readonly alt: string
    readonly description?: string
    readonly isVisible: boolean
    readonly onDismiss: VoidFunction
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const ImageViewer: React.FunctionComponent<Props> = props => {
    /**
     * Hooks
     */
    const escapeModal = useEscapeModal("image-viewer", props.onDismiss)
    const bodyScroll = useBodyScroll()

    /**
     * Component state
     */
    const [focusTrap, setFocusTrap] = React.useState<FocusTrap>()

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        // Setup a "Focus Trap" – prevent tabbing out of modal
        if (props.isVisible) {
            setFocusTrap(() => {
                const trap = createFocusTrap("#imageViewer")
                trap.activate()
                return trap
            })
        } else {
            if (focusTrap) focusTrap.deactivate()
        }

        // Listen for `escape` to dismiss
        if (props.isVisible) {
            bodyScroll.disable()
            escapeModal.addListener()
        }
        return () => {
            bodyScroll.enable()
            escapeModal.removeListener()
        }
    }, [props.isVisible])

    /**
     * Define portal root
     */
    const root = document.getElementById("root-popover")

    /**
     * Template
     */
    const Template = props.isVisible ? (
        <div
            className="fixed z-popover h-screen w-screen bg-black bg-opacity-70 flex items-center justify-center py-xxl px-sm md:px-xl box-border"
            onClick={props.onDismiss}
            id="imageViewer"
            role="dialog"
            aria-modal={true}
        >
            <Button
                name="image-viewer-dismiss-button"
                icon="x"
                inverted={true}
                onClick={props.onDismiss}
                className="absolute top-sm right-sm"
            />

            <figure className="flex flex-col justify-center items-center w-full h-full">
                <img
                    src={props.src}
                    alt={props.alt}
                    className="block object-contain max-h-full max-w-full min-h-none mx-auto rounded-sm"
                    onClick={e => e.stopPropagation()}
                />
                {props.description && (
                    <figcaption
                        aria-hidden="true"
                        className="inline-block px-sm py-xxs mt-xs rounded bg-white dark:bg-dark-200 font-medium dark:text-white"
                        onClick={e => e.stopPropagation()}
                    >
                        {props.description}
                    </figcaption>
                )}
            </figure>
        </div>
    ) : null
    return root ? ReactDOM.createPortal(Template, root) : null
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default ImageViewer
