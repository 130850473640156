/**
 * External Link Modal
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Modal, Button } from "elements"
import { useBrand } from "utils/env"
import { useSelector } from "@/store";

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly name: string
    readonly href?: string
    readonly target?: string
    readonly isVisible: boolean
    readonly onDismiss?: (e?: React.MouseEvent) => void
    readonly onClick?: (e?: React.MouseEvent) => void
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const ExternalLinkModal: React.FC<Props> = props => {
    /**
     * Hooks
     */
    const brand = useBrand()
    const account = useSelector(state => state.account)
    const audience = account?.profile?.audience

    /**
     * Define template variables
     */
    const sitename = (() => {
        // eslint-disable-next-line functional/no-let
        let name = "";
        switch (true) {
            case (brand === "regence") :
                name = "the Regence";
                break;
            case (brand === "asuris") :
                name = "the Asuris";
                break;
            case (brand === "bridgespan") :
                name = "the Bridgespan";
                break;
            default:
                name = "the Regence";
        }
        return name;
    })()

    /**
     * Template
     */
    return (
        <Modal
            name={props.name}
            size="small"
            noBorder={true}
            noDismiss={true}
            isVisible={props.isVisible}
            onDismiss={props.onDismiss}
        >
            <Modal.Content>
                <p>
                    You are now leaving {sitename} site. Once you click "ok" and are directed to a new site, the new site's terms, conditions, and privacy policy will apply.
                </p>
            </Modal.Content>
            <Modal.Footer className="text-right">
                <Button
                    name={props.name + "-cancel-button"}
                    label="Cancel"
                    variant="link"
                    onClick={props.onDismiss}
                />
                {props.onClick
                    ? <Button
                        name={props.name + "-proceed-button"}
                        label="Ok"
                        variant="primary"
                        onClick={props.onClick}
                    />
                    : <Button
                        name={props.name + "-proceed-button"}
                        label="Ok"
                        variant="primary"
                        href={props.href}
                        target={props.target}
                        onClick={props.onDismiss}
                    />

                }
            </Modal.Footer>
        </Modal>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default ExternalLinkModal
