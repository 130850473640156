/**
 * Recent Claims Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions } from "store"
import gql from "@/utils/gql"
import { NormalizedCacheObject } from "@apollo/client"
import { dfisClient } from "@/utils/apollo-client"
import { getHash } from "@/utils/crypto"
import { recentClaimsQuery } from "./queries"
import { ClaimsInquiryResult } from "./types"

/**
 * Get recent claims data
 * -----------------------------------------------------------------------------
 */
export const fetchRecentClaims = createAsyncThunk(
    "recentClaims/fetchRecentClaims", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.recentClaimsStatus("pending"))

        /**
         * Fetch recent claims (from Claims Inquiry Service through DFIS)
         */
        try {
            const res = await gql.query<
                NormalizedCacheObject,
                ClaimsInquiryResult
            >({
                apolloClient: dfisClient,
                queryString: recentClaimsQuery,
            })
            /**
             * Handle request errors
             */
            if (res.error || res.errors) {
                return dispatch(
                    actions.recentClaimsError({
                        response: res,
                    }),
                )
            }

            /**
             * Transform recent claims data
             */
            const recentClaims =
                res.data?.claimsInquiry.recentClaims.claimData || []

            const items = recentClaims?.map(data => {
                return {
                    ...data,
                    claimNumberHash: getHash(data.claimNumber),
                }
            })

            /**
             * Update state
             */
            dispatch(
                actions.receiveRecentClaims({
                    items: items || [],
                }),
            )
        } catch (error) {
            console.error("Error while fetching recent claims")
            return dispatch(
                actions.recentClaimsError({
                    response: error,
                }),
            )
        }
    },
)
