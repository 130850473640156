/**
 * PremiumPay reducer
 * -----------------------------------------------------------------------------
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { PremiumPaymentInfoItem } from "./types"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface PremiumPaymentInfo {
    /**
     * Init states
     */
    readonly isInitialized: boolean
    readonly isLoading: boolean
    readonly errorMessage?: string
    readonly premiumPaymentInfoItem?: PremiumPaymentInfoItem
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: PremiumPaymentInfo = {
    isInitialized: false,
    isLoading: false,
    errorMessage: undefined,
    premiumPaymentInfoItem: {
        onlinePaymentEligible: false,
        premiumPayment: {
            subscriberId: "",
            groupId: "",
            eftFlag: false,
            totalAmountDue: 0.00,
            pastDueAmount: 0.00,
            newChargeAmount: 0.00,
            pendingPaymentFlag: false,
            printStatementId: "",
            dueDate: "",
            paymentEligibleFlag: false,
            exclusionReason: "",
            paybyDate: "",
            coverageTerminated: false,
            invoicePendingFlag: false,
            isPaymentPastDue: false,
            paymentDate: "",
        },
        premiumPaymentHistory: undefined, //TODO: Add history related data 
        extensions:{
            metadata:{
                status:""
            }
        }
    },
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const premiumPaymentInfo = createSlice({
    name: "premiumPaymentInfo",
    initialState,
    reducers: {
        receivePremiumPaymentInfo: (
            state: PremiumPaymentInfo,
            action: PayloadAction<PremiumPaymentInfo>,
        ) => {
            return action.payload
        },
        resetPremiumPaymentInfo: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const premiumPaymentActions = { ...premiumPaymentInfo.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default premiumPaymentInfo.reducer
