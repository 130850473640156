/**
 * Date Utilities
 * -----------------------------------------------------------------------------
 * https://date-fns.org/
 */
import { format, parseISO, fromUnixTime } from "date-fns"

//const logger = console

/**
 * Parse string as date
 * -----------------------------------------------------------------------------
 */
export const parseDate = (date: string | number | Date): Date => {
    if (typeof date === "string") {
        return parseISO(date)
    } else if (typeof date === "number") {
        return fromUnixTime(date)
    } else {
        return date
    }
}

/**
 * Format date as string
 * -----------------------------------------------------------------------------
 */
export const formatDate = (
    date: string | number | Date,
    formatting?: string,
): string => {
    try{
        if (typeof date === "string") {
            return format(parseISO(date), formatting || "PPPP")
        } else if (typeof date === "number") {
            return format(fromUnixTime(date), formatting || "PPPP")
        } else {
            return format(date, formatting || "PPPP")
        }
    } catch (err) {
        // logger.error({message: "Failed to format date", err, date, formatting})
        return ""
    }
}

/**
 * Separate Date as Parts
 * -----------------------------------------------------------------------------
 * separateDate("2021-01-01") // { year: "2021", month: "Jan", day: "01" }
 * separateDate("2021-01-01", { day: "Do" }) // { ..., day: "1st" }
 */
export const separateDate = (
    date: string | Date,
    formats?: {
        readonly day?: string
        readonly month?: string
        readonly year?: string
    },
): {
    readonly day: string
    readonly month: string
    readonly year: string
} => {
    const parsed = typeof date === "string" ? parseISO(date) : date

    if (!parsed) {
        return {
            day: "",
            month: "",
            year: "",
        }
    }

    return {
        day: format(parsed, formats?.day || "dd"),
        month: format(parsed, formats?.month || "MMM"),
        year: format(parsed, formats?.year || "yyyy"),
    }
}
