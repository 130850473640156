import { BenefitsType } from "../benefits/types";

/**
 * GraphQL query to fetch accumulators
 */
export const accumulatorsQuery = (
    benefitsType: BenefitsType = BenefitsType.Default,
) => `
query Query {
    benefits (type: ${benefitsType}) {
        totalCount
        familyMembers {
          membershipId
          firstName
          lastName
          birthDate
          currentAge
          relationship
          disclosureAllowed
          suffix
        }
        edges {
          cursor
          node {
            patientId
            membershipId
            memberId
            groupId
            suffix
            medical {
              ...benefitInfo
            }
            dental {
              ...benefitInfo
            }
            vision {
              ...benefitInfo
            }
            pharmacy {
              ...benefitInfo
            }
            hearing {
              ...benefitInfo
            }
          }
        }
      }
  accumulators {
    totalCount
    edges {
      cursor
      node {
        patientId
        membershipId
        memberId
        groupId
        suffix
        medical {
          ...accumulatorInfo
        }
        dental {
          ...accumulatorInfo
        }
      }
    }
  }
}

fragment accumulatorInfo on AccumulatorsByCategory {
  accumulatorTiers {
    tierNumber
    tierDescription
    accumulatorTierDetails {
      individualDeductibles {
        accumAcctNum
        memberAccumulatorDescription
        periodInd
        measurementCode
        accumulatorTotal
        accumulatorMedicalAmountMet
        accumulatorRxAmountMet
      }
      familyDeductibles {
        accumAcctNum
        memberAccumulatorDescription
        periodInd
        measurementCode
        accumulatorTotal
        accumulatorMedicalAmountMet
        accumulatorRxAmountMet
      }
      individualOutOfPocketMaxes {
        accumAcctNum
        memberAccumulatorDescription
        periodInd
        measurementCode
        accumulatorTotal
        accumulatorMedicalAmountMet
        accumulatorRxAmountMet
      }
      familyOutOfPocketMaxes {
        accumAcctNum
        memberAccumulatorDescription
        periodInd
        measurementCode
        accumulatorTotal
        accumulatorMedicalAmountMet
        accumulatorRxAmountMet
      }
    }
  }
}


fragment benefitInfo on BenefitsByCategory {
  benefitTiers {
    tierNumber
    tierDescription
    benefitTierDetails {
      copays {
        accumAcctNum
        memberAccumulatorDescription
        benefitAmount
        measurementCode
      }
      coinsurances {
        accumAcctNum
        memberAccumulatorDescription
        benefitAmount
        measurementCode
      }
      benefitLimits {
        accumAcctNum
        memberAccumulatorDescription
        periodInd
        measurementCode
        limitAmountTotal
        limitMetIndividual
        limitMetFamily
      }
    }
  }
}
`
