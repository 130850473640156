/**
 * Personal Information Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions } from "store"
import http from "utils/http"
import { personalInfoQuery, updateSelectedGenderIdentity, updateSelectedSexualOrientation } from "./queries"
import { OptionDatatype } from "./types"

/**
 * Get Personal Information data
 * -----------------------------------------------------------------------------
 */
export const fetchPersonalInfo = createAsyncThunk(
    "account/fetchPersonalInfo", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */

        /*personalInformationDetailsStatus is the combination of name from the reducer slice 
        in reducer here in this cause it is (personalInformationDetails) followed by "Status".
        If combination is not correct code will not be executed further
        */

        dispatch(actions.personalInformationDetailsStatus("pending"))

        /**
         * Request Personal Information data
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            personalInfoQuery,
        )

        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.personalInformationDetailsError({
                    response: res,
                }),
            )
        }

        /**
         * Update state
         */
        dispatch(
            actions.receivePersonalInfo({
                personalInfoData: res.data?.data?.myAccount?.accountInformation || {},
            }),
        )
    },
)

/**
 * Update gender Identity
 * -----------------------------------------------------------------------------
 */
export const updateGender = createAsyncThunk(
    "account/updateGender", // Reducer name
    async ({id, code, display}: OptionDatatype, {dispatch}) :Promise<string> => {
        try {
            /**
            * Request to update gender identity
            */
            // eslint-disable-next-line
            let payload = "MISSING_STATUS"
            const url = "/api/janus/digital-first-information-service/graphql"
            const res = await http.mutation(url, updateSelectedGenderIdentity, {
                id,code,display,
            })

            if (!res || res?.error || res?.status !== 200 || res?.data?.errors) {
                console.error(
                    "Failed to update genderIdentity ",
                    res?.data?.errors[0]?.message,
                    res?.error,
                    res?.status,
                )
            }

            payload =
                res.data &&
                res.data?.data?.updateSelectedGenderIdentity &&
                res.data?.data?.updateSelectedGenderIdentity?.success
                    ? "SUCCESS"
                    : "MISSING_STATUS"


            return Promise.resolve(payload)
        } catch (error) {
            console.error("Failed to update gender identity ", error)
        }
    }
)

/**
 * Update Sexual Orientation
 * -----------------------------------------------------------------------------
 */
export const updateSexualOrientation = createAsyncThunk(
    "account/updateSexualOrientation", // Reducer name
    async ({id, code, display}: OptionDatatype, {dispatch}) :Promise<string> => {
        try {
            /**
            * Request to sexual orientation update
            */
            // eslint-disable-next-line
            let payload = "MISSING_STATUS"
            const url = "/api/janus/digital-first-information-service/graphql"
            const res = await http.mutation(url, updateSelectedSexualOrientation, {
                id,code,display,
            })
            if (!res || res?.error || res?.status !== 200 || res?.data?.errors) {
                console.error(
                    "Failed to update sexual orientation ",
                    res?.data?.errors[0]?.message,
                    res?.error,
                    res?.status,
                )
            }

            payload =
                res.data &&
                res.data?.data?.updateSelectedSexualOrientation &&
                res.data?.data?.updateSelectedSexualOrientation?.success
                    ? "SUCCESS"
                    : "MISSING_STATUS"
            return Promise.resolve(payload)
        } catch (error) {
            console.error("Failed to update gender identity ", error)
        }
    }
)

