/**
 * Shimmer
 * -----------------------------------------------------------------------------
 */
import React from "react"
import Features from "@/utils/features"
import { useFeatures, useSelector } from "store"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props extends React.HTMLAttributes<HTMLDivElement> {
    readonly placeholder: string
    readonly value?: string
    readonly isLoading?: boolean
    readonly children?: React.ReactNode
    readonly className?: string
}

const bshIdentifiers = ["DATE", "CLAIM NUMBER", "YOU MAY OWE", "LAST FILLED", "DAY SUPPLY", "PHARMACY"]

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Shimmer: React.FC<Props> = props => {

    /**
     * Template
     */
    const app = useSelector(state => state.app)
    const brand = app.brand
    const isBSH = brand === "bridgespan"
    const stringArray = props.value?.split(":")
    const isClaim = stringArray?.[0] === "Date of service" || stringArray?.[0] === "You may owe"
    const containsBSHValue = bshIdentifiers.includes(stringArray?.[0])
    const classNameHead = isBSH ? "font-['Roboto_Slab'] text-muted colors-gray-200" : "text-muted"
    const classNameTail = isBSH ? "font-['Roboto_Slab'] font-semibold colors-gray-300" : "font-semibold"
    const claimAndBSH = isClaim && containsBSHValue

    if (props.value && !props.isLoading) {
        return (
            claimAndBSH ?
                // handle specific styles for claim cards
                <div>
                    <span className={classNameHead}>{stringArray[0] + ":"}</span>
                    <span className={classNameTail}>{stringArray[1]}</span>
                </div>
                :
                <span>{props.value}</span>
        )
    }
    return (
        <div className={`inline-block relative ${props.className}`}>
            <div className="absolute top-none w-full h-full shimmer rounded-circle" />
            <span className="opacity-0">{props.placeholder}</span>
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Shimmer
