/**
 * Programs Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions } from "store"
import { http } from "utils"

/**
 * Get programs data
 * -----------------------------------------------------------------------------
 */
export const fetchPrograms = createAsyncThunk(
    "programs/fetchPrograms", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.programsStatus("pending"))

        /**
         * Fetch programs
         */
        const res = await http.get("/api/programs")

        /**
         * Handle request errors
         */
        if (res.error) {
            return dispatch(actions.programsError({ response: res }))
        }

        /**
         * Define program data
         */
        const items = res.data?.programs || []
        const sorting = res.data?.sorting || {}

        /**
         * Update state
         */
        dispatch(actions.receivePrograms({ items, sorting }))
    },
)
