import * as React from "react"
import { SVGProps } from "react"
const SvgNoteSm = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Note" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M13.65 17.5H7.75714C7.05 17.5 6.5 16.95 6.5 16.2429V7.75714C6.5 7.05 7.05 6.5 7.75714 6.5H16.1643C16.95 6.5 17.5 7.05 17.5 7.75714V13.5714M13.65 17.5L17.5 13.5714M13.65 17.5V13.5714H17.5M6.5 9.72143H17.5"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgNoteSm
