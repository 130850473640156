/* eslint-disable no-console */
/**
 * Member Cards Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions } from "store"
import http from "utils/http"
import { fetchableIdCardsQuery } from "./queries"

/**
 * Get member cards data
 * -----------------------------------------------------------------------------
 */
export const fetchMemberCards = createAsyncThunk(
    "memberCards/fetchMemberCards", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.memberCardsStatus("pending"))

        /**
         * Request member cards data
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            fetchableIdCardsQuery,
        )

        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.memberCardsError({
                    response: res,
                }),
            )
        }

        const cards = res.data?.data?.fetchableIdCards
        /**
         * Update state
         */
        dispatch(
            actions.receiveMemberCards({
                memberCards: cards || [],
            }),
        )
    },
)
