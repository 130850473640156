/**
 * Timeline Actions
 * -----------------------------------------------------------------------------
 */

import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import http from "utils/http"
import { pharmaciesQuery } from "./queries"

export const fetchPharmacies = createAsyncThunk(
    "pharmacies", // Reducer name


    async (params: string, { dispatch }) => {
        const zipCode = params
        const { account } = store.getState()
        const firstName = account.profile.firstName
        const lastName = account.profile.lastName
        const memberId = account.profile.subscriberId
        const dateOfBirth = account.profile.birthDate

        /**
         * Set loading state
         */
        dispatch(actions.updatePharmacies({ isItemLoading: true }))

        const query = pharmaciesQuery(
            firstName,
            lastName,
            memberId,
            dateOfBirth,
            zipCode,
        )

        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            query,
        )

        /**
         * Handle request errors
         */
        if (res.error) {
            console.error(res.error)
            return dispatch(
                actions.updatePharmacies({
                    isItemLoading: false,
                    isItemInitialized: true,
                    errorMessage: "Could not retrieve coverage data",
                }),
            )
        }
        const items = res.data.data?.rxPharmacies

        /**
         * Update items state
         */
        dispatch(
            actions.updatePharmacies({
                items: items,
                isItemLoading: false,
                isItemInitialized: true,
            }),
        )
    },
)
