/**
 * GraphQL query to fetch eob list
 */
export const fetchDocumentQuery = (toDate: string, fromDate: string) => `
query {
    fetchableEobDocuments(toDate:"${toDate}" fromDate:"${fromDate}") {
    eobs{
      id
      name
      date
      desc
      url
    }
    partialContent
}
} 
`
