/**
 * Modal
 * -----------------------------------------------------------------------------
 */
import React from "react"
import ReactDOM from "react-dom"
import { createFocusTrap, FocusTrap } from "focus-trap"
import { Card } from "elements"
import { Item } from "elements/action_menu"
import { useEscapeModal } from "utils/window"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly name: string
    readonly children: React.ReactNode
    readonly isVisible: boolean
    readonly title?: string
    readonly titleClass?: string
    readonly onDismiss?: VoidFunction
    readonly size?: "small" | "medium" | "large" | "xsmall"
    readonly position?: "center" | "topRight"
    readonly menuItems?: ReadonlyArray<Item>
    readonly modalButton?: Item
    readonly className?: string
    readonly stopPropagation?: boolean
    readonly doNotDismissOnBodyClick?: boolean
    readonly noBorder?: boolean
    readonly noDismiss?: boolean
    readonly padding?: string
    readonly noOverlay?: boolean
    readonly customWidthSize?: string
    readonly disableCardPadding?: boolean
    readonly cardTitleTextSize?: string
    readonly verticalCardPadding?: string
    readonly overflowYScroll?: boolean
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Modal = (props: Props) => {
    /**
     * Hooks
     */
    const escapeModal = useEscapeModal("popup", props.onDismiss)

    /**
     * State
     */
    const [focusTrap, setFocusTrap] = React.useState<FocusTrap>()

    /**
     * Define template variables
     */
    const root = document.getElementById("root-popup")
    const mask = "#" + props.name + "-mask"

    const doStopPropagation = props.stopPropagation === true

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        // Setup a "Focus Trap" – prevent tabbing out of modal
        // https://github.com/focus-trap/focus-trap
        if (props.isVisible && root) {
            setFocusTrap(() => {
                const trap = createFocusTrap(mask, { initialFocus: mask })
                try {
                    trap.activate()
                } catch (e) {
                    console.error("Modal requires at least one button", e)
                }
                return trap
            })
        } else {
            if (focusTrap) focusTrap.deactivate()
        }

        // Listen for `escape` to dismiss
        if (props.isVisible && props.onDismiss) escapeModal.addListener()
        return () => escapeModal.removeListener()
    }, [props.isVisible, root]) // eslint-disable-line

    // Remove focus trap if user uses the back button while modal is open
    React.useEffect(() => {
        if (props.isVisible && focusTrap.active) {
            window.addEventListener("popstate", () => {
                if (focusTrap) focusTrap.deactivate()
                // eslint-disable-next-line functional/immutable-data
                document.body.style.overflow = ""
            })
        }
    }, [focusTrap])

    /**
     * Styles
     */
    const sizes = {
        xsmall: "w-[240px]",
        small: "w-[320px]",
        medium: "w-[500px]",
        large: "w-[830px]",
    }
    const base = `shadow-lg flex flex-col min-h-none max-h-full ${props.className}`
    const size = props.customWidthSize || sizes[props.size || "small"]

    const positions = {
        center: "flex items-center justify-center ",
        topRight: "flex items-start justify-end ",
    }

    const positionAdjustment = positions[props.position || "center"]
    const padding = props.padding ? props.padding : "py-sm px-sm"
    const noOverlay = props.noOverlay ? "" : "sm:bg-black sm:bg-opacity-50"

    /**
     * Template
     */
    const Template = (
        <div
            className={`fixed z-popup top-none left-none w-full h-full ${noOverlay} ${positionAdjustment} ${padding}`}
            onClick={
                !props.doNotDismissOnBodyClick
                    ? props.onDismiss
                    : () => {
                        return
                    }
            }
            id={props.name + "-mask"}
        >
            <Card
                name={props.name}
                id={props.name}
                className={`${base} ${size} overflow-y-auto`}
                title={props.title}
                titleClass={props.titleClass}
                stopPropagation={doStopPropagation}
                onDismiss={props.onDismiss}
                menuItems={props.menuItems}
                modalButton={props.modalButton}
                noBorder={props.noBorder}
                noDismiss={props.noDismiss}
                disablePadding={props.disableCardPadding}
                titleTextSize={props.cardTitleTextSize}
                verticalPadding={props.verticalCardPadding}
                overflowYScroll={props.overflowYScroll}
            >
                {props.children}
            </Card>
        </div>
    )
    return root
        ? ReactDOM.createPortal(props.isVisible ? Template : null, root)
        : null
}

/**
 * Subcomponent: Header
 * -----------------------------------------------------------------------------
 */
export const Header = (props: {
    readonly children: React.ReactNode
    readonly className?: string
}) => {
    return <div className={props.className || ""}>{props.children}</div>
}

/**
 * Subcomponent: Content
 * -----------------------------------------------------------------------------
 */
export const Content = (props: {
    readonly children: React.ReactNode
    readonly className?: string
}) => {
    const base = "flex-1 -mx-xs px-xs"
    const className = `${base} ${props.className || ""}`
    return <div className={className}>{props.children}</div>
}

/**
 * Subcomponent: Footer
 * -----------------------------------------------------------------------------
 */
export const Footer = (props: {
    readonly children: React.ReactNode
    readonly className?: string
}) => {
    return <div className={props.className || ""}>{props.children}</div>
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
Modal.Header = Header // eslint-disable-line
Modal.Content = Content // eslint-disable-line
Modal.Footer = Footer // eslint-disable-line
export default Modal
