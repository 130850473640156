/* eslint-disable no-console */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions } from "store"
import http from "utils/http"
import { fetchClaimServiceTypeQuery } from "./queries"

/**
 * Get claim service data
 * -----------------------------------------------------------------------------
 */
export const fetchClaimService = createAsyncThunk(
    "claimService/fetchClaimService", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.claimServiceStatus("pending"))
        /**
         * Request claim services data
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            fetchClaimServiceTypeQuery,
        )

        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.claimServiceError({
                    response: res,
                }),
            )
        }
        
        const claimServices = res.data?.data?.claimSubmission?.claimServiceTypes
        
        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimService({
                claimServiceType: claimServices || [],
            }),
        )
    },
)
