import * as React from "react"
import { SVGProps } from "react"
const SvgTree = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Tree" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M9 22.0002C8.44772 22.0002 8 22.4479 8 23.0002C8 23.5525 8.44772 24.0002 9 24.0002V22.0002ZM15 24.0002C15.5523 24.0002 16 23.5525 16 23.0002C16 22.4479 15.5523 22.0002 15 22.0002V24.0002ZM12 14.0002L11.4649 14.845L12 15.1839L12.5351 14.845L12 14.0002ZM14 4.00018C14 5.10475 13.1046 6.00018 12 6.00018V8.00018C14.2091 8.00018 16 6.20932 16 4.00018H14ZM12 6.00018C10.8954 6.00018 10 5.10475 10 4.00018H8C8 6.20932 9.79086 8.00018 12 8.00018V6.00018ZM10 4.00018C10 2.89561 10.8954 2.00018 12 2.00018V0.000183105C9.79086 0.000183105 8 1.79104 8 4.00018H10ZM12 2.00018C13.1046 2.00018 14 2.89561 14 4.00018H16C16 1.79104 14.2091 0.000183105 12 0.000183105V2.00018ZM22 9.00018C22 10.1048 21.1046 11.0002 20 11.0002V13.0002C22.2091 13.0002 24 11.2093 24 9.00018H22ZM20 11.0002C18.8954 11.0002 18 10.1048 18 9.00018H16C16 11.2093 17.7909 13.0002 20 13.0002V11.0002ZM18 9.00018C18 7.89561 18.8954 7.00018 20 7.00018V5.00018C17.7909 5.00018 16 6.79104 16 9.00018H18ZM20 7.00018C21.1046 7.00018 22 7.89561 22 9.00018H24C24 6.79104 22.2091 5.00018 20 5.00018V7.00018ZM9 24.0002H15V22.0002H9V24.0002ZM13 23.0002V7.00018H11V23.0002H13ZM17.4649 9.35536L11.4649 13.1554L12.5351 14.845L18.5351 11.045L17.4649 9.35536ZM6 9.00018C6 10.1048 5.10457 11.0002 4 11.0002V13.0002C6.20914 13.0002 8 11.2093 8 9.00018H6ZM4 11.0002C2.89543 11.0002 2 10.1048 2 9.00018H0C0 11.2093 1.79086 13.0002 4 13.0002V11.0002ZM2 9.00018C2 7.89561 2.89543 7.00018 4 7.00018V5.00018C1.79086 5.00018 0 6.79104 0 9.00018H2ZM4 7.00018C5.10457 7.00018 6 7.89561 6 9.00018H8C8 6.79104 6.20914 5.00018 4 5.00018V7.00018ZM5.46495 11.045L11.4649 14.845L12.5351 13.1554L6.53505 9.35536L5.46495 11.045Z"
            fill="currentColor"
        />
    </svg>
)
export default SvgTree
