import * as React from "react"
import { SVGProps } from "react"
const SvgWarning = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Warning sign"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12.0011 8.85732C12.0011 10.4882 12.0066 12.1193 12.0006 13.7502M10.2648 3.56505L1.3526 18.3488C1.21032 18.5816 1.11634 18.8407 1.07626 19.1106C1.03618 19.3805 1.05082 19.6558 1.1193 19.9199C1.18778 20.184 1.3087 20.4317 1.47487 20.6481C1.64104 20.8646 1.84906 21.0453 2.08655 21.1797C2.39409 21.3926 2.76111 21.5027 3.13504 21.4943H20.8546C21.4107 21.4943 21.9441 21.2733 22.3374 20.8801C22.7306 20.4868 22.9516 19.9534 22.9516 19.3973C22.945 19.0255 22.8362 18.6628 22.637 18.3488L13.8297 3.56505C13.6997 3.32311 13.5211 3.11065 13.3052 2.94102C13.0892 2.77138 12.8405 2.64824 12.5746 2.57932C12.3088 2.5104 12.0316 2.49719 11.7604 2.54051C11.4892 2.58384 11.2299 2.68278 10.9987 2.8311C10.7045 3.02061 10.4543 3.27086 10.2648 3.56505Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.9976 16.7962C11.9271 16.7962 11.87 16.7389 11.87 16.6682C11.87 16.5975 11.9271 16.5402 11.9976 16.5402"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M11.9975 16.5402C12.068 16.5402 12.1251 16.5975 12.1251 16.6682C12.1251 16.7389 12.068 16.7962 11.9975 16.7962"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgWarning
