import * as React from "react"
import { SVGProps } from "react"
const SvgFolder = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Folder" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M21 22H3C1.9 22 1 21.1 1 20V4C1 2.9 1.9 2 3 2H8.46C8.8 2 9.11 2.17 9.3 2.45L10.7 4.55C10.89 4.83 11.2 5 11.54 5H21C22.1 5 23 5.9 23 7V20C23 21.1 22.1 22 21 22Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
        />
    </svg>
)
export default SvgFolder
