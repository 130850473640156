/**
 * Tabs
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Icon, IconTypes } from "elements"
import { tagEvent } from "utils/analytics"

/**
 * Props
 * -----------------------------------------------------------------------------
 */
export interface Item {
    readonly name: string
    readonly label?: string
    readonly value: string
    readonly onClick: VoidFunction
    readonly icon?: IconTypes
    readonly disabled?: boolean
    readonly hidden?: boolean
    readonly node?: React.ReactNode
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Unique name for tests/analytics
     */
    readonly name: string

    /**
     * Tab items
     */
    readonly items: ReadonlyArray<Item>
    readonly selected: string

    /**
     * Variants
     */
    readonly variant?: "default" | "binder"
    readonly fullWidth?: boolean
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Tabs: React.FC<Props> = props => {
    /**
     * Refs
     */
    const ref = React.useRef<HTMLDivElement>(null)

    /**
     * Filter out tab items marked as hidden
     */
    const items = props.items.filter(item => !item.hidden)

    /**
     * Methods
     */
    const onClick = (
        item: Item,
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        if (item.disabled) {
            return false
        }

        // Analytic event
        tagEvent({
            tealium_event: "link",
            data_analytics_id: props.name + "-" + item.name,
            link_text: item.label || item.icon || "",
            link_url: "",
        })

        // Handle click
        item.onClick()

        // Scroll tab into view
        // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
        // TODO: Only scroll horizontal, not vertical
        // event.currentTarget.scrollIntoView({
        //     behavior: "smooth",
        //     inline: "center",
        // })
    }

    /**
     * Define template variables
     */
    const isBinder = props.variant === "binder"

    /**
     * Styles
     */
    const buttonStyle = (item: Item) => {
        const isActive = props.selected === item.value
        const isDisabled = item.disabled
        // Binder tab style
        if (isBinder) {
            return `focus-inset font-medium text-muted dark:text-gray-100 hover:text-primary dark:hover:text-accent bg-light-50 dark:bg-dark-100 border-t-xl h-[68px] px-sm ${
                isActive
                    ? "bg-opacity-0 dark:bg-opacity-0 text-primary dark:text-accent-200 border-primary-vivid dark:border-accent shadow-md z-header"
                    : "text-gray-400 dark:text-gray-200 border-transparent"
            } ${isDisabled ? "opacity-50 cursor-default" : ""} ${
                props.fullWidth ? "flex-1" : ""
            }`
        }
        // Default tab style
        return `focus-inset text-lg font-normal hover:text-primary dark:hover:text-accent border-b-xl h-xl md:h-xxl px-xxs md:px-xs ${
            isActive
                ? "text-primary dark:text-accent border-primary-vivid dark:border-accent"
                : "text-muted dark:text-gray-100 border-transparent"
        } ${isDisabled ? "opacity-50 cursor-default" : ""} ${
            props.fullWidth ? "flex-1" : ""
        }`
    }

    /**
     * Template
     */
    return (
        <div
            data-test={props.name}
            ref={ref}
            className={`flex whitespace-nowrap overflow-x-auto hide-scrollbar ${props.className}`}
        >
            {items.map((item, index) => {
                return (
                    <button
                        data-test={item.name}
                        type="button"
                        key={index}
                        onClick={e => onClick(item, e)}
                        className={buttonStyle(item)}
                        disabled={item.disabled}
                    >
                        {item.icon && (
                            <Icon
                                type={item.icon}
                                size={isBinder ? 24 : 20}
                                className="mr-xxs"
                            />
                        )}
                        {item.label}
                        {item.node}
                    </button>
                )
            })}
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Tabs
