/**
 * GraphQL query to validate the date
 */
export const fetchDateValidationQuery = (date: string) =>`
query{
  claimSubmission{
    isValidClaimSubmissionData (inputFieldName:"SERVICE_DATE", inputFieldValue:"${date}"){
      isInputValid
      validationStatusMessage
    }
  }
}`


