/**
 * Timeline Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import { timelineQuery } from "./queries"
import { transformTimelineAPIData } from "./utils"
import http from "utils/http"

/**
 * Get timeline data
 * -----------------------------------------------------------------------------
 */
export const fetchTimeline = createAsyncThunk(
    "timeline/fetchTimeline", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        const { timeline } = store.getState()
        dispatch(actions.receiveTimeline({ ...timeline, isLoading: true }))

        /**
         * Request timeline data from digital-first-information-service
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            timelineQuery,
        )

        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            console.error("[Timeline] Error fetching information.", (res.error || JSON.stringify(res.data?.errors[0]?.message)))
            dispatch(
                actions.receiveTimeline({
                    ...timeline,
                    isLoading: false,
                    isInitialized: true,
                    errorMessage: "Could not retrieve timeline data",
                }),
            )
            return
        }

        /**
         * Transform timeline data
         */
        const careSubjects = res.data.data?.careSubjects || {}
        const items = transformTimelineAPIData(careSubjects)
        const viewableMembers = res.data?.data?.careSubjects?.familyMembers 

        /**
         * Update state
         */
        dispatch(
            actions.receiveTimeline({
                ...timeline,
                items,
                isLoading: false,
                isInitialized: true,
                family: {
                    items: viewableMembers  || [],
                },
            }),
        )
    },
)
