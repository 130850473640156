/**
 * Claim services reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"
import {
    ClaimsReimbursementDetails,
    EFTDetails,
    ClaimsReimbursementOptionConfig,
    REIMBURSEMENT_OPTIONS,
} from "./types"
/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface ClaimReimbursementInfo {
    readonly details: {
        readonly eftPermission: boolean
        readonly reimbursementDetails: ClaimsReimbursementDetails
    }
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<ClaimReimbursementInfo> = {
    status: "pristine",
    details: {
        eftPermission: false,
        reimbursementDetails: {
            eft: undefined,
            options: {
                isAllowedToChange: undefined,
                isAllowedToViewAddress: undefined,
                optionTypes: [],
                availableOptions: [],
                defaultSelectedOption: undefined,
                hasEFTDetails: undefined,
            },
        },
    },
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const claimReimbursementDetailsReducer = createStatefulSlice({
    name: "claimReimbursementDetails",
    initialState,
    reducers: {
        receiveClaimsReimbursementOptions: (
            state: SliceState<ClaimReimbursementInfo>,
            action: PayloadAction<ClaimsReimbursementOptionConfig>,
        ) => {
            return {
                ...state,
                status: "completed",
                details: {
                    ...state.details,
                    reimbursementDetails: {
                        ...state.details.reimbursementDetails,
                        options: action.payload,
                    },
                },
            }
        },
        receiveClaimsReimbursementDetails: (
            state: SliceState<ClaimReimbursementInfo>,
            action: PayloadAction<ClaimReimbursementInfo>,
        ) => {
            return {
                ...state,
                status: "completed",
                details: {
                    ...action.payload.details,
                    reimbursementDetails: {
                        ...action.payload.details.reimbursementDetails,
                        options: state.details.reimbursementDetails.options,
                    },
                },
            }
        },
        resetReimbursementDetails: () => {
            return initialState
        },
        modifyEFTDetailsSuccess: (
            state: SliceState<ClaimReimbursementInfo>,
            action: PayloadAction<EFTDetails>,
        ) => {
            const reimbursementDetails = {
                eft: action.payload ? { ...action.payload } : null,
                options: {
                    ...state.details.reimbursementDetails.options,
                    ...(!action.payload
                        ? {
                              defaultSelectedOption:
                                  REIMBURSEMENT_OPTIONS.MANUAL_CHECK,
                              hasEFTDetails: false,
                          }
                        : {
                              defaultSelectedOption: REIMBURSEMENT_OPTIONS.EFT,
                              hasEFTDetails: true,
                          }),
                },
            }
            return {
                status: "completed",
                details: {
                    eftPermission: state.details.eftPermission,
                    reimbursementDetails: {
                        ...reimbursementDetails,
                        options: {
                            ...reimbursementDetails.options,
                        },
                    },
                },
            }
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const claimReimbursementDetailsActions = {
    ...claimReimbursementDetailsReducer.actions,
    ...actions,
}

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default claimReimbursementDetailsReducer.reducer
