export const fetchConsumerSettingsQuery = 
    `{
      myAccount {
        preferences {
          consumerSettings {
            marketing {
              programId
              permissions
              setting
            }
            wellness {
              programId
              permissions
              setting
            }
            sso {
              programId
              permissions
              setting
            }
          }
        }
      }
    }`

export const fetchCommunicationPreferencesQuery  = 
    `{
      myAccount {
        preferences {
          communicationPreferences {
            claimsAlert {
                sms {
                    programId
                    permissions
                    setting
                }
            }
            newsUpdates {
                email {
                    programId
                    permissions
                    setting
                }
                sms {
                    programId
                    permissions
                    setting
                }
            }
            feedback {
                email {
                    programId
                    permissions
                    setting
                }
                sms {
                    programId
                    permissions
                    setting
              }
            }
          }
        }
      }
    }`

    export const updatePreferencesQuery  = `mutation ($code: String!, $value: Boolean!){
      updatePreference(code: $code, value: $value) {
      success
      }
    }`
  
  

export const paperlessPreferenceSaveQuery =
  `mutation($code: String!, $value: Boolean!, $confirmationText: String) {
    updatePreference (
    code: $code,
    value: $value,
    confirmationText: $confirmationText
    ) {
      success
    }
  }`

export const usernameUpdateQuery =
`mutation ($newUsername: String!) {
  updateUsername(newUsername: $newUsername) {
    success
  }
}`

export const medicalPaperlessSaveQuery =
  `mutation {
    setPreference(preference: EOB, setting: PAPERLESS) {
      status
      preference {
        permission
        setting
      }
    }
  }`

export const medicalPaperSaveQuery =
  `mutation {
    setPreference(preference: EOB, setting: PAPER) {
      status
      preference {
        permission
        setting
      }
    }
  }`

export const pharmacyPaperlessSaveQuery =
  `mutation {
    setPreference(preference: EOB_PRIME, setting: PAPERLESS) {
      status
      preference {
        permission
        setting
      }
    }
  }`

export const pharmacyPaperSaveQuery =
  `mutation {
    setPreference(preference: EOB_PRIME, setting: PAPER) {
      status
      preference {
        permission
        setting
      }
    }
  }`

export const updateMemberPasswordQuery =
  `mutation ($currentPassword: String!, $newPassword: String!) {
    updatePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      success
    }
  }`

export const validateMemberPasswordQuery = 
  `mutation ($password: String!) {
    riskAnalysis(password: $password) {
      strength
      __typename
      risks {
        code
        __typename
      }
    }
  }`

export const saveEmailQuery = `mutation ($email: String!) {
  updateEmail(email: $email) {
    success
    reason
  }
}`

export const saveEmailLinkQuery =
  `mutation($email: String!, $sendActivationLink: Boolean!, $validatedEmail: String!, $isDFW: Boolean!) {
    emailInformationUpdate (
    email: $email,
    isDFW: $isDFW,
    sendActivationLink: $sendActivationLink,
    validatedEmail: $validatedEmail) {
      email
      sendActivationLink
      validatedEmail
    }
}`

export const cancelEmailVerificationQuery = `mutation {
  cancelEmailVerification {
    success
  }
}`

export const hsaSaveQuery =
    `mutation ($value: Boolean!) {
      preference_consumer_setting(
        setting_name: hsa_account_balance,
        value: $value) {
            setting
        }
    }`

export const claimsSaveQuery =
    `mutation ($value: heq_preference) {
      heqPreference(preference: $value) {
        value
      }
    }`

export const hsaPreferecesSaveQuery =
  `mutation($code: String!, $value: Boolean!, $confirmationText: String) {
    updatePreference (
    code: $code,
    value: $value,
    confirmationText: $confirmationText
    ) {
      success
    }
  }`

export const updatePreferenceQuery = `mutation ($code: String!, $value: Boolean!, $confirmationText: String){
  updatePreference(code: $code, value: $value, confirmationText: $confirmationText) {
      success
      }
    }
    `

export const medicareChangeAddress =
    `mutation ($addressType: String!, $addressLine1: String!, $addressLine2: String, $city: String!, $state: String!, $zip: String!, $temporaryMove: Boolean!, $moveDate: String!, $additionalAddressType1: String, $additionalAddressType2: String) {
      updateAddress(
        addressType: $addressType
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        city: $city
        state: $state
        zip: $zip
        temporaryMove: $temporaryMove
        moveDate: $moveDate
        additionalAddressType1: $additionalAddressType1
        additionalAddressType2: $additionalAddressType2
      ) {
        success
      }
    }`

export const updateMailingAddressQuery =
    `mutation ($addressLine1: String!, $addressLine2: String, $city: String!, $state: String!, $zip: String!) {
      updateAddress(
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        city: $city
        state: $state
        zip: $zip
      ) {
        success
      }
    }`

export const getFamilyInfoQuery =
    `{
      myAccount {
        familySharing {
            show
            familyShareInfo {
             authorizedDate
             authorizedTime
             authorized
             firstName
             lastName
             expiryDate
             age
             suffix
          }
        }
      }
    }`

export const createSMSQuery = `mutation($number: String!) {
    updateSms (number: $number) {
      success
      reason
    }
  }`

export const activateSMSQuery = `mutation ($code: String!) {
        activateSms (code: $code) {
          success
          reason
        }
  }`

export const cancelSMSActivationQuery = `mutation {
        cancelSmsActivation {
          success
        }
    }`

export const removeSMSQuery = `mutation {
        deleteSms {
          success
        }
}`

export const updateShareInfoQuery =
    `mutation ($authorized: Boolean!, $suffix: String!) {
      updateFamilySharing(
        authorized: $authorized
        suffix: $suffix
      ) {
        success
      }
    }`

export const cpsSaveQuery =
    `mutation ($code: String!, $value: Boolean!, $confirmationText: String) {
      updatePreference(
        code: $code
        value: $value
        confirmationText: $confirmationText
      ) {
        success
      }
    }`
