/**
 * Popup Modal
 * -----------------------------------------------------------------------------
 */
import React from "react"
import ReactDOM from "react-dom"
import { createFocusTrap, FocusTrap } from "focus-trap"
import { Card, Button } from "elements"
import { useEscapeModal } from "utils/window"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly name: string
    readonly children: React.ReactNode
    readonly isVisible: boolean
    readonly onDismiss?: (e?: React.MouseEvent) => void
    readonly asOverlay?: boolean
    readonly width?: number
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Popup: React.FC<Props> = props => {
    /**
     * Hooks
     */
    const escapeModal = useEscapeModal("popup", props.onDismiss)

    /**
     * State
     */
    const [root, setRoot] = React.useState<HTMLElement>()
    const [focusTrap, setFocusTrap] = React.useState<FocusTrap>()

    /**
     * Methods
     */
    const onDismiss = (e?: React.MouseEvent): void => {
        if (props.onDismiss) {
            props.onDismiss(e)
        }
        // TODO: event analytics
    }

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        // Setup a "Focus Trap" – prevent tabbing out of modal
        if (props.isVisible) {
            // focus trap in lifecycle is slightly faster than render
            const trapDelay = 100
            setTimeout(() => {
                setFocusTrap(() => {
                    const trap = createFocusTrap(`#popup-${props.name}`)
                    if (document.querySelector(`#popup-${props.name}`)) {
                        trap.activate()
                        return trap
                    }
                })
            }, trapDelay)
        } else {
            if (focusTrap) focusTrap.deactivate()
        }

        // Listen for `escape` to dismiss
        if (props.isVisible && props.onDismiss) {
            escapeModal.addListener()
        }
        return () => {
            escapeModal.removeListener()
        }
    }, [props.isVisible]) // eslint-disable-line

    // Remove focus trap if user uses the back button while popup is open
    React.useEffect(() => {
        if (props.isVisible && focusTrap?.active) {
            window.addEventListener("popstate", () => {
                if (focusTrap) focusTrap.deactivate()
            })
        }
    }, [focusTrap])

    /**
     * Define portal DOM root
     */
    React.useEffect(() => {
        setRoot(document.getElementById("root-popup"))
    }, [])

    /**
     * Template
     */
    const template = props.isVisible ? (
        <div
            className={`fixed ${
                props.asOverlay ? "z-overlay" : "z-popup"
            } min-h-screen w-screen overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center`}
            onClick={onDismiss}
            id={`popup-${props.name}`}
            role="dialog"
            aria-modal={true}
        >
            <Card
                name={props.name}
                className={
                    `relative mx-sm max-w-[` +
                    (props.width ? props.width : "320") +
                    "px" +
                    `] ${props.onDismiss ? "pt-lg" : ""}`
                }
                stopPropagation={true}
            >
                {props.onDismiss && (
                    <Button
                        name="dismiss-button"
                        icon="x"
                        variant="link"
                        size="small"
                        className="absolute top-xxs right-xxs"
                        onClick={onDismiss}
                    />
                )}
                {props.children}
            </Card>
        </div>
    ) : null
    return root ? ReactDOM.createPortal(template, root) : null
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Popup
