import http from "utils/http"
import { getBrand, getEnvironment } from "utils/env"
import { getLocalStorageItem, setLocalStorageItem } from "utils/window-storage"
import { formatCase } from "utils/string"
import {
    getCampaignId,
    getConditionId,
    getConditionMedicationId,
    getMedicationId,
    getPharmacyId,
    getTreatmentId,
} from "./rx/utilities"
import { InsightProps } from "@/components/props/insight_props"
import Features from "@/utils/features"
import { store } from "@/store"

enum State {
    AL = "Alabama",
    AK = "Alaska",
    AZ = "Arizona",
    AR = "Arkansas",
    CA = "California",
    CO = "Colorado",
    CT = "Connecticut",
    DE = "Delaware",
    FL = "Florida",
    GA = "Georgia",
    HI = "Hawaii",
    ID = "Idaho",
    IL = "Illinois",
    IN = "Indiana",
    IA = "Iowa",
    KS = "Kansas",
    KY = "Kentucky",
    LA = "Louisiana",
    ME = "Maine",
    MD = "Maryland",
    MA = "Massachusetts",
    MI = "Michigan",
    MN = "Minnesota",
    MS = "Mississippi",
    MO = "Missouri",
    MT = "Montana",
    NE = "Nebraska",
    NV = "Nevada",
    NH = "New Hampshire",
    NJ = "New Jersey",
    NM = "New Mexico",
    NY = "New York",
    NC = "North Carolina",
    ND = "North Dakota",
    OH = "Ohio",
    OK = "Oklahoma",
    OR = "Oregon",
    PW = "Palau",
    PA = "Pennsylvania",
    PR = "Puerto Rico",
    RI = "Rhode Island",
    SC = "South Carolina",
    SD = "South Dakota",
    TN = "Tennessee",
    TX = "Texas",
    UT = "Utah",
    VT = "Vermont",
    VA = "Virginia",
    WA = "Washington",
    EW = "Washington", // Selling affiliate -> state conversion
    WW = "Washington", // Selling affiliate -> state conversion
    WV = "West Virginia",
    WI = "Wisconsin",
    WY = "Wyoming",
}

const brandCodeToState = {
    BCBSO: ["Oregon", "or"],
    BSW: ["Washington", "ww"],
    BSI: ["Idaho", "id"],
    BCBSU: ["Utah", "ut"],
    ASUR: ["Washington", "ew"],
    BSH: ["Washington", "ww"],
}

/**
 * Mapping of digital first and tealium environments
 */
const tealiumEnv = {
    dev: "dev",
    uat: "qa",
    prd: "prod",
}

const tealiumDataQuery = `
query {
    user {
        context { audience location { state region } }
    }
    member {
        firstName lastName personId coverageLobId
        enrollment { subscriberId }
        coverages { brandCode brandFamily groupId groupName }
    }
}`

const dfisTealiumDataQuery = `
query ($partner: String!) {
    primaryPolicy {
      subscriberId
      requesterMembership {
        firstName
        lastName
        primaryCoverage {
          brandFamily
          groupId
          groupName
          primaryEligibility{
            brandCode
            sellingAffiliate
            lineOfBusiness
          }
        }
      }
    }
    externalId(partner: $partner) {
      requester
    }
  }
  `

export const getAudience = (path: string): string => {
    /** This is adif logic, may not be relevant here */
    if (path.match(/\/member\b/)) {
        return "individual"
    } else if (path.match(/\/medicare\b/)) {
        return "medicare"
    } else if (path.match(/\/provider\b/)) {
        return "provider"
    } else if (path.match(/\/producer\b/)) {
        return "producer"
    } else if (path.match(/\/employer\b/)) {
        return "employer"
    }
    return "common"
}

export const getPageUrl = (pathname: string): string => {
    if (!pathname) {
        return "home"
    }
    const splitUrl = pathname.split("/")
    let pageUrl = "home" // eslint-disable-line

    if (splitUrl.length === 2) {
        if (splitUrl[1]) {
            pageUrl = splitUrl[1]
        }
    } else if (splitUrl.length === 3) {
        if (splitUrl[1] === "campaigns") {
            pageUrl = splitUrl[1]
        } else if (splitUrl[2].match(/\d/)) {
            pageUrl = splitUrl[1]
        } else {
            pageUrl = splitUrl[2]
        }
    } else if (splitUrl.length > 3) {
        pageUrl = splitUrl[2]
    }

    return pageUrl
}

export const getPageIdentifier = (pathname: string): string => {
    if (!pathname) {
        return ""
    }
    const splitUrl = pathname.split("/")
    let identifier = "" // eslint-disable-line

    if (splitUrl.length === 3) {
        if (splitUrl[1] === "campaigns") {
            identifier = splitUrl[2]
        } else if (splitUrl[2].match(/\d/)) {
            identifier = splitUrl[2]
        }
    } else if (splitUrl.length > 3) {
        identifier = splitUrl[3]
    }

    return identifier
}

export interface utagDataInterface {
    readonly pageUrl: string
    readonly pageName: string
    readonly pathname: string
    readonly audience: string
    readonly capitalizedBrand: string
    readonly locale: UserLocale
    readonly eventName: string
    readonly customerIsLoggedIn?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly member?: any
    readonly janusId?: string
    readonly region?: string
    readonly siteState?: string
}

export const getUtagData = (params: utagDataInterface) => {
    const {
        pageUrl,
        pageName,
        pathname,
        audience,
        capitalizedBrand,
        locale,
        eventName,
        customerIsLoggedIn,
        member,
        janusId,
        region,
        siteState,
    } = params

    return {
        campaign: getLocalStorageItem("CAMPAIGN_CODE") || "",
        customer_email: "",
        customer_first_name: member?.firstName ? member?.firstName : "",
        customer_id: "",
        customer_is_logged_in: customerIsLoggedIn ? customerIsLoggedIn : "0",
        customer_type: member ? "member" : "guest",
        page_friendly_url: pageUrl,
        page_name: pageName,
        portal_id: member?.personId ? member?.personId : "",
        janus_id: janusId ? janusId : "",
        line_of_business_id: member?.coverageLobId ? member?.coverageLobId : "",
        group_id: member?.groupId? member?.groupId: "",
        group_name: member?.groupName? member?.groupName : "",
        rx_campaign_id: getCampaignId(pathname),
        rx_condition_id: getConditionId(pathname),
        rx_conditionMedication_id: getConditionMedicationId(pathname),
        rx_medication_id: getMedicationId(pathname),
        rx_pharmacy_id: getPharmacyId(pathname),
        site_audience: audience,
        site_brand: capitalizedBrand,
        site_region: region ? region : "",
        site_state: siteState ? siteState : "",
        site_section: "Home",
        site_country_code: locale?.countryCode || "",
        site_display_format: "web",
        site_language_code: locale?.languageCode || "",
        tealium_event: eventName,
    }
}

export const initUtagData = () => {
    let utagData = {} // eslint-disable-line
    const pathname = document.location.pathname
    const pageUrl = getPageUrl(pathname)
    const brand = getBrand()
    const capitalizedBrand = formatCase(brand, "capitalize")
    let audience = getAudience(pathname) // eslint-disable-line
    let pageName = brand + ">>" + "public" + ">" + audience + ">" + pageUrl // eslint-disable-line
    const eventName = "Page load event"
    const locale = getUserLocale(navigator)
    utagData = getUtagData({
        pageUrl: pageUrl,
        pageName: pageName,
        pathname: pathname,
        audience: audience,
        capitalizedBrand: capitalizedBrand,
        locale: locale,
        eventName: eventName,
    })
    return utagData
}

export const getUtagDataJanus = (responseData, utagData, janusId: string) => {
    const member = responseData.member
    const user = responseData.user
    const brand = getBrand()
    const brandCode = member?.brandCode
    const stateArray = brandCodeToState[brandCode]
    const state = stateArray ? JSON.stringify(stateArray) : ""
    const region = user.context.location.region
        ? user.context.location.region
        : ""
    const pageName =
        brand +
        ">>" +
        "private" +
        ">" +
        utagData.audience +
        ">" +
        utagData.pageUrl

    const newUtagData = getUtagData({
        pageUrl: utagData.pageUrl,
        pageName: pageName,
        pathname: utagData.pathname,
        audience: utagData.audience,
        capitalizedBrand: utagData.capitalizedBrand,
        locale: utagData.locale,
        eventName: utagData.eventName,
        customerIsLoggedIn: "1",
        member: member,
        janusId: janusId,
        region: region,
        siteState: state,
    })
    return newUtagData
}

export const isValidJanusResponse = response => {
    return response &&
        response?.member && 
        response?.member?.firstName &&
        response?.member?.groupId &&
        response?.member?.groupName &&
        response?.member?.brandCode &&
        response?.user
        ? true
        : false
}

export const updateBaseAnalytics = utagData => {
    /**
     * In SPA, tealium script is loaded only once.
     * At this point app is trying to initialize tealium data again.
     * This happens when member sign-in or sign-out and we need to change the tealium data based on authenticaton state.
     *
     * There is no out of box way to reload tealium data once its loaded.
     * As part of loading, tealium initializes handler base variables values with data from utag_data object.
     * These base variables are logged with each utag.link event.
     * So this block updates those base variables.
     */

    if (
        typeof globalThis?.utag !== "undefined" &&
        globalThis?.utag !== null &&
        globalThis?.utag.handler &&
        globalThis?.utag.handler.base &&
        globalThis?.utag.handler.base != "*"
    ) {
        const baseVariables = globalThis?.utag.handler.base.split(",")
        //eslint-disable-next-line
        for (let index = 0; index < baseVariables.length; index++) {
            const baseVariable = baseVariables[index]
            if (typeof utagData[baseVariable] !== "undefined")
                //eslint-disable-next-line
                globalThis.utag.handler.df[baseVariable] =
                    utagData[baseVariable]
        }
        console.log("[Tealium] Re-initialized base variables") // eslint-disable-line
    }
}

export const insertTealiumScript = () => {
    console.log("[Tealium] start loading") // eslint-disable-line
    const env = getEnvironment()
    let js = document.getElementsByTagName("script")[0] // eslint-disable-line
    const fjs = document.getElementsByTagName("script")[0]
    const tealiumScriptTagId = "adif-utag-script"
    js = document.createElement("script")
    js.id = tealiumScriptTagId // eslint-disable-line
    js.type = "text/javascript" // eslint-disable-line
    js.async = true // eslint-disable-line
    // eslint-disable-next-line
    js.src =
        "//tags.tiqcdn.com/utag/cambia/main/" + tealiumEnv[env] + "/utag.js"
    fjs.parentNode.insertBefore(js, fjs)
}

/**
 * Function that initializes the global data needed for tealium.
 * Will be called:
 * - When app is loaded for first time
 * - When member signed in
 * - When member reloads a page
 * @param isAuthenticated Boolean indicating to init data with authenticated context or not
 */
export const initAnalyticsData = async (isAuthenticated: boolean) => {
    // eslint-disable-next-line functional/no-let
    let utagData = initUtagData()
    // eslint-disable-next-line functional/no-let
    let responseData = {}
    const dfisUrl = "/api/janus/digital-first-information-service/graphql"
    const { app } = store.getState()
    const {
        [Features.MPS_TEALIUM_DATA_QUERY_ENABLED]:  mpsTealiumDataQueryEnabled,
        [Features.AUDIENCE_MEDICARE]: isUserMedicare
    } = app.features

    /**
     * When authenticated, load the data needed for tealium
     * In the data migrated from MPS, coverage data will be retrieved from requesterMembership -> primaryCoverage -> primaryEligibility
     */
    if (isAuthenticated) {
        if ( mpsTealiumDataQueryEnabled ){

            // Retrieve tealium data from DFIS.
            const dfisResponse = await http.mutation(
                dfisUrl,
                dfisTealiumDataQuery,
                { partner: "tealium" }
            )
            const requester = dfisResponse?.data?.data?.primaryPolicy?.requesterMembership
            responseData = {
                user: {
                    context: {
                        audience: isUserMedicare ? "medicare" : "individual",
                        location: {
                            state: State[requester?.primaryCoverage?.primaryEligibility[0]?.sellingAffiliate],
                            // Region is not populated as it is needed as per below documentation:
                            // https://cambiahealth.atlassian.net/wiki/spaces/CCSE/pages/2175238145/Splitting+the+tealiumDataQuery
                        },
                    }
                },
                member: {
                    subscriberId: dfisResponse?.data?.data?.primaryPolicy?.subscriberId, //not used
                    firstName: requester?.firstName,
                    lastName: requester?.lastName,  //not used
                    brandFamily: requester?.primaryCoverage?.brandFamily, //not used
                    groupId: requester?.primaryCoverage?.groupId,
                    groupName: requester?.primaryCoverage?.groupName,
                    brandCode: requester?.primaryCoverage?.primaryEligibility[0]?.brandCode,
                    personId: dfisResponse?.data?.data?.externalId?.requester,
                    coverageLobId: requester?.primaryCoverage?.primaryEligibility[0]?.lineOfBusiness,
                }
            }
        } else {
            const response = await http.query(
                "/api/adif/user-model",
                tealiumDataQuery,
            )
            const member = response?.data?.data?.member
            const { enrollment, coverages, ...rest } = member
            responseData = {
                ...response.data?.data,
                member: {
                    subscriberId: enrollment?.subscriberId,
                    brandFamily: coverages[0]?.brandFamily, //not used
                    groupId: coverages[0]?.groupId,
                    groupName: coverages[0]?.groupName,
                    brandCode: coverages[0]?.brandCode,
                    ...rest,
                }
            }
        }
        const { data } = await http.get("/api/janus-wt")

        if (isValidJanusResponse(responseData)) {
            utagData = getUtagDataJanus(
                responseData,
                utagData,
                data?.id ? data.id : "",
            )

            // eslint-disable-next-line
            console.log("[Tealium] Data initialized for authenticated session")
        } else {
            // eslint-disable-next-line
            console.error(
                "[Tealium] Failed to initialize data for authenticated session",
            )
        }
    } else {
        // eslint-disable-next-line
        console.log("[Tealium] Data initialized for un-authenticated session")
    }

    // pretty sure this will never be false -- maybe remove this conditional. maybe newrelic errors related to it?
    if (typeof globalThis !== "undefined" && globalThis !== null) {
        /**
         * Only after utag_data is set, load tealium.
         */
        globalThis.utag_data = utagData // eslint-disable-line
        /**
         * Needed this noView setting so that tealium will not log view event when its loaded.
         */
        globalThis.utag_cfg_ovrd = globalThis?.utag_cfg_ovrd || {} // eslint-disable-line
        globalThis.utag_cfg_ovrd.noview = true // eslint-disable-line
        // disable browser console debug logging of tealium event data
        globalThis.utag_cfg_ovrd.noconsole = true // eslint-disable-line
    } else {
        // eslint-disable-next-line no-console
        console.log("globalThis is undefined or null")
    }

    if (typeof globalThis?.utag !== "undefined" && globalThis?.utag !== null) {
        updateBaseAnalytics(utagData)
    } else {
        insertTealiumScript()

        tagInitialPageLoad()
    }
}

/**
 * Tag event for initial page load.
 * Must wait until tealium script is fully loaded, ie `globalThis.utag` data is defined.
 *
 * Note: This is only responsible for the very first "page load" event, immediately upon loading the page (or as soon as tealium is ready).
 * The other "page load" events are captured in _app.tsx (see routeChangeComplete handler),
 * for when the router loads subsequent pages.
 */
const tagInitialPageLoad = () => {
    // capture the value here, just in case it changes during interval
    const path = window.location.pathname

    // TODO: do we really want to debug log in non-prod? not good!
    const debug = (...args) => {
        return getEnvironment() === "prd"
            ? () => {
                return
            }
            : // eslint-disable-next-line no-console
            console.log(...args)
    }

    /**
     * checks if tealium script has been loaded & completed initialization
     * ie, globalThis.utag has data populated
     */
    const ready = () =>
        typeof globalThis.utag !== "undefined" && globalThis.utag !== null

    const tagPageLoad = (path: string) => {
        debug("tag initial page load - path:", path)
        tagEvent({
            tealium_event: "page load event",
            page_path: path,
            page_title: document.title || path,
        })
    }

    debug("tag initial page load - start - starting interval: 250")
    const tagPageLoadInterval = setInterval(() => {
        if (ready()) {
            tagPageLoad(path)
            clearInterval(tagPageLoadInterval)
        } else {
            debug(
                "tag initial page load - NOT READY (utag not available) - waiting interval: 250",
            )
        }
    }, 250)
}

interface UserLocale {
    readonly languageCode: string
    readonly countryCode: string
}

/**
 * Returns user's current locale information by checking navigator languages.
 * @returns UserLocale object
 */
export const getUserLocale = (navigator: Navigator): UserLocale => {
    const browserLocales =
        navigator.languages === undefined
            ? [navigator.language]
            : navigator.languages

    if (!browserLocales || browserLocales[0] == undefined) {
        // Defaulting to current ADIF behavior
        return {
            languageCode: "zz",
            countryCode: "ZZ",
        }
    } else {
        // navigator.languages array is ordered by preference with the most preferred language first.
        const splitLocale = String(browserLocales[0]).trim().split(/-|_/)
        return {
            languageCode: splitLocale[0],
            countryCode: splitLocale[1]?.toUpperCase(),
        }
    }
}

/**
 * Event data types
 * -----------------------------------------------------------------------------
 * Guidelines:
 * https://cambiahealth.atlassian.net/wiki/spaces/APM/pages/1369018022/Analytics+Tagging+Guidelines+for+Web+and+Mobile#Required-Data-for-Web
 */
export interface RxEventData {
    readonly rx_medication_id?: string
    readonly rx_condition_id?: string
    readonly rx_conditionMedication_id?: string
    readonly rx_campaign_id?: string
    readonly rx_pharmacy_id?: string
    readonly rx_treatment_id?: string
}
export interface PageEvent extends RxEventData {
    readonly tealium_event: "page load event"
    readonly page_title: string
    readonly page_name?: string
    readonly page_path?: string
    readonly page_url?: string
    readonly relationship?: string
}
export interface LinkEvent extends RxEventData {
    readonly tealium_event: "link"
    readonly data_analytics_id: string
    readonly link_url: string
    readonly link_text: string
    readonly page_url?: string
    readonly page_path?: string
    readonly relationship?: string
    readonly medication_id?: string
}
export interface FormSubmittedEvent extends RxEventData {
    readonly tealium_event: "form_submitted"
    readonly data_analytics_id: string
    readonly link_url: string
    readonly link_text: string
    readonly page_url?: string
    readonly page_path?: string
    readonly relationship?: string
}
export interface ModalEvent extends RxEventData {
    readonly tealium_event: "modal"
    readonly alert_message: string
    readonly page_url?: string
    readonly page_path?: string
    readonly relationship?: string
}
export interface AlertEvent {
    readonly tealium_event: "alert"
    readonly alert_message: string
    readonly page_url?: string
    readonly page_path?: string
    readonly relationship?: string
}
export interface SearchEvent extends RxEventData {
    readonly tealium_event: "searched"
    readonly page_url?: string
    readonly page_path?: string
    readonly search_query: string
    readonly search_results: string
    readonly search_radius?: string
    readonly search_is_suggested?: string
    readonly search_category?: string
}
export interface SearchSelectEvent extends RxEventData {
    readonly tealium_event: "search result selected"
    readonly page_url?: string
    readonly page_path?: string
    readonly search_query: string
}
export interface SearchSelectTermEvent extends RxEventData {
    readonly tealium_event: "search term cleared"
    readonly page_url?: string
    readonly page_path?: string
    readonly search_query: string
}
export interface AppCardEvent {
    readonly tealium_event: "appcard_view" | "appcard_click" | "appcard_close"
    readonly appcard_name: string
    readonly page_url?: string
    readonly page_path?: string
    readonly page_title?: string
    readonly rx_campaign_id?: string
}
export interface AccountEvent {
    readonly tealium_event: "account_updated"
    readonly account_channel: string
    readonly page_url?: string
    readonly page_path?: string
}
export interface AuthEvent extends RxEventData {
    readonly tealium_event: "signed_in" | "signed_out"
    readonly page_url?: string
    readonly page_title?: string
    readonly page_path?: string
    readonly relationship?: string
}
export interface InputEvent extends RxEventData {
    readonly tealium_event: "input"
    readonly data_analytics_id: string
    readonly page_url?: string
    readonly page_title?: string
    readonly page_path?: string
}
export type EventData =
    | PageEvent
    | LinkEvent
    | FormSubmittedEvent
    | ModalEvent
    | AlertEvent
    | SearchEvent
    | SearchSelectEvent
    | SearchSelectTermEvent
    | AppCardEvent
    | AccountEvent
    | AuthEvent
    | InputEvent

/**
 * Event tagging
 * -----------------------------------------------------------------------------
 * Docs:
 * https://docs.tealium.com/platforms/javascript/api/tracking-functions/#utag-link
 */
export const tagEvent = (event: EventData) => {
    if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                ...event,
            }),
        )
        return
    }

    if (typeof globalThis?.utag !== "undefined" && globalThis?.utag !== null) {
        /**
         * Define event data
         */
        const { origin, pathname } = window.location
        const data = {
            page_url: `${origin}${pathname}`,
            page_name: definePageName(pathname),
            ...defineRxData(pathname, event),
            ...event,
            ...defineCareGapUuid(pathname),
        }

        /**
         * Send event data to Tealium
         */
        try {
            const isDev = getEnvironment() !== "prd"
            if (event.tealium_event === "page load event") {
                /**
                 * Send `view` event
                 */
                globalThis?.utag.view(data)
                if (isDev) console.log("TEALIUM: VIEW", data) // eslint-disable-line
            } else {
                /**
                 * Send `link` event
                 */
                globalThis?.utag.link(data)
                if (isDev) console.log("TEALIUM: LINK", data) // eslint-disable-line
            }
        } catch (e) {
            console.error(e)
        }
    }
}

/**
 * Utility: Define `page_name`
 * -----------------------------------------------------------------------------
 */
export const definePageName = (pathname: string) => {
    // Define utag data
    const utag = globalThis?.utag_data
    const brand = utag.site_brand || "Regence"
    const visibility = utag.custom_is_logged_in === "1" ? "private" : "public"
    const audience = utag.site_audience || "common"

    // Get the last uri segment from the pathname
    const last_uri = pathname.split("/").filter(i => i).pop() // prettier-ignore
    const page_name = last_uri || "home"

    // Return formatted `page_name`
    return `${brand}>>${visibility}>${audience}>${page_name}`
}

/**
 * Utility: Define Rx event data
 * -----------------------------------------------------------------------------
 */
export const defineRxData = (
    pathname: string,
    event: EventData,
): RxEventData => {
    return {
        rx_campaign_id: getCampaignId(pathname, event),
        rx_condition_id: getConditionId(pathname, event),
        rx_conditionMedication_id: getConditionMedicationId(pathname, event),
        rx_medication_id: getMedicationId(pathname, event),
        rx_pharmacy_id: getPharmacyId(pathname, event),
        rx_treatment_id: getTreatmentId(pathname, event),
    }
}

/**
 * Utility: Define Care Gap UUID event
 * -----------------------------------------------------------------------------
 */
export const defineCareGapUuid = (
    pathname: string,
): { readonly care_gap_uuid?: string } => {
    const localCareGapUuid = getLocalStorageItem("CARE_GAP_UUID")
    if (pathname?.includes("care-gaps")) {
        const pathnameCareGapUuid = pathname?.substring(
            pathname.lastIndexOf("/") + 1,
        )
        if (
            (localCareGapUuid &&
                pathnameCareGapUuid &&
                localCareGapUuid !== pathnameCareGapUuid) ||
            (!localCareGapUuid && pathnameCareGapUuid)
        ) {
            setLocalStorageItem("CARE_GAP_UUID", pathnameCareGapUuid)
            return { care_gap_uuid: pathnameCareGapUuid }
        }
    }
    if (localCareGapUuid) {
        return { care_gap_uuid: localCareGapUuid }
    }
    return {}
}

/*
 * Utility: Logs analytics events for the Forms & Documents page.
 * -------------------------------------------------------------------------------
 * TODO: remove when DF migrates off of iFrames
 */
export const formsDocumentsLinkAnalytics = () => {
    const iframe = document.getElementById("iframeElement") as HTMLIFrameElement
    if (iframe.contentDocument !== null) {
        const iframeDoc = iframe.contentDocument
            ? iframe.contentDocument
            : iframe.contentWindow.document
        setTimeout(() => {
            const reset = iframeDoc.getElementById("globalResetLink")
            const yearChange = iframeDoc.getElementById("yearToggler")
            const categoryChange = iframeDoc.getElementById("categoryToggler")
            const initialTableDocs = iframeDoc.querySelectorAll(
                "a, data-analytics-id",
            )
            const table = iframeDoc.querySelector("tbody")

            Array.from(initialTableDocs).forEach(function (doc) {
                doc?.addEventListener("click", function (event) {
                    tagEvent({
                        tealium_event: "link",
                        data_analytics_id: "form-docs-document-select",
                        link_text: doc.getAttribute("data-analytics-id"),
                        link_url: doc.getAttribute("href"),
                    })
                })
            })

            reset?.addEventListener("click", function (event) {
                event.preventDefault()
                tagEvent({
                    tealium_event: "link",
                    data_analytics_id: "form-docs-reset",
                    link_text: "Reset",
                    link_url: "",
                })
            })
            yearChange?.addEventListener(
                "DOMCharacterDataModified",
                function (event) {
                    event.preventDefault()
                    tagEvent({
                        tealium_event: "link",
                        data_analytics_id: "form-docs-year-change",
                        link_text: yearChange.innerText,
                        link_url: "",
                    })
                },
            )
            categoryChange?.addEventListener(
                "DOMCharacterDataModified",
                function (event) {
                    event.preventDefault()
                    const test = iframeDoc.getElementsByClassName(
                        "category checked-category",
                    )
                    let categories = "" //eslint-disable-line
                    Array.from(test).forEach(function (element) {
                        categories = categories + "," + element.textContent
                    })
                    tagEvent({
                        tealium_event: "link",
                        data_analytics_id: "form-docs-category-change",
                        link_text: categories,
                        link_url: "",
                    })
                },
            )

            const observer = new MutationObserver(function (mutations) {
                const pageChangeDocs = iframeDoc.querySelectorAll(
                    "a, data-analytics-id",
                )
                Array.from(pageChangeDocs).forEach(function (doc) {
                    doc?.addEventListener("click", function (event) {
                        tagEvent({
                            tealium_event: "link",
                            data_analytics_id: "form-docs-document-select",
                            link_text: doc.getAttribute("data-analytics-id"),
                            link_url: doc.getAttribute("href"),
                        })
                    })
                })
            })

            const config = {
                attributes: false,
                childList: true,
                characterData: false,
            }

            // Adds event listener to new docs when table changes
            observer.observe(table, config)
        }, 500)
    }
}

/**
 * Utility: Analytics fields from Insight Props
 * -----------------------------------------------------------------------------
 */
interface InsightAnalytics {
    readonly analyticId?: string
    readonly route?: string
    readonly campaignGuid?: string
    readonly title?: string
}

export const insightAnalytics = (insight: InsightProps): InsightAnalytics => {
    const analyticId = insight.analyticsTitle
                    ? insight.analyticsTitle.replace(/ /g, "_")
                    : insight.insightId
    const route = insight.action?.route
    const campaignGuid =
        route
            ?.split("/")
            .slice(4, 5)[0]
            .split("?")
            .slice(0, 1)[0] || ""
    const title = insight.title.replace(/ /g, "_")

    return {analyticId: analyticId, route: route, campaignGuid: campaignGuid, title: title}
}