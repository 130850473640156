/**
 * PremiumPay reducer
 * -----------------------------------------------------------------------------
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { PremiumPayInfoItem } from "./types"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface PremiumPayInfo {
    /**
     * Init states
     */
    readonly isInitialized: boolean
    readonly isLoading: boolean
    readonly errorMessage?: string
    readonly premiumPayInfoItem?: PremiumPayInfoItem
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: PremiumPayInfo = {
    isInitialized: false,
    isLoading: false,
    errorMessage: undefined,
    premiumPayInfoItem: undefined,
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const premiumPayInfo = createSlice({
    name: "premiumPayInfo",
    initialState,
    reducers: {
        receivePremiumPayInfo: (
            state: PremiumPayInfo,
            action: PayloadAction<PremiumPayInfo>,
        ) => {
            return action.payload
        },
        resetPremiumPayInfo: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const premiumPayActions = { ...premiumPayInfo.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default premiumPayInfo.reducer
