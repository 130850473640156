/**
 * Popover Modal
 * -----------------------------------------------------------------------------
 */
import React from "react"
import ReactDOM from "react-dom"
import { Item } from "./action_menu"
import { createFocusTrap, FocusTrap } from "focus-trap"
import { ActionSheet, Card, Button } from "elements"
import { useEscapeModal, useBodyScroll } from "utils/window"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly name: string
    readonly children: React.ReactNode
    readonly isVisible: boolean
    readonly variant?: "default" | "hasActionSheet"
    readonly items?: ReadonlyArray<Item>
    readonly onDismiss: (e?: React.MouseEvent) => void
    readonly title?: string
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Popover: React.FC<Props> = props => {
    /**
     * Hooks
     */
    const escapeModal = useEscapeModal("popover", props.onDismiss)
    const bodyScroll = useBodyScroll()

    /**
     * State
     */
    const [root, setRoot] = React.useState<HTMLElement>()
    const [focusTrap, setFocusTrap] = React.useState<FocusTrap>()

    /**
     * Methods
     */
    const onDismiss = (e?: React.MouseEvent): void => {
        props.onDismiss(e)
        // TODO: event analytics
    }

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        // Setup a "Focus Trap" – prevent tabbing out of modal
        if (props.isVisible) {
            setFocusTrap(() => {
                const trap = createFocusTrap("#popover")
                trap.activate()
                return trap
            })
        } else {
            if (focusTrap) focusTrap.deactivate()
        }

        // Listen for `escape` to dismiss
        if (props.isVisible) {
            escapeModal.addListener()
            bodyScroll.disable()
        }
        return () => {
            escapeModal.removeListener()
            bodyScroll.enable()
        }
    }, [props.isVisible]) // eslint-disable-line

    /**
     * Define portal DOM root
     */
    React.useEffect(() => {
        setRoot(document.getElementById("root-popover"))
    }, [])

    /**
     * Define template variables
     */
    const variants = {
        default: "right-xs",
        hasActionSheet: "left-xs md:left-auto md:right-xs",
    }
    const variant = variants[props.variant || "default"]

    /**
     * Template
     */
    const template = props.isVisible ? (
        <div
            className="fixed z-popover min-h-screen w-screen overflow-y-auto bg-black bg-opacity-50"
            onClick={onDismiss}
            id="popover"
            role="dialog"
            aria-modal={true}
        >
            <div className="absolute min-h-screen w-full px-sm py-md box-border flex justify-center">
                <Card
                    name={props.name}
                    className="relative w-full max-w-[800px]"
                    stopPropagation={true}
                >
                    <Button
                        name="dismiss-button"
                        icon="x"
                        variant="link"
                        size="small"
                        className={`absolute top-xs ${variant}`}
                        onClick={onDismiss}
                    />
                    {props.title && (
                        <h5 className="text-center">{props.title}</h5>
                    )}

                    {props.variant == "hasActionSheet" && (
                        <ActionSheet
                            className="absolute top-xs right-xs md:hidden"
                            name="popover-menu"
                            items={props.items}
                            align="right"
                        />
                    )}

                    {props.children}
                </Card>
            </div>
        </div>
    ) : null
    return root ? ReactDOM.createPortal(template, root) : null
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Popover
