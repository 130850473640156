/**
 * PharmacistInquiry reducer
 * -----------------------------------------------------------------------------
 */
import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import * as actions from "./actions"


export interface PharmacistInquiryTopic {
    readonly slug: string
    readonly topicText: string

    readonly steps: ReadonlyArray<PharmacistInquiryStep>
}

export interface PharmacistInquiryStep {
    readonly stepSlug: string
    readonly stepRoute: string
    readonly headline: string
    readonly ordinality: number
    readonly nextPage: string
    readonly editMedsLink?: string
}

export interface PharmacistInquirySlice {
    readonly isInitialized: boolean
    readonly isLoading: boolean
    readonly errorMessage?: string
    readonly message: string
    readonly additionalOTCMeds: string
    readonly selectedSubjectName: string
    readonly selectedSubjectLabel: string
    readonly selectedSubjectId: string
    readonly selectedTopic: string
    readonly selectedMedications: ReadonlyArray<{
        readonly medicationId: string
        readonly displayName: string
    }>
    readonly selectedCondition: string
    readonly writeInCondition: string
    readonly additionalInfo: string
    readonly effectiveness: string
    readonly email: string
    readonly useUnencryptedEmail: boolean
    readonly referer: string
    readonly jobId: string
    readonly topic: PharmacistInquiryTopic
    readonly currentStep?: number
    readonly oppId?: string
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: PharmacistInquirySlice = {
    isInitialized: false,
    isLoading: false,
    errorMessage: undefined,
    message: undefined,
    selectedSubjectName: undefined,
    selectedSubjectLabel: undefined,
    selectedSubjectId: undefined,
    selectedTopic: undefined,
    additionalInfo: undefined,
    effectiveness: undefined,
    email: undefined,
    selectedMedications: undefined,
    selectedCondition: undefined,
    writeInCondition: undefined,
    additionalOTCMeds: undefined,
    useUnencryptedEmail: false,
    referer: undefined,
    jobId: undefined,
    topic: undefined
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const pharmacistInquiry = createSlice({
    name: "pharmacistInquiry",
    initialState,
    reducers: {
        updatePharmacistInquiry: (
            state: PharmacistInquirySlice,
            action: PayloadAction<PharmacistInquirySlice>,
        ) => {
            return {
                ...state,
                ...action.payload,
            }
        },
        resetPharmacistInquiry: () => {
            return initialState
        },
    },
})


/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const pharmacistInquiryActions = {
    ...pharmacistInquiry.actions,
    ...actions,
}

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default pharmacistInquiry.reducer
