/**
 * Radio Input
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { tagEvent, LinkEvent } from "@/utils/analytics"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Item {
    readonly value: string
    readonly label: string | React.ReactNode
    readonly dataAnalyticsId?: string
}
interface Props extends React.HTMLAttributes<HTMLDivElement> {
    readonly name: string
    readonly value: string
    readonly setValue: (value: string) => void
    readonly items: ReadonlyArray<Item>
    readonly label?: string
    readonly labelClass?: string
    readonly inverted?: boolean
    readonly customStyles?: string
    readonly size?: "small" | "medium" | "large"
    readonly reverse?: boolean
    readonly border?: boolean
    readonly tabIndex?: number
    readonly onClick?: VoidFunction
    readonly analytics?: Partial<LinkEvent>
    readonly dataAnalyticsId?: Record<string, string>
    readonly noCenter?: boolean
    readonly borderBottom?: boolean
    readonly hideRadioSelect?: boolean //In case if any usecase needs radio select button to be kept hidden
    readonly disabled?: boolean
    readonly errorMessage?: string
    readonly customRadioStyle?: string
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Radio: React.FC<Props> = props => {
    /**
     * Methods
     */
    const onClick = (item: Item) => {
        // Update value
        props.setValue(item.value)

        // Analytics
        tagEvent({
            tealium_event: "link",
            data_analytics_id: props.name + "-" + item.value,
            link_text: item.label.toString() || "",
            link_url: "",
            ...props.analytics,
        })

        if (item.dataAnalyticsId) {
            // TODO: Replace instances using `dataAnalyticsId`
            tagEvent({
                tealium_event: "link",
                data_analytics_id: item.dataAnalyticsId,
                link_text: "radio",
                link_url: "",
            })
        }

        // Optional event
        if (props.onClick) props.onClick()
    }

    /**
     * Define template variables
     */
    const radioStyle =
        props.size === "medium"
            ? `disabled:opacity-50 disabled:cursor-not-allowed appearance-none cursor-pointer w-[23px] h-[23px] max-w-[23px] max-h-[23px] rounded-circle border border-light-200 checked:border-[6px] checked:border-primary dark:checked:border-accent ${props.customRadioStyle} ${props.noCenter ? "" : " mt-xxs"}`
            : props.size === "large"
                ? `disabled:opacity-50 disabled:cursor-not-allowed appearance-none cursor-pointer w-[28px] h-[28px] max-w-[28px] max-h-[28px] rounded-circle border border-light-200 checked:border-[7px] checked:border-primary dark:checked:border-accent ${props.customRadioStyle} ${props.noCenter ? "" : " mt-xxs"}`
                : `appearance-none cursor-pointer w-[18px] h-[18px] max-w-[18px] max-h-[18px] rounded-circle border border-light-200 checked:border-[5px] checked:border-primary dark:checked:border-accent ${props.customRadioStyle} ${props.noCenter ? "" : " mt-xxs"}`

    // eslint-disable-next-line
    let containerStyle = props.reverse
        ? "flex flex-row-reverse" +
        (props.noCenter ? "" : " items-center")
        : "flex" + (props.noCenter ? "" : " items-center")
    if (props.border)
        containerStyle += " border-t border-light-100 dark:border-dark-50"

    if (props.borderBottom)
        containerStyle += " border-b border-light-100 dark:border-dark-50"

    /**
     * Template
     */
    return (
        <div className={props.className}>
            {props.label && (
                <label className={`${props.labelClass}`}>{props.label}</label>
            )}
            {props.items?.map((item, index) => {
                const id = props.name + "-" + index
                return (
                    <div key={index} className={containerStyle}>
                        {!props.hideRadioSelect && (
                            <div>
                                <input
                                    type="radio"
                                    tabIndex={props.tabIndex}
                                    name={props.name}
                                    id={id}
                                    value={item.value}
                                    checked={props.value === item.value}
                                    onClick={() => onClick(item)}
                                    className={radioStyle}
                                    data-test={id}
                                    disabled={props.disabled}
                                />
                            </div>
                        )}
                        <div className="flex-1">
                            <label
                                htmlFor={id}
                                className={
                                    props.errorMessage ? `${props.customStyles} text-error ml-xs mb-none` : "" ||
                                        props.customStyles !== undefined
                                        ? props.customStyles
                                        : "block ml-xs mb-none text-base normal-case font-normal tracking-normal"
                                }
                            >
                                {item.label}
                            </label>
                        </div>
                    </div>
                )
            })}
            {/* Error message */}
            {
                props.errorMessage && (
                    <div className="mt-xxs">
                        <small className="text-error">{props.errorMessage}</small>
                    </div>
                )
            }
        </div >
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Radio
