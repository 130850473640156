import fetch from "cross-fetch"
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client"

export const dfisClient = new ApolloClient({
    link: new HttpLink({
        uri: "/api/janus/digital-first-information-service/graphql",
        fetch: fetch,
    }),
    cache: new InMemoryCache(),
    defaultOptions: {
        query: {
            fetchPolicy: "no-cache",
            // errorPolicy: "all",
        },
    },
})

export default { dfisClient }
