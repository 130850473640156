/* eslint-disable no-console */
/**
 * File Upload Params Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions } from "store"
import http from "utils/http"
import { fileUploadParamsQuery } from "./queries"
import { transformFileUploadParams } from "./utils"

/**
 * Get File Upload Params data
 * -----------------------------------------------------------------------------
 */
export const fetchFileUploadParams = createAsyncThunk(
    "fileUploadParams/fetchFileUploadParams", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.fileUploadParamStatus("pending"))

        /**
         * Request File Upload Params
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            fileUploadParamsQuery,
        )

        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.fileuploadParamError({
                    response: res,
                }),
            )
        }

        const paramsData = res.data?.data?.claimSubmission?.fileUplaodParams
        const fileUploadParams = transformFileUploadParams(paramsData)

        /**
         * Update state
         */
        dispatch(
            actions.receiveFileUploadParam({
                fileUploadParameters: fileUploadParams || undefined,
            }),
        )
    },
)
