import * as React from "react"
import { SVGProps } from "react"
const SvgSprayPump = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Spray bottle"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9.9 10.0002H13.8C15.4 10.0002 16.7 11.3002 16.7 13.0002V19.0002C16.7 19.5525 16.2523 20.0002 15.7 20.0002H8C7.44772 20.0002 7 19.5525 7 19.0002V13.0002C7 11.3002 8.3 10.0002 9.9 10.0002ZM9.9 10.0002L10.8273 5.78532C10.9281 5.3268 11.3344 5.00018 11.8039 5.00018H11.9961C12.4656 5.00018 12.8718 5.32679 12.9727 5.78532L13.9 10.0002"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgSprayPump
