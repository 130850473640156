import * as React from "react"
import { SVGProps } from "react"
const SvgMail = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Envelope" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M21.8 5.1L13.6 13.2C12.7 14.1 11.3 14.1 10.5 13.2L2.20001 5.1M20.5 20H3.5C2.1 20 1 18.9 1 17.6V6.4C1 5.1 2.1 4 3.5 4H20.5C21.9 4 23 5.1 23 6.4V17.5C23 18.9 21.9 20 20.5 20Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgMail
