import * as React from "react"
import { SVGProps } from "react"
const SvgPaperplane = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Paper airplane"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M23 1L23.9487 1.31623C24.0686 0.956607 23.9748 0.560122 23.7065 0.292301C23.4382 0.0244802 23.0416 -0.0686282 22.6822 0.0518478L23 1ZM5.1 7L4.78218 6.05185C4.42144 6.17277 4.16057 6.48791 4.10917 6.86489C4.05776 7.24187 4.22473 7.61535 4.53993 7.82844L5.1 7ZM17 19L16.1679 19.5547C16.3798 19.8725 16.7542 20.0418 17.1327 19.9912C17.5113 19.9405 17.8279 19.6786 17.9487 19.3162L17 19ZM1.29289 15.2929C0.902369 15.6834 0.902369 16.3166 1.29289 16.7071C1.68342 17.0976 2.31658 17.0976 2.70711 16.7071L1.29289 15.2929ZM6.29289 22.2929C5.90237 22.6834 5.90237 23.3166 6.29289 23.7071C6.68342 24.0976 7.31658 24.0976 7.70711 23.7071L6.29289 22.2929ZM6.70711 18.7071C7.09763 18.3166 7.09763 17.6834 6.70711 17.2929C6.31658 16.9024 5.68342 16.9024 5.29289 17.2929L6.70711 18.7071ZM0.292893 22.2929C-0.0976311 22.6834 -0.0976311 23.3166 0.292893 23.7071C0.683417 24.0976 1.31658 24.0976 1.70711 23.7071L0.292893 22.2929ZM5.70711 13.7071L6.41421 13L5 11.5858L4.29289 12.2929L5.70711 13.7071ZM11.7071 19.7071L12.4142 19L11 17.5858L10.2929 18.2929L11.7071 19.7071ZM9.70711 15.7071L10.4142 15L9 13.5858L8.29289 14.2929L9.70711 15.7071ZM4.79289 17.7929L4.08579 18.5L5.5 19.9142L6.20711 19.2071L4.79289 17.7929ZM22.6822 0.0518478L4.78218 6.05185L5.41782 7.94815L23.3178 1.94815L22.6822 0.0518478ZM4.53993 7.82844L11.6399 12.6284L12.7601 10.9716L5.66007 6.17156L4.53993 7.82844ZM11.3679 12.3547L16.1679 19.5547L17.8321 18.4453L13.0321 11.2453L11.3679 12.3547ZM17.9487 19.3162L23.9487 1.31623L22.0513 0.683772L16.0513 18.6838L17.9487 19.3162ZM12.9071 12.5071L23.7071 1.70711L22.2929 0.292893L11.4929 11.0929L12.9071 12.5071ZM5.29289 17.2929L0.292893 22.2929L1.70711 23.7071L6.70711 18.7071L5.29289 17.2929ZM8.29289 14.2929L4.79289 17.7929L6.20711 19.2071L9.70711 15.7071L8.29289 14.2929ZM4.29289 12.2929L1.29289 15.2929L2.70711 16.7071L5.70711 13.7071L4.29289 12.2929ZM10.2929 18.2929L6.29289 22.2929L7.70711 23.7071L11.7071 19.7071L10.2929 18.2929Z"
            fill="currentColor"
        />
    </svg>
)
export default SvgPaperplane
