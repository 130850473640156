/* eslint-disable no-console */
/**
 * MTax forms IRS Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions } from "store"
import http from "utils/http"
import { fetchDocumentQuery } from "./queries"
import { transformTaxForm } from "./utils"

/**
 * Get tax forms data
 * -----------------------------------------------------------------------------
 */
export const fetchTaxForms = createAsyncThunk(
    "taxforms/fetchTaxForms", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.taxFormsInformationStatus("pending"))

        /**
         * Request tax forms data
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            // fetchableIdCardsQuery
            fetchDocumentQuery,
        )

        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.taxFormsInformationError({
                    response: res,
                }),
            )
        }

        const forms = res.data?.data?.fetchableDocuments
        const taxForm = transformTaxForm(forms)
        /**
         * Update state
         */
        dispatch(
            actions.receiveTaxForms({
                taxFormsInformation: taxForm || [],
            }),
        )
    },
)
