import { InboxMessages, MessageTopics, SentMessages } from "./reducer"
export const MESSAGE_CENTER_PREV_URL_KEY = "MESSAGE_CENTER_PREV_URL"
export const transformInboxMesages = (
    data: any, // eslint-disable-line
): InboxMessages => {
    // eslint-disable-next-line functional/no-let
    let unreadCount = 0

    // eslint-disable-next-line functional/no-let
    for (let i = 0; i < data.length; i++) {
        if (!data[i].messageRead) {
            unreadCount++
        }
    }

    const messagesList = data.map(d => {
        return {
            messageId: d?.messageId,
            messageRead: d?.messageRead,
            subject: d?.subject,
            bodyPreview: d?.bodyPreview,
            sentDateTime: d?.sentDateTime,
            resourcePath: d?.resourcePath,
        }
    })

    return {
        inboxMessages: messagesList,
        unreadMsgsCount: unreadCount,
    }
}

export const transformSentMesages = (
    data: any, // eslint-disable-line
): SentMessages => {
    const messagesList = data.map(d => {
        return {
            messageId: d?.messageId,
            messageRead: d?.messageRead,
            bodyPreview: d?.bodyPreview,
            subject: d?.subject,
            sentDateTime: d?.sentDateTime,
            resourcePath: d?.resourcePath,
        }
    })
    return {
        sentMessages: messagesList,
    }
}

export const transformMessageTopics = (
    data: any, // eslint-disable-line
): MessageTopics => {
    const messageTopicsList = data.map(d => {
        return {
            mailboxCode: d?.mailboxCode,
            mailSubjectName: d?.mailSubjectName,
            subjectText: d?.subjectText
        }
    })
    
    return {
        messageTopics: messageTopicsList,
    }
}
