/**
 * Insights reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { Insight, Member } from "./types"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Insights {
    readonly items: ReadonlyArray<Insight>
    readonly members: ReadonlyArray<Member>
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<Insights> = {
    status: "pristine",
    items: [],
    members: [],
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const insights = createStatefulSlice({
    name: "insights",
    initialState,
    reducers: {
        receiveInsights: (
            state: SliceState<Insights>,
            action: PayloadAction<Insights>,
        ) => {
            return {
                status: "completed",
                items: action.payload.items,
                members: action.payload.members
            }
        },
        resetInsights: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const insightsActions = { ...insights.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default insights.reducer
