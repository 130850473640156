import { useRouter } from "next/router"

const MEDICAL_PROVIDER_QUERY_PARAMS =
    "?RelayState=%2Fhealthsparq%2F%23%2Fone%2Fauth%2Fresults%2Fquery=Primary%2520Care%2520Provider"
const MEDICAL_ENDOCRINOLOGY_QUERY_PARAMS =
    "?RelayState=%2Fhealthsparq%2F%23%2Fone%2Fauth%2Fresults%2Fquery=Endocrinology"
const MEDICAL_OPTOMETRIST_QUERY_PARAMS =
    "?RelayState=%2Fhealthsparq%2F%23%2Fone%2Fauth%2Fresults%2Fquery=Optometrist"
const MEDICAL_OPHTHALMOLOGIST_QUERY_PARAMS =
    "?RelayState=%2Fhealthsparq%2F%23%2Fone%2Fauth%2Fresults%2Fquery=Ophthalmologist"
const MEDICAL_NEPHROLOGY_QUERY_PARAMS =
    "?RelayState=%2Fhealthsparq%2F%23%2Fone%2Fauth%2Fresults%2Fquery=Nephrology"
const MENTAL_HEALTH_QUERY_PARAMS =
    "?RelayState=%2Fhealthsparq%2F%23%2Fone%2Fauth%2Fresults%2Fquery=Mental%2520Health"
const VIRTUAL_CARE_QUERY_PARAMS =
    "?RelayState=%2Fhealthsparq%2F%23%2Fone%2Fauth%2Fresults%2Fquery=Behavioral%2520Health%2520-%2520Virtual%2520Provider"
const REGINA_IMAGING_QUERY_PARAMS =
    "?RelayState=%2Fhealthsparq%2F%23%2Fone%2Fauth%2Fsearch%2FisPromotionSearch=true&key=CPT%7C2021%7C92227&page=1&query=Imaging%20of%20Retina%20for%20Detection%2FMonitoring&radius=100&searchCategory=ENCOUNTER&searchType=SERVICE"

/**
 * Routes
 * -----------------------------------------------------------------------------
 */
const Routes = {
    // Base
    home: () => "/",
    signin: () => "/signin",
    signout: () => "/signout",
    health: () => "/health",

    // Settings
    settings: () => "/account-settings/account-information",
    messages: () => "/message-center",
    messageDetail: (id: string) => "/messages/" + id,
    verifyEmailClassic: () => "/member/activation",
    verifySms: () => "/member/sms",

    //onboarding
    verifyEmail: () => "/onboarding/activation/check-your-email",
    changeEmail: () => "/onboarding/activation/change-your-email",
    onboardingCheckYourText: () => "/onboarding/activation/check-your-text",
    onboardingChangeYourPhone: () => "/onboarding/activation/change-your-phone",
    onboardingGoPaperless: () => "/onboarding/activation/go-paperless",
    onboardingCommPreferences: () =>
        "/onboarding/activation/communication-preferences",
    onboardingKeepInformed: () => "/onboarding/activation/keep-informed",
    onboardingWelcome: () => "/onboarding/activation/welcome",

    // Your care
    timeline: () => "/care/your-care-history",
    timelineDetail: (id: string) => "/care/your-care-history/" + id,
    yourCareHistory: () => "/care/your-care-history",
    yourCareHistoryDetail: (id: string) => "/care/your-care-history/" + id,
    providers: () => "/care/your-providers",
    providerDetail: (id: string) => "/care/your-providers/" + id,
    medications: () => "/pharmacy",
    medicationDetail: (id: string) => "/pharmacy/" + id,
    records: () => "/care/records",
    recordDetail: (id: string) => "/care/records/" + id,

    // Services
    checkBenefitsGeneric: () => "/coverage/your-benefits",
    checkBenefits: () => "/coverage/your-benefits/medical",
    dentalBenefits: () => "/coverage/your-benefits/dental",
    pharmacyBenefits: () => "/coverage/your-benefits/pharmacy",
    visionBenefits: () => "/coverage/your-benefits/vision",
    hearingBenefits: () => "/coverage/your-benefits/hearing",
    resources: () => "/care/care-resources",
    medigapBehavioralHealth: () => "https://www.medicare.gov",
    programsBehavioralHealth: () => "/care/care-resources/behavioral-health",
    programsMedicare: () => "/care/care-resources/medicare",
    programsConditionManagement: () =>
        "/care/care-resources/condition-management",
    programsMaternity: () => "/care/care-resources/maternity",
    palliativeCare: () => "/care/care-resources/palliative-care",
    programsMedicalSupplies: () => "/care/care-resources/medical-supplies",
    programsTelehealth: () => "/care/care-resources/telehealth",
    advantagesDiscounts: () => "/care/care-resources/advantages-discounts",

    forms: () => "/documents/forms-and-documents",
    preventiveCare: () => "/care/care-resources/preventive-care",
    genderAffirmingCare: () => "/care/care-resources/gender-affirming-care",
    askADoctor: () => "https://www.ask-adoctor.com",
    expertOpinion: () => "https://www.2nd.md/",
    hingeHealth: () => "https://www.hingehealth.com",
    hingeHealthCIS: () => "https://www.hingehealth.com/cisoregon",
    ableTo: () => "https://app.ableto.com/",
    guidanceResources: () => "https://www.guidanceresources.com/",
    waBehavioralHealth: () => "/member/resources/wa-behavioral-health",
    bshBehavioralHealth: () => "/member/members/washington-behavioral-health",
    careManagement: () => "/care/care-resources/care-management",
    programsAdvice24: () => "/care/care-resources/advice24",
    myStrength: () => "https://www.regence.com/mystrength/login",
    heal: () => "https://heal.com",
    experian: () => "https://experian.myfinancialexpert.com/enrollment/24",
    strive: () => "https://strivehealth.com/patients/",
    dispatchHealthConditions: (brand, region) => {
        if (brand === "asuris")
            return "https://www.dispatchhealth.com/asuris?utm_source=asuris&utm_medium=referral&utm_campaign=partner-page&utm_content=landing-page&utm_term=18891"
        if (brand === "bridgespan") return "https://www.dispatchhealth.com/"
        if (brand === "regence") {
            if (region === "or")
                return "https://www.dispatchhealth.com/Regence/OR/?utm_source=regence&utm_medium=referral&utm_campaign=partner-page&utm_content=landing-page&utm_term=18891"
            if (region === "ut")
                return "https://www.dispatchhealth.com/Regence/UT/?utm_source=regence&utm_medium=partnerwebsite&utm_campaign=701VK000007DUx7YAG&utm_content=landing-page&utm_term=18891"
            if (region === "ww" || region === "wa" || region === "ew")
                return "https://www.dispatchhealth.com/regence/wa/?utm_source=regence&utm_medium=referral&utm_campaign=partner-page&utm_content=landing-page&utm_term=18891"
            if (region === "id") return "https://www.dispatchhealth.com/"
            else return "https://www.dispatchhealth.com/"
        }
    },
    dispatchHealthUrgentCareNearby: () => "https://www.dispatchhealth.com/",
    dispatchHealthERNearby: () => "https://www.dispatchhealth.com/",
    suicidePrevention: () => "https://suicidepreventionlifeline.org/",
    samhsa: () => "https://www.samhsa.gov/",
    medicareGov: () => "https://www.medicare.gov/",
    hearingAidsBenefit: (brand, region) => {
        if (brand === "asuris") return "https://www.truhearing.com/asuris/"
        if (brand === "regence") {
            if (region === "or") return "https://www.truhearing.com/regenceor/"
            if (region === "ut") return "https://www.truhearing.com/regenceut/"
            if (region === "ww" || region === "wa" || region === "ew")
                return "https://www.truhearing.com/regencewa/"
            if (region === "id") return "https://www.truhearing.com/regenceid/"
        }
    },

    // Claims and Costs
    discoverHSA: () => "https://learn.healthequity.com/qme/",
    hsaSSO: () => "/sso/healthequity/web",
    claimsAndCosts: () => "/costs/overview",
    claims: () => "/claims",
    memberClaimSubmit: () => "/member/submit-claim",
    claimSubmit: () => "/medicare/submit-claim",
    claimDetail: (id: string) => "/costs/claims-2.0/" + id,
    claimDetailADIF: (id: string) => "/claims?detail=" + id,
    claimDetailDF: (id: string) => `/claims/${id}`,
    nativeClaimDetailDF: (id: string) => `/costs/native-claims/${id}`,
    claimDetailDFMobileLineItem: (id: string, lineItemId: string) =>
        `/claims/${id}/${lineItemId}`,
    nativeClaimDetailDFMobileLineItem: (id: string, lineItemId: string) =>
        `/costs/native-claims/${id}/${lineItemId}`,
    payments: () => "/costs/payments",
    paymentDetail: (id: string) => "/costs/payments/" + id,
    costEstimates: () => "/costs/estimate-costs",
    costEstimateDetail: (id: string) => "/costs/estimate-costs" + id,
    costEstimateSSO: () => "/sso/healthsparq/tce",
    eobs: () => "/coverage/your-eobs",
    taxForms: () => "/documents/tax-forms",
    taxInformation: () => "/documents/tax-information",
    statements1095b: () => "/documents/1095-b",

    // Support
    support: () => "/contact-us",
    contactUs: () => "/contact-us",
    faqs: () => "/contact-us/faqs",
    termsAndPrivacy: () => "/member/terms-of-use",
    ethicsAndCompliance: () => "/ethics",
    memberRegistration: () => "/member/registration",
    memberHome: () => "/member/home",
    fraudAndAbuse: () => "/fraud",
    feedback: () => "/member/feedback",
    nonDiscrimination: () => "/member/non-discrimination",
    reportFraudOrAbuse: () => "/member/report-fraud-or-abuse",
    claimAppeals: () => "/medicare/dashboard/member-appeals",
    memberAppeals: () => "/member/members/member-appeals",
    formsWA: () => "/member/health-care-quality-concerns-form-washington",
    formsOR: () => "/member/health-care-quality-concerns-form-oregon",
    formsUT: () => "/member/health-care-quality-concerns-form-utah",
    formsID: () => "/member/health-care-quality-concerns-form-idaho",
    formsGeneral: () => "/member/health-care-quality-concerns-form",
    inclusiveCare: () => "/diversity-equity-inclusion",

    // Wizards
    researchMedications: () => "/research",
    researchResults: () => "/research/results",
    researchMedicationDetail: (id: string) => "/research/" + id,
    browseConditions: () => "/research/conditions",
    browseConditionDetail: (id: string) => "/research/conditions/" + id,
    findCare: () => "/care/find-care",
    findCareDetail: (id: string) => "/care/find-care/" + id,
    findDoctor: () => "/sso/healthsparq/medical",
    findDoctorMedigap: () => "https://www.medicare.gov/",
    insights: () => "/insights",
    insight: (uuid: string) => `/insights/${uuid}`,
    memberCards: () => "/member-card",
    browseMedication: (id: string) => "/research/medications/" + id,
    browseCondition: (id: string) => "/research/conditions/" + id,
    browseConditionCompare: (id: string) =>
        "/research/conditions/" + id + "/compare",

    browseCampaigns: () => "/campaign",
    browseCampaign: (id: string) => "/campaign/" + id,
    askAPharmacist: () => "/care/find-care/ask-a-pharmacist",

    askAPharmacistConfirmation: () =>
        "/care/find-care/ask-a-pharmacist/confirmation",
    askAPharmacistAskingAbout: () =>
        "/care/find-care/ask-a-pharmacist/asking-about",

    // this is the one with checkboxes:
    askAPharmacistCurrentMedicationsOTCs: () =>
        "/care/find-care/ask-a-pharmacist/current-medications",

    // this is the one with radio buttons:
    askAPharmacistCurrentMedWithCondition: () =>
        "/care/find-care/ask-a-pharmacist/medication-with-condition",
    askAPharmacistSelectConditions: () =>
        "/care/find-care/ask-a-pharmacist/which-conditions",
    askAPharmacistConditionWriteIn: () =>
        "/care/find-care/ask-a-pharmacist/condition-write-in",
    askAPharmacistMessagePrefs: () =>
        "/care/find-care/ask-a-pharmacist/message-preferences",
    askAPharmacistSummaryConfirmation: () =>
        "/care/find-care/ask-a-pharmacist/summary-confirmation",

    // VSP SSO
    vspSSO: () => "/sso/vsp/subscriber",
    vspFamilySSO: () => "/sso/vsp/family",
    vspBenefitsSSO: () => "/sso/vsp/benefits",
    vspBenefitsHistorySSO: () => "/sso/vsp/benefitshistory",
    vspFadSSO: () => "/sso/vsp/fad",
    vspCISNonSSO: () => "https://www.vsp.com",
    vspNonSSO: () => "https://www.vsp.com/choice",
    hcaNonSSO: () =>
        "https://www.hca.wa.gov/employee-retiree-benefits/school-employees/vision-plans-and-benefits",

    // Pharmacy SSO
    findDrugSSO: () => "/sso/prime/medicine",
    findCvsDrugSSO: () => "/sso/cvs/medicine",
    findPharmacySSO: () => "/sso/prime/pharmacy",
    findCvsPharmacySSO: () => "/sso/cvs/pharmacy",
    m3pWiproSSO : () => "/sso/wipro/m3p",
    pharmacyFormsSSO: () => "/sso/prime/forms",
    homeDeliverySSO: () => "/sso/prime/homedelivery",
    homeDeliveryCvsSSO: () => "/sso/cvs/homedelivery",
    cisPharmacySSO: () => "/sso/express-scripts/default",

    // Telehealth SSO
    mdliveSSO: () => "/sso/mdlive/default",
    doctorOnDemandSSO: () => "/sso/doctors-on-demand/default",

    // Babywise
    babyWise: () => "/member/member-dashboard/pregnancy-program",
    bump2Baby: () => "/member/member-dashboard/bump2baby",
    bump2BabyBSH: () => "/member/use/maternity",
    winFertility: (path: string) => "https://managed.winfertility.com/" + path,

    pregnancy: () => "/care/care-resources/pregnancy",
    // Nurse chat
    memberAdvice24: () => "/member/programs/health-support-services/advice24",
    medicareAdvice24: () =>
        "/medicare/programs/health-support-services/advice24",
    memberAdviceThirdParty: () => "/member/advice24-third-party-privacy",

    // Special
    redirectSignin: (path: string) => "/redirect-signin?redirect=" + path,
    careManagementResource: (brand: string) =>
        brand === "regence"
            ? "/api/web/member/programs/health-support-services/regence-case-management"
            : brand === "asuris"
            ? "/api/web/member/programs/health-support-services/case-management"
            : "/api/web/member/use/case-management",

    // Care gaps
    managingYourDiabetes: () =>
        "/care-gaps/7a659bee-4fdf-41e0-96cb-c9d139d88248",

    // Pharmacy CVS
    pharmacyCvsSSO: () => "/sso/cvs/benefits",

    // Rx Pharmacies
    pharmacies: () => "/pharmacies",

    // UMP resources
    umpHealthPEBB: () => "https://ump.regence.com/pebb/benefits/programs",
    umpHealthSEBB: () => "https://ump.regence.com/sebb/benefits/programs",
    umpBenefitsPEBB: () => "https://ump.regence.com/pebb",
    umpBenefitsSEBB: () => "https://ump.regence.com/sebb",
    umpVisionSEBB: () =>
        "https://www.hca.wa.gov/employee-retiree-benefits/school-employees/vision-plans-and-benefits",

    // Moda SSO
    modaSSO: () => "/sso/moda/default",

    //Healthy Benefits resources
    healthyBenefits: () =>
        "https://www.regence.com/go/cis/healthy-eating-weight-management-overview",
    healthyBenefitsForm: () =>
        "https://beonbrand.getbynder.com/m/215f93a5a8c77966/original/HE-Reimbursement-Form-2022.pdf",

    // Behavioral health resources
    findRightCare: (region, brand) => {
        const bluUrls = {
            CC: "https://beonbrand.getbynder.com/m/2063f415a9628d1d/original/REG-CC-933680-22-BH-User-Guide.pdf",
            ID: "https://beonbrand.getbynder.com/m/2f478085f15461c3/original/REG-ID-933680-22-BH-User-Guide.pdf",
            UT: "https://beonbrand.getbynder.com/m/4058d69872a7bc23/original/REG-UT-933680-22-BH-User-Guide.pdf",
            WA: "https://beonbrand.getbynder.com/m/749421efce7dd348/original/REG-WA-933680-22-BH-User-Guide.pdf",
            EW: "https://beonbrand.getbynder.com/m/749421efce7dd348/original/REG-WA-933680-22-BH-User-Guide.pdf",
            WW: "https://beonbrand.getbynder.com/m/749421efce7dd348/original/REG-WA-933680-22-BH-User-Guide.pdf",
            OR: "https://beonbrand.getbynder.com/m/412b23b0ef504e5c/original/REG-OR-933680-22-BH-User-Guide.pdf",
        }
        const bshUrls = {
            UT: "https://beonbrand.getbynder.com/m/456071d7b519d0b0/original/BSH-933680-22-BH-User-Guide.pdf",
            WA: "https://beonbrand.getbynder.com/m/78d625c392ec23b2/original/BSH-WA-933680-22-BH-User-Guide.pdf",
            OR: "https://beonbrand.getbynder.com/m/456071d7b519d0b0/original/BSH-933680-22-BH-User-Guide.pdf",
        }
        const rightCareUrl = {
            regence: bluUrls[region],
            bridgespan: bshUrls[region],
            asuris: "https://beonbrand.getbynder.com/m/43d97c78d231895e/original/ANH-933680-22-BH-User-Guide.pdf",
        }
        return rightCareUrl[brand]
    },
    medicalProvider: (domain: string, brand: string) =>
        `https://${domain}.${brand}.com/auth/realms/${brand
            .charAt(0)
            .toUpperCase()}${brand.slice(
            1,
        )}-Medical/protocol/saml/clients/hsq` + MEDICAL_PROVIDER_QUERY_PARAMS,
    medicalEndocrinology: (domain: string, brand: string) =>
        `https://${domain}.${brand}.com/auth/realms/${brand
            .charAt(0)
            .toUpperCase()}${brand.slice(
            1,
        )}-Medical/protocol/saml/clients/hsq` + MEDICAL_ENDOCRINOLOGY_QUERY_PARAMS,
    medicalOptometrist: (domain: string, brand: string) =>
        `https://${domain}.${brand}.com/auth/realms/${brand
            .charAt(0)
            .toUpperCase()}${brand.slice(
            1,
        )}-Medical/protocol/saml/clients/hsq` + MEDICAL_OPTOMETRIST_QUERY_PARAMS,
    medicalOphthalmologist: (domain: string, brand: string) =>
        `https://${domain}.${brand}.com/auth/realms/${brand
            .charAt(0)
            .toUpperCase()}${brand.slice(
            1,
        )}-Medical/protocol/saml/clients/hsq` + MEDICAL_OPHTHALMOLOGIST_QUERY_PARAMS,
    medicalNephrology: (domain: string, brand: string) =>
        `https://${domain}.${brand}.com/auth/realms/${brand
            .charAt(0)
            .toUpperCase()}${brand.slice(
            1,
        )}-Medical/protocol/saml/clients/hsq` + MEDICAL_NEPHROLOGY_QUERY_PARAMS,
    mentalHealthProvider: (domain: string, brand: string) =>
        `https://${domain}.${brand}.com/auth/realms/${brand
            .charAt(0)
            .toUpperCase()}${brand.slice(
            1,
        )}-Medical/protocol/saml/clients/hsq` + MENTAL_HEALTH_QUERY_PARAMS,
    virtualCareProvider: (domain: string, brand: string) =>
        `https://${domain}.${brand}.com/auth/realms/${brand
            .charAt(0)
            .toUpperCase()}${brand.slice(
            1,
        )}-Medical/protocol/saml/clients/hsq` + VIRTUAL_CARE_QUERY_PARAMS,
    reginaImaging: (domain: string, brand: string, city: string, state: string) =>
        `https://${domain}.${brand}.com/auth/realms/${brand
            .charAt(0)
            .toUpperCase()}${brand.slice(
            1,
        )}-Medical/protocol/saml/clients/hsq` + REGINA_IMAGING_QUERY_PARAMS + `&location=${city}%2C%20${state}`,
    lifeLine: () => "https://988lifeline.org",
    nationalEatingDisorders: () =>
        "https://www.nationaleatingdisorders.org/help-support/contact-helpline",
    huntsmanMentalHealthProvider: () => "https://apps.med.utah.edu/site/hmhibhn/a/search.aspx",
    // Beyondwell SSO
    beyondWellSSO: () => "/sso/beyondwell/default",

    // Managing your diabetes
    meetProvider: () =>
        "https://www.healthwise.net/onlifehealth/Content/StdDocument.aspx?DOCHWID=hw135189#uq1460",
    checkDiabetes: () =>
        "https://www.healthwise.net/onlifehealth/Content/StdDocument.aspx?DOCHWID=abo2883",
    insulinPump: () =>
        "https://www.healthwise.net/onlifehealth/Content/StdDocument.aspx?DOCHWID=zx3069#zx3070",

    // Managing your heart health
    meetCardiacProvider: () =>
        "https://www.healthwise.net/onlifehealth/Content/StdDocument.aspx?DOCHWID=hw113087#hw113228",
    completeTesting: () =>
        "https://www.healthwise.net/onlifehealth/Content/StdDocument.aspx?DOCHWID=abp5470",
    followUpCardiologist: () =>
        " https://www.healthwise.net/onlifehealth/Content/StdDocument.aspx?DOCHWID=abo5519",
    completeImaging: () =>
        "https://www.healthwise.net/onlifehealth/Content/StdDocument.aspx?DOCHWID=aba5713",
    followUpDoctor: () =>
        "https://www.healthwise.net/onlifehealth/Content/StdDocument.aspx?DOCHWID=abn3472",

    // Account Settings
    accountInformation: () => "/account-settings/account-information",
    accountInformationSuccess: () =>
        "/account-settings/account-information?success=true",
    accountInformationResent: () =>
        "/account-settings/account-information?resent=true",
    accountInformationChangeEmail: () =>
        "/account-settings/account-information?emailchange=true",
    accountFinances: () => "/account-settings/finances",
    accountPreferences: () => "/account-settings/preferences",
    accountFamilySharing: () => "/account-settings/family-sharing",
    accountDataSharing: () => "/account-settings/data-and-privacy",
    accountLettersNotices: () => "/account-settings/letters-and-notices",
    accountPersonalInformation: () => "/account-settings/personal-information",
    allAboutHsa: () => "/member/understanding-health-insurance/all-about-hsas",
    heqHome: () => "http://www.healthequity.com/",
    healthRecordsSharing: (env: string, brand: string) =>
        env === "prd"
            ? `https://privacyportal.fhir.cambia.abacusinsights.io/?connection=${brand}-members`
            : `https://privacyportal.fhir.cambiastg.abacusinsights.io/?connection=${brand}-members`,

    // Benefits Pre-Auth
    commercialPreAuth: () =>
        "/member/member-dashboard/benefits-coverage/commercial-pre-authorization",
    medicarePreAuth: () =>
        "/member/member-dashboard/benefits-coverage/medicare-pre-authorization",
    umpPreAuth: () =>
        "/member/member-dashboard/benefits-coverage/ump-pre-authorization",

    // Fortive Vendor resources
    fortiveLivongoHypertension: () => "http://join.livongo.com/fortive/enter",
    fortiveVirtaDiabetes: () => "http://www.virtahealth.com/join/fortive",
    fortiveProgenyInfertility: () => "http://www.progyny.com/",
    fortiveSpringHealth: () => "http://fortive.springhealth.com/",

    // Renewal shop plans
    renewal: () => "/renewal",
    bcbsa: () => "https://www.bcbs.com/",
    idahoMarketplace: () => "https://www.yourhealthidaho.org",
    oregonMarketplace: () =>
        "https://www.oregon.gov/oha/HSD/OHP/Pages/Apply.aspx",
    utahMarketplace: () => "https://www.healthcare.gov/",
    washingtonMarketplace: () => "https://www.wahealthplanfinder.org/",

    // Over the counter products - deprecated on 1/1/2025
    mybenefitsCenter: () => "https://mybenefitscenter.com/",
    // becomes active on 1/1/2025
    blueFlexDollars: brand =>
        brand === "asuris"
            ? "https://www.asurisflexcard.com"
            : "https://www.blueflexdollars.com",

    //onlife SSO
    onlifeSSO: () => "/sso/onlife/default",

    //Spanish language dashboard
    dashboardES: () => "/dashboard-es",

    //Instamed SSO
    instamedAutoPaymentSSO: () => "/sso/instamed/autopayment",
    instamedNewPaymentSSO: () => "/sso/instamed/newpayment",

    //USAble Dental SSO
    usableSSO: () => "/sso/ucd/default",
    findDentistUsableDental: (props: {
        readonly zip: string
        readonly env: string
        readonly planName?: string
        readonly brandName: string
        readonly subBrand: string
    }) => {
        const baseUrl =
            props.env === "prd"
                ? `https://${props.brandName}dental.com`
                : `https://inprogress.${props.brandName}dental.com`
        const urlPath = "/members/find-a-dentist"
        const planNameParam = props.planName
            ? `&planname=${props.planName}`
            : ""
        const queryParams = `?zip=${props.zip}${planNameParam}&subbrand=${props.subBrand}`

        return `${baseUrl}${urlPath}${queryParams}`
    },
    silverAndFit: () => "https://www.silverandfit.com/",
    omada: () => "https://go.omadahealth.com/omadaforcra",

    // Medicare Care Resources
    walgreenSmartSavings: () =>
        "/medicare/resources/discounts/health-and-wellness-products",
    healthyMeals: () => "/medicare/resources/discounts/healthy-meals",
    alternativeMedicine: () =>
        "/medicare/resources/discounts/alternative-medicine",
    hearingAids: () => "/medicare/resources/discounts/hearing-aids",
    healthyRewardsProgram: () => "https://mybenefitscenter.com/",
    diabetesPrevention: () => "https://www.teladochealth.com/expert-care/condition-management/diabetes-prevention",
    inHomeUrgentCare: (brand, region) => {
        if (brand === "asuris")
            return " https://www.dispatchhealth.com/asuris?utm_source=asuris&utm_medium=referral&utm_campaign=partner-page&utm_content=landing-page&utm_term=18891"
        if (brand === "bridgespan") return "https://www.dispatchhealth.com/"

        if (brand === "regence") {
            if (region === "or")
                return "https://www.dispatchhealth.com/Regence/OR/?utm_source=regence&utm_medium=referral&utm_campaign=partner-page&utm_content=landing-page&utm_term=18891"
            if (region === "ut")
                return " https://www.dispatchhealth.com/Regence/UT/?utm_source=regence&utm_medium=partnerwebsite&utm_campaign=701VK000007DUx7YAG&utm_content=landing-page&utm_term=18891"
            if (region === "ww" || region === "wa" || region === "ew")
                return " https://www.dispatchhealth.com/regence/wa/?utm_source=regence&utm_medium=referral&utm_campaign=partner-page&utm_content=landing-page&utm_term=18891"
            if (region === "id") return "https://www.dispatchhealth.com/"
            //  if (region === "id") return "https://www.dispatchhealth.com/conditions/";
        }
    },
    signifyHealth: (brand: string) => {
        if (brand === "regence")
            return "http://schedule.signifyhealth.com/home?utm_medium=referral&utm_source=regence-web&utm_campaign=und&utm_content=und&utm_term=und"
        if (brand === "asuris")
            return "http://schedule.signifyhealth.com/home?utm_medium=referral&utm_source=asuris-web&utm_campaign=und&utm_content=und&utm_term=und"
    },
    advantMed: () =>
        "https://myhealthassessment.com/?utm_source=client&utm_medium=website&utm_campaign=cambia ",
}

/**
 * Redirects
 * -----------------------------------------------------------------------------
 */
const Redirects = []

/**
 * Public routes
 * -----------------------------------------------------------------------------
 */
const PublicRoutes = [
    "/experiments",
    Routes.signin(),
    Routes.health(),
    Routes.memberRegistration(),
    Routes.memberHome(),
    // Routes.home(),
    // Routes.faq(),
    // Routes.terms()
    // Routes.privacy(),
]

/**
 * Joint Admin routes
 * -----------------------------------------------------------------------------
 */
const JointAdminRoutes = [
    "/experiments",
    Routes.home(),
    Routes.health(),
    "/404",
    "/adif/[...page]",
    Routes.settings(),
    Routes.nonDiscrimination(),
    Routes.feedback(),
    Routes.fraudAndAbuse(),
    Routes.reportFraudOrAbuse(),
    Routes.ethicsAndCompliance(),
    Routes.memberClaimSubmit(),
    Routes.claimSubmit(),
    Routes.memberCards(),
    "/costs/submit-claim",
    Routes.accountInformation(),
    Routes.accountPersonalInformation(),
    Routes.verifyEmail(),
    Routes.changeEmail(),
    Routes.onboardingCheckYourText(),
    Routes.onboardingChangeYourPhone(),
    Routes.onboardingWelcome(),
]

/**
 * Export default
 * -----------------------------------------------------------------------------
 */
export default Routes
export { useRouter, Routes, PublicRoutes, Redirects, JointAdminRoutes }
