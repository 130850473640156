/**
 * Chat Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import http from "@/utils/http"
import { Message } from "./reducer"

/**
 * Toggle chat
 * -----------------------------------------------------------------------------
 */
export const toggleChat = createAsyncThunk(
    "chat/toggleChat", // Reducer name
    async (params: void, { dispatch }) => {
        // Get current state
        const chat = store.getState().chat

        // Initialize chat (if `pristine`)
        if (chat.status === "pristine") {
            dispatch(actions.initializeChat())
        }

        // Toggle visibility
        dispatch(actions.receiveChat({ ...chat, isVisible: !chat.isVisible }))
    },
)

/**
 * Initialize chat
 * -----------------------------------------------------------------------------
 */
export const initializeChat = createAsyncThunk(
    "chat/initializeChat", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Get current chat store status
         */
        const { status, isChatEnded } = store.getState().chat

        /**
         * Only initialize chat if not already initialized,
         * or if re-initializing (after ending chat)
         */
        if (status !== "completed" || isChatEnded) {
            /**
             * Set loading state
             */
            await dispatch(actions.chatStatus("pending"))

            /**
             * Create chat session
             */
            const res = await http.post(
                "/api/janus/digital-first-chat-service/v1/session",
                null,
            )
            if (res.error || res.status !== 200) {
                console.error(res.error)
                dispatch(actions.chatStatus("error"))
                return
            }

            /**
             * Receive chat session data
             */
            const { chat } = store.getState()
            const pollInterval = res.data?.pollInterval || 0
            const isAgentTyping = res.data?.isAgentTyping || false
            const intentContext = res.data?.meta?.intentContext || ""
            await dispatch(
                actions.receiveChat({
                    ...chat,
                    intentContext: intentContext,
                    pollInterval: pollInterval,
                    isAgentTyping: isAgentTyping,
                    messages: [],
                    isChatEnded: false,
                }),
            )

            /**
             * Receive chat messages
             */
            res.data.messages.forEach(msg =>
                dispatch(actions.receiveChatMessage(msg)),
            )

            /**
             * Set loading state
             */
            await dispatch(actions.chatStatus("completed"))

            /**
             * Start/stop polling (if `pollInterval`)
             */
            await dispatch(actions.handleChatPolling())
        }
    },
)

/**
 * Send message
 * -----------------------------------------------------------------------------
 */
export interface SendMessageProps {
    readonly input: string
    readonly value: string
}
export const sendMessage = createAsyncThunk(
    "chat/sendMessage", // Reducer name
    async (params: SendMessageProps, { dispatch }) => {
        /**
         * Display member message
         */
        await dispatch(
            actions.receiveChatMessage({
                userAlias: "You",
                createdAt: new Date().getTime(),
                text: params.input,
            }),
        )

        /**
         * Chat state
         */
        const { chat } = store.getState()
        const isSynchronous = !chat.pollInterval || chat.pollInterval === 0

        const value = params.value === "retry" ? chat.lastValue : params.value
        const input = params.value === "retry" ? chat.lastInput : params.value

        /**
         * Show `Typing` indicator (for synchronous agents like Lex)
         */
        if (isSynchronous) {
            await dispatch(
                actions.receiveChat({ ...chat, isAgentTyping: true }),
            )
        }

        /**
         * Create chatbot session
         */
        const res = await http.put(
            "/api/janus/digital-first-chat-service/v1/session",
            {
                message: value,
            },
        )

        if (res.error || res.status !== 200) {
            if (res.error) console.error(res.error)
            dispatch(
                actions.receiveChat({
                    ...chat,
                    lastInput: input,
                    lastValue: value,
                }),
            )
            dispatch(
                actions.receiveChatMessage({
                    userAlias: "Bot",
                    createdAt: new Date().getTime(),
                    text: "Failed to send message",
                    buttons: [
                        {
                            label: "Try again",
                            value: "retry",
                        },
                    ],
                }),
            )
            return
        }

        /**
         * Receive chat data
         */
        const pollInterval = res.data?.pollInterval || 0
        const isAgentTyping = res.data?.isAgentTyping || false
        const intentContext = res.data?.meta?.intentContext || ""
        await dispatch(
            actions.receiveChat({
                ...chat,
                lastInput: input,
                lastValue: value,
                intentContext: intentContext,
                pollInterval: pollInterval,
                isAgentTyping: isAgentTyping,
            }),
        )

        /**
         * Receive messages
         */
        const messages = res.data.messages || []
        if (isSynchronous) {
            /**
             * Queue messages for artificial delay
             */
            const delay = 700
            for await (const [index, message] of messages.entries()) {
                // Receive message
                dispatch(actions.receiveChatMessage(message))

                // Delay next message (if another message exists)
                if (messages[index + 1]) {
                    dispatch(actions.receiveChatAgentTyping(true))
                    await new Promise(resolve => setTimeout(resolve, delay))
                    dispatch(actions.receiveChatAgentTyping(false))
                }
            }
        } else {
            /**
             * Immediately receive messages
             */
            res.data.messages.forEach(msg =>
                dispatch(actions.receiveChatMessage(msg)),
            )
        }

        /**
         * Start/stop polling (if `pollInterval`)
         */
        await dispatch(actions.handleChatPolling())
    },
)

/**
 * Get messages
 * -----------------------------------------------------------------------------
 */
export const getMessages = createAsyncThunk(
    "chat/getMessages", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Get current chat store status
         */
        const { status } = store.getState().chat

        /**
         * Only request messages if chat is initialized (`completed`)
         */
        if (status === "completed") {
            /**
             * Set polling state
             */
            await dispatch(
                actions.receiveChat({
                    ...store.getState().chat,
                    isPolling: true,
                }),
            )

            /**
             * Get messages from chat service
             */
            const res = await http.get(
                "/api/janus/digital-first-chat-service/v1/session",
            )

            /**
             * Update chat state
             */
            const pollInterval = res.data?.pollInterval || 0
            const isAgentTyping = res.data?.isAgentTyping || false
            const intentContext = res.data?.meta?.intentContext || ""
            await dispatch(
                actions.receiveChat({
                    ...store.getState().chat,
                    isAgentTyping,
                    pollInterval,
                    intentContext,
                }),
            )

            /**
             * Receive chat service messages
             */
            const messages: ReadonlyArray<Message> = res.data?.messages || []
            messages?.forEach(message => {
                dispatch(actions.receiveChatMessage(message))
            })

            /**
             * Set polling state
             */
            await dispatch(
                actions.receiveChat({
                    ...store.getState().chat,
                    isPolling: false,
                }),
            )

            /**
             * Start/stop polling (if `pollInterval`)
             */
            await dispatch(actions.handleChatPolling())

            /**
             * Listen for `End chat` from InContact agent
             */
            const regex = /agent has left chat/i
            const agentEndMessages = messages.filter(m => regex.test(m.text))
            if (agentEndMessages.length) {
                await dispatch(actions.endChat())
            }
        }
    },
)

/**
 * Handle Polling
 * -----------------------------------------------------------------------------
 */
export const handleChatPolling = createAsyncThunk(
    "chat/handleChatPolling", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Get chat state
         */
        const { pollInterval, isPolling } = store.getState().chat

        /**
         * Kick off another poll for messages
         */
        if (pollInterval && !isPolling) {
            await dispatch(actions.getMessages())
        }
    },
)

/**
 * End chat session
 * -----------------------------------------------------------------------------
 */
export const endChat = createAsyncThunk(
    "chat/endChat", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Show end chat message
         */
        const chat = store.getState().chat
        dispatch(actions.receiveChat({ ...chat, isChatEnded: true }))

        /**
         * End chatbot session
         */
        const res = await http.destroy(
            "/api/janus/digital-first-chat-service/v1/session",
        )
        if (res.error) {
            console.error(res.error)
        }
    },
)
