import * as React from "react"
import { SVGProps } from "react"
const SvgScreen = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Computer screen"
        viewBox="0 0 26 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M18 22.0002C18.5523 22.0002 19 21.5525 19 21.0002C19 20.4479 18.5523 20.0002 18 20.0002V22.0002ZM7 20.0002C6.44772 20.0002 6 20.4479 6 21.0002C6 21.5525 6.44772 22.0002 7 22.0002V20.0002ZM2 5.00018C2 3.89561 2.89543 3.00018 4 3.00018V1.00018C1.79086 1.00018 0 2.79104 0 5.00018H2ZM4 3.00018H22V1.00018H4V3.00018ZM22 3.00018C23.1046 3.00018 24 3.89561 24 5.00018H26C26 2.79104 24.2091 1.00018 22 1.00018V3.00018ZM24 5.00018V14.0002H26V5.00018H24ZM24 14.0002C24 15.1048 23.1046 16.0002 22 16.0002V18.0002C24.2091 18.0002 26 16.2093 26 14.0002H24ZM22 16.0002H4V18.0002H22V16.0002ZM4 16.0002C2.89543 16.0002 2 15.1048 2 14.0002H0C0 16.2093 1.79086 18.0002 4 18.0002V16.0002ZM2 14.0002V5.00018H0V14.0002H2ZM11.5 16.5002V21.5002H13.5V16.5002H11.5ZM18 20.0002H7V22.0002H18V20.0002Z"
            fill="currentColor"
        />
    </svg>
)
export default SvgScreen
