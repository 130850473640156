import * as React from "react"
import { SVGProps } from "react"
const SvgPhone = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Phone" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M23 17.5V20.8C23 22 22 23 20.8 23C20.7 23 20.7 23 20.6 23C17.2 22.6 13.9 21.5 11.1 19.6C8.40005 17.9 6.20005 15.6 4.50005 13C2.60005 10.1 1.50005 6.8 1.10005 3.4C0.900049 2.2 1.80005 1.1 3.00005 1C3.10005 1 3.10005 1 3.20005 1H6.50005C7.60005 1 8.60005 1.8 8.70005 2.9C8.90005 4 9.10005 5 9.50005 6C9.80005 6.8 9.60005 7.7 9.00005 8.3L8.14013 9.15991C7.82126 9.47879 7.75286 9.97328 7.99283 10.3551C9.42756 12.6379 11.339 14.5515 13.5495 15.9184C13.9293 16.1532 14.4177 16.0823 14.7334 15.7666L15.6 14.9C16.2 14.3 17.1 14.1 17.9 14.4C18.9 14.8 19.9 15 21 15.2C22.2 15.4 23 16.4 23 17.5Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgPhone
