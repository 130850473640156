import * as React from "react"
import { SVGProps } from "react"
const SvgLinked = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Linked chain"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9.65254 12.6025L11.2789 14.2289C12.4103 15.3603 14.4609 15.0067 15.8751 13.5925L19.8349 9.63269C21.3198 8.14777 21.5319 6.09716 20.4713 5.0365L18.8449 3.41015C17.855 2.27878 15.7337 2.56163 14.3194 3.97584L12.481 5.81432M14.3194 11.3297L12.6931 9.7034C11.5617 8.57203 9.51112 8.92559 8.09691 10.3398L4.0664 14.3703C2.58148 15.7138 2.29863 17.8351 3.35929 18.8958L4.98564 20.5221C6.11701 21.6535 8.23833 21.3707 9.65254 19.9565L11.491 18.118"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgLinked
