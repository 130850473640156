import { Insight } from "@/store/insights/types"
import { transformTreatmentsApiData } from "../treatments/utils"
import { Opportunity } from "./types"
import Routes from "@/router"

export const transformOpportunityApiData = (
    opportunity: any, // eslint-disable-line
    medicationTreatmentId: string,
    insight: Insight
): Opportunity => {   
    // TODO: This should be &start=medicine_cabinet, not home
    const campaignLink = insight ? buildCampaignUrl(insight) + "&start=medications" : "" 
    return {
        id: opportunity?.id,
        firstName: opportunity?.firstName,
        lastName: opportunity?.lastName,
        pharmacyName: opportunity?.pharmacyName,
        pharmacyNumber: opportunity?.pharmacyNumber,
        averageCost: opportunity?.averageCost,
        daysSupply: opportunity?.daysSupply,
        providerName: opportunity?.providerName,
        providerNumber: opportunity?.providerNumber,
        rxTreatments: opportunity?.rxTreatments? transformTreatmentsApiData(opportunity.rxTreatments) : undefined,
        campaignMessage: insight?.title,
        medicationTreatmentId: medicationTreatmentId,
        route: campaignLink,
        suffix: opportunity?.suffix,
    }
}

export const buildCampaignUrl = (insight) => {
    const campaignId = insight.action?.campaignId
    const campaignRoute = insight.action?.route
    if (campaignRoute) {
        const campaignRouteUrl = new URL(campaignRoute)
        const oppId = campaignRouteUrl?.searchParams.get("opportunity_id")
        const opportunity_Id = oppId ? `?opportunity_id=${oppId}` : null
        const isCampaignRoute = campaignRoute?.split("/").slice(3, 4)[0] == "campaign"
        const campaignGuid = isCampaignRoute
            ? campaignRoute?.split("/").slice(4, 5)[0].split("?").slice(0, 1)[0]
            : null
        const campaignLink = campaignGuid
            ? Routes.browseCampaign(campaignGuid) + opportunity_Id
            : campaignId
                ? Routes.browseCampaign(campaignId) + opportunity_Id
                : campaignRoute
        return campaignLink
    }
    return ""
}
