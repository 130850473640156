/**
 * Doctors and providers Actions
 * -----------------------------------------------------------------------------
 */
/* eslint-disable no-console */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import { providersQuery } from "./queries"
import { transformProvidersAPIData } from "./utils"
import http from "utils/http"

/**
 * Get doctors and providers data
 * -----------------------------------------------------------------------------
 */
export const fetchProviders = createAsyncThunk(
    "providers/fetch", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        const { providers } = store.getState()
        dispatch(actions.receiveProviders({ ...providers, isLoading: true }))

        /**
         * Request data from digital-first-information-service
         */
        const res = await http.query(
            "/api/digital-first-information-service/graphql",
            providersQuery,
        )

        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            console.error("[Doctors and Providers] Error fetching information.", (res.error || JSON.stringify(res.data?.errors[0]?.message)))
            dispatch(
                actions.receiveProviders({
                    ...providers,
                    isLoading: false,
                    isInitialized: true,
                    errorMessage: "Error fetching doctors and providers information",
                }),
            )
            return
        }

        // Check for no data
        const careSubjects = res.data.data?.careSubjects || {}
        if (Object.keys(careSubjects).length === 0) {
            console.info("[Doctors and Providers] Doctors and providers information not available.")
            dispatch(
                actions.receiveProviders({
                    ...providers,
                    providersByMember: {},
                    isLoading: false,
                    isInitialized: true,
                    errorMessage: "Doctors and providers information not available.",
                }),
            )
            return
        }

        /**
         * Transform data
         */
        const providersByMember = transformProvidersAPIData(careSubjects)

        /**
         * Update state
         */
        dispatch(
            actions.receiveProviders({
                ...providers,
                providersByMember,
                isLoading: false,
                isInitialized: true,
            }),
        )
    },
)
