// defaulting to current year in case the year in coverageEndDate is set to 2199
// which is the case for all active coverages
import {formatDate} from "@/utils/date";
const DEFAULT_YEAR_FOR_ACTIVE_COVERAGE = "2199"

export const getCoverageEndDate = (originalCoverageEndDate, currentYear) => {
    if (!originalCoverageEndDate || !currentYear) {
        return ""
    }
    const coverageEndDate = formatDate(originalCoverageEndDate.slice(0, 10), "MMM. d, yyyy")

    if (coverageEndDate && coverageEndDate.split(" ")[2] != DEFAULT_YEAR_FOR_ACTIVE_COVERAGE) {
        return coverageEndDate
    } else {
        return coverageEndDate.replace(DEFAULT_YEAR_FOR_ACTIVE_COVERAGE, currentYear)
    }
}
