/**
 * Async/Await Helpers for Axios.js
 * -----------------------------------------------------------------------------
 */
import Axios, { AxiosRequestConfig } from "axios"
import AxiosRetry from "axios-retry"
import { getEnvironment } from "."

/**
 * Axios helpers for client-side
 * -----------------------------------------------------------------------------
 */
export interface Response {
    readonly data?: any // eslint-disable-line
    readonly headers?: any // eslint-disable-line
    readonly error?: {
        readonly code: number
        readonly message: string
        readonly fault?: {
            readonly faultstring: string
        }
    }
    readonly responseUrl?: any // eslint-disable-line
    readonly status?: number
    readonly originalError?: Error
}
// eslint-disable-next-line functional/immutable-data
Axios.defaults.timeout= 30 * 1000
// eslint-disable-next-line functional/immutable-data
Axios.defaults.headers.common["platform"] = "web"

//Retrying all the Axios requests with a condition of > 500 status or request timed out 
AxiosRetry( Axios,{
    retries: 2,
    shouldResetTimeout: true, // will reset the timeout of 30s which is default
    retryDelay: (retryCount) =>{
        return retryCount * 500  /* 1st retry will wait for 500ms before retrying the second retry for 1s(1000ms) */
    },
    retryCondition:(error) => {
        return(error.response?.status > 500 || error.code === "ECONNABORTED")
    }
})


/**
 * GET
 * -----------------------------------------------------------------------------
 */
export const get = async (
    endpoint: string,
    config?: AxiosRequestConfig,
): Promise<Response> => {
    return Axios.get(endpoint, config)
        .then(res => {
            return {
                data: res.data,
                headers: res.headers,
                responseUrl: res.request?.res?.responseUrl,
                status: res.status,
            }
        })
        .catch(error => {
            return {
                error: error.response?.data || error?.toString(),
                headers: error.response?.headers || {},
                status: error.response?.status || null,
            }
        })
}

/**
 * POST
 * -----------------------------------------------------------------------------
 */
export const post = async (
    endpoint: string,
    body: any, // eslint-disable-line
    config?: AxiosRequestConfig,
): Promise<Response> => {
    return Axios.post(endpoint, body, config)
        .then(res => {
            return { data: res.data, headers: res.headers, status: res.status }
        })
        .catch(error => {
            return {
                error: error.response?.data || error?.toString(),
                headers: error.response?.headers || {},
                status: error.response?.status || null,
                originalError: error,
            }
        })
}

/**
 * PUT
 * -----------------------------------------------------------------------------
 */
export const put = async (
    endpoint: string,
    body: any, // eslint-disable-line
    config?: AxiosRequestConfig,
): Promise<Response> => {
    return Axios.put(endpoint, body, config)
        .then(res => {
            return { data: res.data, headers: res.headers, status: res.status }
        })
        .catch(error => {
            return {
                error: error.response?.data || error?.toString(),
                headers: error.response?.headers || {},
                status: error.response?.status || null,
            }
        })
}

/**
 * DELETE
 * -----------------------------------------------------------------------------
 */
export const destroy = async (
    endpoint: string,
    config?: AxiosRequestConfig,
): Promise<Response> => {
    return Axios.delete(endpoint, config)
        .then(res => {
            return { data: res.data, headers: res.headers, status: res.status }
        })
        .catch(error => {
            return {
                error: error.response?.data || error?.toString(),
                headers: error.response?.headers || {},
                status: error.response?.status || null,
            }
        })
}

const isBenefitsFault = query => {
    return (
        (query?.includes("benefits") || query?.includes("accumulators")) &&
        getEnvironment() !== "prd" &&
        window &&
        window?.localStorage?.getItem("BAS-fault") === "500"
    )
}

const simulateFault = () => {
    console?.warn("returning a fake response")
    const resp: Response = {
        data: {
            errors: [
                {
                    message: "500: Server Error",
                    extensions: {
                        code: "UNAVAILABLE",
                        response: {
                            url: "https://benefits-accumulator-service.env.janusplatform.io/graphql",
                            status: 500,
                            statusText: "Server Error",
                            body: {
                                schemaVersion: 2.1,
                                error: {
                                    title: "Internal server error",
                                    message:
                                        "We're sorry, we're experiencing technical difficulties at the moment and can not display your benefit information. Try again later or contact health plan customer service for your cost estimate.",
                                },
                            },
                        },
                    },
                },
            ],
            data: {
                benefits: null,
                accumulators: null,
            },
            extensions: {
                requests: [],
            },
        },
        //error: {
        //    code: 200,
        //    message: "Not Available",
        //    fault: { faultstring: "Service not available" },
        //},
        headers: {},
        status: 200,
    }
    return Promise.resolve(resp)
}

/**
 * GraphQL Query
 * -----------------------------------------------------------------------------
 */
export const query = async (
    endpoint: string,
    query: string,
    config?: AxiosRequestConfig,
): Promise<Response> => {
    if (isBenefitsFault(query)) {
        return simulateFault()
    }

    return Axios.post(endpoint, { query }, config)
        .then(res => {
            return { data: res.data, headers: res.headers, status: res.status }
        })
        .catch(error => {
            return {
                error: error.response?.data || error?.toString(),
                headers: error.response?.headers || {},
                status: error.response?.status || null,
            }
        })
}

/**
 * GraphQL Mutation
 * -----------------------------------------------------------------------------
 */
export const mutation = async (
    endpoint: string,
    query: string,
    variables: Record<string | number, string | number | boolean>,
    config?: AxiosRequestConfig,
): Promise<Response> => {
    return Axios.post(endpoint, { query, variables }, config)
        .then(res => {
            return { data: res.data, headers: res.headers, status: res.status }
        })
        .catch(error => {
            return {
                error: error.response?.data || error?.toString(),
                headers: error.response?.headers || {},
                status: error.response?.status || null,
            }
        })
}

/**
 * Export default
 * -----------------------------------------------------------------------------
 */
export default { get, post, put, destroy, query, mutation }
