/* eslint-disable no-console */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions } from "store"
import http from "utils/http"
import { fetchDateValidationQuery } from "./queries"

/**
 * Get claim date validation service
 * -----------------------------------------------------------------------------
 */
export const fetchDateValidation = createAsyncThunk(
    "dateValidationService/fetchDateValidationService", // Reducer name
    async (serviceDate: string, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.dateValidationServiceStatus("pending"))
        /**
         * Request claim date validation service
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            fetchDateValidationQuery(serviceDate)
        )
        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.dateValidationServiceError({
                    response: res,
                }),
            )
        }
        
        const dateValidation = res.data?.data?.claimSubmission?.isValidClaimSubmissionData
        /**
         * Update state
         */
        dispatch(
            actions.receiveDateValidationService({
                dateValidation: dateValidation || {
                    isInputValid: false,
                    validationStatusMessage: ""
                },
            }),
        )
    },
)

