/**
 * GraphQL query to check duplicate
 */
export const fetchDuplicateClaimValidationQuery = (
    payloadValue
) =>`
query {
  claimSubmission {
      isDuplicateClaim(
        subscriberId: "${payloadValue.subscriberId}",
        claimServiceDetails: "${payloadValue.claimServiceDetails}",
        providerName: "${payloadValue.providerName}",
        referringProviderName: "${payloadValue.referringProviderName}",
        referringProviderPhoneNum: "${payloadValue.referringProviderPhoneNum}",
        patientMemberSuffix: "${payloadValue.patientMemberSuffix}",
        claimServiceType: "${payloadValue.claimServiceType}",
        totalAmount: ${payloadValue.totalAmount},
        dateOfService: "${payloadValue.dateOfService}")
  }
}`

