import * as React from "react"
import { SVGProps } from "react"
const SvgMailDocument = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Envelope with letter"
        viewBox="0 0 23 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M19 11.5704V2.56522C19 2.1501 18.842 1.75198 18.5607 1.45844C18.2794 1.16491 17.8978 1 17.5 1H5.5C5.10218 1 4.72064 1.16491 4.43934 1.45844C4.15804 1.75198 4 2.1501 4 2.56522V11.5704M15.308 14.6257L16.779 16.1607M7.69201 14.6257L6.22101 16.1607M14 5H15.5M14 7.75H15.5M20.784 10.0939C20.8944 10.0025 21.0273 9.94546 21.1674 9.92926C21.3075 9.91306 21.4491 9.93838 21.576 10.0023C21.703 10.0663 21.8101 10.1662 21.8851 10.2908C21.96 10.4153 21.9999 10.5593 22 10.7064V20.8282C22 21.2433 21.842 21.6414 21.5607 21.9349C21.2794 22.2285 20.8978 22.3934 20.5 22.3934H2.5C2.10218 22.3934 1.72064 22.2285 1.43934 21.9349C1.15804 21.6414 1 21.2433 1 20.8282V10.7064C1.00012 10.5593 1.03996 10.4153 1.11494 10.2908C1.18993 10.1662 1.29702 10.0663 1.42395 10.0023C1.55088 9.93838 1.69251 9.91306 1.8326 9.92926C1.97268 9.94546 2.10556 10.0025 2.216 10.0939L9.647 16.2442C10.1755 16.6773 10.828 16.9127 11.5 16.9127C12.172 16.9127 12.8245 16.6773 13.353 16.2442L20.784 10.0939ZM7.5 5H10.5V8H7.5V5Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgMailDocument
