/**
 * Timeline Actions
 * -----------------------------------------------------------------------------
 */

import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import http from "utils/http"
import { rxCoverageEligibilityQuery } from "./queries"
import { transformRxCoverageApiData } from "./utils"

export const fetchRxCoverage = createAsyncThunk(
    "coverages", // Reducer name
    async (params: ReadonlyArray<string>, { dispatch }) => {
        const [ndc, selectedMembershipId] = params
        const ndcs = ndc.split(",")
        const account = store.getState().account
        const medications = store.getState().medications
        const familyMembers = medications.familyMembers
        const membershipId = selectedMembershipId || account.family.requesterMembershipId
        const correctSuffix = familyMembers.find(m => m.membershipId === membershipId)?.suffix
        const familyMember = familyMembers.find(item => item.suffix === correctSuffix) 
        const firstName = familyMember?.firstName 
        const lastName = familyMember?.lastName 
        const memberId = account.profile.subscriberId
        const dateOfBirth = familyMember?.birthDate 
        const productType = "representative"

        /**
         * Set loading state
         */
        // TODO: Why doesn't this work? Wipes out state.
        // const { coverages } = store.getState()
        // dispatch(actions.updateCoverages({ ...coverages, isLoading: true }))
        
        dispatch(actions.updateCoverages({ isLoading: true }))

        const query = rxCoverageEligibilityQuery(
            firstName,
            lastName,
            memberId,
            dateOfBirth,
            ndcs,
            productType,
        )

        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            query,
        )

        /**
         * Handle request errors
         */
        if (res.error || res.data.errors || res.data.data?.rxCoverage?.rxCoverageEligibility == null) {
            if (res.error || res.data.errors) {
                const err = res?.error || res.data?.errors[0]?.message 
                console.error(err)
            } else {
                // console.error("rxCoverageEligibility is null")
            }

            return dispatch(
                actions.updateCoverages({
                    isLoading: false,
                    isInitialized: true,
                    errorMessage: "Could not retrieve coverage data",
                }),
            )
        }
        const { coverages } = store.getState()
        const cers = res.data.data?.rxCoverage?.rxCoverageEligibility
        const transformedCers = transformRxCoverageApiData(cers, membershipId)
        const items = [...coverages.items, ...transformedCers]

        /**
         * Update items state
         */
        dispatch(
            actions.updateCoverages({
                items: items,
                isLoading: false,
                isInitialized: true,
            }),
        )
    },
)
