export const opportunityCampaignsQuery = (opportunityIds: ReadonlyArray<string>) =>
  `
  query rxOpportunityCampaigns {
    rxOpportunityCampaigns(oppIds: [${opportunityIds}]) {
      id
      guid
      title
      campaignTitle
      name
      description
      landingPageText
      bannerText
      landingPageMessage
      action
      actionUrl
      categoryKey
      published
      coverage
      benefitKeywords
      disclaimer
      rxOpportunity {
        id
        suffix
        firstName
        lastName
        pharmacyName
        pharmacyNumber
        averageCost
        daysSupply
        providerName
        providerNumber
        rxTreatments {
            id,
            primaryName,
            secondaryName,
            cost,
            costFrequency,
            deliveryMethodName,
            treatmentCategoryName,
            treatmentCategoryDesc,
        }
      }
      rxTreatments {
        totalCount
        edges {
          node {
            id,
            primaryName,
            secondaryName,
            cost,
            costFrequency,
            deliveryMethodName,
            treatmentCategoryName,
            treatmentCategoryDesc,
          }
        }
      }
      rxConditionTreatments {
        edges {
          node {
            id
            score
            grade
            rxTreatment {
              id
              primaryName
              secondaryName
              cost
              treatmentType
              displayOrder
              pharmacistTip
              deliveryMethodName
              treatmentCategoryName
            }
            rxCondition {
              id
              name
              fullName
            }
          }
        }
      }
    }
  }
  `

  export const campaignsQuery =
    `
    query rxCampaigns {
        rxCampaigns {
          totalCount
          edges {
            node {
              id
              guid
              title
              campaignTitle
              name
              description
              landingPageText
              bannerText
              landingPageMessage
              action
              actionUrl
              categoryKey
              published
              coverage
              benefitKeywords
              disclaimer
            }
          }
        }
      }
    `

export const campaignQueryById = (id: string, oppId: string) =>
    `
    query rxCampaigns {
        rxCampaign(id: ${id}, oppId: ${oppId}) {
          id
          guid
          title
          campaignTitle
          name
          description
          landingPageText
          bannerText
          landingPageMessage
          action
          actionUrl
          categoryKey
          published
          coverage
          benefitKeywords
          disclaimer
          rxTreatments {
            totalCount
            edges {
              node {
                id,
                primaryName,
                secondaryName,
                cost,
                costFrequency,
                deliveryMethodName,
                treatmentCategoryName,
                treatmentCategoryDesc,
              }
            }
          }
          rxConditionTreatments {
            edges {
              node {
                id
                score
                grade
                rxTreatment {
                  id
                  primaryName
                  secondaryName
                  cost
                  treatmentType
                  displayOrder
                  pharmacistTip
                  deliveryMethodName
                  treatmentCategoryName
                }
                rxCondition {
                  id
                  name
                  fullName
                }
              }
            }
          }
        }
      }
    `

export const campaignQueryByGuid = (guid: string, oppId: string) =>
    `
    query rxCampaigns {
      rxCampaign(guid: "${guid}", oppId: ${oppId}) {
        id
        guid
        title
        campaignTitle
        name
        description
        landingPageText
        bannerText
        landingPageMessage
        action
        actionUrl
        categoryKey
        published
        coverage
        benefitKeywords
        disclaimer
        rxTreatments {
          totalCount
          edges {
            node {
              id,
              primaryName,
              secondaryName,
              cost,
              costFrequency,
              deliveryMethodName,
              treatmentCategoryName,
              treatmentCategoryDesc,
            }
          }
        }
        rxConditionTreatments {
          edges {
            node {
              id
              score
              grade
              rxTreatment {
                id
                primaryName
                secondaryName
                cost
                treatmentType
                displayOrder
                pharmacistTip
                deliveryMethodName
                treatmentCategoryName
              }
              rxCondition {
                id
                name
                fullName
              }
            }
          }
        }
      }
    }
    `
