/**
 * Search-starts-with reducer
 * -----------------------------------------------------------------------------
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { SearchStartsWithResult } from "./types"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface SearchStartsWithResults {
    // Base props
    readonly isInitialized: boolean
    readonly isLoading: boolean
    readonly errorMessage?: string

    // Typeahead searches possibly in flight, newer return results remove older from list
    readonly aliveQueryStringIDs: ReadonlyArray<string>

    // Resource props
    readonly item: SearchStartsWithResult
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SearchStartsWithResults = {
    isInitialized: false,
    isLoading: false,
    errorMessage: undefined,
    item: undefined,
    aliveQueryStringIDs: [],
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const searchStartsWithResults = createSlice({
    name: "search",
    initialState,
    reducers: {
        receiveSearchStartsWithResults: (
            state: SearchStartsWithResults,
            action: PayloadAction<SearchStartsWithResults>,
        ) => {
            return action.payload
        },
        resetSearchStartsWithResults: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const searchStartsWithActions = { ...searchStartsWithResults.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default searchStartsWithResults.reducer
