/**
 * Claim submission eligible member reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { ClaimHolder } from "./types"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface ClaimHolders {
    readonly claimHolderDetails: ReadonlyArray<ClaimHolder>
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<ClaimHolders> = {
    status: "pristine",
    claimHolderDetails: [],
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const claimHolderDetails = createStatefulSlice({
    name: "claimHolders",
    initialState,
    reducers: {
        receiveClaimHolders: (
            state: SliceState<ClaimHolders>,
            action: PayloadAction<ClaimHolders>,
        ) => {
            return {
                status: "completed",
                claimHolderDetails: action.payload.claimHolderDetails || [],
            }
        },
        resetClaimHolders: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const claimHoldersActions = { ...claimHolderDetails.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default claimHolderDetails.reducer
