/**
 * Cobrowse Actions
 * -----------------------------------------------------------------------------
 */
import { CLASS_GLANCE_SHOW, ID_GLANCE_MODAL_SCRIM, ID_GLANCE_TERMS_MODAL, waitFor } from "@/utils/glance"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import { Cobrowse, CobrowseSessionEndReason } from "./reducer"
import { debugLog } from "./utils"


// QUESTION: Can we create action `initializeCobrowse` that does basically all the stuff that's in glance_include_script.tsx (ie all the useEffects)?

/**
 * Receive cobrowse init update
 * -----------------------------------------------------------------------------
 */
export const receiveCobrowseUpdate = createAsyncThunk(
    "cobrowse/receiveCobrowseUpdate", // Reducer name
    async (cobrowse: Partial<Cobrowse>, { dispatch }) => {
        const oldCobrowse = store.getState().cobrowse
        const updatedCobrowse = {
            status: "completed",
            ...oldCobrowse,
            ...cobrowse
        }
        dispatch(
            actions.receiveCobrowse(updatedCobrowse)
        )
    },
)

/**
 * Receive cobrowse init update
 * -----------------------------------------------------------------------------
 */
export const receiveCobrowseInitialized = createAsyncThunk(
    "cobrowse/receiveCobrowseInitialized", // Reducer name
    async (isInitialized: boolean, { dispatch }) => {
        debugLog("setting cobrowse initialized:", isInitialized)
        const oldCobrowse = store.getState().cobrowse
        const updatedCobrowse = { ...oldCobrowse, isInitialized }
        dispatch(
            actions.receiveCobrowse(updatedCobrowse)
        )
    },
)

/**
 * listens for cobrowse script loaded / globalThis.GLANCE is defined; maybe also globalThis.GLANCE.Cobrowse
 * -----------------------------------------------------------------------------
 */
export const listenForCobrowseScriptLoaded = createAsyncThunk(
    "cobrowse/listenForCobrowseScriptLoaded", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * checks if glance cobrowse script has been loaded & completed initialization
         * ie, globalThis.GLANCE is present, with its critical properties loaded (Presence/Cobrowse)
         */
        const ready = () =>
            !!globalThis.GLANCE && !!globalThis.GLANCE.Presence && !!globalThis.GLANCE.Cobrowse?.Visitor

        debugLog("glance script load check - start - starting interval: 250")
        const glanceCheckInterval = setInterval(() => {
            if (!ready()) {
                debugLog("glance script load check - NOT READY (globalThis.GLANCE not available) - waiting interval: 250")
                return
            }

            debugLog("glance script load check - loaded")
            clearInterval(glanceCheckInterval)
            dispatch(actions.receiveCobrowseUpdate({ isGlanceScriptLoaded: true }))
        }, 250)

    },
)

/**
 * listens for glance terms UI displayed (exists & showing, ie display: block via .glance_show)
 * -----------------------------------------------------------------------------
 * This is a hack, meant to be a stopgap.
 * Ideally, we would have a glance SDK event to listen for instead of this hack. eg:
 * PRES: sending message: {"type":"terms","data":{"status":"displayed"}}
 * ^ listen for this, call dismissSessionEnd() in the event listener
 *
 * Closest thing, still unusable:
 * listen for agent event 'onterms' with 'status==="displayed"'
 * https://help.glance.net/glance-cobrowse/glance-cobrowse-customizing/presence/presence_agent_api/
 * Unfortunately, that event is only in the Agent API -- not Visitor API. Therefore we don't have access. (ie it would be accessible in the code used in salesforce, but not DFW)
 */
export const listenForCobrowseTermsModalDisplay = createAsyncThunk(
    "cobrowse/listenForCobrowseTermsModalDisplay", // Reducer name
    async (params: void, { dispatch }) => {
        // #1. glance terms modal exists?
        await waitForTermsModalExists()
        dispatch(actions.receiveCobrowseUpdate({ isTermsModalExists: true }))

        // #2. glance terms modal is showing?
        listenForTermsModalClassChanges({
            setTermsModalShowing: (showing: boolean) => dispatch(actions.receiveCobrowseTermsModalShowing(showing))
        })
    },
)

/**
 * Resolves once glance terms modal exists in the dom
 */
const waitForTermsModalExists = async () => {
    const doesTermsModalExist = () =>
        !!document.getElementById(ID_GLANCE_TERMS_MODAL)

    // to debug, add `name: "glance terms modal",`
    await waitFor({ predicate: doesTermsModalExist, timeout: 500 })

    // terms modal now exists in the dom at this point
}

const listenForTermsModalClassChanges = ({ setTermsModalShowing }: { readonly setTermsModalShowing: (showing: boolean) => void }) => {
    // validate - check existence, b/c none of this works w/o at least the terms modal existing
    if (!document.getElementById(ID_GLANCE_TERMS_MODAL)) {
        console.error("glance: ERROR: terms modal div DOES NOT exist")
        return
    }

    // this starts listening for class changes on the terms modal
    addTermsModalMutationObserver({ setTermsModalShowing })

    // first check -- is it currently showing?
    const target = document.getElementById(ID_GLANCE_TERMS_MODAL)
    const classes = target.className.split(" ")
    const showing = classes.includes(CLASS_GLANCE_SHOW)
    setTermsModalShowing(showing)
}

const addTermsModalMutationObserver = ({ setTermsModalShowing }: { readonly setTermsModalShowing: (showing: boolean) => void }) => {
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutationRecord) => {
            const { target } = mutationRecord
            // for some reason, gets a TS error on 'className' not existing on type 'Node' (but the code works)
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const classes = target.className.split(" ")

            const showing = classes.includes(CLASS_GLANCE_SHOW)
            setTermsModalShowing(showing)
        })
    })

    const target = document.getElementById(ID_GLANCE_TERMS_MODAL)

    // See docs for MutationObserver: https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver/observe#attributefilter
    observer.observe(target, {
        attributes: true,
        attributeFilter: ["class"]
    })
}


/**
 * cobrowse terms modal display change, either to or from "showing" (display:block)
 * -----------------------------------------------------------------------------
 */
export const receiveCobrowseTermsModalShowing = createAsyncThunk(
    "cobrowse/receiveCobrowseTermsModalShowing", // Reducer name
    async (showing: boolean, { dispatch }) => {
        dispatch(
            actions.receiveCobrowseUpdate({
                isGlanceTermsModalShowing: showing,
            })
        )
    },
)

/**
 * cobrowse session end event received, info saved temporarily
 * -----------------------------------------------------------------------------
 */
export const receiveCobrowseSessionEnd = createAsyncThunk(
    "cobrowse/receiveCobrowseSessionEnd", // Reducer name
    async (params: { readonly reason: CobrowseSessionEndReason }, { dispatch }) => {
        dispatch(
            actions.receiveCobrowseUpdate({
                lastSessionEnd: {
                    reason: params.reason,
                    time: new Date().toISOString(),
                    notified: false,
                }
            })
        )
    },
)

/**
 * user was notified about a cobrowse session end event
 * -----------------------------------------------------------------------------
 */
export const receiveCobrowseSessionEndNotified = createAsyncThunk(
    "cobrowse/receiveCobrowseSessionEndNotified", // Reducer name
    async (params: void, { dispatch }) => {
        const oldCobrowse = store.getState().cobrowse
        dispatch(
            actions.receiveCobrowseUpdate({
                lastSessionEnd: {
                    ...oldCobrowse.lastSessionEnd,
                    notified: true
                }
            })
        )
    },
)