/**
 * Remap legacy icon names
 * -----------------------------------------------------------------------------
 */
import { IconTypes as Current } from "../components/types"
import { IconTypes as Legacy } from "./types"

export const remapped: Record<Legacy, Current> = {
    "address-card-alt": "card-person",
    "address-card": "card-person",
    advice: "person-nurse",
    "angle-down-md": "chevron-down-md",
    "angle-down-sm": "chevron-down-sm",
    "angle-left-sm": "chevron-left-sm",
    "angle-left": "chevron-left-lg",
    "angle-right-md": "chevron-right-md",
    "angle-right-sm": "chevron-right-sm",
    "angle-right": "chevron-right-lg",
    "angle-up-md": "chevron-up-md",
    "angle-up-sm": "chevron-up-sm",
    apple: "apple",
    "arrow-down": "arrow-down",
    "arrow-up": "arrow-up",
    ban: "no-symbol",
    bars: "bars",
    bell: "bell",
    briefcase: "briefcase",
    building: "facility",
    calculator: "chart",
    calendar: "calendar",
    camera: "camera",
    "chart-bar": "bar-chart",
    chart: "chart",
    chat: "message-bubble",
    "check-circle-alt": "check-circle-alt",
    "check-circle": "check-circle",
    check: "check",
    circle: "info-circle",
    claims: "document-money",
    "clipboard-pulse": "clipboard-pulse",
    clipboard: "clipboard",
    clock: "clock",
    coffee: "warning", // Deprecated
    cog: "gear",
    computer: "screen",
    "dollar-bill-circle": "money-circle",
    "dollar-bill": "money",
    "dollar-sign-circle": "money-circle",
    dots: "ellipsis",
    download: "download",
    "envelope-alt": "mail",
    envelope: "mail",
    "external-link": "arrow-top-right",
    "eye-hidden": "eye-slash",
    eye: "eye",
    faceid: "faceid",
    "facility-circle": "warning", // Deprecated
    familytree: "tree",
    file: "document",
    filter: "filter",
    flag: "flag",
    flask: "beaker",
    folder: "folder",
    glasses: "glasses",
    globe: "globe",
    "heart-24": "heart",
    "heart-check": "heart-check",
    "heart-pulse": "heart-pulse",
    heart: "heart",
    history: "arrow-path-clock",
    home: "house",
    hospital: "hospital",
    "info-circle-sm": "info-circle-sm",
    "info-circle": "info-circle",
    "inhaler-alt": "inhaler", // Deprecated
    inhaler: "inhaler",
    lightbulb: "lightbulb",
    linked: "linked",
    "location-plus": "pin-plus",
    lock: "lock",
    lotion: "tube",
    lungs: "lungs",
    "magnifine-glass-circle": "magnify-plus", // Deprecated
    mail: "mail",
    "map-location": "navigation-arrow-circle",
    "map-marker-check": "pin", // Deprecated
    "map-marker-plus": "pin-plus",
    "map-marker": "pin",
    "medication-search": "pills", // Deprecated
    megaphone: "megaphone",
    "member-id-card": "mail-document",
    "minus-circle": "minus-circle",
    minus: "minus",
    missing: "document-x",
    "money-circle": "money-circle",
    "money-cloud": "money-cloud",
    "money-document": "document-money",
    money: "money",
    moon: "moon",
    "mortar-pestle": "warning", // Deprecated
    note: "note-sm",
    "notes-lg": "note",
    overview: "mosaic",
    "paper-heart": "document-heart",
    paperclip: "paperclip",
    paperwork: "document",
    "pencil-square": "pencil-square",
    pencil: "pencil-square", // Deprecated
    phone: "phone",
    "photo-plus": "camera-plus",
    photos: "photo",
    "pill-bottle-alt": "pills", // Deprecated
    "pill-bottle": "pills", // Deprecated
    pills: "pills",
    plant: "plant",
    "plus-circle": "plus-circle",
    plus: "plus",
    printer: "printer",
    "question-circle": "question-circle",
    reload: "arrow-path",
    reminder: "clock-alarm",
    rx: "rx",
    "search-check": "magnify-check",
    "search-plus-alt": "magnify-plus", // Deprecated
    "search-plus": "magnify-plus",
    search: "magnify",
    sent: "paperplane",
    "share-alt": "share-alt",
    share: "share",
    sort: "arrow-opposites",
    spinner: "spinner",
    spray: "spray-pump",
    star: "star",
    starfill: "star", // Deprecated
    stethoscope: "stethoscope",
    stop: "no-symbol",
    sun: "sun",
    syringe: "syringe",
    "tier-one": "warning", // Deprecated
    "tier-three": "warning", // Deprecated
    "tier-two": "warning", // Deprecated
    "times-circle": "x-circle",
    "times-md": "x",
    "times-sm": "x-sm",
    times: "x-lg",
    tooth: "tooth",
    touchid: "touchid",
    trash: "trash",
    "urgent-care": "heart",
    "user-doctor": "person-doctor",
    user: "person",
    users: "person-family",
    "video-bubble": "message-bubble-video",
    "warning-alt": "warning", // Deprecated
    warning: "warning",
}
