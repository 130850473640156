import * as React from "react"
import { SVGProps } from "react"
const SvgStopwatchHeart = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Stopwatch with heart"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9.5928 1.05377L14.3208 1.06097M12 3.47776L12.0024 1.12576M12 4.50018V5.50018M21 13.2313L20 13.2289M11.976 22.0002L11.9784 21.0002M3 13.2073H4M19.392 4.1474L20.9808 5.7386M15.3162 10.6641C14.4023 9.78735 12.9189 9.78493 12.0025 10.6593C11.0887 9.78251 9.60525 9.78008 8.68887 10.6544C7.77249 11.5288 7.76996 12.9481 8.68381 13.8249L11.9924 17.0002L15.3111 13.8346C16.2275 12.9602 16.23 11.5409 15.3162 10.6641ZM21.7056 13.2554C21.6984 18.617 17.3472 22.9538 11.988 22.9466C6.62879 22.9394 2.28959 18.5882 2.29679 13.229C2.30399 7.86976 6.65519 3.53056 12.0144 3.53776C17.376 3.54496 21.7128 7.89616 21.7056 13.2554Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgStopwatchHeart
