import * as React from "react"
import { SVGProps } from "react"
const SvgBars = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Three horizontal bars"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M20 4H4M20 12H4M20 20H4" stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
    </svg>
)
export default SvgBars
