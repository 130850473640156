/* eslint-disable functional/immutable-data */
import Router from "next/router"
import DOMPurify from "dompurify"
import Routes from "@/router"
import janusLogger from "@/utils/logger"
import { Features, FetchBrandAndRegionRequestParams, OverrideFeatureFlag } from "@/store/app/types"
import {
    filterByDisplayRules,
    transformValidRulesResponseData,
    ValidRulesResponseData,
} from "@/utils/display-rules"
import {
    consumerFeatureConfig,
    displayFeatureConfig,
    featureToggleConfig,
    overrideFeatureFlags,
} from "utils/features"
import { NewConsumerRulesResponseData } from "utils/consumer-rules"
import { AppModelFeatureTogglesResponseData } from "@/utils/feature-toggles"
import { getServiceBaseUrl } from "@/utils/get-service-base-url"
import http, { Response } from "@/utils/http"
import { fetchBrandAndRegionQuery } from "./queries"
import createError from "http-errors"

/**
 * Return a frontend friendly object of `{ [featureName: string]: boolean }`
 * based on display-rule results (and in the future other EMS results).
 *
 * @todo Can EMS handle this transformation instead?
 */
export const transformDisplayRulesToFeatures = (
    validRulesResponseData: ValidRulesResponseData,
) => {
    const displayRuleById = transformValidRulesResponseData(
        validRulesResponseData,
    )

    const features = Object.keys(displayFeatureConfig).reduce<Features>(
        (accFeatureMap, feature) => {
            const config = displayFeatureConfig[feature]

            const flag = filterByDisplayRules(
                {
                    allowRules: config.allowRules,
                    denyRules: config.denyRules,
                    oneOfRules: config.oneOfRules,
                },
                displayRuleById,
            )

            accFeatureMap[feature] = flag
            return accFeatureMap
        },
        {},
    )

    return features
}

/**
 * Return a frontend friendly object of `{ [featureName: string]: boolean }`
 * based on experience-rules results (and in the future other EMS results).
 *
 * @todo Can EMS handle this transformation instead?
 */
export const transformConsumerRulesToFeatures = (
    validConsumerRulesResponseData: NewConsumerRulesResponseData,
) => {
    try {
        const entries = Object.entries(consumerFeatureConfig)

        //janusLogger.debug({
        //    message: "consumerFeatureConfig entries",
        //    entries,
        //    validConsumerRulesResponseData,
        //})
        const features = entries?.reduce<Features>(
            (featureMap, [feature, consumerRule]) => {
                //janusLogger.debug({message: "mapping this feature", feature, consumerRule})
                if (
                    validConsumerRulesResponseData?.result?.[
                        consumerRule.category
                    ]?.[consumerRule.name] == undefined
                ) {
                    console.warn(
                        "Missing result for consumer rule " +
                            feature +
                            " [" +
                            consumerRule.category +
                            "//" +
                            consumerRule.name +
                            "]",
                    )
                }
                return {
                    [feature]:
                        validConsumerRulesResponseData?.result?.[
                            consumerRule?.category
                        ]?.[consumerRule?.name],
                    ...featureMap,
                }
            },
            {},
        )

        //janusLogger.debug({message: "Got features", features})

        return features || {}
    } catch (err) {
        janusLogger.error({
            message: "Failed to transformConsumerRulesToFeatures",
            err,
        })
        return {}
    }
}

/**
 *
 * @param appModelFeatureTogglesResponseData
 * @param req
 * @returns
 */
export const transformAppModelFeatureTogglesToFeatures = (
    appModelFeatureTogglesResponseData: AppModelFeatureTogglesResponseData,
) => {
    try {
        const entries = Object.entries(featureToggleConfig)

        const features = entries?.reduce<Features>((featureMap, [feature]) => {
            return {
                /**
                 * `appModelFeatureToggleCookie` will be fetched in DFARS
                 */

                [feature]:
                    appModelFeatureTogglesResponseData?.application?.[feature]
                        ?.box?.value,
                ...featureMap,
            }
        }, {})
        return features || {}
    } catch (err) {
        janusLogger.error({
            message: "Failed to transformAppModelFeatureTogglesToFeatures",
            err,
        })
        return {}
    }
}

/**
 * Transforms overridden feature flags from LocalStorage to
 * front-end friendly features form `{ [featureName: string]: boolean }`.
 * Feature flags can be overriden through `{{dfw-server}}/dev/landing`
 *
 */
export const transformOverrideFeatureFlagsToFeatures = () => {
    const features = overrideFeatureFlags
        .filter(feature => window?.localStorage?.getItem(feature))
        .reduce((featureMap, feature) => {
            return {
                [feature]: (
                    JSON.parse(
                        window?.localStorage?.getItem(feature),
                    ) as OverrideFeatureFlag
                ).overrideValue,
                ...featureMap,
            }
        }, {})
    return features || {}
}

export const checkOnboarding = async (data, dfwMyAccountEnabled,  isJointAdmin=false, isAllowedPaperlessPrefs=true) => {
    const pathToDestination = DOMPurify.sanitize(
        typeof Router.query?.destination === "string"
            ? Router.query.destination
            : Router.pathname,
    )
    const pending = data.userMeta.pending || {}
    const env = data.envs?.regence || "www-dev1"
    const brand =
        data.userMeta.brand === "bridgespan"
            ? "bridgespanhealth"
            : data.userMeta.brand || "regence"
    const host = `https://${env}.${brand}.com`

    /**
     * Redirect to ADIF email activation if not DF Account Enabled
     */
    if (pending.email && !dfwMyAccountEnabled) {
        return Router.push(host + "/member/activation/")
    }

    if (
        pending.email &&
        dfwMyAccountEnabled &&
        Router.pathname !== Routes.verifyEmail()
    ) {
        if (pathToDestination === Routes.home() || pathToDestination === Routes.verifyEmail()) {
            return Router.push(Routes.verifyEmail())
        }
        return Router.push(`${Routes.verifyEmail()}?destination=${pathToDestination}`)

    }

    /**
     * Redirect to SMS activation
     */
    if (pending.sms && !pending.email) {
        if (pathToDestination === Routes.home() || pathToDestination === Routes.onboardingCheckYourText()) {
            return Router.push(Routes.onboardingCheckYourText())
        }
        return Router.push(`${Routes.onboardingCheckYourText()}?destination=${pathToDestination}`)
    }

    /**
     * Redirect to paperless settings
     */
    if (!isJointAdmin){
        if (pending.paperless && isAllowedPaperlessPrefs && !pending.email) {
            if (pathToDestination === Routes.home() || pathToDestination === Routes.onboardingGoPaperless()
                || pathToDestination === Routes.onboardingCheckYourText()) {
                return Router.push(Routes.onboardingGoPaperless())
            }
            return Router.push(`${Routes.onboardingGoPaperless()}?destination=${pathToDestination}`)
        }

        /**
         * Redirect to keep-informed/communications-preferences page
         */
        if (pending.communication && !pending.email) {
            if (pathToDestination === Routes.home() || pathToDestination === Routes.onboardingCommPreferences()
                || pathToDestination === Routes.onboardingKeepInformed()
                || pathToDestination === Routes.onboardingCheckYourText() || pathToDestination === Routes.onboardingGoPaperless()) {
                return Router.push(Routes.onboardingKeepInformed())
            }
            return Router.push(`${Routes.onboardingKeepInformed()}?destination=${pathToDestination}`)
        }
    }

    /**
     * No redirects for onboarding required so return false so calling component can continue on to destination
     */
    return Promise.resolve(false);
}

/**
 * Fetches attributes like region, brandFamily, brandCode from member-policy-service
 * through graphql query
 * @returns graphql response
 */
export const fetchAttributesFromMPS= async ({
    janusToken,
    adifToken,
    requestId,
}: FetchBrandAndRegionRequestParams): Promise<Response> => {
    try {
        const dfisUrl = `${getServiceBaseUrl("digital-first-information-service")}/graphql`
        
        const dfisHeaders = {
            authorization: "Bearer " + janusToken,
            "x-adif-session": adifToken,
            "x-request-id": requestId,
        }
        
        return await http.query(dfisUrl, fetchBrandAndRegionQuery, { headers: {...dfisHeaders} })
        
    } catch (err) {
        const message = "Failed to fetch brand and region from member-policy-service"
        janusLogger.error({
            message,
            err,
        })
        throw createError(
            err.status || 500,
            err.message || message
        )
    }
}

/**
 * Map brand code to brandFamily
 */
export const brandNames = { 
    BLU: "regence",
    ASUR: "asuris",
    BSH: "bridgespan",
}
