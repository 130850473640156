export const rxDrugStrengthQuery = (
    medicationName?: string,    
) => {
    return `
  query drugSearch {
    rxDrugSearch(searchTerm: "${medicationName}") {
      ndc,
      name,
      brand,
      doseForm,
      hasGeneric,
      strength,
      strengthUnitOfMeasure,
    }
  }
`
}