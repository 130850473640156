import * as React from "react"
import { SVGProps } from "react"
const SvgPersonDoctor = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Doctor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M17.4 6.4C17.4 9.4 15 11.8 12 11.8C9.00004 11.8 6.60004 9.4 6.60004 6.4C6.70004 3.4 9.10004 1 12 1C13 1 14 1.3 14.8 1.8M7.70007 4H13.7001M16.2001 17.7V19.0043M16.2001 19.0043V20.2M16.2001 19.0043H15.0001M16.2001 19.0043H17.5001M15.6 14L12 17.2C12 17.2 10 15.5 8.30005 14.1C4.80005 15.6 2.30005 19 2.30005 23H21.6C21.7 18.9 19.2 15.5 15.6 14ZM18.6001 4C18.6001 5.27025 17.5703 6.3 16.3001 6.3C15.0298 6.3 14.0001 5.27025 14.0001 4C14.0001 2.72974 15.0298 1.7 16.3001 1.7C17.5703 1.7 18.6001 2.72974 18.6001 4Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgPersonDoctor
