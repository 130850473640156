/**
  * HSA reducer
  * -----------------------------------------------------------------------------
  */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { HsaPreferences } from "./types"
 
/**
  * Types
  * -----------------------------------------------------------------------------
  */
export interface Hsa {
  /**
    * Base props
    */
  readonly isInitialized: boolean
  readonly isLoading: boolean
  readonly isAuthenticated: boolean
  readonly errorMessage?: string   

  /**
    * Posts props
    */
  readonly showHSACard: boolean
  readonly heqBalance: string
  readonly heqShare: string
  readonly hsaDisplayBalance: boolean
  readonly showBalancePopup: boolean
  readonly showClaimsPopup: boolean
}
 
/**
  * Initial state
  * -----------------------------------------------------------------------------
  */
export const initialState: Hsa = {
    isInitialized: false,
    isLoading: false,
    isAuthenticated: false,
    errorMessage: undefined,
    showHSACard: false,
    heqBalance: "n/a",
    heqShare: "",
    hsaDisplayBalance: false,
    showBalancePopup: false,
    showClaimsPopup: false
}
 
/**
  * Reducer slice
  * -----------------------------------------------------------------------------
  */
const hsa = createSlice({
    name: "hsa",
    initialState,
    reducers: {
        receiveHsa: (state: Hsa, action: PayloadAction<Hsa>) => {
            return action.payload
        },
        resetHsa: () => {
            return initialState
        },
    },
})
 
/**
  * Export reducer actions
  * -----------------------------------------------------------------------------
  */

export const hsaActions = { ...hsa.actions, ...actions }
 
/**
  * Export reducer
  * -----------------------------------------------------------------------------
  */

export default hsa.reducer
 