/**
 * Alerts reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import {
    createStatefulSlice,
    SliceState,
} from "store/util/create-stateful-slice"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Alert {
    readonly id: string
    readonly alertId: string
    readonly level?: "default" | "error" | "success" | "info" | "warning"
    readonly header?: string
    readonly body?: string
    readonly type?: "per session" | "per user"
    readonly isDismissable?: boolean
    readonly isValid?: boolean
    readonly hasIcon?: boolean
    readonly isDismissed?: boolean
}
export interface Alerts {
    readonly items: ReadonlyArray<Alert>
    readonly mpsData: Readonly<MPSResponseData>
}

export interface MPSResponseData {
    readonly extensions: MPSExtension
}
export interface MPSExtension {
    readonly metadata: MPSMetadata
}

export interface MPSMetadata {
    readonly status: string
    readonly requestId: string
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<Alerts> = {
    status: "pristine",
    items: [],
    mpsData: {
        extensions: undefined,
    },
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const alerts = createStatefulSlice({
    name: "alerts",
    initialState,
    reducers: {
        receiveAlerts: (
            state: SliceState<Alerts>,
            action: PayloadAction<Alerts>,
        ) => {
            return {
                ...state,
                status: "completed",
                items: action.payload.items,
            }
        },
        receiveMPSData: (
            state: SliceState<Alerts>,
            action: PayloadAction<Alerts>,
        ) => {
            return {
                ...state,
                status: "completed",
                mpsData: action.payload.mpsData,
            }
        },
        resetAlerts: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const alertsActions = { ...alerts.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default alerts.reducer
