import * as React from "react"
import { SVGProps } from "react"
const SvgPersonFamily = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Group of people"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M23 21C23 17.2 19.7 14.1 15.7 14.1M15.9 11.1C18.1 11 19.9 9.19998 19.9 7.09998C19.9 4.89998 18.1 3.19998 15.9 3.09998M8.3 11.1C6 11.1 4 9.3 4 7.1C4 4.8 6 3 8.3 3C10.6 3 12.6 4.8 12.6 7.1C12.6 9.4 10.7 11.1 8.3 11.1ZM1 21H15.7C15.7 17.2 12.4 14.1 8.4 14.1C4.4 14.1 1 17.2 1 21Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgPersonFamily
