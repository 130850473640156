export const claimsQueryDFIS = `
query {
  claims {
    edges {
      node {
        ... on CambiaClaim {
          appliesToOutOfPocketMax
          adjusted
          chargedAmount
          claimId
          claimNumber
          claimNumberAdjustedFrom
          claimNumberAdjustedTo
          claimServiceEndDate
          claimServiceStartDate
          claimStatusCode
          claimStatusDescription
          claimType
          dateOfBirth
          daysSuppliedPaid
          diagnosisType
          directionOfPay
          familyStructureId
          firstName
          groupId
          groupName
          itsIndicator
          negotiatedAmount
          patientAccountNumber
          payeeIndicator
          providerId
          providerName
          quantityPaid
          receivedDate
          subscriberId
          uniqueMemberIdentifier
          totalPatientResponsibilityAmount
          processedDate
          memberCopayAmount
          claimNotCoveredAmount
          claimPaidAmount
          memberDeductibleAmount
          memberCoinsuranceAmount
          otherInsurancePaidAmount
          pharmacyName
          drugName
          drugCode
          deaNumber
          prescriptionNumber
          paymentNumber
          pharmacyNumber
          lastName
          claimDetails {
            procedureType
            claimLineId
            claimLineServiceStartDate
            procedureCode
            procedureDescription
            negotiatedRate
            claimLinePaidAmount
            diagnosisCode
            claimId
            typeOfService
            unitsOfService
            lineNumber
            amaProcedureDescription
            diagnosisDescription
            coinsuranceAmount
            copayAmount
            deductibleAmount
            patientResponsibilityAmount
            billedAmount
            claimLineNotCoveredAmount
            claimLineServiceEndDate
            cobAmount
            claimDetailExplanationList {
              explanationDescription
              explanationCode
            }
          }
        }
      }
    }
  }
}
`
