/**
 * Campaign Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import { http } from "utils"
import { opportunityByIdQuery } from "../opportunities/queries"
import { transformOpportunityApiData } from "../opportunities/utils"
import {
    campaignsQuery,
    campaignQueryById,
    campaignQueryByGuid,
    opportunityCampaignsQuery,
} from "./queries"
import { transformCampaignApiData, transformCampaignsApiData, transformOpportunityCampaignsApiData } from "./utils"

/**
 * Get campaign data
 * -----------------------------------------------------------------------------
 */
export const fetchOpportunityCampaigns = createAsyncThunk(
    "campaign/fetchOpportunityCampaigns", // Reducer name

    async (opportunityIds: ReadonlyArray<string>, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.updateCampaigns({ isLoading: true, isItemLoading: true }))

        /**
         * Request campaigns data from digital-first-information
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            opportunityCampaignsQuery(opportunityIds),
        )

        /**
         * Handle request errors
         */
        if (res.error) {
            console.error(res.error)
            return dispatch(
                actions.updateCampaigns({
                    isLoading: false,
                    isItemLoading: false,
                    isInitialized: true,
                    errorMessage: "Could not retrieve opportunity campaigns data",
                }),
            )
        }

        /**
         * Transform opportunity campaigns data
         */
        const opportunityCampaignsData = res.data.data?.rxOpportunityCampaigns || []
        const items = transformOpportunityCampaignsApiData(opportunityCampaignsData)

        /**
         * Update state
         */
        dispatch(
            actions.updateCampaigns({
                items,
                isLoading: false,
                isItemLoading: false,
                isInitialized: true,
                errorMessage: undefined
            }),
        )
    },
)

export const fetchCampaigns = createAsyncThunk(
    "campaign/fetchCampaigns", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.updateCampaigns({ isLoading: true, isItemLoading: false }))

        /**
         * Request campaigns data from digital-first-information
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            campaignsQuery,
        )

        /**
         * Handle request errors
         */
        if (res.error) {
            console.error(res.error)
            return dispatch(
                actions.updateCampaigns({
                    isLoading: false,
                    isItemLoading: false,
                    isInitialized: true,
                    errorMessage: "Could not retrieve campaigns data",
                }),
            )
        }

        /**
         * Transform campaigns data
         */
        const campaignsFromQuery = res.data.data?.rxCampaigns.edges || []
        const items = transformCampaignsApiData(campaignsFromQuery)

        /**
         * Update state
         */
        dispatch(
            actions.updateCampaigns({
                items,
                isLoading: false,
                isItemLoading: false,
                isInitialized: true,
                errorMessage: undefined
            }),
        )
    },
)

export const fetchCampaignById = createAsyncThunk(
    "campaign/fetchCampaignById", // Reducer name

    async (params: ReadonlyArray<string>, { dispatch }) => {
        const [campaignId, oppId] = params
        /**
         * Set loading state
         */
        dispatch(actions.updateCampaigns({ isItemLoading: true }))

        /**
         *  Fetch MedSavvy campaign (from digital-first-information-service)
         */
        const query =
            campaignId.length <= 15
                ? campaignQueryById(campaignId, oppId)
                : campaignQueryByGuid(campaignId, oppId)

        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            query,
        )

        /**
         * Handle request errors
         */
        if (res.error) {
            return dispatch(
                actions.updateCampaigns({
                    isItemLoading: false,
                    isItemInitialized: true,
                    errorMessage: "Could not retrieve insight data",
                }),
            )
        }

        const errors = res.data?.errors
        const errorMessage = errors ? errors[0]?.message : null

        if (errorMessage) {
            return dispatch(
                actions.updateCampaigns({
                    isItemLoading: false,
                    isItemInitialized: true,
                    errorMessage: errorMessage,
                }),
            )
        }

        /**
         * Get Opportunity Item data
         * -----------------------------------------------------------------------------
         */
        const opp = await http.query(
            "/api/digital-first-information-service/graphql",
            opportunityByIdQuery(oppId),
        )

        /**
        * Handle request errors
        */
        if (opp.error) {
            console.error(opp.error)
            return dispatch(
                actions.updateCampaigns({
                    isItemLoading: false,
                    isItemInitialized: true,
                    errorMessage: "Could not retrieve opportunity data",
                }),
            )
        }

        /**
         * Transform campaign data
         */
        const campaign = transformCampaignApiData(res.data.data.rxCampaign, true)

        /**
         * Transform opportunities data
         */
        const opportunityFromQuery = opp.data.data?.rxOpportunity || {}
        const opportunity = transformOpportunityApiData(opportunityFromQuery, null, null)
        const campaignItem = {...campaign, rxOpportunity: opportunity}

        /**
         * Add/Update the Item in the campaigns array
         */
        const { campaigns } = store.getState()
        const exists = campaigns.items.find(
            item => item.id === campaignId || item.guid === campaignId,
        )
        const items = exists
            ? campaigns.items.map(item =>
                item.id === campaignId || item.guid === campaignId
                    ? campaignItem
                    : item,
            )
            : campaigns.items.concat([campaignItem])

        /**
         * Update items state
         */
        dispatch(
            actions.updateCampaigns({
                items: items,
                isItemLoading: false,
                isItemInitialized: true,
                errorMessage: undefined,
            }),
        )
    }
)