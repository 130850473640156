/**
 * Tooltip
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Card, Button } from "elements"
import { ReactNode } from "react";

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props extends React.HTMLAttributes<HTMLDivElement> {
    readonly name: string
    readonly children: ReactNode
    readonly width?: number
    readonly disabled?:boolean
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Tooltip: React.FC<Props> = props =>{

    const [showTooltip, setShowTooltip] = React.useState(false)
    const [isOnClick, setIsOnClick] = React.useState(false)
    const closeOnClickOutside = true

    const onClickOutside = () => {
        hideTooltip()
    }
    const ref = React.useRef(null)

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [ closeOnClickOutside ]);

    const handleMouseEnter  = () => {
        setShowTooltip(true)
    }

    const handleMouseLeave  = () => {
        if(!isOnClick){
            setShowTooltip(false)
        }
    }

    const handleMouseClick  = () => {
        setIsOnClick(true)
        setShowTooltip(true)
    }

    const hideTooltip = () =>{
        setIsOnClick(false)
        setShowTooltip(false)
    }

    return (
        <div ref={ref} className="static z-100">
            <Button
                name={props.name + `-help`}
                icon={"question-circle"}
                variant="link"
                size="small"
                className="items-center"
                disabled={props.disabled}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleMouseClick}
            />
            {showTooltip ? (
                <div
                    className={`absolute pr-xl flex `}
                >
                    <Card name={props.name +`-tooltip-card`}
                          className={`max-w-[` + (props.width ? props.width : "320")+ "px" +  `] pt-lg  z-body `}
                          stopPropagation={true} >
                        <Button
                            name="dismiss-button"
                            icon="times"
                            variant="link"
                            size="small"
                            className="absolute top-xxs right-xxl"
                            onClick={hideTooltip}
                            tabIndex={0}
                        />
                        {props.children}
                    </Card>
                </div>
            ) : (<></>) }
        </div>
    );
};

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Tooltip
