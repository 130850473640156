/**
 * Pill
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Icon, IconTypes } from "elements"
import Clickable, { ClickableProps } from "elements/clickable"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Props extends ClickableProps {
    // Content
    readonly label: string
    readonly iconLeading?: IconTypes
    readonly iconTrailing?: IconTypes

    // Visual props
    readonly className?: string
    readonly variant?: "default" | "success" | "warning" | "error" | "primary"
    readonly size?: "small" | "large"
    readonly inverted?: boolean
    readonly shimmer?: boolean
    readonly dataTest?: string

    // Accessibility props
    readonly alt?: string
    readonly ariaLabel?: string
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Pill = (props: Props) => {
    /**
     * Styles
     */
    const base = "inline-block rounded-lg md:rounded-circle"
    const sizes = {
        small: "px-xxs md:h-[20px] text-xs subpixel-antialiased",
        large: "px-xxs text-sm antialiased",
    }
    const size = sizes[props.size || "small"]

    const variants = {
        default: props.inverted
            ? "bg-gray-300 text-white"
            : "bg-gray-100 text-gray-300",
        primary: props.inverted
            ? "bg-primary-300 text-white"
            : "bg-primary-50 text-primary-300",
        success: props.inverted
            ? "bg-success-300 text-white"
            : "bg-success-50 text-success-300",
        warning: props.inverted
            ? "bg-warning-300 text-white"
            : "bg-warning-50 text-warning-300",
        error: props.inverted
            ? "bg-error-300 text-white"
            : "bg-error-50 text-error-300",
    }
    const variant = variants[props.variant || "default"]
    const shimmer = props.shimmer ? "shimmer text-opacity-0" : ""
    const classNames = `${base} ${size} ${variant} ${shimmer} ${props.className || ""}`

    /**
     * Template
     */
    return (
        <Clickable
            className={classNames}
            name={props.name}
            onClick={props.onClick}
            href={props.href}
        >
            <div className="h-full flex items-center justify-between" data-test={props.dataTest}>
                {/* Icon Left */}
                {!!props.iconLeading && (
                    <React.Fragment>
                        <Icon type={props.iconLeading} size={16} alt={props.alt} ariaLabel={props.ariaLabel} />
                        <div className="w-[3px]" />
                    </React.Fragment>
                )}

                {/* Label */}
                <div>{props.label}</div>

                {/* Icon Right */}
                {!!props.iconTrailing && (
                    <React.Fragment>
                        <div style={{ width: "3px" }} />
                        <Icon type={props.iconTrailing} size={16} alt={props.alt} ariaLabel={props.ariaLabel} />
                    </React.Fragment>
                )}
            </div>
        </Clickable>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Pill
