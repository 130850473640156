import * as React from "react"
import { SVGProps } from "react"
const SvgMosaic = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Four squares in a grid"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H10C10.5523 0 11 0.447715 11 1V10C11 10.5523 10.5523 11 10 11H1C0.447715 11 0 10.5523 0 10V1C0 0.734784 0.105357 0.48043 0.292893 0.292893ZM2 2V9H9V2H2ZM13.2929 0.292893C13.4804 0.105357 13.7348 0 14 0H23C23.5523 0 24 0.447715 24 1V10C24 10.5523 23.5523 11 23 11H14C13.4477 11 13 10.5523 13 10V1C13 0.734784 13.1054 0.48043 13.2929 0.292893ZM15 2V9H22V2H15ZM2 15V22H9V15H2ZM0 14C0 13.4477 0.447715 13 1 13H10C10.5523 13 11 13.4477 11 14V23C11 23.5523 10.5523 24 10 24H1C0.447715 24 0 23.5523 0 23V14ZM15 15V22H22V15H15ZM13 14C13 13.4477 13.4477 13 14 13H23C23.5523 13 24 13.4477 24 14V23C24 23.5523 23.5523 24 23 24H14C13.4477 24 13 23.5523 13 23V14Z"
            fill="currentColor"
        />
    </svg>
)
export default SvgMosaic
