/**
 * PremiumPay Info Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import { http } from "utils"
import { premiumPayDFISQuery } from "./queries"
import { transformPremiumPayApiData } from "./utils"

/**
 * Get PremiumPay Info data
 * -----------------------------------------------------------------------------
 */
export const fetchPremiumPaymentInfo = createAsyncThunk(
    "premiumPayment/fetchPremiumPaymentInfo", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        const { premiumPaymentInfo } = store.getState()
        dispatch(
            actions.receivePremiumPaymentInfo({
                ...premiumPaymentInfo,
                isLoading: true,
            }),
        )

        /**
         * Fetch claims (from DFIS)
         */
        const resDFIS = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            premiumPayDFISQuery,
        )

        if (resDFIS.error) {
            console.error(resDFIS.error)
            dispatch(
                actions.receivePremiumPaymentInfo({
                    ...premiumPaymentInfo,
                    isLoading: false,
                    isInitialized: true,
                    errorMessage: "Could not retrieve PremiumPay data",
                }),
            )
            return
        }

        /**
         * Transform Regence data
         */
        const data =
            resDFIS.data?.data?.premium || {}
        const resExtensionDFIS = resDFIS.data?.extensions || {}
        const premiumPaymentInfoItem = transformPremiumPayApiData(data,resExtensionDFIS)


        /**
         * Update state
         */
        dispatch(
            actions.receivePremiumPaymentInfo({
                ...premiumPaymentInfo,
                premiumPaymentInfoItem,
                isLoading: false,
                isInitialized: true,
            }),
        )
    },
)
