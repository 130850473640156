/**
 * Store import/export helper
 * -----------------------------------------------------------------------------
 */
import {
    TypedUseSelectorHook,
    useSelector as UseSelector,
    useDispatch as UseDispatch,
} from "react-redux"
import { State } from "./store"

/**
 * Exports
 * -----------------------------------------------------------------------------
 */
export const useDispatch = UseDispatch
export const useSelector: TypedUseSelectorHook<State> = UseSelector
export { useFeatures, useFeatureByName } from "./app/selectors"
export * from "./store"
