/**
 * Cookie utilities
 * -----------------------------------------------------------------------------
 */
import { CookieSerializeOptions } from "cookie"
import { NextApiRequest, NextApiResponse } from "next"
import nookies, { destroyCookie } from "nookies"
import { getBaseDomain } from "./env"
import { Alert } from "@/store/alerts/reducer"
import addYears from "date-fns/addYears"

/**
 * Globals
 * COOKIE_NAME matches the cookie set by digital-first-gateway
 * ADIF_ cookie names match those set by ADIF identity-service
 */
export const COOKIE_NAME = "DFGSESSION"
export const ADIF_SSO_COOKIE_NAME = "SSO_SESSIONID"
export const SITEMINDER_COOKIE_NAME = "SMSESSION"
export const DFW_AUTH_COOKIE_NAME = "DFW_AUTH"
export const P14N_COOKIE_NAME = "P14N"
// Define alert cookie prefix.
const ALERT_COOKIE_PREFIX = "AlertState"

interface SiteContext {
    readonly county: string,
    readonly state: string,
    readonly zipcode: string
    readonly brand: string,
    readonly region: string,
    readonly audience: string,
    readonly counties: readonly string[],
    readonly subregion: string,
}

/**
 * Set session cookie
 */
export const setSessionToken = (
    req: NextApiRequest,
    res: NextApiResponse,
    token: string,
) => {
    const sharedCookieOpts = getSharedCookieOptions(req)

    nookies.set({ res }, COOKIE_NAME, token, {
        ...sharedCookieOpts,
        httpOnly: true,
        secure: process.env.NODE_ENV === "production",
        path: "/",
    })
}

/**
 * Set ADIF SSO session cookie
 */
export const setAdifSSOSessionToken = (
    req: NextApiRequest,
    res: NextApiResponse,
    token: string,
) => {
    const sharedCookieOpts = getSharedCookieOptions(req)

    nookies.set({ res }, ADIF_SSO_COOKIE_NAME, token, {
        ...sharedCookieOpts,
        httpOnly: true,
        secure: process.env.NODE_ENV === "production",
        path: "/",
    })
}

/**
 * Set Siteminder cookie
 */
export const setSiteminderSession = (
    req: NextApiRequest,
    res: NextApiResponse,
    token: string,
) => {
    const sharedCookieOpts = getSharedCookieOptions(req)

    nookies.set({ res }, SITEMINDER_COOKIE_NAME, token, {
        ...sharedCookieOpts,
        httpOnly: true,
        secure: process.env.NODE_ENV === "production",
        path: "/",
        encode: v => v, // prevent Siteminder cookie from being URL encoded
    })
}

export const setP14NCookie = (
    req: NextApiRequest,
    res: NextApiResponse,
    siteContext: SiteContext
) => {
    const jsonString = JSON.stringify(siteContext)
    const brandFamilyMap = {
        asuris: "asuris.com",
        regence: "regence.com",
        bridgespan: "bridgespanhealth.com",
        localhost: "localhost"
    }
    nookies.set({ res }, P14N_COOKIE_NAME, jsonString, {
        domain: brandFamilyMap["localhost"],
        path: "/",
        secure: true,
        maxAge: 31536000,
    })
}


/**
 * Get session cookie
 */
export const getSessionToken = (req: NextApiRequest) => {
    return nookies.get({ req })[COOKIE_NAME]
}

/**
 * Get ADIF session cookie.
 * Look it up by `ADIF_SSO_COOKIE_NAME` as the SSO cookie has a domain setting that allows it to be shared across subdomains (e.g., `.regence.com`)
 * whereas `ADIF_COOKIE_NAME` does not (e.g., www-sit2.regence.com). The cookies correspond to the same ADIF JWT.
 */
export const getAdifSessionToken = (req: NextApiRequest) => {
    const cookieToken = nookies.get({ req })[ADIF_SSO_COOKIE_NAME]
    if (cookieToken) {
        return cookieToken
    }
    return req?.headers?.["x-adif-session"] as string || ""
}

/**
 * Destroy session cookie
 */
export const destroySessionToken = (res: NextApiResponse) => {
    return nookies.destroy({ res }, COOKIE_NAME, { path: "/" })
}

/**
 * This simply destroys all cookies. In the future we may want to add a filter to keep some cookies post-signout.
 */
export const destroyAllAuthenticationCookies = (
    req: NextApiRequest,
    res: NextApiResponse,
) => {
    const defaultCookieOpts = { path: "/" }
    const sharedCookieOpts = getSharedCookieOptions(req)

    const cookies = nookies.get({ req })

    Object.keys(cookies).forEach(cookie => {
        // Never remove alert dismissal status cookies.
        if (cookie.startsWith(ALERT_COOKIE_PREFIX)) {
            return
        }

        switch (cookie) {
            // cookies shared with the base domain must be destroyed by specifying the domain
            case P14N_COOKIE_NAME:
                //DON'T DELETE
                break
            case SITEMINDER_COOKIE_NAME:
            case ADIF_SSO_COOKIE_NAME:
                nookies.destroy({ res }, cookie, {
                    ...defaultCookieOpts,
                    ...sharedCookieOpts,
                })
                break
            default:
                nookies.destroy({ res }, cookie, defaultCookieOpts)
        }
    })
}

/**
 * Get options for cookies that are shared with the base domain
 *
 * e.g., SSO_SESSIONID is shared by regence.com and all subdomains of regence.com
 */
const getSharedCookieOptions = (
    req: NextApiRequest,
): CookieSerializeOptions => {
    const host = (req.headers["x-forwarded-host"] as string) || req.headers.host
    const baseDomain = getBaseDomain(host)

    if (typeof baseDomain === "string") {
        const domain =
            baseDomain === "localhost" ? "localhost" : "." + baseDomain

        return { domain }
    } else {
        console.warn(
            `Not specifying domain for shared cookie - failed to determine base domain from host '${host}'.`,
        )
    }

    return {}
}

/**
 *  Get DFW authentication cookie
 */
export const getDFWAuthenticationCookie = (req: NextApiRequest) => {
    return nookies.get({ req })[DFW_AUTH_COOKIE_NAME]
}

/**
 * Destroy DFW authentication cookie
 */
export const destroyDFWAuthenticationCookie = (domain: string) => {
    return destroyCookie(null, DFW_AUTH_COOKIE_NAME, {
        domain: domain,
        path: "/",
    })
}

/**
 * Get app model feature toggle cookie value used to override in-session behavior of the toggle
 */
export const getAppModelFeatureToggleCookie = (
    req: NextApiRequest,
    appModelFeatureToggle: string,
) => {
    return nookies.get({ req })[appModelFeatureToggle]
}

/**
 * Get the alerts cookie name.
 * @param alertId The alert id.
 * @returns Alerts cookie name
 */
const getAlertCookieName = (alertId: string) => `${ALERT_COOKIE_PREFIX}-${alertId}`

/**
 * Set the alert dismissal status of alert in client cookie.
 * 
 * @param alertId The alert id whose dismissal status we want to set.
 * @param dismissalType The dismissal type of alert.
 * @param alertState The dismissal state that we want to set for this alert.
 */
export function setAlertDismissalStatusInCookie(alertId: string, dismissalType: Alert["type"], alertState: boolean): boolean {
    try {
        // Define base domain and cookie name.
        const sharedCookieOpts = getSharedCookieOptions({ headers: { host: window.location.host } } as NextApiRequest)
        const cookieName = getAlertCookieName(alertId)

        // Set the client side cookie.
        nookies.set(null, cookieName, alertState.toString(), {
            // Add sharedCookieOpts and path "/" to make cookie accessible to all sub domains.
            ...sharedCookieOpts,
            path: "/",
            secure: true,
            // If alert is of type user then set expire time as date after 1 year.
            // Else if alert is of session type then don't set expire time. Which will make it session cookie.
            expires: dismissalType === "per user"? addYears(new Date(), 1): undefined
        })

        // Return the value of alert state.
        return alertState
    } catch (error) {
        console.error(`Error while setting alert dismissal state: ${error.message}`)
    }
}

/**
 * Get the alert dismissal status of alert from client cookies.
 * 
 * @param alertId The alert Id.
 * @param type The dismissal type of alert. 
 * @returns True if alert is dismissed else False.
 */
export function getAlertDismissalStatusFromCookie(alertId: string, type: Alert["type"]): boolean {
    try {
        // Define cookie/alert name.
        const cookieName = getAlertCookieName(alertId)

        // Get the alert dismissal status from client cookie.
        const result = nookies.get(null)[cookieName]
        const alertDismissalState = result === "true"

        // If alert is already dismissed and is of type user
        // Then extend its expiry time by setting alert again.
        if(alertDismissalState && type === "per user") {
            setAlertDismissalStatusInCookie(alertId, type, alertDismissalState)
        }

        // Return alert dismissal status.
        return alertDismissalState
    } catch (error) {
        console.error(`Error while getting alert dismissal state: ${error.message}`)
    }  
}
