import * as React from "react"
import { SVGProps } from "react"
const SvgClock = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Clock" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M12 6V11.5858C12 11.851 12.1054 12.1054 12.2929 12.2929L15 15M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgClock
