import { transformConditionApiData } from "../conditions/utils"
import { transformTreatmentApiData } from "../treatments/utils"
import { ConditionTreatment } from "./types"

export const transformConditionTreatmentsApiData = (
    conditionTreatments: ReadonlyArray<any>, // eslint-disable-line
): ReadonlyArray<ConditionTreatment> => {
    // return conditionTreatments.map(conditionTreatment => {
    //    const item = conditionTreatment.node || conditionTreatment
    //    return transformConditionTreatmentApiData(item)
    // })
    const items = conditionTreatments.map(conditionTreatment => {
        return conditionTreatment.node || conditionTreatment
    })

    const filtered = items.filter(item => item.id != null)

    return filtered.map(item => {
        return transformConditionTreatmentApiData(item)
    })
}

export const transformConditionTreatmentApiData = (
    conditionTreatment: any, // eslint-disable-line
): ConditionTreatment => {
    return {
        id: conditionTreatment?.id,
        score: conditionTreatment?.score,
        grade: conditionTreatment?.grade,
        clinicalSummary: conditionTreatment?.clinicalSummary,
        effectiveness: conditionTreatment?.effectiveness,
        safety: conditionTreatment?.safety,
        relevanceOrder: conditionTreatment?.relevanceOrder,
        counselingTips: conditionTreatment?.counselingTips,
        nextReviewDate: conditionTreatment?.nextReviewDate,
        rxTreatment: conditionTreatment?.rxTreatment
            ? transformTreatmentApiData(conditionTreatment?.rxTreatment)
            : undefined,
        rxCondition: conditionTreatment?.rxCondition
            ? transformConditionApiData(conditionTreatment?.rxCondition)
            : undefined,
    }
}
