import * as React from "react"
import { SVGProps } from "react"
const SvgNote = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Note" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M3.99997 8.09998H20M14.1 20.5V14.2H20.4M14.6 20.9H5.19998C3.99998 20.9 3.09998 20 3.09998 18.8V5.19998C3.09998 3.99998 3.99998 3.09998 5.19998 3.09998H18.9C20.1 3.09998 21 3.99998 21 5.19998V14.7L14.6 20.9Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgNote
