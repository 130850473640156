import { DISPLAY_RULES } from "utils/display-rules"
import { CONSUMER_RULES } from "utils/consumer-rules"
import { AppModelfeatureToggle, FEATURE_TOGGLES } from "utils/feature-toggles"

export const Features = {
    CHATBOT_AVAILABLE: "chatbotAvailable",
    COBROWSE_AVAILABLE: "cobrowseAvailable",
    DIGITAL_FIRST_ELIGIBLE: "digitalFirstEligible",
    COVERED_SERVICES_BENEFITS_MEDICAL_FIND_CARE:
        "coveredServicesBenefitsMedicalFindCare",
    COVERED_SERVICES_BENEFITS_DENTAL_FIND_CARE:
        "coveredServicesBenefitsDentalFindCare",
    COVERED_SERVICES_BENEFITS_VISION_FIND_CARE:
        "coveredServicesBenefitsVisionFindCare",
    COVERED_SERVICES_BENEFITS_PHARMACY_FIND_CARE:
        "coveredServicesBenefitsPharmacyFindCare",
    COVERED_SERVICES_BENEFITS_RESOURCES_RX_HMO_PLUS:
        "coveredServicesBenefitsRxPlanTypeHMOPlus",
    COVERED_SERVICES_BENEFITS_RESOURCES_RX_HMO:
        "coveredServicesBenefitsRxPlanTypeHMO",
    COVERED_SERVICES_BENEFITS_RESOURCES_RX_ALIGN:
        "coveredServicesBenefitsRxPlanTypeAlign",
    COVERED_SERVICES_BENEFITS_RESOURCES_RX_CLASSIC:
        "coveredServicesBenefitsRxPlanTypeClassic",
    COVERED_SERVICES_BENEFITS_RESOURCES_RX_PRIMARY:
        "coveredServicesBenefitsRxPlanTypePrimary",
    COVERED_SERVICES_BENEFITS_RESOURCES_RX_ENHANCED:
        "coveredServicesBenefitsRxPlanTypeEnhanced",
    COVERED_SERVICES_BENEFITS_RESOURCES_RX_BASIC:
        "coveredServicesBenefitsRxPlanTypeBasic",
    COVERED_SERVICES_BENEFITS_RESOURCES_RX_CORE:
        "coveredServicesBenefitsRxPlanTypeCore",
    PREMIUM_PAYMENT_ELIGIBLE: "premiumPaymentEligible",
    COVERAGE_PENDING_ONLY: "coveragePendingOnly",
    COVERAGE_MEDICAL_ACTIVE: "coverageMedicalActive",
    EOB_STATEMENTS: "eobStatements",

    NATIVE__CLAIMS_COSTS__PAYMENTS: "native/claims-costs/payments",
    CLAIMS_COSTS__PAYMENTS__AMOUNT__PAST_DUE:
        "claims-costs/payments//amount/past-due",
    CLAIMS_COSTS__PAYMENTS__AMOUNT__NEW_CHARGE:
        "claims-costs/payments//amount/new-charges",
    CLAIMS_COSTS__PAYMENTS__AMOUNT__TOTAL_DUE:
        "claims-costs/payments//amount/total-due",
    CLAIMS_COSTS__PAYMENTS__AMOUNT__TOTAL_DUE_PENDING:
        "claims-costs/payments//amount/total-due-pending",
    CLAIMS_COSTS__PAYMENTS__AMOUNT__TOTAL_DUE_INVOICE_PENDING:
        "claims-costs/payments//amount/total-due-invoice-pending",
    CLAIMS_COSTS__PAYMENTS__BUTTON__MAKE_PAYMENT:
        "claims-costs/payments//button/make-payment",
    CLAIMS_COSTS__PAYMENT_HISTORY__BUTTON__MAKE_PAYMENT:
        "claims-costs/payment-history//button/make-payment",
    CLAIMS_COSTS__PAYMENTS__BUTTON__MANAGE_PAYMENT:
        "claims-costs/payments//button/manage-payment",
    CLAIMS_COSTS__PAYMENTS__BUTTON__SET_UP_AUTOPAY:
        "claims-costs/payments//button/set-up-autopay",
    CLAIMS_COSTS__PAYMENTS__BUTTON__CUSTOMER_SERVICE:
        "claims-costs/payments//button/customer-service",
    CLAIMS_COSTS__PAYMENTS__LINK__CUSTOMER_SERVICE:
        "claims-costs/payments//link/customer-service",
    CLAIMS_COSTS__PAYMENTS__MESSAGE__PAYMENT_INFO:
        "claims-costs/payments//message/payment-info",
    CLAIMS_COSTS__PAYMENTS__ALERT__PAST_DUE_WITH_DATE:
        "claims-costs/payments//alert/past-due-with-date",
    CLAIMS_COSTS__PAYMENTS__ALERT__PAST_DUE:
        "claims-costs/payments//alert/past-due",
    CLAIMS_COSTS__PAYMENTS__ALERT__PAST_DUE_WITH_AUTOPAY:
        "claims-costs/payments//alert/past-due-with-autopay",
    CLAIMS_COSTS__PAYMENTS__ALERT__PAST_DUE_WITH_AUTOPAY_AND_DATE:
        "claims-costs/payments//alert/past-due-with-autopay-and-date",
    CLAIMS_COSTS__PAYMENTS__PAYMENT_PENDING:
        "claims-costs/payments//alert/payment-pending",
    CLAIMS_COSTS__PAYMENTS__AUTOPAY_PENDING:
        "claims-costs/payments//alert/autopay-pending",
    CLAIMS_COSTS__PAYMENTS__ALERT__TERMINATED_AUTOPAY_ELIGIBLE:
        "claims-costs/payments//alert/terminated-autopay-eligible",
    CLAIMS_COSTS__PAYMENTS__ALERT__TERMINATED:
        "claims-costs/payments//alert/terminated",
    CLAIMS_COSTS__PAYMENTS__ALERT__SSA: "claims-costs/payments//alert/ssa",
    CLAIMS_COSTS__PAYMENTS__ALERT__AUTOPAY_SCHEDULED:
        "claims-costs/payments//alert/autopay-scheduled",
    MDLIVE_VENDOR: "mdLiveVendor",
    MDLIVE_TELEH: "mdLiveTeleh",
    MDLIVE_TELEX: "mdLiveTelex",
    MDLIVE_TELEV: "mdLiveTelev",
    DOCTOR_ON_DEMAND: "doctorOnDemand",
    ADVICE_24: "advice24",
    NURSE_CHAT: "nurseChat",
    ESTIMATE_COSTS_MEDICAL_ACTIVE: "estimate-costs-medical-active",
    ESTIMATE_COSTS_KYRUUS_REWARDS: "web/kyruus/rewards",
    HEALTH_HISTORY_RECORDS_MEDICAL_ACTIVE:
        "health-history-records-medical-active",
    ASK_A_DOCTOR_CIRRUS: "askADoctorCirrus",
    TELEHEALTH: "telehealth",
    BEHAVIORAL_HEALTH: "behavorial-health",
    SPECIAL_REGION_WA_MANDATE: "specialRegionWaMandate",
    WA_SUBSTANCE_ABUSE: "waSubstanceAbuse",
    PCP_CHANGE: "pcpChange",
    EXPERT_2ND_OPINION: "expert2ndOpinion",
    PHARMACY_CVS: "pharmacyCvs",
    RX_COVERAGE_ACTIVE: "rxCoverageActive",
    MA_HMO: "ma-hmo",
    MA_HMO_PPO: "ma-hmo-ppo",
    MA_PPO: "ma-ppo",
    ROX: "rox",
    MEDIGAP: "medigap",
    MEDICARE_PDP: "medicare-pdp",
    MEDICARE_PART_D: "medicare-part-d",
    M3P_WIPRO_PAYMENT_PORTAL: "m3p-wipro-payment-portal",
    AUDIENCE_ASO: "audienceAso",
    // Feature for DME (Durable Medical Equipment) or Amazon store
    DME: "dme",
    CIS: "cis",
    JOINT_ADMIN: "joint-admin",
    JOINT_ADMIN_WITH_MEMBER_CARD: "joint-admin-with-member-card",
    JOINT_ADMIN_ENABLED: "joint-admin-janus",
    AMERIBEN: "ameriben",
    DASHBOARD_SPOTLIGHT: "dashboard-spotlight",
    NAVIGATION_SPOTLIGHT: "navigation-spotlight",
    PHARMACY_FEEDBACK: "pharmacy-feedback",
    EXPRESS_SCRIPT: "express-script",
    RX_PCOMP: "rx-pcomp",
    RX_PCOMP_01_CABINET: "rx-pcomp-01-cabinet",
    RX_PCOMP_02_RESEARCH_MEDS: "rx-pcomp-02-research-meds",
    RX_PCOMP_03: "rx-pcomp-03",
    RX_PCOMP_04: "rx-pcomp-04",
    RX_PCOMP_05_LOCATION: "rx-pcomp-05-location",
    RX_PRIME_COVERAGE: "rx-prime-coverage",
    RX_PRIME_COVERAGE_RESEARCH: "rx-prime-coverage-research",
    AUDIENCE_MEDICARE: "audienceMedicare",
    PREMIUM_PAYMENTS: "premium-payment",
    SUPPORT_PEBB: "support-pebb",
    SUPPORT_SEBB: "support-sebb",
    VISION_BENEFITS_TAB: "visionBenefitsTab",
    PHARMACY_BENEFITS_TAB: "pharmacyBenefitsTab",
    MEDICAL_BENEFITS_TAB: "medicalBenefitsTab",
    GENERIC_VISION_BENEFITS: "genericVisionBenefits",
    PEBB_VISION_BENEFITS: "pebbVisionBenefits",
    SEBB_VISION_BENEFITS: "sebbVisioBenefits",
    WELLNESS_AVAILABLE: "wellness-available",
    WELLNESS_AUTHORIZATION_REQUIRED: "wellness-authorization",
    HCA_SMARTHEALTH_EARNED: "smarthealth-incentive-earned",
    HCA_SMARTHEALTH_NOT_EARNED: "smarthealth-incentive-not-earned",
    HCA_SMARTHEALTH_OTHER: "smarthealth-incentive-other",
    DISPATCH_HEALTH_FIND_CARE: "dispatchHealthFindCare",
    HCA_PHARMACY_FIND_CARE: "hcaPharmacyFindCare",
    SEBB_VISION_FIND_CARE: "sebbVisionFindCare",
    DISPATCH_HEALTH: "dispatch-health",
    M3P_WIPRO:"m3p-wipro",
    DISPATCH_HEALTH_URL_ID: "resource/dispatch-health/url-id",
    DISPATCH_HEALTH_URL_OR: "resource/dispatch-health/url-or",
    DISPATCH_HEALTH_URL_UT: "resource/dispatch-health/url-ut",
    DISPATCH_HEALTH_URL_WA: "resource/dispatch-health/url-wa",
    DISPATCH_HEALTH_URL_ASURIS: "resource/dispatch-health/url-asuris",
    DISPATCH_HEALTH_URL_BSH: "resource/dispatch-health/url-bridgespan",
    RESOURCE__WINFERTILITY: "resource/winfertility",
    RESOURCE_WINFERTILITY__URL_ID: "resource/winfertility/url/region-id",
    RESOURCE_WINFERTILITY__URL_OR: "resource/winfertility/url/region-or",
    RESOURCE_WINFERTILITY__URL_UT: "resource/winfertility/url/region-ut",
    RESOURCE_WINFERTILITY__URL_WA: "resource/winfertility/url/region-wa",
    RESOURCE_WINFERTILITY__URL_ASURIS: "resource/winfertility/url/asuris",
    COVERED_SERVICES__FIND_CARE__GET_IMMEDIATE_CARE___URGENT_CARE_NEAR_YOU:
        "findCareUrgentCareNearYou",
    COVERED_SERVICES__FIND_CARE__GET_IMMEDIATE_CARE___EMERGENCY_ROOM_NEAR_YOU:
        "findCareEmergencyCareNearYou",
    COVERED_SERVICES_FIND_CARE__RECENT_PROVIDERS: "findCareRecentProviders",
    COVERED_SERVICES_FIND_CARE_DENTAL_USABLE:
        "coveredServicesFindCareDentalUsable",
    COVERED_SERVICES_FIND_CARE_VSP: "coveredServicesFindCareVsp",
    RESOURCE_HCA_SMARTHEALTH: "resource/smarthealth",
    HCA_GENERAL: "hca-general",
    RESOURCE_SELF_GUIDED_EMOTIONAL_HEALTH:
        "resource/self-guided-emotional-health",
    RESOURCE_MDLIVE_PCP: "resource/mdlive-pcp",
    RESOURCE_MDLIVE_NO_BH: "resource/mdlive-no-behavioral-health",
    RESOURCE_MDLIVE_BH: "resource/mdlive-behavioral-health",
    RESOURCE_MDLIVE: "resource/mdlive",
    RESOURCE_HINGE_HEALTH: "resource/hinge-health",
    RESOURCE_EXPERT_OPINION: "resource/expert-opinion",
    RESOURCE_DOCTOR_ON_DEMAND: "resource/doctor-on-demand",
    RESOURCE_ASK_A_DOCTOR: "resource/ask-a-doctor",
    RESOURCE_ADVICE_24: "resource/advice-24",
    RESOURCE_PREVENTATIVE_CARE: "resource/preventative-care",
    RESOURCE_EMPOWER: "resource/empower",
    DASHBOARD_BANNER__PREMIUM_PAYMENT_V2: "dashboard/banner/premium-payment-v2",
    DASHBOARD__BANNER_CONTINUITY_OF_CARE:
        "dashboard//banner/continuity-of-care",
    DASHBOARD_CARD_EMPOWER: "dashboard-card/empower",
    DASHBOARD_LANGUAGE_SPANISH: "dashboard-language/spanish",
    DASHBOARD_DOCUMENTS__SPANISH_DENTAL: "dashboard-documents/spanish-dental",
    DASHBOARD_DOCUMENTS__SPANISH_MEDICAL: "dashboard-documents/spanish-medical",
    DASHBOARD_QUICK_ACTION__MESSAGE_CENTER:
        "dashboard/quick-action/message-center",
    DASHBOARD__FIND_CARE_CARD: "web/dashboard/quick-action/find-care-card",
    DASHBOARD__USABLE_DENTAL_CARD: "web/dashboard/card/usable-dental",
    DASHBOARD__BEHAVIORAL_HEALTH_CARD: "web/dashboard/card/behavioral-health",
    DASHBOARD__FIND_CARE_BUTTON: "web/dashboard/find-care-button",
    DASHBOARD__SPOTLIGHT_CARD: "web/dashboard/spotlight-card",
    DASHBOARD__ADVICE24_CARD: "web/dashboard/advice24-card",
    DASHBOARD__RESOURCE_LIST_CARD: "web/dashboard/resource-list-card",
    WEB__CARE__YOUR_PROVIDER: "web/care/your-provider",
    WEB__CARE__YOUR_CARE_HISTORY__ITEM: "web/care/your-care-history/item",
    WEB__CARE__YOUR_CARE_HISTORY__LIST: "web/care/your-care-history/list",
    WEB__CARE__CARE_GAPS__HELP_CARD: "web/care/care-gaps/help-card",
    CARE_RESOURCES__HEADER_HIDE_PREGNANCY_TAB:
        "web/care-resources/header-hide-pregnancy-tab",
    CARE_RESOURCES__TAB__MEDICARE: "web/care-resources/tab/medicare",
    SUPPORT__SIDEBAR_FIND_CARE_CARD: "support/sidebar/find-care-card",
    BENEFITS__USABLE_DENTAL_CARD: "web/benefits/card/usable-dental",
    BENEFITS__VISION_CARD: "web/benefits/card/vision",
    BENEFITS__MEDICARE_VSP_CARD: "web/benefits/card/medicare-vsp",
    BENEFITS__DISCLAIMER_USE_MEDICARE_URL:
        "web/benefits/layout/disclaimer-use-medicare-ur",
    BENEFITS__MEDICAL_INDY_RESOURCES_CARD:
        "web/benefits/medical/indy-resources-card",
    BENEFITS__MEDICAL_MEDICARE_RESOURCES_CARD:
        "web/benefits/medical/medicare-resources-card",
    BENEFITS__MEDICAL_SHOW_MEDICARE_BOOKLET:
        "web/benefits/medical/show-medicare-booklet",
    BENEFITS__PHARMACY_INDY_RESOURCES_CARD:
        "web/benefits/pharmacy/indy-resources-card",
    BENEFITS__PHARMACY_MEDICARE_RESOURCES_CARD:
        "web/benefits/pharmacy/medicare-resources-card",
    BENEFITS__DENTAL_RESOURCES_CARD: "web/benefits/dental/resources-card",
    BENEFITS__MEDIGAP_RESOURCES_CARD: "web/benefits/medigap/resources-card",
    RESOURCE_RESEARCH_RX: "resource/research-rx",
    RESOURCE_SILVER_AND_FIT: "resource/silver-and-fit",
    RESOURCE_CARE_MANAGEMENT: "resource/care-management",
    RESOURCE_BABYWISE: "resource/babywise",
    RESOURCE_BUMP_TO_BABY: "resource/bump-to-baby",
    RESOURCE_PALLIATIVE_CARE: "resource/palliative-care",
    RESOURCE_CONDITION_MANAGER: "resource/condition-manager",
    RESOURCE_GENDER_AFFIRMING_CARE: "resource/gender-affirming-care",
    RESOURCE_TELEHEALTH: "resource/telehealth",
    RESOURCE_ESTIMATE_COSTS: "resource/estimate-costs",
    RESOURCE_ABLE_TO: "resource/able-to",
    RESOURCE_WINCO_EMPLOYEE_BENEFITS: "resource/winco-employee-benefits",
    RESOURCE_WA_SUBSTANCE_ABUSE: "resource/wa-substance-abuse",
    RESOURCE_ASANTE_EAP: "resource/asante-eap",
    RESOURCE_MEDICAL_SUPPLIES: "resource/medical-supplies",
    RESOURCE_UMP_HEALTH_PEBB: "resource/ump-health-pebb",
    RESOURCE_UMP_HEALTH_SEBB: "resource/ump-health-sebb",
    RESOURCE_UMP_BENEFITS_PEBB: "resource/ump-benefits-pebb",
    RESOURCE_MENTAL_HEALTH_PROVIDER: "resource/mental-health-provider",
    RESOURCE_HUNTSMAN_MENTAL_HEALTH_PROVIDER:
        "resource/huntsman_mental-health-provider",
    RESOURCE_SUICIDE_CRISIS_LIFELINE: "resource/suicide-crisis-lifeline",
    RESOURCE_FIND_RIGHT_CARE: "resource/find-right-care",
    RESOURCE_NEDA: "resource/neda",
    RESOURCE_UMP_BENEFITS_SEBB: "resource/ump-benefits-sebb",
    // this consumer rule will always return false after 1/1/2025 and can be deleted after that date
    RESOURCE_OVER_THE_COUNTER: "resource/medadvantage-otc-incentives",
    // this consume rule will return false until 1/1/2025
    RESOURCE_MEDADVANTAGE_INCENTIVES_OTC:
        "resource/medadvantage-incentives-otc",
    // this consume rule will return false until 1/1/2025
    RESOURCE_MEDADVANTAGE_INCENTIVES: "resource/medadvantage-incentives",

    RESOURCE_MEDICARE_HEARING_AIDS: "resource/medadvantage-hearing-aids",
    RESOURCE_MEDICARE_BENEFITS: "resource/medadvantage-benefits",
    RESOURCE_DOCTOR_ON_DEMAND_VPC: "resourceDoctorOnDemandVpc",
    RESOURCE_VIRTUAL_CARE: "resourceVirtualCare",
    RESOURCE_MA_HEALTHY_REWARDS_PROGRAM: "resource/health-rewards-program",
    RESOURCE_MA_ALTERNATIVE_MEDICINE_BENEFITS:
        "resource/alternative-medicine-benefits",
    RESOURCE_MEDICARE_PREVENTIVE_CARE: "resource/medicare-preventive-care",
    RESOURCE_STRIVE: "resource/strive",
    RESOURCE_MA_DIABETES_PREVENTION:
        "resource/medadvantage-diabetes-prevention",
    PEBB_24_HOUR_NURSELINE: "pebb-24hour-nurseline",
    SEBB_24_HOUR_NURSELINE: "sebb-24hour-nurseline",
    HSA_AVAILABLE: "hsaAvailable",
    ESTIMATE_COSTS_VSP: "costs/tab/vsp",
    ALERT_WA_MANDATE: "alert/wa-mandate",
    FORTIVE_LIVONGO_HYPERTENSION: "fortive/livongo-hypertension",
    FORTIVE_VIRTA_DIABETES: "fortive/virta-diabetes",
    FORTIVE_PROGENY_INFERTILITY: "fortive/progeny-infertility",
    FORTIVE_SPRING_HEALTH: "fortive/spring-health",
    PRIMARY_CARE_PROVIDER: "pcp",
    FIND_CARE_V2_ENABLED: "feature/dfw-find-care-v2-enabled",

    // Account settings
    ACCOUNT_SETTINGS_MEDICAL_PAPERLESS: "account-settings/medical-toggle",
    ACCOUNT_SETTINGS_PHARMACY_PAPERLESS: "account-settings/pharmacy-toggle",
    ACCOUNT_SETTINGS_ANOC_PAPERLESS: "account-settings/anoc-toggle",
    ACCOUNT_SETTINGS_SSO_AUTHORIZATION: "account-settings/sso-authorization",
    ACCOUNT_SETTINGS_WELLNESS_AUTHORIZATION:
        "account-settings/wellness-authorization",
    ACCOUNT_SETTINGS_INTEROP_ELIGIBLE: "account-settings/interop-eligible",
    ACCOUNT_SETTINGS_FAMILY_SHARING: "account-settings/family-sharing",
    ACCOUNT_SETTINGS_SIDEBAR_CHANGE_ADDRESS:
        "account-settings/account-information/sidebar/change-address",
    ACCOUNT_SETTINGS__TAB__DATA_AND_PRIVACY:
        "account-settings/tab/data-and-privacy",
    ACCOUNT_SETTINGS__DATA_AND_PRIVACY__MARKETING_AUTHORIZATION:
        "account-settings/data-and-privacy/marketing-authorization",
    ACCOUNT_SETTINGS__DATA_AND_PRIVACY__SSO_AUTHORIZATION:
        "account-settings/data-and-privacy/sso-authorization",
    ACCOUNT_SETTINGS__DATA_AND_PRIVACY__WELLNESS_AUTHORIZATION:
        "account-settings/data-and-privacy/wellness-authorization",
    ACCOUNT_SETTINGS__DATA_AND_PRIVACY__INTEROP_AUTHORIZATION:
        "account-settings/data-and-privacy/interop-authorization",
    ACCOUNT_SETTINGS__DATA_AND_PRIVACY__SIDEBAR__FAMILY_SHARING:
        "account-settings/data-and-privacy/sidebar/family-sharing",
    ACCOUNT_SETTINGS__DATA_AND_PRIVACY__SIDEBAR__SHARE:
        "account-settings/data-and-privacy/sidebar/share",
    ACCOUNT_SETTINGS__FINANCE__HIDE_PAYMENT_HISTORY:
        "account-settings/finance/hide-payment-history",
    ACCOUNT_SETTINGS__FINANCE__SIDEBAR__MAKE_PAYMENT:
        "account-settings/finance/sidebar/make-payment",
    ACCOUNT_SETTINGS_PAPERLESS_PREFERENCE:
        "account-settings/preferences/paperless",
    // Covered services -> check benefits
    COVERED_SERVICES__CHECK_BENEFITS__BANNER_CONTINUITY_OF_CARE:
        "covered-services/check-benefits//banner/continuity-of-care",
    COVERED_SERVICES__CHECK_BENEFITS__TAB__DENTAL:
        "covered-services/check-benefits/tab/dental",
    COVERED_SERVICES__CHECK_BENEFITS__TAB__HEARING:
        "covered-services/check-benefits/tab/hearing",
    COVERED_SERVICES__CHECK_BENEFITS__TAB__MEDICAL:
        "covered-services/check-benefits/tab/medical",
    COVERED_SERVICES__CHECK_BENEFITS__TAB__PHARMACY:
        "covered-services/check-benefits/tab/pharmacy",
    COVERED_SERVICES__CHECK_BENEFITS__TAB__VISION:
        "covered-services/check-benefits/tab/vision",
    COVERED_SERVICES_CHECKED_BENEFITS_DENTAL_USABLE:
        "covered-services/check-benefits/dental/usable",
    COVERED_SERVICES_FIND_CARE__FIND_A_PROVIDER_VISION:
        "covered-services/find-care/find-a-provider/vision",
    COVERED_SERVICES_FIND_CARE__FIND_A_PROVIDER_OTHER_VISION_BENEFITS:
        "covered-services/find-care/find-a-provider/other-vision-benefits",
    COVERED_SERVICES_FIND_CARE__FIND_A_PROVIDER_VSP_BENEFITS:
        "covered-services/find-care/find-a-provider/vsp-benefits",
    COVERED_SERVICES__CHECK_BENEFITS_QUICK_ACTION:
        "covered-services/check-benefits/quick-action",
    COVERED_SERVICES__CHECK_BENEFITS_LAYOUT: "covered-services/benefits/layout",
    COVERED_SERVICES__YOUR_BENEFITS_VSP_HCA_SEBB:
        "covered-services/benefits/vsp-card",

    // Feature toggles or App level feature flags
    DFW_ENABLED: "dfwEnabled",
    DFW_MY_ACCOUNT_ENABLED: "dfwMyAccountEnabled",
    DFW_INSIGHT_CARD_FAMILY_MEMBER_ENABLED: "dfwIsightCardFamilyMemberEnabled",
    // Footer
    FOOTER__ABLE_TO: "footer/able-to",
    FOOTER_HINGE_HEALTH: "footer/HingeHealth",
    FOOTER_SELF_GUIDED_EMOTIONAL_HEALTH: "footer/self-guided-emotional-health",

    // RCO
    PRIME_PBM: "primePbm",
    NON_PRIME_PBM: "nonPrimePbm",

    //BILLING LEVEL I - NOT MEDICARE OR ROX
    CHANGE_ADDRESS_BILLING_LEVEL_I: "changeAddressBillingLevelI",

    BEHAVIORAL_HEALTH_FIND_CARE: "behavioralHealthFindCare",
    ASK_A_PHARMACIST: "askAPharmacist",
    FIND_CARE__GET_CARE_NOW__ASK_A_PHARMACIST: "askAPharmacistFindCare",

    // Employee/Individual Assistance Program
    EAP_CAMBIA: "eap/cambia",
    EAP_REGENCE_SMALL_GROUP: "eap/regence-small-group",
    EAP_ASURIS_SMALL_GROUP: "eap/asuris-small-group",
    EAP_REGENCE_LARGE_GROUP: "eap/regence-large-group",
    EAP_ASURIS_LARGE_GROUP: "eap/asuris-large-group",
    IAP_REGENCE: "iap/regence",
    IAP_ASURIS: "iap/asuris",

    // Navigation
    NAV__HOME: "nav/home",
    NAV__YOUR_CARE: "nav/your-care",
    NAV__YOUR_CARE__TIMELINE: "nav/your-care/timeline",
    NAV__YOUR_CARE__DOCTORS_AND_PROVIDERS:
        "nav/your-care/doctors-and-providers",
    NAV__YOUR_CARE__MEDICATIONS: "nav/your-care/medications",
    NAV__COVERED_SERVICES: "nav/covered-services",
    NAV__COVERED_SERVICES__CHECK_BENEFITS:
        "nav/covered-services/check-benefits",
    NAV__COVERED_SERVICES__FIND_CARE: "nav/covered-services/find-care",
    NAV__COVERED_SERVICES__RESOURCES: "nav/covered-services/resources",
    NAV__FORMS_DOCUMENTS: "nav/forms-documents",
    NAV__CLAIMS_COSTS: "nav/claims-costs",
    NAV__CLAIMS_COSTS__OVERVIEW: "nav/claims-costs/overview",
    NAV__CLAIMS_COSTS__CLAIMS: "nav/claims-costs/claims",
    NAV__CLAIMS_COSTS__PAYMENTS: "nav/claims-costs/payments",
    NAV__CLAIMS_COSTS__ESTIMATE_COSTS: "nav/claims-costs/estimate-costs",
    NAV__CLAIMS_COSTS__EOB_STATEMENTS: "nav/claims-costs/eob-statements",
    NAV__CLAIMS_COSTS__TAX_FORMS: "nav/claims-costs/tax-forms",
    NAV__SUPPORT: "nav/claims-costs/support",

    // Benefits sidebar cards
    BENEFITS_MEDICAL__SIDEBAR_ESTIMATE_COSTS:
        "benefits/medical/sidebar/estimate-costs",
    SUPPORT__SIDEBAR_ESTIMATE_COSTS: "support/sidebar/estimate-costs",
    SUPPORT_DENTAL_BENEFIT_QUESTIONS: "support/dental/usable",

    BENEFITS_MEDICAL_RESOURCE_PAPERLESS_LETTERS:
        "benefitsMedicalResourcePaperlessLetters",

    // Virtual care options
    VIRTUAL_CARE_OPTIONS_GOV_PRO_TELEPHONE_LINE:
        "virtualCareOptionsGovProTelephoneLine",
    VIRTUAL_CARE_OPTIONS_DOD_TELEPHONE_LINE:
        "virtualCareOptionsDodTelephoneLine",
    VIRTUAL_CARE_OPTIONS_NURSE_CHAT_TELEPHONE_LINE:
        "virtualCareOptionsNurseChatTelephoneLine",
    VIRTUAL_CARE_OPTIONS_OPTUM_TELEPHONE_LINE:
        "virtualCareOptionsOptumTelephoneLine",

    // Pager
    PAGER_TELEMEDICINE_MDLIVE_VENDOR: "pagerTelemedicineMdliveVendor",
    PAGER_TELEMEDICINE_DOD_VENDOR: "pagerTelemedicineDodVendor",

    // Feature flags
    FEATURE_BEHAVIORAL_HEALTH_ENABLED: "featureBehavioralHealthEnabled",
    FEATURE_NATIVE_CLAIM_SUBMISSION_ENABLED:
        "featureNativeClaimSubmissionEnabled",
    FEATURE_NATIVE_HIDE_MESSAGE_CHAIN_ENABLED:
        "featureNativeHideMessageChainEnabed",
    FEATURE_NATIVE_APPEND_PREVIOUS_MSGS_ENABLED:
        "featureNativeAppendMsgsEnabled",
    MPS_ONLIFE_QUERY_ENABLED: "mpsOnlifeQueryEnabled",
    TAX_INFORMATION_IRS_DOCUMENT: "taxInformationIrsDocument",
    MPS_BENEFIT_PRODUCTID_QUERY_ENABLED: "mpsBenefitProductIdQueryEnabled",
    MPS_TEALIUM_DATA_QUERY_ENABLED: "mpsTealiumDataQueryEnabled",

    //Dental EOBs in EOB of Claims and Costs
    USABLE_EOB_DENTAL: "DentalEobUSAble",
    // Your-care
    YOUR_CARE_TIMELINE_USABLE: "your-care/timeline/usable",

    //HSQ Dental SSO for USAble
    DASHBOARD_QUICK_LINK_USABLE: "QuickLinkDentalSSOForUSAble",

    //Hide Dental Accums and Benefits on Dashboard for USAble
    DASHBOARD_DENTAL_BENEFITS_ACCUMS: "hideDentalAccumsandBenefits",
    //Hide Dental Accums on CLaims and COsts overview for USABle user
    USABLE_CLAIMS_COSTS_OVERVIEW: "hideDentalAccumsfor USABleeligible",

    ACCOUNT_PERSONAL_INFORMATION: "accountPersonalInformation",
    ACCOUNT_PERSONAL_INFORMATION_INDIVIDUAL:
        "accountPersonalInformationIndividual",
    ACCOUNT__SHOW_MEDICARE_ADDRESS_CARD:
        "personal-information/show-medicare-address-card",
    ACCOUNT__SHOW_ADDRESS_CARD: "personal-information/show-address-card",
    ACCOUNT__SHOW_SMS_CLAIMS_ALERTS_CARD:
        "preferences/show-sms-claims-alerts-toggle-card",
    ACCOUNT__SHOW_MEDICARE_CONTACT_PREFERENCES_CARD:
        "preferences/show-medicare-contact-preferences-card",
    ACCOUNT__FAMILY_SHARING_USE_BRIDGESPAN_AUTH_URL:
        "family-sharing/use-bridgespan-auth-url",
    ACCOUNT__FAMILY_SHARING_USE_ASURIS_NON_MEDICARE_AUTH_URL:
        "family-sharing/use-asuris-non-medicare-auth-url",
    ACCOUNT__FAMILY_SHARING_USE_ASURIS_MEDICARE_AUTH_URL:
        "family-sharing/use-asuris-medicare-auth-url",
    ACCOUNT__FAMILY_SHARING_USE_REGENCE_NON_MEDICARE_AUTH_URL:
        "family-sharing/use-regence-non-medicare-auth-url",
    ACCOUNT__FAMILY_SHARING_USE_REGENCE_MEDICARE_AUTH_URL:
        "family-sharing/use-regence-medicare-auth-url",
    // Qualtrics survey
    QUALTRICS_SURVEY_ENABLED: "qualtricsSurvey",
    //CVS consumer rules
    CVS_CHECK_COST_CARD_LINK: "web/pharmacy/check-cost-card/cvs-link",
    CVS_FIND_PHARMACY_CARD_LINK: "web/pharmacy/find-pharmacy-card/cvs-link",
    CVS_FIND_CARE_PHARMACY_LINK: "web/find-care/cvs-pharmacy-link",
    CVS_RETIRED_CAMPAIGNS_LINK: "web/campaigns/retired-campaigns/cvs-link",
    CVS_SORT_OPTIONS: "web/conditions/cvs-sort-options",
    CONDITIONS_TREATMENT_PANEL_SHOW_AVG_COST:
        "web/conditions/treatments-panel/show-avg-cost",
    PCOMP_CONDITION_TREATMENT_SHOW_AVG_COST:
        "web/conditions/pcomp-treatment-card/show-avg-cost",
    PHARMACY_SHOW_PCOMP_MEDICATION_CARD:
        "web/pharmacy/show-pcomp-medication-card",
    PHARMACY_SHOW_FORTIVE_MEDICATION_CARD:
        "web/pharmacy/show-fortive-medication-card",
    PHARMACY_PCOMP_FIND_PHARMACY_CARD_SEARCH:
        "web/pharmacy/pcomp-find-pharmacy-card/pharmacy-search",
    PHARMACY_PCOMP_FIND_PHARMACY_CARD_CVS:
        "web/pharmacy/pcomp-find-pharmacy-card/cvs-link",
    PHARMACY_CVS_HOME_DELIVERY_LINK: "web/pharmacy/home-delivery/cvs-link",
    CVS_TIMELINE_YOU_PAID_UNKNOWN: "web/timeline/medication/you-paid-unknown",
    BENEFITS_LAYOUT_SHOW_CONTENT: "web/benefits/layout-show-benefits",
    TREATMENT_DETAILS_CONNECT_WITH_PHARMACIST:
        "web/medications/treatment-detail/ask-pharmacist-link",
    CAMPAIGNS_SHOW_ASK_PHARMACIST_CARD:
        "web/campaigns/show-ask-pharmacist-card",
    CONDITION_DETAIL_SHOW_CONNECT_PHARMACIST_CARD:
        "web/conditions//show-connect-with-pharmacist-card",
    CONDITION_COMPARE_FETCH_RX_COVERAGE: "web/conditions/fetch-rx-coverage",
    BENEFITS_PHARMACY_RESEARCH_MEDICATIONS_CARD:
        "web/benefits/card/research-medications",
    DASHBOARD_INSIGHTS_CARD_RX_ENHANCED: "web/dashboard/card/insights-rx",
    HEADER_INSIGHTS_POPOVER_RX_ENHANCED: "web/header/popover/insights-rx",
    INSIGHTS_A1C: "web/insights/a1c",
    INSIGHTS_DIABETES_EYE_EXAM: "web/insights/diabetes-eye-exam",
    INSIGHTS_KIDNEY_HEALTH: "web/insights/kidney-health",
    RESEARCH_ASK_PHARMACIST_CARD: "web/research/card/ask-pharmacist",
    RESEARCH_CARD_DETAILS_TIER_COVERAGE:
        "web/research/card-details/tier-coverage",
    RESEARCH_CARD_DETAILS_TIER_COVERAGE_RESEARCH:
        "web/research/card-details/tier-coverage-research",
    RESEARCH_CARD_DETAILS_ASK_PHARMACIST_CARD:
        "web/research/card-details/ask-pharmacist",
    RESEARCH_CARD_DETAILS_PHARMACIST_LINK:
        "web/research/card-details/pharmacist-link",
    RESEARCH_CARD_TIER_COVERAGE: "web/research/card/tier-coverage",
    PHARMACY_MEDICATION_HISTORY_PAGE: "web/pharmacy/page/medication-history",
    PHARMACY_PAGE_RX_ENHANCED: "web/pharmacy/page/rx-enhanced",
    PHARMACY_PAGE_PRIME_ENABLED: "web/pharmacy/page/prime-enabled",
    PHARMACY_PAGE_MEDICINE_CABINET_CARD: "web/pharmacy/card/medicine-cabinet",
    RESOURCE_OMADA: "resource/omada",
    RESOURCE_EXPERIAN: "resource/experian",
    COSTS_OVERVIEW__SUBMIT_CLAIM_USE_MEDICARE_URL:
        "costs/overview/submit-claim-use-medicare-url",
    COSTS_OVERVIEW__SHOW_SUBMIT_CLAIMS_CARD:
        "costs/overview/show-submit-claims-card",
    COSTS_NATIVE_CLAIMS_DETAIL__SHOW_SUBMIT_CLAIMS_CARD:
        "costs/native-claims-detail/show-submit-claims-card",
    COSTS_NATIVE_CLAIMS__SHOW_SUBMIT_CLAIMS_CARD:
        "costs/native-claims/show-submit-claims-card",
    CLAIMS_DETAIL__SHOW_SUBMIT_CLAIMS_CARD:
        "claims/claims-detail/show-submit-claims-card",
    CLAIMS__SHOW_SUBMIT_CLAIMS_CARD: "claims/claims/show-submit-claims-card",
    ONBOARDING__GO_PAPERLESS_SHOW_MEDICARE_CARD:
        "onboarding/go-paperless/show-medicare-card",
    ONBOARDING__GO_PAPERLESS_USE_MEDICARE_PAGE_TITLE:
        "onboarding/go-paperless/use-medicare-page-title",
    FEATURE_VSP_GRAPH_ENABLED: "featureVSPGraphEnabled",
    DISCOUNTS_CARD_HEARING_AIDS: "resource/discounts/hearing-aids",
    RESOURCE__HEALTH_SUPPORT_PROGRAMS__CARE_MANAGEMENT:
        "resource/health-support-programs/care-management",
    RESOURCE__HEALTH_SUPPORT_PROGRAMS__CONDITION_MANAGER:
        "resource/health-support-programs/condition-manager",
    RESOURCE__HEALTH_SUPPORT_PROGRAMS__PALLIATIVE_CARE:
        "resource/health-support-programs/palliative-care",
    DATADOG_RUM_USERID_ENABLED: "web/datadog/rum/userid",
    IN_HOME_CARE_CARD_DOCTOR_ON_DEMAND: "resource/in-home/doctor-on-demand",
    IN_HOME_CARE_CARD_URGENT_CARE: "resource/in-home/urgent-care",
    IN_HOME_CARE_CARD_HEALTH_ASSESSMENT: "resource/in-home/health-assessment",
    IN_HOME_CARE_CARD_ADVANTMED: "resource/in-home/advantmed",

    //Dashboard resources
    DASHBOARD_MEDICARE_DISCOUNTS_RESOURCES:
        "web/dashboard/card/medicare-resources",
    DASHBOARD_ADVANTAGES_DISCOUNTS: "web/dashboard/card/advantages-discounts",

    IS_CLAIMS_SUBMISSION_AVAILABLE: "claim-submission/available",
    VISION_BENEFITS_V2_ENABLED: "feature/dfw-your-benefits-vision-v2-enabled",
    FIND_CARE_VIRTUAL_CARE_BEHAVIORAL_HEALTH:
        "care/find-care/virtual-care/behavioral-health-link",
    PREGNANCY_RESOURCE_CARD: "pregnancyResourceCard",
} as const

export const displayFeatureConfig: Record<
    string,
    {
        readonly allowRules?: ReadonlyArray<string>
        readonly denyRules?: ReadonlyArray<string>
        readonly oneOfRules?: ReadonlyArray<string>
    }
> = {
    [Features.DIGITAL_FIRST_ELIGIBLE]: {
        allowRules: [DISPLAY_RULES.DIGITAL_FIRST_ELIGIBLE],
    },
    [Features.EOB_STATEMENTS]: {
        allowRules: [DISPLAY_RULES.EOBS_ELIGIBLE],
    },
    [Features.ADVICE_24]: {
        allowRules: [DISPLAY_RULES.ADVICE_24_ELIGIBLE],
    },
    [Features.ASK_A_DOCTOR_CIRRUS]: {
        allowRules: [DISPLAY_RULES.ASK_A_DOCTOR_CIRRUS],
    },
    [Features.TELEHEALTH]: {
        allowRules: [DISPLAY_RULES.TELEHEALTH],
    },
    [Features.SPECIAL_REGION_WA_MANDATE]: {
        allowRules: [DISPLAY_RULES.SPECIAL_REGION_WA_MANDATE],
    },
    [Features.WA_SUBSTANCE_ABUSE]: {
        allowRules: [DISPLAY_RULES.WA_SUBSTANCE_ABUSE],
    },
    [Features.EXPERT_2ND_OPINION]: {
        allowRules: [DISPLAY_RULES.EXPERT_2ND_OPINION],
    },
    [Features.PCP_CHANGE]: {
        allowRules: [DISPLAY_RULES.PCP_CHANGE],
    },
    [Features.DME]: {
        allowRules: [DISPLAY_RULES.DME_ELIGIBLE],
    },
    [Features.AUDIENCE_ASO]: {
        allowRules: [DISPLAY_RULES.AUDIENCE_ASO],
    },
}

export const consumerFeatureConfig: Record<
    string,
    {
        readonly category: string
        readonly name: string
    }
> = {
    [Features.DASHBOARD_SPOTLIGHT]: {
        category: CONSUMER_RULES.DASHBOARD_SPOTLIGHT.CATEGORY,
        name: CONSUMER_RULES.DASHBOARD_SPOTLIGHT.NAME,
    },
    [Features.NAVIGATION_SPOTLIGHT]: {
        category: CONSUMER_RULES.NAVIGATION_SPOTLIGHT.CATEGORY,
        name: CONSUMER_RULES.NAVIGATION_SPOTLIGHT.NAME,
    },
    [Features.PHARMACY_FEEDBACK]: {
        category: CONSUMER_RULES.PHARMACY_FEEDBACK.CATEGORY,
        name: CONSUMER_RULES.PHARMACY_FEEDBACK.NAME,
    },
    [Features.EXPRESS_SCRIPT]: {
        category: CONSUMER_RULES.EXPRESS_SCRIPT.CATEGORY,
        name: CONSUMER_RULES.EXPRESS_SCRIPT.NAME,
    },
    [Features.RX_COVERAGE_ACTIVE]: {
        category: CONSUMER_RULES.RX_COVERAGE_ACTIVE.CATEGORY,
        name: CONSUMER_RULES.RX_COVERAGE_ACTIVE.NAME,
    },
    [Features.RX_PCOMP]: {
        category: CONSUMER_RULES.RX_PCOMP.CATEGORY,
        name: CONSUMER_RULES.RX_PCOMP.NAME,
    },
    [Features.RX_PCOMP_01_CABINET]: {
        category: CONSUMER_RULES.RX_PCOMP_01_CABINET.CATEGORY,
        name: CONSUMER_RULES.RX_PCOMP_01_CABINET.NAME,
    },
    [Features.RX_PCOMP_02_RESEARCH_MEDS]: {
        category: CONSUMER_RULES.RX_PCOMP_02_RESEARCH_MEDS.CATEGORY,
        name: CONSUMER_RULES.RX_PCOMP_02_RESEARCH_MEDS.NAME,
    },
    [Features.RX_PCOMP_03]: {
        category: CONSUMER_RULES.RX_PCOMP_03.CATEGORY,
        name: CONSUMER_RULES.RX_PCOMP_03.NAME,
    },
    [Features.RX_PCOMP_04]: {
        category: CONSUMER_RULES.RX_PCOMP_04.CATEGORY,
        name: CONSUMER_RULES.RX_PCOMP_04.NAME,
    },
    [Features.RX_PCOMP_05_LOCATION]: {
        category: CONSUMER_RULES.RX_PCOMP_05_LOCATION.CATEGORY,
        name: CONSUMER_RULES.RX_PCOMP_05_LOCATION.NAME,
    },
    [Features.RX_PRIME_COVERAGE]: {
        category: CONSUMER_RULES.RX_PRIME_COVERAGE.CATEGORY,
        name: CONSUMER_RULES.RX_PRIME_COVERAGE.NAME,
    },
    [Features.RX_PRIME_COVERAGE_RESEARCH]: {
        category: CONSUMER_RULES.RX_PRIME_COVERAGE_RESEARCH.CATEGORY,
        name: CONSUMER_RULES.RX_PRIME_COVERAGE_RESEARCH.NAME,
    },
    [Features.PREMIUM_PAYMENTS]: {
        category: CONSUMER_RULES.PREMIUM_PAYMENTS.CATEGORY,
        name: CONSUMER_RULES.PREMIUM_PAYMENTS.NAME,
    },
    [Features.PREMIUM_PAYMENT_ELIGIBLE]: {
        category: CONSUMER_RULES.PREMIUM_PAYMENT_ELIGIBLE.CATEGORY,
        name: CONSUMER_RULES.PREMIUM_PAYMENT_ELIGIBLE.NAME,
    },
    [Features.AUDIENCE_MEDICARE]: {
        category: CONSUMER_RULES.AUDIENCE_MEDICARE.CATEGORY,
        name: CONSUMER_RULES.AUDIENCE_MEDICARE.NAME,
    },
    [Features.COVERAGE_PENDING_ONLY]: {
        category: CONSUMER_RULES.COVERAGE_PENDING_ONLY.CATEGORY,
        name: CONSUMER_RULES.COVERAGE_PENDING_ONLY.NAME,
    },
    [Features.COVERAGE_MEDICAL_ACTIVE]: {
        category: CONSUMER_RULES.COVERAGE_MEDICAL_ACTIVE.CATEGORY,
        name: CONSUMER_RULES.COVERAGE_MEDICAL_ACTIVE.NAME,
    },
    [Features.WELLNESS_AVAILABLE]: {
        category: CONSUMER_RULES.WELLNESS_AVAILABLE.CATEGORY,
        name: CONSUMER_RULES.WELLNESS_AVAILABLE.NAME,
    },
    [Features.WELLNESS_AUTHORIZATION_REQUIRED]: {
        category: CONSUMER_RULES.WELLNESS_AUTHORIZATION.CATEGORY,
        name: CONSUMER_RULES.WELLNESS_AUTHORIZATION.NAME,
    },
    [Features.MA_HMO]: {
        category: CONSUMER_RULES.MA_HMO.CATEGORY,
        name: CONSUMER_RULES.MA_HMO.NAME,
    },
    [Features.MA_HMO_PPO]: {
        category: CONSUMER_RULES.MA_HMO_PPO.CATEGORY,
        name: CONSUMER_RULES.MA_HMO_PPO.NAME,
    },
    [Features.MA_PPO]: {
        category: CONSUMER_RULES.MA_PPO.CATEGORY,
        name: CONSUMER_RULES.MA_PPO.NAME,
    },
    [Features.MEDIGAP]: {
        category: CONSUMER_RULES.MEDIGAP.CATEGORY,
        name: CONSUMER_RULES.MEDIGAP.NAME,
    },
    [Features.MEDICARE_PDP]: {
        category: CONSUMER_RULES.MEDICARE_PDP.CATEGORY,
        name: CONSUMER_RULES.MEDICARE_PDP.NAME,
    },
    [Features.MEDICARE_PART_D]: {
        category: CONSUMER_RULES.MEDICARE_PART_D.CATEGORY,
        name: CONSUMER_RULES.MEDICARE_PART_D.NAME,
    },
    [Features.M3P_WIPRO_PAYMENT_PORTAL]: {
        category: CONSUMER_RULES.M3P_WIPRO_PAYMENT_PORTAL.CATEGORY,
        name: CONSUMER_RULES.M3P_WIPRO_PAYMENT_PORTAL.NAME,
    },
    [Features.ROX]: {
        category: CONSUMER_RULES.ROX.CATEGORY,
        name: CONSUMER_RULES.ROX.NAME,
    },
    [Features.COVERED_SERVICES_BENEFITS_MEDICAL_FIND_CARE]: {
        category: CONSUMER_RULES.MEDICAL_FIND_CARE.CATEGORY,
        name: CONSUMER_RULES.MEDICAL_FIND_CARE.NAME,
    },
    [Features.COVERED_SERVICES_BENEFITS_DENTAL_FIND_CARE]: {
        category: CONSUMER_RULES.DENTAL_FIND_CARE.CATEGORY,
        name: CONSUMER_RULES.DENTAL_FIND_CARE.NAME,
    },
    [Features.COVERED_SERVICES_BENEFITS_VISION_FIND_CARE]: {
        category: CONSUMER_RULES.VISION_FIND_CARE.CATEGORY,
        name: CONSUMER_RULES.VISION_FIND_CARE.NAME,
    },
    [Features.COVERED_SERVICES_BENEFITS_PHARMACY_FIND_CARE]: {
        category: CONSUMER_RULES.PHARMACY_FIND_CARE.CATEGORY,
        name: CONSUMER_RULES.PHARMACY_FIND_CARE.NAME,
    },
    [Features.COVERED_SERVICES_BENEFITS_RESOURCES_RX_HMO_PLUS]: {
        category:
            CONSUMER_RULES
                .COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_HMO_PLUS
                .CATEGORY,
        name: CONSUMER_RULES
            .COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_HMO_PLUS
            .NAME,
    },
    [Features.COVERED_SERVICES_BENEFITS_RESOURCES_RX_HMO]: {
        category:
            CONSUMER_RULES
                .COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_HMO
                .CATEGORY,
        name: CONSUMER_RULES
            .COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_HMO.NAME,
    },
    [Features.COVERED_SERVICES_BENEFITS_RESOURCES_RX_ALIGN]: {
        category:
            CONSUMER_RULES
                .COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_ALIGN
                .CATEGORY,
        name: CONSUMER_RULES
            .COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_ALIGN.NAME,
    },
    [Features.COVERED_SERVICES_BENEFITS_RESOURCES_RX_CLASSIC]: {
        category:
            CONSUMER_RULES
                .COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_CLASSIC
                .CATEGORY,
        name: CONSUMER_RULES
            .COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_CLASSIC
            .NAME,
    },
    [Features.COVERED_SERVICES_BENEFITS_RESOURCES_RX_PRIMARY]: {
        category:
            CONSUMER_RULES
                .COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_PRIMARY
                .CATEGORY,
        name: CONSUMER_RULES
            .COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_PRIMARY
            .NAME,
    },
    [Features.COVERED_SERVICES_BENEFITS_RESOURCES_RX_ENHANCED]: {
        category:
            CONSUMER_RULES
                .COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_ENHANCED
                .CATEGORY,
        name: CONSUMER_RULES
            .COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_ENHANCED
            .NAME,
    },
    [Features.COVERED_SERVICES_BENEFITS_RESOURCES_RX_BASIC]: {
        category:
            CONSUMER_RULES
                .COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_BASIC
                .CATEGORY,
        name: CONSUMER_RULES
            .COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_BASIC.NAME,
    },
    [Features.COVERED_SERVICES_BENEFITS_RESOURCES_RX_CORE]: {
        category:
            CONSUMER_RULES
                .COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_CORE
                .CATEGORY,
        name: CONSUMER_RULES
            .COVERED_SERVICES_CHECK_BENEFITS_RESOURCES_RX_PLAN_TYPE_CORE.NAME,
    },
    [Features.COVERED_SERVICES_FIND_CARE__FIND_A_PROVIDER_VISION]: {
        category:
            CONSUMER_RULES.COVERED_SERVICES_FIND_CARE__FIND_A_PROVIDER_VISION
                .CATEGORY,
        name: CONSUMER_RULES.COVERED_SERVICES_FIND_CARE__FIND_A_PROVIDER_VISION
            .NAME,
    },
    [Features.COVERED_SERVICES_FIND_CARE__FIND_A_PROVIDER_OTHER_VISION_BENEFITS]:
        {
            category:
                CONSUMER_RULES
                    .COVERED_SERVICES_FIND_CARE__FIND_A_PROVIDER_OTHER_VISION_BENEFITS
                    .CATEGORY,
            name: CONSUMER_RULES
                .COVERED_SERVICES_FIND_CARE__FIND_A_PROVIDER_OTHER_VISION_BENEFITS
                .NAME,
        },
    [Features.COVERED_SERVICES_FIND_CARE__FIND_A_PROVIDER_VSP_BENEFITS]: {
        category:
            CONSUMER_RULES
                .COVERED_SERVICES_FIND_CARE__FIND_A_PROVIDER_VSP_BENEFITS
                .CATEGORY,
        name: CONSUMER_RULES
            .COVERED_SERVICES_FIND_CARE__FIND_A_PROVIDER_VSP_BENEFITS.NAME,
    },
    [Features.COVERED_SERVICES__CHECK_BENEFITS_QUICK_ACTION]: {
        category:
            CONSUMER_RULES.COVERED_SERVICES__CHECK_BENEFITS__QUICK__ACTION
                .CATEGORY,
        name: CONSUMER_RULES.COVERED_SERVICES__CHECK_BENEFITS__QUICK__ACTION
            .NAME,
    },
    [Features.COVERED_SERVICES__CHECK_BENEFITS_LAYOUT]: {
        category:
            CONSUMER_RULES.COVERED_SERVICES__CHECK_BENEFITS__LAYOUT.CATEGORY,
        name: CONSUMER_RULES.COVERED_SERVICES__CHECK_BENEFITS__LAYOUT.NAME,
    },
    [Features.COVERED_SERVICES__YOUR_BENEFITS_VSP_HCA_SEBB]: {
        category:
            CONSUMER_RULES.COVERED_SERVICE__YOUR_BENEFITS_VSP_HCA_SEBB.CATEGORY,
        name: CONSUMER_RULES.COVERED_SERVICE__YOUR_BENEFITS_VSP_HCA_SEBB.NAME,
    },
    [Features.VISION_BENEFITS_TAB]: {
        category: CONSUMER_RULES.VISION_BENEFITS_TAB.CATEGORY,
        name: CONSUMER_RULES.VISION_BENEFITS_TAB.NAME,
    },
    [Features.PHARMACY_BENEFITS_TAB]: {
        category: CONSUMER_RULES.PHARMACY_BENEFITS_TAB.CATEGORY,
        name: CONSUMER_RULES.PHARMACY_BENEFITS_TAB.NAME,
    },
    [Features.MEDICAL_BENEFITS_TAB]: {
        category: CONSUMER_RULES.MEDICAL_BENEFITS_TAB.CATEGORY,
        name: CONSUMER_RULES.MEDICAL_BENEFITS_TAB.NAME,
    },
    [Features.GENERIC_VISION_BENEFITS]: {
        category: CONSUMER_RULES.VISION_BENEFITS_GENERIC.CATEGORY,
        name: CONSUMER_RULES.VISION_BENEFITS_GENERIC.NAME,
    },
    [Features.PEBB_VISION_BENEFITS]: {
        category: CONSUMER_RULES.VISION_BENEFITS_PEBB.CATEGORY,
        name: CONSUMER_RULES.VISION_BENEFITS_PEBB.NAME,
    },
    [Features.SEBB_VISION_BENEFITS]: {
        category: CONSUMER_RULES.VISION_BENEFITS_SEBB.CATEGORY,
        name: CONSUMER_RULES.VISION_BENEFITS_SEBB.NAME,
    },
    [Features.HCA_SMARTHEALTH_EARNED]: {
        category: CONSUMER_RULES.HCA_SMARTHEALTH_EARNED.CATEGORY,
        name: CONSUMER_RULES.HCA_SMARTHEALTH_EARNED.NAME,
    },
    [Features.HCA_SMARTHEALTH_NOT_EARNED]: {
        category: CONSUMER_RULES.HCA_SMARTHEALTH_NOT_EARNED.CATEGORY,
        name: CONSUMER_RULES.HCA_SMARTHEALTH_NOT_EARNED.NAME,
    },
    [Features.HCA_SMARTHEALTH_OTHER]: {
        category: CONSUMER_RULES.HCA_SMARTHEALTH_OTHER.CATEGORY,
        name: CONSUMER_RULES.HCA_SMARTHEALTH_OTHER.NAME,
    },
    [Features.CIS]: {
        category: CONSUMER_RULES.CIS.CATEGORY,
        name: CONSUMER_RULES.CIS.NAME,
    },
    [Features.HCA_PHARMACY_FIND_CARE]: {
        category: CONSUMER_RULES.HCA_PHARMACY_FIND_CARE.CATEGORY,
        name: CONSUMER_RULES.HCA_PHARMACY_FIND_CARE.NAME,
    },
    [Features.SEBB_VISION_FIND_CARE]: {
        category: CONSUMER_RULES.SEBB_VISION_FIND_CARE.CATEGORY,
        name: CONSUMER_RULES.SEBB_VISION_FIND_CARE.NAME,
    },
    [Features.DISPATCH_HEALTH]: {
        category: CONSUMER_RULES.DISPATCH_HEALTH.CATEGORY,
        name: CONSUMER_RULES.DISPATCH_HEALTH.NAME,
    },
    [Features.M3P_WIPRO]: {
        category: CONSUMER_RULES.M3P_WIPRO.CATEGORY,
        name: CONSUMER_RULES.M3P_WIPRO.NAME,
    },
    [Features.DISPATCH_HEALTH_URL_ID]: {
        category: CONSUMER_RULES.DISPATCH_HEALTH_URL_ID.CATEGORY,
        name: CONSUMER_RULES.DISPATCH_HEALTH_URL_ID.NAME,
    },
    [Features.DISPATCH_HEALTH_URL_OR]: {
        category: CONSUMER_RULES.DISPATCH_HEALTH_URL_OR.CATEGORY,
        name: CONSUMER_RULES.DISPATCH_HEALTH_URL_OR.NAME,
    },
    [Features.DISPATCH_HEALTH_URL_UT]: {
        category: CONSUMER_RULES.DISPATCH_HEALTH_URL_UT.CATEGORY,
        name: CONSUMER_RULES.DISPATCH_HEALTH_URL_UT.NAME,
    },
    [Features.DISPATCH_HEALTH_URL_WA]: {
        category: CONSUMER_RULES.DISPATCH_HEALTH_URL_WA.CATEGORY,
        name: CONSUMER_RULES.DISPATCH_HEALTH_URL_WA.NAME,
    },
    [Features.DISPATCH_HEALTH_URL_ASURIS]: {
        category: CONSUMER_RULES.DISPATCH_HEALTH_URL_ASURIS.CATEGORY,
        name: CONSUMER_RULES.DISPATCH_HEALTH_URL_ASURIS.NAME,
    },
    [Features.DISPATCH_HEALTH_URL_BSH]: {
        category: CONSUMER_RULES.DISPATCH_HEALTH_URL_BSH.CATEGORY,
        name: CONSUMER_RULES.DISPATCH_HEALTH_URL_BSH.NAME,
    },
    [Features.RESOURCE__WINFERTILITY]: {
        category: CONSUMER_RULES.RESOURCE__WINFERTILITY.CATEGORY,
        name: CONSUMER_RULES.RESOURCE__WINFERTILITY.NAME,
    },
    [Features.RESOURCE_WINFERTILITY__URL_ID]: {
        category: CONSUMER_RULES.RESOURCE_WINFERTILITY__URL_ID.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_WINFERTILITY__URL_ID.NAME,
    },
    [Features.RESOURCE_WINFERTILITY__URL_OR]: {
        category: CONSUMER_RULES.RESOURCE_WINFERTILITY__URL_OR.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_WINFERTILITY__URL_OR.NAME,
    },
    [Features.RESOURCE_WINFERTILITY__URL_UT]: {
        category: CONSUMER_RULES.RESOURCE_WINFERTILITY__URL_UT.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_WINFERTILITY__URL_UT.NAME,
    },
    [Features.RESOURCE_WINFERTILITY__URL_WA]: {
        category: CONSUMER_RULES.RESOURCE_WINFERTILITY__URL_WA.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_WINFERTILITY__URL_WA.NAME,
    },
    [Features.RESOURCE_WINFERTILITY__URL_ASURIS]: {
        category: CONSUMER_RULES.RESOURCE_WINFERTILITY__URL_ASURIS.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_WINFERTILITY__URL_ASURIS.NAME,
    },
    [Features.RESOURCE_HCA_SMARTHEALTH]: {
        category: CONSUMER_RULES.RESOURCE_HCA_SMARTHEALTH.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_HCA_SMARTHEALTH.NAME,
    },
    [Features.HCA_GENERAL]: {
        category: CONSUMER_RULES.HCA_GENERAL.CATEGORY,
        name: CONSUMER_RULES.HCA_GENERAL.NAME,
    },
    [Features.RESOURCE_SELF_GUIDED_EMOTIONAL_HEALTH]: {
        category: CONSUMER_RULES.SELF_GUIDED_EMOTIONAL_HEALTH.CATEGORY,
        name: CONSUMER_RULES.SELF_GUIDED_EMOTIONAL_HEALTH.NAME,
    },
    [Features.RESOURCE_MDLIVE_PCP]: {
        category: CONSUMER_RULES.RESOURCE_MDLIVE_PCP.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_MDLIVE_PCP.NAME,
    },
    [Features.RESOURCE_MDLIVE_NO_BH]: {
        category: CONSUMER_RULES.RESOURCE_MDLIVE_NO_BH.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_MDLIVE_NO_BH.NAME,
    },
    [Features.RESOURCE_MDLIVE_BH]: {
        category: CONSUMER_RULES.RESOURCE_MDLIVE_BH.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_MDLIVE_BH.NAME,
    },
    [Features.RESOURCE_MDLIVE]: {
        category: CONSUMER_RULES.RESOURCE_MDLIVE.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_MDLIVE.NAME,
    },
    [Features.RESOURCE_HINGE_HEALTH]: {
        category: CONSUMER_RULES.RESOURCE_HINGE_HEALTH.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_HINGE_HEALTH.NAME,
    },
    [Features.RESOURCE_EXPERT_OPINION]: {
        category: CONSUMER_RULES.RESOURCE_EXPERT_OPINION.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_EXPERT_OPINION.NAME,
    },
    [Features.RESOURCE_DOCTOR_ON_DEMAND]: {
        category: CONSUMER_RULES.RESOURCE_DOCTOR_ON_DEMAND.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_DOCTOR_ON_DEMAND.NAME,
    },
    [Features.RESOURCE_ASK_A_DOCTOR]: {
        category: CONSUMER_RULES.RESOURCE_ASK_A_DOCTOR.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_ASK_A_DOCTOR.NAME,
    },
    [Features.RESOURCE_ADVICE_24]: {
        category: CONSUMER_RULES.RESOURCE_ADVICE_24.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_ADVICE_24.NAME,
    },
    [Features.RESOURCE_PREVENTATIVE_CARE]: {
        category: CONSUMER_RULES.RESOURCE_PREVENTATIVE_CARE.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_PREVENTATIVE_CARE.NAME,
    },
    [Features.RESOURCE_EMPOWER]: {
        category: CONSUMER_RULES.RESOURCE_EMPOWER.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_EMPOWER.NAME,
    },
    [Features.DASHBOARD_BANNER__PREMIUM_PAYMENT_V2]: {
        category: CONSUMER_RULES.DASHBOARD_BANNER__PREMIUM_PAYMENT_V2.CATEGORY,
        name: CONSUMER_RULES.DASHBOARD_BANNER__PREMIUM_PAYMENT_V2.NAME,
    },
    [Features.DASHBOARD__BANNER_CONTINUITY_OF_CARE]: {
        category: CONSUMER_RULES.DASHBOARD__BANNER_CONTINUITY_OF_CARE.CATEGORY,
        name: CONSUMER_RULES.DASHBOARD__BANNER_CONTINUITY_OF_CARE.NAME,
    },
    [Features.DASHBOARD_CARD_EMPOWER]: {
        category: CONSUMER_RULES.DASHBOARD_CARD_EMPOWER.CATEGORY,
        name: CONSUMER_RULES.DASHBOARD_CARD_EMPOWER.NAME,
    },
    [Features.DASHBOARD_LANGUAGE_SPANISH]: {
        category: CONSUMER_RULES.DASHBOARD_LANGUAGE_SPANISH.CATEGORY,
        name: CONSUMER_RULES.DASHBOARD_LANGUAGE_SPANISH.NAME,
    },
    [Features.DASHBOARD_DOCUMENTS__SPANISH_DENTAL]: {
        category: CONSUMER_RULES.DASHBOARD_DOCUMENTS__SPANISH_DENTAL.CATEGORY,
        name: CONSUMER_RULES.DASHBOARD_DOCUMENTS__SPANISH_DENTAL.NAME,
    },
    [Features.DASHBOARD_DOCUMENTS__SPANISH_MEDICAL]: {
        category: CONSUMER_RULES.DASHBOARD_DOCUMENTS__SPANISH_MEDICAL.CATEGORY,
        name: CONSUMER_RULES.DASHBOARD_DOCUMENTS__SPANISH_MEDICAL.NAME,
    },
    [Features.DASHBOARD_QUICK_ACTION__MESSAGE_CENTER]: {
        category:
            CONSUMER_RULES.DASHBOARD_QUICK_ACTION__MESSAGE_CENTER.CATEGORY,
        name: CONSUMER_RULES.DASHBOARD_QUICK_ACTION__MESSAGE_CENTER.NAME,
    },
    [Features.DASHBOARD__FIND_CARE_CARD]: {
        category: CONSUMER_RULES.DASHBOARD__FIND_CARE_CARD.CATEGORY,
        name: CONSUMER_RULES.DASHBOARD__FIND_CARE_CARD.NAME,
    },
    [Features.DASHBOARD__USABLE_DENTAL_CARD]: {
        category: CONSUMER_RULES.DASHBOARD__USABLE_DENTAL_CARD.CATEGORY,
        name: CONSUMER_RULES.DASHBOARD__USABLE_DENTAL_CARD.NAME,
    },
    [Features.DASHBOARD__BEHAVIORAL_HEALTH_CARD]: {
        category: CONSUMER_RULES.DASHBOARD__BEHAVIORAL_HEALTH_CARD.CATEGORY,
        name: CONSUMER_RULES.DASHBOARD__BEHAVIORAL_HEALTH_CARD.NAME,
    },
    [Features.BENEFITS__USABLE_DENTAL_CARD]: {
        category: CONSUMER_RULES.BENEFITS__USABLE_DENTAL_CARD.CATEGORY,
        name: CONSUMER_RULES.BENEFITS__USABLE_DENTAL_CARD.NAME,
    },
    [Features.BENEFITS__VISION_CARD]: {
        category: CONSUMER_RULES.BENEFITS__VISION_CARD.CATEGORY,
        name: CONSUMER_RULES.BENEFITS__VISION_CARD.NAME,
    },
    [Features.BENEFITS__MEDICARE_VSP_CARD]: {
        category: CONSUMER_RULES.BENEFITS__MEDICARE_VSP_CARD.CATEGORY,
        name: CONSUMER_RULES.BENEFITS__MEDICARE_VSP_CARD.NAME,
    },
    [Features.BENEFITS__DISCLAIMER_USE_MEDICARE_URL]: {
        category: CONSUMER_RULES.BENEFITS__DISCLAIMER_USE_MEDICARE_URL.CATEGORY,
        name: CONSUMER_RULES.BENEFITS__DISCLAIMER_USE_MEDICARE_URL.NAME,
    },
    [Features.BENEFITS__MEDICAL_INDY_RESOURCES_CARD]: {
        category: CONSUMER_RULES.BENEFITS__MEDICAL_INDY_RESOURCES_CARD.CATEGORY,
        name: CONSUMER_RULES.BENEFITS__MEDICAL_INDY_RESOURCES_CARD.NAME,
    },
    [Features.BENEFITS__MEDICAL_MEDICARE_RESOURCES_CARD]: {
        category:
            CONSUMER_RULES.BENEFITS__MEDICAL_MEDICARE_RESOURCES_CARD.CATEGORY,
        name: CONSUMER_RULES.BENEFITS__MEDICAL_MEDICARE_RESOURCES_CARD.NAME,
    },
    [Features.BENEFITS__MEDICAL_SHOW_MEDICARE_BOOKLET]: {
        category:
            CONSUMER_RULES.BENEFITS__MEDICAL_SHOW_MEDICARE_BOOKLET.CATEGORY,
        name: CONSUMER_RULES.BENEFITS__MEDICAL_SHOW_MEDICARE_BOOKLET.NAME,
    },
    [Features.BENEFITS__PHARMACY_INDY_RESOURCES_CARD]: {
        category:
            CONSUMER_RULES.BENEFITS__PHARMACY_INDY_RESOURCES_CARD.CATEGORY,
        name: CONSUMER_RULES.BENEFITS__PHARMACY_INDY_RESOURCES_CARD.NAME,
    },
    [Features.BENEFITS__PHARMACY_MEDICARE_RESOURCES_CARD]: {
        category:
            CONSUMER_RULES.BENEFITS__PHARMACY_MEDICARE_RESOURCES_CARD.CATEGORY,
        name: CONSUMER_RULES.BENEFITS__PHARMACY_MEDICARE_RESOURCES_CARD.NAME,
    },
    [Features.BENEFITS__DENTAL_RESOURCES_CARD]: {
        category: CONSUMER_RULES.BENEFITS__DENTAL_RESOURCES_CARD.CATEGORY,
        name: CONSUMER_RULES.BENEFITS__DENTAL_RESOURCES_CARD.NAME,
    },
    [Features.BENEFITS__MEDIGAP_RESOURCES_CARD]: {
        category: CONSUMER_RULES.BENEFITS__MEDIGAP_RESOURCES_CARD.CATEGORY,
        name: CONSUMER_RULES.BENEFITS__MEDIGAP_RESOURCES_CARD.NAME,
    },
    [Features.RESOURCE_RESEARCH_RX]: {
        category: CONSUMER_RULES.RESOURCE_RESEARCH_RX.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_RESEARCH_RX.NAME,
    },
    [Features.RESOURCE_SILVER_AND_FIT]: {
        category: CONSUMER_RULES.RESOURCE_SILVER_AND_FIT.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_SILVER_AND_FIT.NAME,
    },
    [Features.RESOURCE_CARE_MANAGEMENT]: {
        category: CONSUMER_RULES.RESOURCE_CARE_MANAGEMENT.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_CARE_MANAGEMENT.NAME,
    },
    [Features.RESOURCE_BABYWISE]: {
        category: CONSUMER_RULES.RESOURCE_BABYWISE.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_BABYWISE.NAME,
    },
    [Features.RESOURCE_BUMP_TO_BABY]: {
        category: CONSUMER_RULES.RESOURCE_BUMP_TO_BABY.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_BUMP_TO_BABY.NAME,
    },
    [Features.RESOURCE_PALLIATIVE_CARE]: {
        category: CONSUMER_RULES.RESOURCE_PALLIATIVE_CARE.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_PALLIATIVE_CARE.NAME,
    },
    [Features.RESOURCE_CONDITION_MANAGER]: {
        category: CONSUMER_RULES.RESOURCE_CONDITION_MANAGER.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_CONDITION_MANAGER.NAME,
    },
    [Features.RESOURCE_GENDER_AFFIRMING_CARE]: {
        category: CONSUMER_RULES.RESOURCE_GENDER_AFFIRMING_CARE.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_GENDER_AFFIRMING_CARE.NAME,
    },
    [Features.RESOURCE_TELEHEALTH]: {
        category: CONSUMER_RULES.RESOURCE_TELEHEALTH.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_TELEHEALTH.NAME,
    },
    [Features.RESOURCE_ESTIMATE_COSTS]: {
        category: CONSUMER_RULES.RESOURCE_ESTIMATE_COSTS.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_ESTIMATE_COSTS.NAME,
    },
    [Features.RESOURCE_ABLE_TO]: {
        category: CONSUMER_RULES.RESOURCE_ABLE_TO.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_ABLE_TO.NAME,
    },
    [Features.FOOTER__ABLE_TO]: {
        category: CONSUMER_RULES.FOOTER__ABLE_TO.CATEGORY,
        name: CONSUMER_RULES.FOOTER__ABLE_TO.NAME,
    },
    [Features.RESOURCE_WINCO_EMPLOYEE_BENEFITS]: {
        category: CONSUMER_RULES.RESOURCE_WINCO_EMPLOYEE_BENEFITS.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_WINCO_EMPLOYEE_BENEFITS.NAME,
    },
    [Features.RESOURCE_WA_SUBSTANCE_ABUSE]: {
        category: CONSUMER_RULES.RESOURCE_WA_SUBSTANCE_ABUSE.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_WA_SUBSTANCE_ABUSE.NAME,
    },
    [Features.RESOURCE_ASANTE_EAP]: {
        category: CONSUMER_RULES.RESOURCE_ASANTE_EAP.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_ASANTE_EAP.NAME,
    },
    [Features.RESOURCE_MEDICAL_SUPPLIES]: {
        category: CONSUMER_RULES.RESOURCE_MEDICAL_SUPPLIES.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_MEDICAL_SUPPLIES.NAME,
    },
    [Features.RESOURCE_UMP_HEALTH_PEBB]: {
        category: CONSUMER_RULES.RESOURCE_UMP_HEALTH_PEBB.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_UMP_HEALTH_PEBB.NAME,
    },
    [Features.RESOURCE_UMP_HEALTH_SEBB]: {
        category: CONSUMER_RULES.RESOURCE_UMP_HEALTH_SEBB.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_UMP_HEALTH_SEBB.NAME,
    },
    [Features.RESOURCE_UMP_BENEFITS_PEBB]: {
        category: CONSUMER_RULES.RESOURCE_UMP_BENEFITS_PEBB.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_UMP_BENEFITS_PEBB.NAME,
    },
    [Features.RESOURCE_UMP_BENEFITS_SEBB]: {
        category: CONSUMER_RULES.RESOURCE_UMP_BENEFITS_SEBB.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_UMP_BENEFITS_SEBB.NAME,
    },
    // inactive after 1/1/2025
    [Features.RESOURCE_OVER_THE_COUNTER]: {
        category: CONSUMER_RULES.RESOURCE_OVER_THE_COUNTER.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_OVER_THE_COUNTER.NAME,
    },
    // active after 1/1/2025
    [Features.RESOURCE_MEDADVANTAGE_INCENTIVES]: {
        category: CONSUMER_RULES.RESOURCE_MEDADVANTAGE_INCENTIVES.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_MEDADVANTAGE_INCENTIVES.NAME,
    },
    // active after 1/1/2025
    [Features.RESOURCE_MEDADVANTAGE_INCENTIVES_OTC]: {
        category: CONSUMER_RULES.RESOURCE_MEDADVANTAGE_INCENTIVES_OTC.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_MEDADVANTAGE_INCENTIVES_OTC.NAME,
    },
    [Features.PEBB_24_HOUR_NURSELINE]: {
        category: CONSUMER_RULES.PEBB_24_HOUR_NURSELINE.CATEGORY,
        name: CONSUMER_RULES.PEBB_24_HOUR_NURSELINE.NAME,
    },
    [Features.SEBB_24_HOUR_NURSELINE]: {
        category: CONSUMER_RULES.SEBB_24_HOUR_NURSELINE.CATEGORY,
        name: CONSUMER_RULES.SEBB_24_HOUR_NURSELINE.NAME,
    },
    [Features.HSA_AVAILABLE]: {
        category: CONSUMER_RULES.HSA_AVAILABLE.CATEGORY,
        name: CONSUMER_RULES.HSA_AVAILABLE.NAME,
    },
    [Features.ESTIMATE_COSTS_VSP]: {
        category: CONSUMER_RULES.ESTIMATE_COSTS_VSP.CATEGORY,
        name: CONSUMER_RULES.ESTIMATE_COSTS_VSP.NAME,
    },
    [Features.SUPPORT_PEBB]: {
        category: CONSUMER_RULES.SUPPORT_PEBB.CATEGORY,
        name: CONSUMER_RULES.SUPPORT_PEBB.NAME,
    },
    [Features.SUPPORT_SEBB]: {
        category: CONSUMER_RULES.SUPPORT_SEBB.CATEGORY,
        name: CONSUMER_RULES.SUPPORT_SEBB.NAME,
    },
    [Features.ALERT_WA_MANDATE]: {
        category: CONSUMER_RULES.ALERT_WA_MANDATE.CATEGORY,
        name: CONSUMER_RULES.ALERT_WA_MANDATE.NAME,
    },
    [Features.CHATBOT_AVAILABLE]: {
        category: CONSUMER_RULES.CHATBOT_AVAILABLE.CATEGORY,
        name: CONSUMER_RULES.CHATBOT_AVAILABLE.NAME,
    },
    [Features.COBROWSE_AVAILABLE]: {
        category: CONSUMER_RULES.COBROWSE_AVAILABLE.CATEGORY,
        name: CONSUMER_RULES.COBROWSE_AVAILABLE.NAME,
    },
    [Features.FORTIVE_LIVONGO_HYPERTENSION]: {
        category: CONSUMER_RULES.FORTIVE_LIVONGO_HYPERTENSION.CATEGORY,
        name: CONSUMER_RULES.FORTIVE_LIVONGO_HYPERTENSION.NAME,
    },
    [Features.FORTIVE_VIRTA_DIABETES]: {
        category: CONSUMER_RULES.FORTIVE_VIRTA_DIABETES.CATEGORY,
        name: CONSUMER_RULES.FORTIVE_VIRTA_DIABETES.NAME,
    },
    [Features.FORTIVE_PROGENY_INFERTILITY]: {
        category: CONSUMER_RULES.FORTIVE_PROGENY_INFERTILITY.CATEGORY,
        name: CONSUMER_RULES.FORTIVE_PROGENY_INFERTILITY.NAME,
    },
    [Features.FORTIVE_SPRING_HEALTH]: {
        category: CONSUMER_RULES.FORTIVE_SPRING_HEALTH.CATEGORY,
        name: CONSUMER_RULES.FORTIVE_SPRING_HEALTH.NAME,
    },
    [Features.PRIMARY_CARE_PROVIDER]: {
        category: CONSUMER_RULES.PRIMARY_CARE_PROVIDER.CATEGORY,
        name: CONSUMER_RULES.PRIMARY_CARE_PROVIDER.NAME,
    },
    [Features.ACCOUNT_SETTINGS_MEDICAL_PAPERLESS]: {
        category: CONSUMER_RULES.ACCOUNT_SETTINGS_MEDICAL_PAPERLESS.CATEGORY,
        name: CONSUMER_RULES.ACCOUNT_SETTINGS_MEDICAL_PAPERLESS.NAME,
    },
    [Features.ACCOUNT_SETTINGS_PHARMACY_PAPERLESS]: {
        category: CONSUMER_RULES.ACCOUNT_SETTINGS_PHARMACY_PAPERLESS.CATEGORY,
        name: CONSUMER_RULES.ACCOUNT_SETTINGS_PHARMACY_PAPERLESS.NAME,
    },
    [Features.ACCOUNT_SETTINGS_ANOC_PAPERLESS]: {
        category: CONSUMER_RULES.ACCOUNT_SETTINGS_ANOC_PAPERLESS.CATEGORY,
        name: CONSUMER_RULES.ACCOUNT_SETTINGS_ANOC_PAPERLESS.NAME,
    },
    [Features.ACCOUNT_SETTINGS_SSO_AUTHORIZATION]: {
        category: CONSUMER_RULES.ACCOUNT_SETTINGS_SSO_AUTHORIZATION.CATEGORY,
        name: CONSUMER_RULES.ACCOUNT_SETTINGS_SSO_AUTHORIZATION.NAME,
    },
    [Features.ACCOUNT_SETTINGS_WELLNESS_AUTHORIZATION]: {
        category:
            CONSUMER_RULES.ACCOUNT_SETTINGS_WELLNESS_AUTHORIZATION.CATEGORY,
        name: CONSUMER_RULES.ACCOUNT_SETTINGS_WELLNESS_AUTHORIZATION.NAME,
    },
    [Features.ACCOUNT_SETTINGS_INTEROP_ELIGIBLE]: {
        category: CONSUMER_RULES.ACCOUNT_SETTINGS_INTEROP_ELIGIBLE.CATEGORY,
        name: CONSUMER_RULES.ACCOUNT_SETTINGS_INTEROP_ELIGIBLE.NAME,
    },
    [Features.ACCOUNT_SETTINGS_FAMILY_SHARING]: {
        category: CONSUMER_RULES.ACCOUNT_SETTINGS_FAMILY_SHARING.CATEGORY,
        name: CONSUMER_RULES.ACCOUNT_SETTINGS_FAMILY_SHARING.NAME,
    },
    [Features.ACCOUNT_SETTINGS_SIDEBAR_CHANGE_ADDRESS]: {
        category:
            CONSUMER_RULES.ACCOUNT_SETTINGS_SIDEBAR_CHANGE_ADDRESS.CATEGORY,
        name: CONSUMER_RULES.ACCOUNT_SETTINGS_SIDEBAR_CHANGE_ADDRESS.NAME,
    },
    [Features.ACCOUNT_SETTINGS__TAB__DATA_AND_PRIVACY]: {
        category:
            CONSUMER_RULES.ACCOUNT_SETTINGS__TAB__DATA_AND_PRIVACY.CATEGORY,
        name: CONSUMER_RULES.ACCOUNT_SETTINGS__TAB__DATA_AND_PRIVACY.NAME,
    },
    [Features.ACCOUNT_SETTINGS__DATA_AND_PRIVACY__MARKETING_AUTHORIZATION]: {
        category:
            CONSUMER_RULES
                .ACCOUNT_SETTINGS__DATA_AND_PRIVACY__MARKETING_AUTHORIZATION
                .CATEGORY,
        name: CONSUMER_RULES
            .ACCOUNT_SETTINGS__DATA_AND_PRIVACY__MARKETING_AUTHORIZATION.NAME,
    },
    [Features.ACCOUNT_SETTINGS__DATA_AND_PRIVACY__SSO_AUTHORIZATION]: {
        category:
            CONSUMER_RULES.ACCOUNT_SETTINGS__DATA_AND_PRIVACY__SSO_AUTHORIZATION
                .CATEGORY,
        name: CONSUMER_RULES
            .ACCOUNT_SETTINGS__DATA_AND_PRIVACY__SSO_AUTHORIZATION.NAME,
    },
    [Features.ACCOUNT_SETTINGS__DATA_AND_PRIVACY__WELLNESS_AUTHORIZATION]: {
        category:
            CONSUMER_RULES
                .ACCOUNT_SETTINGS__DATA_AND_PRIVACY__WELLNESS_AUTHORIZATION
                .CATEGORY,
        name: CONSUMER_RULES
            .ACCOUNT_SETTINGS__DATA_AND_PRIVACY__WELLNESS_AUTHORIZATION.NAME,
    },
    [Features.ACCOUNT_SETTINGS__DATA_AND_PRIVACY__INTEROP_AUTHORIZATION]: {
        category:
            CONSUMER_RULES
                .ACCOUNT_SETTINGS__DATA_AND_PRIVACY__INTEROP_AUTHORIZATION
                .CATEGORY,
        name: CONSUMER_RULES
            .ACCOUNT_SETTINGS__DATA_AND_PRIVACY__INTEROP_AUTHORIZATION.NAME,
    },
    [Features.ACCOUNT_SETTINGS__DATA_AND_PRIVACY__SIDEBAR__FAMILY_SHARING]: {
        category:
            CONSUMER_RULES
                .ACCOUNT_SETTINGS__DATA_AND_PRIVACY__SIDEBAR__FAMILY_SHARING
                .CATEGORY,
        name: CONSUMER_RULES
            .ACCOUNT_SETTINGS__DATA_AND_PRIVACY__SIDEBAR__FAMILY_SHARING.NAME,
    },
    [Features.ACCOUNT_SETTINGS__DATA_AND_PRIVACY__SIDEBAR__SHARE]: {
        category:
            CONSUMER_RULES.ACCOUNT_SETTINGS__DATA_AND_PRIVACY__SIDEBAR__SHARE
                .CATEGORY,
        name: CONSUMER_RULES.ACCOUNT_SETTINGS__DATA_AND_PRIVACY__SIDEBAR__SHARE
            .NAME,
    },
    [Features.ACCOUNT_SETTINGS__FINANCE__HIDE_PAYMENT_HISTORY]: {
        category:
            CONSUMER_RULES.ACCOUNT_SETTINGS__FINANCE__HIDE_PAYMENT_HISTORY
                .CATEGORY,
        name: CONSUMER_RULES.ACCOUNT_SETTINGS__FINANCE__HIDE_PAYMENT_HISTORY
            .NAME,
    },
    [Features.ACCOUNT_SETTINGS__FINANCE__SIDEBAR__MAKE_PAYMENT]: {
        category:
            CONSUMER_RULES.ACCOUNT_SETTINGS__FINANCE__SIDEBAR__MAKE_PAYMENT
                .CATEGORY,
        name: CONSUMER_RULES.ACCOUNT_SETTINGS__FINANCE__SIDEBAR__MAKE_PAYMENT
            .NAME,
    },
    [Features.ACCOUNT_SETTINGS_PAPERLESS_PREFERENCE]: {
        category: CONSUMER_RULES.ACCOUNT_SETTINGS_PAPERLESS_PREFERENCE.CATEGORY,
        name: CONSUMER_RULES.ACCOUNT_SETTINGS_PAPERLESS_PREFERENCE.NAME,
    },
    [Features.COVERED_SERVICES__CHECK_BENEFITS__BANNER_CONTINUITY_OF_CARE]: {
        category:
            CONSUMER_RULES
                .COVERED_SERVICES__CHECK_BENEFITS__BANNER_CONTINUITY_OF_CARE
                .CATEGORY,
        name: CONSUMER_RULES
            .COVERED_SERVICES__CHECK_BENEFITS__BANNER_CONTINUITY_OF_CARE.NAME,
    },
    [Features.COVERED_SERVICES__CHECK_BENEFITS__TAB__DENTAL]: {
        category:
            CONSUMER_RULES.COVERED_SERVICES__CHECK_BENEFITS__TAB__DENTAL
                .CATEGORY,
        name: CONSUMER_RULES.COVERED_SERVICES__CHECK_BENEFITS__TAB__DENTAL.NAME,
    },
    [Features.COVERED_SERVICES__CHECK_BENEFITS__TAB__HEARING]: {
        category:
            CONSUMER_RULES.COVERED_SERVICES__CHECK_BENEFITS__TAB__HEARING
                .CATEGORY,
        name: CONSUMER_RULES.COVERED_SERVICES__CHECK_BENEFITS__TAB__HEARING
            .NAME,
    },
    [Features.COVERED_SERVICES__CHECK_BENEFITS__TAB__MEDICAL]: {
        category:
            CONSUMER_RULES.COVERED_SERVICES__CHECK_BENEFITS__TAB__MEDICAL
                .CATEGORY,
        name: CONSUMER_RULES.COVERED_SERVICES__CHECK_BENEFITS__TAB__MEDICAL
            .NAME,
    },
    [Features.COVERED_SERVICES__CHECK_BENEFITS__TAB__PHARMACY]: {
        category:
            CONSUMER_RULES.COVERED_SERVICES__CHECK_BENEFITS__TAB__PHARMACY
                .CATEGORY,
        name: CONSUMER_RULES.COVERED_SERVICES__CHECK_BENEFITS__TAB__PHARMACY
            .NAME,
    },
    [Features.COVERED_SERVICES__CHECK_BENEFITS__TAB__VISION]: {
        category:
            CONSUMER_RULES.COVERED_SERVICES__CHECK_BENEFITS__TAB__VISION
                .CATEGORY,
        name: CONSUMER_RULES.COVERED_SERVICES__CHECK_BENEFITS__TAB__VISION.NAME,
    },
    [Features.PRIME_PBM]: {
        category: CONSUMER_RULES.PRIME_PBM.CATEGORY,
        name: CONSUMER_RULES.PRIME_PBM.NAME,
    },
    [Features.NON_PRIME_PBM]: {
        category: CONSUMER_RULES.NON_PRIME_PBM.CATEGORY,
        name: CONSUMER_RULES.NON_PRIME_PBM.NAME,
    },
    [Features.CHANGE_ADDRESS_BILLING_LEVEL_I]: {
        category: CONSUMER_RULES.CHANGE_ADDRESS_BILLING_LEVEL_I.CATEGORY,
        name: CONSUMER_RULES.CHANGE_ADDRESS_BILLING_LEVEL_I.NAME,
    },
    [Features.BEHAVIORAL_HEALTH_FIND_CARE]: {
        category: CONSUMER_RULES.BEHAVIORAL_HEALTH_FIND_CARE.CATEGORY,
        name: CONSUMER_RULES.BEHAVIORAL_HEALTH_FIND_CARE.NAME,
    },
    [Features.ASK_A_PHARMACIST]: {
        category: CONSUMER_RULES.ASK_A_PHARMACIST.CATEGORY,
        name: CONSUMER_RULES.ASK_A_PHARMACIST.NAME,
    },
    [Features.FIND_CARE__GET_CARE_NOW__ASK_A_PHARMACIST]: {
        category:
            CONSUMER_RULES.FIND_CARE__GET_CARE_NOW__ASK_A_PHARMACIST.CATEGORY,
        name: CONSUMER_RULES.FIND_CARE__GET_CARE_NOW__ASK_A_PHARMACIST.NAME,
    },
    [Features.EAP_CAMBIA]: {
        category: CONSUMER_RULES.EAP_CAMBIA.CATEGORY,
        name: CONSUMER_RULES.EAP_CAMBIA.NAME,
    },
    [Features.EAP_REGENCE_SMALL_GROUP]: {
        category: CONSUMER_RULES.EAP_REGENCE_SMALL_GROUP.CATEGORY,
        name: CONSUMER_RULES.EAP_REGENCE_SMALL_GROUP.NAME,
    },
    [Features.EAP_ASURIS_SMALL_GROUP]: {
        category: CONSUMER_RULES.EAP_ASURIS_SMALL_GROUP.CATEGORY,
        name: CONSUMER_RULES.EAP_ASURIS_SMALL_GROUP.NAME,
    },
    [Features.EAP_REGENCE_LARGE_GROUP]: {
        category: CONSUMER_RULES.EAP_REGENCE_LARGE_GROUP.CATEGORY,
        name: CONSUMER_RULES.EAP_REGENCE_LARGE_GROUP.NAME,
    },
    [Features.EAP_ASURIS_LARGE_GROUP]: {
        category: CONSUMER_RULES.EAP_ASURIS_LARGE_GROUP.CATEGORY,
        name: CONSUMER_RULES.EAP_ASURIS_LARGE_GROUP.NAME,
    },
    [Features.IAP_REGENCE]: {
        category: CONSUMER_RULES.IAP_REGENCE.CATEGORY,
        name: CONSUMER_RULES.IAP_REGENCE.NAME,
    },
    [Features.IAP_ASURIS]: {
        category: CONSUMER_RULES.IAP_ASURIS.CATEGORY,
        name: CONSUMER_RULES.IAP_ASURIS.NAME,
    },
    [Features.JOINT_ADMIN_ENABLED]: {
        category: CONSUMER_RULES.JOINT_ADMIN_ENABLED.CATEGORY,
        name: CONSUMER_RULES.JOINT_ADMIN_ENABLED.NAME,
    },
    [Features.NAV__HOME]: {
        category: CONSUMER_RULES.NAV__HOME.CATEGORY,
        name: CONSUMER_RULES.NAV__HOME.NAME,
    },
    [Features.NAV__YOUR_CARE]: {
        category: CONSUMER_RULES.NAV__YOUR_CARE.CATEGORY,
        name: CONSUMER_RULES.NAV__YOUR_CARE.NAME,
    },
    [Features.NAV__YOUR_CARE__TIMELINE]: {
        category: CONSUMER_RULES.NAV__YOUR_CARE__TIMELINE.CATEGORY,
        name: CONSUMER_RULES.NAV__YOUR_CARE__TIMELINE.NAME,
    },
    [Features.NAV__YOUR_CARE__DOCTORS_AND_PROVIDERS]: {
        category: CONSUMER_RULES.NAV__YOUR_CARE__DOCTORS_AND_PROVIDERS.CATEGORY,
        name: CONSUMER_RULES.NAV__YOUR_CARE__DOCTORS_AND_PROVIDERS.NAME,
    },
    [Features.NAV__YOUR_CARE__MEDICATIONS]: {
        category: CONSUMER_RULES.NAV__YOUR_CARE__MEDICATIONS.CATEGORY,
        name: CONSUMER_RULES.NAV__YOUR_CARE__MEDICATIONS.NAME,
    },
    [Features.NAV__COVERED_SERVICES]: {
        category: CONSUMER_RULES.NAV__COVERED_SERVICES.CATEGORY,
        name: CONSUMER_RULES.NAV__COVERED_SERVICES.NAME,
    },
    [Features.NAV__COVERED_SERVICES__CHECK_BENEFITS]: {
        category: CONSUMER_RULES.NAV__COVERED_SERVICES__CHECK_BENEFITS.CATEGORY,
        name: CONSUMER_RULES.NAV__COVERED_SERVICES__CHECK_BENEFITS.NAME,
    },
    [Features.NAV__COVERED_SERVICES__FIND_CARE]: {
        category: CONSUMER_RULES.NAV__COVERED_SERVICES__FIND_CARE.CATEGORY,
        name: CONSUMER_RULES.NAV__COVERED_SERVICES__FIND_CARE.NAME,
    },
    [Features.NAV__COVERED_SERVICES__RESOURCES]: {
        category: CONSUMER_RULES.NAV__COVERED_SERVICES__RESOURCES.CATEGORY,
        name: CONSUMER_RULES.NAV__COVERED_SERVICES__RESOURCES.NAME,
    },
    [Features.NAV__FORMS_DOCUMENTS]: {
        category:
            CONSUMER_RULES.NAV__COVERED_SERVICES__FORMS_DOCUMENTS.CATEGORY,
        name: CONSUMER_RULES.NAV__COVERED_SERVICES__FORMS_DOCUMENTS.NAME,
    },
    [Features.NAV__CLAIMS_COSTS]: {
        category: CONSUMER_RULES.NAV__CLAIMS_COSTS.CATEGORY,
        name: CONSUMER_RULES.NAV__CLAIMS_COSTS.NAME,
    },
    [Features.NAV__CLAIMS_COSTS__OVERVIEW]: {
        category: CONSUMER_RULES.NAV__CLAIMS_COSTS__OVERVIEW.CATEGORY,
        name: CONSUMER_RULES.NAV__CLAIMS_COSTS__OVERVIEW.NAME,
    },
    [Features.NAV__CLAIMS_COSTS__CLAIMS]: {
        category: CONSUMER_RULES.NAV__CLAIMS_COSTS__CLAIMS.CATEGORY,
        name: CONSUMER_RULES.NAV__CLAIMS_COSTS__CLAIMS.NAME,
    },
    [Features.NAV__CLAIMS_COSTS__PAYMENTS]: {
        category: CONSUMER_RULES.NAV__CLAIMS_COSTS__PAYMENTS.CATEGORY,
        name: CONSUMER_RULES.NAV__CLAIMS_COSTS__PAYMENTS.NAME,
    },
    [Features.NAV__CLAIMS_COSTS__ESTIMATE_COSTS]: {
        category: CONSUMER_RULES.NAV__CLAIMS_COSTS__ESTIMATE_COSTS.CATEGORY,
        name: CONSUMER_RULES.NAV__CLAIMS_COSTS__ESTIMATE_COSTS.NAME,
    },
    [Features.NAV__CLAIMS_COSTS__EOB_STATEMENTS]: {
        category: CONSUMER_RULES.NAV__CLAIMS_COSTS__EOB_STATEMENTS.CATEGORY,
        name: CONSUMER_RULES.NAV__CLAIMS_COSTS__EOB_STATEMENTS.NAME,
    },
    [Features.NAV__CLAIMS_COSTS__TAX_FORMS]: {
        category: CONSUMER_RULES.NAV__CLAIMS_COSTS__TAX_FORMS.CATEGORY,
        name: CONSUMER_RULES.NAV__CLAIMS_COSTS__TAX_FORMS.NAME,
    },
    [Features.NAV__SUPPORT]: {
        category: CONSUMER_RULES.NAV__SUPPORT.CATEGORY,
        name: CONSUMER_RULES.NAV__SUPPORT.NAME,
    },
    [Features.BENEFITS_MEDICAL__SIDEBAR_ESTIMATE_COSTS]: {
        category:
            CONSUMER_RULES.BENEFITS_MEDICAL__SIDEBAR_ESTIMATE_COSTS.CATEGORY,
        name: CONSUMER_RULES.BENEFITS_MEDICAL__SIDEBAR_ESTIMATE_COSTS.NAME,
    },
    [Features.SUPPORT__SIDEBAR_ESTIMATE_COSTS]: {
        category: CONSUMER_RULES.SUPPORT__SIDEBAR_ESTIMATE_COSTS.CATEGORY,
        name: CONSUMER_RULES.SUPPORT__SIDEBAR_ESTIMATE_COSTS.NAME,
    },
    [Features.SUPPORT_DENTAL_BENEFIT_QUESTIONS]: {
        category: CONSUMER_RULES.SUPPORT_DENTAL_BENEFIT_QUESTIONS.CATEGORY,
        name: CONSUMER_RULES.SUPPORT_DENTAL_BENEFIT_QUESTIONS.NAME,
    },
    [Features.DISPATCH_HEALTH_FIND_CARE]: {
        category: CONSUMER_RULES.DISPATCH_HEALTH_FIND_CARE.CATEGORY,
        name: CONSUMER_RULES.DISPATCH_HEALTH_FIND_CARE.NAME,
    },
    [Features.COVERED_SERVICES__FIND_CARE__GET_IMMEDIATE_CARE___URGENT_CARE_NEAR_YOU]:
        {
            category:
                CONSUMER_RULES
                    .COVERED_SERVICES__FIND_CARE__GET_IMMEDIATE_CARE___URGENT_CARE_NEAR_YOU
                    .CATEGORY,
            name: CONSUMER_RULES
                .COVERED_SERVICES__FIND_CARE__GET_IMMEDIATE_CARE___URGENT_CARE_NEAR_YOU
                .NAME,
        },
    [Features.COVERED_SERVICES__FIND_CARE__GET_IMMEDIATE_CARE___EMERGENCY_ROOM_NEAR_YOU]:
        {
            category:
                CONSUMER_RULES
                    .COVERED_SERVICES__FIND_CARE__GET_IMMEDIATE_CARE___EMERGENCY_ROOM_NEAR_YOU
                    .CATEGORY,
            name: CONSUMER_RULES
                .COVERED_SERVICES__FIND_CARE__GET_IMMEDIATE_CARE___EMERGENCY_ROOM_NEAR_YOU
                .NAME,
        },
    [Features.COVERED_SERVICES_FIND_CARE__RECENT_PROVIDERS]: {
        category:
            CONSUMER_RULES.COVERED_SERVICES_FIND_CARE__RECENT_PROVIDERS
                .CATEGORY,
        name: CONSUMER_RULES.COVERED_SERVICES_FIND_CARE__RECENT_PROVIDERS.NAME,
    },
    [Features.RESOURCE_MENTAL_HEALTH_PROVIDER]: {
        category: CONSUMER_RULES.RESOURCE_MENTAL_HEALTH_PROVIDER.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_MENTAL_HEALTH_PROVIDER.NAME,
    },
    [Features.RESOURCE_HUNTSMAN_MENTAL_HEALTH_PROVIDER]: {
        category:
            CONSUMER_RULES.RESOURCE_HUNTSMAN_MENTAL_HEALTH_PROVIDER.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_HUNTSMAN_MENTAL_HEALTH_PROVIDER.NAME,
    },
    [Features.RESOURCE_SUICIDE_CRISIS_LIFELINE]: {
        category: CONSUMER_RULES.RESOURCE_SUICIDE_CRISIS_LIFELINE.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_SUICIDE_CRISIS_LIFELINE.NAME,
    },
    [Features.RESOURCE_FIND_RIGHT_CARE]: {
        category: CONSUMER_RULES.RESOURCE_FIND_RIGHT_CARE.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_FIND_RIGHT_CARE.NAME,
    },
    [Features.RESOURCE_NEDA]: {
        category: CONSUMER_RULES.RESOURCE_NEDA.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_NEDA.NAME,
    },
    [Features.BENEFITS_MEDICAL_RESOURCE_PAPERLESS_LETTERS]: {
        category:
            CONSUMER_RULES.BENEFITS_MEDICAL_RESOURCE_PAPERLESS_LETTERS.CATEGORY,
        name: CONSUMER_RULES.BENEFITS_MEDICAL_RESOURCE_PAPERLESS_LETTERS.NAME,
    },
    [Features.RESOURCE_DOCTOR_ON_DEMAND_VPC]: {
        category: CONSUMER_RULES.RESOURCE_DOCTOR_ON_DEMAND_VPC.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_DOCTOR_ON_DEMAND_VPC.NAME,
    },
    [Features.VIRTUAL_CARE_OPTIONS_GOV_PRO_TELEPHONE_LINE]: {
        category:
            CONSUMER_RULES.VIRTUAL_CARE_OPTIONS_GOV_PRO_TELEPHONE_LINE.CATEGORY,
        name: CONSUMER_RULES.VIRTUAL_CARE_OPTIONS_GOV_PRO_TELEPHONE_LINE.NAME,
    },
    [Features.VIRTUAL_CARE_OPTIONS_DOD_TELEPHONE_LINE]: {
        category:
            CONSUMER_RULES.VIRTUAL_CARE_OPTIONS_DOD_TELEPHONE_LINE.CATEGORY,
        name: CONSUMER_RULES.VIRTUAL_CARE_OPTIONS_DOD_TELEPHONE_LINE.NAME,
    },
    [Features.VIRTUAL_CARE_OPTIONS_NURSE_CHAT_TELEPHONE_LINE]: {
        category:
            CONSUMER_RULES.VIRTUAL_CARE_OPTIONS_NURSE_CHAT_TELEPHONE_LINE
                .CATEGORY,
        name: CONSUMER_RULES.VIRTUAL_CARE_OPTIONS_NURSE_CHAT_TELEPHONE_LINE
            .NAME,
    },
    [Features.VIRTUAL_CARE_OPTIONS_OPTUM_TELEPHONE_LINE]: {
        category:
            CONSUMER_RULES.VIRTUAL_CARE_OPTIONS_OPTUM_TELEPHONE_LINE.CATEGORY,
        name: CONSUMER_RULES.VIRTUAL_CARE_OPTIONS_OPTUM_TELEPHONE_LINE.NAME,
    },
    [Features.NURSE_CHAT]: {
        category: CONSUMER_RULES.VIRTUAL_CARE_OPTIONS_NURSE_CHAT.CATEGORY,
        name: CONSUMER_RULES.VIRTUAL_CARE_OPTIONS_NURSE_CHAT.NAME,
    },
    [Features.DOCTOR_ON_DEMAND]: {
        category: CONSUMER_RULES.VIRTUAL_CARE_OPTIONS_DOCTOR_ON_DEMAND.CATEGORY,
        name: CONSUMER_RULES.VIRTUAL_CARE_OPTIONS_DOCTOR_ON_DEMAND.NAME,
    },
    [Features.MDLIVE_VENDOR]: {
        category: CONSUMER_RULES.MDLIVE_VENDOR.CATEGORY,
        name: CONSUMER_RULES.MDLIVE_VENDOR.NAME,
    },
    [Features.MDLIVE_TELEH]: {
        category: CONSUMER_RULES.MDLIVE_TELEH.CATEGORY,
        name: CONSUMER_RULES.MDLIVE_TELEH.NAME,
    },
    [Features.MDLIVE_TELEX]: {
        category: CONSUMER_RULES.MDLIVE_TELEX.CATEGORY,
        name: CONSUMER_RULES.MDLIVE_TELEX.NAME,
    },
    [Features.MDLIVE_TELEV]: {
        category: CONSUMER_RULES.MDLIVE_TELEV.CATEGORY,
        name: CONSUMER_RULES.MDLIVE_TELEV.NAME,
    },
    [Features.BEHAVIORAL_HEALTH]: {
        category: CONSUMER_RULES.BEHAVIORAL_HEALTH.CATEGORY,
        name: CONSUMER_RULES.BEHAVIORAL_HEALTH.NAME,
    },
    [Features.PAGER_TELEMEDICINE_MDLIVE_VENDOR]: {
        category: CONSUMER_RULES.PAGER_TELEMEDICINE_MDLIVE_VENDOR.CATEGORY,
        name: CONSUMER_RULES.PAGER_TELEMEDICINE_MDLIVE_VENDOR.NAME,
    },
    [Features.PAGER_TELEMEDICINE_DOD_VENDOR]: {
        category: CONSUMER_RULES.PAGER_TELEMEDICINE_DOD_VENDOR.CATEGORY,
        name: CONSUMER_RULES.PAGER_TELEMEDICINE_DOD_VENDOR.NAME,
    },
    [Features.NATIVE__CLAIMS_COSTS__PAYMENTS]: {
        category: CONSUMER_RULES.NATIVE_PAYMENTS.CATEGORY,
        name: CONSUMER_RULES.NATIVE_PAYMENTS.NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENTS__AMOUNT__PAST_DUE]: {
        category:
            CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__AMOUNT__PAST_DUE.CATEGORY,
        name: CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__AMOUNT__PAST_DUE.NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENTS__AMOUNT__NEW_CHARGE]: {
        category:
            CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__AMOUNT__NEW_CHARGE.CATEGORY,
        name: CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__AMOUNT__NEW_CHARGE.NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENTS__AMOUNT__TOTAL_DUE]: {
        category:
            CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__AMOUNT__TOTAL_DUE.CATEGORY,
        name: CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__AMOUNT__TOTAL_DUE.NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENTS__AMOUNT__TOTAL_DUE_PENDING]: {
        category:
            CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__AMOUNT__TOTAL_DUE_PENDING
                .CATEGORY,
        name: CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__AMOUNT__TOTAL_DUE_PENDING
            .NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENTS__AMOUNT__TOTAL_DUE_INVOICE_PENDING]: {
        category:
            CONSUMER_RULES
                .CLAIMS_COSTS__PAYMENTS__AMOUNT__TOTAL_DUE_INVOICE_PENDING
                .CATEGORY,
        name: CONSUMER_RULES
            .CLAIMS_COSTS__PAYMENTS__AMOUNT__TOTAL_DUE_INVOICE_PENDING.NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENTS__BUTTON__MAKE_PAYMENT]: {
        category:
            CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__BUTTON__MAKE_PAYMENT
                .CATEGORY,
        name: CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__BUTTON__MAKE_PAYMENT.NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENT_HISTORY__BUTTON__MAKE_PAYMENT]: {
        category:
            CONSUMER_RULES.CLAIMS_COSTS__PAYMENT_HISTORY__BUTTON__MAKE_PAYMENT
                .CATEGORY,
        name: CONSUMER_RULES.CLAIMS_COSTS__PAYMENT_HISTORY__BUTTON__MAKE_PAYMENT
            .NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENTS__BUTTON__MANAGE_PAYMENT]: {
        category:
            CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__BUTTON__MANAGE_PAYMENT
                .CATEGORY,
        name: CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__BUTTON__MANAGE_PAYMENT
            .NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENTS__BUTTON__SET_UP_AUTOPAY]: {
        category:
            CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__BUTTON__SET_UP_AUTOPAY
                .CATEGORY,
        name: CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__BUTTON__SET_UP_AUTOPAY
            .NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENTS__BUTTON__CUSTOMER_SERVICE]: {
        category:
            CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__BUTTON__CUSTOMER_SERVICE
                .CATEGORY,
        name: CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__BUTTON__CUSTOMER_SERVICE
            .NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENTS__LINK__CUSTOMER_SERVICE]: {
        category:
            CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__LINK__CUSTOMER_SERVICE
                .CATEGORY,
        name: CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__LINK__CUSTOMER_SERVICE
            .NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENTS__MESSAGE__PAYMENT_INFO]: {
        category:
            CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__MESSAGE__PAYMENT_INFO
                .CATEGORY,
        name: CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__MESSAGE__PAYMENT_INFO.NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENTS__ALERT__PAST_DUE_WITH_DATE]: {
        category:
            CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__ALERT__PAST_DUE_WITH_DATE
                .CATEGORY,
        name: CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__ALERT__PAST_DUE_WITH_DATE
            .NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENTS__ALERT__PAST_DUE]: {
        category:
            CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__ALERT__PAST_DUE.CATEGORY,
        name: CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__ALERT__PAST_DUE.NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENTS__ALERT__PAST_DUE_WITH_AUTOPAY]: {
        category:
            CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__ALERT__PAST_DUE_WITH_AUTOPAY
                .CATEGORY,
        name: CONSUMER_RULES
            .CLAIMS_COSTS__PAYMENTS__ALERT__PAST_DUE_WITH_AUTOPAY.NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENTS__ALERT__PAST_DUE_WITH_AUTOPAY_AND_DATE]: {
        category:
            CONSUMER_RULES
                .CLAIMS_COSTS__PAYMENTS__ALERT__PAST_DUE_WITH_AUTOPAY_AND_DATE
                .CATEGORY,
        name: CONSUMER_RULES
            .CLAIMS_COSTS__PAYMENTS__ALERT__PAST_DUE_WITH_AUTOPAY_AND_DATE.NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENTS__PAYMENT_PENDING]: {
        category:
            CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__PAYMENT_PENDING.CATEGORY,
        name: CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__PAYMENT_PENDING.NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENTS__AUTOPAY_PENDING]: {
        category:
            CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__AUTOPAY_PENDING.CATEGORY,
        name: CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__AUTOPAY_PENDING.NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENTS__ALERT__TERMINATED_AUTOPAY_ELIGIBLE]: {
        category:
            CONSUMER_RULES
                .CLAIMS_COSTS__PAYMENTS__ALERT__TERMINATED_AUTOPAY_ELIGIBLE
                .CATEGORY,
        name: CONSUMER_RULES
            .CLAIMS_COSTS__PAYMENTS__ALERT__TERMINATED_AUTOPAY_ELIGIBLE.NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENTS__ALERT__TERMINATED]: {
        category:
            CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__ALERT__TERMINATED.CATEGORY,
        name: CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__ALERT__TERMINATED.NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENTS__ALERT__SSA]: {
        category: CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__ALERT__SSA.CATEGORY,
        name: CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__ALERT__SSA.NAME,
    },
    [Features.CLAIMS_COSTS__PAYMENTS__ALERT__AUTOPAY_SCHEDULED]: {
        category:
            CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__ALERT__AUTOPAY_SCHEDULED
                .CATEGORY,
        name: CONSUMER_RULES.CLAIMS_COSTS__PAYMENTS__ALERT__AUTOPAY_SCHEDULED
            .NAME,
    },
    [Features.FIND_CARE_V2_ENABLED]: {
        category: CONSUMER_RULES.FIND_CARE_V2_ENABLED.CATEGORY,
        name: CONSUMER_RULES.FIND_CARE_V2_ENABLED.NAME,
    },
    [Features.FEATURE_BEHAVIORAL_HEALTH_ENABLED]: {
        category: CONSUMER_RULES.FEATURE_BEHAVIORAL_HEALTH_ENABLED.CATEGORY,
        name: CONSUMER_RULES.FEATURE_BEHAVIORAL_HEALTH_ENABLED.NAME,
    },
    [Features.AMERIBEN]: {
        category: CONSUMER_RULES.AMERIBEN.CATEGORY,
        name: CONSUMER_RULES.AMERIBEN.NAME,
    },
    [Features.JOINT_ADMIN]: {
        category: CONSUMER_RULES.JOINT_ADMIN.CATEGORY,
        name: CONSUMER_RULES.JOINT_ADMIN.NAME,
    },
    [Features.JOINT_ADMIN_WITH_MEMBER_CARD]: {
        category: CONSUMER_RULES.JOINT_ADMIN_WITH_MEMBER_CARD.CATEGORY,
        name: CONSUMER_RULES.JOINT_ADMIN_WITH_MEMBER_CARD.NAME,
    },
    [Features.TAX_INFORMATION_IRS_DOCUMENT]: {
        category: CONSUMER_RULES.TAX_INFORMATION_IRS_DOCUMENT.CATEGORY,
        name: CONSUMER_RULES.TAX_INFORMATION_IRS_DOCUMENT.NAME,
    },
    [Features.FEATURE_NATIVE_CLAIM_SUBMISSION_ENABLED]: {
        category:
            CONSUMER_RULES.FEATURE_NATIVE_CLAIM_SUBMISSION_ENABLED.CATEGORY,
        name: CONSUMER_RULES.FEATURE_NATIVE_CLAIM_SUBMISSION_ENABLED.NAME,
    },
    [Features.FEATURE_NATIVE_HIDE_MESSAGE_CHAIN_ENABLED]: {
        category:
            CONSUMER_RULES.FEATURE_NATIVE_HIDE_MESSAGE_CHAIN_ENABLED.CATEGORY,
        name: CONSUMER_RULES.FEATURE_NATIVE_HIDE_MESSAGE_CHAIN_ENABLED.NAME,
    },
    [Features.USABLE_EOB_DENTAL]: {
        category: CONSUMER_RULES.USABLE_EOB_DENTAL.CATEGORY,
        name: CONSUMER_RULES.USABLE_EOB_DENTAL.NAME,
    },
    [Features.FEATURE_NATIVE_APPEND_PREVIOUS_MSGS_ENABLED]: {
        category:
            CONSUMER_RULES.FEATURE_NATIVE_APPEND_PREVIOUS_MSGS_ENABLED.CATEGORY,
        name: CONSUMER_RULES.FEATURE_NATIVE_APPEND_PREVIOUS_MSGS_ENABLED.NAME,
    },
    [Features.COVERED_SERVICES_CHECKED_BENEFITS_DENTAL_USABLE]: {
        category:
            CONSUMER_RULES.COVERED_SERVICES_CHECKED_BENEFITS_DENTAL_USABLE
                .CATEGORY,
        name: CONSUMER_RULES.COVERED_SERVICES_CHECKED_BENEFITS_DENTAL_USABLE
            .NAME,
    },
    [Features.COVERED_SERVICES_FIND_CARE_DENTAL_USABLE]: {
        category:
            CONSUMER_RULES.COVERED_SERVICES_FIND_CARE_DENTAL_USABLE.CATEGORY,
        name: CONSUMER_RULES.COVERED_SERVICES_FIND_CARE_DENTAL_USABLE.NAME,
    },
    [Features.COVERED_SERVICES_FIND_CARE_VSP]: {
        category: CONSUMER_RULES.COVERED_SERVICES_FIND_CARE_VSP.CATEGORY,
        name: CONSUMER_RULES.COVERED_SERVICES_FIND_CARE_VSP.NAME,
    },
    [Features.YOUR_CARE_TIMELINE_USABLE]: {
        category: CONSUMER_RULES.YOUR_CARE_TIMELINE_USABLE.CATEGORY,
        name: CONSUMER_RULES.YOUR_CARE_TIMELINE_USABLE.NAME,
    },
    [Features.DASHBOARD_QUICK_LINK_USABLE]: {
        category: CONSUMER_RULES.DASHBOARD_QUICK_LINK_USABLE.CATEGORY,
        name: CONSUMER_RULES.DASHBOARD_QUICK_LINK_USABLE.NAME,
    },
    [Features.WEB__CARE__YOUR_CARE_HISTORY__ITEM]: {
        category: CONSUMER_RULES.WEB__CARE__YOUR_CARE_HISTORY__ITEM.CATEGORY,
        name: CONSUMER_RULES.WEB__CARE__YOUR_CARE_HISTORY__ITEM.NAME,
    },
    [Features.WEB__CARE__YOUR_CARE_HISTORY__LIST]: {
        category: CONSUMER_RULES.WEB__CARE__YOUR_CARE_HISTORY__LIST.CATEGORY,
        name: CONSUMER_RULES.WEB__CARE__YOUR_CARE_HISTORY__LIST.NAME,
    },
    [Features.WEB__CARE__YOUR_PROVIDER]: {
        category: CONSUMER_RULES.WEB__CARE__YOUR_PROVIDER.CATEGORY,
        name: CONSUMER_RULES.WEB__CARE__YOUR_PROVIDER.NAME,
    },
    [Features.WEB__CARE__CARE_GAPS__HELP_CARD]: {
        category: CONSUMER_RULES.WEB__CARE__CARE_GAPS__HELP_CARD.CATEGORY,
        name: CONSUMER_RULES.WEB__CARE__CARE_GAPS__HELP_CARD.NAME,
    },
    [Features.CARE_RESOURCES__HEADER_HIDE_PREGNANCY_TAB]: {
        category:
            CONSUMER_RULES.CARE_RESOURCES__HEADER_HIDE_PREGNANCY_TAB.CATEGORY,
        name: CONSUMER_RULES.CARE_RESOURCES__HEADER_HIDE_PREGNANCY_TAB.NAME,
    },
    [Features.DASHBOARD__SPOTLIGHT_CARD]: {
        category: CONSUMER_RULES.DASHBOARD__SPOTLIGHT_CARD.CATEGORY,
        name: CONSUMER_RULES.DASHBOARD__SPOTLIGHT_CARD.NAME,
    },
    [Features.DASHBOARD__ADVICE24_CARD]: {
        category: CONSUMER_RULES.DASHBOARD__ADVICE24_CARD.CATEGORY,
        name: CONSUMER_RULES.DASHBOARD__ADVICE24_CARD.NAME,
    },
    [Features.DASHBOARD__RESOURCE_LIST_CARD]: {
        category: CONSUMER_RULES.DASHBOARD__RESOURCE_LIST_CARD.CATEGORY,
        name: CONSUMER_RULES.DASHBOARD__RESOURCE_LIST_CARD.NAME,
    },
    [Features.CARE_RESOURCES__TAB__MEDICARE]: {
        category: CONSUMER_RULES.CARE_RESOURCES__TAB__MEDICARE.CATEGORY,
        name: CONSUMER_RULES.CARE_RESOURCES__TAB__MEDICARE.NAME,
    },
    [Features.SUPPORT__SIDEBAR_FIND_CARE_CARD]: {
        category: CONSUMER_RULES.SUPPORT__SIDEBAR_FIND_CARE_CARD.CATEGORY,
        name: CONSUMER_RULES.SUPPORT__SIDEBAR_FIND_CARE_CARD.NAME,
    },
    [Features.DASHBOARD__FIND_CARE_BUTTON]: {
        category: CONSUMER_RULES.DASHBOARD__FIND_CARE_BUTTON.CATEGORY,
        name: CONSUMER_RULES.DASHBOARD__FIND_CARE_BUTTON.NAME,
    },
    [Features.DASHBOARD_DENTAL_BENEFITS_ACCUMS]: {
        category: CONSUMER_RULES.DASHBOARD_DENTAL_BENEFITS_ACCUMS.CATEGORY,
        name: CONSUMER_RULES.DASHBOARD_DENTAL_BENEFITS_ACCUMS.NAME,
    },
    [Features.USABLE_CLAIMS_COSTS_OVERVIEW]: {
        category: CONSUMER_RULES.USABLE_CLAIMS_COSTS_OVERVIEW.CATEGORY,
        name: CONSUMER_RULES.USABLE_CLAIMS_COSTS_OVERVIEW.NAME,
    },
    [Features.RESOURCE_VIRTUAL_CARE]: {
        category: CONSUMER_RULES.RESOURCE_VIRTUAL_CARE.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_VIRTUAL_CARE.NAME,
    },
    [Features.ACCOUNT_PERSONAL_INFORMATION]: {
        category: CONSUMER_RULES.ACCOUNT_PERSONAL_INFORMATION.CATEGORY,
        name: CONSUMER_RULES.ACCOUNT_PERSONAL_INFORMATION.NAME,
    },
    [Features.ACCOUNT_PERSONAL_INFORMATION_INDIVIDUAL]: {
        category:
            CONSUMER_RULES.ACCOUNT_PERSONAL_INFORMATION_INDIVIDUAL.CATEGORY,
        name: CONSUMER_RULES.ACCOUNT_PERSONAL_INFORMATION_INDIVIDUAL.NAME,
    },
    [Features.ACCOUNT__SHOW_MEDICARE_ADDRESS_CARD]: {
        category: CONSUMER_RULES.ACCOUNT__SHOW_MEDICARE_ADDRESS_CARD.CATEGORY,
        name: CONSUMER_RULES.ACCOUNT__SHOW_MEDICARE_ADDRESS_CARD.NAME,
    },
    [Features.ACCOUNT__SHOW_ADDRESS_CARD]: {
        category: CONSUMER_RULES.ACCOUNT__SHOW_ADDRESS_CARD.CATEGORY,
        name: CONSUMER_RULES.ACCOUNT__SHOW_ADDRESS_CARD.NAME,
    },
    [Features.ACCOUNT__SHOW_SMS_CLAIMS_ALERTS_CARD]: {
        category: CONSUMER_RULES.ACCOUNT__SHOW_SMS_CLAIMS_ALERTS_CARD.CATEGORY,
        name: CONSUMER_RULES.ACCOUNT__SHOW_SMS_CLAIMS_ALERTS_CARD.NAME,
    },
    [Features.ACCOUNT__SHOW_MEDICARE_CONTACT_PREFERENCES_CARD]: {
        category:
            CONSUMER_RULES.ACCOUNT__SHOW_MEDICARE_CONTACT_PREFERENCES_CARD
                .CATEGORY,
        name: CONSUMER_RULES.ACCOUNT__SHOW_MEDICARE_CONTACT_PREFERENCES_CARD
            .NAME,
    },
    [Features.ACCOUNT__FAMILY_SHARING_USE_BRIDGESPAN_AUTH_URL]: {
        category:
            CONSUMER_RULES.ACCOUNT__FAMILY_SHARING_USE_BRIDGESPAN_AUTH_URL
                .CATEGORY,
        name: CONSUMER_RULES.ACCOUNT__FAMILY_SHARING_USE_BRIDGESPAN_AUTH_URL
            .NAME,
    },
    [Features.ACCOUNT__FAMILY_SHARING_USE_ASURIS_NON_MEDICARE_AUTH_URL]: {
        category:
            CONSUMER_RULES
                .ACCOUNT__FAMILY_SHARING_USE_ASURIS_NON_MEDICARE_AUTH_URL
                .CATEGORY,
        name: CONSUMER_RULES
            .ACCOUNT__FAMILY_SHARING_USE_ASURIS_NON_MEDICARE_AUTH_URL.NAME,
    },
    [Features.ACCOUNT__FAMILY_SHARING_USE_ASURIS_MEDICARE_AUTH_URL]: {
        category:
            CONSUMER_RULES.ACCOUNT__FAMILY_SHARING_USE_ASURIS_MEDICARE_AUTH_URL
                .CATEGORY,
        name: CONSUMER_RULES
            .ACCOUNT__FAMILY_SHARING_USE_ASURIS_MEDICARE_AUTH_URL.NAME,
    },
    [Features.ACCOUNT__FAMILY_SHARING_USE_REGENCE_NON_MEDICARE_AUTH_URL]: {
        category:
            CONSUMER_RULES
                .ACCOUNT__FAMILY_SHARING_USE_REGENCE_NON_MEDICARE_AUTH_URL
                .CATEGORY,
        name: CONSUMER_RULES
            .ACCOUNT__FAMILY_SHARING_USE_REGENCE_NON_MEDICARE_AUTH_URL.NAME,
    },
    [Features.ACCOUNT__FAMILY_SHARING_USE_REGENCE_MEDICARE_AUTH_URL]: {
        category:
            CONSUMER_RULES.ACCOUNT__FAMILY_SHARING_USE_REGENCE_MEDICARE_AUTH_URL
                .CATEGORY,
        name: CONSUMER_RULES
            .ACCOUNT__FAMILY_SHARING_USE_REGENCE_MEDICARE_AUTH_URL.NAME,
    },
    [Features.MPS_BENEFIT_PRODUCTID_QUERY_ENABLED]: {
        category: CONSUMER_RULES.MPS_BENEFIT_PRODUCTID_QUERY_ENABLED.CATEGORY,
        name: CONSUMER_RULES.MPS_BENEFIT_PRODUCTID_QUERY_ENABLED.NAME,
    },
    [Features.MPS_ONLIFE_QUERY_ENABLED]: {
        category: CONSUMER_RULES.MPS_ONLIFE_QUERY_ENABLED.CATEGORY,
        name: CONSUMER_RULES.MPS_ONLIFE_QUERY_ENABLED.NAME,
    },
    [Features.MPS_TEALIUM_DATA_QUERY_ENABLED]: {
        category: CONSUMER_RULES.MPS_TEALIUM_DATA_QUERY_ENABLED.CATEGORY,
        name: CONSUMER_RULES.MPS_TEALIUM_DATA_QUERY_ENABLED.NAME,
    },
    [Features.FOOTER_HINGE_HEALTH]: {
        category: CONSUMER_RULES.FOOTER_HINGE_HEALTH.CATEGORY,
        name: CONSUMER_RULES.FOOTER_HINGE_HEALTH.NAME,
    },
    [Features.FOOTER_SELF_GUIDED_EMOTIONAL_HEALTH]: {
        category: CONSUMER_RULES.FOOTER_SELF_GUIDED_EMOTIONAL_HEALTH.CATEGORY,
        name: CONSUMER_RULES.FOOTER_SELF_GUIDED_EMOTIONAL_HEALTH.NAME,
    },
    [Features.QUALTRICS_SURVEY_ENABLED]: {
        category: CONSUMER_RULES.QUALTRICS_SURVEY_ENABLED.CATEGORY,
        name: CONSUMER_RULES.QUALTRICS_SURVEY_ENABLED.NAME,
    },
    [Features.ESTIMATE_COSTS_MEDICAL_ACTIVE]: {
        category: CONSUMER_RULES.ESTIMATE_COSTS_MEDICAL_ACTIVE.CATEGORY,
        name: CONSUMER_RULES.ESTIMATE_COSTS_MEDICAL_ACTIVE.NAME,
    },
    [Features.ESTIMATE_COSTS_KYRUUS_REWARDS]: {
        category: CONSUMER_RULES.ESTIMATE_COSTS_KYRUUS_REWARDS.CATEGORY,
        name: CONSUMER_RULES.ESTIMATE_COSTS_KYRUUS_REWARDS.NAME,
    },
    [Features.HEALTH_HISTORY_RECORDS_MEDICAL_ACTIVE]: {
        category: CONSUMER_RULES.HEALTH_HISTORY_RECORDS_MEDICAL_ACTIVE.CATEGORY,
        name: CONSUMER_RULES.HEALTH_HISTORY_RECORDS_MEDICAL_ACTIVE.NAME,
    },
    [Features.CVS_CHECK_COST_CARD_LINK]: {
        category: CONSUMER_RULES.CVS_CHECK_COST_CARD_LINK.CATEGORY,
        name: CONSUMER_RULES.CVS_CHECK_COST_CARD_LINK.NAME,
    },
    [Features.CVS_FIND_PHARMACY_CARD_LINK]: {
        category: CONSUMER_RULES.CVS_FIND_PHARMACY_CARD_LINK.CATEGORY,
        name: CONSUMER_RULES.CVS_FIND_PHARMACY_CARD_LINK.NAME,
    },
    [Features.CVS_FIND_CARE_PHARMACY_LINK]: {
        category: CONSUMER_RULES.CVS_FIND_CARE_PHARMACY_LINK.CATEGORY,
        name: CONSUMER_RULES.CVS_FIND_CARE_PHARMACY_LINK.NAME,
    },
    [Features.CVS_RETIRED_CAMPAIGNS_LINK]: {
        category: CONSUMER_RULES.CVS_RETIRED_CAMPAIGNS_LINK.CATEGORY,
        name: CONSUMER_RULES.CVS_RETIRED_CAMPAIGNS_LINK.NAME,
    },
    [Features.CVS_SORT_OPTIONS]: {
        category: CONSUMER_RULES.CVS_SORT_OPTIONS.CATEGORY,
        name: CONSUMER_RULES.CVS_SORT_OPTIONS.NAME,
    },
    [Features.CONDITIONS_TREATMENT_PANEL_SHOW_AVG_COST]: {
        category:
            CONSUMER_RULES.CONDITIONS_TREATMENT_PANEL_SHOW_AVG_COST.CATEGORY,
        name: CONSUMER_RULES.CONDITIONS_TREATMENT_PANEL_SHOW_AVG_COST.NAME,
    },
    [Features.PCOMP_CONDITION_TREATMENT_SHOW_AVG_COST]: {
        category:
            CONSUMER_RULES.PCOMP_CONDITION_TREATMENT_SHOW_AVG_COST.CATEGORY,
        name: CONSUMER_RULES.PCOMP_CONDITION_TREATMENT_SHOW_AVG_COST.NAME,
    },
    [Features.PHARMACY_CVS]: {
        category: CONSUMER_RULES.PHARMACY_CVS.CATEGORY,
        name: CONSUMER_RULES.PHARMACY_CVS.NAME,
    },
    [Features.PHARMACY_SHOW_PCOMP_MEDICATION_CARD]: {
        category: CONSUMER_RULES.PHARMACY_SHOW_PCOMP_MEDICATION_CARD.CATEGORY,
        name: CONSUMER_RULES.PHARMACY_SHOW_PCOMP_MEDICATION_CARD.NAME,
    },
    [Features.PHARMACY_SHOW_FORTIVE_MEDICATION_CARD]: {
        category: CONSUMER_RULES.PHARMACY_SHOW_FORTIVE_MEDICATION_CARD.CATEGORY,
        name: CONSUMER_RULES.PHARMACY_SHOW_FORTIVE_MEDICATION_CARD.NAME,
    },
    [Features.PHARMACY_PCOMP_FIND_PHARMACY_CARD_SEARCH]: {
        category:
            CONSUMER_RULES.PHARMACY_PCOMP_FIND_PHARMACY_CARD_SEARCH.CATEGORY,
        name: CONSUMER_RULES.PHARMACY_PCOMP_FIND_PHARMACY_CARD_SEARCH.NAME,
    },
    [Features.PHARMACY_PCOMP_FIND_PHARMACY_CARD_CVS]: {
        category: CONSUMER_RULES.PHARMACY_PCOMP_FIND_PHARMACY_CARD_CVS.CATEGORY,
        name: CONSUMER_RULES.PHARMACY_PCOMP_FIND_PHARMACY_CARD_CVS.NAME,
    },
    [Features.PHARMACY_CVS_HOME_DELIVERY_LINK]: {
        category: CONSUMER_RULES.PHARMACY_CVS_HOME_DELIVERY_LINK.CATEGORY,
        name: CONSUMER_RULES.PHARMACY_CVS_HOME_DELIVERY_LINK.NAME,
    },
    [Features.CVS_TIMELINE_YOU_PAID_UNKNOWN]: {
        category: CONSUMER_RULES.CVS_TIMELINE_YOU_PAID_UNKNOWN.CATEGORY,
        name: CONSUMER_RULES.CVS_TIMELINE_YOU_PAID_UNKNOWN.NAME,
    },
    [Features.BENEFITS_LAYOUT_SHOW_CONTENT]: {
        category: CONSUMER_RULES.BENEFITS_LAYOUT_SHOW_CONTENT.CATEGORY,
        name: CONSUMER_RULES.BENEFITS_LAYOUT_SHOW_CONTENT.NAME,
    },
    [Features.TREATMENT_DETAILS_CONNECT_WITH_PHARMACIST]: {
        category:
            CONSUMER_RULES.TREATMENT_DETAILS_CONNECT_WITH_PHARMACIST.CATEGORY,
        name: CONSUMER_RULES.TREATMENT_DETAILS_CONNECT_WITH_PHARMACIST.NAME,
    },
    [Features.CAMPAIGNS_SHOW_ASK_PHARMACIST_CARD]: {
        category: CONSUMER_RULES.CAMPAIGNS_SHOW_ASK_PHARMACIST_CARD.CATEGORY,
        name: CONSUMER_RULES.CAMPAIGNS_SHOW_ASK_PHARMACIST_CARD.NAME,
    },
    [Features.CONDITION_DETAIL_SHOW_CONNECT_PHARMACIST_CARD]: {
        category:
            CONSUMER_RULES.CONDITION_DETAIL_SHOW_CONNECT_PHARMACIST_CARD
                .CATEGORY,
        name: CONSUMER_RULES.CONDITION_DETAIL_SHOW_CONNECT_PHARMACIST_CARD.NAME,
    },
    [Features.CONDITION_COMPARE_FETCH_RX_COVERAGE]: {
        category: CONSUMER_RULES.CONDITION_COMPARE_FETCH_RX_COVERAGE.CATEGORY,
        name: CONSUMER_RULES.CONDITION_COMPARE_FETCH_RX_COVERAGE.NAME,
    },
    [Features.BENEFITS_PHARMACY_RESEARCH_MEDICATIONS_CARD]: {
        category:
            CONSUMER_RULES.BENEFITS_PHARMACY_RESEARCH_MEDICATIONS_CARD.CATEGORY,
        name: CONSUMER_RULES.BENEFITS_PHARMACY_RESEARCH_MEDICATIONS_CARD.NAME,
    },
    [Features.DASHBOARD_INSIGHTS_CARD_RX_ENHANCED]: {
        category: CONSUMER_RULES.DASHBOARD_INSIGHTS_CARD_RX_ENHANCED.CATEGORY,
        name: CONSUMER_RULES.DASHBOARD_INSIGHTS_CARD_RX_ENHANCED.NAME,
    },
    [Features.HEADER_INSIGHTS_POPOVER_RX_ENHANCED]: {
        category: CONSUMER_RULES.HEADER_INSIGHTS_POPOVER_RX_ENHANCED.CATEGORY,
        name: CONSUMER_RULES.HEADER_INSIGHTS_POPOVER_RX_ENHANCED.NAME,
    },
    [Features.INSIGHTS_A1C]: {
        category: CONSUMER_RULES.INSIGHTS_A1C.CATEGORY,
        name: CONSUMER_RULES.INSIGHTS_A1C.NAME,
    },
    [Features.INSIGHTS_DIABETES_EYE_EXAM]: {
        category: CONSUMER_RULES.INSIGHTS_DIABETES_EYE_EXAM.CATEGORY,
        name: CONSUMER_RULES.INSIGHTS_DIABETES_EYE_EXAM.NAME,
    },
    [Features.INSIGHTS_KIDNEY_HEALTH]: {
        category: CONSUMER_RULES.INSIGHTS_KIDNEY_HEALTH.CATEGORY,
        name: CONSUMER_RULES.INSIGHTS_KIDNEY_HEALTH.NAME,
    },
    [Features.RESEARCH_ASK_PHARMACIST_CARD]: {
        category: CONSUMER_RULES.RESEARCH_ASK_PHARMACIST_CARD.CATEGORY,
        name: CONSUMER_RULES.RESEARCH_ASK_PHARMACIST_CARD.NAME,
    },
    [Features.RESEARCH_CARD_DETAILS_TIER_COVERAGE_RESEARCH]: {
        category:
            CONSUMER_RULES.RESEARCH_CARD_DETAILS_TIER_COVERAGE_RESEARCH
                .CATEGORY,
        name: CONSUMER_RULES.RESEARCH_CARD_DETAILS_TIER_COVERAGE_RESEARCH.NAME,
    },
    [Features.RESEARCH_CARD_DETAILS_ASK_PHARMACIST_CARD]: {
        category:
            CONSUMER_RULES.RESEARCH_CARD_DETAILS_ASK_PHARMACIST_CARD.CATEGORY,
        name: CONSUMER_RULES.RESEARCH_CARD_DETAILS_ASK_PHARMACIST_CARD.NAME,
    },
    [Features.RESEARCH_CARD_DETAILS_PHARMACIST_LINK]: {
        category: CONSUMER_RULES.RESEARCH_CARD_DETAILS_PHARMACIST_LINK.CATEGORY,
        name: CONSUMER_RULES.RESEARCH_CARD_DETAILS_PHARMACIST_LINK.NAME,
    },
    [Features.RESEARCH_CARD_DETAILS_TIER_COVERAGE]: {
        category: CONSUMER_RULES.RESEARCH_CARD_DETAILS_TIER_COVERAGE.CATEGORY,
        name: CONSUMER_RULES.RESEARCH_CARD_DETAILS_TIER_COVERAGE.NAME,
    },
    [Features.RESEARCH_CARD_TIER_COVERAGE]: {
        category: CONSUMER_RULES.RESEARCH_CARD_TIER_COVERAGE.CATEGORY,
        name: CONSUMER_RULES.RESEARCH_CARD_TIER_COVERAGE.NAME,
    },
    [Features.PHARMACY_MEDICATION_HISTORY_PAGE]: {
        category: CONSUMER_RULES.PHARMACY_MEDICATION_HISTORY_PAGE.CATEGORY,
        name: CONSUMER_RULES.PHARMACY_MEDICATION_HISTORY_PAGE.NAME,
    },
    [Features.PHARMACY_PAGE_RX_ENHANCED]: {
        category: CONSUMER_RULES.PHARMACY_PAGE_RX_ENHANCED.CATEGORY,
        name: CONSUMER_RULES.PHARMACY_PAGE_RX_ENHANCED.NAME,
    },
    [Features.PHARMACY_PAGE_PRIME_ENABLED]: {
        category: CONSUMER_RULES.PHARMACY_PAGE_PRIME_ENABLED.CATEGORY,
        name: CONSUMER_RULES.PHARMACY_PAGE_PRIME_ENABLED.NAME,
    },
    [Features.PHARMACY_PAGE_MEDICINE_CABINET_CARD]: {
        category: CONSUMER_RULES.PHARMACY_PAGE_MEDICINE_CABINET_CARD.CATEGORY,
        name: CONSUMER_RULES.PHARMACY_PAGE_MEDICINE_CABINET_CARD.NAME,
    },
    [Features.RESOURCE_OMADA]: {
        category: CONSUMER_RULES.RESOURCE_OMADA.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_OMADA.NAME,
    },
    [Features.RESOURCE_EXPERIAN]: {
        category: CONSUMER_RULES.RESOURCE_EXPERIAN.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_EXPERIAN.NAME,
    },
    [Features.COSTS_OVERVIEW__SUBMIT_CLAIM_USE_MEDICARE_URL]: {
        category:
            CONSUMER_RULES.COSTS_OVERVIEW__SUBMIT_CLAIM_USE_MEDICARE_URL
                .CATEGORY,
        name: CONSUMER_RULES.COSTS_OVERVIEW__SUBMIT_CLAIM_USE_MEDICARE_URL.NAME,
    },
    [Features.COSTS_OVERVIEW__SHOW_SUBMIT_CLAIMS_CARD]: {
        category:
            CONSUMER_RULES.COSTS_OVERVIEW__SHOW_SUBMIT_CLAIMS_CARD.CATEGORY,
        name: CONSUMER_RULES.COSTS_OVERVIEW__SHOW_SUBMIT_CLAIMS_CARD.NAME,
    },
    [Features.COSTS_NATIVE_CLAIMS_DETAIL__SHOW_SUBMIT_CLAIMS_CARD]: {
        category:
            CONSUMER_RULES.COSTS_NATIVE_CLAIMS_DETAIL__SHOW_SUBMIT_CLAIMS_CARD
                .CATEGORY,
        name: CONSUMER_RULES.COSTS_NATIVE_CLAIMS_DETAIL__SHOW_SUBMIT_CLAIMS_CARD
            .NAME,
    },
    [Features.COSTS_NATIVE_CLAIMS__SHOW_SUBMIT_CLAIMS_CARD]: {
        category:
            CONSUMER_RULES.COSTS_NATIVE_CLAIMS__SHOW_SUBMIT_CLAIMS_CARD
                .CATEGORY,
        name: CONSUMER_RULES.COSTS_NATIVE_CLAIMS__SHOW_SUBMIT_CLAIMS_CARD.NAME,
    },
    [Features.CLAIMS_DETAIL__SHOW_SUBMIT_CLAIMS_CARD]: {
        category:
            CONSUMER_RULES.CLAIMS_DETAIL__SHOW_SUBMIT_CLAIMS_CARD.CATEGORY,
        name: CONSUMER_RULES.CLAIMS_DETAIL__SHOW_SUBMIT_CLAIMS_CARD.NAME,
    },
    [Features.CLAIMS__SHOW_SUBMIT_CLAIMS_CARD]: {
        category: CONSUMER_RULES.CLAIMS__SHOW_SUBMIT_CLAIMS_CARD.CATEGORY,
        name: CONSUMER_RULES.CLAIMS__SHOW_SUBMIT_CLAIMS_CARD.NAME,
    },
    [Features.ONBOARDING__GO_PAPERLESS_SHOW_MEDICARE_CARD]: {
        category:
            CONSUMER_RULES.ONBOARDING__GO_PAPERLESS_SHOW_MEDICARE_CARD.CATEGORY,
        name: CONSUMER_RULES.ONBOARDING__GO_PAPERLESS_SHOW_MEDICARE_CARD.NAME,
    },
    [Features.ONBOARDING__GO_PAPERLESS_USE_MEDICARE_PAGE_TITLE]: {
        category:
            CONSUMER_RULES.ONBOARDING__GO_PAPERLESS_USE_MEDICARE_PAGE_TITLE
                .CATEGORY,
        name: CONSUMER_RULES.ONBOARDING__GO_PAPERLESS_USE_MEDICARE_PAGE_TITLE
            .NAME,
    },
    [Features.FEATURE_VSP_GRAPH_ENABLED]: {
        category: CONSUMER_RULES.FEATURE_VSP_GRAPH_ENABLED.CATEGORY,
        name: CONSUMER_RULES.FEATURE_VSP_GRAPH_ENABLED.NAME,
    },
    [Features.DISCOUNTS_CARD_HEARING_AIDS]: {
        category: CONSUMER_RULES.DISCOUNTS_CARD_HEARING_AIDS.CATEGORY,
        name: CONSUMER_RULES.DISCOUNTS_CARD_HEARING_AIDS.NAME,
    },
    [Features.RESOURCE__HEALTH_SUPPORT_PROGRAMS__CARE_MANAGEMENT]: {
        category:
            CONSUMER_RULES.RESOURCE__HEALTH_SUPPORT_PROGRAMS__CARE_MANAGEMENT
                .CATEGORY,
        name: CONSUMER_RULES.RESOURCE__HEALTH_SUPPORT_PROGRAMS__CARE_MANAGEMENT
            .NAME,
    },
    [Features.RESOURCE__HEALTH_SUPPORT_PROGRAMS__CONDITION_MANAGER]: {
        category:
            CONSUMER_RULES.RESOURCE__HEALTH_SUPPORT_PROGRAMS__CONDITION_MANAGER
                .CATEGORY,
        name: CONSUMER_RULES
            .RESOURCE__HEALTH_SUPPORT_PROGRAMS__CONDITION_MANAGER.NAME,
    },
    [Features.RESOURCE__HEALTH_SUPPORT_PROGRAMS__PALLIATIVE_CARE]: {
        category:
            CONSUMER_RULES.RESOURCE__HEALTH_SUPPORT_PROGRAMS__PALLIATIVE_CARE
                .CATEGORY,
        name: CONSUMER_RULES.RESOURCE__HEALTH_SUPPORT_PROGRAMS__PALLIATIVE_CARE
            .NAME,
    },
    [Features.RESOURCE_MEDICARE_HEARING_AIDS]: {
        category: CONSUMER_RULES.RESOURCE_MEDICARE_HEARING_AIDS.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_MEDICARE_HEARING_AIDS.NAME,
    },
    [Features.RESOURCE_MEDICARE_BENEFITS]: {
        category: CONSUMER_RULES.RESOURCE_MEDICARE_BENEFITS.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_MEDICARE_BENEFITS.NAME,
    },
    [Features.DATADOG_RUM_USERID_ENABLED]: {
        category: CONSUMER_RULES.DATADOG_RUM_USERID_ENABLED.CATEGORY,
        name: CONSUMER_RULES.DATADOG_RUM_USERID_ENABLED.NAME,
    },
    [Features.IN_HOME_CARE_CARD_DOCTOR_ON_DEMAND]: {
        category: CONSUMER_RULES.IN_HOME_CARE_CARD_DOCTOR_ON_DEMAND.CATEGORY,
        name: CONSUMER_RULES.IN_HOME_CARE_CARD_DOCTOR_ON_DEMAND.NAME,
    },
    [Features.IN_HOME_CARE_CARD_URGENT_CARE]: {
        category: CONSUMER_RULES.IN_HOME_CARE_CARD_URGENT_CARE.CATEGORY,
        name: CONSUMER_RULES.IN_HOME_CARE_CARD_URGENT_CARE.NAME,
    },
    [Features.IN_HOME_CARE_CARD_HEALTH_ASSESSMENT]: {
        category: CONSUMER_RULES.IN_HOME_CARE_CARD_HEALTH_ASSESSMENT.CATEGORY,
        name: CONSUMER_RULES.IN_HOME_CARE_CARD_HEALTH_ASSESSMENT.NAME,
    },
    [Features.IN_HOME_CARE_CARD_ADVANTMED]: {
        category: CONSUMER_RULES.IN_HOME_CARE_CARD_ADVANTMED.CATEGORY,
        name: CONSUMER_RULES.IN_HOME_CARE_CARD_ADVANTMED.NAME,
    },
    [Features.RESOURCE_MA_HEALTHY_REWARDS_PROGRAM]: {
        category: CONSUMER_RULES.RESOURCE_MA_HEALTHY_REWARDS_PROGRAM.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_MA_HEALTHY_REWARDS_PROGRAM.NAME,
    },
    [Features.RESOURCE_MA_ALTERNATIVE_MEDICINE_BENEFITS]: {
        category:
            CONSUMER_RULES.RESOURCE_MA_ALTERNATIVE_MEDICINE_BENEFITS.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_MA_ALTERNATIVE_MEDICINE_BENEFITS.NAME,
    },
    [Features.RESOURCE_MEDICARE_PREVENTIVE_CARE]: {
        category: CONSUMER_RULES.RESOURCE_MEDICARE_PREVENTIVE_CARE.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_MEDICARE_PREVENTIVE_CARE.NAME,
    },
    [Features.DASHBOARD_MEDICARE_DISCOUNTS_RESOURCES]: {
        category:
            CONSUMER_RULES.DASHBOARD_MEDICARE_DISCOUNTS_RESOURCES.CATEGORY,
        name: CONSUMER_RULES.DASHBOARD_MEDICARE_DISCOUNTS_RESOURCES.NAME,
    },
    [Features.DASHBOARD_ADVANTAGES_DISCOUNTS]: {
        category: CONSUMER_RULES.DASHBOARD_ADVANTAGES_DISCOUNTS.CATEGORY,
        name: CONSUMER_RULES.DASHBOARD_ADVANTAGES_DISCOUNTS.NAME,
    },
    [Features.IS_CLAIMS_SUBMISSION_AVAILABLE]: {
        category: CONSUMER_RULES.IS_CLAIMS_SUBMISSION_AVAILABLE.CATEGORY,
        name: CONSUMER_RULES.IS_CLAIMS_SUBMISSION_AVAILABLE.NAME,
    },
    [Features.VISION_BENEFITS_V2_ENABLED]: {
        category: CONSUMER_RULES.VISION_BENEFITS_V2_ENABLED.CATEGORY,
        name: CONSUMER_RULES.VISION_BENEFITS_V2_ENABLED.NAME,
    },
    [Features.FIND_CARE_VIRTUAL_CARE_BEHAVIORAL_HEALTH]: {
        category:
            CONSUMER_RULES.FIND_CARE_VIRTUAL_CARE_BEHAVIORAL_HEALTH.CATEGORY,
        name: CONSUMER_RULES.FIND_CARE_VIRTUAL_CARE_BEHAVIORAL_HEALTH.NAME,
    },
    [Features.PREGNANCY_RESOURCE_CARD]: {
        category: CONSUMER_RULES.PREGNANCY_RESOURCE_CARD.CATEGORY,
        name: CONSUMER_RULES.PREGNANCY_RESOURCE_CARD.NAME,
    },
    [Features.RESOURCE_STRIVE]: {
        category: CONSUMER_RULES.RESOURCE_STRIVE.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_STRIVE.NAME,
    },
    [Features.RESOURCE_MA_DIABETES_PREVENTION]: {
        category: CONSUMER_RULES.RESOURCE_MA_DIABETES_PREVENTION.CATEGORY,
        name: CONSUMER_RULES.RESOURCE_MA_DIABETES_PREVENTION.NAME,
    },
}

export const featureToggleConfig: Record<string, AppModelfeatureToggle> = {
    [Features.DFW_ENABLED]: FEATURE_TOGGLES.DFW_ENABLED,
    [Features.DFW_MY_ACCOUNT_ENABLED]: FEATURE_TOGGLES.DFW_MY_ACCOUNT_ENABLED,
    [Features.DFW_INSIGHT_CARD_FAMILY_MEMBER_ENABLED]:
        FEATURE_TOGGLES.DFW_INSIGHT_CARD_FAMILY_MEMBER_ENABLED,
}

/**
 * List of feature flags we want to override through app
 * instead of using environment level global value
 */
export const overrideFeatureFlags = [...Object.values(Features)]

export default Features
