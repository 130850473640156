/* Retrieves medications by opportunity identifier (aka Pharmacy rx API opportunities) 
    for http://dfw/research/medications/{id}
    */

// TEAM REX: The opportunitiesQuery is here for possible future work. I put it in to troubleshoot an issue
// and decided to leave it because ... well ... the effort was already spent. -- Rhino B.

// export const opportunitiesQuery = `
//     query rxOpportunities {
//       rxOpportunities{
//         nodes {
//          id
//          firstName
//          lastName
//          pharmacyName
//          pharmacyNumber
//          averageCost
//          daysSupply
//          providerName
//          providerNumber   
//         }
//       }
// }
// `

export const opportunityByIdQuery = function(opportunityId: string) {
    return `
        query rxOpportunityById{
            rxOpportunity(id: ${opportunityId}) {
                id
                suffix
                firstName
                lastName
                pharmacyName
                pharmacyNumber
                averageCost
                daysSupply
                providerName
                providerNumber
                rxTreatments {
                    id,
                    primaryName,
                    secondaryName,
                    cost,
                    costFrequency,
                    deliveryMethodName,
                    treatmentCategoryName,
                    treatmentCategoryDesc,
                }
            }
     }`
}

export const OpportunityByTreatmentIdQuery = function(opp_ids: ReadonlyArray<string>, treatment_id: string) {
    return `
        query rxOpportunityByTreatment{
            rxOpportunities(ids: [${opp_ids}], treatmentId: ${treatment_id}, type: "ninety_day") {
                id
                userId
                suffix
                firstName
                lastName
                pharmacyIdentifier
                pharmacyNpi
                pharmacyName
                pharmacyNumber
                lastFillDate
                averageCost
                daysSupply
                providerIdentifier
                providerName
                providerNumber
                rxTreatments{
                    id
                    primaryName
                    cost
                    commonSideEffectList
                    rxcui
                }
                rxCampaign {
                    id
                    guid
                }
           
        }
    }`
}
