/**
 * Transform digital-first-information-service response for Redux
 * -----------------------------------------------------------------------------
 */
import { CareSubjectConnection } from "store/dfis.d"
import { Medication } from "store/rx/medications/types"
import { Props as AvatarProps } from "elements/avatar"

export const transformMedicationsApiData = (
    careSubjects: CareSubjectConnection,
): ReadonlyArray<Medication> => {
    if (!careSubjects?.edges?.length) {
        return []
    }

    const cabinets = careSubjects.edges
        .map(edge => {
            const medicineCabinets = edge.node.medicineCabinet.edges
            const membershipId = edge.node.membershipId || ""
            const updatedCabinets = medicineCabinets.map(cab => ({membershipId: membershipId, ...cab}))
            return updatedCabinets
        })
        .flat()

    const medicines = cabinets.reduce((acc, cab) => {
        cab.node.type === "CabinetMedication" && acc.push(({membershipId: cab.membershipId, ...cab.node}))
        return acc
    }, [])

    // temp fix until we have the right data
    const tempMeds = tempMedFix(medicines)
    // return medicines
    return tempMeds
}

/**
 * Data Fix
 * -----------------------------------------------------------------------------
 */

// We need to temporarily give acetaminophen a medicationTreatmentId

export const tempMedFix = (
    medicationsFromDFIS: ReadonlyArray<Medication>,
) => {
    const acetaminophenId = "WyJDYWJpbmV0TWVkaWNhdGlvbiIsIjUwNTgwLTA2MDAtMDIiLCJjYzU0MTZkYTI3YWUzNGI3ZDNmOTY3N2IyZTkzYzgwNWYzYzhkMDY5YTg4MmY3OTE0NjczMDllZGQxMGUyNjdkIl0="

    const tempMeds = medicationsFromDFIS.map(med => {
        if (med.id === acetaminophenId && med.medicationTreatmentId === "") {
            return { ...med, medicationTreatmentId: "437" }
        } else {
            return med
        }
    })
    return tempMeds
}

/**
 * Unify medications data
 * -----------------------------------------------------------------------------
 */
import { transformTreatmentApiData } from "../treatments/utils"

export const unifyMedicationData = (
    medicationsFromCAS: ReadonlyArray<Medication>,
    rxTreatments: ReadonlyArray<Medication>,
) => {
    const treatmentArray = rxTreatments ? Object.keys(rxTreatments).map(key => rxTreatments[key]) : []

    const mergedMedications = medicationsFromCAS.map(medication => {
        const correctTreatment = treatmentArray.find(
            treatment => treatment.id === medication?.medicationTreatmentId,
        )

        const transformedTreatment = correctTreatment ? transformTreatmentApiData(correctTreatment) : {}
        return { ...medication, rxTreatment: transformedTreatment }
    })

    return mergedMedications
}

/**
 * Get Medication Fill History
 * -----------------------------------------------------------------------------
 */
import { TimelineItem, MedicationEvent } from "store/timeline/types"

export const getMedicationEvents = (
    medication: Medication,
    timelineItems: ReadonlyArray<TimelineItem>,
) => {
    const medicationNdcs = [].concat(...[medication.medicationNdcs])

    // Select the care subject's medication events
    const medicationEvents = timelineItems.filter(
        item =>
            item.type === "MedicationEvent" &&
            item.careSubjectId === medication.careSubjectId,
    ) as readonly MedicationEvent[]

    // Select the events that match by medication ID, NDC, or treatment ID
    const fills = medicationEvents.filter(
        item =>
            item.medication.id === medication.id ||
            medicationNdcs.includes(item.medication.medicationNdc) ||
            item.medication?.medicationTreatmentId ===
                medication.medicationTreatmentId,
    )

    const fillsOrdered = fills.sort(function (a, b) {
        return (
            new Date(b.medication.lastFillDate).getTime() -
            new Date(a.medication.lastFillDate).getTime()
        )
    })

    return fillsOrdered
}

export const getClaims = (
    claims: ReadonlyArray<Claim> | ReadonlyArray<NativeClaim>,
    medication: Medication,
) => {
    const medicationClaims = medication?.medicationNdcs
        ?.map(ndc => {
            const dashlessNdc = ndc.replace(/-/g, "")
            const medicationClaims = claims.filter(
                claim =>
                    // Janus native `claims-inquiry-service` for backword compatibility
                    // with existing components
                    claim.membershipId === medication?.membershipId &&
                    claim.drugCode === dashlessNdc,
            )
            return medicationClaims
        })
        .flat(1)
    const sortedClaims = medicationClaims?.sort(
        (a, b) =>
            new Date(b.claimServiceStartDate).getTime() -
            new Date(a.claimServiceStartDate).getTime(),
    )
    return sortedClaims
}

/**
 * Define Medication Avatar (color and icon)
 * -----------------------------------------------------------------------------
 */
export const defineMedicationAvatar = (
    route: string,
): {
    readonly variant: AvatarProps["variant"]
    readonly icon: AvatarProps["icon"]
} => {
    switch (route) {
        case "Oral":
            return { variant: "default", icon: "pills" }
        case "Inhalant":
            return { variant: "default", icon: "inhaler" }
        case "Injectable":
            return { variant: "default", icon: "syringe" }
        case "Topical":
            return { variant: "default", icon: "lotion" }
        default:
            return { variant: "default", icon: "rx" }
    }
}

/**
 * Set IconTypes
 * -----------------------------------------------------------------------------
 */
import { IconTypes } from "elements"

const getIconType = (item?: string): IconTypes => {
    switch (item) {
        case "Oral":
            return "pills"
        case "Inhalant":
            return "inhaler"
        case "Injectable":
            return "syringe"
        // case "Topical":
        //     return "topical"
        case "Chart":
            return "chart"
        case "Map-Marker-Plus":
            return "map-marker-plus"
        case "Phone":
            return "phone"
        case "Calendar":
            return "calendar"
        case "User-Doctor":
            return "user-doctor"
        default:
            return "rx"
    }
}
/**
 * Assemble Fill Details
 * -----------------------------------------------------------------------------
 */
import { formatDate } from "utils"
import { Claim } from "@/store/claims/types"
import { Claim as NativeClaim } from "@/store/claims-inquiry/types"

const getDisplayDetails = (key: string, value: string) => {
    switch (key) {
        case "supply": {
            return {
                iconType: getIconType("Chart"),
                label: "Day supply",
                value: value,
            }
        }
        case "pharmacyName": {
            return {
                iconType: getIconType("Map-Marker-Plus"),
                label: "Pharmacy name",
                value: value,
            }
        }
        case "route": {
            return {
                iconType: getIconType(value),
                label: "how it's taken",
                value: value,
            }
        }
        case "pharmacyPhone": {
            return {
                iconType: getIconType("Phone"),
                label: "Pharmacy Phone",
                value: value,
            }
        }
        case "lastFillDate": {
            return {
                iconType: getIconType("Calendar"),
                label: "Last filled",
                value: value ? formatDate(value, "P") : "N/A",
            }
        }
        case "prescriberName": {
            return {
                iconType: getIconType("User-Doctor"),
                label: "Prescriber",
                value: value,
            }
        }
    }
}

const fillFromMedication = (item: Medication) => {
    const fillDetails = {
        supply: item.supply.value,
        pharmacyName: item.pharmacyName,
        route: item.dosage.routeCategory,
        pharmacyPhone: item.pharmacyPhone,
        lastFillDate: item.lastFillDate,
        prescriberName: item.prescriberName,
        fillCost: item.fillCost,
    }

    const fill = Object.keys(fillDetails).map(key =>
        getDisplayDetails(key, fillDetails[key]),
    )
    return fill
}

export const fillFromTimeline = (event: MedicationEvent) => {
    const fillDetails = {
        supply: event.supply.value,
        pharmacyName: event.medication.pharmacyName,
        route: event.medication.dosage.routeCategory,
        pharmacyPhone: event.medication.pharmacyPhone,
        lastFillDate: event.medication.lastFillDate,
        prescriberName: event.medication.prescriberName,
        fillCost: event.medication.fillCost,
    }

    const fill = Object.keys(fillDetails).map(key =>
        getDisplayDetails(key, fillDetails[key]),
    )
    return fill
}

export const getCurrentFill = (
    item: Medication,
    event: MedicationEvent,
) => {
    if (
        item.supply.value &&
        item.dosage.routeCategory &&
        item.lastFillDate &&
        item.pharmacyName &&
        item.pharmacyPhone &&
        item.prescriberName
        // && item.fillCost
    ) {
        return fillFromMedication(item)
    } else {
        return event && fillFromTimeline(event)
    }
}
