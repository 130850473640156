import * as React from "react"
import { SVGProps } from "react"
const SvgXSm = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Close X" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M14.7577 9.2422L9.24225 14.7576M14.7577 14.7576L9.24231 9.24219"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgXSm
