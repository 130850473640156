/**
 * Button
 * -----------------------------------------------------------------------------
 */
import React from "react"
import NextLink from "next/link"
import { Icon, IconTypes, ExternalLinkModal } from "elements"
import {
    tagEvent,
    LinkEvent,
    SearchSelectEvent,
    SearchEvent,
} from "utils/analytics"

/**
 * Props
 * -----------------------------------------------------------------------------
 */
export interface Props extends React.HTMLAttributes<HTMLButtonElement> {
    /**
     * Base
     */
    readonly name: string
    readonly label?: string
    readonly labelClass?: string

    /**
     * Icons
     */
    readonly icon?: IconTypes
    readonly iconRight?: IconTypes
    readonly iconNode?: React.ReactNode
    readonly iconSize?: number

    /**
     * Display
     */
    readonly variant?:
    | "primary"
    | "secondary"
    | "accent"
    | "success"
    | "error"
    | "link"
    | "none"
    readonly size?: "small" | "medium" | "large"
    readonly inverted?: boolean
    readonly rounded?: boolean

    /**
     * States
     */
    readonly disabled?: boolean
    readonly loading?: boolean
    readonly shimmer?: boolean

    /**
     * Behavior
     */
    readonly href?: string
    readonly target?: string
    readonly onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
    readonly passHref?: string
    readonly isExternal?: boolean
    readonly stopPropagation?: boolean
    readonly windowTitle?: string //To display custom user-friendly title of the window that will get opened on click of button

    /**
     * Extended
     */
    readonly type?: "button" | "submit" | "reset"
    readonly children?: React.ReactNode

    /**
     * Analytics
     */
    readonly analytics?: Partial<LinkEvent>
    readonly searchAnalytics?: Partial<SearchEvent>
    readonly searchSelectAnalytics?: Partial<SearchSelectEvent>
    readonly dataAnalyticsId?: string
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Button: React.FC<Props> = props => {
    /**
     * Component state
     */
    const [modal, setModal] = React.useState(false)

    /**
     * Methods
     */
    const handleAnalytics = () => {
        if (props.searchAnalytics) {
            tagEvent({
                tealium_event: props.searchAnalytics.tealium_event,
                page_url: props.searchAnalytics.page_url,
                page_path: props.searchAnalytics.page_path,
                search_query: props.searchAnalytics.search_query,
                search_results: props.searchAnalytics.search_results,
                rx_medication_id: props.searchAnalytics.rx_medication_id || "",
                rx_condition_id: props.searchAnalytics.rx_condition_id || "",
            })
        } else if (props.searchSelectAnalytics) {
            tagEvent({
                tealium_event: props.searchSelectAnalytics.tealium_event,
                page_url: props.searchSelectAnalytics.page_url,
                page_path: props.searchSelectAnalytics.page_path,
                search_query: props.searchSelectAnalytics.search_query,
                rx_medication_id:
                    props.searchSelectAnalytics.rx_medication_id || "",
                rx_condition_id:
                    props.searchSelectAnalytics.rx_condition_id || "",
            })
        } else {
            tagEvent({
                tealium_event: "link",
                data_analytics_id: props.name,
                link_text: props.label || props.icon || "",
                link_url: props.href || "",
                ...props.analytics,
            })
        }
    }

    const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // Conditionally stop parent events
        if (props.stopPropagation) event.stopPropagation()

        if (!props.disabled) {
            // Handle analytics
            handleAnalytics()

            // Fire `onClick` event
            if (props.onClick) props.onClick(event)
        }
    }

    /**
     * Destructure component props
     */
    const {
        name,
        label,
        variant = "primary",
        size = "medium",
        inverted,
        loading,
        icon,
        iconRight,
        iconNode,
        iconSize,
        className = "",
        rounded,
        labelClass,
        href,
        passHref,
        isExternal,
        analytics,
        windowTitle,
        ...rest
    } = props

    /**
     * Styles
     */
    const noAppearance = variant === "none"
    const base =
        "disabled:opacity-50 disabled:cursor-not-allowed subpixel-antialiased transform active:translate-y-one self-start"
    const offset = inverted ? "focus-offset-inverted" : "focus-offset"
    const variants = {
        primary: inverted
            ? "bg-white text-primary border-white hover:opacity-90 dark:bg-dark-200 dark:text-accent"
            : "bg-primary border border-primary text-white hover:bg-primary-200 hover:border-primary-200 dark:bg-primary-vivid dark:border-primary-vivid dark:hover:bg-primary-200 dark:hover:border-primary-200",
        secondary: inverted
            ? "bg-primary text-white border border-white hover:bg-primary-300"
            : "bg-white text-primary border border-primary hover:text-primary-200 hover:border-primary-200 dark:bg-dark-200 dark:text-gray-50 dark:border-gray-200 dark:hover:text-white dark:hover:border-gray-50",
        accent: inverted
            ? "bg-accent border border-accent text-white hover:opacity-90"
            : "bg-accent border border-accent text-white hover:bg-accent-200 hover:border-accent-200",
        link: inverted
            ? "text-white border border-transparent hover:opacity-80"
            : "text-primary-vivid border border-transparent hover:opacity-80 dark:text-accent",
        success: "text-success-300 hover:opacity-90",
        error: "text-error-300 hover:opacity-90",
        none: "",
    }
    const radius = rounded ? "rounded-circle" : "rounded-sm"
    const sizes = {
        small: label ? "min-h-[32px] px-xs" : "min-h-[32px] w-md",
        medium: label ? "min-h-[40px] px-sm" : "min-h-[40px] w-lg",
        large: label ? "min-h-[48px] px-md" : "min-h-[48px] w-xl",
    }
    const buttonClassNames = noAppearance
        ? `${base} ${offset} ${className}`
        : `${base} ${offset} ${sizes[size]} ${variants[variant]} ${radius} ${className}`
    const anchorClasses = noAppearance
        ? "no-underline"
        : "inline-block no-underline align-top"

    /**
     * Define template variables
     */
    const defaultIconSize = size === "small" ? 18 : 24
    const _iconSize = iconSize || defaultIconSize

    /**
     * Button content
     */
    const variantLocal = variants[props.variant || "default"]
    const buttonContent = (
        <div className="relative w-full min-h-[inherit]">
            <div
                className={`min-h-[inherit] flex items-center justify-center ${loading ? "opacity-0" : ""
                    }`}
            >
                {/* Custom icon node */}
                {iconNode && <div className="-ml-xxs mr-xxs">{iconNode}</div>}

                {/* Left Icon */}
                {icon && (
                    <Icon
                        type={icon}
                        className={`transform -translate-y-one ${label ? "mr-xxs -ml-xxs" : ""
                            }`}
                        size={_iconSize}
                        ariaLabel={props.name}
                    />
                )}

                {/* Label */}
                {label && <div className={labelClass}>{label}</div>}
                {!label && <span className="sr-only">{props.name}</span>}

                {/* Children nodes */}
                {props.children && props.children}

                {/* Right Icon */}
                {iconRight && (
                    <Icon
                        type={iconRight}
                        className={`transform -translate-y-one ${label || icon ? "ml-xxs -mr-xxs" : ""
                            }`}
                        size={_iconSize}
                        ariaLabel={props.name}
                    />
                )}
            </div>
            {loading && (
                <div className="absolute top-none w-full h-full flex items-center justify-center">
                    <Icon
                        type="spinner"
                        className="animate-spin"
                        size={_iconSize}
                        ariaLabel={props.name}
                    />
                </div>
            )}
        </div>
    )

    /**
     * Template: Link Button
     */
    if (props.href || props.passHref) {
        return (
            <React.Fragment>
                <NextLink href={props.href || ""} legacyBehavior={true}>
                    <a
                        data-test={name}
                        className={`${buttonClassNames} ${anchorClasses}`}
                        target={props?.target}
                        href={props.passHref || props.href}
                        data-analytics-id={props.dataAnalyticsId || name}
                        data-analytics-event="dfwEvent"
                        onClick={e => {
                            if (windowTitle) {
                                e.preventDefault()
                                const w = window.open(passHref)
                                setTimeout(() => {
                                    // eslint-disable-next-line functional/immutable-data
                                    w.document.title = windowTitle;

                                }, 1000)
                            }
                            if (isExternal) {
                                e.preventDefault()
                                setModal(true)
                            }
                            handleAnalytics()
                        }}
                    >
                        {noAppearance ? props.children : buttonContent}
                    </a>
                </NextLink>

                {/* External link modal */}
                {isExternal && (
                    <ExternalLinkModal
                        name={props.name + "-modal"}
                        isVisible={modal}
                        onDismiss={() => setModal(false)}
                        href={props.href}
                        target={props.target}
                    />
                )}
            </React.Fragment>
        )
    }

    /**
     * Template: Click Button
     */
    return (
        <button
            {...rest}
            {...analytics}
            name={name}
            data-test={name}
            onClick={onClick}
            className={buttonClassNames}
        >
            {noAppearance ? props.children : buttonContent}
        </button>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Button
