/**
 * RX Medication Utilities
 */
import { Coverage } from "@/store/rx/coverages/types"
import { getPageIdentifier, getPageUrl } from "../analytics"
import { formatCase, formatDate } from "utils"
import { ContentBlock, FRAGMENT_IDS, ManagedContentStatus, fetchManagedContentMultiFragment } from "../styx"
import React from "react"
import { Insight } from "@/store/insights/types"
import { ContentCardActionTypes } from "store/dfis.d"
import moment from "moment"
import { SliceState } from "@/store/util/create-stateful-slice"
import { Insights } from "@/store/insights/reducer"
import { useFeatures } from "@/store/app/selectors"
import Features from "../features"
import { Campaigns } from "@/store/rx/campaigns/reducer"

const rxFullFeatures = () => {
    const {
        [Features.DASHBOARD_INSIGHTS_CARD_RX_ENHANCED]:dashboardEnhanced,
        [Features.PHARMACY_PAGE_RX_ENHANCED]: rxEnhanced,
        [Features.PHARMACY_CVS]: pharmacyCvs,
    } = useFeatures()

    return dashboardEnhanced || rxEnhanced || pharmacyCvs
}

export const isCampaign = (insight: Insight) => {
    return (
        insight.action?.type ===
        ContentCardActionTypes.MedSavvyWebViewAction ||
        insight.action?.type ===
        ContentCardActionTypes.MedSavvySwitchMedsAction
    )
}

export const publishedCampaign = (insight: Insight, campaigns: Campaigns) => {
    if (!isCampaign(insight)){
        return true
    }

    const campaignId = insight.action?.campaignId
    const campaignRoute = insight.action?.route
    const isCampaignRoute =
        campaignRoute?.split("/").slice(3, 4)[0] == "campaign"
    const campaignGuid = isCampaignRoute
        ? campaignRoute?.split("/").slice(4, 5)[0].split("?").slice(0, 1)[0]
        : null      
    const id = campaignGuid || campaignId
    const campaign = campaigns?.items.find(campaign => campaign.id == id || campaign.guid == id) 
    return campaign?.published || false
}

export const getOpportunityIds = (insights: Insights) => {
    const opportunityIds = insights.items.map(insight => {
        if (!insight.action.route) return null
        const url = new URL(insight.action.route)
        return url.searchParams.get("opportunity_id")
    })
    return opportunityIds ? opportunityIds.filter((value) => value != null) : []
}

/**
 * missed_refill masquarading as non_rx
 */
export const isMissingRefill = (title: string) => {   
    const missedRefillText = "Your medication"
    return title?.includes(missedRefillText)
}

const campaignCategory = (insight: Insight) => {   
    return insight?.analyticsTitle?.split("recommendation_identified-").pop()
}

const missed_refill = (title: string) => {   
    return isMissingRefill(title)
}

export const campaignNonRx = (categoryKey: string, title: string) => {
    const campaignKey = categoryKey?.split("recommendation_identified-").pop()
    return ["non_rx"].includes(campaignKey) && !missed_refill(title)
}

const showCampaignInsight = (insight: Insight) => {   
    if (rxFullFeatures) {
        return true
    }

    const categories = ["safety", "message", "recall"]
    const categoryKey = campaignCategory(insight)
    const validAsoBaseInsights = categories.includes(categoryKey) || campaignNonRx(categoryKey, insight.title)
    return validAsoBaseInsights
}

export const findViewableInsights = (insights: SliceState<Insights>, selectedMembershipId: string) => {
    const selectedMember = insights?.members?.find(m => m.membershipId === selectedMembershipId)
    if (!selectedMember?.disclosureAllowed) {
        return []
    }

    const viewableInsights = insights?.items?.filter(i => i?.member?.membershipId === selectedMembershipId)
    const sortedInsights = [...viewableInsights].sort((b, a) => Number(a.isNew) - Number(b.isNew))
    return sortedInsights
}

export const showInsightItems = (insights: SliceState<Insights>) => {
    return insights?.items?.filter(insight => {
        if (!isCampaign(insight)) {
            return insight
        } else if (showCampaignInsight(insight)) {
            return insight
        }
    })
}

export const findPharmacyInsights = (insights: ReadonlyArray<Insight>) => {
    return insights?.filter(insight => {
        if (isCampaign(insight) && showCampaignInsight(insight)) {
            return insight
        }
    })
}

export const insightsByDate = (insights: ReadonlyArray<Insight>) => {
    const filteredItems = [...insights]
    // eslint-disable-next-line functional/immutable-data
    return filteredItems.sort(function (a, b) {
        return a.startDate > b.startDate
            ? -1
            : a.startDate < b.startDate
                ? 1
                : 0
    })
}

export const daysDifference = (startDate: Date, endDate: Date) => {
    const nullDate = new Date(1980, 0, 1, 0, 0, 0);
    const today = new Date()
    const startMoment = moment(startDate || nullDate)
    const endMoment = moment(today)
    const diffDays = endMoment.diff(startMoment, "days", false)
    return diffDays
}

export const currentInsights = (insights: ReadonlyArray<Insight>) => {
    return insights?.filter(x => (x.dismissed === null || x.dismissed === false))?.map(x => x)
}
    
export const dismissedInsights = (insights: ReadonlyArray<Insight>) => {
    // 30 days dismissed is filtered in store/action => .filter(x => daysDifference(x.dismissedAt, today) <= 30)
    return insights?.filter(x => x.dismissed)?.map(x => x)
}

export const insightDateFormatter = (insight: Insight) => {
    const today = new Date()
    const noOfDays = daysDifference(insight.startDate, today)

    if (insight.startDate === null) {
        return ""
    } else if (insight.dismissed) {
        return `sent on: ${formatDate(insight.startDate, "P")}`
    } else if (noOfDays === 0){
        return "Today"
    } else if (noOfDays === 1) {
        return `1 day ago`
    } else if (noOfDays <= 30) {
        return `${noOfDays} days ago`
    } else {
        return formatDate(insight.startDate, "P")
    }
}

export const getKeyIdentifier = (pathname: string, key: string) => {
    const pageUrl = getPageUrl(pathname)
    const identifier = getPageIdentifier(pathname)
    return pageUrl === key ? identifier : ""
}

export const getEventData = (
    identifier: string,
    eventData: object,
    key: string,
) => {
    const event_data_id = eventData ? eventData[key] : ""
    const identifier_id = event_data_id ? event_data_id : identifier
    // strip parameters after the "?"
    return identifier_id.split("?")[0]
}

/**
 * Get Campaign Id
 */
export const getCampaignId = (pathname: string, eventData?: object) => {
    const identifier = getKeyIdentifier(pathname, "campaign")
    return getEventData(identifier, eventData, "rx_campaign_id")
}

/**
 * Get Condition Id
 */
export const getConditionId = (pathname: string, eventData?: object) => {
    const identifier = getKeyIdentifier(pathname, "conditions")
    return getEventData(identifier, eventData, "rx_condition_id")
}

/**
 * Get ConditionMedication Id
 */
export const getConditionMedicationId = (
    pathname: string,
    eventData?: object,
) => {
    const identifier = getKeyIdentifier(pathname, "condition_medications")
    return getEventData(identifier, eventData, "rx_conditionMedication_id")
}

/**
 * Get Medication Id
 */
export const getMedicationId = (pathname: string, eventData?: object) => {
    const identifier = getKeyIdentifier(pathname, "medications")
    return getEventData(identifier, eventData, "rx_medication_id")
}

/**
 * Get Pharmacy Id
 */
export const getPharmacyId = (pathname: string, eventData?: object) => {
    const identifier = getKeyIdentifier(pathname, "pharmacies")
    return getEventData(identifier, eventData, "rx_pharmacy_id")
}

/**
 * Get Treatment Id
 */
export const getTreatmentId = (pathname: string, eventData?: object) => {
    const identifier = getKeyIdentifier(pathname, "treatments")
    return getEventData(identifier, eventData, "rx_treatment_id")
}

/**
 * Get Coverage
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getCoverageDetails = (coverage: Coverage): ReadonlyArray<any> => {
    if(coverage === undefined){
        return ["", "", "", false]
    }
    const included = coverage?.excluded === "false"
    const excluded = coverage?.excluded === "true"
    const tierRaw = coverage?.tierDescription?.toLowerCase()
    const inTier = tierRaw?.toLowerCase()?.includes("tier")
    const tier = getTier(tierRaw)
    const drugList = coverage?.insuranceItemName?.toLowerCase()
    const onDrugList =
        !drugList?.includes("not") && drugList?.includes("on drug")
    const validIncludedPill = included && inTier && onDrugList
    const showPill = checkPillValidity(
        validIncludedPill,
        excluded,
        inTier,
        drugList,
    )

    const isCoveredText = getCoverageText(included, excluded)
    const tierDescriptionText = getTierText(inTier, tier)
    const onDrugListText = getDrugListText(drugList)

    const tierText = `${isCoveredText}, ${onDrugListText}, ${tierDescriptionText}`
    const tierVariant = validIncludedPill ? "success" : "error"
    const tierIcon = validIncludedPill ? "check" : "x"
    return [tierText, tierVariant, tierIcon, showPill]
}

const getCoverageText = (included, excluded) => {
    if (included) {
        return "Covered"
    } else if (excluded) {
        return "Not covered"
    } else {
        return ""
    }
}

const getDrugListText = drugList => {
    if (drugList?.includes("not")) {
        return "not on drug list"
    } else if (drugList?.includes("medd excluded")) {
        return "MedD Excluded"
    } else if (drugList?.includes("on drug")) {
        return "on drug list"
    } else {
        return ""
    }
}

const getTier = tierRaw => {
    const index = tierRaw?.indexOf(":") + 1
    const tierReplaced = tierRaw?.replace(/_/g, " ").replace(/:/g, "/")
    const tierCaps1 = formatCase(tierReplaced, "capitalize")
    const tierCaps2 =
        tierReplaced?.charAt(0)?.toUpperCase() +
        tierReplaced?.slice(1, index) +
        tierReplaced?.charAt(index)?.toUpperCase() +
        tierReplaced?.slice(index + 1)
    const tier = index == 0 ? tierCaps1 : tierCaps2
    return tier
}

const getTierText = (inTier, tier) => {
    if (inTier) {
        return tier
    } else if (!inTier) {
        return "not in tier"
    } else {
        return ""
    }
}

const checkPillValidity = (validIncludedPill, excluded, inTier, drugList) => {
    const validExcludedPill = excluded && !inTier && drugList?.includes("not")
    const validMedDPill = excluded && drugList?.includes("medd excluded")
    return validIncludedPill || validExcludedPill || validMedDPill
}

export const pillReadyClass = (primeCoverageEnabled, coverages, showPill) => {
    if (primeCoverageEnabled && !coverages.isInitialized) {
        return "ml-none"
    } else if (primeCoverageEnabled && coverages.isInitialized && showPill) {
        return "ml-none"
    } else if (!coverages.isLoading && (!primeCoverageEnabled || !showPill)) {
        return "hidden"
    } else {
        return "ml-none"
    }
}

/**
 * Get Ask A Pharmacist Pictograms
 */
export const getAskAPharmacistPictograms = () => {
    const [styxLoadingStatus, setStyxLoadingStatus] = React.useState<ManagedContentStatus>("pristine")
    const [resourcesFragment, setResourcesFragment] = React.useState<ReadonlyArray<ContentBlock>>([])
    const fragment_id = FRAGMENT_IDS.PHARMACY_ASK_A_PHARMACIST

    React.useEffect(() => {
        fetchManagedContentMultiFragment(fragment_id, setStyxLoadingStatus, setResourcesFragment)
    }, [])

    const resource = resourcesFragment[0]
    const regencePictogram = styxLoadingStatus !== "loading" ? resource?.pictograms?.[0].regence : "reg_alt_medicine_pear"
    const asurisPictogram = styxLoadingStatus !== "loading" ? resource?.pictograms?.[0].asuris : "ahs_alternative_medicine"
    const bshPictogram = styxLoadingStatus !== "loading" ? resource?.pictograms?.[0].bridgespan :"bsh_medication_alt"
    return {regence: regencePictogram, asuris: asurisPictogram, bridgespan: bshPictogram}
}