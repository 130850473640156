import * as React from "react"
import { SVGProps } from "react"
const SvgShare = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Share" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M8.2 13.7002L15.8 18.0002M15.8 6.00018L8.2 10.3002M22 4.30018C22 6.12272 20.5225 7.60018 18.7 7.60018C16.8775 7.60018 15.4 6.12272 15.4 4.30018C15.4 2.47764 16.8775 1.00018 18.7 1.00018C20.5225 1.00018 22 2.47764 22 4.30018ZM8.6 12.0002C8.6 13.8227 7.12254 15.3002 5.3 15.3002C3.47746 15.3002 2 13.8227 2 12.0002C2 10.1776 3.47746 8.70018 5.3 8.70018C7.12254 8.70018 8.6 10.1776 8.6 12.0002ZM22 19.7002C22 21.5227 20.5225 23.0002 18.7 23.0002C16.8775 23.0002 15.4 21.5227 15.4 19.7002C15.4 17.8776 16.8775 16.4002 18.7 16.4002C20.5225 16.4002 22 17.8776 22 19.7002Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgShare
