import * as React from "react"
import { SVGProps } from "react"
const SvgMoney = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Dollar sign"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6.5 17.7C10.5 21.5 17.5 19.6 17.5 15.8C17.5 13 14.5 12 12.5 12H11.5C9.5 12 6.5 11.1 6.5 8.2C6.5 4.4 13.5 2.5 17.5 6.3M12 22V2"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgMoney
