/* eslint-disable no-console */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions } from "store"
import http from "utils/http"
import {
    deleteEFTDetailsMutationQuery,
    fetchReimbursementDetailsQuery,
    fetchReimbursementOptionsQuery,
    saveEFTDetailsMutationQuery,
} from "./queries"
import { EFTDetails, OptionTypesParams } from "./types"

/**
 * Get claim service data
 * -----------------------------------------------------------------------------
 */
export const fetchReimbursementDetails = createAsyncThunk(
    "claimReimbursementDetails/fetchReimbursementDetails", // Reducer name
    async (_params: string, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.claimReimbursementDetailsStatus("pending"))
        /**
         * Request claim services data
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            fetchReimbursementDetailsQuery(),
        )

        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.claimReimbursementDetailsError({
                    response: res,
                }),
            )
        }

        const reimbursementDetails = res.data?.data?.claimSubmission

        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimsReimbursementDetails({
                details: reimbursementDetails || {},
            }),
        )
    },
)

export const fetchReimbursementOptions = createAsyncThunk(
    "claimReimbursementDetails/fetchReimbursementDetails", // Reducer name
    async (params: OptionTypesParams, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.claimReimbursementDetailsStatus("pending"))
        /**
         * Request claim services data
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            fetchReimbursementOptionsQuery(params.suffix, params.claimType),
        )

        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.claimReimbursementDetailsError({
                    response: res,
                }),
            )
        }

        const reimbursementDetails =
            res.data?.data?.claimSubmission?.reimbursementDetails

        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimsReimbursementOptions({
                ...(reimbursementDetails.options || {}),
            }),
        )
    },
)

export const saveEFTDetails = createAsyncThunk(
    "claimReimbursementDetails/saveEFTDetails", // Reducer name
    async (params: EFTDetails, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.claimReimbursementDetailsStatus("pending"))
        /**
         * Request claim services data
         */
        const mutationVariables = {
            input: { ...params },
        }
        try {
            const res = await http.post(
                "/api/janus/digital-first-information-service/graphql",
                {
                    query: saveEFTDetailsMutationQuery,
                    variables: mutationVariables,
                },
            )

            /**
             * Handle request errors
             */
            if (res.error || res.data?.errors) {
                throw res
            }

            if (res.data?.data.addEFTDetails.code !== 200) {
                throw res
            }
            dispatch(actions.modifyEFTDetailsSuccess(params))
            return Promise.resolve(res)
        } catch (error) {
            console.error("Failed to save EFT details")
            throw Error("Failed to save")
        }
    },
)

export const deleteEFTDetails = createAsyncThunk(
    "claimReimbursementDetails/deleteEFTDetails", // Reducer name
    async (_params: EFTDetails, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.claimReimbursementDetailsStatus("pending"))
        /**
         * Request claim services data
         */

        try {
            const res = await http.post(
                "/api/janus/digital-first-information-service/graphql",
                {
                    query: deleteEFTDetailsMutationQuery,
                },
            )

            /**
             * Handle request errors
             */
            if (res.error || res.data?.errors) {
                throw res
            }

            if (res.data?.data.deleteEFTDetails.code !== 200) {
                throw res
            }
            dispatch(actions.modifyEFTDetailsSuccess(null))
            return Promise.resolve(res)
        } catch (error) {
            console.error("Failed to delete EFT details")
            throw Error("Failed to delete")
        }
    },
)
