import { TaxFaqs } from "./reducer"

export const transformTaxFaq = (
    data: any, // eslint-disable-line
): TaxFaqs => {
    // const faqs = data?.[0].panel?.questionsAndAnswers
    return data.map(d => {
        return {
            title : d.title,
            bodyText: d.bodyText
        }
    })
}