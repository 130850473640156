import * as React from "react"
import { SVGProps } from "react"
const SvgCheckCircle = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Checkmark inside circle"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M7.70711 12.7929C7.31658 12.4024 6.68342 12.4024 6.29289 12.7929C5.90237 13.1834 5.90237 13.8166 6.29289 14.2071L7.70711 12.7929ZM9.7 16.2L8.99289 16.9071C9.18043 17.0946 9.43478 17.2 9.7 17.2C9.96522 17.2 10.2196 17.0946 10.4071 16.9071L9.7 16.2ZM18.6071 8.70711C18.9976 8.31658 18.9976 7.68342 18.6071 7.29289C18.2166 6.90237 17.5834 6.90237 17.1929 7.29289L18.6071 8.70711ZM6.29289 14.2071L8.99289 16.9071L10.4071 15.4929L7.70711 12.7929L6.29289 14.2071ZM10.4071 16.9071L18.6071 8.70711L17.1929 7.29289L8.99289 15.4929L10.4071 16.9071ZM22 12C22 17.5228 17.5228 22 12 22V24C18.6274 24 24 18.6274 24 12H22ZM12 22C6.47715 22 2 17.5228 2 12H0C0 18.6274 5.37258 24 12 24V22ZM2 12C2 6.47715 6.47715 2 12 2V0C5.37258 0 0 5.37258 0 12H2ZM12 2C17.5228 2 22 6.47715 22 12H24C24 5.37258 18.6274 0 12 0V2Z"
            fill="currentColor"
        />
    </svg>
)
export default SvgCheckCircle
