import React from "react"

/**
 * Debounce value
 */
export function useDebounce(value: string, delay: number): string {
    const [debouncedValue, setDebouncedValue] = React.useState(value)

    React.useEffect(() => {
        const handler = setTimeout(() => setDebouncedValue(value), delay)
        return () => clearTimeout(handler)
    }, [value]) // eslint-disable-line

    return debouncedValue
}
