import { CareSubjectConnection } from "store/dfis.d"
import { ProviderItem, Visit } from "./types"
import { Props as AvatarProps } from "elements/avatar"
import { formatCase, formatDate} from "utils"

/**
 * Transform service response for Redux
 * -----------------------------------------------------------------------------
 */
export const transformProvidersAPIData = (
    careSubjects: CareSubjectConnection,
) => {
    return careSubjects.edges.reduce((providersByMember, careSubject) => {
        const events = careSubject?.node.events.edges || []

        const practitionerEvents = events.filter(
            event => event.nodeType === "PractitionerEvent"
        )
        const providerItems = practitionerEvents.reduce(
            practitionerEventReducer,
            []
        )
        const memberKey = careSubject.node.membershipId ? careSubject.node.membershipId : careSubject.node.careSubjectId

        // eslint-disable-next-line functional/immutable-data
        providersByMember[memberKey] = providerItems
        return providersByMember
    }, {})
}


/**
 * Group practitiner events by NPI so that for a given provider,
 * we could get all visits and related claim information.
 * @param result Array<ProviderItem>
 * @param event PractitionerEvent
 * @returns Array<ProviderItem>
 */
// eslint-disable-next-line functional/prefer-readonly-type
export const practitionerEventReducer = (result: Array<ProviderItem>, event): Array<ProviderItem> => {
    const eventData = event.node
    const npi = eventData.practitioner.npi
    const provider = result.filter(provider => provider.npi === npi)
    const visitRecord: Visit = {
        eventDate: formatDate(eventData.eventDate, "MMMM d, yyyy"),
        claimId: eventData.claimId
    }
    if (provider && provider[0]) {
        // provider record exist, just update the visits array
        provider[0].visits.push(visitRecord)
        return result
    } else {
        // provider record doesn't exist, create and put new visit entry
        const index = result.length
        const providerEntry = {
            dataAnalyticsId: `doctors-and-providers-item-${index}`,
            encounterType: eventData.encounterType || "",
            providerName: formatCase(
                eventData.practitioner.displayName ||
                "",
                "titlecase",
            ),
            speciality: formatCase(
                eventData.practitioner.specialty ||
                "",
                "titlecase",
            ),
            facility: formatCase(
                eventData.practitioner.taxName ||
                "",
                "titlecase",
            ),
            npi: eventData.practitioner.npi || "",
            visits: [visitRecord]
        }
        result.push(providerEntry)
    }
    return result
}


/**
 * Define doctors and providers avatar (color and icon)
 * -----------------------------------------------------------------------------
 */
export const getAvatar = (
    encounterType: string,
): {
    readonly variant: AvatarProps["variant"]
    readonly icon: AvatarProps["icon"]
} => {
    switch (encounterType) {
        case "Dental":
            return { variant: "default", icon: "tooth" }
        case "Vision":
            return { variant: "default", icon: "glasses" }
        case "Medical":
            return { variant: "default", icon: "stethoscope" }
        case "Hospital":
            return { variant: "default", icon: "building" }
        default:
            return { variant: "default", icon: "stethoscope" }
    }
}
