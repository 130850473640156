/**
 * Avatar
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Icon, IconTypes, Badge } from "elements"
import { Pictogram, PictogramProps } from "heartwood-component-library"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Props extends React.HTMLAttributes<HTMLDivElement> {
    readonly variant?:
    | "default"
    | "white"
    | "primary"
    | "primary-200"
    | "secondary"
    | "accent"
    | "success"
    | "info"
    | "warning"
    | "error"
    | "muted"
    | "disabled"
    readonly size?: "xsmall" | "small" | "medium" | "large"
    readonly label?: string
    readonly icon?: IconTypes
    readonly pictogram?: typeof Pictogram
    readonly shimmer?: boolean
    readonly badge?: string | boolean
    readonly regencePictogram?: PictogramProps["regence"]
    readonly asurisPictogram?: PictogramProps["asuris"]
    readonly bridgespanPictogram?: PictogramProps["bridgespan"]
    readonly showAll?: boolean
}


/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Avatar: React.FC<Props> = props => {
    /**
     * Define template variables
     */
    const variants = {
        default:
            "bg-primary-50 text-primary-vivid dark:bg-dark-50 dark:text-accent",
        white: "bg-white text-primary-vivid dark:bg-dark-50 dark:text-accent",
        primary: "bg-primary-300 dark:bg-primary-vivid text-white",
        "primary-200": "bg-primary-200 dark:bg-primary-vivid text-white",
        secondary: "bg-secondary dark:bg-secondary-vivid text-white",
        accent: "bg-accent text-white",
        success: "bg-success dark:bg-success-300 text-white",
        info: "bg-info dark:bg-info-300 text-white",
        warning: "bg-warning dark:bg-warning-300 text-white",
        error: "bg-error dark:bg-error-300 text-white",
        muted: "bg-gray-200 dark:bg-dark-100 text-white",
        disabled: "bg-gray-50 text-gray-100",
    }
    const sizes: {
        readonly [key: string]: {
            readonly widthClass: string
            readonly textClass: string
            readonly iconSize: number
        }
    } = {
        xsmall: {
            widthClass: "w-md h-md flex-none",
            textClass: "text-base",
            iconSize: 16,
        },
        small: {
            widthClass: "w-lg h-lg flex-none",
            textClass: "text-base",
            iconSize: 24,
        },
        medium: {
            widthClass: "w-xl h-xl flex-none",
            textClass: "text-lg",
            iconSize: 24,
        },
        large: {
            widthClass: "w-xxxl h-xxxl flex-none",
            textClass: "text-2xl",
            iconSize: 30,
        },
    }
    const variant = variants[props.variant || "default"]
    const size = sizes[props.size || "medium"]
    const initials = props.label ? props.label.slice(0, 2) : undefined
    const label = props?.showAll ? "All" : initials


    /**
     * Template
     */
    if (props.shimmer) {
        return (
            <div
                className={`rounded-circle shimmer ${size.widthClass} ${props.className}`}
            />
        )
    }
    return (
        <div
            className={`relative rounded-circle flex items-center justify-center ${size.widthClass} ${size.textClass} ${variant} ${props.className}`}
        >
            {label && label}
            {props.icon && <Icon type={props.icon} size={size.iconSize} />}
            {props.badge && typeof props.badge === "string" && (
                <Badge
                    label={props.badge}
                    className="absolute -top-xxs -right-xxs"
                />
            )}
            {props.badge && typeof props.badge !== "string" && (
                <Badge className="absolute top-none right-none" />
            )}
        </div>
    )
}

/**
 * Subcomponent: AvatarStack
 * -----------------------------------------------------------------------------
 */
interface StackProps extends React.HTMLAttributes<HTMLDivElement> {
    readonly items: ReadonlyArray<Props>
    readonly reverse?: boolean
}
export const AvatarStack: React.FC<StackProps> = props => {
    const zSidebar = props.reverse ? `last:z-sidebar` : `first:z-sidebar`
    return (
        <div className={`flex ${props.className}`}>
            {props.items.map((item, index) => (
                <Avatar
                    key={index}
                    {...item}
                    className={`z-body -ml-xxs first:-ml-none ${zSidebar}`}
                />
            ))}
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Avatar
