/**
 * LN-device Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { http } from "utils"
import {isLocalDevelopment} from "@/utils/env"
import { trueFalseStringToBoolean } from "@/utils/string"


interface MaasRiskAssessmentRequest {
    readonly deviceSessionId: string
    readonly suffix: string
    readonly subscriberId: string
    readonly email: string
    readonly rememberDevice: string
    readonly brandFamily: string  
}

const maasEligibilityEndpoint = () => {
    return isLocalDevelopment(window?.location?.host)
        ? "/api/janus/digital-first-api-routing-service/account-access/v1/eligibility"
        : "/api/account-access/v1/eligibility"
}

const maasAssessRiskEndpoint = () => {
    return isLocalDevelopment(window?.location?.host)
        ? "/api/janus/digital-first-api-routing-service/account-access/v1/register/assess-risk"
        : "/api/account-access/v1/register/assess-risk"
}

const maasSavDeviceEndpoint = () => {
    return isLocalDevelopment(window?.location?.host)
        ? "/api/janus/digital-first-api-routing-service/account-access/v1/register/save-device"
        : "/api/account-access/v1/register/save-device"
}

/**
 * Check LN-device eligibility 
 * -----------------------------------------------------------------------------
 */
export const processLNRegistrationFlow = createAsyncThunk(
    "ln-device/processLNRegistrationFlow", // Reducer name
    async (params: MaasRiskAssessmentRequest, { dispatch }) => {
        
        // 1. to check the ln eligibility
        // 2. for eligible user, assess-risk service will get call
        // 3. if rememberDevice is true then it will call save-device service

        const eligibilityEndpoint = maasEligibilityEndpoint()
        const eligibilityResponse  = await http.post(
            eligibilityEndpoint, {}
        )

        if(eligibilityResponse.data?.lexisNexisEligibleFlag){

            // if user are LN eligible call /register/assess-risk 
            // this call will create instant-id 
            const assessRiskEndpoint = maasAssessRiskEndpoint()
            const assessRiskResponse  = await http.post(
                assessRiskEndpoint, 
                {
                    deviceSessionId: params.deviceSessionId
                }
            )
            if (assessRiskResponse.error || !assessRiskResponse.data) {
                console.error("Failed to maasRiskAssessment ")
            }else{
                // if user are LN eligible and checked save devide at time of registration call  /register/save-device  
                if(trueFalseStringToBoolean(params.rememberDevice)){
                    const savDeviceEndpoint = maasSavDeviceEndpoint()
                    const savDeviceResponse  = await http.post(
                        savDeviceEndpoint, 
                        { 
                            deviceSessionId: params.deviceSessionId,
                            subscriberId: params.subscriberId ,
                            suffix: params.suffix ,
                            brandFamily: params.brandFamily,
                            deviceId: assessRiskResponse.data?.deviceId ,
                            riskLevel: assessRiskResponse.data?.riskLevel,
                            deviceRiskCode: assessRiskResponse.data?.deviceRiskCodes ,
                            identityRiskCode: assessRiskResponse.data?.identityRiskCodes,
                            lexId: assessRiskResponse.data?.lexId ,
                            message: assessRiskResponse.data?.  message,
                            verifyInd: true ,
                            userDeviceId: 0 ,
                            hooruRequestId: assessRiskResponse.data?.hooruRequestId ,
                            otpChannel: "EMAIL" ,
                            otpChannelValue: params.email ,
                        }
                    )
                    if (savDeviceResponse.error || !savDeviceResponse.data) {
                        console.error("Failed to maasRiskAssessment ")
                    }
                }
            }
        }else{
            console.error("User is not LN eligible ")
        }
    
        if (eligibilityResponse.error || !eligibilityResponse.data) {
            console.error("Failed to maasRiskAssessment ")
        }
    },
)

