import * as React from "react"
import { SVGProps } from "react"
const SvgHospital = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Hospital building"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M13 21C13 20.4477 12.5523 20 12 20C11.4477 20 11 20.4477 11 21H13ZM8 13C7.44772 13 7 13.4477 7 14C7 14.5523 7.44772 15 8 15V13ZM10 15C10.5523 15 11 14.5523 11 14C11 13.4477 10.5523 13 10 13V15ZM8 17C7.44772 17 7 17.4477 7 18C7 18.5523 7.44772 19 8 19V17ZM10 19C10.5523 19 11 18.5523 11 18C11 17.4477 10.5523 17 10 17V19ZM14 13C13.4477 13 13 13.4477 13 14C13 14.5523 13.4477 15 14 15V13ZM16 15C16.5523 15 17 14.5523 17 14C17 13.4477 16.5523 13 16 13V15ZM14 17C13.4477 17 13 17.4477 13 18C13 18.5523 13.4477 19 14 19V17ZM16 19C16.5523 19 17 18.5523 17 18C17 17.4477 16.5523 17 16 17V19ZM3 22C2.44772 22 2 22.4477 2 23C2 23.5523 2.44772 24 3 24V22ZM21 24C21.5523 24 22 23.5523 22 23C22 22.4477 21.5523 22 21 22V24ZM11 7.5C11 8.05228 11.4477 8.5 12 8.5C12.5523 8.5 13 8.05228 13 7.5H11ZM13 4.5C13 3.94772 12.5523 3.5 12 3.5C11.4477 3.5 11 3.94772 11 4.5H13ZM10.5 5C9.94771 5 9.5 5.44772 9.5 6C9.5 6.55229 9.94772 7 10.5 7L10.5 5ZM13.5 7C14.0523 7 14.5 6.55228 14.5 6C14.5 5.44772 14.0523 5 13.5 5V7ZM13 23V21H11V23H13ZM6 23V8H4V23H6ZM18 8V23H20V8H18ZM19 22H12V24H19V22ZM12 22H5V24H12V22ZM20 8C20 6.34315 18.6569 5 17 5V7C17.5523 7 18 7.44772 18 8H20ZM6 8C6 7.44772 6.44772 7 7 7V5C5.34315 5 4 6.34315 4 8H6ZM8 15H10V13H8V15ZM8 19H10V17H8V19ZM14 15H16V13H14V15ZM14 19H16V17H14V19ZM3 24H5V22H3V24ZM19 24H21V22H19V24ZM15.5 6C15.5 7.933 13.933 9.5 12 9.5V11.5C15.0376 11.5 17.5 9.03757 17.5 6H15.5ZM12 9.5C10.067 9.5 8.5 7.933 8.5 6H6.5C6.5 9.03757 8.96243 11.5 12 11.5V9.5ZM8.5 6C8.5 4.067 10.067 2.5 12 2.5V0.5C8.96243 0.5 6.5 2.96243 6.5 6H8.5ZM12 2.5C13.933 2.5 15.5 4.067 15.5 6H17.5C17.5 2.96243 15.0376 0.5 12 0.5V2.5ZM13 7.5V6H11V7.5H13ZM13 6V4.5H11V6H13ZM10.5 7L12 7V5L10.5 5L10.5 7ZM12 7H13.5V5H12V7Z"
            fill="currentColor"
        />
    </svg>
)
export default SvgHospital
