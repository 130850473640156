/**
 * Input
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Icon, IconTypes } from "elements"
import Button, { Props as ButtonProps } from "elements/button"
import { tagEvent } from "@/utils/analytics"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
type InputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "size">
export interface Props extends InputProps {
    readonly name: string

    // State
    readonly value: string
    readonly setValue: (value: string) => void

    // Content
    readonly label?: string
    readonly labelClass?: string
    readonly placeholder?: string
    readonly errorMessage?: string
    readonly tabIndex?: number

    // Layout
    readonly variant?: "default" | "rounded"
    readonly hideLabel?: boolean
    readonly size?: "medium" | "large"
    readonly inverted?: boolean
    readonly customValidation?: boolean
    readonly isValid?: boolean
    readonly icon?: IconTypes
    readonly iconRight?: IconTypes
    readonly button?: ButtonProps

    // Custom Input Styles
    readonly inputStyles?: string

    // Events
    readonly onChange?: (e: React.FormEvent<HTMLInputElement>) => void
    readonly onKeyDown?: (e: React.FormEvent<HTMLInputElement>) => void
    readonly onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void

    //Analytics
    readonly data_analytics_id?: string
    readonly data_analytics_input_flag?: boolean
}

const Input: React.FC<Props> = props => {
    /**
     * Methods
     */
    const [inputAnalyticsFlag, setInputAnalyticsFlag] = React.useState(
        props.data_analytics_input_flag,
    )
    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
        props.setValue(e.currentTarget.value)
        if (props.onChange) props.onChange(e)
        e.stopPropagation()
        if (inputAnalyticsFlag) {
            tagEvent({
                tealium_event: "input",
                data_analytics_id: props.data_analytics_id,
            })
        }
        setInputAnalyticsFlag(false)
    }

    const onKeyDown = (e: React.FormEvent<HTMLInputElement>) => {
        if (props.onKeyDown) {
            props.onKeyDown(e)
            e.stopPropagation()
        }
    }

    /**
     * Define styles
     */
    const baseClasses =
        "appearance-none w-full bg-transparent focus:outline-none subpixel-antialiased disabled:cursor-not-allowed"
    const placeholderClasses = props.inverted
        ? "text-white placeholder-white placeholder-opacity-60 focus:placeholder-opacity-30"
        : "placeholder-gray-200 placeholder-opacity-60 focus:placeholder-opacity-30 dark:placeholder-white dark:placeholder-opacity-60 dark:focus:placeholder-opacity-30"
    //eslint-disable-next-line
    let borderClasses = props.inverted
        ? props.errorMessage
            ? "border-error-200 focus:border-error-200"
            : "border-light-50 border-opacity-30 focus:border-accent focus:border-opacity-100"
        : props.errorMessage
            ? "border-error-200 focus:border-error-200"
            : "border-light-200 focus:border-primary-vivid dark:border-dark-50 dark:focus:border-accent"

    {
        props.customValidation &&
            (borderClasses = props.isValid
                ? "border-success-200 border-opacity-100 focus:border-success-200 focus:border-opacity-100"
                : "border-error-200 focus:border-error-200 dark:border-dark-50 dark:focus:border-accent")
    }
    // prettier-ignore
    const variantClasses =
        props.variant === "rounded"
            ? props.size === "large"
                ? "border rounded-sm px-sm h-xl"
                : "border rounded-sm px-xs h-lg"
            : props.size === "large"
                ? "border-b py-xxs text-lg"
                : "border-b py-xxs"
    const leftPadding = props.icon ? "pl-xl" : ""
    const rightPadding = props.button || props.errorMessage ? "pr-xxl" : ""
    const classNames = `${baseClasses} ${placeholderClasses} ${borderClasses} ${variantClasses} ${leftPadding} ${rightPadding} ${props.inputStyles}`
    const hideLabel = props.hideLabel ? "hidden" : ""

    /**
     * Template
     */
    return (
        <div className={props.className}>
            {/* Label */}
            {props.label && (
                <label
                    htmlFor={props.name}
                    className={`${props.labelClass} ${hideLabel} ${props.inverted
                        ? "text-white"
                        : "dark:text-gray-200"
                        }`}
                    tabIndex={0}
                >
                    {props.label}
                </label>
            )}
            <div className="relative">
                {/* Icon */}

                {props.icon && (
                    <div className="absolute top-none left-xxs h-full flex items-center">
                        <Icon type={props.icon} size={24} />
                    </div>
                )}
                {/* Icon Right */}
                {props.iconRight && (
                    <div className="absolute top-none right-xs h-full flex items-center">
                        <Icon type={props.iconRight} size={24} />
                    </div>
                )}
                {/* Input */}
                <input
                    id={props.name}
                    name={props.name}
                    tabIndex={props.tabIndex || 0}
                    data-test={props.name}
                    value={props.value}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    placeholder={props.placeholder || props.label}
                    type={props.type}
                    spellCheck={props.spellCheck || false}
                    autoCorrect={props.autoCorrect || "off"}
                    autoCapitalize={props.autoCapitalize || "off"}
                    autoComplete={props.autoComplete || "off"}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    onClick={props.onClick}
                    onPaste={props.onPaste}
                    disabled={props.disabled}
                    className={classNames}
                    minLength={props.minLength}
                    maxLength={props.maxLength}
                />
                {/* Button */}
                {props.button && !props.errorMessage && (
                    <div
                        className={`absolute top-none h-full flex items-center ${props.size === "large" ? "right-xs" : "right-xxs"
                            }`}
                    >
                        <Button
                            {...props.button}
                            variant={
                                props.button.variant
                                    ? props.button.variant
                                    : "link"
                            }
                            inverted={props.inverted}
                        />
                    </div>
                )}
                {/* Error icon */}
                {props.errorMessage && (
                    <div
                        className={`absolute top-none h-full flex items-center ${props.size === "large" ? "right-xs" : "right-xxs"
                            }`}
                    >
                        <Icon
                            type="warning"
                            size={24}
                            className="text-error-200"
                        />
                    </div>
                )}
            </div>
            {/* Error message */}
            {props.errorMessage && (
                <div className="mt-xxxs">
                    <small className="text-error" tabIndex={0}>{props.errorMessage}</small>
                </div>
            )}
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Input
