/**
 * Date validation services reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface DateValidation {
    readonly dateValidation: {
          readonly isInputValid: boolean,
          readonly validationStatusMessage: string
    }
}
/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<DateValidation> = {
    status: "pristine",
    dateValidation: {
        isInputValid: true,
        validationStatusMessage: ""
    },
}
/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const dateValidation = createStatefulSlice({
    name: "dateValidationService", 
    initialState,
    reducers: {
        receiveDateValidationService: (
            state: SliceState<DateValidation>,
            action: PayloadAction<DateValidation>,
        ) => {
            return {
                status: "completed",
                dateValidation: action.payload.dateValidation || { 
                    isInputValid: true,
                    validationStatusMessage: ""
                },
            }
        },
        resetDateValidationService: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const dateValidationServiceActions = { ...dateValidation.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default dateValidation.reducer
