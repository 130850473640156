/**
 * Paperless Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import { http } from "utils"
import { paperlessQuery } from "./queries"
import { format as formatDate, sub, } from "date-fns"

const dateFormat = "MM-dd-yyyy"
const toDate = formatDate(new Date(), dateFormat).toString()
//Load max 2 Years letters and notices
const fromDate = formatDate(sub(new Date(), { years: 2 }), dateFormat).toString()
/**
 * Get Account Settings
 * -----------------------------------------------------------------------------
 */
export const fetchPaperless = createAsyncThunk(
    "paperless/fetchPaperless", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.paperlessStatus("pending"))

        /**
         * Request paperless data
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            paperlessQuery(fromDate, toDate ),
        )

        if (res.error) {
            return dispatch(
                actions.paperlessError({
                    response: res,
                }),
            )
        }

        /**
         * Paperless data
         */
        const data = res.data?.data || {}
        const machineLetters = data?.fetchableDocuments || []

        /**
         * Update state
         */
        dispatch(
            actions.receivePaperless({
                ...store.getState().paperless,
                machinedLetters: machineLetters,
                status: "completed",
            }),
        )
    },
)
