/**
 * TaxForm reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { TaxForm } from "./types"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface TaxForms {
    readonly taxFormsInformation: ReadonlyArray<TaxForm>
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<TaxForms> = {
    status: "pristine",
    taxFormsInformation: [],
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const taxFormsInformation = createStatefulSlice({
    name: "taxFormsInformation",
    initialState,
    reducers: {
        receiveTaxForms: (
            state: SliceState<TaxForms>,
            action: PayloadAction<TaxForms>,
        ) => {
            return {
                status: "completed",
                taxFormsInformation: action.payload.taxFormsInformation || [],
            }
        },
        resetTaxForms: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const taxFormsActions = { ...taxFormsInformation.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default taxFormsInformation.reducer
