/**
 * Resources Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions } from "store"
import { http } from "utils"

/**
 * Get resources data
 * -----------------------------------------------------------------------------
 */
export const fetchResources = createAsyncThunk(
    "resources/fetchResources", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.resourcesStatus("pending"))

        /**
         * Fetch resources
         */
        const config = { headers: { platform: "web" } }
        const res = await http.get("/api/v3/programs", config)

        /**
         * Handle request errors
         */
        if (res.error) {
            return dispatch(actions.resourcesError({ response: res }))
        }

        /**
         * Define resources data
         */
        const programs = res.data || {}

        /**
         * Update state
         */
        dispatch(actions.receiveResources({ ...programs }))
    },
)
