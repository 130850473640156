/**
 * Query to fetch account settings
 * @returns
 */

export const myAccountSettingsQuery = `query {
    myAccount {
      profile {
        firstName
        lastName
        birthDate
      }
      accountInformation {
        username
        email
        isEmailActivated
        phone {
          activated
          number
        }
        homeAddress {
          address1
          address2
          city
          state
          zip
        }
        mailingAddress {
          address1
          address2
          city
          state
          zip
        }
        billingAddress {
          address1
          address2
          city
          state
          zip
        }
      }
      preferences {
        paperless {
          eob {
            programId
            permissions
            setting
          }
          medical {
            programId
            permissions
            setting
          }
          prime {
            programId
            permissions
            setting
          }
          anoc {
            programId
            permissions
            setting
          }
        }
        consumerSettings {
          marketing {
            programId
            permissions
            setting
          }
          wellness {
            programId
            permissions
            setting
          }
          sso {
            programId
            permissions
            setting
          }
          providerAccessAuth {
            programId
            permissions
            setting
          }
        } 
        communicationPreferences {
          claimsAlert {
            email {
              programId
              permissions
              setting
            }
            sms {
              programId
              permissions
              setting
            }
          }
          newsUpdates {
            email {
              programId
              permissions
              setting
            }
            sms {
              programId
              permissions
              setting
            }
          }
          feedback {
            email {
              programId
              permissions
              setting
            }
            sms {
              programId
              permissions
              setting
            }
          }
        }
        hsaPreferences {
          balance {
            programId
            permissions
            setting
          }
          shareClaims {
            programId
            permissions
            setting
          }
          shareHealthInfo {
            programId
            permissions
            setting
          }
        }
      }
      familySharing {
        show
        familyShareInfo {
          authorizedDate
          authorizedTime
          authorized
          firstName
          lastName
          expiryDate
          age
          suffix
        }
      } 
    }
}`


export const fetchLetterNoticesQuery = (
    fromDate: string,
    toDate: string,
) => `query {
  fetchableDocuments(fromDate: "${fromDate}", toDate: "${toDate}", type: letter) {
    id
    date
    name
    desc
    url
  }
}`