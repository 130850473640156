export const medicationsQuery = `
query {
  careSubjects {
      familyMembers {
        membershipId
        firstName
        lastName
        birthDate
        currentAge
        relationship
        disclosureAllowed
        suffix
      }
      edges {
          node {
              careSubjectId
              membershipId
              name { 
                given 
                family 
              }
              medicineCabinet {
                  ...edgeCollectionInfo
                  edges {
                      ...medicationEdge
                  }
              }
              events {
                  ...edgeCollectionInfo
                  edges {
                      nodeType
                      sortDate
                      node {
                          ...on MedicationEvent {
                              medicationId
                              supply { unit value }
                              supplyText
                              medication {
                                  ...on Medication {
                                      id
                                      type
                                      displayName
                                      brandStatus
                                      dosage {
                                          description
                                          route
                                          routeCategory
                                      }
                                      lastFillDate
                                      pharmacyName
                                      pharmacyPhone
                                      pharmacyAddress { street city state zip }
                                      prescriberName
                                  }
                                  ...on ClaimsMedication {
                                      medicationNdc
                                      source
                                      pharmacyAddress { street city state zip }
                                      fillCost
                                      claimId
                                  }
                                  ...on CabinetMedication {
                                      medicationNdcs
                                      medicationTreatmentId
                                      source
                                      fillCost
                                      status
                                  }
                              }
                          }
                      }
                  }
              }

          }
      }
  }
}

fragment edgeCollectionInfo on EdgeCollection {
  totalCount
  pageInfo { hasNextPage startCursor endCursor }
}

fragment medicationEdge on MedicationEdge {
  nodeType
  sortDate
  node {
      ... on Medication {
          id
          medicationId
          type
          careSubjectId
          brandStatus
          displayName
          supply { unit value }
          dosage { route description routeCategory }
          lastFillDate
          pharmacyName
          pharmacyPhone
          pharmacyAddress { street city state zip }
          prescriberName
      }
      ... on ClaimsMedication {
          medicationId
          medicationNdc
          source
          fillCost
          claimId
      }
      ... on CabinetMedication {
          medicationTreatmentId
          medicationNdcs
          source
          fillCost
          status
      }
  }
}
`

export const rxTreatmentsByIdQuery = function (ids: ReadonlyArray<string>) {
    const queryItems = []

    ids.forEach((id, index) => {
        const item = `
        treatment_${index}: rxTreatment(id: ${id}) {
          id
          primaryName
          secondaryName
          cost
          commonSideEffectList
          severeSideEffectList
          rxConditionTreatments {
            edges {
              node {
                id
                grade
                clinicalSummary
                effectiveness
                safety
                rxCondition {
                  id
                  name
                  fullName
                  rxConditionTreatments(first: 3){
                    edges{
                      node{
                        id
                        grade
                        rxTreatment{
                          id
                          primaryName
                          secondaryName
                          cost
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `
        queryItems.push(item)
    })

    const queryBody = queryItems.join()

    return `
    query rxTreatment {
      ${queryBody}
    }
  `
}

const userMetaFragment = `
    fragment userMetaInfo on HasUserMeta {
        meta {
        creationDate
        creatorUserIdentityId
        creatorName { given family }
        isCreator
        canEdit
        updateDate
        canDelete
        ownerIdentityIds
        isOwner
        }
    }`

export const medicationStatusMutation = `
    mutation SetMedicationStatus($input: SetMedicationStatusPayload!) {
        setMedicationStatus(input: $input) {
            notepadId
            value
            ...userMetaInfo
        }
    } ${userMetaFragment}`
