/**
 * Timeline reducer
 * -----------------------------------------------------------------------------
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { TimelineItem } from "./types"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Timeline {
    // Base props
    readonly isInitialized: boolean
    readonly isLoading: boolean
    readonly errorMessage?: string
    readonly family: {
        readonly items: ReadonlyArray<{
            readonly membershipId?: string,
            readonly firstName?: string,
            readonly lastName?: string,
            readonly birthDate?: string,
            readonly currentAge?: string,
            readonly relationship?: string,
            readonly disclosureAllowed?: string,
            readonly suffix?: string,
          }>
    }

    // Resource props
    readonly items: { readonly [key: string]: TimelineItem }
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: Timeline = {
    isInitialized: false,
    isLoading: false,
    errorMessage: undefined,
    items: {},
    family: { items: [] },
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const timeline = createSlice({
    name: "timeline",
    initialState,
    reducers: {
        receiveTimeline: (state: Timeline, action: PayloadAction<Timeline>) => {
            return action.payload
        },
        resetTimeline: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const timelineActions = { ...timeline.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default timeline.reducer
