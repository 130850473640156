/**
 * Session Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import http from "utils/http"
import Router from "next/router"
import { initAnalyticsData } from "utils/analytics"
import {cleanupLocalStorage, cleanupSessionStorage} from "@/utils/window-storage";
import { signoutHandler } from "@/utils/sign-out"

/**
 * Authenticate
 * -----------------------------------------------------------------------------
 */
export interface AuthenticateParams {
    readonly username: string
    readonly password: string
    readonly remember?: boolean
    readonly useCie?: boolean
}

export const authenticate = createAsyncThunk(
    "session/authenticate", // Reducer name
    async (params: AuthenticateParams, { dispatch }) => {
        /**
         * Set loading state
         */
        const { app, session } = store.getState()
        dispatch(
            actions.receiveSession({
                ...session,
                isLoading: true,
                errorMessage: "",
            }),
        )
        
        /**
         * Authenticate user
         */
        const { error } = await http.post(`/api/auth/cie`, {
            username: params.username,
            password: params.password,
            remember: params.remember,
            brand: app.brand,
            deviceSessionId: crypto.randomUUID()
        })
        if (error) {
            dispatch(
                actions.receiveSession({
                    ...session,
                    isLoading: false,
                    errorMessage: error.message,
                }),
            )
            return
        }
        // ensure window storage is clear for new login
        cleanupSessionStorage()
        cleanupLocalStorage()

        /**
         * Set local storage to remember username
         */
        if (params.remember) {
            localStorage.setItem("username", params.username)
        }

        /**
         * Request important "first fetch" data
         */
        await dispatch(actions.getAccount())
        await dispatch(actions.fetchMemberSettings())

        /**
         * Reinitialize app now that we're authenticated.
         * Updates authentication status on app state.
         */
        dispatch(actions.initialize())

        /**
         * Reset loading state
         */
        dispatch(
            actions.receiveSession({
                ...session,
                isLoading: false,
                errorMessage: "",
            }),
        )

        /**
         * Initialize analytics
         */
        initAnalyticsData(true)

        /**
         * Define redirect path
         */
        const query = new URLSearchParams(window.location.search)
        // eslint-disable-next-line functional/no-let
        let redirectHref = ""
        query.forEach((value, key) => {
            key === "redirect" ? redirectHref += value : redirectHref += "&" + key + "=" + value
        })
        const redirect = query.has("redirect") ? redirectHref : ""

        /**
         * Redirect to home (or `redirect` param)
         *
         * Note: Something about Next v12 doesn't like `Router`
         */
        window.location.href = redirect || "/" // eslint-disable-line
        // Router.replace(redirect || "/")
    },
)

/**
 * Deauthenticate
 * -----------------------------------------------------------------------------
 *
 * Currently unused as the app redirects to ADIF for signout.
 */
export const deauthenticate = createAsyncThunk(
    "session/deauthenticate", // Reducer name
    async (params: { readonly errorMessage?: string }, { dispatch }) => {
        /**
         * Set loading state
         */
        const { session } = store.getState()
        dispatch(actions.receiveSession({ ...session, isLoading: true }))

        /**
         * Request signout
         */
        await http.post("/api/auth/signout", {})

        /**
         * Reset application state
         */
        dispatch(actions.resetApp())
        dispatch(actions.resetSession())

        /**
         * Initialize application
         */
        dispatch(actions.initialize())

        /**
         * Display message on sign in page
         */
        if (params?.errorMessage) {
            const errorMessage = params.errorMessage
            dispatch(actions.receiveSession({ ...session, errorMessage }))
        }

        /**
         * Redirect
         */
        Router.replace("/redirect-signin")
    },
)

/**
 * Refresh session
 * -----------------------------------------------------------------------------
 */
export const refreshSession = createAsyncThunk(
    "session/refresh", // Reducer name
    async (params: void, _) => {
        /**
         * Should we set loading state? This happens in the background.
         */

        /**
         * Request updated cookies.
         * Also get updated ADIF sessionId for use in iframes.
         */
        try {
            const { adifSessionId } = await putSession()
            refreshAdifFrames({ adifSessionId })
        } catch (_) {
            signoutHandler()
            return
        }
    },
)

const putSession = async (): Promise<{ readonly adifSessionId: string }> => {
    try {
        const resp = await http.put("/api/session", {})

        if (resp.error || typeof resp?.data?.adifSessionId !== "string") {
            /**
             * Retry once on error
             */
            const retriedResp = await http.put("/api/session", {})

            // failure
            if (
                retriedResp.error ||
                typeof retriedResp?.data?.adifSessionId !== "string"
            ) {
                throw new Error("Session refresh failed")
            }

            // success
            return { adifSessionId: retriedResp?.data?.adifSessionId }
        }

        // success
        return { adifSessionId: resp?.data?.adifSessionId }
    } catch (_) {
        throw new Error("Session refresh failed")
    }
}

const refreshAdifFrames = ({ adifSessionId }) => {
    try {
        const iframe = document.getElementById(
            "iframeElement",
        ) as HTMLIFrameElement

        if (iframe && iframe?.contentWindow?.window !== null) {
            const outdatedSessionID = iframe.contentWindow.window["sessionID"]

            /**
             * Reset session global, then dispatch event so ADIF Hecate sources get reloaded.
             */
            if (typeof outdatedSessionID === "string") {
                // eslint-disable-next-line functional/immutable-data
                iframe.contentWindow.window["sessionID"] = adifSessionId
                document.dispatchEvent(new Event("adif.session_refresh"))
            }
        }
    } catch (_error) {
        // todo: log error with NewRelic
        throw new Error("Session refresh failed")
    }
}
