import { featureToggleConfig } from "@/utils/features"

/**
 * GraphQL query to fetch app model flags only for feature toggles configured in DFW
 */
export const adifAppModelQuery = () => {
    const entries = Object.entries(featureToggleConfig)

    const queryBuilder = entries?.reduce(
        (queryBuilder, [feature, appModelfeatureToggle]) => {
            /**
             * Prepare individual featue toggle query item of below form
             * dfwFeatureToggle: parameter(name: "appModelfeatureToggle") {
                box {
                  ... on ParameterBool { value }
                }
              }
             */
            const featureQuery = `
            ${feature}: parameter(name: "${appModelfeatureToggle}") {
                box {
                  ... on ParameterBool { value }
                }
              }
            `
            return [...queryBuilder, featureQuery]
        },
        [],
    )

    /**
     * Prepare final query
     */
    return `
query {
    application(name: "cambia.web") {
        ${queryBuilder.join(" ")}
    }
  }
`
}

/**
 * Query to fetch region and brand from MPS
 */
export const fetchBrandAndRegionQuery = `
query {
    primaryPolicy {
    requesterMembership {
      primaryCoverage {
        brandFamily
        primaryEligibility {
          region
          brandCode
        }
      }
    }
  }
}
`