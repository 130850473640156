/**
 * Query to fetch message center data
 *
 */
export const fetchBadgeCount = `query{
  messageCenter{
    badgeCount
    }
}`

export const fetchInboxMailsQuery = `query{
  messageCenter{
      inboxMessages{
        edges{
            
            node{
                recipients{
                    mailboxId
                    mailboxDescription
                    messageRead
                }
                messageId
                messageRead
                
                 bodyPreview
                sentDateTime
                subject
                resourcePath
                
            }
        }
      }
  }
}`

export const fetchSentMailsQuery = `query{
  messageCenter{        
    sentMessages{      
      edges{
        node{
          bodyPreview
          createDateTime
          messageId
          messageRead
          readDateTime
          sentDateTime
          subject
        }
      }
    }
  }
}`

export const fetchMessageDetailsQuery = `
query MessageDetails($messageId: String!) 
{
  messageCenter {
    messageDetails (messageId: $messageId) {
      body
      createDateTime
      messageId
      originalMessageId
      readDateTime
      recipients {
        mailboxCode
        mailboxDescription
        mailboxId
      }
      sender {
        mailboxCode
        mailboxDescription
        mailboxId
      }
      sentDateTime
      subject
      previousMessages{ 
          body
          createDateTime
      messageId
      originalMessage
      readDateTime
      recipients {
        mailboxCode
        mailboxDescription
        mailboxId
      }
      sender {
        mailboxCode
        mailboxDescription
        mailboxId
      }
      sentDateTime
      subject}
    }
  }
}`

export const fetchMailSubjectQuery = `query{
  messageCenter{
    messageTopics{
      mailboxCode
      mailSubjectName
      subjectText
    }
  }
}`

export const sendNewMessageMutation = `mutation CreateMessage($mailboxCode: String!, $subject: String!, $body: String!, $originalMessageId: String) {
  createMessage(mailboxCode: $mailboxCode, subject: $subject, body: $body, originalMessageId: $originalMessageId) {
    resultStatus
    resultDescription
  }
}`

export const deleteMessagesQuery = `mutation DeleteMessages($messageIds: [String!]) {
  deleteMessages(messageIds: $messageIds) {
    resultStatus
    resultDescription
  }
}`

export const deleteMessageQuery = `mutation DeleteMessage($messageId: String!) {
  deleteMessage(messageId: $messageId) {
    resultStatus
    resultDescription
  }
}`

export const updateMessageReadStatusMutation = `mutation markMessageRead($messageId: String!) {
   markMessageRead(messageId: $messageId) {
    resultStatus
    resultDescription
   }
  }`
