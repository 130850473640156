/**
 * Datepicker
 * -----------------------------------------------------------------------------
 * Built on: http://react-day-picker.js.org/
 *
 * Known bugs:
 * [ ] First available month is disabled in some states
 */
import React from "react"
import Input, { Props as InputProps } from "elements/input"
import { Card } from "elements"
import { useKeyPress } from "utils"
import {
    parse,
    format as formatDate,
    isBefore,
    isAfter,
    isValid,
    add,
    sub,
    startOfYear,
    endOfYear,
} from "date-fns"
import { DayPicker, DayPickerProps } from "react-day-picker"
import "react-day-picker/dist/style.css"
import { tagEvent } from "@/utils/analytics"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props extends InputProps {
    /**
     * Date range – disable before and after
     */
    readonly fromDate?: Date // default: 100 years ago
    readonly toDate?: Date // default: end of next year
    readonly onClick?: VoidFunction
    readonly dataAnalytics?: Record<string, string>
    readonly tabIndex?: number
    readonly placeholder?: string
    readonly customErrorMessage?: string //Prop to add a custom error message
    /**
     * Alignment
     */
    readonly align?:
        | "left"
        | "right"
        | "topleft"
        | "topright"
        | "center"
        | "topcenter"
        
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Datepicker: React.FC<Props> = props => {
    /**
     * Hooks
     */
    useKeyPress("Escape", () => setVisible(false))

    /**
     * Component state
     */
    const [visible, setVisible] = React.useState(false)
    const [day, setDay] = React.useState<Date>(undefined)
    const [month, setMonth] = React.useState<Date>(new Date())
    const [error, setError] = React.useState("")
    const [rangeError, setRangeError] = React.useState("")

    /**
     * Refs
     */
    const ref = React.useRef<HTMLDivElement>(null)

    /**
     * Methods
     */
    const onSelect: DayPickerProps["onDayClick"] = (day, modifiers) => {
        if (!modifiers["DayPicker-Day--disabled"]) {
            // Update datepicker private state
            setDay(day)
            setMonth(day)

            // Hide datepicker calendar
            setVisible(false)

            // Format date and update value
            const date = formatDate(day, dateFormat)
            props.setValue(date)

            // Data analytics tagging for selecting date in the picker and noting a range if there are multiple date pickers like in timeline
            if (props.dataAnalytics) {
                // eslint-disable-next-line functional/no-let
                let dataAnalyticsId = ""
                if (props.dataAnalytics.startRange) {
                    dataAnalyticsId =
                        props.dataAnalytics.name +
                        props.dataAnalytics.startRange +
                        " - " +
                        date
                } else if (props.dataAnalytics.endRange) {
                    dataAnalyticsId =
                        props.dataAnalytics.name +
                        date +
                        " - " +
                        props.dataAnalytics.endRange
                } else {
                    dataAnalyticsId = props.dataAnalytics.name + date
                }

                tagEvent({
                    tealium_event: "link",
                    data_analytics_id: dataAnalyticsId,
                    link_text: "datepicker",
                    link_url: "",
                })
            }

            // Clear other date filters
            if (props.onClick) props.onClick()

            // Clear error message
            setError("")
            setRangeError("")
        }
        //focusing on calender icon. In responsive view focus was resetting at start of filter model.
        document.getElementsByName(props.name + "-button")[0]?.focus()
    }
    const onMouseDown = (event: MouseEvent) => {
        if (!ref.current?.contains(event.target as Node)) {
            setVisible(false)
        }
    }
    const onKeyDown = (event: KeyboardEvent) => {
        if (!ref.current?.contains(event.target as Node)) {
            setVisible(false)
        }
    }

    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
        // Clear error messages
        setError("")
        setRangeError("")
        // Auto-format "MM/DD/YYY" while typing
        const value = event.currentTarget?.value || ""
        if (value && value.match(/^\d{2}$/) !== null) {
            props.setValue(value + "/")
        } else if (value && value.match(/^\d{2}\/\d{2}$/) !== null) {
            props.setValue(value + "/")
        }
        // Parse date and update
        if (value.length >= 10) {
            try {
                if (!isValid(new Date(value))) {
                    throw "Invalid date"
                }
                const newDay = parse(value, dateFormat, new Date())
                if (isBefore(newDay, fromDate)) {
                    setRangeError(value)
                    setDay(fromDate)
                    setMonth(fromDate)
                    props.setValue(formatDate(fromDate, dateFormat))
                } else if (isAfter(newDay, toDate)) {
                    setRangeError(value)
                    setDay(toDate)
                    setMonth(toDate)
                    props.setValue(formatDate(toDate, dateFormat))
                } else {
                    setDay(newDay)
                    setMonth(newDay)
                }
            } catch (e) {
                setError("Invalid date format")
            }
        }

        
        // Pass change event to parent
        if (props.onChange) {
            props.onChange(event)
        }
    }

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        // Define initial calendar day
        if (props.value) {
            try {
                setDay(parse(props.value, dateFormat, new Date()))
            } catch (e) {
                setDay(new Date())
            }
            setMonth(new Date(props.value || undefined))
        }

        // Dismiss calendar listeners
        window.addEventListener("mousedown", onMouseDown)
        window.addEventListener("keydown", onKeyDown)
        return () => {
            window.removeEventListener("mousedown", onMouseDown)
            window.removeEventListener("keydown", onKeyDown)
        }
    }, [])

    /**
     * Define template variables
     */
    const now = new Date()
    const {
        className,
        fromDate = startOfYear(sub(now, { years: 100 })),
        toDate = endOfYear(add(now, { years: 1 })),
        align,
        ...inputProps
    } = props
    const disabledDays = [{ after: toDate, before: fromDate }]
    const dateFormat = "MM/dd/yyyy" // https://date-fns.org/v2.23.0/docs/format

    /**
     * Styles
     * https://github.com/gpbl/react-day-picker/blob/v7/src/classNames.js
     */
    const styles = {
        container: "DayPicker",
        wrapper: "DayPicker-wrapper",
        interactionDisabled: "DayPicker--interactionDisabled",
        months: "DayPicker-Months",
        month: "DayPicker-Month",
        navBar: "DayPicker-NavBar",
        navButtonPrev: "DayPicker-NavButton DayPicker-NavButton--prev",
        navButtonNext: "DayPicker-NavButton DayPicker-NavButton--next",
        navButtonInteractionDisabled:
            "DayPicker-NavButton--interactionDisabled",
        caption: "DayPicker-Caption",
        weekdays: "DayPicker-Weekdays",
        weekdaysRow: "DayPicker-WeekdaysRow",
        weekday: "DayPicker-Weekday",
        body: "DayPicker-Body",
        week: "DayPicker-Week",
        weekNumber: "DayPicker-WeekNumber",
        day: "DayPicker-Day rounded-sm hover:bg-light-50 dark:hover:bg-dark-100",
        footer: "DayPicker-Footer",
        todayButton: "DayPicker-TodayButton",
        today: "font-bold",
        selected: "DayPicker-Day--selected bg-primary hover:bg-primary",
        disabled: "DayPicker-Day--disabled",
        outside: "DayPicker-Day--outside",
    }
    const positioning = {
        left: "left-none mt-xxxs",
        right: "-right-xxs ml-sm mt-xxxs",
        center: "center-xxs mt-xxxs",
        topleft: "left-none bottom-xl",
        topright: "right-none bottom-xl",
        topcenter: "center-none mr-sm mb-xxxl bottom-xxxl",
    }

    /**
     * Template
     */
    return (
        <div ref={ref} className={`static ${className}`}>
            <Input
                {...inputProps}
                tabIndex={props.tabIndex || 0}
                button={{
                    className: "",
                    size: "medium",
                    name: props.name + "-button",
                    icon: "calendar",
                    onClick: () => setVisible(!visible),
                    tabIndex: -1,
                }}
                variant="rounded"
                onFocus={() => setVisible(true)}
                onChange={onChange}
                maxLength={10}
                errorMessage={error || props.customErrorMessage}
                placeholder={props.placeholder || "Search"}
            />
            <Card
                name={props.name + "-dropdown"}
                className={`absolute px-xxs py-xxxs z-popup ${
                    positioning[align || "left"]
                } ${visible ? "" : "hidden"}`}
                disablePadding={true}
            >
                <DayPicker
                    mode="single"
                    captionLayout="dropdown"
                    month={month}
                    fromDate={fromDate}
                    toDate={toDate}
                    disabled={disabledDays}
                    selected={day}
                    onDayClick={onSelect}
                    onMonthChange={setMonth}
                    classNames={styles}
                    modifiersClassNames={{
                        selected:
                            "rounded-sm py-xxxs px-xxs bg-primary text-white hover:bg-primary",
                    }}
                    /* In-line styles */
                    styles={{
                        caption_label: {
                            fontSize: "20px",
                            fontWeight: "normal",
                        },
                        dropdown_icon: { marginLeft: "20px" },
                        head: { color: "#767677" },
                        head_row: { border: "none" },
                        head_cell: { fontWeight: "normal" },
                        row: { border: "none" },
                    }}
                />
            </Card>
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Datepicker
