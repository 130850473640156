import { PremiumPayInfoItem } from "./types"
import { parseDate } from "utils/date"
import { isBefore, addDays, subDays } from "date-fns"

export const transformPremiumPayApiData = (
    data: any, // eslint-disable-line
    isMedicare: boolean,
): PremiumPayInfoItem => {
    return {
        isMedicare,
        dueDate: data[0]?.dueDate,
        pastDueAmount: data[0]?.pastDueAmount || 0,
        totalAmountDue: data[0]?.totalAmountDue || 0,
        eftFlag: data[0]?.eftFlag || false,
    }
}

export const isPaymentDue = ({dueDate}: PremiumPayInfoItem): boolean => {
    // is today the due date or past the due date
    return isBefore(subDays(parseDate(dueDate), 1), new Date())
}

export const isPaymentPastDue = ({isMedicare, dueDate}: PremiumPayInfoItem): boolean => {
    if (isMedicare) { 
        /*** Medicare: 15 days past the due date
         * duedate + 14 days = 15 days
         * (e.g. if dueDate is Jan 1st, then return true on the Jan 16th) */ 
        return isBefore(addDays(parseDate(dueDate), 14), new Date())
    }
    /***
     *   For Indy users: 10 days past the due date
     *  duedate + 9 days = 10 days
     *  (e.g. if dueDate is Jan 1st, then return true on the Jan 11th) */
    return isBefore(addDays(parseDate(dueDate), 9), new Date())
}

export const isBillableAmount = (amount: number) => {
    return amount > 0
}
