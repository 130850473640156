/**
 * Mobile Utilities
 * -----------------------------------------------------------------------------
 */

export const USER_AGENT_REGENCEAPP = "regenceapp"

export const isMobileAppRequest = () => {
    return navigator.userAgent.toLowerCase().includes(USER_AGENT_REGENCEAPP)
}

/**
 * Removing target attribute from anchor tags for requests from mobile app
 * See [here](https://github.com/react-native-webview/react-native-webview/issues/1855) &
 * [here](https://cambiahealth.atlassian.net/browse/DFM-913)
 * for more info
 */
export const removeTargetAttrFromAnchors = () => {
    const anchorTags = document.getElementsByTagName("a")
    if (anchorTags && anchorTags.length) {
        const anchors = Array.from(anchorTags)
        anchors.forEach(anchor => {
            if (anchor.target === "_blank") {
                anchor.removeAttribute("target")
            }
        })
    }
}
