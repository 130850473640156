/* eslint-disable no-console */
/**
 * EOB Statements Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions } from "store"
import http from "utils/http"
import { fetchDocumentQuery } from "./queries"
import { transformEobStatements } from "./utils"
import { transformEobFaq } from "./types"
import { FRAGMENT_IDS } from "../../../src/utils/styx"

/**
 * Get EOB Statements data
 * -----------------------------------------------------------------------------
 */
interface Params {
    readonly startDate: string
    readonly endDate: string
}
export const fetchEobStatements = createAsyncThunk(
    "eobs/fetchEobStatements", // Reducer name
    async (params: Params, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.eobStatementStatus("pending"))
        const fromDate = params?.startDate
        const toDate = params?.endDate
        /**
         * Request EOB Benefits data
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            fetchDocumentQuery(toDate, fromDate),
        )

        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.eobStatementError({
                    response: res,
                }),
            )
        }

        const statements = res.data?.data?.fetchableEobDocuments
        const partialContent =
            res.data?.data?.fetchableEobDocuments?.partialContent
        const eob = transformEobStatements(statements)
        /**
         * Update state
         */
        dispatch(
            actions.receiveEobStatements({
                eobStatement: eob || [],
                eobPartialContent: partialContent,
            }),
        )
    },
)

export const fetchEobFaq = createAsyncThunk(
    "eobFaq/fetchEobFaq", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */

        dispatch(actions.eobStatementStatus("pending"))
        /**
         * Request eob faq data
         */
        const fragmentId = FRAGMENT_IDS.EOB_FAQ_FRAGMENT
        const config = { params: { id: fragmentId } }
        const res = await http
            .get("/api/managed-content", config)
            .then(({ data }) => {
                if (data && data.length > 0) {
                    return data[0]
                }
            })
        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.eobFaqError({
                    response: res,
                }),
            )
        }

        const eobFaqs = transformEobFaq(res?.data)

        /**
         * Update state
         */
        dispatch(
            actions.receiveEobFaq({
                eobFaqs: { eobFaq: eobFaqs } || [],
            }),
        )
    },
)
