import { CareSubjectConnection } from "store/dfis.d"
import { TimelineItem, UnifiedTimelineItem } from "./types"
import { formatCase } from "utils/string"
import { Props as AvatarProps } from "elements/avatar"

/**
 * Transform digital-first-information-service response for Redux
 * -----------------------------------------------------------------------------
 */
export const transformTimelineAPIData = (
    careSubjects: CareSubjectConnection,
) => {
    return careSubjects.edges.reduce((acc, item) => {
        const events = item?.node.events.edges || []
        events.forEach(event => {
            if (event) {
                if (item.node.membershipId) {
                    // eslint-disable-next-line functional/immutable-data
                    event.node.membershipId = item.node.membershipId
                }
                acc[event.node.id] = event.node // eslint-disable-line
            }
        })
        return acc
    }, {})
}

/**
 * Dummy Timeline item (helpful for loading states)
 * -----------------------------------------------------------------------------
 */
export const dummyTimelineItem: TimelineItem = {
    // Dummy timeline item while loading
    type: "UserEvent",
    title: "Loading timeline...",
    details: "Loading description...",
    eventDate: "2020-01-01",
    id: "",
    careSubjectId: "",
    notepadId: "",
    meta: {
        canEdit: false,
        canDelete: false,
        creatorName: { given: "", family: "" },
    },
    membershipId: "",
}

/**
 * Unify Timeline Item Data (for timeline cards)
 * -----------------------------------------------------------------------------
 * TODO: MedicationEvent - description
 * TODO: PractitionerEvent – description, service labels, badges
 * TODO: UserImmunization – description
 * TODO: UserAllergy – description
 */
export const unifyTimelineItem = (item: TimelineItem, phone?: string, addressLine1?: string, addressLine2?: string): UnifiedTimelineItem => {
    const shared = {
        id: item.id,
        type: item.type || "UserEvent",
        title: "",
        date: item.eventDate || "",
        notepadId: item.notepadId,
        canEdit: item.meta?.canEdit || false,
        canDelete: item.meta?.canDelete || false,
        index: JSON.stringify(item.index) || "",
    }
    if (item.type === "MedicationEvent") {
        const supply = item.supply?.value
        const brandStatus = /brand/gi.test(item.medication?.brandStatus)
            ? ""
            : item.medication?.brandStatus
        return {
            ...shared,
            title: formatCase(item.medication.displayName, "capitalize"),
            subtitle: brandStatus,
            subtitle2: item.medication.pharmacyName,
            source: item.medication.source,
            claimId: item.medication.claimId,
            medication: {
                daysSupply: supply,
                prescriberName: item.medication.prescriberName,
                pharmacyName: item.medication.pharmacyName,
                pharmacyPhone: item.medication.pharmacyPhone,
                pharmacyAddress: {
                    street: item.medication.pharmacyAddress.street,
                    city: item.medication.pharmacyAddress.city,
                    state: item.medication.pharmacyAddress.state,
                    zip: item.medication.pharmacyAddress.zip,
                },
                medicationId: item.medication.id,
                medicationNdc: item.medication.medicationNdc,
                medicationTreatmentId: String([
                    Math.floor(Math.random() * [1, 2, 3].length),
                ]), // item.medication.medicationTreatmentId,
                fillCost: item.medication.fillCost,
            },
        }
    }
    if (item.type === "PractitionerEvent") {
        return {
            ...shared,
            encounterType: item.encounterType,
            title: formatCase(
                item.practitioner.displayName ||
                "",
                "titlecase",
            ),
            subtitle:
                item.encounterType === "Hospital"
                    ? "Hospital Visit"
                    : formatCase(
                          item.practitioner.specialty ||
                          "",
                          "titlecase",
                      ) || "",
            subtitle2: formatCase(
                item.practitioner.taxName ||
                    "",
                "titlecase",
            ),
            source: item.practitioner.source,
            practitionerId: item.practitioner.id,
            procedureDescriptions: item.procedureDescriptions,
            amountOwed: item.amountOwed,
            npi: item.practitioner.npi,
            phone: phone,
            location: addressLine1 ? (addressLine1 + " " + addressLine2) : "",
            claimId: item.claimId,
        }
    }
    if (item.type === "UserAllergy") {
        return {
            ...shared,
            title: item.name,
            subtitle: item.severity
                ? formatCase(item.severity, "capitalize") + " reaction"
                : "",
            badges: ["allergy"],
            description: item.symptoms,
            creator: item.meta.creatorName.given,
            treatmentName: item.treatmentName,
        }
    }
    if (
        item.type === "UserImmunization" ||
        item.type === "ClaimsImmunization"
    ) {
        return {
            ...shared,
            title: item.name,
            badges: ["immunization"],
            creator: item.meta.creatorName.given,
            location: item.location,
        }
    }
    if (item.type === "UserEvent") {
        return {
            ...shared,
            title: item.title,
            description: item.details,
            // Truncate description to 100 max length for collapsed state and add ellipses if truncated
            descriptionSummary:
                item.details.slice(0, 100) +
                (item.details.length > 100 ? "..." : ""),
            creator: item.meta.creatorName.given,
        }
    }
    return shared
}

/**
 * Define Timeline Avatar (color and icon)
 * -----------------------------------------------------------------------------
 */
export const defineTimelineAvatar = (
    item: TimelineItem,
): {
    readonly variant: AvatarProps["variant"]
    readonly icon: AvatarProps["icon"]
} => {
    switch (item.type) {
        case "MedicationEvent":
            return { variant: "default", icon: "rx" }
        case "UserAllergy":
            return { variant: "error", icon: "ban" }
        case "UserImmunization" || "ClaimsImmunization":
            return { variant: "info", icon: "syringe" }
        case "PractitionerEvent":
            switch (item.encounterType) {
                case "Dental":
                    return { variant: "default", icon: "tooth" }
                case "Vision":
                    return { variant: "default", icon: "glasses" }
                case "Medical":
                    return { variant: "default", icon: "stethoscope" }
                case "Hospital":
                    return { variant: "default", icon: "building" }
                default:
                    return { variant: "default", icon: "stethoscope" }
            }
        case "UserEvent":
            return { variant: "secondary", icon: "clipboard" }
        default:
            return { variant: "secondary", icon: "clipboard" }
    }
}

/**
 * Filter duplicate filter values
 */
export const filterDuplicateFilterItems = (
    items: ReadonlyArray<TimelineItem>,
    filterValue: string,
) => {
    switch (filterValue) {
        case "displayName":
            return items
                .map(item => {
                    return item.type === "PractitionerEvent" &&
                        item.practitioner.displayName !== null
                        ? item.practitioner.displayName
                        : ""
                })
                .filter((value, index, array) => {
                    return array.indexOf(value) === index && value !== ""
                })
        case "taxName":
            return items
                .map(item => {
                    return item.type === "PractitionerEvent" &&
                        item.practitioner.taxName !== null
                        ? item.practitioner.taxName
                        : ""
                })
                .filter((value, index, array) => {
                    return array.indexOf(value) === index && value !== ""
                })
    }
}
