import * as React from "react"
import { SVGProps } from "react"
const SvgChevronLeftSm = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Chevron pointed left"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M14 17C12.8865 15.9845 9 12 9 12C9 12 12.8865 8.01552 14 7"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgChevronLeftSm
