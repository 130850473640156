/* eslint-disable functional/prefer-readonly-type */
import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit"
import { SliceCaseReducers, ValidateSliceCaseReducers } from "@reduxjs/toolkit"

/**
 * This module is a utility that creates actions for error handling
 * There is an example of it's use in
 * src/store/insights/reducer.ts
 * src/store/insights/actions.ts
 * createSlice and createStatefulSlice do the same thing but createStatefulSlice
 * adds four actions automatically using the name you pass into the slice
 * set[name]Status
 * =============== i.e.
 * action.setInsightsStatus("completed")
 *
 * [name]Error
 * ================ i.e.
 * actions.insightsError({
 *       response: res,
 * }),
 *
 * [name]PageError
 * actions.PageError({
 *       response: res,
 * }),
 * and finally the reset for the page error for the dismiss
 * [name]DismissPageError
 * actions.insightsDismissPageError({
 *       response: res,
 * }),
 */

/**
 * Types of states possible for the slice data
 * (proposal that would possibly replace isLoading and hasLoaded)
 */
export type DataStates = "pristine" | "pending" | "completed" | "error"

/**
 * WIP Error Types which are applied by passing the response into the error action reducer
 */
export type ErrorTypes =
    | "GENERIC400" // 400, 401 ...
    | "GENERIC500" // 500, 501, ...
    | "GENERICERROR" // Any other error

/**
 * SliceState is the barebones reducer state for any stateful slice
 * Slice state includes all attributes of T as well as what is listed below
 * EX:
 * interface Insights {
 *      items: Insight[]
 * }
 * SliceState<Insights> (is)
 * {
 *      items: Insight[]
 *      status: DataStates
 *      readonly error?: ErrorTypes
 *      readonly errorCode?: string
 *      readonly pageError: {
 *          error: ErrorTypes
 *          errorCode?: string
 *      }
 * }
 */
export type SliceState<T> = T & {
    readonly status: DataStates
    readonly error?: ErrorTypes
    readonly errorCode?: string
    /**
     * Page level dismissable error with errorcode
     * If status does not exist errorCode will be undefined
     */
    readonly pageError?: {
        error: ErrorTypes
        errorCode?: string
    }
}

/**
 * Function return the error of a bad response
 * @param response the axios response from a request
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getResponseError = (response: any): ErrorTypes => {
    if (!response?.status) {
        return "GENERICERROR"
    } else if (/^5[0-9]{2}$/.exec(response?.status?.toString())) {
        return "GENERIC500"
    } else if (/^4[0-9]{2}$/.exec(response?.status?.toString())) {
        return "GENERIC400"
    } else {
        return "GENERICERROR"
    }
}

/**
 * createStatefulSlice can be used the same way as createSlice
 * after using createStatefulSlice with the types passed in as in createSlice
 * createStatefulSlice includes two reducers automatically as in the following example
 * EX for the insights createSlice
 */
//   createStatefulSlice({
//       name: "insights",
//       initialState,
//       reducers: {
//          // ... include here the data update reducer and reset reducer as per normal
//          // replace what was <Insights> with SliceState<Insights>
//          // ==================== i.e.
//          receiveInsights: (
//              state: SliceState<Insights>,
//              action: PayloadAction<Insights>,
//          ) => {
//              return {
//                  status: "completed",
//                  items: action.payload.items,
//              }
//          },
//       }
//    })
/**
 * See https://github.com/reduxjs/redux-toolkit/issues/580 for more detail &
 * These are just unfolded reducers with some extra action bits like what prepareActions do here:
 * https://redux-toolkit.js.org/api/createAction#using-prepare-callbacks-to-customize-action-contents
 */
export const createStatefulSlice = <
    T,
    Reducers extends SliceCaseReducers<SliceState<T>>
>({
        name,
        initialState,
        reducers,
    }: {
    name: string
    initialState: SliceState<T>
    reducers: ValidateSliceCaseReducers<SliceState<T>, Reducers>
}) => {
    const extension = {
        /**
         * I.E. actions.insightsStatus where insights is the name of the reducer
         * To be used like actions.insightsStatus("completed")
         * Where @param status is one of "pristine" | "pending" | "completed" | "error"
         */
        [name + "Status"]: {
            reducer(
                state,
                action: PayloadAction<
                    SliceState<T>,
                    string,
                    { status: DataStates }
                >,
            ) {
                return {
                    ...state,
                    status: action.meta.status,
                }
            },
            prepare(status: ErrorTypes) {
                return { undefined, meta: { status } }
            },
        },

        /**
         * I.E. actions.insightsError where insights is the name of the reducer
         * To be used like actions.insightsError(response)
         * Where @param response is the response from the Axios request manager
         **/
        [name + "Error"]: {
            reducer(
                state,
                action: PayloadAction<
                    SliceState<T>,
                    string,
                    { error: ErrorTypes; errorCode: string }
                >,
            ) {
                return {
                    ...state,
                    status: "error",
                    error: action.meta.error,
                    ...(action.meta.errorCode && {
                        errorCode: action.meta.errorCode,
                    }),
                }
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            prepare(payload: any) {
                return {
                    undefined,
                    meta: {
                        error: getResponseError(payload.response),
                        ...(payload.response?.status && {
                            errorCode: payload.response?.status.toString(),
                        }),
                    },
                }
            },
        },
        [name + "PageError"]: {
            reducer(
                state,
                action: PayloadAction<
                    SliceState<T>,
                    string,
                    /**
                     * Not passing in an id assumes a one to one relationship between target and attribute
                     * Passing an id corresponds to a many to one attribute such as insights.items (Array of insights)
                     * so with target "insight" an insight item we can setup an error state tree for postceding requests
                     **/
                    { error: ErrorTypes; errorCode: string }
                >,
            ) {
                const { error, errorCode } = action.meta
                return {
                    ...state,
                    pageError: {
                        error,
                        errorCode,
                    },
                }
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            prepare(payload: any) {
                return {
                    undefined,
                    meta: {
                        error: getResponseError(payload.response),
                        ...(payload.response?.status && {
                            errorCode: payload.response.status?.toString(),
                        }),
                    },
                }
            },
        },
        [name + "DismissPageError"]: {
            reducer(state) {
                return {
                    ...state,
                    pageError: undefined,
                }
            },
        },
    }

    /**
     * Return the stateful slice with the additional slice reducers added
     */
    return createSlice({
        name,
        initialState,
        reducers: {
            ...extension,
            ...reducers,
        },
    }) as Slice<SliceState<T>, Reducers & typeof extension, typeof name>
}
