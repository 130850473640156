/**
 * Info Grid
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Icon, IconTypes } from "elements"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props extends React.HTMLAttributes<HTMLDivElement> {
    readonly items: ReadonlyArray<{
        readonly label: string
        readonly value: string | React.ReactNode
        readonly icon: IconTypes
    }>
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const InfoGrid: React.FC<Props> = props => {
    /**
     * Template
     */
    return (
        <div
            className={`grid grid-cols-1 md:grid-cols-2 gap-y-sm gap-x-sm ${
                props.className || ""
            }`}
        >
            {props.items.map((item, index) => (
                <div className="pb-sm border-b border-muted" key={index}>
                    <div className="flex space-x-xs">
                        <Icon type={item.icon} className="text-muted" />
                        <div className="flex-1">
                            <label className="block">{item.label}</label>
                            <div className="mt-xxs leading-none">
                                {item.value}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default InfoGrid
