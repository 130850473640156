/**
 * Session reducer
 * -----------------------------------------------------------------------------
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface LnDevice {
    readonly isInitialized: boolean
    readonly isLoading: boolean
    readonly errorMessage?: string
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: LnDevice = {
    isInitialized: false,
    isLoading: false,
    errorMessage: undefined,
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const lnDevice = createSlice({
    name: "lnDevice",
    initialState,
    reducers: {
        receiveLnDevice: (state: LnDevice, action: PayloadAction<LnDevice>) => {
            return action.payload
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const lnDeviceActions = { ...lnDevice.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default lnDevice.reducer
