/**
 * Account Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import { http } from "utils"
import { transformRegenceAccount } from "./utils"
import { isLocalDevelopment } from "@/utils/env"
import { ActivationType } from "@/store/account/types"
import { activateEmailQuery } from "./queries"
import { fetchBadgeCount } from "../message-center/queries"

interface ActivateEmail {
    readonly activationCode: string
}

/**
 * Get account data { profile, care subjects, plan, etc... }
 * -----------------------------------------------------------------------------
 */
export const getAccount = createAsyncThunk(
    "account/getAccount", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        const { account } = store.getState()
        dispatch(actions.receiveAccount({ ...account, isLoading: true }))

        /**
         * Fetch Regence user data
         */
        const accountEndpoint = isLocalDevelopment(window?.location?.host)
            ? "/api/janus/digital-first-api-routing-service/account"
            : "/api/account"

        const [accountResponse, badgeCountResponse] = await Promise.all([
            http.get(accountEndpoint),
            http.query(
                "/api/janus/digital-first-information-service/graphql",
                fetchBadgeCount,
            ),
        ])

        /**
         * Request mctr unread essages count
         */
        const badgeCount =
            badgeCountResponse?.data?.data?.messageCenter?.badgeCount

        if (badgeCountResponse.error || badgeCountResponse.data?.errors) {
            console.error(
                badgeCountResponse.error ||
                    JSON.stringify(badgeCountResponse.data?.errors),
            )
        }

        // if account fetch fails we want to return early with error
        // but if badge count call fails it is not critical, we should not fail
        // in fact it should probably be moved to a separate action
        if (accountResponse.error) {
            console.error(accountResponse.error)
            dispatch(
                actions.receiveAccount({
                    ...account,
                    isLoading: false,
                    isInitialized: true,
                    errorMessage: "Could not retrieve account data",
                }),
            )
            return
        }

        /**
         * Transform Regence data
         */
        const data = accountResponse.data.data || {}
        const { profile, plan } = transformRegenceAccount(data)
        /**
         * Update state
         */
        dispatch(
            actions.receiveAccount({
                ...account,
                profile: { ...profile, messageCenterNotifications: badgeCount },
                plan,
                isLoading: false,
                isInitialized: true,
            }),
        )
    },
)

/**
 * Update phone verification status
 * -----------------------------------------------------------------------------
 */
export const setSmsVerificationStatus = createAsyncThunk(
    "account/setSmsVerificationStatus", // Reducer name
    async (newStatus: string, { dispatch }) => {
        /**
         * Set the updated status in the state
         */
        const { account } = store.getState()
        const { profile } = account
        const _profile = { ...profile, setSmsVerificationStatus: newStatus }
        dispatch(
            actions.receiveAccount({
                ...account,
                profile: _profile,
                isLoading: false,
                isInitialized: true,
            }),
        )
    },
)

/**
 * Update email verification status
 * -----------------------------------------------------------------------------
 */
export const setEmailVerificationStatus = createAsyncThunk(
    "member-settings/setEmailVerificationStatus", // Reducer name
    async (newStatus: string, { dispatch }) => {
        /**
         * Set the updated status in the state
         */
        const { myAccount } = store.getState()
        const { profile } = myAccount
        const _profile = { ...profile, emailVerificationStatus: newStatus }
        dispatch(
            actions.receiveMyAccountSettings({
                ...myAccount,
                profile: _profile,
                emailActivation: {
                    status:
                        myAccount.emailActivation.status ===
                        ActivationType.Success
                            ? ActivationType.Empty
                            : myAccount.emailActivation.status,
                },
            }),
        )
    },
)

/**
 * Request to activate new email
 */
export const sendEmailActivationCode = createAsyncThunk(
    "member-settings/activateEmail",
    async (params: ActivateEmail, { dispatch }) => {
        /**
         * Update state
         */
        dispatch(
            actions.receiveMyAccountEmailActivationStatus({
                emailActivation: {
                    status: "PENDING",
                },
            }),
        )

        //eslint-disable-next-line
        let status = "SUCCESS"
        try {
            const url = "/api/janus/digital-first-information-service/graphql"
            const query = activateEmailQuery
            const res = await http.mutation(url, query, { activationCode: params.activationCode })
            if (
                !res ||
                res?.error ||
                res?.status !== 200 ||
                res?.data?.errors
            ) {
                console.error(
                    "Failed to activate email",
                    res?.data?.errors[0]?.message,
                    res?.status,
                )
                status = "FAILED"
            }
            return Promise.resolve(res)

        } catch (err) {
            console.error(
                "Failed to activate email",
                err?.status,
            )
            status = "FAILED"
        }

        /**
         * Update state
         */
        dispatch(
            actions.receiveMyAccountEmailActivationStatus({
                emailActivation: {
                    status: status,
                },
            }),
        )
    },
)

/**
 * Update membershipId
 * -----------------------------------------------------------------------------
 */
export const setMembershipId = createAsyncThunk(
    "account/setMembershipId", // Reducer name
    async (params: void, { dispatch }) => {

        const { account } = store.getState()
        /**
         * Set loading state
         */
        dispatch(actions.receiveAccount({ ...account, isLoading: true }))

        const query = `query { primaryPolicy { requesterMembership { membershipId } } }`
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            query,
        )

        /**
            * Handle request errors
            */
        if (res.error) {
            console.error(res.error)
            return dispatch(
                actions.receiveAccount({
                    isItemLoading: false,
                    isItemInitialized: true,
                    errorMessage: "Could not retrieve membershipId data",
                }),
            )
        }

        /**
         * Set the updated status in the state
        */
        const { family } = account
        const requesterMembershipId = res?.data?.data?.primaryPolicy?.requesterMembership?.membershipId
        const _family = { ...family, requesterMembershipId: requesterMembershipId }
        dispatch(
            actions.receiveAccount({
                ...account,
                family: _family,
                isLoading: false,
                isInitialized: true,
            }),
        )
    },
)
