import * as React from "react"
import { SVGProps } from "react"
const SvgPaperclip = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Paperclip"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12 20.1L10.9 21.2C8.70002 23.4 5.00002 23.4 2.80002 21.2C0.600024 19 0.600024 15.3 2.80002 13.1L13.1 2.80002C15.3 0.600024 19 0.600024 21.2 2.80002C23.3 4.90002 23.4 8.30002 21.6 10.5C21.5 10.6 21.4 10.8 21.2 10.9L16.4 15.7C15.2 16.9 13.2 16.9 12 15.7C10.8 14.5 10.8 12.5 12 11.3L15.7 7.60002"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgPaperclip
