import * as React from "react"
import { SVGProps } from "react"
const SvgTooth = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Tooth" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M14.8 18.8002C14.6 20.6002 15 21.5002 15.8 21.2002C16.2298 21.039 17.1215 18.713 17.9011 16.4087C18.8963 13.4676 20.7669 10.5958 20.6766 7.49225C20.6408 6.26151 20.3261 5.07706 19.8 4.20018C19 3.00018 18 2.30018 16.5 3.10018C16.2 3.30018 13.5 4.80018 12 4.80018C10.5 4.80018 7.8 3.30018 7.5 3.10018C6.1 2.30018 5.1 3.00018 4.2 4.20018C3.79629 4.87304 3.51708 5.72699 3.39283 6.644C2.90335 10.2566 5.11855 13.7883 6.3433 17.2221C7.06019 19.232 7.82094 21.058 8.2 21.2002C9 21.5002 9.5 20.6002 9.2 18.8002C9 17.1002 10.1 14.8002 12 14.8002C13.9 14.8002 15 17.1002 14.8 18.8002Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
        />
    </svg>
)
export default SvgTooth
