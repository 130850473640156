import * as React from "react"
import { SVGProps } from "react"
const SvgEllipsis = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Ellipsis" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M4.97363 12.0273C4.97363 12.5947 5.43204 13.0546 5.99752 13.0546C6.563 13.0546 7.02141 12.5947 7.02141 12.0273C7.02141 11.4599 6.563 11 5.99752 11C5.43204 11 4.97363 11.4599 4.97363 12.0273Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M10.9999 12.0273C10.9999 12.5947 11.4583 13.0546 12.0238 13.0546C12.5893 13.0546 13.0477 12.5947 13.0477 12.0273C13.0477 11.4599 12.5893 11 12.0238 11C11.4583 11 10.9999 11.4599 10.9999 12.0273Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M17.0262 12.0273C17.0262 12.5947 17.4847 13.0546 18.0501 13.0546C18.6156 13.0546 19.074 12.5947 19.074 12.0273C19.074 11.4599 18.6156 11 18.0501 11C17.4847 11 17.0262 11.4599 17.0262 12.0273Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgEllipsis
