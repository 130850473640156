/**
 * Native Claims Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import {
    claimDetailsQuery,
    claimHeadersQuery,
    claimsWithDetailsQuery,
} from "./queries"
import { getHash } from "@/utils/crypto"
import { dfisClient } from "@/utils/apollo-client"
import { retryClaimDetailsInquiry, retryClaimsInquiry } from "./utils"
import { CacheStatus } from "./types"

/**
 * Get claims data with only headers
 * -----------------------------------------------------------------------------
 */
export const fetchNativeClaims = createAsyncThunk(
    "nativeClaims/fetchNativeClaims", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.nativeClaimsStatus("pending"))

        /**
         * Fetch claim headers (from Claims Inquiry Service through DFIS)
         */
        try {
            const res = await retryClaimsInquiry(dfisClient, claimHeadersQuery)

            /**
             * Handle request errors
             */
            const cacheStatus = res.data?.["claimsInquiry"].claims.cacheStatus
            if (
                res.error ||
                res.errors ||
                cacheStatus === CacheStatus.IN_PROGRESS
            ) {
                // Throw error if cache status is LOADING even after retries
                if (cacheStatus === CacheStatus.IN_PROGRESS) {
                    console.error("Claims inquiry request timed out")
                }
                return dispatch(
                    actions.nativeClaimsError({
                        response: res,
                    }),
                )
            }

            /**
             * Transform claims data
             */
            const claimsDFIS =
                res.data?.["claimsInquiry"].claims.claimData || []
            const claimFilters =
                res.data?.["claimsInquiry"].claims.filters || []
            const memberFilterValues = claimFilters.find(
                f => f.fieldName === "familyMemberId",
            )
            const providerFilterValues = claimFilters.find(
                f => f.fieldName === "providerName",
            )
            const claimTypeFilterValues = claimFilters.find(
                f => f.fieldName === "claimType",
            )
            const claimStatusFilterValues = claimFilters.find(
                f => f.fieldName === "claimStatusCode",
            )

            const items = claimsDFIS?.map(data => {
                return {
                    ...data,
                    claimNumberHash: getHash(data.claimNumber),
                }
            })

            /**
             * Update state
             */
            dispatch(
                actions.receiveNativeClaims({
                    items: items || [],
                    claimFilterData: {
                        memberFilterValues,
                        providerFilterValues,
                        claimTypeFilterValues,
                        claimStatusFilterValues: {
                            ...claimStatusFilterValues,
                            filterValues: claimStatusFilterValues.filterValues.map(f => {
                                return f.filterDisplayName === "Submitted"
                                    ? {
                                        filterValue: "P",
                                        filterDisplayName: "Pending",
                                    }
                                    : f
                            }),
                        },
                    },
                }),
            )
        } catch (error) {
            console.error("Error while fetching claim headers")
            return dispatch(
                actions.nativeClaimsError({
                    response: error,
                }),
            )
        }
    },
)

/**
 * Get all claim header numbers along with corresponding claim details
 * -----------------------------------------------------------------------------
 */
export const fetchNativeClaimsWithDetails = async () => {
    // Get current state
    const { nativeClaims: claims } = store.getState()

    /**
     * Fetch all claim headers and lines (from Claims Inquiry Service through DFIS)
     */
    try {
        const res = await retryClaimsInquiry(dfisClient, claimsWithDetailsQuery)

        /**
         * Handle request errors
         */
        const cacheStatus = res.data?.["claimsInquiry"].claims.cacheStatus
        if (
            res.error ||
            res.errors ||
            cacheStatus === CacheStatus.IN_PROGRESS
        ) {
            // TODO: Need to handle error
            // Throw error if cache status is LOADING even after retries
            if (cacheStatus === CacheStatus.IN_PROGRESS) {
                console.error("Claims inquiry request timed out")
            } else {
                console.error("Error while fetching claims with details")
            }
            return
        }

        /**
         * Transform claims data
         */
        const claimsDFIS = res.data?.["claimsInquiry"].claims.claimData || []

        const items = claims.items.map(item => {
            const claim = claimsDFIS.find(
                data => data.claimNumber === item.claimNumber,
            )
            return {
                ...item,
                claimDetails: claim?.claimDetails,
                isClaimDetailsInitialized: true,
            }
        })
        return items
    } catch (error) {
        console.error("Error while fetching claims with details")
        return
    }
}

export const fetchNativeClaimDetails = createAsyncThunk(
    "nativetClaims/fetchNativeClaimDetails", // Reducer name
    async (claimNumber: string, { dispatch }) => {
        // Get current state
        const { nativeClaims: claims } = store.getState()

        // Rx claims do not have line items so skip fetching details
        if (
            claims.items.find(
                c => c.claimNumber === claimNumber && c.claimType === "R",
            )
        )
            return

        /**
         * Fetch claim details (from Claims Inquiry Service through DFIS)
         */
        try {
            const res = await retryClaimDetailsInquiry(
                dfisClient,
                claimDetailsQuery,
                [claimNumber],
            )

            /**
             * Handle request errors
             */
            const cacheStatus =
                res.data?.["claimsInquiry"].claimLines.cacheStatus
            if (
                res.error ||
                res.errors ||
                cacheStatus === CacheStatus.IN_PROGRESS
            ) {
                // TODO: Need to handle error
                if (cacheStatus === CacheStatus.IN_PROGRESS) {
                    console.error("Claims inquiry request timed out")
                } else {
                    console.error("Error while fetching claim details")
                }
                return
            }

            /**
             * Transform claim lines data
             */
            const claimLines =
                res.data?.["claimsInquiry"].claimLines?.claimData || []

            const claimDetails = claimLines.find(
                l => l.claimNumber === claimNumber,
            )?.claimDetails

            const items = claims.items.map(item => {
                return item.claimNumber === claimNumber
                    ? { ...item, claimDetails, isClaimDetailsInitialized: true }
                    : item
            })

            /**
             * Update state
             */
            dispatch(
                actions.receiveNativeClaims({
                    ...claims,
                    items,
                }),
            )
        } catch (error) {
            // TODO: Need to handle error
            return
        }
    },
)

/**
 * Update actions for claim filters
 * -----------------------------------------------------------------------------
 */
export const updateClaimNumberNative = createAsyncThunk(
    "nativeClaims/updateClaimNumberNative",
    async (claimNumber: string, { dispatch }) => {
        // Get current state
        const { nativeClaims: claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveNativeClaimFilters({
                ...claims.claimFilters,
                claimNumber: claimNumber,
            }),
        )
    },
)

export const updateSortByNative = createAsyncThunk(
    "nativeClaims/updateSortByNative",
    async (sortBy: string, { dispatch }) => {
        // Get current state
        const { nativeClaims: claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveNativeClaimFilters({
                ...claims.claimFilters,
                sortBy: sortBy,
            }),
        )
    },
)

export const updateMembersNative = createAsyncThunk(
    "nativeClaims/updateMembersNative",
    async (members: ReadonlyArray<string>, { dispatch }) => {
        // Get current state
        const { nativeClaims: claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveNativeClaimFilters({
                ...claims.claimFilters,
                members: members,
            }),
        )
    },
)

export const updateStartRangeNative = createAsyncThunk(
    "nativeClaims/updateStartRangeNative",
    async (startRange: string, { dispatch }) => {
        // Get current state
        const { nativeClaims: claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveNativeClaimFilters({
                ...claims.claimFilters,
                startRange: startRange,
            }),
        )
    },
)

export const updateEndRangeNative = createAsyncThunk(
    "nativeClaims/updateEndRangeNative",
    async (endRange: string, { dispatch }) => {
        // Get current state
        const { nativeClaims: claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveNativeClaimFilters({
                ...claims.claimFilters,
                endRange: endRange,
            }),
        )
    },
)

export const updateMedicalNative = createAsyncThunk(
    "nativeClaims/updateMedicalNative",
    async (medical: boolean, { dispatch }) => {
        // Get current state
        const { nativeClaims: claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveNativeClaimFilters({
                ...claims.claimFilters,
                medical: medical,
            }),
        )
    },
)

export const updatePharmacyNative = createAsyncThunk(
    "nativeClaims/updatePharmacyNative",
    async (pharmacy: boolean, { dispatch }) => {
        // Get current state
        const { nativeClaims: claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveNativeClaimFilters({
                ...claims.claimFilters,
                pharmacy: pharmacy,
            }),
        )
    },
)

export const updateDentalNative = createAsyncThunk(
    "nativeClaims/updateDentalNative",
    async (dental: boolean, { dispatch }) => {
        // Get current state
        const { nativeClaims: claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveNativeClaimFilters({
                ...claims.claimFilters,
                dental: dental,
            }),
        )
    },
)

export const updateProvidersNative = createAsyncThunk(
    "nativeClaims/updateProvidersNative",
    async (providers: ReadonlyArray<string>, { dispatch }) => {
        // Get current state
        const { nativeClaims: claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveNativeClaimFilters({
                ...claims.claimFilters,
                providers: providers,
            }),
        )
    },
)

export const updateCompletedNative = createAsyncThunk(
    "nativeClaims/updateCompletedNative",
    async (completed: boolean, { dispatch }) => {
        // Get current state
        const { nativeClaims: claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveNativeClaimFilters({
                ...claims.claimFilters,
                completed: completed,
            }),
        )
    },
)

export const updateInProcessNative = createAsyncThunk(
    "nativeClaims/updateInProcessNative",
    async (inProcess: boolean, { dispatch }) => {
        // Get current state
        const { nativeClaims: claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveNativeClaimFilters({
                ...claims.claimFilters,
                inProcess: inProcess,
            }),
        )
    },
)

export const updatePendingNative = createAsyncThunk(
    "nativeClaims/updatePendingNative",
    async (pending: boolean, { dispatch }) => {
        // Get current state
        const { nativeClaims: claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveNativeClaimFilters({
                ...claims.claimFilters,
                pending: pending,
            }),
        )
    },
)

export const updateSubmittedNative = createAsyncThunk(
    "nativeClaims/updateSubmittedNative",
    async (submitted: boolean, { dispatch }) => {
        // Get current state
        const { nativeClaims: claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveNativeClaimFilters({
                ...claims.claimFilters,
                submitted: submitted,
            }),
        )
    },
)

export const updateAdjustedNative = createAsyncThunk(
    "nativeClaims/updateAdjustedNative",
    async (adjusted: boolean, { dispatch }) => {
        // Get current state
        const { nativeClaims: claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveNativeClaimFilters({
                ...claims.claimFilters,
                adjusted: adjusted,
            }),
        )
    },
)

export const updateSortByOpenNative = createAsyncThunk(
    "nativeClaims/updateSortByOpenNative",
    async (sortByOpened: boolean, { dispatch }) => {
        // Get current state
        const { nativeClaims: claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveNativeClaimFilters({
                ...claims.claimFilters,
                sortByOpened: sortByOpened,
            }),
        )
    },
)

export const updateMemberOpenedNative = createAsyncThunk(
    "nativeClaims/updateMemberOpenedNative",
    async (memberOpened: boolean, { dispatch }) => {
        // Get current state
        const { nativeClaims: claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveNativeClaimFilters({
                ...claims.claimFilters,
                memberOpened: memberOpened,
            }),
        )
    },
)

export const updateDateOpenedNative = createAsyncThunk(
    "nativeClaims/updateDateOpenedNative",
    async (dateOpened: boolean, { dispatch }) => {
        // Get current state
        const { nativeClaims: claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveNativeClaimFilters({
                ...claims.claimFilters,
                dateOpened: dateOpened,
            }),
        )
    },
)

export const updateCategoryOpenedNative = createAsyncThunk(
    "nativeClaims/updateCategoryOpenedNative",
    async (categoryOpened: boolean, { dispatch }) => {
        // Get current state
        const { nativeClaims: claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveNativeClaimFilters({
                ...claims.claimFilters,
                categoryOpened: categoryOpened,
            }),
        )
    },
)

export const updateProviderOpenedNative = createAsyncThunk(
    "nativeClaims/updateProviderOpenedNative",
    async (providerOpened: boolean, { dispatch }) => {
        // Get current state
        const { nativeClaims: claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveNativeClaimFilters({
                ...claims.claimFilters,
                providerOpened: providerOpened,
            }),
        )
    },
)

export const updateStatusOpenedNative = createAsyncThunk(
    "nativeClaims/updateStatusOpenedNative",
    async (statusOpened: boolean, { dispatch }) => {
        // Get current state
        const { nativeClaims: claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveNativeClaimFilters({
                ...claims.claimFilters,
                statusOpened: statusOpened,
            }),
        )
    },
)
