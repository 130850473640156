import {
    ApolloClient,
    ApolloQueryResult,
    OperationVariables,
    gql,
} from "@apollo/client"

export type QueryConfig<TCacheShape> = {
    readonly apolloClient: ApolloClient<TCacheShape>
    readonly queryString: string
    readonly queryVariables?: OperationVariables
    readonly retryConfig?: RetryConfig
}

export type RetryConfig = {
    /**
     * Max numbe rof retries
     * Default: 3
     */
    readonly maxRetries?: number
    /**
     * A callback to further control the delay between retry requests
     * Default: 500 ms
     * Delays will be -> 500 -> 1000 -> 2000
     */
    readonly retryDelay?: number
    /**
     * A callback to further control if a request should be retried.
     * Default: It retires in case of network error
     */
    readonly retryCondition?: <T>(
        response: ApolloQueryResult<T>,
    ) => boolean | Promise<boolean>
    /**
     * Current value for retry counter or iteration
     * Default: 0
     */
    readonly retryCount?: number
}

/**
 * @param delayTime in milliseconds
 * @returns
 */
export const delay = (delayTime: number) =>
    new Promise(resolve => setTimeout(resolve, delayTime))

/**
 *
 * @param apolloClient
 * @param queryString
 * @param retryConfig
 * @returns
 */
export const query = async <TCacheShape, T>(
    queryConfig: QueryConfig<TCacheShape>,
): Promise<ApolloQueryResult<T>> => {
    const {
        apolloClient,
        queryString,
        queryVariables = {},
        retryConfig = {},
    } = queryConfig
    const {
        maxRetries = 3,
        retryDelay = 500,
        retryCondition = <T>(response: ApolloQueryResult<T>) =>
            !!response?.error?.networkError,
        retryCount = 0,
    } = retryConfig

    const res = await apolloClient.query({
        query: gql`
            ${queryString}
        `,
        variables: queryVariables,
    })
    if (retryCount < maxRetries && retryCondition(res)) {
        await delay(retryDelay)
        return query({
            apolloClient,
            queryString,
            queryVariables,
            retryConfig: {
                maxRetries,
                retryDelay: retryDelay * 2,
                retryCondition,
                retryCount: retryCount + 1,
            },
        })
    }

    return res
}

export default { query }
