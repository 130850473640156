/**
 * Claim Submission reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./action"
import { ClaimSubmission } from "./types"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface ClaimSubmissions {
    readonly claimSubmissionFaq: ReadonlyArray<ClaimSubmission>
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<ClaimSubmissions> = {
    status: "pristine",
    claimSubmissionFaq: [],
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const claimSubmissionFaq = createStatefulSlice({
    name: "claimSubmissionFaq",
    initialState,
    reducers: {
        receiveClaimSubmissionFaq: (
            state: SliceState<ClaimSubmissions>,
            action: PayloadAction<ClaimSubmissions>,
        ) => {
            return {
                status: "completed",
                claimSubmissionFaq: action.payload.claimSubmissionFaq || [],
            }
        },
        resetClaimSubmissionFaq: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const claimSubmissionFaqActions = { ...claimSubmissionFaq.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default claimSubmissionFaq.reducer