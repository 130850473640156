import { getEnvironment, isLocalDevelopment } from "./env"

const prodGroupId = "21585"
const nonprodGroupId = "21637" // TODO confirm from Glance what the real group ID is for non-prod -- this is not working currently
const baseUrl = "https://www.glancecdn.net/cobrowse/CobrowseJS.ashx"

// glance UI values of interest
export const ID_GLANCE_TERMS_MODAL = "glance_terms"
export const ID_GLANCE_MODAL_SCRIM = "glance_scrim"
export const CLASS_GLANCE_SHOW = "glance_show"

/**
 * Glance Cobrowse values related to environment (not user):
 * https://help.glance.net/glance-cobrowse/getting-started/attributes/
 */
interface CobrowseConfig {
    readonly groupId: string
    readonly site: "staging" | "production"
    readonly url: string
    readonly scriptId: string
    readonly cookieDomain: string
    readonly cookieType: "Normal" | "Secure" | "LS" | "Dual"
    readonly disableLogging: boolean
    readonly presence: "on" | "off"
}

/**
 * Glance Cobrowse script attributes/data/setup:
 * https://help.glance.net/glance-cobrowse/getting-started/attributes/
 */
interface CobrowseScriptData extends CobrowseConfig {
    readonly visitorId?: string
}

/**
 * Get cobrowse config for the current environment
 * https://help.glance.net/glance-cobrowse/getting-started/attributes/
 */
export const getCobrowseConfig = (host: string): CobrowseConfig => {
    const env = getEnvironment(host)
    const site = env === "prd" ? "production" : "staging"
    const groupId = env === "prd" ? prodGroupId : nonprodGroupId
    const url = `${baseUrl}?group=${groupId}&site=${site}`
    const scriptId = "glance-cobrowse"
    const getCookieDomain = (isLocal, env) => {
        if (isLocal) {
            return "localhost"
        } else if (env === "prd") {
            return "account.regence.com"
        } else {
            return `account-${env}.regence.com`
        }
    }
    const cookieDomain = getCookieDomain(isLocalDevelopment(host), env)
    const cookieType = isLocalDevelopment(host) ? "Normal" : "Secure"

    return {
        groupId,
        site,
        url,
        scriptId,
        cookieDomain,
        cookieType,
        disableLogging: !isDebug(host),
        presence: "on",
    }
}

/**
 * Create Cobrowse <script> tag element
 */
export const createGlanceScript = (config: CobrowseConfig) => {
    /* eslint-disable functional/immutable-data */
    globalThis.GLANCE_COBROWSE = { disableLogging: config.disableLogging }

    const scriptTag = document.createElement("script")
    scriptTag.type = "text/javascript"
    scriptTag.async = true
    scriptTag.src = config.url
    scriptTag.setAttribute("id", config.scriptId)
    scriptTag.setAttribute("data-groupid", config.groupId)
    scriptTag.setAttribute("data-visitorid", "temporaryPlaceholderID") // load visitorId async from account.memeCk
    scriptTag.setAttribute("data-presence", config.presence)
    scriptTag.setAttribute("data-cookiedomain", config.cookieDomain)
    scriptTag.setAttribute("data-cookietype", config.cookieType)
    scriptTag.setAttribute("data-site", config.site)

    return scriptTag
    /* eslint-enable functional/immutable-data */
}

/**
 * this is kinda weird, but seemed the most reliable with what glance provides.
 * it's not necessary other than confirming we have the right value loaded, ie troubleshooting.
 *
 * @returns visitorId in presence data; null if not found
 */
export const getPresenceVisitorId = (): string | null => {
    if (!globalThis.GLANCE?.Presence?.Visitor?.instance) {
        return null
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const result: unknown = Object.values(
        globalThis.GLANCE.Presence.Visitor.instance,
    ).find((x: any) => !!x.visitorid)
    return (result as any)?.visitorid
    /* eslint-enable @typescript-eslint/no-explicit-any */
}

export const isCobrowseSessionInProgress = () => {
    return (
        globalThis.GLANCE?.Cobrowse?.Visitor &&
        globalThis.GLANCE.Cobrowse.Visitor.inSession()
    )
}

/**
 * Sets Cobrowse presence key (visitorId), used to link user to salesforce, etc.
 *
 * Numbers are accepted here, but coerced to strings per Glance's requirements for keys/visitorIds:
 * - https://help.glance.net/glance-cobrowse/glance-cobrowse-customizing/visitor_agent/visitor/#using-startsession
 * - https://help.glance.net/glance-cobrowse/glance-cobrowse-customizing/visitor_agent/visitor/#$cobrowse-visitor-api
 *
 * exerpt from docs:
 * can contain the following characters: numbers, letters (upper or lower case), underscore (“_”), dash (“-”), and tilde (“~”).
 * Keys may be up to 100 characters long.
 *
 * Since we are setting MEME_CK for visitorId, we do not have to validate the visitorId format here (for now at least).
 */
export const setVisitorId = (visitorId: string | number) => {
    if (globalThis.GLANCE?.Presence?.Visitor) {
        globalThis.GLANCE.Presence.Visitor.setVisitorId(visitorId + "")

        // alternate method, supposedly the same but might work better in certain cases. leaving this here as a note from Glance.
        // const presencevisitor = new globalThis.GLANCE.Presence.Visitor({ visitorid: visitorId + "" })
        // presencevisitor.presence()
        // presencevisitor.connect()
    }
}

/**
 * Returns promise that waits until a given predicate function returns true to resolve, sleeping {@link timeout} (default 250ms) between checks.
 */
export const waitFor = ({
    predicate,
    name,
    timeout = 250,
}: {
    // what to wait for. function that returns true or false. once true, we're done waiting (ie the fn resolves).
    readonly predicate: () => boolean
    // optional. used for debug logging -- leave empty for no logging
    readonly name?: string
    // timeout to wait in between checks, in ms
    readonly timeout?: number
}) => {
    return new Promise<void>(resolve => {
        const waitLog = (...args) => {
            if (name) {
                debugLog("waitFor", name, ...args)
            }
        }
        if (predicate()) {
            waitLog("waitFor no need to wait")
            resolve()
            return
        }

        waitLog("waitFor going to start waiting")
        const intervalId = setInterval(() => {
            if (predicate()) {
                clearInterval(intervalId)
                waitLog("waitFor waiting no more")
                resolve()
            } else {
                waitLog("waitFor more time")
            }
        }, timeout)
    })
}

export const isDebug = (host?: string) => {
    const env = getEnvironment(host)
    return !["uat", "prd"].includes(env)
}

export const debugLog = (...args) => {
    if (isDebug()) {
        // eslint-disable-next-line no-console
        console.log(...args)
    }
}
