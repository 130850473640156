/**
 * Digital First Information Service (DFIS) types
 * -----------------------------------------------------------------------------
 */

/* eslint-disable */
export type Maybe<T> = T | null
export type Scalars = {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
}

export type AcceptCareTeamInvitationResponse = CareTeamMember | Error | Success

export enum AccountState {
    Granted = "GRANTED",
    Revoked = "REVOKED",
    Terminated = "TERMINATED",
}

export type Address = {
    __typename?: "Address"
    city?: Maybe<Scalars["String"]>
    postalCode?: Maybe<Scalars["String"]>
    state?: Maybe<Scalars["String"]>
    street?: Maybe<Array<Maybe<Scalars["String"]>>>
    streetText?: Maybe<Scalars["String"]>
}

export type AddressInput = {
    city?: Maybe<Scalars["String"]>
    postalCode?: Maybe<Scalars["String"]>
    state?: Maybe<Scalars["String"]>
    street?: Maybe<Array<Maybe<Scalars["String"]>>>
    streetText?: Maybe<Scalars["String"]>
}

export type Allergy = {
    careSubjectId: Scalars["ID"]
    category?: Maybe<AllergyCategory>
    id: Scalars["ID"]
    medicationDetailsId?: Maybe<Scalars["ID"]>
    meta: UserMeta
    name: Scalars["String"]
    notepadId: Scalars["ID"]
    notes?: Maybe<NoteConnection>
    severity?: Maybe<AllergySeverity>
    startedAt: Scalars["String"]
    symptoms?: Maybe<Scalars["String"]>
    treatmentMedicationId?: Maybe<Scalars["ID"]>
    treatmentName?: Maybe<Scalars["String"]>
}

export enum AllergyCategory {
    Medication = "Medication",
    Other = "Other",
}

export type AllergyConnection = EdgeCollection & {
    __typename?: "AllergyConnection"
    edges: Array<Maybe<AllergyEdge>>
    pageInfo: PageInfo
    totalCount: Scalars["Int"]
}

export type AllergyEdge = {
    __typename?: "AllergyEdge"
    cursor: Scalars["ID"]
    edgeId: Scalars["ID"]
    node: Allergy
    nodeType: Scalars["String"]
    sortDate?: Maybe<Scalars["String"]>
}

export type AllergyResponse = Error | UserAllergy

export enum AllergySeverity {
    Mild = "Mild",
    Moderate = "Moderate",
    Severe = "Severe",
}

export type AvailableBenefit = {
    __typename?: "AvailableBenefit"
    description?: Maybe<Scalars["String"]>
    name: Scalars["String"]
    phone?: Maybe<Scalars["String"]>
    url?: Maybe<Scalars["String"]>
}

export type BillFinancialInfo = {
    __typename?: "BillFinancialInfo"
    healthpassBills?: Maybe<Array<Maybe<HealthpassBill>>>
    status: Scalars["Int"]
}

export type BuildSupport = {
    __typename?: "BuildSupport"
    minimumAndroidSemantic: Scalars["String"]
    minimumAndroidVersion: Scalars["Int"]
    minimumIosSemantic: Scalars["String"]
    minimumIosVersion: Scalars["Int"]
}

export type CabinetMedication = HasMedicationDetails &
    HasUserMeta &
    Medication &
    Notepad &
    Tags & {
        __typename?: "CabinetMedication"
        brandStatus?: Maybe<Scalars["String"]>
        careSubjectId: Scalars["ID"]
        condition?: Maybe<MedicationConditionNote>
        conditionId?: Maybe<Scalars["ID"]>
        conditionNoteId?: Maybe<Scalars["ID"]>
        displayName?: Maybe<Scalars["String"]>
        dosage?: Maybe<MedicationDosage>
        id: Scalars["ID"]
        lastFillDate?: Maybe<Scalars["String"]>
        medicationDetailsId: Scalars["ID"]
        medicationId: Scalars["ID"]
        medicationNdcs?: Maybe<Array<Maybe<Scalars["String"]>>>
        medicationTreatmentId?: Maybe<Scalars["String"]>
        meta: UserMeta
        notepadId: Scalars["ID"]
        notes?: Maybe<NoteConnection>
        pharmacyName?: Maybe<Scalars["String"]>
        pharmacyPhone?: Maybe<Scalars["String"]>
        prescriberName?: Maybe<Scalars["String"]>
        status: MedicationStatusNote
        supply: Quantity
        tags: Array<Maybe<TagNodeType>>
        type: MedicationType
    }

export type CabinetMedicationTag = Tag & {
    __typename?: "CabinetMedicationTag"
    createdAt: Scalars["String"]
    expiresAt?: Maybe<Scalars["String"]>
    id: Scalars["ID"]
    identityId: Scalars["ID"]
    itemId: Scalars["ID"]
    itemType: Scalars["String"]
    subjectId: Scalars["ID"]
    type: NotificationType
    updatedAt: Scalars["String"]
}

export type CardInfo = {
    __typename?: "CardInfo"
    brand?: Maybe<Scalars["String"]>
    cardAlias?: Maybe<Scalars["String"]>
    cardLastFourDigits?: Maybe<Scalars["String"]>
}

export type CareEvent = {
    careSubjectId: Scalars["ID"]
    eventDate?: Maybe<Scalars["String"]>
    eventId: Scalars["ID"]
    id: Scalars["ID"]
    isDeleted?: Maybe<Scalars["Boolean"]>
    meta: UserMeta
    type: CareEventType
}

export type CareEventEdge = Edge & {
    __typename?: "CareEventEdge"
    cursor: Scalars["ID"]
    edgeId: Scalars["ID"]
    node: CareEventNode
    nodeType: CareEventType
    sortDate?: Maybe<Scalars["String"]>
}

export type CareEventNode =
    | ClaimsImmunization
    | DeletedEvent
    | MedicationEvent
    | PractitionerEvent
    | UserAllergy
    | UserEvent
    | UserImmunization

export enum CareEventType {
    UserEvent = "UserEvent",
    PractitionerEvent = "PractitionerEvent",
    MedicationEvent = "MedicationEvent",
    DeletedEvent = "DeletedEvent",
    UserImmunization = "UserImmunization",
    ClaimsImmunization = "ClaimsImmunization",
    UserAllergy = "UserAllergy",
}

export type CareSubject = {
    __typename?: "CareSubject"
    allergies: AllergyConnection
    birthDate?: Maybe<Scalars["String"]>
    careSubjectId: Scalars["ID"]
    membershipId?: Scalars["ID"]
    careTeam: CareTeam
    events: EventConnection
    familyMemberHistories: FamilyMemberHistoryConnection
    gender?: Maybe<Scalars["String"]>
    immunizations: ImmunizationConnection
    isOwner?: Maybe<Scalars["Boolean"]>
    isUser?: Maybe<Scalars["Boolean"]>
    isViewer?: Maybe<Scalars["Boolean"]>
    medications: MedicationConnection
    medicineCabinet: MedicineCabinetConnection
    name: HumanName
    practitioners: PractitionerConnection
    tags: Array<Maybe<TagNodeType>>
    type: Scalars["String"]
    userIdentityId?: Maybe<Scalars["ID"]>
    familyMemberId?: Maybe<Scalars["String"]>
}

export type CareSubjectConnection = EdgeCollection & {
    __typename?: "CareSubjectConnection"
    edges: Array<Maybe<CareSubjectEdge>>
    familyMembers: Array<Maybe<FamilyMember>>
    pageInfo: PageInfo
    totalCount: Scalars["Int"]
}

export type CareSubjectEdge = Edge & {
    __typename?: "CareSubjectEdge"
    cursor: Scalars["ID"]
    node: CareSubject
}

export type FamilyMember = {
    membershipId: Scalars["String"]
    firstName: Scalars["String"]
    lastName: Scalars["String"]
    birthDate: Scalars["String"]
    currentAge: Scalars["String"]
    relationship: Scalars["String"]
    disclosureAllowed: Scalars["String"]
    suffix: Scalars["String"]
}

export type CareTeam = {
    __typename?: "CareTeam"
    careSubjectId: Scalars["ID"]
    careTeamId: Scalars["ID"]
    items: Array<Maybe<CareTeamListItem>>
}

export type CareTeamInvitation = {
    __typename?: "CareTeamInvitation"
    canRescind: Scalars["Boolean"]
    canResend: Scalars["Boolean"]
    careSubjectId: Scalars["ID"]
    careSubjectName?: Maybe<HumanName>
    careTeamId: Scalars["ID"]
    careTeamInvitationId: Scalars["ID"]
    confirmationCode: Scalars["String"]
    creationDate: Scalars["String"]
    expirationDate: Scalars["String"]
    invitationState: Scalars["String"]
    inviteeEmailAddress: Scalars["String"]
    inviteeName?: Maybe<HumanName>
    inviterIdentityId: Scalars["ID"]
    message?: Maybe<Scalars["String"]>
    statusChangeDate?: Maybe<Scalars["String"]>
}

export type CareTeamInvitationDetails = CareTeamInvitation | Error

export type CareTeamListItem = CareTeamInvitation | CareTeamMember

export type CareTeamMember = {
    __typename?: "CareTeamMember"
    canLeave: Scalars["Boolean"]
    canRemove: Scalars["Boolean"]
    careSubjectId: Scalars["ID"]
    careTeamId: Scalars["ID"]
    careTeamMemberId: Scalars["ID"]
    dateJoined?: Maybe<Scalars["String"]>
    email: Scalars["String"]
    isUser: Scalars["Boolean"]
    memberIdentityId: Scalars["ID"]
    name: HumanName
}

export type CategorySuggestion = ProviderSearchSuggestion & {
    __typename?: "CategorySuggestion"
    displayName: Scalars["String"]
    distance?: Maybe<Scalars["Float"]>
    searchType: ProviderSearchType
}

export enum ChatTarget {
    Nurse = "Nurse",
    HealthCoach = "HealthCoach",
    CareGuide = "CareGuide",
}

export type ClaimEnhancements = {
    __typename?: "ClaimEnhancements"
    iconTags?: Maybe<Array<Maybe<Scalars["String"]>>>
    serviceLabels?: Maybe<Array<Maybe<Scalars["String"]>>>
}

export type ClaimsImmunization = CareEvent &
    HasUserMeta &
    Immunization &
    Notepad & {
        __typename?: "ClaimsImmunization"
        careSubjectId: Scalars["ID"]
        eventDate?: Maybe<Scalars["String"]>
        eventId: Scalars["ID"]
        id: Scalars["ID"]
        isDeleted?: Maybe<Scalars["Boolean"]>
        location?: Maybe<Scalars["String"]>
        meta: UserMeta
        name: Scalars["String"]
        notepadId: Scalars["ID"]
        notes?: Maybe<NoteConnection>
        occurrenceDate: Scalars["String"]
        practitionerEventId: Scalars["ID"]
        status: Scalars["String"]
        type: CareEventType
        vaccineCode?: Maybe<Scalars["String"]>
        vaccineCodeId?: Maybe<Scalars["String"]>
        membershipId?: Maybe<Scalars["String"]>
    }

export type ClaimsMedication = HasMedicationDetails &
    HasUserMeta &
    Medication &
    Notepad &
    Tags & {
        __typename?: "ClaimsMedication"
        brandStatus?: Maybe<Scalars["String"]>
        careSubjectId: Scalars["ID"]
        condition?: Maybe<MedicationConditionNote>
        conditionId?: Maybe<Scalars["ID"]>
        conditionNoteId?: Maybe<Scalars["ID"]>
        displayName?: Maybe<Scalars["String"]>
        dosage?: Maybe<MedicationDosage>
        id: Scalars["ID"]
        lastFillDate?: Maybe<Scalars["String"]>
        medicationDetailsId: Scalars["ID"]
        medicationId: Scalars["ID"]
        medicationNdc?: Maybe<Scalars["String"]>
        meta: UserMeta
        notepadId: Scalars["ID"]
        notes?: Maybe<NoteConnection>
        pharmacyName?: Maybe<Scalars["String"]>
        pharmacyPhone?: Maybe<Scalars["String"]>
        prescriberName?: Maybe<Scalars["String"]>
        source?: Maybe<Scalars["String"]>
        status: MedicationStatusNote
        supply: Quantity
        tags: Array<Maybe<TagNodeType>>
        type: MedicationType
    }

export type ClaimsMedicationTag = Tag & {
    __typename?: "ClaimsMedicationTag"
    createdAt: Scalars["String"]
    expiresAt?: Maybe<Scalars["String"]>
    id: Scalars["ID"]
    identityId: Scalars["ID"]
    itemId: Scalars["ID"]
    itemType: Scalars["String"]
    subjectId: Scalars["ID"]
    type: NotificationType
    updatedAt: Scalars["String"]
}

export type ClaimsPractitioner = HasUserMeta &
    Notepad &
    Practitioner &
    Tags & {
        __typename?: "ClaimsPractitioner"
        careSubjectId: Scalars["ID"]
        displayName?: Maybe<Scalars["String"]>
        enhancements?: Maybe<ProviderEnhancements>
        id: Scalars["ID"]
        isDeleted?: Maybe<Scalars["Boolean"]>
        locations?: Maybe<Array<Maybe<LocationNote>>>
        meta: UserMeta
        notepadId: Scalars["ID"]
        notes?: Maybe<NoteConnection>
        npi?: Maybe<Scalars["String"]>
        practitionerId: Scalars["ID"]
        qualification?: Maybe<Scalars["String"]>
        specialty?: Maybe<Scalars["String"]>
        tags: Array<Maybe<TagNodeType>>
        taxName: Scalars["String"]
        type: PractitionerType
    }

export type ClaimsPractitionerTag = Tag & {
    __typename?: "ClaimsPractitionerTag"
    createdAt: Scalars["String"]
    expiresAt?: Maybe<Scalars["String"]>
    id: Scalars["ID"]
    identityId: Scalars["ID"]
    itemId: Scalars["ID"]
    itemType: Scalars["String"]
    subjectId: Scalars["ID"]
    type: NotificationType
    updatedAt: Scalars["String"]
}

export type Clinic = {
    __typename?: "Clinic"
    address?: Maybe<Address>
    name?: Maybe<Scalars["String"]>
    telephone?: Maybe<Array<Maybe<Scalars["String"]>>>
}

export type ClinicInput = {
    address?: Maybe<AddressInput>
    name?: Maybe<Scalars["String"]>
    telephone?: Maybe<Array<Maybe<Scalars["String"]>>>
}

export type CognitoConfig = {
    __typename?: "CognitoConfig"
    appClientId: Scalars["ID"]
    userPoolId: Scalars["ID"]
}

export type Condition = {
    __typename?: "Condition"
    conditionId: Scalars["ID"]
    name: Scalars["String"]
}

export type ConditionDetail = {
    __typename?: "ConditionDetail"
    conditionId: Scalars["ID"]
    description?: Maybe<Scalars["String"]>
    name: Scalars["String"]
}

export type ConditionNote = HasUserMeta &
    Note & {
        __typename?: "ConditionNote"
        conditionId: Scalars["ID"]
        id: Scalars["ID"]
        medicationDetailsId?: Maybe<Scalars["ID"]>
        meta: UserMeta
        noteId: Scalars["ID"]
        notepadId: Scalars["ID"]
        notepadType: Scalars["String"]
        type: NoteType
    }

export type ContactDetails = {
    __typename?: "ContactDetails"
    email?: Maybe<Scalars["String"]>
    phone?: Maybe<Scalars["String"]>
}

export type ContentCard = {
    cardId: Scalars["ID"]
    patientInitials?: Maybe<Scalars["String"]>
    type: ContentCardType
}

export type ContentCardAction = {
    impressionId: Scalars["ID"]
    label: Scalars["String"]
    type: ContentCardActionTypes
}

export type ContentCardActionNode =
    | ContentCardChatAction
    | ContentCardNavigationAction
    | ContentCardTabAction
    | ContentCardUrlAction
    | MedSavvyWebViewAction
    | MedSavvySwitchMedsAction

export enum ContentCardActionTypes {
    MedSavvyWebViewAction = "MedSavvyWebViewAction",
    MedSavvySwitchMedsAction = "MedSavvySwitchMedsAction",
    ContentCardChatAction = "ContentCardChatAction",
    ContentCardUrlAction = "ContentCardUrlAction",
    ContentCardNavigationAction = "ContentCardNavigationAction",
    ContentCardTabAction = "ContentCardTabAction",
}

export type ContentCardChatAction = ContentCardAction & {
    __typename?: "ContentCardChatAction"
    chat?: Maybe<Scalars["String"]>
    impressionId: Scalars["ID"]
    label: Scalars["String"]
    type: ContentCardActionTypes
}

export type ContentCardConnection = EdgeCollection & {
    __typename?: "ContentCardConnection"
    edges: Array<Maybe<ContentCardEdge>>
    invalidCardCount: Scalars["Int"]
    invalidCardMessages: Array<Maybe<Scalars["String"]>>
    invalidCards: Array<Maybe<ContentCardNode>>
    pageInfo: PageInfo
    totalCount: Scalars["Int"]
}

export type ContentCardEdge = Edge & {
    __typename?: "ContentCardEdge"
    cursor: Scalars["ID"]
    node: ContentCardNode
}

export type ContentCardNavigationAction = ContentCardAction & {
    __typename?: "ContentCardNavigationAction"
    impressionId: Scalars["ID"]
    label: Scalars["String"]
    route: Scalars["String"]
    type: ContentCardActionTypes
}

export type ContentCardNode = InfoCard

export type ContentCardTabAction = ContentCardAction & {
    __typename?: "ContentCardTabAction"
    impressionId: Scalars["ID"]
    label: Scalars["String"]
    tab: Scalars["String"]
    type: ContentCardActionTypes
}

export enum ContentCardType {
    InfoCard = "InfoCard",
}

export type ContentCardUrlAction = ContentCardAction & {
    __typename?: "ContentCardUrlAction"
    impressionId: Scalars["ID"]
    label: Scalars["String"]
    type: ContentCardActionTypes
    url: Scalars["String"]
}

export type CreateAllergyPayload = {
    careSubjectId?: Maybe<Scalars["ID"]>
    category?: Maybe<AllergyCategory>
    medicationDetailsId?: Maybe<Scalars["ID"]>
    name: Scalars["String"]
    severity?: Maybe<AllergySeverity>
    startedAt: Scalars["String"]
    symptoms?: Maybe<Scalars["String"]>
    treatmentMedicationId?: Maybe<Scalars["ID"]>
    treatmentName?: Maybe<Scalars["String"]>
}

export type CreateCareTeamInvitationToSubjectPayload = {
    careSubjectId: Scalars["ID"]
    message?: Maybe<Scalars["String"]>
    unconnectedPerson?: Maybe<UnconnectedPersonDetails>
}

export type CreateConditionNotePayload = {
    conditionId: Scalars["ID"]
    medicationDetailsId: Scalars["ID"]
    notepadId: Scalars["ID"]
}

export type CreateFamilyMemberHistoryPayload = {
    careSubjectId?: Maybe<Scalars["ID"]>
    condition: Scalars["String"]
    onsetAge?: Maybe<Scalars["Int"]>
    relationship: Scalars["String"]
}

export type CreateImageNotePayload = {
    caption?: Maybe<Scalars["String"]>
    data?: Maybe<Scalars["String"]>
    notepadId: Scalars["ID"]
}

export type CreateImmunizationPayload = {
    careSubjectId?: Maybe<Scalars["ID"]>
    location?: Maybe<Scalars["String"]>
    name: Scalars["String"]
    occurrenceDate: Scalars["String"]
    status: Scalars["String"]
    vaccineCode?: Maybe<Scalars["String"]>
    vaccineCodeId?: Maybe<Scalars["String"]>
}

export type CreateLocationNotePayload = {
    city?: Maybe<Scalars["String"]>
    name?: Maybe<Scalars["String"]>
    notepadId: Scalars["ID"]
    postalCode?: Maybe<Scalars["String"]>
    state?: Maybe<Scalars["String"]>
    streetText?: Maybe<Scalars["String"]>
    telephone?: Maybe<Scalars["String"]>
}

export type CreatePagerChatPayload = {
    chatEncounterId: Scalars["ID"]
    chatTarget: ChatTarget
    launchType: LaunchType
    timeInitiated?: Maybe<Scalars["String"]>
}

export type CreateSolicitInvitationPayload = {
    message?: Maybe<Scalars["String"]>
    unconnectedPerson?: Maybe<UnconnectedPersonDetails>
}

export type CreateTextNotePayload = {
    content: Scalars["String"]
    notepadId: Scalars["ID"]
}

export type CreateUserCabinetMedicationPayload = {
    brandStatus?: Maybe<Scalars["String"]>
    careSubjectId?: Maybe<Scalars["ID"]>
    displayName?: Maybe<Scalars["String"]>
    dosage?: Maybe<MedicationDosageInput>
    medicationDetailsId: Scalars["ID"]
    supply?: Maybe<QuantityInput>
}

export type CreateUserEventPayload = {
    careSubjectId?: Maybe<Scalars["ID"]>
    details: Scalars["String"]
    eventDate?: Maybe<Scalars["String"]>
    title: Scalars["String"]
}

export type CreateUserMedicationPayload = {
    brandStatus?: Maybe<Scalars["String"]>
    careSubjectId?: Maybe<Scalars["ID"]>
    displayName?: Maybe<Scalars["String"]>
    dosage?: Maybe<MedicationDosageInput>
    supply?: Maybe<QuantityInput>
}

export type CreateUserPractitionerPayload = {
    careSubjectId?: Maybe<Scalars["ID"]>
    classification?: Maybe<Scalars["String"]>
    clinic: ClinicInput
    name: HumanNameInput
    specialty?: Maybe<Scalars["String"]>
}

export type DailySchedule = {
    __typename?: "DailySchedule"
    day: Scalars["String"]
    hours: Array<Maybe<Scalars["String"]>>
}

export type DeleteConditionNotePayload = {
    medicationDetailsId: Scalars["ID"]
    noteId: Scalars["ID"]
}

export type DeletedConditionNote = {
    __typename?: "DeletedConditionNote"
    id: Scalars["ID"]
    medicationDetailsId?: Maybe<Scalars["ID"]>
    type: NoteType
}

export type DeletedEvent = CareEvent & {
    __typename?: "DeletedEvent"
    careSubjectId: Scalars["ID"]
    eventDate?: Maybe<Scalars["String"]>
    eventId: Scalars["ID"]
    id: Scalars["ID"]
    isDeleted?: Maybe<Scalars["Boolean"]>
    meta: UserMeta
    type: CareEventType
    membershipId?: Maybe<Scalars["String"]>
}

export type DeletedMedication = Medication & {
    __typename?: "DeletedMedication"
    brandStatus?: Maybe<Scalars["String"]>
    careSubjectId: Scalars["ID"]
    displayName?: Maybe<Scalars["String"]>
    dosage?: Maybe<MedicationDosage>
    id: Scalars["ID"]
    lastFillDate?: Maybe<Scalars["String"]>
    medicationId: Scalars["ID"]
    meta: UserMeta
    notepadId: Scalars["ID"]
    notes?: Maybe<NoteConnection>
    pharmacyName?: Maybe<Scalars["String"]>
    pharmacyPhone?: Maybe<Scalars["String"]>
    prescriberName?: Maybe<Scalars["String"]>
    status: MedicationStatusNote
    supply: Quantity
    tags: Array<Maybe<TagNodeType>>
    type: MedicationType
}

export type DeletedNote = {
    __typename?: "DeletedNote"
    id: Scalars["ID"]
    type: NoteType
}

export type DeletedPractitioner = Practitioner & {
    __typename?: "DeletedPractitioner"
    careSubjectId: Scalars["ID"]
    id: Scalars["ID"]
    isDeleted?: Maybe<Scalars["Boolean"]>
    meta: UserMeta
    notepadId: Scalars["ID"]
    notes?: Maybe<NoteConnection>
    practitionerId: Scalars["ID"]
    type: PractitionerType
}

export type DeleteHealthpassWalletItemPayload = {
    walletItemId: Scalars["ID"]
}

export type EasyLoginUser = {
    __typename?: "EasyLoginUser"
    password: Scalars["String"]
    username: Scalars["String"]
}

export type Edge = {
    cursor: Scalars["ID"]
}

export type EdgeCollection = {
    pageInfo: PageInfo
    totalCount: Scalars["Int"]
}

export type EditHealthpassWalletItemPayload = {
    makeDefault?: Maybe<Scalars["Boolean"]>
    walletItem: HealthpassWalletItemInput
}

export type EditStamps = {
    createdAt: Scalars["String"]
    updatedAt: Scalars["String"]
}

export type Either = Error | Success

export enum EncounterType {
    Dental = "Dental",
    Vision = "Vision",
    Medical = "Medical",
    Hospital = "Hospital",
}

export type EnvironmentConfig = {
    __typename?: "EnvironmentConfig"
    buildSupport: BuildSupport
    cognito?: Maybe<CognitoConfig>
    easyLoginUsers?: Maybe<Array<Maybe<EasyLoginUser>>>
    janusEnvironmentName?: Maybe<Scalars["String"]>
    mpulse?: Maybe<MpulseConfig>
    pager?: Maybe<PagerConfig>
    tealium?: Maybe<TealiumConfig>
}

export type Error = {
    __typename?: "Error"
    message?: Maybe<Scalars["String"]>
    severity?: Maybe<Severity>
}

export type EventConnection = EdgeCollection & {
    __typename?: "EventConnection"
    edges: Array<Maybe<CareEventEdge>>
    pageInfo: PageInfo
    totalCount: Scalars["Int"]
}

export type FacilityDetail = ProviderSearchDetail & {
    __typename?: "FacilityDetail"
    address?: Maybe<Address>
    defaultFacility?: Maybe<Scalars["Boolean"]>
    displayName: Scalars["String"]
    distance?: Maybe<Scalars["Float"]>
    inNetwork?: Maybe<Scalars["Boolean"]>
    isAcceptingNewPatients?: Maybe<Scalars["Boolean"]>
    isHandicapAccessible?: Maybe<Scalars["Boolean"]>
    languages?: Maybe<Scalars["String"]>
    networkTier?: Maybe<ProviderSearchTier>
    npi?: Maybe<Scalars["String"]>
    officeHours?: Maybe<Array<Maybe<FacilityOfficeHours>>>
    providerSearchDetailId: Scalars["ID"]
    specialties?: Maybe<Array<Maybe<Scalars["String"]>>>
    telephone?: Maybe<Array<Maybe<Scalars["String"]>>>
    type: ProviderDetailType
}

export type FacilityOfficeHours = {
    __typename?: "FacilityOfficeHours"
    day: Scalars["String"]
    hours: Array<Maybe<Scalars["Int"]>>
}

export type FamilyMemberHistory = {
    careSubjectId: Scalars["ID"]
    condition: Scalars["String"]
    conditionNote?: Maybe<Scalars["String"]>
    id: Scalars["ID"]
    meta: UserMeta
    notepadId: Scalars["ID"]
    notes?: Maybe<NoteConnection>
    onsetAge?: Maybe<Scalars["Int"]>
    relationship: Scalars["String"]
    status: Scalars["String"]
}

export type FamilyMemberHistoryConnection = EdgeCollection & {
    __typename?: "FamilyMemberHistoryConnection"
    edges: Array<Maybe<FamilyMemberHistoryEdge>>
    pageInfo: PageInfo
    totalCount: Scalars["Int"]
}

export type FamilyMemberHistoryEdge = {
    __typename?: "FamilyMemberHistoryEdge"
    cursor: Scalars["ID"]
    edgeId: Scalars["ID"]
    node: FamilyMemberHistoryNode
    nodeType: Scalars["String"]
}

export type FamilyMemberHistoryNode = UserFamilyMemberHistory

export type FamilyMemberHistoryResponse = Error | FamilyMemberHistoryEdge

export type FeatureToggleFlags = {
    __typename?: "FeatureToggleFlags"
    accumulatorsRegence: Scalars["Boolean"]
    chatEnabled: Scalars["Boolean"]
    chatOffHoursCareGuideSupport: Scalars["Boolean"]
    chatPagerEnabled: Scalars["Boolean"]
    chatPagerHealthCoach: Scalars["Boolean"]
    chatPagerNurse: Scalars["Boolean"]
    financialsEnabled: Scalars["Boolean"]
    healthpassBillPay: Scalars["Boolean"]
    healthpassBillView: Scalars["Boolean"]
    hsaHealthequityEnabled: Scalars["Boolean"]
    lhrV2Enabled: Scalars["Boolean"]
    medicationsAskAPharmacistViewEnabled: Scalars["Boolean"]
    medicationsAverageCostViewEnabled: Scalars["Boolean"]
    medicationsCompareTreatmentsViewEnabled: Scalars["Boolean"]
    medicationsGradeEnabled: Scalars["Boolean"]
    medicineCabinet: Scalars["Boolean"]
    profilesOverviewTabEnabled: Scalars["Boolean"]
    providerSearchEnabled: Scalars["Boolean"]
    providerSearchTiersEnabled: Scalars["Boolean"]
    resourcesEnabled: Scalars["Boolean"]
    timeline30Enabled: Scalars["Boolean"]
    tipCards: Scalars["Boolean"]
    urgeHealthPlanLinking: Scalars["Boolean"]
    welcomeShowBeta: Scalars["Boolean"]
}

export type FeedbackInput = {
    insightId: Scalars["ID"]
    impressionId: Scalars["ID"]
    isDismissed: Scalars["Boolean"]
}

export type FeedbackResult = {
    __typename?: "FeedbackResult"
    insightId: Scalars["ID"]
    impressionId: Scalars["ID"]
    isDismissed: Scalars["Boolean"]
}

export type FeedbackWanted = {
    viewerHasLeftFeedback: Scalars["Boolean"]
}

export type FinalizeHealthpassWalletItemPayload = {
    makeDefault?: Maybe<Scalars["Boolean"]>
    walletItem: HealthpassWalletItemInput
}

export type FinancialAccumulator = {
    __typename?: "FinancialAccumulator"
    current?: Maybe<Scalars["Int"]>
    maximum?: Maybe<Scalars["Int"]>
}

export type HasMedicationDetails = {
    condition?: Maybe<MedicationConditionNote>
    conditionId?: Maybe<Scalars["ID"]>
    conditionNoteId?: Maybe<Scalars["ID"]>
    medicationDetailsId: Scalars["ID"]
}

export type HasUserMeta = {
    meta: UserMeta
}

export type HealthpassBill = {
    __typename?: "HealthpassBill"
    amounts: HealthpassBillAmounts
    dates: HealthpassBillDates
    id: Scalars["ID"]
    patient: HealthpassBillPatient
    payee: HealthpassBillPayee
    provider: HealthpassBillProvider
    serviceDetailsId: Scalars["ID"]
    state: HealthpassBillState
}

export type HealthpassBillAmounts = {
    __typename?: "HealthpassBillAmounts"
    paidByInsuranceAndDiscounts: Scalars["String"]
    paidByMember: Scalars["String"]
    providerCharges: Scalars["String"]
    remainingDue: Scalars["String"]
}

export type HealthpassBillDates = {
    __typename?: "HealthpassBillDates"
    estimatedDueDate: Scalars["String"]
    receivedDate: Scalars["String"]
    serviceDate: Scalars["String"]
    stateUpdatedDate: Scalars["String"]
}

export type HealthpassBillPatient = {
    __typename?: "HealthpassBillPatient"
    firstName: Scalars["String"]
}

export type HealthpassBillPayee = {
    __typename?: "HealthpassBillPayee"
    name: Scalars["String"]
}

export type HealthpassBillProvider = {
    __typename?: "HealthpassBillProvider"
    name: Scalars["String"]
}

export enum HealthpassBillState {
    Open = "Open",
    Ignored = "Ignored",
    Closed = "Closed",
}

export type HealthpassPayment = {
    __typename?: "HealthpassPayment"
    amount: Scalars["String"]
    billId: Scalars["ID"]
    card: CardInfo
    createdAt: Scalars["String"]
    estimatedDelivery?: Maybe<Scalars["String"]>
    healthpassBillId: Scalars["ID"]
    healthpassPaymentId: Scalars["ID"]
    id: Scalars["ID"]
    method?: Maybe<Scalars["String"]>
    paidByName?: Maybe<Scalars["String"]>
    payeeName: Scalars["String"]
    payerClaimId?: Maybe<Scalars["String"]>
    paymentCode?: Maybe<Scalars["String"]>
    providerName: Scalars["String"]
    scheduledDate?: Maybe<Scalars["String"]>
    status: PaymentStatus
    type: PaymentType
    updatedAt?: Maybe<Scalars["String"]>
    userNote?: Maybe<Scalars["String"]>
}

export type HealthpassPaymentConfirmation = {
    __typename?: "HealthpassPaymentConfirmation"
    claimId: Scalars["ID"]
    estimatedDelivery?: Maybe<Scalars["String"]>
    id: Scalars["ID"]
    paymentCode: Scalars["ID"]
    status: PaymentStatus
}

export type HealthpassPayments = {
    __typename?: "HealthpassPayments"
    payments?: Maybe<Array<Maybe<HealthpassPayment>>>
}

export type HealthpassServiceDetails = {
    __typename?: "HealthpassServiceDetails"
    claimId: Scalars["String"]
    id: Scalars["ID"]
    originalAmountDue: Scalars["String"]
    serviceDate?: Maybe<Scalars["String"]>
    serviceItems?: Maybe<Array<Maybe<HealthpassServiceDetailsServiceItem>>>
    status: Scalars["Int"]
}

export type HealthpassServiceDetailsServiceItem = {
    __typename?: "HealthpassServiceDetailsServiceItem"
    description: Scalars["String"]
    discount: Scalars["String"]
    insurancePaid: Scalars["String"]
    providerCharges: Scalars["String"]
    yourTotal: Scalars["String"]
}

export type HealthpassWallet = {
    __typename?: "HealthpassWallet"
    walletItems?: Maybe<Array<Maybe<HealthpassWalletItem>>>
}

export type HealthpassWalletItem = {
    __typename?: "HealthpassWalletItem"
    brand?: Maybe<Scalars["String"]>
    cardAlias?: Maybe<Scalars["String"]>
    cardBillingZip?: Maybe<Scalars["String"]>
    cardExpirationMonth?: Maybe<Scalars["String"]>
    cardExpirationYear?: Maybe<Scalars["String"]>
    cardLastFourDigits?: Maybe<Scalars["String"]>
    createdAt: Scalars["String"]
    isDefaultCard: Scalars["Boolean"]
    nameOnCard?: Maybe<Scalars["String"]>
    updatedAt: Scalars["String"]
    walletItemId: Scalars["ID"]
}

export type HealthpassWalletItemInput = {
    cardAlias: Scalars["String"]
    cardBillingZip: Scalars["String"]
    cardExpirationMonth: Scalars["String"]
    cardExpirationYear: Scalars["String"]
    cardLastFourDigits: Scalars["String"]
    nameOnCard: Scalars["String"]
    walletItemId: Scalars["ID"]
}

export type HealthpassWalletItemPaymentStatusesPayload = {
    paymentStatuses: Array<Maybe<PaymentStatusCode>>
    walletItemId: Scalars["ID"]
}

export type HealthpassWalletItemPaymentStatusResponse = {
    __typename?: "HealthpassWalletItemPaymentStatusResponse"
    hasPayments: Scalars["Boolean"]
    statusCode: PaymentStatusCode
}

export type Holiday = {
    __typename?: "Holiday"
    date: Scalars["String"]
    label: Scalars["String"]
}

export enum HospitalCodeType {
    Inpatient = "Inpatient",
    Outpatient = "Outpatient",
}

export type HsaFinancialInfo = {
    __typename?: "HsaFinancialInfo"
    availableBalance?: Maybe<Scalars["Int"]>
    contributions?: Maybe<Scalars["Int"]>
    distributions?: Maybe<Scalars["Int"]>
    status: Scalars["Int"]
    updatedAt?: Maybe<Scalars["String"]>
}

export type HumanName = {
    __typename?: "HumanName"
    family?: Maybe<Scalars["String"]>
    given?: Maybe<Scalars["String"]>
}

export type HumanNameInput = {
    family?: Maybe<Scalars["String"]>
    given?: Maybe<Scalars["String"]>
}

export type ImageNote = HasUserMeta &
    Note & {
        __typename?: "ImageNote"
        caption?: Maybe<Scalars["String"]>
        id: Scalars["ID"]
        meta: UserMeta
        noteId: Scalars["ID"]
        notepadId: Scalars["ID"]
        notepadType: Scalars["String"]
        original?: Maybe<Scalars["String"]>
        thumbSize10?: Maybe<Scalars["String"]>
        thumbSize15?: Maybe<Scalars["String"]>
        thumbSize20?: Maybe<Scalars["String"]>
        thumbSize5?: Maybe<Scalars["String"]>
        type: NoteType
    }

export type Immunization = {
    careSubjectId: Scalars["ID"]
    id: Scalars["ID"]
    location?: Maybe<Scalars["String"]>
    meta: UserMeta
    name: Scalars["String"]
    notepadId: Scalars["ID"]
    notes?: Maybe<NoteConnection>
    occurrenceDate: Scalars["String"]
    status: Scalars["String"]
    vaccineCode?: Maybe<Scalars["String"]>
    vaccineCodeId?: Maybe<Scalars["String"]>
}

export type ImmunizationConnection = EdgeCollection & {
    __typename?: "ImmunizationConnection"
    edges: Array<Maybe<ImmunizationEdge>>
    pageInfo: PageInfo
    totalCount: Scalars["Int"]
}

export type ImmunizationEdge = {
    __typename?: "ImmunizationEdge"
    cursor: Scalars["ID"]
    edgeId: Scalars["ID"]
    node: ImmunizationNode
    nodeType: Scalars["String"]
    sortDate?: Maybe<Scalars["String"]>
}

export type ImmunizationNode = ClaimsImmunization | UserImmunization

export type ImmunizationResponse = Error | ImmunizationEdge

export type InfoCard = ContentCard &
    FeedbackWanted & {
        __typename?: "InfoCard"
        action?: Maybe<ContentCardActionNode>
        cardId: Scalars["ID"]
        category: Scalars["String"]
        icon: Scalars["String"]
        patientInitials?: Maybe<Scalars["String"]>
        subtitle?: Maybe<Scalars["String"]>
        title: Scalars["String"]
        type: ContentCardType
        viewerHasLeftFeedback: Scalars["Boolean"]
    }

export type InitHealthpassWalletItemResponse =
    | Error
    | InitHealthpassWalletItemSuccess

export type InitHealthpassWalletItemSuccess = {
    __typename?: "InitHealthpassWalletItemSuccess"
    mcgToken: Scalars["ID"]
    sessionToken: Scalars["ID"]
    walletItemId: Scalars["ID"]
}

export enum LaunchType {
    TipCard = "TipCard",
    ProviderSearch = "ProviderSearch",
    PushNotification = "PushNotification",
    ChatLandingPage = "ChatLandingPage",
    ChatDetailPage = "ChatDetailPage",
    HelpButton = "HelpButton",
    QuickAction = "QuickAction",
    BillDetails = "BillDetails",
}

export type LinkableService = {
    __typename?: "LinkableService"
    additionalParameters?: Maybe<Scalars["String"]>
    baseUrl?: Maybe<Scalars["String"]>
    capabilities?: Maybe<Array<Maybe<ServiceCapability>>>
    category: ServiceCategory
    clientId?: Maybe<Scalars["String"]>
    details?: Maybe<LinkableServiceDetails>
    developMessage?: Maybe<Scalars["String"]>
    landingRedirectUrl?: Maybe<Scalars["String"]>
    linkMethod: ServiceLinkMethod
    linkableServiceId: Scalars["ID"]
    name: Scalars["String"]
    url?: Maybe<Scalars["String"]>
}

export type LinkableServiceDetails = OAuthLinkingDetails

export type LinkAccountPayload = {
    accountName: Scalars["String"]
    linkableServiceId: Scalars["ID"]
    serviceName: Scalars["String"]
}

export type LinkAccountViaOAuthPayload = {
    accountName: Scalars["String"]
    code: Scalars["String"]
    codeVerifier?: Maybe<Scalars["String"]>
    linkableServiceId: Scalars["ID"]
    redirectUri: Scalars["String"]
}

export type LinkedAccount = {
    __typename?: "LinkedAccount"
    accountName?: Maybe<Scalars["String"]>
    details?: Maybe<LinkedAccountDetails>
    expirationDate?: Maybe<Scalars["String"]>
    formFields?: Maybe<Array<Maybe<LinkedAccountFormField>>>
    history?: Maybe<Array<Maybe<LinkedAccountHistoryState>>>
    linkedAccountId: Scalars["ID"]
    linkedDate: Scalars["String"]
    service: LinkableService
    serviceCapabilities?: Maybe<Array<Maybe<ServiceCapability>>>
    serviceCategory: ServiceCategory
    serviceName: Scalars["String"]
    state: AccountState
    unlinkedDate?: Maybe<Scalars["String"]>
}

export type LinkedAccountDetails = LinkedHealthPlanDetails

export type LinkedAccountFormField = {
    __typename?: "LinkedAccountFormField"
    name?: Maybe<Scalars["String"]>
    value?: Maybe<Scalars["String"]>
}

export type LinkedAccountHistoryState = {
    __typename?: "LinkedAccountHistoryState"
    date?: Maybe<Scalars["String"]>
    state: AccountState
}

export type LinkedHealthPlanDetails = {
    __typename?: "LinkedHealthPlanDetails"
    birthDate?: Maybe<Scalars["String"]>
    firstName?: Maybe<Scalars["String"]>
    groupNumber?: Maybe<Scalars["String"]>
    lastName?: Maybe<Scalars["String"]>
    memberPrefix?: Maybe<Scalars["String"]>
    subscriberId?: Maybe<Scalars["String"]>
}

export type LocationNote = HasUserMeta &
    Note & {
        __typename?: "LocationNote"
        address?: Maybe<Address>
        id: Scalars["ID"]
        meta: UserMeta
        name?: Maybe<Scalars["String"]>
        noteId: Scalars["ID"]
        notepadId: Scalars["ID"]
        notepadType: Scalars["String"]
        telephone?: Maybe<Scalars["String"]>
        type: NoteType
    }

export enum LocationSearchCenterPointType {
    Geo = "Geo",
    Account = "Account",
    Portland = "Portland",
}

export type LocationSearchSuggestion = {
    __typename?: "LocationSearchSuggestion"
    latitude: Scalars["Float"]
    longitude: Scalars["Float"]
    name: Scalars["String"]
    type: LocationType
}

export type LocationSuggestResults = {
    __typename?: "LocationSuggestResults"
    centerPointType?: Maybe<LocationSearchCenterPointType>
    locations: Array<Maybe<LocationSearchSuggestion>>
}

export enum LocationType {
    State = "State",
    County = "County",
    City = "City",
    Address = "Address",
    PostalCode = "PostalCode",
}

export type Medication = {
    brandStatus?: Maybe<Scalars["String"]>
    careSubjectId: Scalars["ID"]
    displayName?: Maybe<Scalars["String"]>
    dosage?: Maybe<MedicationDosage>
    id: Scalars["ID"]
    familyMemberId?: Maybe<Scalars["String"]>
    lastFillDate?: Maybe<Scalars["String"]>
    medicationId: Scalars["ID"]
    meta: UserMeta
    notepadId: Scalars["ID"]
    notes?: Maybe<NoteConnection>
    pharmacyName?: Maybe<Scalars["String"]>
    pharmacyPhone?: Maybe<Scalars["String"]>
    prescriberName?: Maybe<Scalars["String"]>
    medicationNdcs?: Maybe<Array<Maybe<Scalars["String"]>>>
    status: MedicationStatusNote
    supply: Quantity
    tags: Array<Maybe<TagNodeType>>
    type: MedicationType
}

export type MedicationCondition = {
    __typename?: "MedicationCondition"
    conditionId: Scalars["ID"]
    conditionName: Scalars["String"]
    grade?: Maybe<Scalars["String"]>
    medicationDetailsId: Scalars["ID"]
}

export type MedicationConditionNote = HasUserMeta &
    Note & {
        __typename?: "MedicationConditionNote"
        conditionId: Scalars["ID"]
        conditionName: Scalars["String"]
        id: Scalars["ID"]
        medicationDetailsId: Scalars["ID"]
        meta: UserMeta
        noteId: Scalars["ID"]
        notepadId: Scalars["ID"]
        notepadType: Scalars["String"]
        type: NoteType
    }

export type MedicationConnection = EdgeCollection & {
    __typename?: "MedicationConnection"
    edges: Array<Maybe<MedicationEdge>>
    pageInfo: PageInfo
    totalCount: Scalars["Int"]
}

export type MedicationDetails = {
    __typename?: "MedicationDetails"
    clinicalSummary?: Maybe<Scalars["String"]>
    conditionName?: Maybe<Scalars["String"]>
    cost?: Maybe<Scalars["Int"]>
    costDescription?: Maybe<Scalars["String"]>
    costFrequency?: Maybe<Scalars["String"]>
    effectivenessInfo?: Maybe<Scalars["String"]>
    grade?: Maybe<Scalars["String"]>
    gradeDescription?: Maybe<Scalars["String"]>
    medSavvyLinks: MedSavvyLinks
    medicationDetailsId: Scalars["ID"]
    name: Scalars["String"]
    safetyInfo?: Maybe<Scalars["String"]>
    secondaryName?: Maybe<Scalars["String"]>
    sideEffects: Array<Maybe<MedicationSideEffects>>
}

export type MedicationDosage = {
    __typename?: "MedicationDosage"
    description?: Maybe<Scalars["String"]>
    route?: Maybe<Scalars["String"]>
    routeCategory?: Maybe<RouteCategory>
}

export type MedicationDosageInput = {
    description?: Maybe<Scalars["String"]>
    route?: Maybe<Scalars["String"]>
}

export type MedicationEdge = {
    __typename?: "MedicationEdge"
    cursor: Scalars["ID"]
    edgeId: Scalars["ID"]
    node: MedicationNode
    nodeType?: Maybe<MedicationType>
    sortDate?: Maybe<Scalars["String"]>
}

export type MedicationEvent = CareEvent &
    HasUserMeta &
    Notepad &
    Tags & {
        __typename?: "MedicationEvent"
        careSubjectId: Scalars["ID"]
        eventDate?: Maybe<Scalars["String"]>
        eventId: Scalars["ID"]
        id: Scalars["ID"]
        isDeleted?: Maybe<Scalars["Boolean"]>
        medication: MedicationNode
        medicationId: Scalars["ID"]
        medicationType: MedicationType
        meta: UserMeta
        notepadId: Scalars["ID"]
        notes?: Maybe<NoteConnection>
        quantity?: Maybe<Quantity>
        supply?: Maybe<Quantity>
        supplyText?: Maybe<Scalars["String"]>
        tags: Array<Maybe<TagNodeType>>
        type: CareEventType
        membershipId?: Maybe<Scalars["String"]>
    }

export type MedicationEventTag = Tag & {
    __typename?: "MedicationEventTag"
    createdAt: Scalars["String"]
    expiresAt?: Maybe<Scalars["String"]>
    id: Scalars["ID"]
    identityId: Scalars["ID"]
    itemId: Scalars["ID"]
    itemType: Scalars["String"]
    subjectId: Scalars["ID"]
    type: NotificationType
    updatedAt: Scalars["String"]
}

export type MedicationListItem = {
    __typename?: "MedicationListItem"
    medicationDetailsId: Scalars["ID"]
    name: Scalars["String"]
}

export type MedicationNode =
    | CabinetMedication
    | ClaimsMedication
    | DeletedMedication
    | UserCabinetMedication
    | UserMedication

export type MedicationsAndConditionsResults = {
    __typename?: "MedicationsAndConditionsResults"
    conditions?: Maybe<Array<Maybe<Condition>>>
    medications?: Maybe<Array<Maybe<MedicationListItem>>>
}

export type MedicationSideEffects = {
    __typename?: "MedicationSideEffects"
    common: Scalars["Boolean"]
    name: Scalars["String"]
    severe: Scalars["Boolean"]
}

export enum MedicationStatus {
    Current = "Current",
    Past = "Past",
    Allergic = "Allergic",
}

export type MedicationStatusNote = HasUserMeta &
    Note & {
        __typename?: "MedicationStatusNote"
        id: Scalars["ID"]
        meta: UserMeta
        noteId: Scalars["ID"]
        notepadId: Scalars["ID"]
        notepadType: Scalars["String"]
        type: NoteType
        value: MedicationStatus
    }

export enum MedicationType {
    UserMedication = "UserMedication",
    ClaimsMedication = "ClaimsMedication",
    CabinetMedication = "CabinetMedication",
    UserCabinetMedication = "UserCabinetMedication",
    DeletedMedication = "DeletedMedication",
}

export type MedicineCabinetConnection = EdgeCollection & {
    __typename?: "MedicineCabinetConnection"
    edges: Array<Maybe<MedicationEdge>>
    pageInfo: PageInfo
    totalCount: Scalars["Int"]
}

export type MedSavvyLinks = {
    __typename?: "MedSavvyLinks"
    compare?: Maybe<Scalars["String"]>
    cost: Scalars["String"]
    pharmacist: Scalars["String"]
}

export type MedSavvyWebViewAction = ContentCardAction & {
    __typename?: "MedSavvyWebViewAction"
    impressionId: Scalars["ID"]
    label: Scalars["String"]
    route: Scalars["String"]
    type: ContentCardActionTypes
}

export type MedSavvySwitchMedsAction = ContentCardAction & {
    __typename?: "MedSavvySwitchMedsAction"
    impressionId: Scalars["ID"]
    label: Scalars["String"]
    campaignId: Scalars["String"]
    type: ContentCardActionTypes
}

export type MpulseConfig = {
    __typename?: "MpulseConfig"
    accountId: Scalars["String"]
}

export type MutationRoot = {
    __typename?: "MutationRoot"
    acceptCareTeamInvitation?: Maybe<AcceptCareTeamInvitationResponse>
    cancelHealthpassPayment?: Maybe<Either>
    createAllergy?: Maybe<AllergyEdge>
    createCareTeamInvitationToSubject?: Maybe<CareTeamInvitation>
    createConditionNote?: Maybe<ConditionNote>
    createFamilyMemberHistory?: Maybe<FamilyMemberHistoryEdge>
    createFeedback?: Maybe<FeedbackResult>
    createImageNote?: Maybe<ImageNote>
    createImmunization?: Maybe<ImmunizationEdge>
    createLocationNote?: Maybe<LocationNote>
    createPagerChat?: Maybe<PagerChatDetails>
    createSolicitInvitation?: Maybe<SolicitInvitationResponse>
    createTextNote?: Maybe<TextNote>
    createUserCabinetMedication?: Maybe<MedicationEdge>
    createUserEvent?: Maybe<CareEventEdge>
    createUserMedication?: Maybe<MedicationEdge>
    createUserPractitioner?: Maybe<PractitionerEdge>
    deleteAllergy?: Maybe<Either>
    deleteCareEvent?: Maybe<CareEventEdge>
    deleteConditionNote?: Maybe<DeletedConditionNote>
    deleteFamilyMemberHistory?: Maybe<Either>
    deleteHealthpassWalletItem?: Maybe<Either>
    deleteImmunization?: Maybe<Either>
    deleteMedication?: Maybe<MedicationEdge>
    deleteNote?: Maybe<DeletedNote>
    deletePractitioner?: Maybe<PractitionerEdge>
    dismissNotification?: Maybe<Either>
    editHealthpassWalletItem?: Maybe<Either>
    finalizeHealthpassWalletItem?: Maybe<Either>
    ignoreHealthpassBill?: Maybe<Either>
    initHealthpassWalletItem?: Maybe<InitHealthpassWalletItemResponse>
    linkAccount?: Maybe<LinkedAccount>
    linkAccountViaOAuth?: Maybe<LinkedAccount>
    linkToDoctorOnDemand?: Maybe<LinkAccountPayload>
    recordHealthpassManualPayment?: Maybe<HealthpassPaymentConfirmation>
    removeCareTeamMember?: Maybe<Either>
    reopenHealthpassBill?: Maybe<Either>
    reportPagerChatInitiated?: Maybe<PagerChatDetails>
    reportPagerEncounterCreated?: Maybe<Either>
    rescindCareTeamInvitation?: Maybe<CareTeamInvitation>
    resendCareTeamInvitation?: Maybe<CareTeamInvitation>
    savePractitioner?: Maybe<PractitionerEdge>
    scheduleHealthpassCardPayment?: Maybe<HealthpassPaymentConfirmation>
    setMedicationCondition?: Maybe<MedicationConditionNote>
    setMedicationStatus?: Maybe<MedicationStatusNote>
    unlinkAccount?: Maybe<LinkedAccount>
    updateAllergy?: Maybe<AllergyEdge>
    updateConditionNote?: Maybe<ConditionNote>
    updateFamilyMemberHistory?: Maybe<FamilyMemberHistoryEdge>
    updateHealthpassCardPayment?: Maybe<Either>
    updateImageNote?: Maybe<ImageNote>
    updateImmunization?: Maybe<ImmunizationEdge>
    updateLocationNote?: Maybe<LocationNote>
    updateTextNote?: Maybe<TextNote>
    updateUserCabinetMedication?: Maybe<MedicationEdge>
    updateUserEvent?: Maybe<CareEventEdge>
    updateUserMedication?: Maybe<MedicationEdge>
    updateUserPractitioner?: Maybe<PractitionerEdge>
}

export type MutationRootAcceptCareTeamInvitationArgs = {
    careSubjectBirthDate: Scalars["String"]
    careTeamInvitationId: Scalars["ID"]
    confirmationCode: Scalars["String"]
}

export type MutationRootCancelHealthpassPaymentArgs = {
    id: Scalars["ID"]
}

export type MutationRootCreateAllergyArgs = {
    input: CreateAllergyPayload
}

export type MutationRootCreateCareTeamInvitationToSubjectArgs = {
    input: CreateCareTeamInvitationToSubjectPayload
}

export type MutationRootCreateConditionNoteArgs = {
    input: CreateConditionNotePayload
}

export type MutationRootCreateFamilyMemberHistoryArgs = {
    input: CreateFamilyMemberHistoryPayload
}

export type MutationRootCreateFeedbackArgs = {
    input: FeedbackInput
}

export type MutationRootCreateImageNoteArgs = {
    input: CreateImageNotePayload
}

export type MutationRootCreateImmunizationArgs = {
    input: CreateImmunizationPayload
}

export type MutationRootCreateLocationNoteArgs = {
    input: CreateLocationNotePayload
}

export type MutationRootCreatePagerChatArgs = {
    input: CreatePagerChatPayload
}

export type MutationRootCreateSolicitInvitationArgs = {
    input: CreateSolicitInvitationPayload
}

export type MutationRootCreateTextNoteArgs = {
    input: CreateTextNotePayload
}

export type MutationRootCreateUserCabinetMedicationArgs = {
    input: CreateUserCabinetMedicationPayload
}

export type MutationRootCreateUserEventArgs = {
    input: CreateUserEventPayload
}

export type MutationRootCreateUserMedicationArgs = {
    input: CreateUserMedicationPayload
}

export type MutationRootCreateUserPractitionerArgs = {
    input: CreateUserPractitionerPayload
}

export type MutationRootDeleteAllergyArgs = {
    id: Scalars["ID"]
}

export type MutationRootDeleteCareEventArgs = {
    eventId: Scalars["ID"]
}

export type MutationRootDeleteConditionNoteArgs = {
    input: DeleteConditionNotePayload
}

export type MutationRootDeleteFamilyMemberHistoryArgs = {
    id: Scalars["ID"]
}

export type MutationRootDeleteHealthpassWalletItemArgs = {
    input: DeleteHealthpassWalletItemPayload
}

export type MutationRootDeleteImmunizationArgs = {
    id: Scalars["ID"]
}

export type MutationRootDeleteMedicationArgs = {
    medicationId: Scalars["ID"]
}

export type MutationRootDeleteNoteArgs = {
    noteId: Scalars["ID"]
}

export type MutationRootDeletePractitionerArgs = {
    practitionerId: Scalars["ID"]
}

export type MutationRootDismissNotificationArgs = {
    id: Scalars["ID"]
}

export type MutationRootEditHealthpassWalletItemArgs = {
    input: EditHealthpassWalletItemPayload
}

export type MutationRootFinalizeHealthpassWalletItemArgs = {
    input: FinalizeHealthpassWalletItemPayload
}

export type MutationRootIgnoreHealthpassBillArgs = {
    id: Scalars["ID"]
}

export type MutationRootLinkAccountArgs = {
    input: LinkAccountPayload
}

export type MutationRootLinkAccountViaOAuthArgs = {
    input: LinkAccountViaOAuthPayload
}

export type MutationRootRecordHealthpassManualPaymentArgs = {
    input: RecordHealthpassManualPaymentPayload
}

export type MutationRootRemoveCareTeamMemberArgs = {
    careTeamMemberId: Scalars["ID"]
}

export type MutationRootReopenHealthpassBillArgs = {
    id: Scalars["ID"]
}

export type MutationRootReportPagerChatInitiatedArgs = {
    input: ReportPagerChatInitiatedPayload
}

export type MutationRootReportPagerEncounterCreatedArgs = {
    input: ReportPagerEncounterCreatedPayload
}

export type MutationRootRescindCareTeamInvitationArgs = {
    careTeamInvitationId: Scalars["ID"]
}

export type MutationRootResendCareTeamInvitationArgs = {
    careTeamInvitationId: Scalars["ID"]
}

export type MutationRootSavePractitionerArgs = {
    input: SavedPractitionerPayload
}

export type MutationRootScheduleHealthpassCardPaymentArgs = {
    input: ScheduleHealthpassCardPaymentPayload
}

export type MutationRootSetMedicationConditionArgs = {
    input: SetMedicationConditionPayload
}

export type MutationRootSetMedicationStatusArgs = {
    input: SetMedicationStatusPayload
}

export type MutationRootUnlinkAccountArgs = {
    input: UnlinkAccountPayload
}

export type MutationRootUpdateAllergyArgs = {
    input: UpdateAllergyPayload
}

export type MutationRootUpdateConditionNoteArgs = {
    input: UpdateConditionNotePayload
}

export type MutationRootUpdateFamilyMemberHistoryArgs = {
    input: UpdateFamilyMemberHistoryPayload
}

export type MutationRootUpdateHealthpassCardPaymentArgs = {
    input: UpdateHealthpassCardPaymentPayload
}

export type MutationRootUpdateImageNoteArgs = {
    input: UpdateImageNotePayload
}

export type MutationRootUpdateImmunizationArgs = {
    input: UpdateImmunizationPayload
}

export type MutationRootUpdateLocationNoteArgs = {
    input: UpdateLocationNotePayload
}

export type MutationRootUpdateTextNoteArgs = {
    input: UpdateTextNotePayload
}

export type MutationRootUpdateUserCabinetMedicationArgs = {
    input: UpdateUserCabinetMedicationPayload
}

export type MutationRootUpdateUserEventArgs = {
    input: UpdateUserEventPayload
}

export type MutationRootUpdateUserMedicationArgs = {
    input: UpdateUserMedicationPayload
}

export type MutationRootUpdateUserPractitionerArgs = {
    input: UpdateUserPractitionerPayload
}

export type MutationRootLinkToDoctorOnDemandArgs = {
    input: LinkAccountPayload
}

export type Note = {
    id: Scalars["ID"]
    meta: UserMeta
    noteId: Scalars["ID"]
    notepadId: Scalars["ID"]
    notepadType: Scalars["String"]
    type: NoteType
}

export type NoteConnection = EdgeCollection & {
    __typename?: "NoteConnection"
    edges: Array<Maybe<NoteEdge>>
    pageInfo: PageInfo
    totalCount: Scalars["Int"]
}

export type NoteEdge = Edge & {
    __typename?: "NoteEdge"
    cursor: Scalars["ID"]
    node: NoteNodeType
    nodeType: Scalars["String"]
}

export type NoteNodeType =
    | ConditionNote
    | DeletedNote
    | ImageNote
    | LocationNote
    | TextNote

export type Notepad = {
    notepadId: Scalars["ID"]
    notes?: Maybe<NoteConnection>
}

export enum NoteType {
    TextNote = "TextNote",
    ConditionNote = "ConditionNote",
    DeletedNote = "DeletedNote",
    ImageNote = "ImageNote",
    LocationNote = "LocationNote",
}

export type Notification = {
    __typename?: "Notification"
    createdAt: Scalars["String"]
    expiresAt?: Maybe<Scalars["String"]>
    id: Scalars["ID"]
    identityId: Scalars["ID"]
    itemId: Scalars["ID"]
    itemType: Scalars["String"]
    subjectId: Scalars["ID"]
    type: NotificationType
    updatedAt: Scalars["String"]
}

export type NotificationDotBranch = {
    __typename?: "NotificationDotBranch"
    branchName: Scalars["String"]
    count: Scalars["Int"]
    subjectId?: Maybe<Scalars["String"]>
}

export type NotificationDotTree = {
    __typename?: "NotificationDotTree"
    branches?: Maybe<Array<Maybe<NotificationDotBranch>>>
}

export type Notifications = {
    __typename?: "Notifications"
    notifications?: Maybe<Array<Maybe<Notification>>>
}

export enum NotificationType {
    New = "New",
    Updated = "Updated",
}

export type OAuthLinkingDetails = {
    __typename?: "OAuthLinkingDetails"
    additionalParameters?: Maybe<Scalars["String"]>
    baseUrl: Scalars["String"]
    clientId: Scalars["String"]
    mobileLandingUrl?: Maybe<Scalars["String"]>
    scopes: Array<Maybe<Scalars["String"]>>
}

export type PageInfo = {
    __typename?: "PageInfo"
    endCursor?: Maybe<Scalars["ID"]>
    hasNextPage: Scalars["Boolean"]
    startCursor?: Maybe<Scalars["ID"]>
}

export type PagerChatDetails = {
    __typename?: "PagerChatDetails"
    caseId: Scalars["String"]
    caseNumber: Scalars["String"]
}

export type PagerConfig = {
    __typename?: "PagerConfig"
    apiKeyAndroid: Scalars["String"]
    apiKeyIos: Scalars["String"]
}

export type PagerContactInfo = {
    __typename?: "PagerContactInfo"
    chatTarget: ChatTarget
    contact?: Maybe<ContactDetails>
    dailySchedule: Array<Maybe<DailySchedule>>
    holidays: Array<Maybe<Holiday>>
    offHoursWaitTimeMinutes?: Maybe<Scalars["Int"]>
}

export type PagerContacts = {
    __typename?: "PagerContacts"
    pagerContactInfo?: Maybe<PagerContactInfo>
}

export type PagerContactsPagerContactInfoArgs = {
    chatTarget: ChatTarget
}

export type PagerInitDetails = {
    __typename?: "PagerInitDetails"
    demographics: Scalars["String"]
    token: Scalars["String"]
}

export type PaymentStatus = {
    __typename?: "PaymentStatus"
    code: PaymentStatusCode
    longDescription?: Maybe<Scalars["String"]>
    shortDescription?: Maybe<Scalars["String"]>
}

export enum PaymentStatusCode {
    Recorded = "Recorded",
    Scheduled = "Scheduled",
    Accepted = "Accepted",
    Failed = "Failed",
    Cancelled = "Cancelled",
    Processing = "Processing",
    Unknown = "Unknown",
}

export enum PaymentType {
    Manual = "Manual",
    CreditCard = "CreditCard",
}

export type PlanEligibilities = {
    __typename?: "PlanEligibilities"
    isLinkedToHealthPlan: Scalars["Boolean"]
    isMedicallyEligible: Scalars["Boolean"]
}

export type PlanFinancialInfo = {
    __typename?: "PlanFinancialInfo"
    familyDeductible?: Maybe<FinancialAccumulator>
    familyOutOfPocket?: Maybe<FinancialAccumulator>
    individualDeductible?: Maybe<FinancialAccumulator>
    individualOutOfPocket?: Maybe<FinancialAccumulator>
    recentDeductibleContribution?: Maybe<Scalars["Int"]>
    recentOutOfPocketContribution?: Maybe<Scalars["Int"]>
    status: Scalars["Int"]
    updatedAt?: Maybe<Scalars["String"]>
}

export type PlanInformation = {
    __typename?: "PlanInformation"
    availableBenefits: Array<Maybe<AvailableBenefit>>
}

export type Practitioner = {
    careSubjectId: Scalars["ID"]
    id: Scalars["ID"]
    isDeleted?: Maybe<Scalars["Boolean"]>
    meta: UserMeta
    notepadId: Scalars["ID"]
    notes?: Maybe<NoteConnection>
    practitionerId: Scalars["ID"]
    type: PractitionerType
}

export type PractitionerConnection = EdgeCollection & {
    __typename?: "PractitionerConnection"
    edges: Array<Maybe<PractitionerEdge>>
    pageInfo: PageInfo
    totalCount: Scalars["Int"]
}

export type PractitionerDetail = ProviderSearchDetail & {
    __typename?: "PractitionerDetail"
    classification?: Maybe<Scalars["String"]>
    displayName: Scalars["String"]
    facilities?: Maybe<Array<Maybe<FacilityDetail>>>
    facilityCount?: Maybe<Scalars["Int"]>
    gender?: Maybe<Scalars["String"]>
    inNetwork?: Maybe<Scalars["Boolean"]>
    languages?: Maybe<Scalars["String"]>
    name: HumanName
    npi?: Maybe<Scalars["String"]>
    providerSearchDetailId: Scalars["ID"]
    specialties?: Maybe<Array<Maybe<Scalars["String"]>>>
    type: ProviderDetailType
}

export type PractitionerEdge = Edge & {
    __typename?: "PractitionerEdge"
    cursor: Scalars["ID"]
    edgeId: Scalars["ID"]
    node: PractitionerNode
    nodeType: PractitionerType
    sortDate?: Maybe<Scalars["String"]>
}

export type PractitionerEvent = CareEvent &
    Notepad &
    Tags & {
        __typename?: "PractitionerEvent"
        careSubjectId: Scalars["ID"]
        diagnosisDescriptions?: Maybe<Array<Maybe<Scalars["String"]>>>
        encounterType?: Maybe<EncounterType>
        enhancements?: Maybe<ClaimEnhancements>
        eventDate?: Maybe<Scalars["String"]>
        eventId: Scalars["ID"]
        hospitalCode?: Maybe<HospitalCodeType>
        id: Scalars["ID"]
        isDeleted?: Maybe<Scalars["Boolean"]>
        meta: UserMeta
        notepadId: Scalars["ID"]
        notes?: Maybe<NoteConnection>
        practitioner: PractitionerNode
        practitionerId: Scalars["ID"]
        practitionerType: PractitionerType
        procedureDescriptions?: Maybe<Array<Maybe<Scalars["String"]>>>
        tags: Array<Maybe<TagNodeType>>
        type: CareEventType
        membershipId?: Maybe<Scalars["String"]>
    }

export type PractitionerEventTag = Tag & {
    __typename?: "PractitionerEventTag"
    createdAt: Scalars["String"]
    expiresAt?: Maybe<Scalars["String"]>
    id: Scalars["ID"]
    identityId: Scalars["ID"]
    itemId: Scalars["ID"]
    itemType: Scalars["String"]
    subjectId: Scalars["ID"]
    type: NotificationType
    updatedAt: Scalars["String"]
}

export type PractitionerNode =
    | ClaimsPractitioner
    | DeletedPractitioner
    | SavedPractitioner
    | UserPractitioner

export enum PractitionerType {
    UserPractitioner = "UserPractitioner",
    SavedPractitioner = "SavedPractitioner",
    ClaimsPractitioner = "ClaimsPractitioner",
    DeletedPractitioner = "DeletedPractitioner",
}

export enum ProviderDetailType {
    PractitionerDetail = "PractitionerDetail",
    FacilityDetail = "FacilityDetail",
}

export type ProviderEnhancements = {
    __typename?: "ProviderEnhancements"
    displayName1?: Maybe<Scalars["String"]>
    displayName2?: Maybe<Scalars["String"]>
    specialty1?: Maybe<Scalars["String"]>
    taxName1?: Maybe<Scalars["String"]>
    taxName2?: Maybe<Scalars["String"]>
}

export type ProviderFilter = {
    __typename?: "ProviderFilter"
    label: Scalars["String"]
    options?: Maybe<Array<Maybe<ProviderFilterOption>>>
    type: ProviderFilterType
}

export type ProviderFilterOption = {
    __typename?: "ProviderFilterOption"
    count: Scalars["Int"]
    label: Scalars["String"]
    providerFilterOptionId: Scalars["ID"]
}

export enum ProviderFilterType {
    Specialty = "Specialty",
    Gender = "Gender",
    Language = "Language",
    AcceptingNewPatients = "AcceptingNewPatients",
    NetworkTiersCalculated = "NetworkTiersCalculated",
}

export type ProviderSearchConfig = {
    __typename?: "ProviderSearchConfig"
    quickSearchOptions?: Maybe<Array<Maybe<Scalars["String"]>>>
}

export type ProviderSearchDetail = {
    displayName: Scalars["String"]
    inNetwork?: Maybe<Scalars["Boolean"]>
    languages?: Maybe<Scalars["String"]>
    npi?: Maybe<Scalars["String"]>
    providerSearchDetailId: Scalars["ID"]
    specialties?: Maybe<Array<Maybe<Scalars["String"]>>>
    type: ProviderDetailType
}

export type ProviderSearchResults = {
    __typename?: "ProviderSearchResults"
    availableFilters?: Maybe<Array<Maybe<ProviderFilter>>>
    morePages?: Maybe<Scalars["Boolean"]>
    nextPageNumber?: Maybe<Scalars["Int"]>
    pageNumber: Scalars["Int"]
    pageResultCount: Scalars["Int"]
    results: Array<Maybe<ProviderSearchDetail>>
    totalResultCount: Scalars["Int"]
}

export enum ProviderSearchSortType {
    BestMatch = "BestMatch",
    Distance = "Distance",
    NameAz = "NameAz",
    NameZa = "NameZa",
    TierLevel = "TierLevel",
}

export type ProviderSearchSuggestion = {
    displayName: Scalars["String"]
    distance?: Maybe<Scalars["Float"]>
    searchType: ProviderSearchType
}

export type ProviderSearchTier = {
    __typename?: "ProviderSearchTier"
    description?: Maybe<Scalars["String"]>
    icon?: Maybe<Scalars["String"]>
    name?: Maybe<Scalars["String"]>
    tierNumber?: Maybe<Scalars["Int"]>
}

export enum ProviderSearchType {
    ProviderName = "ProviderName",
    Specialty = "Specialty",
    FacilityType = "FacilityType",
    SpecialtyAndFacilityType = "SpecialtyAndFacilityType",
}

export type ProviderSuggestion = ProviderSearchSuggestion & {
    __typename?: "ProviderSuggestion"
    displayName: Scalars["String"]
    distance?: Maybe<Scalars["Float"]>
    providerSearchDetailId: Scalars["ID"]
    searchType: ProviderSearchType
}

export type ProviderSuggestResults = {
    __typename?: "ProviderSuggestResults"
    facilities: Array<Maybe<ProviderSuggestion>>
    facilityTypes: Array<Maybe<CategorySuggestion>>
    practitioners: Array<Maybe<ProviderSuggestion>>
    specialties: Array<Maybe<CategorySuggestion>>
}

export type Quantity = {
    __typename?: "Quantity"
    unit?: Maybe<Scalars["String"]>
    value?: Maybe<Scalars["Int"]>
}

export type QuantityInput = {
    unit?: Maybe<Scalars["String"]>
    value?: Maybe<Scalars["Int"]>
}

export enum QueryCategory {
    AppConfig = "AppConfig",
    CareEvent = "CareEvent",
    CareSubject = "CareSubject",
    Financials = "Financials",
    Medication = "Medication",
    MedSavvy = "MedSavvy",
    Meta = "Meta",
    Notes = "Notes",
    Practitioner = "Practitioner",
    Tips = "Tips",
    UserOnboarding = "UserOnboarding",
    Pager = "Pager",
}

export type QueryExample = {
    __typename?: "QueryExample"
    categories?: Maybe<Array<Maybe<QueryCategory>>>
    description?: Maybe<Scalars["String"]>
    query: Scalars["String"]
    title: Scalars["String"]
    variables?: Maybe<Scalars["String"]>
}

export type QueryExampleConnection = {
    __typename?: "QueryExampleConnection"
    edges: Array<Maybe<QueryExampleEdge>>
}

export type QueryExampleEdge = {
    __typename?: "QueryExampleEdge"
    node?: Maybe<QueryExample>
}

export type QueryRoot = {
    __typename?: "QueryRoot"
    allConditions?: Maybe<Array<Maybe<Condition>>>
    conditionDetail?: Maybe<ConditionDetail>
    conditions?: Maybe<Array<Maybe<Condition>>>
    environmentConfig: EnvironmentConfig
    fuzzySearchMedicationsAndConditions?: Maybe<MedicationsAndConditionsResults>
    invitationDetails?: Maybe<CareTeamInvitationDetails>
    medicationConditions?: Maybe<Array<Maybe<MedicationCondition>>>
    medicationDetails?: Maybe<MedicationDetails>
    pagerContacts?: Maybe<PagerContacts>
    providerSearchConfig?: Maybe<ProviderSearchConfig>
    queryExamples: QueryExampleConnection
    searchMedicationsAndConditions?: Maybe<MedicationsAndConditionsResults>
    suggestLocations?: Maybe<LocationSuggestResults>
    suggestedAllergies: Array<Maybe<Scalars["String"]>>
    suggestedFamilyMemberHistoryCancerTypes: Array<Maybe<Scalars["String"]>>
    suggestedFamilyMemberHistoryConditions: Array<Maybe<Scalars["String"]>>
    suggestedImmunizations: Array<Maybe<Scalars["String"]>>
    user: UserContext
    viewer: UserContext
}

export type QueryRootConditionDetailArgs = {
    conditionId: Scalars["ID"]
}

export type QueryRootConditionsArgs = {
    nameFragment: Scalars["String"]
}

export type QueryRootFuzzySearchMedicationsAndConditionsArgs = {
    nameFragment: Scalars["String"]
}

export type QueryRootInvitationDetailsArgs = {
    invitationCode: Scalars["ID"]
}

export type QueryRootMedicationConditionsArgs = {
    medicationDetailsId: Scalars["ID"]
}

export type QueryRootMedicationDetailsArgs = {
    medicationDetailsId: Scalars["ID"]
}

export type QueryRootSearchMedicationsAndConditionsArgs = {
    nameFragment: Scalars["String"]
}

export type QueryRootSuggestLocationsArgs = {
    latitude?: Maybe<Scalars["Float"]>
    longitude?: Maybe<Scalars["Float"]>
    searchTerm: Scalars["String"]
}

export type QueryRootUserArgs = {
    identityId: Scalars["ID"]
}

export type Receipt = {
    __typename?: "Receipt"
    id: Scalars["ID"]
}

export type RecordHealthpassManualPaymentPayload = {
    amount: Scalars["String"]
    id: Scalars["ID"]
    memoNote?: Maybe<Scalars["String"]>
    methodNote?: Maybe<Scalars["String"]>
}

export type ReportPagerChatInitiatedPayload = {
    chatTarget: ChatTarget
    launchType: LaunchType
    timeInitiated?: Maybe<Scalars["String"]>
}

export type ReportPagerEncounterCreatedPayload = {
    chatEncounterId: Scalars["ID"]
}

export enum RouteCategory {
    Oral = "Oral",
    Inhalant = "Inhalant",
    Injectable = "Injectable",
    Nasal = "Nasal",
    Topical = "Topical",
    Other = "Other",
}

export type SavedPractitioner = HasUserMeta &
    Notepad &
    Practitioner & {
        __typename?: "SavedPractitioner"
        careSubjectId: Scalars["ID"]
        classification?: Maybe<Scalars["String"]>
        clinic?: Maybe<Clinic>
        id: Scalars["ID"]
        isDeleted?: Maybe<Scalars["Boolean"]>
        locations?: Maybe<Array<Maybe<LocationNote>>>
        meta: UserMeta
        name?: Maybe<HumanName>
        notepadId: Scalars["ID"]
        notes?: Maybe<NoteConnection>
        npi?: Maybe<Scalars["String"]>
        practitionerId: Scalars["ID"]
        specialties?: Maybe<Array<Maybe<Scalars["String"]>>>
        type: PractitionerType
    }

export type SavedPractitionerPayload = {
    careSubjectId?: Maybe<Scalars["ID"]>
    classification?: Maybe<Scalars["String"]>
    clinic?: Maybe<ClinicInput>
    name?: Maybe<HumanNameInput>
    npi?: Maybe<Scalars["String"]>
    specialties?: Maybe<Array<Maybe<Scalars["String"]>>>
}

export type ScheduleHealthpassCardPaymentPayload = {
    amount: Scalars["String"]
    id: Scalars["ID"]
    paymentDate: Scalars["String"]
    walletId: Scalars["ID"]
}

export enum ServiceCapability {
    FinancialData = "FinancialData",
    CareEvents = "CareEvents",
}

export enum ServiceCategory {
    HealthPlan = "HealthPlan",
    Hsa = "HSA",
}

export enum ServiceLinkMethod {
    Custom = "CUSTOM",
    Oauth = "OAUTH",
}

export type SetMedicationConditionPayload = {
    conditionId: Scalars["ID"]
    medicationDetailsId: Scalars["ID"]
    notepadId: Scalars["ID"]
}

export type SetMedicationStatusPayload = {
    notepadId: Scalars["ID"]
    value: MedicationStatus
}

export enum Severity {
    Retry = "Retry",
    Fatal = "Fatal",
}

export type SolicitInvitation = {
    __typename?: "SolicitInvitation"
    message?: Maybe<Scalars["String"]>
    soliciteeEmailAddress: Scalars["String"]
    soliciteeName?: Maybe<HumanName>
    solicitorIdentityId: Scalars["ID"]
    solicitorName?: Maybe<HumanName>
}

export type SolicitInvitationResponse = Error | SolicitInvitation

export type Success = {
    __typename?: "Success"
    success: Scalars["Boolean"]
}

export type Tag = {
    createdAt: Scalars["String"]
    expiresAt?: Maybe<Scalars["String"]>
    id: Scalars["ID"]
    identityId: Scalars["ID"]
    itemId: Scalars["ID"]
    itemType: Scalars["String"]
    subjectId: Scalars["ID"]
    type: NotificationType
    updatedAt: Scalars["String"]
}

export enum TagItemType {
    PractitionerEventTag = "PractitionerEventTag",
    UserPractitionerTag = "UserPractitionerTag",
    UserEventTag = "UserEventTag",
    MedicationEventTag = "MedicationEventTag",
    ClaimsMedicationTag = "ClaimsMedicationTag",
    UserMedicationTag = "UserMedicationTag",
    CabinetMedicationTag = "CabinetMedicationTag",
    UserCabinetMedicationTag = "UserCabinetMedicationTag",
    ClaimsPractitionerTag = "ClaimsPractitionerTag",
}

export type TagNodeType =
    | CabinetMedicationTag
    | ClaimsMedicationTag
    | ClaimsPractitionerTag
    | MedicationEventTag
    | PractitionerEventTag
    | UserCabinetMedicationTag
    | UserEventTag
    | UserMedicationTag
    | UserPractitionerTag

export type Tags = {
    tags: Array<Maybe<TagNodeType>>
}

export type TealiumConfig = {
    __typename?: "TealiumConfig"
    account: Scalars["String"]
    android: Scalars["String"]
    endpoint: Scalars["String"]
    environment: Scalars["String"]
    ios: Scalars["String"]
    profile: Scalars["String"]
}

export type TextNote = HasUserMeta &
    Note & {
        __typename?: "TextNote"
        content: Scalars["String"]
        id: Scalars["ID"]
        meta: UserMeta
        noteId: Scalars["ID"]
        notepadId: Scalars["ID"]
        notepadType: Scalars["String"]
        type: NoteType
    }

export type TreatmentCostFacility = {
    __typename?: "TreatmentCostFacility"
    address?: Maybe<Address>
    averageCost?: Maybe<Scalars["Float"]>
    defaultFacility?: Maybe<Scalars["Boolean"]>
    distance?: Maybe<Scalars["Float"]>
    facilityName?: Maybe<Scalars["String"]>
    outOfPocketCost?: Maybe<Scalars["Float"]>
    specialties?: Maybe<Array<Maybe<Scalars["String"]>>>
    telephone?: Maybe<Array<Maybe<Scalars["String"]>>>
}

export type TreatmentCostProvider = {
    __typename?: "TreatmentCostProvider"
    displayName: Scalars["String"]
    facilities?: Maybe<Array<Maybe<TreatmentCostFacility>>>
    treatmentCostProviderId: Scalars["ID"]
    treatmentName: Scalars["String"]
    treatmentSuggestionId: Scalars["ID"]
}

export type TreatmentCostResults = {
    __typename?: "TreatmentCostResults"
    morePages?: Maybe<Scalars["Boolean"]>
    nextPageNumber?: Maybe<Scalars["Int"]>
    pageNumber: Scalars["Int"]
    pageResultCount: Scalars["Int"]
    results: Array<Maybe<TreatmentCostProvider>>
    totalResultCount: Scalars["Int"]
}

export type TreatmentSuggestion = {
    __typename?: "TreatmentSuggestion"
    displayName: Scalars["String"]
    treatmentSuggestionId: Scalars["ID"]
}

export type UnconnectedPersonDetails = {
    emailAddress: Scalars["String"]
    name: HumanNameInput
}

export type UnlinkAccountPayload = {
    accountName: Scalars["String"]
    linkedAccountId: Scalars["ID"]
    serviceName: Scalars["String"]
}

export type UpdateAllergyPayload = {
    category?: Maybe<AllergyCategory>
    id: Scalars["ID"]
    medicationDetailsId?: Maybe<Scalars["ID"]>
    name: Scalars["String"]
    severity?: Maybe<AllergySeverity>
    startedAt: Scalars["String"]
    symptoms?: Maybe<Scalars["String"]>
    treatmentMedicationId?: Maybe<Scalars["ID"]>
    treatmentName?: Maybe<Scalars["String"]>
}

export type UpdateConditionNotePayload = {
    conditionId: Scalars["ID"]
    medicationDetailsId: Scalars["ID"]
    noteId: Scalars["ID"]
}

export type UpdateFamilyMemberHistoryPayload = {
    condition: Scalars["String"]
    id: Scalars["ID"]
    onsetAge?: Maybe<Scalars["Int"]>
    relationship: Scalars["String"]
}

export type UpdateHealthpassCardPaymentPayload = {
    amount: Scalars["String"]
    id: Scalars["ID"]
    paymentDate: Scalars["String"]
    walletId: Scalars["ID"]
}

export type UpdateImageNotePayload = {
    caption?: Maybe<Scalars["String"]>
    data?: Maybe<Scalars["String"]>
    noteId: Scalars["ID"]
}

export type UpdateImmunizationPayload = {
    id: Scalars["ID"]
    location?: Maybe<Scalars["String"]>
    name: Scalars["String"]
    occurrenceDate: Scalars["String"]
    status: Scalars["String"]
    vaccineCode?: Maybe<Scalars["String"]>
    vaccineCodeId?: Maybe<Scalars["String"]>
}

export type UpdateLocationNotePayload = {
    city?: Maybe<Scalars["String"]>
    name?: Maybe<Scalars["String"]>
    noteId: Scalars["ID"]
    postalCode?: Maybe<Scalars["String"]>
    state?: Maybe<Scalars["String"]>
    streetText?: Maybe<Scalars["String"]>
    telephone?: Maybe<Scalars["String"]>
}

export type UpdateTextNotePayload = {
    content: Scalars["String"]
    noteId: Scalars["ID"]
}

export type UpdateUserCabinetMedicationPayload = {
    brandStatus?: Maybe<Scalars["String"]>
    displayName?: Maybe<Scalars["String"]>
    dosage?: Maybe<MedicationDosageInput>
    medicationDetailsId: Scalars["ID"]
    medicationId: Scalars["ID"]
    supply?: Maybe<QuantityInput>
}

export type UpdateUserEventPayload = {
    details: Scalars["String"]
    eventDate?: Maybe<Scalars["String"]>
    eventId: Scalars["ID"]
    title: Scalars["String"]
}

export type UpdateUserMedicationPayload = {
    brandStatus?: Maybe<Scalars["String"]>
    displayName?: Maybe<Scalars["String"]>
    dosage?: Maybe<MedicationDosageInput>
    medicationId: Scalars["ID"]
    supply?: Maybe<QuantityInput>
}

export type UpdateUserPractitionerPayload = {
    classification?: Maybe<Scalars["String"]>
    clinic: ClinicInput
    name: HumanNameInput
    practitionerId: Scalars["ID"]
    specialty?: Maybe<Scalars["String"]>
}

export type UserAllergy = Allergy &
    CareEvent &
    HasUserMeta &
    Notepad & {
        __typename?: "UserAllergy"
        careSubjectId: Scalars["ID"]
        category?: Maybe<AllergyCategory>
        eventDate?: Maybe<Scalars["String"]>
        eventId: Scalars["ID"]
        id: Scalars["ID"]
        isDeleted?: Maybe<Scalars["Boolean"]>
        medicationDetailsId?: Maybe<Scalars["ID"]>
        meta: UserMeta
        name: Scalars["String"]
        notepadId: Scalars["ID"]
        notes?: Maybe<NoteConnection>
        severity?: Maybe<AllergySeverity>
        startedAt: Scalars["String"]
        symptoms?: Maybe<Scalars["String"]>
        treatmentMedicationId?: Maybe<Scalars["ID"]>
        treatmentName?: Maybe<Scalars["String"]>
        type: CareEventType
        membershipId?: Maybe<Scalars["String"]>
    }

export type UserCabinetMedication = HasMedicationDetails &
    HasUserMeta &
    Medication &
    Notepad &
    Tags & {
        __typename?: "UserCabinetMedication"
        brandStatus?: Maybe<Scalars["String"]>
        careSubjectId: Scalars["ID"]
        condition?: Maybe<MedicationConditionNote>
        conditionId?: Maybe<Scalars["ID"]>
        conditionNoteId?: Maybe<Scalars["ID"]>
        displayName?: Maybe<Scalars["String"]>
        dosage?: Maybe<MedicationDosage>
        id: Scalars["ID"]
        isFillHistoryItem: Scalars["Boolean"]
        lastFillDate?: Maybe<Scalars["String"]>
        medicationDetailsId: Scalars["ID"]
        medicationId: Scalars["ID"]
        medicationTreatmentId?: Maybe<Scalars["String"]>
        meta: UserMeta
        notepadId: Scalars["ID"]
        notes?: Maybe<NoteConnection>
        pharmacyName?: Maybe<Scalars["String"]>
        pharmacyPhone?: Maybe<Scalars["String"]>
        prescriberName?: Maybe<Scalars["String"]>
        source?: Maybe<Scalars["String"]>
        status: MedicationStatusNote
        supply: Quantity
        tags: Array<Maybe<TagNodeType>>
        type: MedicationType
    }

export type UserCabinetMedicationTag = Tag & {
    __typename?: "UserCabinetMedicationTag"
    createdAt: Scalars["String"]
    expiresAt?: Maybe<Scalars["String"]>
    id: Scalars["ID"]
    identityId: Scalars["ID"]
    itemId: Scalars["ID"]
    itemType: Scalars["String"]
    subjectId: Scalars["ID"]
    type: NotificationType
    updatedAt: Scalars["String"]
}

export type UserContext = {
    __typename?: "UserContext"
    billFinancials?: Maybe<BillFinancialInfo>
    cards?: Maybe<ContentCardConnection>
    careSubjects: CareSubjectConnection
    featureToggles: FeatureToggleFlags
    healthpassPayments?: Maybe<HealthpassPayments>
    healthpassServiceDetails?: Maybe<HealthpassServiceDetails>
    healthpassWallet?: Maybe<HealthpassWallet>
    healthpassWalletItemPaymentStatuses: Array<
        Maybe<HealthpassWalletItemPaymentStatusResponse>
    >
    hsaFinancials?: Maybe<HsaFinancialInfo>
    linkableServices?: Maybe<Array<Maybe<LinkableService>>>
    linkedAccounts?: Maybe<Array<Maybe<LinkedAccount>>>
    notificationDotTree?: Maybe<NotificationDotTree>
    pagerInitDetails: PagerInitDetails
    planEligibilities: PlanEligibilities
    planFinancials?: Maybe<PlanFinancialInfo>
    planInformation: PlanInformation
    providerDetail?: Maybe<ProviderSearchDetail>
    providerHealthplanEligibilityValidation: X12Eligibilities
    providerSearchTiers?: Maybe<Array<Maybe<ProviderSearchTier>>>
    searchProviders?: Maybe<ProviderSearchResults>
    searchTreatmentCosts?: Maybe<TreatmentCostResults>
    suggestLocations?: Maybe<LocationSuggestResults>
    suggestProviders?: Maybe<ProviderSuggestResults>
    suggestTreatments?: Maybe<Array<Maybe<TreatmentSuggestion>>>
    tags: Array<Maybe<TagNodeType>>
}

export type UserContextCardsArgs = {
    after?: Maybe<Scalars["ID"]>
    first?: Maybe<Scalars["Int"]>
}

export type UserContextHealthpassServiceDetailsArgs = {
    serviceDetailsId: Scalars["ID"]
}

export type UserContextHealthpassWalletItemPaymentStatusesArgs = {
    input?: Maybe<HealthpassWalletItemPaymentStatusesPayload>
}

export type UserContextProviderDetailArgs = {
    latitude?: Maybe<Scalars["Float"]>
    longitude?: Maybe<Scalars["Float"]>
    providerSearchDetailId: Scalars["ID"]
}

export type UserContextProviderHealthplanEligibilityValidationArgs = {
    birthday: Scalars["String"]
    firstName?: Maybe<Scalars["String"]>
    groupNumber: Scalars["String"]
    lastName: Scalars["String"]
    memberId: Scalars["String"]
    memberPrefix?: Maybe<Scalars["String"]>
}

export type UserContextSearchProvidersArgs = {
    distance?: Maybe<Scalars["Float"]>
    filters?: Maybe<Array<Maybe<Scalars["ID"]>>>
    latitude: Scalars["Float"]
    longitude: Scalars["Float"]
    pageNumber: Scalars["Int"]
    pageSize?: Maybe<Scalars["Int"]>
    searchTerm: Scalars["String"]
    searchType?: Maybe<ProviderSearchType>
    sortType?: Maybe<ProviderSearchSortType>
}

export type UserContextSearchTreatmentCostsArgs = {
    location: Scalars["String"]
    pageNumber: Scalars["Int"]
    treatmentSuggestionId: Scalars["ID"]
}

export type UserContextSuggestLocationsArgs = {
    latitude?: Maybe<Scalars["Float"]>
    longitude?: Maybe<Scalars["Float"]>
    searchTerm: Scalars["String"]
}

export type UserContextSuggestProvidersArgs = {
    distance?: Maybe<Scalars["Float"]>
    latitude: Scalars["Float"]
    longitude: Scalars["Float"]
    searchTerm: Scalars["String"]
    sortType?: Maybe<ProviderSearchSortType>
}

export type UserContextSuggestTreatmentsArgs = {
    location: Scalars["String"]
    searchTerm: Scalars["String"]
}

export type UserEvent = CareEvent &
    HasUserMeta &
    Notepad &
    Tags & {
        __typename?: "UserEvent"
        careSubjectId: Scalars["ID"]
        details: Scalars["String"]
        eventDate?: Maybe<Scalars["String"]>
        eventId: Scalars["ID"]
        id: Scalars["ID"]
        isDeleted?: Maybe<Scalars["Boolean"]>
        meta: UserMeta
        notepadId: Scalars["ID"]
        notes?: Maybe<NoteConnection>
        tags: Array<Maybe<TagNodeType>>
        title: Scalars["String"]
        type: CareEventType
        membershipId?: Maybe<Scalars["String"]>
    }

export type UserEventTag = Tag & {
    __typename?: "UserEventTag"
    createdAt: Scalars["String"]
    expiresAt?: Maybe<Scalars["String"]>
    id: Scalars["ID"]
    identityId: Scalars["ID"]
    itemId: Scalars["ID"]
    itemType: Scalars["String"]
    subjectId: Scalars["ID"]
    type: NotificationType
    updatedAt: Scalars["String"]
}

export type UserFamilyMemberHistory = FamilyMemberHistory &
    HasUserMeta &
    Notepad & {
        __typename?: "UserFamilyMemberHistory"
        careSubjectId: Scalars["ID"]
        condition: Scalars["String"]
        conditionNote?: Maybe<Scalars["String"]>
        id: Scalars["ID"]
        meta: UserMeta
        notepadId: Scalars["ID"]
        notes?: Maybe<NoteConnection>
        onsetAge?: Maybe<Scalars["Int"]>
        relationship: Scalars["String"]
        status: Scalars["String"]
    }

export type UserImmunization = CareEvent &
    HasUserMeta &
    Immunization &
    Notepad & {
        __typename?: "UserImmunization"
        careSubjectId: Scalars["ID"]
        eventDate?: Maybe<Scalars["String"]>
        eventId: Scalars["ID"]
        id: Scalars["ID"]
        isDeleted?: Maybe<Scalars["Boolean"]>
        location?: Maybe<Scalars["String"]>
        meta: UserMeta
        name: Scalars["String"]
        notepadId: Scalars["ID"]
        notes?: Maybe<NoteConnection>
        occurrenceDate: Scalars["String"]
        status: Scalars["String"]
        type: CareEventType
        vaccineCode?: Maybe<Scalars["String"]>
        vaccineCodeId?: Maybe<Scalars["String"]>
        membershipId?: Maybe<Scalars["String"]>
    }

export type UserMedication = HasUserMeta &
    Medication &
    Notepad &
    Tags & {
        __typename?: "UserMedication"
        brandStatus?: Maybe<Scalars["String"]>
        careSubjectId: Scalars["ID"]
        displayName?: Maybe<Scalars["String"]>
        dosage?: Maybe<MedicationDosage>
        id: Scalars["ID"]
        lastFillDate?: Maybe<Scalars["String"]>
        medicationId: Scalars["ID"]
        meta: UserMeta
        notepadId: Scalars["ID"]
        notes?: Maybe<NoteConnection>
        pharmacyName?: Maybe<Scalars["String"]>
        pharmacyPhone?: Maybe<Scalars["String"]>
        prescriberName?: Maybe<Scalars["String"]>
        status: MedicationStatusNote
        supply: Quantity
        tags: Array<Maybe<TagNodeType>>
        type: MedicationType
    }

export type UserMedicationTag = Tag & {
    __typename?: "UserMedicationTag"
    createdAt: Scalars["String"]
    expiresAt?: Maybe<Scalars["String"]>
    id: Scalars["ID"]
    identityId: Scalars["ID"]
    itemId: Scalars["ID"]
    itemType: Scalars["String"]
    subjectId: Scalars["ID"]
    type: NotificationType
    updatedAt: Scalars["String"]
}

export type UserMeta = {
    __typename?: "UserMeta"
    canDelete?: Maybe<Scalars["Boolean"]>
    canEdit?: Maybe<Scalars["Boolean"]>
    creationDate?: Maybe<Scalars["String"]>
    creatorName?: Maybe<HumanName>
    creatorUserIdentityId?: Maybe<Scalars["String"]>
    isCreator?: Maybe<Scalars["Boolean"]>
    isOwner?: Maybe<Scalars["Boolean"]>
    ownerIdentityIds?: Maybe<Array<Maybe<Scalars["ID"]>>>
    updateDate?: Maybe<Scalars["String"]>
}

export type UserPractitioner = HasUserMeta &
    Notepad &
    Practitioner &
    Tags & {
        __typename?: "UserPractitioner"
        careSubjectId: Scalars["ID"]
        classification?: Maybe<Scalars["String"]>
        clinic?: Maybe<Clinic>
        id: Scalars["ID"]
        isDeleted?: Maybe<Scalars["Boolean"]>
        meta: UserMeta
        name?: Maybe<HumanName>
        notepadId: Scalars["ID"]
        notes?: Maybe<NoteConnection>
        practitionerId: Scalars["ID"]
        specialty?: Maybe<Scalars["String"]>
        tags: Array<Maybe<TagNodeType>>
        type: PractitionerType
    }

export type UserPractitionerTag = Tag & {
    __typename?: "UserPractitionerTag"
    createdAt: Scalars["String"]
    expiresAt?: Maybe<Scalars["String"]>
    id: Scalars["ID"]
    identityId: Scalars["ID"]
    itemId: Scalars["ID"]
    itemType: Scalars["String"]
    subjectId: Scalars["ID"]
    type: NotificationType
    updatedAt: Scalars["String"]
}

export type X12Eligibilities = {
    __typename?: "X12Eligibilities"
    isMedicallyEligible: Scalars["Boolean"]
}
/* eslint-enable */
