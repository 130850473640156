export const renewalQuery = `
query PlanEnrollment {
    planEnrollment {
        renewalInfo {
            renewedProduct {
                id
                name
            }
            isIndividualMember
            isDiscontinuedProduct
            isDiscontinuedCounty
            renewalInteractionState
            renewalWindow
            shopMarketPlaceURL
        }
        region
        subregion
        currentPlan {
            product {
                id
            }
            brand {
                code
                family
            }
            currentYear
            nextYear
            openEnrollmentEndDate
            openEnrollmentStartDate
            coverageEndDate
            residentialAddress {
                street
                city
                state
                zip
            }
            planMembers {
                familyMemberId
                firstName
                lastName
                subscriberId
                memberType
                age
                suffix
                gender
                dateOfBirth
            }
        }
    }
}`

export const renewalReviewMutation = `
mutation RenewIndividualPlan($input: IndividualMemberRenewalInput!) {
    renewIndividualPlan(input: $input) {
        resultStatus
        resultDescription
    }
}`

export const renewalConfirmationMutation = `
mutation ReenrollIndividualPlan($input: ReenrollIndividualInput) {
    reenrollIndividualPlan(input: $input) {
        resultStatus
        resultDescription
    }
}`

export const renewalCancelMutation = `
mutation CancelIndividualRenewal($input: CancelIndividualRenewalInput!) {
    cancelIndividualRenewal(input: $input) {
        resultStatus
        resultDescription
    }
}`
