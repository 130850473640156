/**
 * Timeline reducer
 * -----------------------------------------------------------------------------
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { ProviderItem } from "./types"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Providers {
    // Base props
    readonly isInitialized: boolean
    readonly isLoading: boolean
    readonly errorMessage?: string

    // Resource props
    readonly providersByMember: { readonly [key: string]: ReadonlyArray<ProviderItem> }
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: Providers = {
    isInitialized: false,
    isLoading: false,
    errorMessage: undefined,
    providersByMember: {},
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const Providers = createSlice({
    name: "Providers",
    initialState,
    reducers: {
        receiveProviders: (state: Providers, action: PayloadAction<Providers>) => {
            return action.payload
        },
        resetProviders: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const ProvidersActions = { ...Providers.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default Providers.reducer
