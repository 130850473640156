import { Eobs } from "./reducer"

export const transformEobStatements = (
    data: any, // eslint-disable-line
): Eobs => {
    return data?.eobs?.map(item => {
        return {
            id: item?.id,
            date:
                item?.date || Boolean(item?.date) || !isNaN(item?.date)
                    ? item.date
                    : "",
            desc:
                item?.desc !== undefined ||
                Boolean(item?.desc) ||
                !isNaN(item?.desc)
                    ? item.desc
                    : "",
            name: item?.name,
            url: item?.url,
        }
    })
}
