import * as React from "react"
import { SVGProps } from "react"
const SvgPhoto = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Photo" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M20.5556 21.0002H3.44444C2.1 21.0002 1 19.6723 1 18.0494V5.951C1 4.32805 2.1 3.00018 3.44444 3.00018H20.5556C21.9 3.00018 23 4.32805 23 5.951V18.0494C23 19.5248 21.9 21.0002 20.5556 21.0002Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinejoin="round"
        />
        <path
            d="M7.18421 13.4028L4 18.0002H15L11.9605 12.0002L9.30702 14.4158L7.18421 13.4028Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinejoin="round"
        />
        <path
            d="M20 8.00018C20 9.10475 19.1046 10.0002 18 10.0002C16.8954 10.0002 16 9.10475 16 8.00018C16 6.89561 16.8954 6.00018 18 6.00018C19.1046 6.00018 20 6.89561 20 8.00018Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgPhoto
