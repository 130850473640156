/**
 * GraphQL query to fetch eligible member details
 */
export const fetchClaimHoldersQuery = `
query{
    claimSubmission{
        authorizedFamilyMembers{
          firstName
          lastName
          relationship
          subscriberId
          suffix
          age
        }
      }
}`
