/**
 * Cobrowse reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import {
    createStatefulSlice,
    SliceState,
} from "store/util/create-stateful-slice"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Cobrowse {
    readonly isInitialized: boolean // = isGlanceScriptLoaded && isEventHandlersAdded && (isPresenceReady || !config.presence === "on") -- can make a fn to get on demand, but also can use this prop to listen for in redux (ie useEffect)
    readonly isPresenceReady: boolean
    readonly isGlanceScriptLoaded: boolean
    readonly isEventHandlersAdded: boolean
    readonly isGlanceTermsModalExists: boolean // means it exists in the dom, but may be visible or invisible
    readonly isGlanceTermsModalShowing: boolean // means it is visible to the user
    readonly lastSessionEnd: CobrowseSessionEnd
}

export interface CobrowseSessionEnd {
    readonly time: string
    readonly reason: CobrowseSessionEndReason
    readonly notified: boolean
}

/**
 * see docs (which are missing 'timeout', but can happen):
 * https://help.glance.net/glance-cobrowse/glance-cobrowse-customizing/visitor_agent/visitor_event/#session-lifecycle-events
 */
export type CobrowseSessionEndReason = "visitor" | "agent" | "error" | "timeout"

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<Cobrowse> = {
    status: "pristine",
    isInitialized: false,
    isPresenceReady: false,
    isGlanceScriptLoaded: false,
    isEventHandlersAdded: false,
    isGlanceTermsModalExists: false,
    isGlanceTermsModalShowing: false,
    lastSessionEnd: {
        time: null,
        reason: null,
        notified: true,
    },
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const cobrowse = createStatefulSlice({
    name: "cobrowse",
    initialState,
    reducers: {
        receiveCobrowse: (
            state: SliceState<Cobrowse>,
            action: PayloadAction<Cobrowse>,
        ) => {
            return { status: "completed", ...action.payload }
        },
        // moved this to actions, which helps with type hinting, etc
        // receiveCobrowseUpdate: (
        //     state: SliceState<Cobrowse>,
        //     action: PayloadAction<Cobrowse>,
        // ) => {
        //     return { status: "completed", ...state, ...action.payload }
        // },
        resetCobrowse: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const cobrowseActions = { ...cobrowse.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default cobrowse.reducer
