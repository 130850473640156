import * as React from "react"
import { SVGProps } from "react"
const SvgGlasses = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Glasses" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M21 16.5C21 18.433 19.433 20 17.5 20C15.567 20 14 18.433 14 16.5M21 16.5C21 14.567 19.433 13 17.5 13C15.567 13 14 14.567 14 16.5M21 16.5V6.90977C21 4.95489 20.0571 3 18 3M14 16.5H10M10 16.5C10 18.433 8.433 20 6.5 20C4.567 20 3 18.433 3 16.5M10 16.5C10 14.567 8.433 13 6.5 13C4.567 13 3 14.567 3 16.5M3 16.5V6.90977C3 4.95489 3.94286 3 6 3"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgGlasses
