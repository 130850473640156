/**
 * Spinner
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Icon } from "elements"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props extends React.HTMLAttributes<HTMLDivElement> {
    readonly variant?: "primary" | "secondary"
    readonly size?: "medium" | "small"
    readonly inverted?: boolean
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Spinner: React.FC<Props> = props => {
    /**
     * Define template variables
     */
    const colors = {
        primary: props.inverted
            ? "text-white"
            : "text-primary dark:text-accent",
        secondary: props.inverted
            ? "text-white opacity-50"
            : "text-light-200 dark:text-dark-50",
    }

    /**
     * Template
     */
    return (
        <div className={props.className}>
            <Icon
                type="spinner"
                size={props.size === "small" ? 24 : 50}
                className={`animate-spin ${colors[props.variant || "primary"]}`}
            />
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Spinner
