/**
 * Badge
 * -----------------------------------------------------------------------------
 */
import React from "react"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Props extends React.HTMLAttributes<HTMLDivElement> {
    readonly label?: string
    readonly variant?: "default" | "info" | "success" | "warning" | "error"
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Badge: React.FC<Props> = props => {
    /**
     * Define variants
     */
    const variants: Record<Props["variant"], string> = {
        default: "bg-primary-50",
        info: "bg-info-vivid",
        success: "bg-success-vivid",
        warning: "bg-warning-vivid",
        error: "bg-error-vivid",
    }
    const variantClass = variants[props.variant || "error"]

    /**
     * Template – Dot
     */
    if (!props.label) {
        return (
            <div
                className={`w-[10px] h-[10px] rounded-circle border border-white dark:border-dark-200 ${variantClass} ${props.className}`}
            />
        )
    }

    /**
     * Template - With Label
     */
    return (
        <div
            className={`inline-block rounded-circle px-[6px] min-w-[24px] h-[24px] leading-[23px] text-center ${variantClass} ${props.className}`}
        >
            <span className={`${props.variant == "default" ? "text-primary-300" : "text-white font-bold"} text-[12px]`}>
                {props.label}
            </span>
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Badge
