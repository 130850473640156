export interface Profile {
    readonly id: string
    readonly username?: string
    readonly firstName: string
    readonly lastName: string
    readonly email: string
    readonly birthDate?: string
    readonly gender?: string
    readonly relationship?: string
    readonly groupId?: string
    readonly subscriberId: string
    readonly pronouns?: string
    readonly phone?: string
    readonly address?: {
        readonly street?: string
        readonly city?: string
        readonly state?: string
        readonly postalCode?: string
    }
    readonly billingAddress?: {
        readonly street?: string
        readonly city?: string
        readonly state?: string
        readonly postalCode?: string
    }
    readonly audience?: string
    readonly region: string
    readonly messageCenterNotifications?: number
    readonly cca?: boolean  // this cca field only belongs to logged in member now, also earlier field used to contain cca member list of the family, whereas with new auth policies implementation, cca members other than logged in member in the family gets filtered from the backend service itself
    readonly employerGroupName?: string
    readonly employerGroupCategory?: string
    readonly coverages?: ReadonlyArray<Record<string, unknown>>
    readonly heqShare?: boolean
    readonly emailVerificationStatus?: string
    readonly smsVerificationStatus?: string
    readonly sellingAffiliate?: string
    readonly dentalSellingAffiliate?: string
    readonly brandName?: string
    readonly memeCk?: number
}

export interface FamilyMember {
    readonly id: string
    readonly firstName: string
    readonly lastName: string
    readonly suffix: string
    readonly birthDate?: string
    readonly relationship?: string
    readonly isSharingAuthorized?: boolean
    readonly gender?: string
}

export interface Plan {
    readonly memberId: string
    readonly groupNumber: string
    readonly suffix: string
    readonly brandCode: string
    readonly usableDentalPlanName: string
}

export type Groups = "asn" | "uu" | "winco" | "ump" | "cis"
export interface EmployerGroup {
    readonly id: Groups
    readonly name: string
    readonly src: string
    readonly size?: string
}

export enum ActivationType {
    Success = "SUCCESS",
    Failed = "FAILED",
    Pending = "PENDING",
    Empty = "",
}

export interface EmailActivation {
    readonly status: ActivationType
}
