/**
 * App Wrapper
 * -----------------------------------------------------------------------------
 * A. Manage Redux provider
 * B. Wrap application with <Initialize>
 * C. Protect authenticated routes
 */
import "@/utils/console-init" // Keep this import always as the first import
import React from "react"
import { AppProps } from "next/app"
import { useRouter } from "next/router"
import Routes, { PublicRoutes, JointAdminRoutes } from "router"
import { Provider } from "react-redux"
import { I18nextProvider } from "react-i18next"
import i18n from "utils/i18n"
import { useDispatch, useSelector, useFeatures, actions, store } from "store"
import Features from "@/utils/features"
import { systemDark } from "utils/device"
import "styles/base.css"
import "focus-visible"
import { tagEvent } from "@/utils/analytics"
import TimeoutPopup from "@/components/timeout_popup"
import { useOnboardingClient } from "utils"
import { isMobileAppRequest, removeTargetAttrFromAnchors } from "@/utils/mobile"
import LoadingScreen, { ExtendedLoadingScreen } from "components/layout/loading"
import { destroyDFWAuthenticationCookie } from "@/utils/cookies"
import { setLocalStorageItem } from "@/utils/window-storage"
import { datadogRum } from "@datadog/browser-rum"
import {
    HeartwoodProvider,
    HeartwoodContextProps,
    HeartwoodEvent,
} from "heartwood-component-library"
import "heartwood-component-library/dist/heartwood.css"
import { isNonProd } from "@/utils/env"
import { initQualtricsScript } from "@/utils/qualtrics"
import SmartBannerMetaTag from "@/components/smart_banner_meta_tag"
// import { ErrorBoundary } from "@/components/ErrorBoundary"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorFallback } from "@/components/ErrorBoundary"

/**
 * curried function
 * applies "label" in the error message prefix,
 * to identify what this particular error boundary is / where it is placed
 *
 * log will appear like this:
 * error "ERROR BOUNDARY (<label>): <error message>"
 */
const logError = (label = "") =>
    (error: Error, info: { readonly componentStack: string }) => {
        /* eslint-disable functional/immutable-data */
        // Do something with the error, e.g. log to an external API
        const prefix = `ERROR BOUNDARY (${label}): `
        error.message = prefix + error.message
        error.stack = prefix + error.stack
        console.error(error, `componentStack: ${info.componentStack}`) // ensure we see 'componentStack' coming through in DD RUM
    }

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const App: React.FC<AppProps> = ({ Component, pageProps }) => {
    /**
     * Template
     */
    return (
        /* Ultimate error fallback, top-most level */
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError("top-fallback")} >
            <I18nextProvider i18n={i18n}>
                <Provider store={store}>
                    <SmartBannerMetaTag />
                    <Initialize>
                        {/* Catching the error here would apply our Heartwood theme to the error fallback UI */}
                        <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError("initialize")} >
                            <Component {...pageProps} />
                        </ErrorBoundary>
                    </Initialize>
                </Provider>
            </I18nextProvider>
        </ErrorBoundary>
    )
}

/**
 * Subcomponent: Initialize
 * -----------------------------------------------------------------------------
 * TODO: Create loading spinner (as load time increases)
 */
const Initialize = (props: { readonly children: React.ReactNode }) => {
    /**
     * Hooks
     */
    const router = useRouter()

    /**
     * Feature Toggle
     */
    const {
        [Features.JOINT_ADMIN_ENABLED]: isJointAdminEnabled,
        [Features.JOINT_ADMIN]: isJointAdmin,
    } = useFeatures()

    /**
     * Application state
     */
    const dispatch = useDispatch()
    const app = useSelector(state => state.app)
    const profile = useSelector(state => state.account.profile)

    /**
     * Component state
     */
    const [initialized, setInitialized] = React.useState(false)
    const [showExtendedLoadingScreen, setShowExtendedLoadingScreen] =
        React.useState(false)
    const [system, setSystem] = React.useState(false)
    const dfwAuthInterval = React.useRef(null)
    const [datadogRumInitialized, setDatadogRumInitialized] =
        React.useState(false)

    /**
     * Lifecycle: Initialize redux
     */
    React.useEffect(() => {
        // Setting the flag to render the extended loading screen for initializing app
        const timeout = 15000
        setTimeout(() => {
            setShowExtendedLoadingScreen(true)
        }, timeout)

        dispatch(actions.initialize())
    }, [])

    /**
     * Lifecycle: Listen for redux initialize complete
     */
    React.useEffect(() => {
        if (!datadogRumInitialized && app.datadog) {
            // configure datadog RUM
            datadogRum.init({
                // Initialize the RUM SDK. Configure the allowedTracingUrls initialization parameter with the list of internal, first-party origins called by your browser application. https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces/?tab=browserrum
                // TODO: do we need to lock these hosts by environment? Would be complicated, but explicit
                allowedTracingUrls: [
                    /https:\/\/.*\.(regence|asuris|bridgespanhealth)\.com/,
                    /https:\/\/.*\.janusplatform\.io/,
                    /https:\/\/.*\.cambiahealthplanapis\.com/,
                ],
                traceSampleRate: 100, // (Optional) Configure the traceSampleRate initialization parameter to keep a defined percentage of the backend traces. If not set, 100% of the traces coming from browser requests are sent to Datadog.
                // The rest of the config comes from /api/env
                ...app.datadog,
            })
            setDatadogRumInitialized(true)
        }

        if (app.isInitialized && !initialized) {
            // Set system preference for dark mode (requires `window`)
            setSystem(systemDark())

            // Show application (or redirect if protected route)
            if (isProtected(router.pathname)) {
                protectRoute()
            } else if (
                isJointAdminEnabled &&
                isJointAdmin &&
                isJointAdminNotAllowedRoute(router.pathname)
            ) {
                protectJointAdminNotAllowedRoute()
            } else {
                setInitialized(true)
            }
        }
    }, [app])

    /**
     * Lifecycle: Protect routes
     */
    React.useEffect(() => {
        /**
         * TEMP: RGR-8525
         * Redirect new "Find Care" dashboard for production
         */
        if (router.pathname === "/dashboard" && !isNonProd()) {
            window.location.href = "/" // eslint-disable-line
        }

        if (initialized) {
            if (isProtected(router.pathname)) {
                protectRoute()
            }
            if (
                isJointAdminEnabled &&
                isJointAdmin &&
                isJointAdminNotAllowedRoute(router.pathname)
            ) {
                protectJointAdminNotAllowedRoute()
            }
        }
    }, [router.pathname])

    /**
     * Lifecycle: Page load analytics
     */
    React.useEffect(() => {
        const pathName = document?.location?.pathname
        setTimeout(() => {
            tagEvent({
                tealium_event: "page load event",
                page_path: pathName,
                page_title: document?.title || pathName,
            })
        }, 500)
    }, [router.asPath])

    /**
     * Lifecycle: Qualtrics
     */
    React.useEffect(() => {
        initQualtricsScript
    })

    /**
     * Lifecycle: Signed in analytics
     */
    React.useEffect(() => {
        if (
            app.isDfwAuth &&
            profile.relationship &&
            app.brand &&
            dfwAuthInterval !== null
        ) {
            if (typeof window !== "undefined" && window !== null) {
                // eslint-disable-next-line functional/immutable-data
                dfwAuthInterval.current = setInterval(() => {
                    if (
                        // eslint-disable-next-line
                        typeof (window as any).utag !== "undefined" &&
                        // eslint-disable-next-line
                        (window as any).utag !== null
                    ) {
                        // Sign in tealium event
                        tagEvent({
                            tealium_event: "signed_in",
                            page_title: document.title,
                            relationship: profile.relationship,
                        })

                        // Removes the DFW Authentication cookie, that was used to identity member authentication through ADIF sign-in.
                        destroyDFWAuthenticationCookie(app.brand + ".com")

                        clearInterval(dfwAuthInterval.current)
                        // eslint-disable-next-line functional/immutable-data
                        dfwAuthInterval.current = null

                        // Reset App dfwAuth to false
                        dispatch(actions.resetDfwAuth())
                    }
                }, 2000)
            }
        } else if (
            !app.isDfwAuth &&
            typeof window !== "undefined" &&
            window !== null
        ) {
            if (
                // eslint-disable-next-line
                typeof (window as any).utag !== "undefined" &&
                // eslint-disable-next-line
                (window as any).utag !== null &&
                dfwAuthInterval.current !== null
            ) {
                clearInterval(dfwAuthInterval.current)
                // eslint-disable-next-line functional/immutable-data
                dfwAuthInterval.current = null
            }
        }
    }, [app.isDfwAuth, app.brand, profile.relationship])

    /**
     * Lifecycle: Care gaps campaign
     */
    React.useEffect(() => {
        if (app.ecid) {
            setLocalStorageItem("CAMPAIGN_CODE", app.ecid)
        }
    }, [app.ecid])

    /**
     * Initialize onboarding client
     */
    useOnboardingClient()

    /**
     * Methods
     */
    const isProtected = (path: string) => {
        return (
            app.isInitialized &&
            !app.isAuthenticated &&
            !PublicRoutes.includes(path)
        )
    }
    const protectRoute = () => {
        const path = router.asPath
        window.location.href = Routes.redirectSignin(path) // eslint-disable-line
    }
    const isJointAdminNotAllowedRoute = (path: string) => {
        return (
            app.isAuthenticated &&
            isJointAdminEnabled &&
            isJointAdmin &&
            !JointAdminRoutes.includes(path)
        )
    }
    const protectJointAdminNotAllowedRoute = () => {
        window.location.href = "/404" // eslint-disable-line
    }

    /**
     * Heartwood Events
     */
    const onEvent = (event: HeartwoodEvent) => {
        if (event.type === "link") {
            tagEvent({
                tealium_event: "link",
                data_analytics_id: event.name,
                link_url: event.href || "",
                link_text: event.label,
                page_url: event.page_url,
                page_path: event.page_path,
            })
        }
        if (event.type === "click") {
            tagEvent({
                tealium_event: "link",
                data_analytics_id: event.name,
                link_url: "",
                link_text: event.label,
                page_url: event.page_url,
                page_path: event.page_path,
            })
        }
        if (event.type === "input") {
            tagEvent({
                tealium_event: "input",
                data_analytics_id: event.name,
                page_url: event.page_url,
                page_path: event.page_path,
            })
        }
    }

    /**
     * Define template values
     */
    const isDark = app.darkMode === "system" ? system : app.darkMode === "dark"

    /**
     * Styles
     */
    const wrapClass = `heartwood ${isDark ? "dark" : "light"}`
    const contentClass = "js-focus-visible focus-visible"

    /**
     * This onLoad handler
     */
    const onPageContentLoadHandler = () => {
        // Checks if request is from mobile app and then removes target attribute from anchor tags
        if (isMobileAppRequest()) {
            removeTargetAttrFromAnchors()
        }
    }

    /**
     * Template: Loading
     */
    if (!initialized) {
        if (!showExtendedLoadingScreen)
            return <LoadingScreen />
        else
            return <ExtendedLoadingScreen />
    }

    /**
     * Template
     */
    return (
        <div className={wrapClass}>
            <HeartwoodProvider
                theme={app.brand as HeartwoodContextProps["theme"]}
                onEvent={onEvent}
            >
                {/* Modal "Portals" */}
                <div id="root-overlay" className={contentClass}></div>
                <div id="root-popup" className={contentClass}></div>
                <div id="root-popover" className={contentClass}></div>

                {/* Page Content */}
                <div
                    className={`min-h-screen max-w-none relative bg-gray-25 dark:bg-dark-300 ${contentClass}`}
                    onLoad={onPageContentLoadHandler}
                >
                    {/* Display warning on session timeout due to 30 minutes of inactivity */}
                    {app.isAuthenticated && (
                        <TimeoutPopup activityTimeoutMs={app.activityTimeoutMs} />
                    )}
                    {props.children}
                </div>
            </HeartwoodProvider>
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default App
