/**
 * Environment utilities
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { useSelector } from "store"
import { App } from "store/app/reducer"
import { v4 as uuidv4 } from "uuid"

const deviceSessionId = uuidv4()
export const getDeviceSessionId = () => {
    return deviceSessionId
}

export const stackName = process?.env?.JANUS_STACK_NAME || "digital-first-web"

/**
 * Globals
 * -----------------------------------------------------------------------------
 */
export const REGENCE_TENANTS = {
    BCBSO: "rbcbsor",
    BSW: "rbswa",
    BCBSU: "rbcbsut",
    BSI: "rbsid",
    ASUR: "asuris",
    BSH: "bridgespan",
}

export type MemberBrandString = "regence" | "asuris" | "bridgespan"
export const MEMBER_TENANT_BRAND = {
    rbcbsor: "regence",
    rbswa: "regence",
    rbcbsut: "regence",
    rbsid: "regence",
    asuris: "asuris",
    bridgespan: "bridgespan",
}

export const getBrandFromTenant = (tenant: string): MemberBrandString => {
    try {
        const t = tenant?.trim()?.toLowerCase()
        return MEMBER_TENANT_BRAND[t]
    } catch (err) {
        console.warn("Failed to translate tenant to brand", { err, tenant })
        return null
    }
}

export interface Prefix {
    readonly janus: string
    readonly adif: string
    readonly adifLiveCluster: string
    readonly regence: string
    readonly apigee: string
    readonly dfw: string
    readonly fastTrack: string
}

export const ENVIRONMENT_PREFIXES: Record<string, Prefix> = {
    local: {
        janus: "dev",
        adif: "dev",
        adifLiveCluster: "2",
        regence: `www-dev2`,
        apigee: "www-dev",
        dfw: "localhost",
        fastTrack: "www-uat", // NOTE: Use `www-dev` when ready
    },
    /**
     * If you want to do local develpoment off of VPN,
     * use these settings for `local` instead. This will use the
     * SIT environment of ADIF, which is exposed publicly.
     */
    // local: {
    //     janus: "dev",
    //     adif: "sit",
    //     adifLiveCluster: "2",
    //     regence: `www-sit2`,
    //     apigee: "www-qa",
    //     dfw: "account-dev",
    // },
    dev: {
        janus: "dev",
        adif: "dev",
        adifLiveCluster: "2",
        regence: `www-dev2`,
        apigee: "www-dev",
        dfw: "account-dev",
        fastTrack: "www-uat", // NOTE: Use `www-dev` when ready
    },
    sit: {
        janus: "dev",
        adif: "sit",
        adifLiveCluster: "2",
        regence: `www-sit2`,
        apigee: "www-qa",
        dfw: "account-dev",
        fastTrack: "www-uat", // NOTE: Use `www-dev` when ready
    },
    uat: {
        janus: "uat",
        adif: "uat",
        adifLiveCluster: "2",
        regence: `www-uat2`,
        apigee: "www-perf",
        dfw: "account-uat",
        fastTrack: "www-uat",
    },
    prd: {
        janus: "prd",
        adif: "prd",
        adifLiveCluster: "2",
        regence: "www",
        apigee: "www",
        dfw: "account",
        fastTrack: "www",
    },
}

/**
 * Define Environment
 * -----------------------------------------------------------------------------
 */
export type Environments = "dev" | "sit" | "uat" | "prd"
export const getEnvironment = (host?: string): Environments => {
    // For server (w/o defined `host`)
    if (!host && typeof window === "undefined") {
        return (process.env.JANUS_ENVIRONMENT as Environments) || "dev"
    }

    // For browser (or defined `host`)
    const _host = host || window.location.host
    if (/(localhost|local|dev|ngrok)/.test(_host)) {
        return "dev"
    } else if (/(sit)/.test(_host)) {
        return "sit"
    } else if (/(uat|test|demo)/.test(_host)) {
        return "uat"
    } else {
        return "prd"
    }
}

/**
 * Determine if running in non-prod environment.
 * True iff environment is dev or uat.
 */
export const isNonProd = (host?: string): boolean => {
    const env = getEnvironment(host)
    return env === "dev" || env === "sit" || env === "uat"
}

/**
 * Define Brand/Theme
 * -----------------------------------------------------------------------------
 * Parse brand from url pattern
 * ex. `app.regence.com`
 */
export const getBrand = (host?: string): App["brand"] => {
    const _host = host || window.location.host
    if (/(regence)/.test(_host)) {
        return "regence"
    }
    if (/(asuris)/.test(_host)) {
        return "asuris"
    }
    if (/(bridgespan)/.test(_host)) {
        return "bridgespan"
    }
    return "regence" // Default brand
}

/**
 * Hook: Get brand
 * -----------------------------------------------------------------------------
 */
export const useBrand = (): App["brand"] => {
    /**
     * Methods
     */
    const defineBrand = () => {
        const urlBrand = getBrand()
        const environment = getEnvironment()
        const userBrand = localStorage.getItem("THEME") as App["brand"]
        return environment !== "prd" ? userBrand || urlBrand : urlBrand
    }

    /**
     * Hook state
     */
    const [brand, setBrand] = React.useState(defineBrand())

    /**
     * Lifecycle: When localStorage event is fired in `theme_switcher.tsx`
     */
    React.useEffect(() => {
        document.addEventListener("brand", () => setBrand(defineBrand()))
        return () => {
            document.removeEventListener("brand", () => setBrand(defineBrand()))
        }
    }, [])

    /**
     * Return brand
     */
    return brand
}

/**
 * Hook: Development flags
 * -----------------------------------------------------------------------------
 */
export const useDevFlag = (flag: string): boolean => {
    const { flags } = useSelector(state => state.app)
    return flags.includes(flag)
}

/**
 * Determine base domain based on host
 *
 * account.regence.com => regence.com
 * account-dev.asuris.com => asuris.com
 * localhost => localhost
 */
const RIHC_DOMAIN_REGEX = /(?:^|\.)(regence|asuris|bridgespanhealth).com$/
type BaseDomain =
    | "localhost"
    | "regence.com"
    | "asuris.com"
    | "bridgespanhealth.com"
export const getBaseDomain = (host: string): BaseDomain => {
    // eslint-disable-next-line functional/no-let
    const defaultBaseDomain = "regence.com"
    if (host.startsWith("localhost")) {
        return "localhost"
    } else {
        const regMatch = host.match(RIHC_DOMAIN_REGEX)
        if (Array.isArray(regMatch)) {
            const brand = regMatch[1]
            switch (brand) {
                case "regence":
                case "asuris":
                case "bridgespanhealth":
                    return (brand + ".com") as BaseDomain
                default:
                    console.warn(
                        `Could not determine base domain - no match for host: '${host}'. Defaulting to '${defaultBaseDomain}'.`,
                    )
                    return defaultBaseDomain
            }
        } else {
            console.warn(
                `Could not determine base domain - no match for host: '${host}'. Defaulting to ${defaultBaseDomain}.`,
            )
            return defaultBaseDomain
        }
    }
}

/**
 * Determine if local enviornment or not based on host string
 */
export const isLocalDevelopment = (host: string): boolean => {
    return isNonProd(host) && host.startsWith("localhost")
}

export const isDemoEnvironment = (host: string): boolean => {
    return /demo/i.test(host)
}

/**
 * Generates
 * @param env Environments
 * @returns apigee host string
 * @example https://www.cambiahealthplanapis.com
 */
export const getApigeeHost = (env = ""): string => {
    const prefix = ENVIRONMENT_PREFIXES?.[env || getEnvironment()]?.apigee
    if (!prefix) {
        throw new Error(`Cannot determine apigee host for environment ${env}`)
    }
    const apigeeHost = `https://${prefix}.cambiahealthplanapis.com`
    //janusLogger.debug({ message: "determined apigee host for the environment", apigeeHost })
    return apigeeHost
}

/**
 * Could be even more generic method using service name as input
 * then it could read concatenate and get environment variable name
 * or use defaults
 * @param env
 * @returns
 */
export const getDFGHost = (env = "", isDemo = false): string => {
    const prefix =
        ENVIRONMENT_PREFIXES?.[env || getEnvironment()]?.janus || "dev"
    const defaultHost = isDemo
        ? `https://digital-first-gateway-demo.${prefix}.janusplatform.io`
        : `https://digital-first-gateway.${prefix}.janusplatform.io`
    const host = process?.env?.DIGITAL_FIRST_GATEWAY_HOST || defaultHost
    return host
}

export const getDFWHost = (): string => {
    const dfwEnv = getEnvironment()
    const baseDomain = getBaseDomain(window.location.host)
    const dfwBaseDomain =
        baseDomain === "localhost" ? "regence.com" : baseDomain
    return dfwEnv === "prd"
        ? `account.${dfwBaseDomain}`
        : `account-${dfwEnv}.${dfwBaseDomain}`
}
