/**
 * Redirect utilities
 * -----------------------------------------------------------------------------
 */
import { Routes } from "router"
import { SingletonRouter } from "next/router"
import { App } from "store/app/reducer"
import { getEnvironment, ENVIRONMENT_PREFIXES } from "utils/env"

/**
 * Define ADIF Redirects (for non-eligible Digital First users)
 * -----------------------------------------------------------------------------
 * URL Mappings:
 * https://cambiahealth.atlassian.net/wiki/spaces/APM/pages/1415054574/URL+Mapping+for+Digital+First+and+Classic
 */
export interface ADIF_REDIRECT {
    readonly default: string
    readonly medicare: string
}
export const ADIF_REDIRECTS = (id?: string): Record<string, ADIF_REDIRECT> => {
    return {
        [Routes.home()]: {
            default: "/member/dashboard/",
            medicare: "/member/dashboard/",
        },
        [Routes.pharmacyBenefits()]: {
            default: "/member/benefits/#pharmacy",
            medicare: "/medicare/pharmacy-benefits/",
        },
        [Routes.claims()]: {
            default: "/member/claims/",
            medicare: undefined,
        },
        [Routes.claimDetailADIF(id)]: {
            default: "/member/claims/?detail=" + id,
            medicare: undefined,
        },
        [Routes.payments()]: {
            default: "/member/paying-premiums/",
            medicare: "/medicare/paying-premiums/",
        },
        [Routes.settings()]: {
            default: "/member/my-account/",
            medicare: "/medicare/my-account/",
        },
        [Routes.findCare()]: {
            default: "/member/finding-doctors-by-coverage",
            medicare: "/medicare/providers",
        },
        [Routes.messages()]: {
            default: "/member/message-center",
            medicare: undefined,
        },
        // Note: Might be an incorrect DFW page?
        ["/member/members/member-appeals"]: {
            default: "/member/members/member-appeals",
            medicare: "/member/members/member-appeals",
        },
        [Routes.programsBehavioralHealth()]: {
            default: "/member/behavioral-health",
            medicare: "/medicare/behavioral-health",
        },
        [Routes.programsMaternity()]: {
            default: "/member/programs/health-support-services/maternity",
            medicare: "/medicare/programs/health-support-services/maternity",
        },
        [Routes.programsTelehealth()]: {
            default: "/member/programs/health-support-services/telehealth",
            medicare: "/medicare/programs/health-support-services/telehealth",
        },
        [Routes.forms()]: {
            default: "/member/resources/forms-documents",
            medicare: "/medicare/resources",
        },
        [Routes.costEstimates()]: {
            default: "/member/members/estimate-treatment-costs",
            medicare: undefined,
        },
        [Routes.eobs()]: {
            default: "/member/eob-statements",
            medicare: undefined,
        },
        [Routes.support()]: {
            default: "/member/contact-us",
            medicare: "/medicare/contact-us",
        },
        [Routes.taxForms()]: {
            default: "/member/member-dashboard/about-your-tax-forms",
            medicare: "/member/member-dashboard/about-your-tax-forms",
        },
        ["/member/welcome"]: {
            default: "/member/welcome/",
            medicare: "/member/welcome/",
        },
        ["/member/go-paperless"]: {
            default: "/member/go-paperless/",
            medicare: "/member/go-paperless/",
        },
        ["/medicare/paperless-options"]: {
            default: "/medicare/paperless-options/",
            medicare: "/medicare/paperless-options/",
        },
        ["/member/sms"]: {
            default: "/member/sms/",
            medicare: "/member/sms/",
        },
        ["/member/activation"]: {
            default: "/member/activation/",
            medicare: "/member/activation/",
        },
    }
}

/**
 * ADIF URLs whitelist to allowed redirect to ADIF classic even when user is `digitalFirstEligible`
 */
const ADIF_URLS_WHITELIST = [
    "/member/welcome",
    "/medicare/paperless-options",
    "/member/go-paperless",
    "/member/sms",
]

const ADIF_URLS_WHITELIST_CONDITIONAL = ["/member/activation"]

/**
 * Redirect to Regence Classic
 * -----------------------------------------------------------------------------
 */
export const getAdifRedirect = async (data: {
    readonly router: SingletonRouter
    readonly userMeta: App["userMeta"]
    readonly features: App["features"]
}): Promise<string | undefined> => {
    try {
        /**
         * If missing data needed to determine redirect, do nothing
         */
        const hasFeatures = !!Object.keys(data.features).length
        const hasMeta = !!Object.keys(data.userMeta).length
        const hasPendingOnboarding = Object.values(data.userMeta.pending).some(
            onboardingState => onboardingState === true,
        )
        if (!hasFeatures || !hasMeta || hasPendingOnboarding) return undefined

        /**
         * Define path
         */
        const path = data.router.asPath

        /**
         * If user is `digitalFirstEligible` and not included in ADIF URLs whitelist, do nothing
         */
        if (
            data.features.digitalFirstEligible &&
            !ADIF_URLS_WHITELIST.includes(path)
        )
            return undefined
        if (
            data.features.digitalFirstEligible &&
            data.features.dfwMyAccountEnabled &&
            ADIF_URLS_WHITELIST_CONDITIONAL.includes(path)
        )
            return undefined

        /**
         * Define resource id
         *
         * NOTE: id only supports the `claims/?detail=<id>` endpoint for now
         */
        const params = new URLSearchParams(window.location.search)
        const id = params.get("detail")

        /**
         * Define ADIF route to redirect to
         */
        const redirect = ADIF_REDIRECTS(id)[path]
        if (!redirect) return undefined
        const isMedicare = data.userMeta.audience === "medicare"
        const redirectPath = redirect[isMedicare ? "medicare" : "default"]
        if (!redirectPath) return undefined

        /**
         * Define Regence url
         */
        const environment = getEnvironment()
        const prefixes = ENVIRONMENT_PREFIXES[environment]
        const brand =
            data.userMeta.brand === "bridgespan"
                ? "bridgespanhealth"
                : data.userMeta.brand || "regence"
        const basePath = `https://${prefixes.regence}.${brand}.com`

        /**
         * Return the redirect endpoint
         */
        return basePath + redirectPath
    } catch (err) {
        console.debug("get adif redirect failed", err)
        return undefined
    }
}
