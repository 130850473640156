import { getEnvironment } from "@/utils"


const isDebug = () => {
    const env = getEnvironment()
    return !["uat", "prd"].includes(env)
}

export const debugLog = (...args) => {
    if (isDebug()) {
        // eslint-disable-next-line no-console
        console.log(...args)
    }
}
