/**
 * Benefits Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions } from "store"
import http from "utils/http"
import { transformBenefitsAPIData } from "@/store/benefits/utils"
import { benefitsQuery } from "@/store/benefits/queries"
import { BenefitsType } from "@/store/benefits/types"

/**
 * Get benefits data
 * -----------------------------------------------------------------------------
 */
export interface Props {
    readonly type: BenefitsType
}
export const fetchCareGapsBenefits = createAsyncThunk(
    "benefits/fetchCareGapsBenefits", // Reducer name
    async (params: Props, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.careGapsBenefitsStatus("pending"))

        /**
         * Request benefits data
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            benefitsQuery(params.type),
        )

        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.careGapsBenefitsError({
                    response: res,
                }),
            )
        }

        /**
         * Transform benefit data
         */
        const transformed = transformBenefitsAPIData(res.data?.data)
        const viewableMembers = res.data?.data?.benefits?.familyMembers.filter(member=>member.isSharingAuthorized === true)

        /**
         * Update state
         */
        dispatch(
            actions.receiveCareGapsBenefits({
                benefits: transformed.benefits || {},
                benefitsByMembershipId: transformed.benefitsByMembershipId || {},
                family: {
                    items: viewableMembers  || [],
                },
            }),
        )
    },
)
