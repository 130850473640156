import * as React from "react"
import { SVGProps } from "react"
const SvgInfoCircle = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Information sign"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11.9941 16.7958C11.9941 15.1649 11.9885 13.5338 11.9946 11.9029M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.9975 7.85691C12.068 7.85691 12.1251 7.91422 12.1251 7.98491C12.1251 8.05561 12.068 8.11292 11.9975 8.11292"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M11.9976 8.11293C11.9271 8.11293 11.87 8.05563 11.87 7.98493C11.87 7.91424 11.9271 7.85693 11.9976 7.85693"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgInfoCircle
