/**
 * Search-like Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import { rxSearchLikeQuery } from "./queries"
import http from "utils/http"
import { transformSearchLikeData} from "./utils"

export interface MedicationSearchProps {
    readonly searchTerm?: string
    readonly selected?: boolean
}

/**
 * Get search-like data
 * -----------------------------------------------------------------------------
 */
export const fetchSearchLikeResults = createAsyncThunk(
    "search/fetchSearchLike", // Reducer name

    async (props: MedicationSearchProps, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.receiveSearchLikeResults({ isLoading: true }))

        const { searchLikeResults } = store.getState()
        /**
         * Request data from digital-first-information-service
         */
        const query = rxSearchLikeQuery(props.searchTerm)

        const res = await http.query(
            "/api/digital-first-information-service/graphql",
            query,
        )

        /**
         * Handle request errors
         */
        if (res.error) {
            console.error(res.error)
            return dispatch(
                actions.receiveSearchLikeResults({
                    ...res,
                    isLoading: false,
                    isInitialized: true,
                    errorMessage: "Could not retrieve search data",
                }),
            )
        }

        /**
         * Transform Search Results
         */
        const data = res.data?.data?.rxSearch
        const item = data ? transformSearchLikeData(data)?.searchLikeResults : undefined

        /**
         * Update state
         */
        dispatch(
            actions.receiveSearchLikeResults({
                ...searchLikeResults,
                item: item,
                isLoading: false,
                isInitialized: true,
                errorMessage: undefined,
            }),
        )
    },
)
