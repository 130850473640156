import {AccountInformation, MemberAccountSettings, PaymentHistory} from "./types"
import { separateDate } from "@/utils/date"

export const ACCOUNT_SETTINGS_PREV_URL_KEY = "ACCOUNT_SETTINGS_PREV_URL_KEY"
export const ACCOUNT_SETTINGS_SMS_IN_PROGRESS_KEY = "ACCOUNT_SETTINGS_SMS_IN_PROGRESS_KEY"
export const ACCOUNT_SETTINGS_IN_PROGRESS_NEW_PHONE_KEY = "ACCOUNT_SETTINGS_IN_PROGRESS_NEW_PHONE_KEY"

export const transformAccountSettings = (
    data: any, // eslint-disable-line
): MemberAccountSettings => {
    return {
        accountInformation: {
            userName: data.user.username,
            phone: getPhoneNumber(data.member, data.sms),
            email: data.member.email?.address,
            address: {
                address1: data.member.enrollment.billingAddress?.address1,
                address2: data.member.enrollment.billingAddress?.address2,
                city: data.member.enrollment.billingAddress?.city,
                state: data.member.enrollment.billingAddress?.state,
                zip: data.member.enrollment.billingAddress?.zip,
            },
            residentialAddress: {
                address1: data.member.enrollment.homeAddress?.address1,
                address2: data.member.enrollment.homeAddress?.address2,
                city: data.member.enrollment.homeAddress?.city,
                state: data.member.enrollment.homeAddress?.state,
                zip: data.member.enrollment.homeAddress?.zip,
            },
            billingAddress: {
                address1: data.member.enrollment.realBillingAddress?.address1,
                address2: data.member.enrollment.realBillingAddress?.address2,
                city: data.member.enrollment.realBillingAddress?.city,
                state: data.member.enrollment.realBillingAddress?.state,
                zip: data.member.enrollment.realBillingAddress?.zip,
            },
            loginType: data.user.loginType
        },
        financeInformation: {
            claimsPreference: data.member.preferences?.heq?.value,
            hsaPreference: data.member.preferences?.consumerSetting?.setting,
            paymentHistory: transformPaymentHistory(
                data.member.enrollment?.premium?.history,
            ),
        },
        contactPreference: {
            permission: data.member.permissions?.paperless,
            paperless: data.member.preferences?.paperless
        },
        communicationPreferences: {
            preferences: data.member.preferences?.communications.preferences,
            programs: data.member.preferences?.communications.programs
        },
        lettersNotices: {
            letterList: data.member.machineLetters
        },
        familySharing: {
            show: data.member.shareInfo?.show,
            familyShareInfoList: data.member.shareInfo?.familyShareInfo,
        }
    }
}

const transformPaymentHistory = (
    paymentHistory: any, // eslint-disable-line
): ReadonlyArray<PaymentHistory> => {
    return paymentHistory?.map(paymentHistory => {
        return {
            billingId: paymentHistory.billingId,
            checkNumber: paymentHistory.checkNumber,
            groupOrgznId: paymentHistory.groupOrgznId,
            paymentHistoryId: paymentHistory.paymentHistoryId,
            paymentType: paymentHistory.paymentType,
            reasonCode: paymentHistory.reasonCode,
            receiptAmount: paymentHistory.receiptAmount,
            receiptCode: paymentHistory.receiptCode,
            receiptCreateDate: paymentHistory.receiptCreateDate,
            receiptDate: paymentHistory.receiptDate,
            subscriberId: paymentHistory.subscriberId,
            // extract payment year
            paymentYear: separateDate(paymentHistory.receiptDate)?.year,
        }
    })
}

/**
 * Get User Phone Number
 */
export const getPhoneNumber = (
    member: any, // eslint-disable-line
    sms: any, // eslint-disable-line
): string => {
    const phone =
        member.preferences?.communications?.address?.attributes?.address?.phone
    if (sms.number && sms.activated) {
        return formatPhone(sms.number)
    } else if (phone) {
        return formatPhone(phone)
    } else {
        return ""
    }
}

export const formatPhone = (phone: string): string => {
    // eslint-disable-next-line functional/no-let
    let formattedPhone = ""
    if (phone) {
        const cleaned = phone.replace(/\D/g, "")
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            formattedPhone = `(${match[1]})  ${match[2]}-${match[3]}`
        }
    }
    return formattedPhone
}

export const formatPhoneDashes = (phone: string): string => {
    // eslint-disable-next-line functional/no-let
    let formattedPhone = ""
    if (phone) {
        const cleaned = phone.replace(/\D/g, "")
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            formattedPhone = `${match[1]}-${match[2]}-${match[3]}`
        }
    }
    return formattedPhone
}

/**
 * Get medicare address
 */
export const getMedicareAddress = (accountInformation: AccountInformation, addressType: string) => {
    //addressType === "residential" ? accountInformation.residentialAddress : accountInformation.address
    switch (addressType) {
        case "billing":
            return accountInformation.billingAddress
        case "residential":
            return accountInformation.residentialAddress
        default:
            return accountInformation.address
    }
}