import * as React from "react"
import { SVGProps } from "react"
const SvgArrowPathClock = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Arrow pointed in a circle with a clock inside"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M1.00037 2.87152V9.71644M1.00037 9.71644H7.60083M1.00037 9.71644L6.09339 4.74243C7.13846 3.65751 8.40465 2.83041 9.79845 2.31932C14.9545 0.430124 20.61 3.23312 22.4317 8.58015C24.2535 13.926 21.5506 19.7921 16.3945 21.6813C11.2395 23.5705 5.58295 20.7675 3.76122 15.4205M13.0995 8.2204V12.4136C13.0995 12.5462 13.1521 12.6734 13.2459 12.7672L15.2996 14.8209"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgArrowPathClock
