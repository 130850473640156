import * as React from "react"
import { SVGProps } from "react"
const SvgChart = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Chart" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1M23 12C23 5.92487 18.0751 1 12 1M23 12H13C12.4477 12 12 11.5523 12 11V1"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
        />
    </svg>
)
export default SvgChart
