/**
 * Message Center Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions } from "store"
import { http } from "utils"
import {
    fetchInboxMailsQuery,
    fetchSentMailsQuery,
    fetchMailSubjectQuery,
    sendNewMessageMutation,
    fetchMessageDetailsQuery,
    updateMessageReadStatusMutation,
    deleteMessagesQuery,
    deleteMessageQuery,
} from "./queries"
import { SentMessagesData } from "./types"
import {
    transformInboxMesages,
    transformMessageTopics,
    transformSentMesages,
} from "./utils"
import { Response } from "@/utils/http"

export const getInboxMails = createAsyncThunk(
    "message-center/getInboxMails",
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.messageCenterStatus("pending"))

        /**
         * Request inbox messages data
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            fetchInboxMailsQuery,
        )

        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.messageCenterError({
                    response: res,
                }),
            )
        }

        const inboxMessagesData =
            res?.data?.data?.messageCenter?.inboxMessages?.edges
                ?.map(d => ({ ...d.node }))
                .reverse()

        const inboxMessagesList = transformInboxMesages(inboxMessagesData)
        /**
         * Update state
         */
        dispatch(
            actions.receiveInboxMessages({
                inbox: {
                    inboxMessages: inboxMessagesList.inboxMessages || [],
                    unreadMsgsCount: inboxMessagesList.unreadMsgsCount || 0,
                },
            }),
        )
    },
)

export const getSentMails = createAsyncThunk(
    "message-center/getSentMails",
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.messageCenterStatus("pending"))

        /**
         * Request sent messages data
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            fetchSentMailsQuery,
        )

        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.messageCenterError({
                    response: res,
                }),
            )
        }

        const sentMessagesData =
            res?.data?.data?.messageCenter?.sentMessages?.edges
                ?.map(d => ({ ...d.node }))
                .reverse()
        const sentMessagesList = transformSentMesages(sentMessagesData)
        /**
         * Update state
         */
        dispatch(
            actions.receiveSentMessages({
                sent: {
                    sentMessages: sentMessagesList.sentMessages || [],
                },
            }),
        )
    },
)

export const getMessageDetails = createAsyncThunk(
    "message-center/getMessageDetails",
    async (msgId: string, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.messageCenterStatus("pending"))

        /**
         * Request Message Details
         */
        const res = await http.mutation(
            "/api/janus/digital-first-information-service/graphql",
            fetchMessageDetailsQuery,
            {
                messageId: msgId,
            },
        )

        if (res.error) {
            return dispatch(
                actions.messageCenterError({
                    response: res,
                }),
            )
        }

        const getMessageData = res?.data?.data?.messageCenter?.messageDetails

        /**
         * Update state
         */
        dispatch(
            actions.receiveMessageDetails({
                messageDetails: getMessageData || [],
            }),
        )
    },
)

export const getMessageTopics = createAsyncThunk(
    "message-center/getMessageTopics",
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.messageCenterStatus("pending"))

        /**
         * Request sent messages data
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            fetchMailSubjectQuery,
        )

        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.messageCenterError({
                    response: res,
                }),
            )
        }

        const messageTopicsData = res?.data?.data?.messageCenter?.messageTopics

        const messageTopicsList = transformMessageTopics(messageTopicsData)

        /**
         * Update state
         */
        dispatch(
            actions.receiveMessageTopics({
                messages: {
                    messageTopics: messageTopicsList.messageTopics || [],
                },
            }),
        )
    },
)

export const sendNewMessage = createAsyncThunk(
    "message-center/sendNewMessage",
    async (newMessagedata: SentMessagesData, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.messageCenterStatus("pending"))

        /**
         * Request sent messages data
         */
        const url = "/api/janus/digital-first-information-service/graphql"
        const query = sendNewMessageMutation
        const res = await http.mutation(url, query, {
            mailboxCode: newMessagedata?.mailboxCode,
            subject: newMessagedata?.subject,
            body: newMessagedata?.body,
            originalMessageId: newMessagedata?.originalMessageId,
        })

        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.messageCenterError({
                    response: res,
                }),
            )
        }

        const sendMessageData = res?.data?.data?.createMessage

        /**
         * Update state
         */

        dispatch(
            actions.receiveCreateMessage({
                resultStatus: sendMessageData.resultStatus || "MISSING_STATUS",
            }),
        )

        return Promise.resolve(sendMessageData)
    },
)

export const updateMessageReadStatus = createAsyncThunk(
    "message-center/updateMessageReadStatus ",
    async (msgId: string, { dispatch }) => {
        /**
         * Request to update info
         */

        try {
            const res = await http.mutation(
                "/api/janus/digital-first-information-service/graphql",
                updateMessageReadStatusMutation,
                {
                    messageId: msgId,
                },
            )
            if (!res || res?.error || res?.status !== 200) {
                console.error(
                    "Failed to update status",
                    res?.error,
                    res?.status,
                    msgId,
                )
            }
            const updateStatus = res?.data?.data?.markMessageRead?.resultStatus
            dispatch(
                actions.receiveMessageUpdateStatus({
                    resultStatus: updateStatus,
                }),
            )
        } catch (err) {
            console.error("Failed to update mail status", msgId, err)
        }
    },
)

export const deleteMessage = createAsyncThunk(
    "message-center/deleteMails",
    async (params: readonly string[], { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.messageCenterStatus("pending"))

        /**
         * Request to get info
         */
        const url = "/api/janus/digital-first-information-service/graphql"

        // eslint-disable-next-line functional/no-let
        let res: Response
        if (params.length == 1) {
            res = await http.mutation(url, deleteMessageQuery, {
                messageId: params.toString(),
            })
        } else if (params.length > 1) {
            res = await http.mutation(url, deleteMessagesQuery, {
                messageIds: JSON.parse(JSON.stringify(params)),
            })
        }

        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.messageCenterError({
                    response: res,
                }),
            )
        }

        const delMessageData =
            res?.data?.data?.deleteMessage || res?.data?.data?.deleteMessages

        /**
         * Update state
         */

        dispatch(
            actions.receiveDeleteMessage({
                resultStatus: delMessageData.resultStatus,
            }),
        )

        return Promise.resolve(delMessageData)
    },
)
