/**
 * Feedback
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Button, Checkbox, useForm, yup } from "heartwood-component-library"
import { toKebabCase } from "utils/string"
import { tagEvent } from "utils/analytics"
import { getLocalStorageItem, setLocalStorageItem } from "utils/window-storage"
import i18next from "i18next"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props {
    readonly name: string
    readonly label: string
    readonly resetOption?: boolean
    readonly options: ReadonlyArray<{
        readonly label: string
        readonly title: string
        readonly values: ReadonlyArray<string>
    }>
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Feedback = (props: Props) => {
    /**
     * Component state
     */
    const [optionIndex, setOptionIndex] = React.useState<number>()
    const [optionValues, setOptionValues] = React.useState([])
    const [submitted, setSubmitted] = React.useState(false)

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        // Show "Success" if feedback submitted during session
        const hasSubmitted = getLocalStorageItem(sessionSubmittedName)
        if (hasSubmitted) setSubmitted(true)

        // Persist option if selected during session
        const hasOption = getLocalStorageItem(sessionOptionName)
        if (props.options[hasOption]) setOptionIndex(hasOption)

        // Feedback for Insights (non_rx campaign)
        if (props.resetOption && !hasSubmitted) setOptionIndex(undefined)
    }, [])

    /**
     * Methods
     */
    const onSubmit = () => {
        if (optionValues.length) {
            optionValues.forEach(value => {
                tagEvent({
                    tealium_event: "form_submitted",
                    data_analytics_id: toKebabCase(value) + "-checked",
                    link_text: value,
                    link_url: "",
                })
            })

            setSubmitted(true)
            setOptionIndex(undefined)
            setLocalStorageItem(sessionSubmittedName, true)
        }
    }
    const onSelectOption = (index: number) => {
        setOptionIndex(index)
        setLocalStorageItem(sessionOptionName, index)
    }
    const onSelectValue = (value: string) => {
        if (optionValues.includes(value)) {
            setOptionValues(optionValues.filter(o => o !== value))
        } else {
            setOptionValues([...optionValues, value])
        }
    }

    /**
     * Form
     */
    // const form = useForm({
    //     initialValues: { checkbox: false },
    //     validationSchema: yup.object().shape({
    //         checkbox: yup.boolean().oneOf([true], "Please select an option"),
    //     }).required,
    //     onSubmit,
    // })

    /**
     * Define template variables
     */
    const options = props.options[optionIndex] || undefined
    const sessionSubmittedName = props.name + "-submitted"
    const sessionOptionName = props.name + "-option"
    // const hasSubmitted = form.submitCount !== 0 // Has submit been attempted?
    // const errors = hasSubmitted ? form.errors : {}
    // const hasValue = !optionValues.length

    /**
     * Template: Feedback Submitted
     */
    if (submitted) {
        return (
            <React.Fragment>
                <strong className="block mb-xs">
                    {i18next.t("feedbackCard.thankYou")}
                </strong>
                <p>{i18next.t("feedbackCard.thankYouBody")}</p>
            </React.Fragment>
        )
    }

    /**
     * Template: Option Selected
     */
    if (options)
        return (
            <React.Fragment>
                <strong className="block mb-xs">{options.title}</strong>

                {options.values.map((value, index) => {
                    const formattedValue = toKebabCase(value)
                    const name = `${props.name}-${formattedValue}-checkbox`
                    return (
                        <div key={index} className="mb-xs">
                            <Checkbox
                                name={name}
                                label={value}
                                value={optionValues.includes(value)}
                                setValue={() => onSelectValue(value)}
                            />
                        </div>
                    )
                })}

                <div className="flex justify-end">
                    <Button
                        name={props.name + "-submit-button"}
                        label="Submit feedback"
                        onClick={onSubmit}
                        disabled={!optionValues.length}
                    />
                </div>
            </React.Fragment>
        )

    /**
     * Template: Default
     */
    return (
        <React.Fragment>
            <strong className="block mb-xs">{props.label}</strong>
            <div className="flex justify-center">
                {props.options.map((option, index) => {
                    const label = toKebabCase(option.label)
                    return (
                        <Button
                            name={`${props.name}-${label}-button`}
                            label={option.label}
                            variant="link"
                            className="px-0 bg-gray-25 last-of-type:ml-xxs"
                            onClick={() => onSelectOption(index)}
                            key={index}
                        />
                    )
                })}
            </div>
        </React.Fragment>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Feedback
