import * as React from "react"
import { SVGProps } from "react"
const SvgStar = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Star" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M12 3.00018L15 9.00018L21 10.0002L17 14.0002L18 21.0002L12 17.0002L6 21.0002L7 14.0002L3 10.0002L9 9.00018L12 3.00018Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgStar
