import { getSmsVerificationStatus } from "../account/utils";
import { AddressTypes, Profile } from "./types";

/**
 * Transform Regence account data from DFIS
 * -----------------------------------------------------------------------------
 */

export const transformMemberSettings = ( 
    data: any // eslint-disable-line 
): Profile => {
    return {
        firstName: data.profile?.firstName,
        lastName: data.profile?.lastName,
        birthDate: data.profile?.birthDate,
        username: data.accountInformation?.username,
        email: data.accountInformation?.email,
        phone: {
            activated :data.accountInformation?.phone?.activated,
            number :data.accountInformation?.phone?.number
        },
        emailVerificationStatus: getEmailVerificationStatus(data.accountInformation?.isEmailActivated),
        smsVerificationStatus: getSmsVerificationStatus(data.accountInformation?.phone),
        preferences: {
            communicationPreferences: data.preferences?.communicationPreferences,
            paperless: data.preferences?.paperless,
            hsaPreferences: data.preferences?.hsaPreferences
        },
        familySharing: {
            show: data.familySharing?.show,
            familyShareInfoList: data.familySharing?.familyShareInfo
        },
        address: {
            homeAddress: {
                address1: data.accountInformation?.homeAddress?.address1,
                address2: data.accountInformation?.homeAddress?.address2,
                city: data.accountInformation?.homeAddress?.city,
                state: data.accountInformation?.homeAddress?.state,
                zip: data.accountInformation?.homeAddress?.zip
            },
            billingAddress: {
                address1: data.accountInformation?.billingAddress?.address1,
                address2: data.accountInformation?.billingAddress?.address2,
                city: data.accountInformation?.billingAddress?.city,
                state: data.accountInformation?.billingAddress?.state,
                zip: data.accountInformation?.billingAddress?.zip
            },
            mailingAddress: {
                address1: data.accountInformation?.mailingAddress?.address1,
                address2: data.accountInformation?.mailingAddress?.address2,
                city: data.accountInformation?.mailingAddress?.city,
                state: data.accountInformation?.mailingAddress?.state,
                zip: data.accountInformation?.mailingAddress?.zip
            },
        }
    }
}

/**
 * Returns member's current email verification status.
 * @returns VERIFIED | PENDING | UNKNOWN
 */
export const getEmailVerificationStatus = status => {
    if (status === true) {
        return "VERIFIED"
    } else if (status === false) {
        return "PENDING"
    } else {
        return "UNKNOWN"
    }
}

/**
 * Get medicare address
 */
export const getMedicareAddress = (accountInformation: AddressTypes, addressType: string) => {
    //addressType === "residential" ? accountInformation.residentialAddress : accountInformation.address
    switch (addressType) {
        case "billing":
            return accountInformation.billingAddress
        case "mailing":
            return accountInformation.mailingAddress
        default:
            return accountInformation.homeAddress
    }
}

export const MY_ACCOUNT_SETTINGS_IN_PROGRESS_NEW_EMAIL_KEY = "MY_ACCOUNT_SETTINGS_IN_PROGRESS_NEW_EMAIL_KEY"