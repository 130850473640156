import { EobFaqs } from "./reducer"

/**
 * Eob Statement Redux shape
 * -----------------------------------------------------------------------------
 */
export interface Eob {
    readonly id: string
    readonly name: string
    readonly date: string
    readonly desc: string
    readonly url: string
}

export interface EobFaq {
    readonly id: string
    readonly question: string
    readonly answer: string
}

export const transformEobFaq = (
    data: any, // eslint-disable-line
): EobFaqs => {
    const faqs = data?.sections
    return faqs.map(faq => {
        return {
            title: faq?.title,
            bodyText: faq?.bodyText,
        }
    })
}
