import * as React from "react"
import { SVGProps } from "react"
const SvgBell = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Bell" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M14.5857 20.8104C14.3804 21.163 14.1039 21.4721 13.7712 21.7164C13.2577 22.0936 12.6372 22.297 12 22.297C11.3629 22.297 10.7423 22.0936 10.2288 21.7164C9.89613 21.4721 9.61962 21.163 9.4143 20.8104M17.6 10.9V8.49999C17.6 7.01478 17.01 5.5904 15.9598 4.5402C14.9096 3.48999 13.4852 2.89999 12 2.89999C10.5148 2.89999 9.09041 3.48999 8.0402 4.5402C6.99 5.5904 6.4 7.01478 6.4 8.49999V10.9C6.4 13.54 4 14.18 4 15.7C4 17.06 7.12 18.1 12 18.1C16.88 18.1 20 17.06 20 15.7C20 14.18 17.6 13.54 17.6 10.9Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgBell
