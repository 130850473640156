export const benefitDocumentsMDPSQuery = `
query{
       fetchableBenefitDocuments
       {
           id
           language
           type
           coverageType
           name
           url
       }
}`

export const mpsBenefitProductIdQuery = `
query {
       primaryPolicy {
              memberships {
                     primaryCoverage {
                            suffix
                            primaryEligibility {
                                   productId
                                   category
                            }
                     }
              }
       }
}
`
export const benefitProductIdQuery = `
 query {
        enrollment {
                     family{
                            subscriber {
                                   benefits {
                                          productId
                                          coverageCategory
                                   }
                            }
                     }
              }
       }`
