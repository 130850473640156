/**
 * Window Event Utilities
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { useDispatch, useSelector, actions, store } from "store"

/**
 * Keypress Listener
 * -----------------------------------------------------------------------------
 */
export const useKeyPress = (keyName: string, action?: VoidFunction) => {
    /**
     * State
     */
    const [keyPressed, setKeyPressed] = React.useState(false)

    /**
     * Methods
     */
    const onKeyDown = (event: KeyboardEvent) => {
        if (event.key === keyName) {
            setKeyPressed(true)
            if (action) action()
        }
    }
    const onKeyUp = (event: KeyboardEvent) => {
        if (event.key === keyName) setKeyPressed(false)
    }

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        window.addEventListener("keydown", onKeyDown)
        window.addEventListener("keyup", onKeyUp)
        return () => {
            window.removeEventListener("keydown", onKeyDown)
            window.removeEventListener("keyup", onKeyUp)
        }
    }, [])

    /**
     * Return value
     */
    return keyPressed
}

/**
 * Focus Next/Previous Node on Keypress Down/Up
 * -----------------------------------------------------------------------------
 */
export const useFocusNext = (selector: string) => {
    /**
     * Methods
     */
    const onKeyDown = (event: KeyboardEvent) => {
        if (["Up", "ArrowUp"].includes(event.key)) {
            event.preventDefault()
            navigateNodes("previous")
        }
        if (["Down", "ArrowDown"].includes(event.key)) {
            event.preventDefault()
            navigateNodes("next")
        }
    }
    const navigateNodes = (direction: "previous" | "next") => {
        try {
            const nodes = document.querySelectorAll(selector)
            const active = document.activeElement
            const children = Array.from(nodes as NodeListOf<HTMLElement>)

            let index = 0 // eslint-disable-line
            children.forEach((button, _index) => {
                if (button === active) {
                    index = direction === "next" ? _index + 1 : _index - 1
                }
            })
            if (children[index]) {
                children[index].focus()
            }
        } catch (e) {
            console.log(e) // eslint-disable-line
        }
    }

    /**
     * Return hook methods
     */
    return {
        addListener: () => {
            window.addEventListener("keydown", onKeyDown)
        },
        removeListener: () => {
            window.removeEventListener("keydown", onKeyDown)
        },
    }
}

/**
 * Prevent scrolling on body
 * -----------------------------------------------------------------------------
 */
export const useBodyScroll = () => {
    /**
     * Select body element
     */
    const body = document.querySelector("body")

    /**
     * Return hook methods
     */
    return {
        enable: () => {
            if (body) {
                /* eslint-disable */
                body.ontouchstart = undefined
                body.className = body.className.replace(" overflow-hidden", "")
                /* eslint-enable */
            }
        },
        disable: () => {
            if (body) {
                /* eslint-disable */
                body.ontouchstart = e => e.preventDefault()
                body.className = body.className + " overflow-hidden"
                /* eslint-enable */
            }
        },
    }
}

/**
 * "Click away" Listener
 */
export const useClickAway = (action?: VoidFunction) => {
    /**
     * State
     */
    const [clickedAway, setClickedAway] = React.useState(false)

    /**
     * Refs
     */
    const ref = React.useRef(null)

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        window.addEventListener("mousedown", onMouseDown)
        return () => {
            window.removeEventListener("mousedown", onMouseDown)
        }
    }, [])

    const onMouseDown = (event: MouseEvent) => {
        if (
            ref.current !== null &&
            !ref.current?.contains(event.target as Node)
        ) {
            setClickedAway(true)
            if (action) action()
        } else {
            setClickedAway(false)
        }
    }

    return { ref, clickedAway }
}

/**
 * Dismiss Modal on "Escape" keypress
 * -----------------------------------------------------------------------------
 * Since we have multiple layers of modals we want to listen for "Escape"
 * and dismiss only the top-most modal.
 *
 * For instance, if a Popover and an Overlay are both visible, the Overlay
 * is "above" the Popover, so typing "Escape" should only dismiss the
 * Overlay, and a second "Escape" will then dismiss the Popover.
 */
export const useEscapeModal = (name: string, onDismiss: VoidFunction) => {
    /**
     * Hooks
     */
    const dispatch = useDispatch()
    const app = useSelector(state => state.app)

    /**
     * State
     */
    const [keyPress, setKeyPress] = React.useState("")

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        if (keyPress) {
            if (keyPress === "Escape" && app.modals[0] === name) {
                onDismiss() // Dismiss the modal (if top of stack)
            }
            setKeyPress("") // Clear previous keypress
        }
    }, [keyPress]) // eslint-disable-line

    /**
     * Methods
     */
    const onKeyDown = (event: KeyboardEvent) => setKeyPress(event.key)
    const updateSessionModals = (push: boolean) => {
        // Add/ remove modal name from app modal stack

        /**
         * This inner function is defined once at first render. 
         * We must get `app` directly from store within the scope of this inner function,
         * because the reference to `app` from the outer function's may be out of date
         * when this function is called (i.e., still the version of `app` from first render).
         */ 
        const app = store.getState().app

        if (push) {
            const modals = [name, ...app.modals]
            dispatch(actions.receiveApp({ ...app, modals }))
        } else {
            const modals = app.modals.filter(m => m !== name)
            dispatch(actions.receiveApp({ ...app, modals: modals || [] }))
        }
    }

    /**
     * Return hook methods
     */
    return {
        addListener: () => {
            setKeyPress("")
            updateSessionModals(true)
            window.addEventListener("keydown", onKeyDown)
        },
        removeListener: () => {
            updateSessionModals(false)
            window.removeEventListener("keydown", onKeyDown)
        },
    }
}
