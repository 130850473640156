import * as React from "react"
import { SVGProps } from "react"
const SvgMessageBubble = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Message bubble"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M4.2 18.6C2.4 18.6 1 17.2 1 15.6V5C1 3.3 2.5 2 4.2 2H19.7C21.5 2 23 3.3 23 5V15.6C23 17.3 21.5 18.6 19.8 18.6H17.3L16.9 22L10.9 18.6H4.2Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.9976 10.194C11.8919 10.194 11.8062 10.1081 11.8062 10.002C11.8062 9.89601 11.8919 9.81006 11.9976 9.81006"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M11.9976 9.81C12.1033 9.81 12.1889 9.89596 12.1889 10.002C12.1889 10.108 12.1033 10.194 11.9976 10.194"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M6.99758 10.194C6.89189 10.194 6.80621 10.1081 6.80621 10.002C6.80621 9.89601 6.89189 9.81006 6.99758 9.81006"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M6.99758 9.81C7.10326 9.81 7.18894 9.89596 7.18894 10.002C7.18894 10.108 7.10326 10.194 6.99758 10.194"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M16.9976 10.194C16.8919 10.194 16.8062 10.1081 16.8062 10.002C16.8062 9.89601 16.8919 9.81006 16.9976 9.81006"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M16.9976 9.81C17.1033 9.81 17.1889 9.89596 17.1889 10.002C17.1889 10.108 17.1033 10.194 16.9976 10.194"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgMessageBubble
