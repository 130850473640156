import * as React from "react"
import { SVGProps } from "react"
const SvgPin = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Pin" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M20 6.3002C23.8 13.9002 14.5 21.3002 12.4 22.8002C12.1 23.0002 11.8 23.0002 11.6 22.8002C9.8 21.6002 3 16.2002 3 10.1002C3 5.5002 6.4 1.7002 10.8 1.1002C14.7 0.6002 18.3 2.8002 20 6.3002Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M12 12.0002C13.6569 12.0002 15 10.6571 15 9.0002C15 7.34334 13.6569 6.0002 12 6.0002C10.3431 6.0002 9 7.34334 9 9.0002C9 10.6571 10.3431 12.0002 12 12.0002Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgPin
