/**
 * Medications reducer
 * -----------------------------------------------------------------------------
 */
import { createSlice, PayloadAction    } from "@reduxjs/toolkit"
import * as actions from "./actions"
import {  Medication, FamilyMember } from "./types"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Medications {
    // Base props
    readonly isInitialized: boolean
    readonly isLoading: boolean
    readonly errorMessage?: string
    readonly isError?: boolean

    // Resource props
    readonly familyMembers: ReadonlyArray<FamilyMember>
    readonly items: ReadonlyArray<Medication>
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: Medications = {
    isInitialized: false,
    isLoading: false,
    errorMessage: undefined,
    items: [],
    familyMembers: [],
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const medications = createSlice({
    name: "medications",
    initialState,
    reducers: {
        receiveMedications: (
            state: Medications,
            action: PayloadAction<Medications>,
        ) => {
            return action.payload
        },
        resetMedications: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const medicationsActions = { ...medications.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default medications.reducer
