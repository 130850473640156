import * as React from "react"
import { SVGProps } from "react"
const SvgClipboardPulse = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Clipboard with pulse inside"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9.8 3.2C9.8 2 10.8 1 12 1C13.2 1 14.2 2 14.2 3.2M4 13.7614H6.489C6.69964 13.7615 6.90558 13.7058 7.08085 13.6015C7.25613 13.4971 7.39291 13.3487 7.47393 13.175L9.3229 9.2901C9.36513 9.20134 9.43652 9.12611 9.52764 9.07437C9.61875 9.02263 9.72529 8.9968 9.83318 9.00032C9.94107 9.00383 10.0452 9.03651 10.1319 9.09403C10.2185 9.15156 10.2836 9.23123 10.3185 9.3225L13.6851 18.6775C13.7199 18.7688 13.785 18.8484 13.8717 18.906C13.9583 18.9635 14.0625 18.9962 14.1704 18.9997C14.2783 19.0032 14.3848 18.9774 14.4759 18.9256C14.567 18.8739 14.6384 18.7987 14.6807 18.7099L16.5261 14.3478C16.6071 14.1741 16.7439 14.0258 16.9191 13.9214C17.0944 13.817 17.3004 13.7614 17.511 13.7614H20M8 6H16M17.5 23H6.5C5.1 23 4 21.8 4 20.5V5C4 4.44772 4.44772 4 5 4H19C19.5523 4 20 4.44772 20 5V20.5C20 21.8 18.9 23 17.5 23Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgClipboardPulse
