/**
 * Select
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Icon } from "elements"
import { tagEvent } from "@/utils/analytics"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
    readonly name: string
    readonly value: string
    readonly setValue: (value: string) => void
    readonly items: ReadonlyArray<{
        readonly value: string
        readonly label?: string
        readonly disabled?: boolean
    }>
    readonly placeholder?: string
    readonly errorMessage?: string
    readonly variant?: "default" | "rounded"
    readonly noPlaceholder?: boolean
    readonly onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
    readonly disabled?: boolean
    readonly enablePlaceHolder?: boolean
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Select: React.FC<Props> = props => {
    /**
     * Methods
     */
    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        props.setValue(e.currentTarget.value)
        handleAnalytics()
    }

    /**
     * Styles
     */
    const base = `appearance-none outline-none w-full truncate ${(props.disabled ? "cursor-not-allowed" : "cursor-pointer")}`
    const placeholder = !props.value ? "text-muted" : ""
    const padding =
        props.variant === "rounded" ? "h-lg pl-xs pr-md" : "h-lg pr-sm"
    const border =
        props.variant === "rounded"
            ? props.errorMessage
                ? "border rounded-sm border-error-200 hover:border-error-200 focus:border-error-200"
                : `border rounded-sm dark:border-gray-200 ${props.disabled ? "" : "border-primary-vivid"}`
            : "border-b rounded-none"
    const borderColor = props.errorMessage
        ? "border-error-200 hover:border-error-200 focus:border-error-200"
        : `border-b border-light-200 ${props.disabled ? "" : "hover:border-primary-vivid focus:border-primary-vivid dark:border-dark-50 dark:focus:border-accent dark:hover:border-accent"}`
    const background =
        props.variant === "rounded"
            ? props.disabled
                ? "text-gray-300 bg-gray-50"
                : "dark:bg-transparent dark:text-white text-primary bg-white"
            : "bg-transparent"
    const styles = `${base} ${placeholder} ${padding} ${border} ${borderColor} ${background}`

    const handleAnalytics = () => {
        tagEvent({
            tealium_event: "link",
            data_analytics_id: props.name + "-" + props.value,
            link_text: props.name || "",
            link_url: "",
        })
    }

    /**
     * Template
     */
    return (
        <div className={props.className || ""}>
            <div className="relative">
                <select
                    id={props.name}
                    name={props.name}
                    data-test={props.name}
                    value={props.value}
                    onChange={props.onChange || onChange}
                    className={styles}
                    aria-label={props.name}
                    disabled={props.disabled}
                >
                    {!props.noPlaceholder && (
                        <option value="" disabled={!props.enablePlaceHolder} selected={true}>
                            {props.placeholder || "Select ..."}
                        </option>
                    )}
                    {props.items &&
                        props.items.map((option, index) => (
                            <option
                                key={index}
                                value={option.value}
                                data-test={props.name + "-" + index}
                                disabled={option.disabled}
                            >
                                {option.label || option.value}
                            </option>
                        ))}
                </select>
                <div
                    className={`absolute top-none  h-full flex items-center pointer-events-none opacity-80 ${
                        props.variant === "rounded"
                            ? "right-xxs text-primary-300 dark:text-white"
                            : "right-none"
                    }`}
                >
                    <Icon type="chevron-down-sm" />
                </div>
            </div>

            {/* Error message */}
            {props.errorMessage && (
                <div className="mt-xxxs">
                    <small className="text-error">{props.errorMessage}</small>
                </div>
            )}
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Select
