import { read } from "fs"

/**
 * Types
 */
export interface Profile{
    readonly firstName: string
    readonly lastName: string
    readonly birthDate?: string
    readonly username : string
    readonly email : string
    readonly phone: SMS
    readonly emailVerificationStatus?: string
    readonly smsVerificationStatus?: string
    readonly preferences?: Preferences
    readonly familySharing?: FamilySharing
    readonly address?: AddressTypes
}
export interface SMS{
    readonly activated : boolean
    readonly number : string }
export interface ValidationStatus {
    readonly strength: string,
    readonly risks: readonly PasswordRisk[]
    readonly __typename?: string
}
export interface PasswordRisk {
    readonly code: string,
    readonly __typename?: string
}

export interface Preferences{
    readonly communicationPreferences?: CommunicationPreferences
    readonly paperless?: Paperless
    readonly hsaPreferences?: HSAPreferences
}

export interface Paperless{
    readonly eob: PreferenceValues,
    readonly medical: PreferenceValues,
    readonly prime: PreferenceValues,
    readonly anoc: PreferenceValues
}

export interface CommunicationPreferences {
    readonly claimsAlert?: CommunicationChannels
    readonly newsUpdates: CommunicationChannels
    readonly feedback: CommunicationChannels
}

export type UpdateCommPreferences = Omit<CommunicationPreferences, "claimsAlert">

export interface CommunicationChannels{
    readonly email: PreferenceValues
    readonly sms?: PreferenceValues
}

export interface HSAPreferences {
    readonly balance: PreferenceValues
    readonly shareClaims: PreferenceValues
    readonly shareHealthInfo: PreferenceValues
}
export interface PreferenceValues {
    readonly programId : string
    readonly permissions?: boolean
    readonly setting: boolean
}
export interface Letter {
    readonly id: string
    readonly date: string
    readonly name: string
    readonly desc: string
    readonly url: string
}
export interface LettersAndNotices {
    readonly letterList: ReadonlyArray<Letter>
}

export enum ActivationType {
    Success = "SUCCESS",
    Failed = "FAILED",
    Pending = "PENDING",
    Empty = "",
}
export interface EmailActivation {
    readonly status: ActivationType
}
export interface MemberFamilyShareInfo {
    readonly authorizedDate: string
    readonly authorizedTime: string
    readonly authorized: boolean
    readonly firstName: string
    readonly lastName: string
    readonly expiryDate: string
    readonly age: string
    readonly suffix: string
}
export interface FamilySharing {
    readonly show: boolean
    readonly familyShareInfoList: ReadonlyArray<MemberFamilyShareInfo>
}

export interface AddressTypes {
    readonly homeAddress: Address
    readonly billingAddress: Address
    readonly mailingAddress: Address
}

export interface Address {
    readonly address1?: string
    readonly address2?: string
    readonly city: string
    readonly state: string
    readonly zip: string
}

export interface medicareMemberAddress {
    readonly addressType: string
    readonly addressLine1: string
    readonly addressLine2: string
    readonly city: string
    readonly state: string
    readonly zip: string
    readonly temporaryMove: boolean
    readonly moveDate: string
    readonly additionalAddressType1: string
    readonly additionalAddressType2: string
}