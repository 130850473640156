import * as React from "react"
import { SVGProps } from "react"
const SvgX = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Close X" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M6.1 6L17.9 18M18 6L6 18"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgX
