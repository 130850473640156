/**
 * Care Gaps Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions } from "store"
import { http } from "utils"
import {isLocalDevelopment} from "@/utils/env";

/**
 * Get programs data
 * -----------------------------------------------------------------------------
 */
export const fetchCareGaps = createAsyncThunk(
    "careGaps/fetchCareGaps", // Reducer name
    async (params: {
        readonly insightId: string
    }, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.careGapsStatus("pending"))
        const insightId = params.insightId || ""

        /**
         * DFG endpoint is not available to DFW local development.
         * Local development will be using the janus proxy for calling DFARS.
         */
        const v2CareGapsEndpoint = () => {
            return isLocalDevelopment(window?.location?.host)
                ? "/api/janus/digital-first-api-routing-service/care-gaps?insight="
                : "/v2/api/care-gaps?insight="
        }

        const careGapsEndpoint = v2CareGapsEndpoint()

        /**
         * Fetch care gaps
         */
        const res = await http.get(careGapsEndpoint + insightId)

        /**
         * Handle request errors
         */
        if (res.error) {
            return dispatch(actions.careGapsError({ response: res }))
        }

        /**
         * Define care gaps items
         */
        const items = res.data?.careGaps || []
        const uuids = res.data?.uuids || []

        /**
         * Update state
         */
        dispatch(actions.receiveCareGaps({
            items,
            uuids,
            insightId,
        }))
    },
)
