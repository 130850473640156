/**
 * Conditions reducer
 * -----------------------------------------------------------------------------
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { Coverage } from "../coverages/types"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Coverages {
    // Base props
    readonly isInitialized: boolean
    readonly isLoading: boolean
    readonly isItemInitialized: boolean
    readonly isItemLoading: boolean
    readonly errorMessage?: string

    // Resource props
    readonly items: ReadonlyArray<Coverage>
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: Coverages = {
    isInitialized: false,
    isLoading: false,
    isItemInitialized: false,
    isItemLoading: false,
    errorMessage: undefined,
    items: [],
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const coverages = createSlice({
    name: "coverages",
    initialState,
    reducers: {
        updateCoverages: (
            state: Coverages,
            action: PayloadAction<Coverages>,
        ) => {
            return {
                ...state,
                ...action.payload,
            }
        },
        resetCoverages: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const coveragesActions = { ...coverages.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default coverages.reducer
