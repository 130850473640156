export const providersQuery = `
query fetchProvidersData {
    careSubjects {
      edges {
        node {
          careSubjectId
          membershipId
          events {
            totalCount
            edges {
              edgeId
              nodeType
              sortDate
              node {
                ... on PractitionerEvent {
                  encounterType
                  claimId
                  eventDate
                  practitioner {
                    ... on ClaimsPractitioner {
                      id
                      type
                      displayName
                      taxName
                      specialty
                      npi
                      practitionerId
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }      
`
