import { Service, ServiceConfig } from "@pgr/web-sdk-widget"

export const serviceConfig: ServiceConfig = {
    encounterConfig: { reasonCode: "nurse_care", notes: "" }
}

/**
 * Define Pagers services available to the user
 */
export const services: ReadonlyArray<Service> = [
    {
        config: serviceConfig,
        imageUrl: "",
        titles: {
            "en-US": {
                title: "Chat with a nurse",
                subtitle: "Chat with a nurse",
            },
        },
    },
]
