/**
 * Care gaps reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"
import { CareGap, UUID } from "@/utils/care-gaps"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface CareGaps {
    readonly uuids: ReadonlyArray<UUID>
    readonly items: ReadonlyArray<CareGap>
    readonly insightId?: string
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<CareGaps> = {
    status: "pristine",
    uuids: [],
    items: [],
    insightId: "",
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const careGaps = createStatefulSlice({
    name: "careGaps",
    initialState,
    reducers: {
        receiveCareGaps: (
            state: SliceState<CareGaps>,
            action: PayloadAction<CareGaps>,
        ) => {
            return {
                status: "completed",
                uuids: action.payload.uuids,
                items: action.payload.items,
                insightId: action.payload.insightId,
            }
        },
        resetCareGaps: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const careGapsActions = { ...careGaps.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export type { CareGap }
export default careGaps.reducer
