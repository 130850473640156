import * as React from "react"
import { SVGProps } from "react"
const SvgPinPlus = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Pin with plus mark"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9 11.0002H15M12 8.0002V14.0002M20.1 6.3002C23.9 13.9002 14.6 21.4002 12.5 22.9002C12.2 23.1002 11.9 23.1002 11.7 22.9002C9.8 21.7002 3 16.2002 3 10.1002C3 5.5002 6.4 1.7002 10.8 1.1002C14.8 0.6002 18.4 2.8002 20.1 6.3002Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgPinPlus
