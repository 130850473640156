/**
 * Chat reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import {
    createStatefulSlice,
    SliceState,
} from "store/util/create-stateful-slice"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Button {
    readonly label: string
    readonly value: string
}
export interface Message {
    readonly userAlias: string
    readonly createdAt: number
    readonly text: string
    readonly buttons?: ReadonlyArray<Button>
}
export interface Chat {
    // Is Chat UI visible/open?
    readonly isVisible: boolean

    // Is Chat UI toggle after page refresh?
    readonly isRefresh: boolean

    // Has an existing chat session been ended?
    readonly isChatEnded: boolean

    // Last input value or button label being sent; used in retry
    readonly lastInput: string

    // Last button value being sent; used in retry
    readonly lastValue: string

    // Array of sent/received messages
    readonly messages: ReadonlyArray<Message>

    // How many milliseconds between polls
    // Note: If value is 0, don't poll
    readonly pollInterval: number

    // Is the app polling for messages?
    // Note: Used to prevent multiple `setInterval`
    readonly isPolling: boolean

    // Is agent currently typing?
    readonly isAgentTyping: boolean

    // Intent meta-data from AWS Lex for analytics
    readonly intentContext?: string

    // Show end chat modal
    readonly showEndChatModal: boolean
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<Chat> = {
    status: "pristine",
    isVisible: false,
    isRefresh: false,
    isChatEnded: false,
    lastInput: "",
    lastValue: "",
    intentContext: "",
    messages: [],
    pollInterval: 0,
    isPolling: false,
    isAgentTyping: false,
    showEndChatModal: false,
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const chat = createStatefulSlice({
    name: "chat",
    initialState,
    reducers: {
        toggleEndChatModal: (state: SliceState<Chat>) => {
            return { ...state, showEndChatModal: !state.showEndChatModal }
        },
        toggleIsRefresh: (state: SliceState<Chat>) => {
            return {...state, isRefresh: !state.isRefresh }
        },
        receiveChat: (state: SliceState<Chat>, action: PayloadAction<Chat>) => {
            return { status: "completed", ...action.payload }
        },
        receiveChatMessage: (
            state: SliceState<Chat>,
            action: PayloadAction<Message>,
        ) => {
            return { ...state, messages: [...state.messages, action.payload] }
        },
        receiveChatAgentTyping: (
            state: SliceState<Chat>,
            action: PayloadAction<boolean>,
        ) => {
            return { ...state, isAgentTyping: action.payload }
        },
        resetChat: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const chatActions = { ...chat.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default chat.reducer
