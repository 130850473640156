/**
 * Loading Screen
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Card, Spinner } from "elements"
import { useTranslation } from "react-i18next"

interface ExtendLoadingScreenProps {
    readonly message?: string
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const LoadingScreen = () => {
    /**
     * Template
     */
    return (
        <div className="bg-light-50 min-h-screen min-w-screen flex items-center justify-center">
            <Card name="initialing-card" className="px-lg">
                <Spinner className="flex justify-center" />
                <div className="text-center mt-sm">Loading...</div>
            </Card>
        </div>
    )
}

/**
 * Component - Extended Loading Screen
 * -----------------------------------------------------------------------------
 */
export const ExtendedLoadingScreen = (props: ExtendLoadingScreenProps) => {
    const { t } = useTranslation()
    const defaultExtendedLoadingMessage = t("loadingScreen.defaultExtendedLoadingMessage")
    const message = props.message || defaultExtendedLoadingMessage

    /**
     * Template
     */
    return (
        <div className="bg-light-50 min-h-screen min-w-screen flex items-center justify-center">
            <Card name="initialing-card" className="px-lg">
                <Spinner className="flex justify-center" />
                <div className="text-center mt-sm">{message}</div>
            </Card>
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default LoadingScreen
