/**
 * Treatments reducer
 * -----------------------------------------------------------------------------
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { Opportunity } from "./types"
 
/**
  * Types
  * -----------------------------------------------------------------------------
  */

export interface Opportunities {
     // Base props
     readonly isInitialized: boolean
     readonly isLoading: boolean
     readonly isItemInitialized: boolean
     readonly isItemLoading: boolean
     readonly errorMessage?: string

     // Resource props
     readonly items: ReadonlyArray<Opportunity>
 }
 
/**
  * Initial state
  * -----------------------------------------------------------------------------
  */

export const initialState: Opportunities = {
    isInitialized: false,
    isLoading: false,
    isItemInitialized: false,
    isItemLoading: false,
    errorMessage: undefined,
    items: [],
}
 
/**
  * Reducer slice
  * -----------------------------------------------------------------------------
  */
const opportunities = createSlice({
    name: "opportunities",
    initialState,
    reducers: {
        receiveOpportunities: (
            state: Opportunities,
            action: PayloadAction<Opportunities>,
        ) => {
            return action.payload
        },
        updateOpportunities: (
            state: Opportunities,
            action: PayloadAction<Opportunities>,
        ) => {
            return {
                ...state,
                ...action.payload
            }
        },
        resetOpprotunities: () => {
            return initialState
        },
    },
})
 
/**
  * Export reducer actions
  * -----------------------------------------------------------------------------
  */
export const opportunitiesActions = { ...opportunities.actions, ...actions }
 
/**
  * Export reducer
  * -----------------------------------------------------------------------------
  */
export default opportunities.reducer
