export const onlifeQuery = `
query {
    user {
        context { 
            audience 
        }
    }
    member {
        relationship
        coverages {
            status
            startDate
        }
        onLifeJourney {
            incentiveSymbol
            incentiveType
            incentiveValue
            isIncentiveAssociated
            journeyCardName
        }
        onLifeIncentives {
            earnedIncentives
            potentialIncentives
        }
        onLifeMessageCount
    }
}
`
export const mpsOnLifeQuery = `
query {
  primaryPolicy {
    requesterMembership {
      primaryCoverage {
        primaryEligibility {
          effectiveDate
        }
      }
    }
  }
  onlife {
    messageCount
    journey {
      journeyCardName
      isIncentiveAssociated
      incentiveSymbol
      incentiveType
      incentiveValue
    }
    incentives {
      earnedIncentives
      potentialIncentives
    }
  }
}
`

export const mapsWellnessQuery = `
query {
    myAccount {
      preferences {
        consumerSettings {
          wellness {
            timestamp
          }
        }
      }
    }
  }
`