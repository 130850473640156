/**
 * VspInfo Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import { http } from "utils"
import { vspInfoFromDFISQuery, vspInfoQuery } from "./queries"
import { transformVspInfoData, transformVspInfoFromDFISData } from "./utils"

/**
 * Get VSP Info data from ADIF user model
 * -----------------------------------------------------------------------------
 */
export const fetchVspInfo = createAsyncThunk(
    "vsp/fetchVspInfo", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        const { vspInfo } = store.getState()
        dispatch(actions.receiveVspInfo({ ...vspInfo, isLoading: true }))

        /**
         * Fetch vspInfo from ADIF user model
         */
        const res = await http.query("/api/adif/user-model", vspInfoQuery)

        /**
         * Handle request errors
         */
        if (res.error) {
            return dispatch(
                actions.receiveVspInfo({
                    ...vspInfo,
                    isLoading: false,
                    isInitialized: true,
                    errorMessage: "Could not retrieve vspInfo data",
                }),
            )
        }

        /**
         * Transform vsp info data
         */
        const data = res.data?.data?.member?.enrollment?.vsp || []
        const items = transformVspInfoData(data)

        /**
         * Update state
         */
        dispatch(
            actions.receiveVspInfo({
                ...vspInfo,
                items,
                isLoading: false,
                isInitialized: true,
            }),
        )
    },
)

/**
 * Get VSP Info data from DFIS
 * -----------------------------------------------------------------------------
 */
export const fetchVspInfoFromDFIS = createAsyncThunk(
    "vsp/fetchVspInfoFromDFIS", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        const { vspInfo } = store.getState()
        dispatch(actions.receiveVspInfo({ ...vspInfo, isLoading: true }))

        /**
         * Request
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            vspInfoFromDFISQuery,
        )

        /**
         * Handle request errors
         */
        if (res.error) {
            return dispatch(
                actions.receiveVspInfo({
                    ...vspInfo,
                    isLoading: false,
                    isInitialized: true,
                    errorMessage: "Could not retrieve vspInfo data",
                }),
            )
        }

        /**
         * Transform vsp info data
         */
        const data = res.data?.data?.vsp || []
        const items = transformVspInfoFromDFISData(data)
 
        /**
         * Update state
         */
        dispatch(
            actions.receiveVspInfo({
                ...vspInfo,
                items,
                isLoading: false,
                isInitialized: true,
            }),
        )
    },
)