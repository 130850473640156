/**
 * Renewal Utilities
 * -----------------------------------------------------------------------------
 */
import i18next from "i18next"
import {
    Applicant,
    CancelRenewal,
    FastTrackForm,
    Renewal,
    ReviewRenewal,
    ConfirmRenewal,
    RenewalSteps,
    Address,
    initialState,
} from "@/store/renewal/reducer"
import { formatDate } from "utils/date"
import http, { Response } from "utils/http"
import { getCoverageEndDate } from "@/utils/renewal"

/**
 * Define initialize data
 * -----------------------------------------------------------------------------
 */
export const defineRenewalInitializeData = (
    res: Response,
): Pick<
    Renewal,
    | "address"
    | "currentPlan"
    | "renewalInfo"
    | "region"
    | "applicants"
    | "subregion"
> => {
    const planEnrollment = res.data?.data?.planEnrollment || {}
    const region = planEnrollment.region
    const renewalInfo = planEnrollment.renewalInfo || {}
    const planMembers = planEnrollment.currentPlan?.planMembers || []
    const residentialAddress =
        planEnrollment.currentPlan?.residentialAddress || {}
    const currentPlan = planEnrollment.currentPlan || {}
    const subscriber = planMembers.filter(m => m.memberType === "SUBSCRIBER")
    const spouse = planMembers.filter(m => m.memberType === "SPOUSE")
    const dependents = planMembers
        .filter(m => m.memberType === "DEPENDENT")
        .sort((a, b) => b.age - a.age)
    const sortedPlanMembers = [...subscriber, ...spouse, ...dependents]
    const relationship = { SUBSCRIBER: "SB", SPOUSE: "SP", DEPENDENT: "DE" }
    const calculatedEndDate =
        getCoverageEndDate(
            currentPlan?.coverageEndDate,
            currentPlan?.currentYear,
        ) || ""

    return {
        address: {
            ...residentialAddress,
            county: "",
        },
        currentPlan: {
            openEnrollmentStartDate: currentPlan.openEnrollmentStartDate || "",
            openEnrollmentEndDate: currentPlan.openEnrollmentEndDate || "",
            coverageEndDate: calculatedEndDate || "",
            currentYear: currentPlan.currentYear || "",
            nextYear: currentPlan.nextYear || "",
            product: currentPlan.product || { id: "" },
            brand: currentPlan.brand || { code: null, family: null },
        },
        renewalInfo: renewalInfo || {
            isIndividualMember: false,
            isDiscontinuedProduct: false,
            isDiscontinuedCounty: false,
            renewalInteractionState: null,
            renewalWindow: "OUTSIDE_ENROLLMENT_PERIOD",
            renewedProduct: null,
            shopMarketPlaceURL: "",
        },
        region: region || "",
        subregion: planEnrollment.subregion || "",
        applicants: sortedPlanMembers.map(member => {
            return {
                familyMemberId: member.familyMemberId || "",
                relationship: relationship[member.memberType] || "DP",
                gender: member.gender,
                dateOfBirth: member.dateOfBirth || "",
                age: member.age,
                firstName: member.firstName,
                tobaccoUse: isUnderAged(member.age) ? "false" : "",
                isRemoved: false,
                isEligibleForMedicareByAge: isEligibleForMedicareByAge(
                    member.dateOfBirth,
                ),
            }
        }),
    }
}

export const isUnderAged = (age: number) => {
    return age < 21
}

/**
 * Assuming that the new coverage effective date is 1/1 of next year and ends 12/31 of next year
 * when this method returns false then the person may proceed with Indi renewal; otherwise they need to renew manually
 * @param dateOfBirth person's date of birth
 * @returns true if date of birth puts person at 65 years old before the end the new coverage (12/31 of next year); false otherwise
 */
export const isEligibleForMedicareByAge = (dateOfBirth: string) => {
    //Sets new coverage end date to 12/31 of next year
    const newCoverageEndDate = new Date(
        new Date().getFullYear() + 1,
        11, //January is 0, so December is 11
        31,
    )
    const birthDate = new Date(dateOfBirth)

    //Date the person turns 65 years old
    const cutoffDate = new Date(
        birthDate.getFullYear() + 65,
        birthDate.getMonth(),
        birthDate.getDate(),
    )

    if (cutoffDate <= newCoverageEndDate) {
        return true
    }

    return false
}

/**
 * Determine renewal progress
 * -----------------------------------------------------------------------------
 */
interface RenewalProgress {
    readonly steps: ReadonlyArray<RenewalSteps>
    readonly completed: ReadonlyArray<RenewalSteps>
    readonly pending: ReadonlyArray<RenewalSteps>
}
export const renewalProgress = (renewal: Renewal): RenewalProgress => {
    // Define renewal steps and order
    const steps = ["address", "applicants", "plan", "pcp", "review"]

    // Validate each step (and if required)
    const hasAddress = isAddressStepValid(renewal.address)
    const hasApplicants = isApplicantsStepValid(renewal.applicants)
    const hasPlan = isPlanStepValid(renewal.fastTrackPlan)
    const requiresPCP = isPCPStepRequired(renewal.fastTrackPlan)
    const hasPCP = isPCPStepValid(renewal.applicants)
    const requiresReview = isReviewStepRequired(renewal.fastTrackPlan)
    const hasReview = isReviewStepValid(renewal.review)

    // Filter steps for `required`, `pending` and `completed`
    const required = steps.filter(step => {
        if (step === "pcp") return requiresPCP
        if (step === "review") return requiresReview
        return true
    }) as ReadonlyArray<RenewalSteps>
    const pending = []
    // TODO: do we need to check isSubmitted or isCancelled?
    const completed = required.filter(step => {
        if (hasAddress && step === "address") return true
        if (hasApplicants && step === "applicants") return true
        if (hasPlan && step === "plan") return true
        if (hasPCP && step === "pcp") return true
        if (hasReview && step === "review") return true
        pending.push(step)
        return false
    }) as ReadonlyArray<RenewalSteps>

    // Return the goods
    return { steps: required, pending, completed }
}

/**
 * Validate step data utilities
 * -----------------------------------------------------------------------------
 */
export const isAddressStepValid = (address: Renewal["address"]): boolean => {
    // console.log("address", address.street)
    return !!(
        address?.addressChanged &&
        address?.street &&
        address?.city &&
        address?.state &&
        isZipValid(address?.zip) &&
        address?.county
    )
}
export const isApplicantsStepValid = (
    applicants: Renewal["applicants"],
): boolean => {
    if (!applicants?.length) return false
    const required = applicants?.filter(app => !app.isRemoved)
    return !!(required?.length && !required?.filter(a => !a.tobaccoUse).length)
}
export const isPlanStepValid = (plan: Renewal["fastTrackPlan"]): boolean => {
    return (
        isPlanCancelled(plan) ||
        isPlanReenrolling(plan) ||
        !!(
            plan?.productId &&
            plan?.networkName &&
            plan?.planName &&
            plan?.serviceArea &&
            plan?.county
        )
    )
}
export const isPlanCancelled = (plan: Renewal["fastTrackPlan"]): boolean => {
    return plan?.cancel === "true"
}
export const isPlanReenrolling = (plan: Renewal["fastTrackPlan"]): boolean => {
    return plan?.useReenrollingPlan === "true"
}
export const isPCPStepRequired = (plan: Renewal["fastTrackPlan"]): boolean => {
    if (isPlanReenrolling(plan)) {
        // Renewing/reenrolling plans don't require PCP selection
        return false
    }
    if (isPlanCancelled(plan)) {
        // cancelled plans don't require PCP selection
        return false
    }

    // Only certain network/state combinations require PCP
    const { serviceArea: sa, networkName: n } = plan || {}
    const isUtah = !!(sa?.match(/UT/gi) || sa?.match(/utah/gi))
    const isIdaho = !!(sa?.match(/ID/gi) || sa?.match(/idaho/gi))
    const isOregon = !!(sa?.match(/OR/gi) || sa?.match(/oregon/gi))
    const isClarkCounty = !!(sa?.match(/CC/gi) || sa?.match(/clarkco/gi))
    const isWesternWashington =
        !!(sa?.match(/WW/gi) || sa?.match(/western washington/gi)) ||
        sa?.match(/washington/gi)
    const isAsuris = !!(sa?.match(/EW/gi) || sa?.match(/asuris/gi))
    const isSaveWell = !!n?.match(/SaveWell/gi)
    const isIAFN = !!n?.match(/individual and family network/gi)

    return !!(
        isAsuris ||
        isWesternWashington ||
        isClarkCounty ||
        (isUtah && isSaveWell) ||
        (isIdaho && isIAFN) ||
        (isOregon && isIAFN)
    )
}
export const isPCPStepValid = (applicants: Renewal["applicants"]): boolean => {
    const planMembers = applicants?.filter(applicant => !applicant.isRemoved)
    const filteredPlanMembers = planMembers?.filter(
        a => a.hasPrimaryCareProvider === "yes",
    )
    // eslint-disable-next-line functional/no-let
    let isValid = true
    filteredPlanMembers.map(member => {
        if (!member.primaryCareProvider?.providerName) {
            isValid = false
        }
        if (
            isAddress(member.primaryCareProvider?.address) &&
            !isAddressComplete(member.primaryCareProvider?.address)
        ) {
            isValid = false
        }
        if (
            isAddress(member.primaryCareProvider?.address) &&
            !isZipValid(member.primaryCareProvider?.address?.zip)
        ) {
            isValid = false
        }
    })
    return (
        !!(
            planMembers?.length &&
            !planMembers?.filter(a => !a.hasPrimaryCareProvider).length
        ) && isValid
    )
}
export const isReviewStepRequired = (
    plan: Renewal["fastTrackPlan"],
): boolean => {
    if (isPlanReenrolling(plan)) {
        // Renewing/reenrolling plans don't require review
        return false
    }
    if (isPlanCancelled(plan)) {
        // cancelled plans don't require review
        return false
    }
    return true
}
export const isReviewStepValid = (review: Renewal["review"]): boolean => {
    // prettier-ignore
    return !!(
        // validate `billingAddress`
        (review?.paymentInfo?.billingAddress?.street &&
            review?.paymentInfo?.billingAddress?.city &&
            review?.paymentInfo?.billingAddress?.state &&
            isZipValid(review?.paymentInfo?.billingAddress?.zip)) &&

        // validate `communicationPreferences`
        (isPhoneValid(trimPhone(review?.communicationPreferences?.phoneNumber)) &&
            review?.communicationPreferences?.address?.street &&
            review?.communicationPreferences?.address?.city &&
            review?.communicationPreferences?.address?.state &&
            review?.communicationPreferences?.address?.zip &&
            isZipValid(review?.communicationPreferences?.address?.zip)) &&

        // validate conditional `email`
        (review?.communicationPreferences?.email
            ? isEmailValid(review?.communicationPreferences?.email)
            : true) &&

        // validate conditional `thirdPartyPayer`
        (review?.paymentInfo?.thirdPartyPayer?.isSelected
            ? !!(
                review?.paymentInfo?.thirdPartyPayer?.name &&
                review?.paymentInfo?.thirdPartyPayer?.payerType)
            : true) &&

        // validate conditional `selfEmployement`
        (review?.paymentInfo?.selfEmployment?.isSelected
            ? !!review?.paymentInfo?.selfEmployment?.businessName
            : true) &&

        // validate conditional `hsaPreference`
        (review?.hsaPreference?.isSelected
            ? isSSNValid(review?.hsaPreference?.ssn)
            : true)
    )
}

/**
 * Form validation helpers
 * -----------------------------------------------------------------------------
 * NOTE: Salesforce is super picky, so we need to validate/format a few fields
 * to play nicely with the schema defined in `membership-renewals-service`
 *
 * https://github.com/cambiahealth/membership-renewals-service/blob/master/resources/salesforce.schema.json
 */
export const isSSNValid = (ssn: string): boolean => {
    if (!ssn) return false
    return !!ssn.match(new RegExp("^([0-9]{3}-?[0-9]{2}-?[0-9]{4})?$"))
}
export const isEmailValid = (email: string): boolean => {
    if (!email) return false
    return !!email.match(
        new RegExp(
            "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$",
        ),
    )
}
export const isZipValid = (zip: string): boolean => {
    if (!zip) return false
    if (zip.length < 5 || zip.length > 10) return false
    return !!zip.match(new RegExp("^([0-9]{5,5}(\\-[0-9]{4,4})?)$"))
}
export const isPhoneValid = (phone: string): boolean => {
    if (!phone) return false
    if (phone.length !== 10) return false
    return !!phone.match(new RegExp("^[0-9]+$"))
}
export const trimPhone = (phone: string): string => {
    if (!phone) return ""
    return phone.replace(/\s/g, "").replace(/[^0-9.]/g, "")
}
export const trimEmail = (email: string): string => {
    if (!email) return ""
    return email.replace(/\s/g, "")
}
export const isAddress = (address: Address): boolean => {
    return !!(
        address?.street ||
        address?.city ||
        address?.state ||
        address?.zip
    )
}
export const isAddressComplete = (address: Address): boolean => {
    return !!(
        address?.street &&
        address?.city &&
        address?.state &&
        address?.zip
    )
}

/**
 * Delete {@link Renewal} progress data after the provided {@link RenewalSteps step}
 * TODO: unit test, for all RenewalSteps values
 * -----------------------------------------------------------------------------
 */
export const clearProgressAfterStep = (
    renewal: Renewal,
    step: RenewalSteps,
): Renewal => {
    /**
     * helper fn - resets PCP props, which are inside renewal.applicants
     * applicants[*].primaryCareProvider
     * applicants[*].hasPrimaryCareProvider
     */
    const deletePcp = (applicants: ReadonlyArray<Applicant>) => {
        return applicants.map(app => ({
            ...app,
            primaryCareProvider: undefined,
            hasPrimaryCareProvider: undefined,
        }))
    }

    if (step === "address") {
        return {
            ...renewal,
            applicants: renewal.applicants.map(app => ({
                ...app,
                tobaccoUse: "",
                isRemoved: false,
                primaryCareProvider: undefined,
                hasPrimaryCareProvider: undefined,
            })),
            fastTrackPlan: initialState.fastTrackPlan,
            review: initialState.review,
        }
    }

    if (step === "applicants") {
        return {
            ...renewal,
            fastTrackPlan: initialState.fastTrackPlan,
            applicants: deletePcp(renewal.applicants),
            review: initialState.review,
        }
    }

    if (step === "plan") {
        return {
            ...renewal,
            applicants: deletePcp(renewal.applicants),
            review: initialState.review,
            fastTrackPlan: undefined,
        }
    }

    return renewal
}

/**
 * Define payload for FastTrack form
 * -----------------------------------------------------------------------------
 */
export const transformFastTrackBody = (renewal: Renewal): FastTrackForm => {
    // TODO: could add some validation, ie do we have all required data?
    return {
        zipCode: renewal?.address?.zip || "",
        county: renewal?.address?.county || "",
        productId: renewal?.currentPlan?.product?.id || "",
        childOnlyApp: false,
        effectiveDate: "2022-10-01",
        // today: new Date().toISOString(), // Note: is this required?
        applicants:
            renewal?.applicants
                ?.filter(a => !a.isRemoved)
                ?.map(applicant => {
                    return {
                        age: applicant.age,
                        birthDate: applicant.dateOfBirth,
                        gender: applicant.gender,
                        relationship: applicant.relationship,
                        tobaccoUse: applicant.tobaccoUse || "false",
                    }
                }) || [],
    }
}

/**
 * Define query params to send to FastTrack
 * -----------------------------------------------------------------------------
 * Reference: https://git.cambiahealth.com/cmb-cas/cwm-shop-client/-/blob/release_2022_02_24/shop/src/utils/format.ts#L101
 */
export const transformFastTrackParams = (renewal: Renewal): string => {
    // Define param values from state
    const productId = renewal?.currentPlan?.product?.id || ""
    const zipCode = renewal?.address?.zip || ""
    const county = renewal?.address?.county || ""
    const applicants = renewal?.applicants
        ?.filter(applicant => !applicant.isRemoved)
        ?.map(applicant => {
            const relationship = applicant.relationship
            const gender =
                applicant.gender === "male"
                    ? "M"
                    : applicant.gender === "female"
                    ? "F"
                    : "U"
            const tobacco = applicant.tobaccoUse === "true" ? "T" : "F"
            const birthDate = formatDate(applicant.dateOfBirth, "MM/dd/yyyy")
            return relationship + gender + tobacco + birthDate
        })
        ?.join(",")

    // Assemble and return query params
    const data = { productId, zipCode, county, applicants }
    const params = new URLSearchParams(data).toString()
    return params
}

/**
 * Define input data for renewal review and e-sign form to save to MRS
 */
export const transformRenewalReviewBody = (renewal: Renewal): ReviewRenewal => {
    const emptyAddress = {
        street: "",
        city: "",
        state: "",
        zip: "",
    }
    const commPreferences = renewal?.review?.communicationPreferences
        ? {
              ...renewal?.review?.communicationPreferences,
              phoneNumber: trimPhone(
                  renewal?.review?.communicationPreferences.phoneNumber,
              ),
          }
        : {
              phoneNumber: "",
              address: emptyAddress,
          }

    return {
        productId: renewal?.fastTrackPlan?.productId || "",
        brandCode: renewal?.currentPlan?.brand?.code,
        brandFamily: renewal?.currentPlan?.brand?.family,
        billingAddress:
            renewal?.review?.paymentInfo?.billingAddress || emptyAddress,
        communicationPreferences: commPreferences,
        hsaPreference: renewal?.review?.hsaPreference || { isSelected: false },
        thirdPartyPayer: renewal?.review?.paymentInfo?.thirdPartyPayer
            ?.isSelected
            ? {
                  name: renewal?.review?.paymentInfo?.thirdPartyPayer?.name,
                  payerType:
                      renewal?.review?.paymentInfo?.thirdPartyPayer?.payerType,
              }
            : null,
        selfEmployment: renewal?.review?.paymentInfo?.selfEmployment?.isSelected
            ? {
                  businessName:
                      renewal?.review?.paymentInfo?.selfEmployment
                          ?.businessName,
              }
            : null,
        serviceArea: renewal?.fastTrackPlan?.serviceArea || "",
        county: renewal?.fastTrackPlan?.county || "",
        residentialAddress: {
            street: renewal?.address?.street || "",
            city: renewal?.address?.city || "",
            state: renewal?.address?.state || "",
            zip: renewal?.address?.zip || "",
        },
        planMembers:
            renewal?.applicants?.map(applicant => {
                return {
                    remove: applicant.isRemoved,
                    familyMemberId: applicant.familyMemberId,
                    primaryCareProvider: applicant.primaryCareProvider,
                    isTobaccoUser: applicant.tobaccoUse === "true",
                }
            }) || [],
    }
}

/**
 * Define input data for renewal confirmation to save to MRS
 */
export const transformRenewalConfirmBody = (
    renewal: Renewal,
): ConfirmRenewal => {
    return {
        productId: renewal?.fastTrackPlan?.productId || "",
        serviceArea: renewal?.fastTrackPlan?.serviceArea || "",
        county: renewal?.fastTrackPlan?.county || "",
        hasChangedAddress: renewal?.address?.addressChanged === "yes",
        residentialAddress: {
            street: renewal?.address?.street || "",
            city: renewal?.address?.city || "",
            state: renewal?.address?.state || "",
            zip: renewal?.address?.zip || "",
        },
        planMembers:
            renewal?.applicants?.map(applicant => {
                return {
                    remove: applicant.isRemoved,
                    familyMemberId: applicant.familyMemberId,
                    isTobaccoUser: applicant.tobaccoUse === "true",
                }
            }) || [],
    }
}

/**
 * Define input data for renewal cancellation to save to MRS
 */
export const transformRenewalCancelBody = (renewal: Renewal): CancelRenewal => {
    return {
        serviceArea: renewal?.fastTrackPlan?.serviceArea || "",
        county: renewal?.fastTrackPlan?.county || "",
        residentialAddress: {
            street: renewal?.address?.street || "",
            city: renewal?.address?.city || "",
            state: renewal?.address?.state || "",
            zip: renewal?.address?.zip || "",
        },
    }
}

/**
 * clear query params
 */
export const clearQueryParams = router => {
    router.replace({ pathname: "/renewal", query: {} }, null, {
        shallow: true,
    })
}

/**
 * Utility: Get relationship label from relationship ID
 * -----------------------------------------------------------------------------
 */
export const defineRelationshipLabel = (rel: Applicant["relationship"]) => {
    const strings = i18next.t("en:planRenewal.common", { returnObjects: true })
    if (rel === "SB") return strings["subscriber"]
    if (rel === "SP") return strings["spouse"]
    if (rel === "DP") return strings["domesticPartner"]
    if (rel === "DE") return strings["dependant"]
    return rel
}

export type FastTrackSimType = "ChooseNew" | "Renew" | "Cancel"
export const simulateFastTrackCallback = (simType: FastTrackSimType) => {
    //console.log("simulateFastTrackCallback simType:", simType)
    /**
     * get mocked {@link FastTrackResponse} core fields
     */
    const mockCoreFields = () => ({
        productId: "foo-product-id",
        planName: "Bar Plan Name",
        networkName: "Bean Network name",
        serviceArea: "baz-service-area",
        county: "Bazinga County",
        // cancel: "false", // does FastTrack send this as "false" or just omit?
        // useReenrollingPlan: "false", // does FastTrack send this as "false" or just omit?
    })

    if (simType === "ChooseNew") {
        const fastTrackResponse = {
            ...mockCoreFields(),
            // cancel: "false", // does FastTrack send this as "false" or just omit?
            // useReenrollingPlan: "false", // does FastTrack send this as "false" or just omit?
        }
        // window.location causes a full page load (as would happen coming back from FastTrack)
        // eslint-disable-next-line functional/immutable-data
        window.location.search = new URLSearchParams(
            fastTrackResponse,
        ).toString()
        return
    }

    if (simType === "Renew") {
        const fastTrackResponse = {
            ...mockCoreFields(),
            useReenrollingPlan: "true",
            // cancel: "false", // does FastTrack send this as "false" or just omit?
        }
        // window.location causes a full page load (as would happen coming back from FastTrack)
        // eslint-disable-next-line functional/immutable-data
        window.location.search = new URLSearchParams(
            fastTrackResponse,
        ).toString()
        return
    }

    if (simType === "Cancel") {
        const fastTrackResponse = {
            cancel: "true", // do we need to return any other values?
            // useReenrollingPlan: "false", // does FastTrack send this as "false" or just omit?
        }
        // window.location causes a full page load (as would happen coming back from FastTrack)
        // eslint-disable-next-line functional/immutable-data
        window.location.search = new URLSearchParams(
            fastTrackResponse,
        ).toString()
        return
    }
}

/**
 * Utility: List of States
 * -----------------------------------------------------------------------------
 */
export const states = [
    { label: "Alabama", value: "AL" },
    { label: "Alaska", value: "AK" },
    { label: "Arizona", value: "AZ" },
    { label: "Arkansas", value: "AR" },
    { label: "California", value: "CA" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "Delaware", value: "DE" },
    { label: "District Of Columbia", value: "DC" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Hawaii", value: "HI" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Iowa", value: "IA" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Maine", value: "ME" },
    { label: "Maryland", value: "MD" },
    { label: "Massachusetts", value: "MA" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Mississippi", value: "MS" },
    { label: "Missouri", value: "MO" },
    { label: "Montana", value: "MT" },
    { label: "Nebraska", value: "NE" },
    { label: "Nevada", value: "NV" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "New York", value: "NY" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Puerto Rico", value: "PR" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Vermont", value: "VT" },
    { label: "Virginia", value: "VA" },
    { label: "Washington", value: "WA" },
    { label: "West Virginia", value: "WV" },
    { label: "Wisconsin", value: "WI" },
    { label: "Wyoming", value: "WY" },
]

export const getStyxFragment = async fragmentId => {
    const config = { params: { id: fragmentId } }
    const res = await http.get("/api/managed-content-blocks", config)
    return res?.data?.[0]
}

export interface CustomerPhoneData {
    readonly description: string
    readonly hours1: string
    readonly hours2: string
    readonly phoneNumber: string
    readonly phoneNumbers: ReadonlyArray<PhoneNumber>
}

export interface PhoneNumber {
    readonly number: string
    readonly description?: string
}

/**
 * Looks up customer phone support block used on the support page, to use in renewal flow's FAQ sidebar
 * swallows any errors thrown; default to return `null`
 * if failed to find a styx fragment, returns `null`
 */
export const getCustomerPhoneData = async (): Promise<CustomerPhoneData> => {
    // this code comes from the support page component
    // const {
    //     [Features.SUPPORT_PEBB]: pebb,
    //     [Features.SUPPORT_SEBB]: sebb,
    // } = useFeatures()
    // const ump = false //pebb || sebb
    // const mainFragmentId = ump ? pebb ? "5rcTmuzmSwJo81AwM4tWDn" : "eRiofLoked5g2eEH1p8jKn" : "wvnEU7Ar1SoaBN6DZiAQwf"

    const fragmentIds = {
        // FYI according to Deepak, ump does not apply to indy renewal
        ump: {
            pebb: "5rcTmuzmSwJo81AwM4tWDn",
            sebb: "eRiofLoked5g2eEH1p8jKn",
        },
        other: "wvnEU7Ar1SoaBN6DZiAQwf",
    }

    try {
        const mainFragment = await getStyxFragment(fragmentIds["other"])
        // TODO: maybe instead of exact string match, do x.title.toLowerCase().includes("customer service"), which will make it work with more fragments in styx
        const element = mainFragment?.data[0]?.data?.content?.find(
            x => x.title === "CUSTOMER SERVICE FOR MEMBERS",
        )
        return element
    } catch (err) {
        // maybe we should debug-log an error
        return null
    }
}

/**
 * Format the {@link CustomerPhoneData} into a markdown-parseable string
 */
export const formatCustomerPhoneMarkdownText = (
    customerPhoneData: CustomerPhoneData,
): string => {
    const { description, hours1, hours2, phoneNumber, phoneNumbers } =
        customerPhoneData
    const formattedPhoneNumbers = phoneNumbers
        .map(p => {
            // this phone number logic is copied from pages/support/index.tsx
            if (
                p.number &&
                p.description &&
                p.description?.toUpperCase()?.includes("TTY")
            ) {
                return p.description
            } else {
                return `${p.number} ${p.description || ""}`.trim()
            }
        })
        .map(linkPhoneNumber)
        .map(p => `<strong>${p}</strong>`)

    return `<p>${description}</p><p>${hours1}<br />${hours2}</p><p>${formattedPhoneNumbers.join(
        "<br />",
    )}</p>`
}

/**
 * wrap phone number in <a> anchor link, if the phoneNumber is valid (is 10, 11, or 7 digits long)
 * - returns original value if invalid/unlinkable
 */
export const linkPhoneNumber = phoneNumber => {
    const linkableNumber = formatPhoneNumber(phoneNumber)
    if (!linkableNumber) {
        return phoneNumber
    } else {
        return `<a href="tel:${linkableNumber}">${phoneNumber}</a>`
    }
}

/**
 * parses phoneNumber string into what can be used in a href tel:<number> value
 * - only numeric values, no spaces or other characters
 * - also, only if it applies -- ie if number is 10, 11, or 7 digits long
 * - return null on invalid phone number
 */
export const formatPhoneNumber = phoneNumber => {
    const formatted = phoneNumber.replace(/\D/g, "")
    if (
        formatted.length === 10 ||
        formatted.length === 11 ||
        formatted.length === 7
    ) {
        return formatted
    }

    return null
}
