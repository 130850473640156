/**
 * Benefits reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { MemberBenefit } from "./types"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Benefits {
    readonly viewableMembers: ReadonlyArray<{
        readonly membershipId?: string,
        readonly firstName?: string,
        readonly lastName?: string,
        readonly birthDate?: string,
        readonly currentAge?: string,
        readonly relationship?: string,
        readonly disclosureAllowed?: string,
        readonly suffix?: string,
      }>
    readonly benefits: { 
        readonly [membershipId: string]: MemberBenefit 
    }
    readonly benefitsByMembershipId: { 
        readonly [membershipId: string]: MemberBenefit 
    }
    readonly tier: {
        readonly medical?: { readonly selected: string }
        readonly dental?: { readonly selected: string }
        readonly pharmacy?: { readonly selected: string }
    }
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<Benefits> = {
    status: "pristine",
    viewableMembers: [],
    benefits: {},
    benefitsByMembershipId: {},
    tier: {},
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const benefits = createStatefulSlice({
    name: "benefits",
    initialState,
    reducers: {
        receiveBenefits: (
            state: SliceState<Benefits>,
            action: PayloadAction<Benefits>,
        ) => {
            return {
                status: "completed",
                benefits: action.payload.benefits || {},
                benefitsByMembershipId: action.payload.benefitsByMembershipId || {},
                viewableMembers: action.payload.viewableMembers,
                tier: action.payload.tier || {},
            }
        },
        resetBenefits: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const benefitsActions = { ...benefits.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default benefits.reducer
