/**
 * Paperless reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"
import * as actions from "./actions"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface MachinedLetter {
    readonly id: string
    readonly date: string
    readonly name: string
    readonly desc: string
    readonly url: string
}

export interface Paperless {
    readonly machinedLetters: ReadonlyArray<MachinedLetter>
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<Paperless> = {
    status: "pristine",
    machinedLetters: [],
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const paperless = createStatefulSlice({
    name: "paperless",
    initialState,
    reducers: {
        receivePaperless: (
            state: SliceState<Paperless>,
            action: PayloadAction<Paperless>,
        ) => {
            return { status: "completed", ...action.payload }
        },
        resetPaperless: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const paperlessActions = { ...paperless.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default paperless.reducer