import * as React from "react"
import { SVGProps } from "react"
const SvgHeart = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Heart" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M21.9414 5.28139C21.5606 4.50363 21.0094 3.8306 20.3327 3.31695C19.656 2.8033 18.8727 2.46352 18.0466 2.32521C17.2205 2.18689 16.3748 2.25394 15.5781 2.5209C14.7815 2.78786 14.0563 3.24722 13.4617 3.86166L12 5.25411L10.5383 3.86166C9.94346 3.24733 9.21818 2.78814 8.42139 2.5214C7.62461 2.25465 6.77879 2.18787 5.95264 2.32647C5.12649 2.46507 4.34331 2.80516 3.6667 3.3191C2.99009 3.83304 2.43913 4.50634 2.05859 5.28432C1.56184 6.31178 1.39026 7.47733 1.56856 8.6131C1.74687 9.74887 2.26585 10.7963 3.05077 11.6044L12 21.2551L20.9492 11.6015C21.7342 10.7933 22.2531 9.74595 22.4314 8.61017C22.6097 7.4744 22.4382 6.30886 21.9414 5.28139Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgHeart
