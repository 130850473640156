export type AppModelfeatureToggle = string

interface FeatureToggleValue {
    readonly box: { readonly value: boolean }
}

export interface AppModelFeatureTogglesResponseData {
    readonly application: Record<string, FeatureToggleValue>
}

export const FEATURE_TOGGLES = {
    DFW_ENABLED: "sign-in/identity-v2-login-endpoint-enabled",
    DFW_MY_ACCOUNT_ENABLED: "digital-first-web/account-settings-enabled",
    DFW_INSIGHT_CARD_FAMILY_MEMBER_ENABLED:
        "digital-first-web.insight-card/family-member-id-enabled",
}
