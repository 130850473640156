import * as React from "react"
import { SVGProps } from "react"
const SvgStethoscope = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Stethoscope"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M19.4 14.6002C19.4 14.0479 18.9523 13.6002 18.4 13.6002C17.8477 13.6002 17.4 14.0479 17.4 14.6002H19.4ZM9.10001 12.0002C9.10001 11.4479 8.65229 11.0002 8.10001 11.0002C7.54772 11.0002 7.10001 11.4479 7.10001 12.0002H9.10001ZM5.1 3.00018C5.65228 3.00018 6.1 2.55247 6.1 2.00018C6.1 1.4479 5.65228 1.00018 5.1 1.00018V3.00018ZM10.9 1.00018C10.3477 1.00018 9.9 1.4479 9.9 2.00018C9.9 2.55247 10.3477 3.00018 10.9 3.00018V1.00018ZM17.4 14.6002V17.0002H19.4V14.6002H17.4ZM17.4 17.0002C17.4 19.1976 15.5994 21.0002 13.1 21.0002V23.0002C16.6007 23.0002 19.4 20.4027 19.4 17.0002H17.4ZM13.1 21.0002C10.6787 21.0002 9.10001 19.2746 9.10001 17.0002H7.10001C7.10001 20.3257 9.52135 23.0002 13.1 23.0002V21.0002ZM9.10001 17.0002V12.0002H7.10001V17.0002H9.10001ZM5.1 1.00018H4V3.00018H5.1V1.00018ZM2 3.00018V7.00018H4V3.00018H2ZM2 7.00018C2 10.3525 4.64772 13.0002 8 13.0002V11.0002C5.75228 11.0002 4 9.2479 4 7.00018H2ZM8 13.0002C11.3523 13.0002 14 10.3525 14 7.00018H12C12 9.2479 10.2477 11.0002 8 11.0002V13.0002ZM14 7.00018V3.00018H12V7.00018H14ZM12 1.00018H10.9V3.00018H12V1.00018ZM14 3.00018C14 1.89561 13.1046 1.00018 12 1.00018V3.00018V3.00018H14ZM4 1.00018C2.89543 1.00018 2 1.89561 2 3.00018H4H4V1.00018ZM16.7 12.0002C16.7 11.0774 17.427 10.4002 18.3 10.4002V8.40018C16.373 8.40018 14.7 9.92293 14.7 12.0002H16.7ZM18.3 10.4002C19.2228 10.4002 19.9 11.1272 19.9 12.0002H21.9C21.9 10.0732 20.3773 8.40018 18.3 8.40018V10.4002ZM19.9 12.0002C19.9 12.8479 19.1477 13.6002 18.3 13.6002V15.6002C20.2523 15.6002 21.9 13.9525 21.9 12.0002H19.9ZM18.3 13.6002C17.4523 13.6002 16.7 12.8479 16.7 12.0002H14.7C14.7 13.9525 16.3477 15.6002 18.3 15.6002V13.6002Z"
            fill="currentColor"
        />
    </svg>
)
export default SvgStethoscope
