import * as React from "react"
import { SVGProps } from "react"
const SvgBriefcase = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Briefcase"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M19.5 6.5V5.5C19.5 3.9 18.2 2.5 16.5 2.5H7.5C5.9 2.5 4.5 3.8 4.5 5.5V6.5M15.7 14.5H22.7M1.7 14.5H8.7M1 7.5H23V21.5H1V7.5ZM9 11.5H15V18.5H9V11.5Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgBriefcase
