import { TaxForms } from "./reducer"
export const transformTaxForm = (
    data: any, // eslint-disable-line
): TaxForms => {
    return data.map(d => {
        return {
            id: d?.id,
            date: d?.date!==undefined|| Boolean(d?.date)!==false|| isNaN(d?.date)!==true?d.date: "",
            desc: d?.desc!==undefined|| Boolean(d?.desc)!==false|| isNaN(d?.desc)!==true?d.desc: "",
            name: d?.name,
            url: d?.url,
        }
    })
}
