/**
 * Accumulators reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { MemberAccumulator } from "./types"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"
import { FamilyMember } from "../account/types"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Accumulators {
    readonly accumulators: {
        readonly [membershipId: string]: MemberAccumulator
    }
    readonly family: {
        readonly items: ReadonlyArray<FamilyMember>
    }
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<Accumulators> = {
    status: "pristine",
    accumulators: {},
    family: { items: [] },
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const accumulators = createStatefulSlice({
    name: "accumulators",
    initialState,
    reducers: {
        receiveAccumulators: (
            state: SliceState<Accumulators>,
            action: PayloadAction<Accumulators>,
        ) => {
            return {
                status: "completed",
                accumulators: action.payload.accumulators || {},
                family: action.payload.family || { items: []},
            }
        },
        resetAccumulators: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const accumulatorsActions = { ...accumulators.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default accumulators.reducer
