import * as React from "react"
import { SVGProps } from "react"
const SvgSpinner = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Spinner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
            <g transform="translate(12.000000, 0.000000)" fill="currentColor" fillRule="nonzero">
                <path d="M9.87873551,17.9212763 L8.87095116,17.3318681 C8.60277976,17.1750292 8.50603285,16.8337981 8.6452729,16.5536061 C10.103833,13.6175475 10.0170076,10.0990425 8.38460314,7.24103556 C6.75645767,4.37812586 3.79185938,2.54258665 0.552556014,2.35417411 C0.243343403,2.33618103 0,2.08074795 0,1.76702121 L0,0.588253904 C0,0.249768296 0.281093573,-0.0165979136 0.614795396,0.000806835902 C4.65870976,0.211919094 8.36630883,2.49453974 10.3964452,6.06437644 C12.4321957,9.62847693 12.5297654,14.0223182 10.6883315,17.6756487 C10.5363628,17.9770695 10.1680567,18.09047 9.87873551,17.9212763 Z" />
            </g>
        </g>
    </svg>
)
export default SvgSpinner
