/**
 * Claims Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import { http } from "utils"
import { claimsQueryDFIS } from "./queries"
import { getHash } from "@/utils/crypto"

/**
 * Get claims data
 * -----------------------------------------------------------------------------
 */
export const fetchClaims = createAsyncThunk(
    "claims/fetchClaims", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        const { claims } = store.getState()
        dispatch(actions.receiveClaims({ ...claims, isLoading: true }))

        /**
         * Fetch claims (from DFIS)
         */
        const resDFIS = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            claimsQueryDFIS,
        )

        /**
         * Handle request errors
         */
        if (resDFIS.error) {
            dispatch(
                actions.receiveClaims({
                    ...claims,
                    isLoading: false,
                    isInitialized: true,
                    errorMessage: "Could not retrieve claims data",
                }),
            )
            return
        }

        /**
         * Transform claims data
         */
        const claimsDFIS = resDFIS.data.data.claims.edges || []

        const items = claimsDFIS?.map(data => {
            return {
                ...data.node,
                claimNumberHash: getHash(data.node?.claimNumber),
            }
        })

        /**
         * Update state
         */
        dispatch(
            actions.receiveClaims({
                ...claims,
                items,
                isLoading: false,
                isInitialized: true,
            }),
        )
    },
)

/**
 * Update actions for claim filters
 * -----------------------------------------------------------------------------
 */
export const updateClaimNumber = createAsyncThunk(
    "claims/updateClaimNumber",
    async (claimNumber: string, { dispatch }) => {
        // Get current state
        const { claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimFilters({
                ...claims.claimFilters,
                claimNumber: claimNumber,
            }),
        )
    },
)

export const updateSortBy = createAsyncThunk(
    "claims/updateSortBy",
    async (sortBy: string, { dispatch }) => {
        // Get current state
        const { claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimFilters({
                ...claims.claimFilters,
                sortBy: sortBy,
            }),
        )
    },
)

export const updateMembers = createAsyncThunk(
    "claims/updateMembers",
    async (members: ReadonlyArray<string>, { dispatch }) => {
        // Get current state
        const { claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimFilters({
                ...claims.claimFilters,
                members: members,
            }),
        )
    },
)

export const updateStartRange = createAsyncThunk(
    "claims/updateStartRange",
    async (startRange: string, { dispatch }) => {
        // Get current state
        const { claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimFilters({
                ...claims.claimFilters,
                startRange: startRange,
            }),
        )
    },
)

export const updateEndRange = createAsyncThunk(
    "claims/updateEndRange",
    async (endRange: string, { dispatch }) => {
        // Get current state
        const { claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimFilters({
                ...claims.claimFilters,
                endRange: endRange,
            }),
        )
    },
)

export const updateMedical = createAsyncThunk(
    "claims/updateMedical",
    async (medical: boolean, { dispatch }) => {
        // Get current state
        const { claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimFilters({
                ...claims.claimFilters,
                medical: medical,
            }),
        )
    },
)

export const updatePharmacy = createAsyncThunk(
    "claims/updatePharmacy",
    async (pharmacy: boolean, { dispatch }) => {
        // Get current state
        const { claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimFilters({
                ...claims.claimFilters,
                pharmacy: pharmacy,
            }),
        )
    },
)

export const updateDental = createAsyncThunk(
    "claims/updateDental",
    async (dental: boolean, { dispatch }) => {
        // Get current state
        const { claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimFilters({
                ...claims.claimFilters,
                dental: dental,
            }),
        )
    },
)

export const updateProviders = createAsyncThunk(
    "claims/updateProviders",
    async (providers: ReadonlyArray<string>, { dispatch }) => {
        // Get current state
        const { claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimFilters({
                ...claims.claimFilters,
                providers: providers,
            }),
        )
    },
)

export const updateCompleted = createAsyncThunk(
    "claims/updateCompleted",
    async (completed: boolean, { dispatch }) => {
        // Get current state
        const { claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimFilters({
                ...claims.claimFilters,
                completed: completed,
            }),
        )
    },
)

export const updatePending = createAsyncThunk(
    "claims/updatePending",
    async (pending: boolean, { dispatch }) => {
        // Get current state
        const { claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimFilters({
                ...claims.claimFilters,
                pending: pending,
            }),
        )
    },
)

export const updateAdjusted = createAsyncThunk(
    "claims/updateAdjusted",
    async (adjusted: boolean, { dispatch }) => {
        // Get current state
        const { claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimFilters({
                ...claims.claimFilters,
                adjusted: adjusted,
            }),
        )
    },
)

export const updateSortByOpen = createAsyncThunk(
    "claims/updateSortByOpen",
    async (sortByOpened: boolean, { dispatch }) => {
        // Get current state
        const { claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimFilters({
                ...claims.claimFilters,
                sortByOpened: sortByOpened,
            }),
        )
    },
)

export const updateMemberOpened = createAsyncThunk(
    "claims/updateMemberOpened",
    async (memberOpened: boolean, { dispatch }) => {
        // Get current state
        const { claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimFilters({
                ...claims.claimFilters,
                memberOpened: memberOpened,
            }),
        )
    },
)

export const updateDateOpened = createAsyncThunk(
    "claims/updateDateOpened",
    async (dateOpened: boolean, { dispatch }) => {
        // Get current state
        const { claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimFilters({
                ...claims.claimFilters,
                dateOpened: dateOpened,
            }),
        )
    },
)

export const updateCategoryOpened = createAsyncThunk(
    "claims/updateCategoryOpened",
    async (categoryOpened: boolean, { dispatch }) => {
        // Get current state
        const { claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimFilters({
                ...claims.claimFilters,
                categoryOpened: categoryOpened,
            }),
        )
    },
)

export const updateProviderOpened = createAsyncThunk(
    "claims/updateProviderOpened",
    async (providerOpened: boolean, { dispatch }) => {
        // Get current state
        const { claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimFilters({
                ...claims.claimFilters,
                providerOpened: providerOpened,
            }),
        )
    },
)

export const updateStatusOpened = createAsyncThunk(
    "claims/updateStatusOpened",
    async (statusOpened: boolean, { dispatch }) => {
        // Get current state
        const { claims } = store.getState()

        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimFilters({
                ...claims.claimFilters,
                statusOpened: statusOpened,
            }),
        )
    },
)
