/**
 * Insights reducer
 * -----------------------------------------------------------------------------
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { VspInfoItem } from "./types"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface VspInfo {
    // Base props
    readonly isInitialized: boolean
    readonly isLoading: boolean
    readonly errorMessage?: string

    // VspInfo props
    readonly items: ReadonlyArray<VspInfoItem>
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: VspInfo = {
    isInitialized: false,
    isLoading: false,
    errorMessage: undefined,
    items: [],
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const vspInfo = createSlice({
    name: "vspInfo",
    initialState,
    reducers: {
        receiveVspInfo: (state: VspInfo, action: PayloadAction<VspInfo>) => {
            return action.payload
        },
        resetVspInfo: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const vspInfoActions = { ...vspInfo.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default vspInfo.reducer
