/**
 * GraphQL query to fetch benefits
 */
export const personalInfoQuery = `
query{      
  myAccount {
      accountInformation {
          genderIdentity {
              options {
                  id
                  code
                  display
              }
              selected {
                  id
                  code
                  display
              }
          }
          sexualOrientation {
              options {
                  id
                  code
                  display
              }
              selected {
                  id
                  code
                  display
              }
          }
      }
  }
}`


export const updateSelectedGenderIdentity =
`mutation ($id: String! $code: String! $display: String!) {
    updateSelectedGenderIdentity(id: $id, code: $code, display: $display) {
    success
  }
}`

export const updateSelectedSexualOrientation =
`mutation ($id: String! $code: String! $display: String!) {
    updateSelectedSexualOrientation(id: $id, code: $code, display: $display) {
    success
  }
}`
