export const recentClaimsQuery = `
query {
  claimsInquiry {
    recentClaims {
      claimData (limit: 3) {
        subscriberId
        suffix
        membershipId
        uniqueMemberIdentifier
        familyStructureId
        firstName
        lastName
        dateOfBirth
        groupId
        groupName
        claimId
        claimNumber
        adjusted
        claimServiceStartDate
        claimServiceEndDate
        claimStatusCode
        claimStatusDescription
        claimType
        providerId
        providerName
        processedDate
        receivedDate
        pharmacyName
        totalPatientResponsibilityAmount
      }
    }
    }
  }
`
