import * as React from "react"
import { SVGProps } from "react"
const SvgMagnifyPlus = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Magnifying glass with plus sign"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M17 17L23 23M10 6.10001V13.9M13.9 10H6.09998M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgMagnifyPlus
