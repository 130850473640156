/**
 * Onlife Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { store } from "store"
import { http } from "utils"
import { Features } from "utils/features"
import { onlifeQuery, mpsOnLifeQuery, mapsWellnessQuery } from "./queries"
import { updateWellnessAuthorizationPreferenceMutation } from "./mutations"
import { OnlifeIncentives, OnlifeJourney, OnlifeUserModelData } from "./types"

export const fetchOnlifeUserModelData = createAsyncThunk<OnlifeUserModelData, void, { readonly rejectValue: string }>(
    "onlife/fetchOnlifeUserModelData",
    async (params: void, { rejectWithValue }) => {
        const { app } = store.getState()
        const { [Features.MPS_ONLIFE_QUERY_ENABLED]: mpsOnlifeQueryEnabled } = app.features
        const { [Features.AUDIENCE_MEDICARE]: isMedicare } = app.features


        // eslint-disable-next-line functional/no-let
        let responseData
        // eslint-disable-next-line functional/no-let
        let userModelRes

        const mapsRes = await http.query("/api/janus/digital-first-information-service/graphql", mapsWellnessQuery)
        if (mapsRes.status !== 200) {
            return rejectWithValue("Could not retrieve MAPS data")
        }

        /**
         * Fetch onlife
         * MEPS onlife data
         */
        if ( mpsOnlifeQueryEnabled ) {
            const mpsOnlifeRes = await http.query("/api/janus/digital-first-information-service/graphql", mpsOnLifeQuery)

            if (mpsOnlifeRes.error || mpsOnlifeRes.data?.errors) {
                return rejectWithValue("Could not retrieve dfis onlife data")
            }

            const wellnessAuthTimestamp = mapsRes.data?.data?.myAccount?.preferences?.consumerSettings?.wellness?.timestamp
            const planStartDate = mpsOnlifeRes.data?.data?.primaryPolicy?.requesterMembership?.primaryCoverage?.primaryEligibility[0]?.effectiveDate

            const isAuthorizationRequired =
                (wellnessAuthTimestamp && new Date(wellnessAuthTimestamp) < planStartDate) // Reauthorization should happen even if it's not required by the plan, if it's now expired (even for those that declined in the past)
                || (!wellnessAuthTimestamp && app.features[Features.WELLNESS_AUTHORIZATION_REQUIRED]) // Require authorization if there is no timestamp


            // Onlife-specific data may potentially fail selectively in the meps query, depending on third-party responses
            const messageCountFailure = mpsOnlifeRes.data?.data?.errors && mpsOnlifeRes.data?.errors.find(error => error.path[1] === "messageCount")
            const journeyFailure = mpsOnlifeRes.data?.data?.errors && mpsOnlifeRes.data?.errors.find(error => error.path[1] === "journey")
            const incentivesFailure = mpsOnlifeRes.data?.data?.errors && mpsOnlifeRes.data?.errors.find(error => error.path[1] === "incentives")

            const journey: OnlifeJourney = {
                "incentiveSymbol": mpsOnlifeRes?.data?.data?.onlife?.journey?.incentiveSymbol,
                "incentiveType": mpsOnlifeRes?.data?.data?.onlife?.journey?.incentiveType,
                "incentiveValue": mpsOnlifeRes?.data?.data?.onlife?.journey?.incentiveValue,
                "isIncentiveAssociated": mpsOnlifeRes?.data?.data?.onlife?.journey?.isIncentiveAssociated,
                "journeyCardName": mpsOnlifeRes?.data?.data?.onlife?.journey?.journeyCardName,
            }

            const incentives: OnlifeIncentives = {
                "potentialIncentives": mpsOnlifeRes?.data?.data?.onlife?.incentives?.potentialIncentives || 0,
                "earnedIncentives": mpsOnlifeRes?.data?.data?.onlife?.incentives?.earnedIncentives || 0,
            }

            const onlifeMessageCount = mpsOnlifeRes?.data?.data?.onlife?.messageCount || 0


            return {
                isMedicare: isMedicare,
                isAuthorizationRequired: isAuthorizationRequired,
                isMessageCountFailure: messageCountFailure,
                isJourneyFailure: journeyFailure,
                isIncentivesFailure: incentivesFailure,
                journey: journey,
                incentives: incentives,
                messageCount: onlifeMessageCount,
            }

        } else {
            userModelRes = await http.query("/api/adif/user-model", onlifeQuery)
            if (userModelRes.error) {
                return rejectWithValue("Could not retrieve user-model data")
            }

            responseData = userModelRes.data?.data
            responseData = {
                ...userModelRes.data?.data,
                member: {
                    ...userModelRes.data?.data?.member,
                    preferences: {
                        wellnessAuth: {
                            timestamp: mapsRes.data?.data?.myAccount?.preferences?.consumerSettings?.wellness?.timestamp
                        }
                    }
                }
            }

            /**
             * Transform User model onlife data
             */
            const coverages = responseData?.member?.coverages || []

            const wellnessAuthTimestamp = responseData?.member?.preferences?.wellnessAuth?.timestamp
            const planStartDate = coverages.map(coverage => new Date(coverage.startDate)).sort((startA, startB) => startA - startB)[0]

            const isAuthorizationRequired =
                (wellnessAuthTimestamp && new Date(wellnessAuthTimestamp) < planStartDate) // Reauthorization should happen even if it's not required by the plan, if it's now expired (even for those that declined in the past)
                || (!wellnessAuthTimestamp && app.features[Features.WELLNESS_AUTHORIZATION_REQUIRED]) // Require authorization if there is no timestamp

            // Onlife-specific data may potentially fail selectively in the user-model query, depending on third-party responses
            const messageCountFailure = userModelRes.data?.errors && userModelRes.data?.errors.find(error => error.path[1] === "onLifeMessageCount")
            const journeyFailure = userModelRes.data?.errors && userModelRes.data?.errors.find(error => error.path[1] === "onLifeJourney")
            const incentivesFailure = userModelRes.data?.errors && userModelRes.data?.errors.find(error => error.path[1] === "onLifeIncentives")

            const journey: OnlifeJourney = {
                "incentiveSymbol": responseData?.member?.onLifeJourney?.incentiveSymbol,
                "incentiveType": responseData?.member?.onLifeJourney?.incentiveType,
                "incentiveValue": responseData?.member?.onLifeJourney?.incentiveValue,
                "isIncentiveAssociated": responseData?.member?.onLifeJourney?.isIncentiveAssociated,
                "journeyCardName": responseData?.member?.onLifeJourney?.journeyCardName,
            }

            const incentives: OnlifeIncentives = {
                "potentialIncentives": responseData?.member?.onLifeIncentives?.potentialIncentives || 0,
                "earnedIncentives": responseData?.member?.onLifeIncentives?.earnedIncentives || 0,
            }

            const onlifeMessageCount = responseData?.member?.onLifeMessageCount || 0


            return {
                isMedicare: isMedicare,
                isAuthorizationRequired: isAuthorizationRequired,
                isMessageCountFailure: messageCountFailure,
                isJourneyFailure: journeyFailure,
                isIncentivesFailure: incentivesFailure,
                journey: journey,
                incentives: incentives,
                messageCount: onlifeMessageCount,
            }
        }
    },
)

export const fetchWellnessAuthorizationContent = createAsyncThunk<string, void, { readonly rejectValue: string }>(
    "onlife/fetchWellnessAuthorizationContent",
    async (params: void, { rejectWithValue }) => {
        const config = { params: { id: "oDth2XTZnfdg7sUBBWuQ9U" } }
        const res = await http.get(`/api/managed-content-blocks`, config)

        if (res.error) {
            return rejectWithValue("Failed to get onlifeAuthorizationAgreement content from Styx")
        }

        // TODO!!! Use a Styx component renderer that correctly uses consumer rule logic & devo component mappings
        return res.data[0].data?.content[0]?.markdown1
    })

export const saveWellnessAuthorizationPreference = createAsyncThunk<void, { readonly preference: boolean, readonly signature: string }, { readonly rejectValue: string }>(
    "onlife/saveWellnessAuthorizationPreference",
    async ({ preference, signature }, { rejectWithValue }) => {
        // Attempt preference save
        const res = await http.query("/api/adif/user-model", updateWellnessAuthorizationPreferenceMutation(preference, signature))

        if (res.error || res.data?.errors?.length > 0) {
            return rejectWithValue("Could not update wellness authorization preference")
        }

        // Preference save successful
    })