/**
 * Utilities import helper
 * -----------------------------------------------------------------------------
 */
/**
 * HTTP utilities
 */
export { default as http } from "./http"

/**
 * Cookie utilities
 */
export {
    setSessionToken,
    getSessionToken,
    getAdifSessionToken,
    setAdifSSOSessionToken,
    setSiteminderSession,
    destroySessionToken,
    destroyAllAuthenticationCookies,
} from "./cookies"

/**
 * Environment utilities
 */
export {
    REGENCE_TENANTS,
    ENVIRONMENT_PREFIXES,
    getEnvironment,
    getBrand,
    useBrand,
    useDevFlag,
    isNonProd,
} from "./env"

/**
 * String utilities
 */
export { formatCase, toCamelCase, toPascalCase, toKebabCase, toNumber } from "./string"

/**
 * Currency utilities
 */
export { formatCurrency } from "./currency"

/**
 * Date utilities
 */
export { parseDate, formatDate, separateDate } from "./date"

/**
 * Window utilities
 */
export {
    useKeyPress,
    useBodyScroll,
    useEscapeModal,
    useFocusNext,
    useClickAway,
} from "./window"

/**
 * Event utilities
 */
export { useDebounce } from "./event"

/**
 * Onboarding utilities
 */
export { useOnboardingClient } from "./onboarding"

/**
 * Phone utilities
 */
export { formatPhone } from "./phone"
