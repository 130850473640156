
/**
 * Account Settings Actions
 * -----------------------------------------------------------------------------
 */

import { http } from "utils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { actions, store } from "store"
import { fetchLetterNoticesQuery, myAccountSettingsQuery } from "./queries";
import { transformMemberSettings } from "./utils";
import {format as formatDate, sub,} from "date-fns"

const dateFormat = "MM-dd-yyyy"
const toDate = formatDate(new Date(), dateFormat).toString()
//Load max 2 Years letters and notices
const fromDate = formatDate(sub(new Date(), { years: 2 }), dateFormat).toString()

/**
 * Get Member Settings
 * -----------------------------------------------------------------------------
 */
export const fetchMemberSettings = createAsyncThunk(
    "member-settings/fetchMemberSettings", // Reducer name
    async(params: void, {dispatch}) => {
        /**
         * get state
         */
        const { myAccount } = store.getState()

        /**
         * Request account settings data
         */
        try {
            const url = "/api/janus/digital-first-information-service/graphql"
            const res = await http.query(url, myAccountSettingsQuery)
            if(!res || res?.error) {
                console.error("account err", res.error)
                return dispatch(actions.myAccountError({ response: res.data }))
            }

            /**
             * Transform Account Settings
             */
            const data = res.data?.data?.myAccount || {}
            const transformed = transformMemberSettings(data)

            /**
             * Update state
             */
            dispatch(
                actions.receiveMyAccountSettings({
                    ...myAccount,
                    status: "completed",
                    profile: transformed || {},
                    consumerSettings: data.preferences?.consumerSettings,
                }),
            )
        } catch (error) {
            console.error("Failed to get member info", error)
        }
    }
)


/**
 * Get Letters & Notices
 * -----------------------------------------------------------------------------
 */
export const fetchLetterNotices = createAsyncThunk(
    "member-settings/fetchLetterNotices", // Reducer name
    async(params: void, {dispatch}) => {        
        /**
         * Request letters & notices data from member service
         */
        try {
            const url = "/api/janus/digital-first-information-service/graphql"
            const res = await http.query(url, fetchLetterNoticesQuery(fromDate, toDate ))
            if(!res || res?.error || res?.data?.errors) {
                console.error("falied to fetch Letters & Notices", res.data.error)
            }
            /**
             * Update state
             */
            dispatch(
                actions.receiveLetterNotices({
                    letterList: res.data?.data?.fetchableDocuments
                }),
            )
        } catch (error) {
            console.error("Failed to get letter & notices", error)
        }
    }
)