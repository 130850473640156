/**
 * Accumulators Utilities
 * -----------------------------------------------------------------------------
 *
 */
import { FamilyMember } from "../account/types"
import {
    Accumulator,
    AccumulatorData,
    MemberAccumulator,
    NamedAccumulator,
    TieredAccumulator,
} from "./types"
import { Accumulators } from "./reducer"
import isEmpty from "lodash/isEmpty"
import { formatCurrency } from "@/utils/currency"
import { formatCase } from "utils/string"

/**
 * Define Annual Costs
 * -----------------------------------------------------------------------------
 */
export interface AnnualCost {
    readonly type: "deductible" | "oop"
    readonly label: string
    readonly percent: number
    readonly title: string
    readonly subtitle: string
    readonly remaining: string
}
export const defineAnnualCosts = (
    data: Accumulators,
    id?: string,
): ReadonlyArray<AnnualCost> => {
    /**
     * Define member account id (or fallback to first accumulator id)
     */
    const accountId = id
    const firstAccumId = Object.keys(data.accumulators)[0]

    /**
     * Define tiers details
     */
    const medical =
        data.accumulators?.[accountId]?.medical ||
        data.accumulators?.[firstAccumId]?.medical
    const tiers = medical?.accumulatorTiers?.[0]?.accumulatorTierDetails
    if (!tiers) return []

    /**
     * Determine whether member has multi-tier deductible
     * 
     * NOTE: We're determining whether a member has "multi-tier"
     * if the deductible description is anything other than 
     * "Member Deductible". Some plans might say something like
     * "Ded w/4th Qtr Carryover Unless Met". (strange world)
     */
    const description = tiers?.individualDeductibles ? tiers?.individualDeductibles[0]?.memberAccumulatorDescription : ""
    const isMultiTier = description && !description.match(/medical deductible/i)

    /**
     * Determine whether member has family/dependants
     */
    const isFamily = !!tiers?.familyDeductibles

    /**
     * Assemble annual costs
     */
    const individual = []
    if (tiers.individualDeductibles) { individual.push(formatAnnualCost(tiers.individualDeductibles[0], "deductible")) }
    if (tiers.individualOutOfPocketMaxes) { individual.push(formatAnnualCost(tiers.individualOutOfPocketMaxes[0], "oop")) }

    const multiTier = []
    if (tiers.individualDeductibles) { multiTier.push(formatAnnualCost(tiers.individualDeductibles[0], "deductible")) }
    if (tiers.familyDeductibles) { multiTier.push(formatAnnualCost(tiers.familyDeductibles[0], "deductible", true)) }
    if (tiers.individualOutOfPocketMaxes) { multiTier.push(formatAnnualCost(tiers.individualOutOfPocketMaxes[0], "oop")) }
    if (tiers.familyOutOfPocketMaxes) { multiTier.push(formatAnnualCost(tiers.familyOutOfPocketMaxes[0], "oop", true)) }
    
    const notMultiTier = []
    if (tiers.familyDeductibles) { notMultiTier.push(formatAnnualCost(tiers.familyDeductibles[0], "deductible", true)) }
    if (tiers.familyOutOfPocketMaxes) { notMultiTier.push(formatAnnualCost(tiers.familyOutOfPocketMaxes[0], "oop", true)) }

    const family = isFamily ? isMultiTier ? multiTier : notMultiTier : []

    /**
     * Return annual costs
     */
    return isFamily ? family : individual
}

/**
 * Format Annual Cost item (used above)
 */
const formatAnnualCost = (
    data: Accumulator, 
    type: "deductible" | "oop",
    isFamily?: boolean
): AnnualCost => {
    if (!data) return undefined

    // Calculate values
    const medical = parseFloat(data.accumulatorMedicalAmountMet) || 0.0
    const rx = parseFloat(data.accumulatorRxAmountMet) || 0.0
    const met = medical + rx
    const total = parseFloat(data.accumulatorTotal)
    const percent = Math.round((met / total) * 100)
    const remaining = total - met

    // Define label
    const description = data.memberAccumulatorDescription
    const label =
        !description.startsWith("Member") &&
        !description.startsWith("Family")
            ? (isFamily ? "Family " : "Member ") + description
            : description

    // Return annual cost item
    return {
        type: type,
        label: label,
        percent: percent,
        title: percent.toString() + "%",
        subtitle: "of " + formatCurrency(total, true) + " total",
        remaining: formatCurrency(remaining, false) + " to go",
    }
}

/**
 * String helpers
 */
const capitalize = (value: string) => {
    if (!value) return ""
    return formatCase(value, "titlecase")
}
/**
 * Transform `benefits-accumulator-service` data
 * -----------------------------------------------------------------------------
 *
 */
export const transformAccumulatorsAPIData = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
): {
    readonly accumulators: AccumulatorData
    readonly familyMembers: ReadonlyArray<FamilyMember>
} => {
    return {
        accumulators: data?.accumulators?.edges?.reduce(
            (acc, { node: accumulator }) => {
                return {
                    ...acc,
                    [accumulator.membershipId]: {   
                        patientId: accumulator.patientId,
                        medical: accumulator.medical,
                        dental: accumulator.dental,
                    },
                }
            },
            {},
        ),
        familyMembers: data.benefits?.familyMembers.map(member => {
            return {
                id: member.membershipId,
                firstName: capitalize(member.firstName), //fix capitalize
                lastName: capitalize(member.lastName), //fix capitalize
                suffix: member.suffix,
                birthDate: member.birthDate,
                relationship: member.relationship,
                isSharingAuthorized: member.disclosureAllowed,
            }
        }),
    }
}

/**
 * Transforms accumulators of given category to named accumulators format
 * {member-name, accumulator}
 */
export const transformFamilyMembersAccumulators = (
    category: "medical" | "dental",
    type: "individualDeductibles" | "individualOutOfPocketMaxes",
    familyMembers: ReadonlyArray<FamilyMember>,
    accumulators: {
        readonly [membershipId: string]: MemberAccumulator
    },
): ReadonlyArray<NamedAccumulator> => {
    const namedMemberAccumulators = !isEmpty(accumulators)
        ? familyMembers.map(member => {
            return {
                name: member.firstName,
                accumulators:
                      accumulators[member.id]?.[category]?.accumulatorTiers?.[0]
                          .accumulatorTierDetails?.[type],
            }
        })
        : []
    return namedMemberAccumulators.filter(
        namedAccumulator =>
            namedAccumulator.accumulators?.filter(
                accumulator => accumulator?.accumulatorTotal,
            )?.length,
    )
}

/**
 * Transforms accumulators of a given member and category to named accumulators format
 * {tier-name, accumulator}
 */
export const transformTieredAccumulators = (
    category: "medical" | "dental",
    type:
        | "individualDeductibles"
        | "individualOutOfPocketMaxes"
        | "familyDeductibles"
        | "familyOutOfPocketMaxes",
    membershipId: string,
    accumulators: {
        readonly [membershipId: string]: MemberAccumulator
    },
): ReadonlyArray<NamedAccumulator> => {
    const accumulatorTiers =
        accumulators?.[membershipId]?.[category]?.accumulatorTiers
    return !isEmpty(accumulatorTiers)
        ? accumulatorTiers
            .filter(
                tier =>
                    tier?.accumulatorTierDetails?.[type]?.filter(
                        accumulatorType => accumulatorType?.accumulatorTotal,
                    )?.length,
            )
            .map(tier => {
                return {
                    name: tier?.tierDescription,
                    accumulators: tier?.accumulatorTierDetails?.[type],
                }
            })
        : []
}

/**
 * Transforms accumulators to usage form
 * Extracts accumulators for selected tier and then transforms the same
 */
export const transformToAccumulatorsUsage = (
    category: "medical" | "dental",
    type:
        | "individualDeductibles"
        | "individualOutOfPocketMaxes"
        | "familyDeductibles"
        | "familyOutOfPocketMaxes",
    level: "INDIVIDUAL" | "FAMILY",
    membershipId: string,
    selectedTierName: string,
    accumulators: {
        readonly [membershipId: string]: MemberAccumulator
    },
): TieredAccumulator => {
    const tieredAccumulators = transformTieredAccumulators(
        category,
        type,
        membershipId,
        accumulators,
    )

    // Retrieve details for selected tier
    const selectedTier = tieredAccumulators?.find(
        tieredAccumulator => tieredAccumulator.name === selectedTierName,
    )
    const accumulatorItems = selectedTier?.accumulators

    if (accumulatorItems?.length) {
        // Retrieve first accumulator entry in case of multiple accumulators in single tier
        const accumulatorItem = accumulatorItems?.[0]

        const accumUsedValueMedical =
            parseFloat(accumulatorItem?.accumulatorMedicalAmountMet) || 0.0
        const accumUsedValuePrescription =
            parseFloat(accumulatorItem?.accumulatorRxAmountMet) || 0.0
        const accumUsedValueTotal =
            accumUsedValueMedical + accumUsedValuePrescription
        const accumulatorMax = parseFloat(accumulatorItem?.accumulatorTotal)
        const accumulatorMaxText =
            0.0 + parseInt(accumulatorItem?.accumulatorTotal, 10) ===
            accumulatorMax
                ? parseInt(accumulatorItem?.accumulatorTotal, 10)
                : accumulatorMax

        // Convert to accumulators usage form
        return {
            tierName: selectedTierName,
            label: level,
            max: `${formatCurrency(accumulatorMaxText, true)} total`,
            values: {
                accumUsedValueTotal: accumUsedValueTotal,
                accumulatorMax: accumulatorMax,
            },
        }
    }

    return undefined
}

export const HELP_TEXT = {
    DEDUCTIBLE:
        "This is the fixed amount you are required to meet before your health plan begins to pay for covered services in a plan year. Some covered services and medications do not apply toward a deductible.",
    OOP_MAX:
        "This is the maximum amount you pay for covered services in a plan year. If this limit is reached, your health plan will pay 100% of all covered health care costs for the remainder of the year.",
}
