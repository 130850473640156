/**
 * i18n - Internationalization
 * -----------------------------------------------------------------------------
 */
import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import { en, es } from "./../app_strings"

/**
 * Initialize
 * -----------------------------------------------------------------------------
 */
i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        returnObjects: true,
        resources: {
            en: { translation: en },
            es: { translation: es },
        },
        fallbackLng: { default: ["en"] },
    })

/**
 * Export i18next
 * -----------------------------------------------------------------------------
 */
export default i18n
