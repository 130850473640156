import * as React from "react"
import { SVGProps } from "react"
const SvgMoon = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Moon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M2 12C2 18.0751 6.92487 23 13 23C16.424 23 19.4826 21.4356 21.5 18.9826C21.5 18.9826 22.3 18 21.8 17.5C21.3 17 21.0219 17.5032 18.261 17.0016C15.5 16.5 12 14.5 11.5023 11C11.0045 7.5 13 5.5 14 4.5C15 3.5 16 3.5 16 2.3C16 1.1 13 1 13 1C6.92487 1 2 5.92487 2 12Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgMoon
