/**
 * Phone Utilities
 * -----------------------------------------------------------------------------
 */

/**
 * Format phone number provided without country codes to ###-###-#### style.
 * @param phone phone number string
 * @returns phone number string formatted in ###-###-####
 */
export const formatPhone = (phone: string): string => {
    // eslint-disable-next-line functional/no-let
    let formattedPhone = ""
    if (phone) {
        const cleaned = phone.replace(/\D/g, "")
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            formattedPhone = `(${match[1]}) ${match[2]}-${match[3]}`
        }
    }
    return formattedPhone;
}
