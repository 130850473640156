import * as React from "react"
import { SVGProps } from "react"
const SvgNoSymbol = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="No symbol"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M18.1 5.8L5.79999 18.1M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgNoSymbol
