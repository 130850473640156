/**
 * Utitlity functions for onboarding workflows
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { useDispatch, useSelector, actions } from "store"
import Routes, { useRouter } from "router"

/**
 * Set of routes that should not be considered for trigerring redirection in case of pending actions.
 * This will avoid redirect loop.
 */
const PENDING_ACTIONS_REDIRECT_BLACKLIST: ReadonlySet<string> = new Set([
    // old adif urls
    "/member/contact-us",
    "/medicare/contact-us",
    "/member/go-paperless",

    Routes.signin(),
    Routes.signout(),
    Routes.settings(),
    Routes.support(),
    Routes.termsAndPrivacy(),

    // in case of following routes, user is already on pending action pages so no need to redirect.
    Routes.verifyEmailClassic(),
    Routes.verifySms(),
])

export const useOnboardingClient = () => {
    /**
     * Hooks
     */
    const dispatch = useDispatch()
    const router = useRouter()
    const myAccount = useSelector(state => state.myAccount)

    /**
    * Added to support communication of important events from Iframe.
    * Based on these events in iframed pages, app state is updated to ensure app reacts to those events.
    * @param event 
    */
    const messageListener = (event: MessageEvent) => {
        switch (event.data?.message) {
            case "PHONE_ADDED":
                dispatch(actions.setSmsVerificationStatus("PENDING"))
                break;
            case "PHONE_DELETED":
                dispatch(actions.setSmsVerificationStatus("NOT_FOUND"))
                break;
            case "PHONE_CANCELLED":
                dispatch(actions.setSmsVerificationStatus("UNKNOWN"))
                break;
            case "PHONE_VERIFIED":
                dispatch(actions.setSmsVerificationStatus("VERIFIED"))
                break;
            case "EMAIL_ADDED":
                dispatch(actions.setEmailVerificationStatus("PENDING"))
                break;
            case "EMAIL_VERIFIED":
                dispatch(actions.setEmailVerificationStatus("VERIFIED"))
                break;
            case "MEMBER_CARDS_ORDERED":
                // Refresh member cards redux state
                dispatch(actions.fetchMemberCards())
                break;
            default:
                break;
        }
    }

    /**
     * Initialize message event listner to check for events from iframe loaded ADIF pages.
     */
    React.useEffect(() => {
        window.addEventListener("message", messageListener)
        return () => window.removeEventListener("message", messageListener)
    }, [])


    /**
     * Lifecycle: Check for pending actions such as pending email and phone verification.
     * Keep redirect users to related experience until they complete verification.
     */
    React.useEffect(() => {
        const basePath = router.asPath.split("?").shift()
        if (!PENDING_ACTIONS_REDIRECT_BLACKLIST.has(basePath)) {
            if (myAccount.profile.emailVerificationStatus === "PENDING") {
                // router.push(Routes.verifyEmailClassic())
            } else if (myAccount.profile.smsVerificationStatus === "PENDING") {
                // router.push(Routes.verifySms())
            }
        }
    }, [router.asPath, myAccount])
}
