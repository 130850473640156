export const rxCoverageEligibilityQuery = (
  firstName: string,
  lastName: string,
  memberId: string,
  dateOfBirth: string,
  ndcs: ReadonlyArray<string>,
  productType: string,
) => {
  return `
    query rxCoverageEligibilityQuery {
      rxCoverage(
        firstName: "${firstName}",
        lastName: "${lastName}",
        memberId: "${memberId}",
        dateOfBirth: "${dateOfBirth}",
        ndcs: ${JSON.stringify(ndcs)},
        productType: "${productType}",
      )
      {
        rxCoverageEligibility {
            inForce
            excluded
            tierDescription
            insuranceItemName
            ndc
            representativeNdc
            drugName
        }
      }
    }
`
}

