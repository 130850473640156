/**
 * Programs reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import {
    createStatefulSlice,
    SliceState,
} from "store/util/create-stateful-slice"
import { Pill } from "../resources/reducer"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export type Category =
    | "All"
    | "Find care"
    | "Behavioral health"
    | "Pregnancy"
    | "Telehealth"
    | "Hca"
    | "Cis"
    | "BSH"

export interface Program {
    readonly id: string
    readonly category: ReadonlyArray<Category>
    readonly level: 1 | 2 | 3 | 4
    readonly type: "default" | "tool" | "large" // Use for sorting/ordering
    readonly title: string
    readonly description: string
    readonly url: string
    readonly external: boolean
    readonly showExternalMessage: boolean
    readonly isExternalOnClick?: boolean
    readonly enabled: boolean
    readonly linkText?: string
    readonly contact?: string
    readonly onClick?: VoidFunction
    readonly cta?: {
        readonly title: string
        readonly labels?: ReadonlyArray<string>
        readonly description?: string
        readonly actions: ReadonlyArray<CTAAction>
    }
    readonly subDescription?: string
    readonly pills?: ReadonlyArray<Pill>
}

export interface CTAAction {
    readonly type: "call" | "chat" | "link"
    readonly title: string
    readonly description?: string
    readonly label?: string
    readonly url?: string
    readonly availability?: string
}

export interface Sort {
    readonly id: string
    readonly label: string
    readonly sort: ReadonlyArray<string>
}

export interface Programs {
    readonly items: ReadonlyArray<Program>
    readonly sorting: {
        readonly [key: string]: ReadonlyArray<Sort>
    }
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<Programs> = {
    status: "pristine",
    items: [],
    sorting: {},
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const programs = createStatefulSlice({
    name: "programs",
    initialState,
    reducers: {
        receivePrograms: (
            state: SliceState<Programs>,
            action: PayloadAction<Programs>,
        ) => {
            return {
                status: "completed",
                items: action.payload.items,
                sorting: action.payload.sorting,
            }
        },
        resetPrograms: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const programsActions = { ...programs.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default programs.reducer
