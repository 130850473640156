import * as React from "react"
import { SVGProps } from "react"
const SvgTrash = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Trash can"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3 6.00018H21M10 11.0002V17.0002M14 11.0002V17.0002M17.5 22.0002H6.5C5.7 22.0002 5 21.3002 5 20.5002V6.00018H19V20.5002C19 21.3002 18.3 22.0002 17.5 22.0002ZM16 6.00018H8V3.50018C8 2.70018 8.7 2.00018 9.5 2.00018H14.5C15.3 2.00018 16 2.70018 16 3.50018V6.00018Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgTrash
