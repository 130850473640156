/* eslint-disable no-console */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions } from "store"
import http from "utils/http"
import { fetchClaimHoldersQuery } from "./queries"
import { transformClaimHolders } from "./utils"

/**
 * Get eligible member data
 * -----------------------------------------------------------------------------
 */
export const fetchClaimHolders = createAsyncThunk(
    "claimHolders/fetchClaimHolders", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.claimHoldersStatus("pending"))
        /**
         * Request eligible member data
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            fetchClaimHoldersQuery,
        )

        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.claimHoldersError({
                    response: res,
                }),
            )
        }
        
        const authorizedMembers = res.data?.data?.claimSubmission?.authorizedFamilyMembers
        const claimHolders = transformClaimHolders(authorizedMembers)
        
        /**
         * Update state
         */
        dispatch(
            actions.receiveClaimHolders({
                claimHolderDetails: claimHolders || [],
            }),
        )
    },
)
