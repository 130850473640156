/**
 * A GraphQL query to fetch all claims
 * It requests claims metadata like cacheStatus and cacheStatus along with
 * claimNumber and its corresponding claim details.
 * It does not request all other fields in claim headers as those are already retrieved with
 * header query
 */
export const claimsWithDetailsQuery = `
query {
  claimsInquiry {
    claims {
      cacheStatus
      totalCount
      claimData {
            claimNumber
            claimDetails {
              procedureType
              claimLineId
              claimLineServiceStartDate
              procedureCode
              procedureDescription
              negotiatedRate
              claimLinePaidAmount
              diagnosisCode
              claimId
              typeOfService
              unitsOfService
              lineNumber
              amaProcedureDescription
              diagnosisDescription
              coinsuranceAmount
              copayAmount
              deductibleAmount
              patientResponsibilityAmount
              billedAmount
              claimLineNotCoveredAmount
              claimLineServiceEndDate
              cobAmount
              claimDetailExplanationList {
                explanationDescription
                explanationCode
              }
            }
          }
        }
      }
  }
`;

/**
 * A GraphQL query to fetch only claim headers along with 
 * claims metadata like cacheStatus, totalCount and filters
 */
export const claimHeadersQuery = `
query {
  claimsInquiry {
    claims {
      cacheStatus
      totalCount
      filters {
            fieldName
            fieldDisplayName
            filterValues {
              filterValue
              filterDisplayName
            }
      }
      claimData {
            adjusted
            chargedAmount
            claimId
            claimNumber
            claimNumberAdjustedFrom
            claimNumberAdjustedTo
            claimServiceEndDate
            claimServiceStartDate
            claimStatusCode
            claimStatusDescription
            claimType
            dateOfBirth
            daysSuppliedPaid
            diagnosisType
            directionOfPay
            membershipId
            legacyFamilyMemberId
            familyStructureId
            firstName
            groupId
            groupName
            itsIndicator
            negotiatedAmount
            patientAccountNumber
            payeeIndicator
            providerId
            providerName
            quantityPaid
            receivedDate
            subscriberId
            uniqueMemberIdentifier
            totalPatientResponsibilityAmount
            processedDate
            memberCopayAmount
            claimNotCoveredAmount
            claimPaidAmount
            memberDeductibleAmount
            memberCoinsuranceAmount
            otherInsurancePaidAmount
            pharmacyName
            drugName
            drugCode
            deaNumber
            prescriptionNumber
            paymentNumber
            pharmacyNumber
            lastName
          }
        }
      }
  }
`;

/**
 * A GraphQL query to fetch claim details for given header claims
 */
export const claimDetailsQuery = `
query ($claimNumbers: [String]!) {
	claimsInquiry {
		claimLines(claimNumbers: $claimNumbers) {
			cacheStatus
			claimData {
			  claimNumber
			  claimDetails {
				procedureType
				claimLineId
				claimLineServiceStartDate
				procedureCode
				procedureDescription
				negotiatedRate
				claimLinePaidAmount
				diagnosisCode
				claimId
				typeOfService
				unitsOfService
				lineNumber
				amaProcedureDescription
				diagnosisDescription
				coinsuranceAmount
				copayAmount
				deductibleAmount
				patientResponsibilityAmount
				billedAmount
				claimLineNotCoveredAmount
				claimLineServiceEndDate
				cobAmount
				claimDetailExplanationList {
				  explanationDescription
				  explanationCode
				}
			  }
			}
		}
  }
}
`;
