/**
 * Pictogram
 * -----------------------------------------------------------------------------
 */
import React from "react"
import * as Types from "heartwood-component-library"
import { useBrand } from "utils/env"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Props {
    /**
     * Pictogram name per brand
     */
    readonly regence?: Types.PictogramProps["regence"]
    readonly asuris?: Types.PictogramProps["asuris"]
    readonly bridgespan?: Types.PictogramProps["bridgespan"]

    /**
     * Image element props
     */
    readonly alt?: string
    readonly className?: string

    /**
     * Disable brand and always show pictogram
     */
    readonly disableBrand?: boolean
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Pictogram = (props: Props) => {
    /**
     * Define brand
     */
    const brand = useBrand()

    /**
     * Methods
     */
    const defineDirectory = () => {
        if (props.disableBrand) {
            if (props.asuris) return "asuris"
            if (props.bridgespan) return "bridgespan"
            else return "regence"
        }
        if (brand === "regence-legacy") return "regence-legacy"
        if (brand === "aesthetic") return "asuris"
        if (brand === "bridgespan") return "bridgespan"
        return brand
    }
    const definePictogram = () => {
        if (props.disableBrand) {
            return (
                props.regence ||
                props.asuris ||
                props.bridgespan
            )
        }
        if (brand === "aesthetic") return props.asuris
        if (brand === "bridgespan") return props.bridgespan
        return props[brand]
    }

    /**
     * Define template variables
     */
    const pictogram = definePictogram()
    const directory = defineDirectory()

    /**
     * Template
     */
    if (!pictogram) return null
    return (
        <img
            src={`/assets/pictograms/${directory}/${pictogram}.png`}
            alt={props.alt || ""}
            className={props.className || ""}
        />
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Pictogram
