import * as React from "react"
import { SVGProps } from "react"
const SvgLock = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Lock" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M18 11V7C18 3.68629 15.3137 1 12 1C8.68629 1 6 3.68629 6 7V11M12 17V18M3 11H21V20C21 21.6569 19.6569 23 18 23H6C4.34315 23 3 21.6569 3 20V11Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgLock
