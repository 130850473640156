import * as React from "react"
import { SVGProps } from "react"
const SvgDocumentHeart = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Paper with heart inside"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M15 1V5C15 6.10457 15.8954 7 17 7H21M4.8 1H14.8759C15.3438 1 15.7968 1.16403 16.1563 1.46356L20.2804 4.90031C20.7364 5.2803 21 5.84319 21 6.43675V20.8C21 22.01 20.19 23 19.2 23H4.8C3.81 23 3 22.01 3 20.8V3.2C3 1.99 3.81 1 4.8 1ZM16.734 11.4335C16.5527 11.0651 16.2902 10.7463 15.968 10.503C15.6457 10.2597 15.2727 10.0987 14.8793 10.0332C14.4859 9.96768 14.0832 9.99944 13.7039 10.1259C13.3245 10.2523 12.9792 10.4699 12.696 10.761L12 11.4206L11.304 10.761C11.0207 10.47 10.6753 10.2525 10.2959 10.1261C9.91648 9.99977 9.51371 9.96814 9.12031 10.0338C8.7269 10.0994 8.35396 10.2605 8.03176 10.504C7.70957 10.7474 7.44721 11.0664 7.26599 11.4349C7.02945 11.9216 6.94774 12.4737 7.03265 13.0117C7.11755 13.5497 7.36469 14.0458 7.73846 14.4286L12 19L16.2615 14.4272C16.6353 14.0444 16.8824 13.5483 16.9674 13.0103C17.0523 12.4723 16.9706 11.9202 16.734 11.4335Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgDocumentHeart
