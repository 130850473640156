import isEmpty from "lodash/isEmpty"

export type DisplayRuleName = string
export type DisplayRuleCategory = string
export interface ValidRulesResponseData {
    readonly validRules: ReadonlyArray<DisplayRuleName>
    readonly validRulesByCategory: Record<
        DisplayRuleCategory,
        ReadonlyArray<DisplayRuleName>
    >
}

export type AllowRules = ReadonlyArray<DisplayRuleName>
export type DenyRules = ReadonlyArray<DisplayRuleName>
export type OneOfRules = ReadonlyArray<DisplayRuleName>

export type DisplayRuleId = string
export interface DisplayRule {
    readonly category: DisplayRuleCategory
    readonly id: DisplayRuleId
    readonly isValid: boolean
}


export type DisplayRulesResult = Record<string, boolean>
export interface NewDisplayValidRulesResponseData {
    readonly valid: boolean
    readonly explain?: Record<string, ReadonlyArray<unknown>>
    readonly validRules: ReadonlyArray<string>
    readonly invalidRules?: ReadonlyArray<string>
    readonly validRulesByCategory: Record<string, ReadonlyArray<string>>
    readonly invalidRulesByCategory?: Record<string, ReadonlyArray<string>>
    readonly rulesByCategory?: Record<string, DisplayRulesResult>
    readonly unknownRules?: ReadonlyArray<string>
}


export type DisplayRuleById = Record<DisplayRuleId, DisplayRule>

export const transformValidRulesResponseData = (
    validRulesResponseData: ValidRulesResponseData,
): DisplayRuleById => {
    const transformed: DisplayRuleById = {}
    for (const category in validRulesResponseData.validRulesByCategory) {
        const rulesForCategory =
            validRulesResponseData.validRulesByCategory[category]
        for (const ruleId of rulesForCategory) {
            // eslint-disable-next-line functional/immutable-data
            transformed[ruleId] = {
                category,
                id: ruleId,
                isValid: true,
            }
            // }
        }
    }

    return transformed
}

/**
 * @returns `true` if every category contains at least one valid rule, else `false`
 * @returns if @onlyCheckIfOneIsTrue `true` if any rule is true
 */
const checkDisplayRules = (
    categorizedRules: Record<DisplayRuleCategory, readonly DisplayRule[]>,
    // option to return true if any rule passes
    onlyCheckIfOneIsTrue = false,
) => {
    if (onlyCheckIfOneIsTrue) {
        return Object.keys(categorizedRules).some(category =>
            categorizedRules[category].some(rule => rule.isValid),
        )
    }
    return Object.keys(categorizedRules).every(category =>
        categorizedRules[category].some(rule => rule.isValid),
    )
}

/**
 * @example
 * ```
 * categorizeRules({ "brand/regence": { isValid: true } }, ["brand/regence", "brand/asuris", "audience/medicare"])
 * => { brand: [{ category: "brand", id: "brand/regence", isValid: true },
 *              { category: "brand", id: "brand/asuris", isValid: false }]
 *      audience: [{ category: "audience", id: "audience/medicare", isValid: false }]}
 * ```
 *
 * @returns Picked display rules from displayRuleById, grouped by category. If a
 * ruleId to pick is not found in displayRuleById,
 * it is categorized based on the string preceding `/` in its id and gets `isValid: false`.
 */
const categorizeRules = (
    displayRuleById: DisplayRuleById,
    displayRulesToPick: readonly string[],
) => {
    // eslint-disable-next-line functional/prefer-readonly-type
    const categorized: Record<DisplayRuleCategory, DisplayRule[]> = {}

    displayRulesToPick.forEach(maybeKnownDisplayRule => {
        const knownDisplayRule = displayRuleById[maybeKnownDisplayRule]

        if (knownDisplayRule) {
            const existingCategory = categorized[knownDisplayRule.category]

            if (existingCategory?.length) {
                existingCategory.push(knownDisplayRule)
            } else {
                // eslint-disable-next-line functional/immutable-data
                categorized[knownDisplayRule.category] = [knownDisplayRule]
            }
        } else {
            const category = maybeKnownDisplayRule.split("/")[0]
            const displayRule = {
                category,
                id: maybeKnownDisplayRule,
                isValid: false,
            }

            const existingCategory = categorized[category]
            if (existingCategory?.length) {
                existingCategory.push(displayRule)
            } else {
                // eslint-disable-next-line functional/immutable-data
                categorized[category] = [displayRule]
            }
        }
    })

    return categorized
}

/**
 * This function is called frequently. It's recommended to benchmark changes to it or its
 * dependencies (`categorizeRules`, `checkDisplayRules`) using a tool like benchmark.js
 *
 * @returns `false` if denyRules evaluate to `true`, `false` if allowRules evaluate to `false`, else `true`
 */
export const filterByDisplayRules = (
    {
        allowRules,
        denyRules,
        oneOfRules,
    }: {
        readonly allowRules?: AllowRules
        readonly denyRules?: DenyRules
        readonly oneOfRules?: OneOfRules
    },
    displayRuleById: DisplayRuleById,
): boolean => {
    if (denyRules?.length) {
        const denyRulesByCategory = categorizeRules(displayRuleById, denyRules)

        // if denyRules exist and evaluate to true, this item should be denied
        if (
            !isEmpty(denyRulesByCategory) &&
            checkDisplayRules(denyRulesByCategory)
        ) {
            return false
        }
    }

    if (oneOfRules?.length) {
        const oneOfRulesByCategory = categorizeRules(
            displayRuleById,
            oneOfRules,
        )
        // if oneOfRules exists and none evaluate to true, return false
        if (
            !isEmpty(oneOfRulesByCategory) &&
            !checkDisplayRules(oneOfRulesByCategory, true)
        ) {
            return false
        }
    }

    // denyRules don't exist or evaluated false, so next check allowRules
    if (allowRules?.length) {
        const allowRulesByCategory = categorizeRules(
            displayRuleById,
            allowRules,
        )

        return checkDisplayRules(allowRulesByCategory)
    }

    // if allowRules, denyRules and oneOfRules all either don't exist or passed, return true
    return true
}

export const DISPLAY_RULES = {
    DIGITAL_FIRST_ELIGIBLE: "account-permissions/digital-first-eligible",

    ANY_COVERAGE_TERMINATED: "member-coverage-state/legacy",

    AUDIENCE_ASO: "claims-funding/aso",
    AUDIENCE_FULLY_INSURED: "claims-funding/fully-funded",

    MEDICAL_COVERAGE_PENDING: "medical-coverage-state/pending",

    DENTAL_COVERAGE_PENDING: "dental-coverage-state/pending",

    VSP_COVERAGE_PENDING: "vsp-coverage-state/pending",
    VSP_COVERAGE_TERMINATED: "vsp-coverage-state/terminated",

    PREMIUM_PAYMENT_ELIGIBLE: "premium-payment/eligible",
    SUBSCRIBER: "member-roles/subscriber",
    BILLING_LEVEL_INDIVIDUAL: "business-segment/individual",

    EOBS_ELIGIBLE: "account-permissions/show-eobs",

    ADVICE_24_ELIGIBLE: "virtual-care-options/advice-24-optum",
    ASK_A_DOCTOR_CIRRUS: "virtual-care-options/ask-a-doctor-cirrus-md",


    TELEHEALTH: "virtual-care-options/generic-telehealth",
    SPECIAL_REGION_WA_MANDATE: "special-region/wa-mandate",
    WA_SUBSTANCE_ABUSE: "special-region/wa-mandate-eobs",
    EXPERT_2ND_OPINION: "virtual-care-options/2nd-md",
    PCP_CHANGE: "account-permissions/pcp-change",

    DME_ELIGIBLE: "dme/eligible",

    UMP: "employer.group/ump",
    ZENITH: "employer.group/zenith",
    
}
