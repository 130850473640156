import * as React from "react"
import { SVGProps } from "react"
const SvgPencilSquare = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Pencil inside a square"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M19.2 14.3942V19.2002C19.2 20.1938 18.3936 21.0002 17.4 21.0002H4.8C3.8064 21.0002 3 20.1938 3 19.2002V6.60017C3 5.60567 3.8064 4.80017 4.8 4.80017H9.606M17.4 3L21 6.6L12 15.6H8.39999V12L17.4 3Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgPencilSquare
