import * as React from "react"
import { SVGProps } from "react"
const SvgPersonNurse = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Nurse" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M16.2001 17.7V19.0043M16.2001 19.0043V20.2M16.2001 19.0043H15.0001M16.2001 19.0043H17.5001M15.1162 5.5706L16.4953 1.99385C16.5145 1.92334 16.4669 1.84253 16.3952 1.82383C14.9901 1.46588 13.5189 1 12.0001 1C10.4812 1 9.00784 1.46588 7.60494 1.82383C7.53322 1.84253 7.48567 1.92334 7.5049 1.99385L9.00006 5.45569M15.6 14L12 17.2C12 17.2 10 15.5 8.30005 14.1C4.80005 15.6 2.30005 19 2.30005 23H21.6C21.7 18.9 19.2 15.5 15.6 14ZM13.6181 4.52227C15.7707 5.41394 16.793 7.88184 15.9013 10.0345C15.0096 12.1871 12.5417 13.2093 10.3891 12.3177C8.23643 11.426 7.21422 8.9581 8.10589 6.80546C8.99756 4.65282 11.4655 3.6306 13.6181 4.52227Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgPersonNurse
