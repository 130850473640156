/**
 * Dental Benefit Additional Material reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import {AdditionalMaterial} from "./types"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface AdditionalMaterials {
    readonly additionalMaterials: ReadonlyArray<AdditionalMaterial>
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<AdditionalMaterials> = {
    status: "pristine",
    additionalMaterials: [],
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const additionalMaterials = createStatefulSlice({
    name: "additionalMaterials",
    initialState,
    reducers: {
        receiveAdditionalMaterials: (
            state: SliceState<AdditionalMaterials>,
            action: PayloadAction<AdditionalMaterials>,
        ) => {
            return {
                status: "completed",
                additionalMaterials: action.payload.additionalMaterials || [],
            }
        },
        resetAdditionalMaterials: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const additionalMaterialsActions = { ...additionalMaterials.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default additionalMaterials.reducer
