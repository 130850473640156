/**
 * Timeline Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import {
    conditionByIdQuery,
    conditionsQuery,
    childrenOfConditionQuery,
} from "./queries"
import { transformConditionsApiData } from "./utils"
import http from "utils/http"
import { Condition } from "@/store/rx/conditions/types"

/**
 * Get Conditions data
 * -----------------------------------------------------------------------------
 */
export const fetchConditions = createAsyncThunk(
    "conditions/fetchConditions", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.updateConditions({ isLoading: true }))

        /**
         * Request conditions data from digital-first-information
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            conditionsQuery,
        )

        /**
         * Handle request errors
         */
        if (res.error) {
            console.error(res.error)
            return dispatch(
                actions.updateConditions({
                    isLoading: false,
                    isInitialized: true,
                    errorMessage: "Could not retrieve conditions data",
                }),
            )
        }

        /**
         * Transform conditions data
         */
        const conditionsFromQuery = res.data.data?.rxConditions.nodes || []
        const items = transformConditionsApiData(conditionsFromQuery)

        /**
         * Update state
         */
        dispatch(
            actions.updateConditions({
                items,
                isLoading: false,
                isInitialized: true,
            }),
        )
    },
)

/**
 * Get Condition Item data
 * -----------------------------------------------------------------------------
 */
export const fetchConditionById = createAsyncThunk(
    "conditions/fetchConditionById",

    async (conditionId: string, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.updateConditions({ isItemLoading: true }))

        /**
         * Request conditions data from digital-first-information-service
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            conditionByIdQuery(conditionId),
        )

        /**
         * Handle request errors
         */
        if (res.error) {
            console.error(res.error)
            return dispatch(
                actions.updateConditions({
                    isItemLoading: false,
                    isItemInitialized: true,
                    errorMessage: "Could not retrieve conditions data",
                }),
            )
        }

        /**
         * Transform conditions data
         */
        const conditionFromQuery = res.data.data?.rxCondition
            ? [res.data.data?.rxCondition]
            : []
        const transformedCondition =
            transformConditionsApiData(conditionFromQuery)[0]

        // eslint-disable-next-line functional/no-let
        let conditionItem: Condition

        // condition may have children, even if it has ancestry, because we may have conditions that are child or child records.
        const subRes = await http.query(
            "/api/digital-first-information-service/graphql",
            childrenOfConditionQuery(conditionId),
        )
        if (subRes?.data?.errors?.length) {
            return dispatch(
                actions.updateConditions({
                    isItemLoading: false,
                    isItemInitialized: true,
                    errorMessage: "Could not retrieve children of conditions data",
                }),
            )
        }

        const childConditions = subRes.data.data?.rxConditionChildren || []
        // Sometimes these are parents but have no children! In that case
        // just display it as if it weren't a parent
        if (childConditions.length === 0) {
            conditionItem = transformedCondition
        } else {
            conditionItem = { ...transformedCondition, childConditions }
        }

        /**
         * Add/Update the Item in the conditions array
         */
        const { conditions } = store.getState()
        const exists = conditions.items.find(item => item.id == conditionId)
        const items = exists
            ? conditions.items.map(item =>
                item.id === conditionId ? conditionItem : item,
            )
            : conditions.items.concat([conditionItem])

        /**
         * Update items state
         */
        dispatch(
            actions.updateConditions({
                items: items,
                isItemLoading: false,
                isItemInitialized: true,
            }),
        )
    },
)
