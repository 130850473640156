import * as React from "react"
import { SVGProps } from "react"
const SvgXCircle = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Close X with circle"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M14.7 9.30017L11.95 12.0002M11.95 12.0002L9.20001 14.7002M11.95 12.0002L14.7 14.7002M11.95 12.0002L9.20001 9.30017M23 12.0002C23 18.0753 18.0751 23.0002 12 23.0002C5.92487 23.0002 1 18.0753 1 12.0002C1 5.92505 5.92487 1.00018 12 1.00018C18.0751 1.00018 23 5.92505 23 12.0002Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgXCircle
