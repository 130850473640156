/**
 * Device utilities
 * -----------------------------------------------------------------------------
 */

/**
 * System Dark Mode
 * -----------------------------------------------------------------------------
 * Does this device prefer dark mode?
 */
export const systemDark = (): boolean => {
    return false

    // TODO: Re-enable if we decide this is a good default
    // return window.matchMedia("(prefers-color-scheme: dark)").matches
}
