import { BenefitDocuments } from "./types"
import { http } from "utils"
import { isCobrowseSessionInProgress } from "@/utils/glance"

export const transformBenefitDocumentsApiData = (
    data: any, // eslint-disable-line
    benefits
): {
    readonly benefitDocuments: ReadonlyArray<BenefitDocuments>
    readonly productId: string
} => {
    const documents = data?.fetchableBenefitDocuments?.map(benefitDocument => {
        return {
            coverageType: benefitDocument.coverageType,
            documentId: benefitDocument.id,
            type: benefitDocument.type,
            url: benefitDocument.url,
            language: benefitDocument.language
        }
    });
    const medicalBenefits = benefits.filter(b => b.category === "M");

    return {
        benefitDocuments: documents || [],
        productId: medicalBenefits?.[0]?.productId,
    }
}

/**
 * Retrieves benefit booklet and opens it
 * - if cobrowsing, opens the PDF in the cobrowse share UI
 */
export const getBenefitBooklet = async (
    e: React.MouseEvent<HTMLElement>,
    url: string,
) => {
    e.preventDefault()

    if (isCobrowseSessionInProgress()) {
        const pdfBlob = await getBenefitBookletData(url)
        // TODO: handle error case (if !pdfBlob)

        const pdfArrayBuffer = await pdfBlob.arrayBuffer()
        globalThis.GLANCE.Cobrowse.Visitor.viewPDF({ data: pdfArrayBuffer })
    } else {
        const bookletWindow = window.open("", "_blank")
        const pdfUrl = await getBenefitBookletUrl(url)
        // TODO: handle error case (if !pdfUrl)

        // eslint-disable-next-line functional/immutable-data
        bookletWindow.location.href = pdfUrl
        bookletWindow.focus()
    }
}

/**
 * Retrieves benefit booklet data
 */
export const getBenefitBookletData = async (
    url: string,
): Promise<Blob> => {
    try {
        const {
            data,
            error
        } = await http.get("/api/janus/member-documents-presentation-service/" + url, {
            responseType: "arraybuffer"
        });
        if (error) {
            console.error("Failed to get booklet document");
            return null
        }
        // ensure that the type is set to application/pdf
        return new Blob([data], { type: "application/pdf" });
    } catch (error) {
        console.error({message: error.message});
        return;
    }
}

export const getBenefitBookletUrl = async (
    url: string,
): Promise<string> => {
    const data = await getBenefitBookletData(url)
    if (!data) {
        return null
    }

    return URL.createObjectURL(data)
}
