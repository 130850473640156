/**
 * Grab Overflow
 * -----------------------------------------------------------------------------
 */
import React from "react"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
interface Props extends React.HTMLAttributes<HTMLDivElement> {
    readonly children: React.ReactNode
    readonly enableScrollbar?: boolean
    readonly direction?: "vertical" | "horizontal"
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const Grab: React.FC<Props> = props => {
    /**
     * Ref
     */
    const ref = React.useRef<HTMLDivElement>(null)

    /**
     * Component state
     * Note: React.useState doesn't update fast enough for grabbing
     */
    let pos = { top: 0, left: 0, x: 0, y: 0 } // eslint-disable-line

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        const setCursor = () => {
            // eslint-disable-next-line
            ref.current.style.cursor = isOverflowing() ? "grab" : "default"
        }
        setCursor()
        window.addEventListener("resize", setCursor)
        return () => window.removeEventListener("resize", setCursor)
    }, [])

    /**
     * Methods
     */
    const mouseDownHandler = function (e) {
        if (isOverflowing()) {
            ref.current.style.cursor = "grabbing" // eslint-disable-line
            ref.current.style.userSelect = "none" // eslint-disable-line
            pos = {
                left: ref.current.scrollLeft,
                top: ref.current.scrollTop,
                x: e.clientX,
                y: e.clientY,
            }
            document.addEventListener("mousemove", mouseMoveHandler)
            document.addEventListener("mouseup", mouseUpHandler)
        }
    }
    const mouseMoveHandler = function (e) {
        const dx = e.clientX - pos.x
        const dy = e.clientY - pos.y
        ref.current.scrollTop = pos.top - dy // eslint-disable-line
        ref.current.scrollLeft = pos.left - dx // eslint-disable-line
    }
    const mouseUpHandler = function () {
        document.removeEventListener("mousemove", mouseMoveHandler)
        document.removeEventListener("mouseup", mouseUpHandler)
        ref.current.style.cursor = "grab" // eslint-disable-line
        ref.current.style.removeProperty("user-select")
    }
    const isOverflowing = () => {
        return (
            ref.current.scrollHeight > ref.current.clientHeight ||
            ref.current.scrollWidth > ref.current.clientWidth
        )
    }

    /**
     * Define template variables
     */
    const overflowClass =
        props.direction === "vertical"
            ? "overflow-x-hidden overflow-y-auto"
            : "overflow-y-hidden overflow-x-auto"
    const scrollbarClass = props.enableScrollbar ? "" : "hide-scrollbar"
    const propsClasses = props.className || ""

    /**
     * Template
     */
    return (
        <div
            ref={ref}
            className={`w-full max-w-full ${overflowClass} ${scrollbarClass} ${propsClasses}`}
            onMouseDown={mouseDownHandler}
        >
            {props.children}
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default Grab
