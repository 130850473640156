/**
 * TaxForm reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"
import { Eob, EobFaq } from "../eob-statements/types"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Eobs {
    readonly eobStatement: ReadonlyArray<Eob>
    readonly eobFaqs: EobFaqs
    readonly eobPartialContent: boolean
}
export interface EobFaqs {
    readonly eobFaq: ReadonlyArray<EobFaq>
}
/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<Eobs> = {
    status: "pristine",
    eobStatement: [],
    eobPartialContent: false,
    eobFaqs: { eobFaq: [] },
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const eobStatement = createStatefulSlice({
    name: "eobStatement",
    initialState,
    reducers: {
        receiveEobStatements: (
            state: SliceState<Eobs>,
            action: PayloadAction<Eobs>,
        ) => {
            return {
                ...state,
                status: "completed",
                eobStatement: action.payload.eobStatement || [],
                eobPartialContent: action.payload.eobPartialContent,
            }
        },
        receiveEobFaq: (
            state: SliceState<Eobs>,
            action: PayloadAction<Eobs>,
        ) => {
            return {
                ...state,
                status: "completed",
                eobFaqs: action.payload.eobFaqs || { eobFaq: [] },
            }
        },
        resetEobs: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const eobsActions = { ...eobStatement.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default eobStatement.reducer
