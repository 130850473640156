import * as React from "react"
import { SVGProps } from "react"
const SvgPrinter = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Printer" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M6 4.00018V3.00018C5.44772 3.00018 5 3.4479 5 4.00018H6ZM18 4.00018H19C19 3.4479 18.5523 3.00018 18 3.00018V4.00018ZM18 16.0002C17.4477 16.0002 17 16.4479 17 17.0002C17 17.5525 17.4477 18.0002 18 18.0002V16.0002ZM21 17.0002V18.0002C21.5523 18.0002 22 17.5525 22 17.0002H21ZM21 7.00018H22C22 6.4479 21.5523 6.00018 21 6.00018V7.00018ZM3 7.00018V6.00018C2.44772 6.00018 2 6.4479 2 7.00018H3ZM3 17.0002H2C2 17.5525 2.44772 18.0002 3 18.0002V17.0002ZM6 18.0002C6.55228 18.0002 7 17.5525 7 17.0002C7 16.4479 6.55228 16.0002 6 16.0002V18.0002ZM18 14.0002H19V13.0002H18V14.0002ZM6 14.0002V13.0002H5V14.0002H6ZM6 5.00018H18V3.00018H6V5.00018ZM17 4.00018V7.00018H19V4.00018H17ZM18 6.00018H6V8.00018H18V6.00018ZM7 7.00018V4.00018H5V7.00018H7ZM18 18.0002H21V16.0002H18V18.0002ZM22 17.0002V7.00018H20V17.0002H22ZM21 6.00018H18V8.00018H21V6.00018ZM6 6.00018H3V8.00018H6V6.00018ZM2 7.00018V17.0002H4V7.00018H2ZM3 18.0002H6V16.0002H3V18.0002ZM7.2 21.0002H16.8V19.0002H7.2V21.0002ZM16.8 21.0002C17.3339 21.0002 17.8728 20.8474 18.2982 20.4971C18.7391 20.134 19 19.6019 19 19.0002H17C17 19.0087 16.9989 18.999 17.0074 18.981C17.0112 18.973 17.0156 18.9662 17.0198 18.9609C17.0218 18.9584 17.0235 18.9564 17.0248 18.9551C17.0261 18.9538 17.0268 18.9532 17.0268 18.9533C17.0268 18.9533 17.0234 18.956 17.0153 18.9604C17.0072 18.9647 16.9943 18.9706 16.9758 18.9766C16.9385 18.9886 16.8805 19.0002 16.8 19.0002V21.0002ZM19 19.0002V14.0002H17V19.0002H19ZM18 13.0002H6V15.0002H18V13.0002ZM5 14.0002V19.0002H7V14.0002H5ZM5 19.0002C5 19.6019 5.2609 20.134 5.70179 20.4971C6.12718 20.8474 6.66605 21.0002 7.2 21.0002V19.0002C7.11955 19.0002 7.0615 18.9886 7.02419 18.9766C7.0057 18.9706 6.99283 18.9647 6.98469 18.9604C6.97659 18.956 6.97322 18.9533 6.97321 18.9533C6.97319 18.9532 6.97391 18.9538 6.97519 18.9551C6.97648 18.9564 6.97821 18.9584 6.98022 18.9609C6.98441 18.9662 6.9888 18.973 6.99256 18.981C7.00113 18.999 7 19.0087 7 19.0002H5Z"
            fill="currentColor"
        />
        <path
            d="M17.1276 10.2562C17.0571 10.2562 17 10.1989 17 10.1282C17 10.0575 17.0571 10.0002 17.1276 10.0002"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <path
            d="M17.1276 10.0002C17.198 10.0002 17.2551 10.0575 17.2551 10.1282C17.2551 10.1989 17.198 10.2562 17.1276 10.2562"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgPrinter
