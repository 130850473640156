import * as React from "react"
import { SVGProps } from "react"
const SvgClipboard = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Clipboard"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M9.79999 3.2C9.79999 2 10.8 1 12 1C13.2 1 14.2 2 14.2 3.2M8 6H16M17.5 23H6.5C5.1 23 4 21.8 4 20.5V5C4 4.44772 4.44772 4 5 4H19C19.5523 4 20 4.44772 20 5V20.5C20 21.8 18.9 23 17.5 23Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgClipboard
