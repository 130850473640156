import * as React from "react"
import { SVGProps } from "react"
const SvgDocumentMoney = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Paper with dollar sign inside"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M15 1V5C15 6.10457 15.8954 7 17 7H21M10 16.9658C11.4795 18.6302 14 17.7672 14 16.1644C14 14.9315 12.9041 14.5 12.1918 14.5H11.8082C11.0959 14.5 10 14.0685 10 12.8356C10 11.1713 12.5205 10.3699 14 12.0343M12.1918 19V18.1986M12.1918 10.863V10M4.8 1H14.8759C15.3438 1 15.7968 1.16403 16.1563 1.46356L20.2804 4.90031C20.7364 5.2803 21 5.84319 21 6.43675V20.8C21 22.01 20.19 23 19.2 23H4.8C3.81 23 3 22.01 3 20.8V3.2C3 1.99 3.81 1 4.8 1Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgDocumentMoney
