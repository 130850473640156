import React from "react"
import { useIdleTimer } from "react-idle-timer"
import Routes, { useRouter } from "router"
import { Button, Popup } from "elements"
import { actions, useDispatch, useSelector } from "store"
import { IFRAME_ACTIVITY_EVENT_TYPE } from "@/elements/iframe"
import { tagEvent } from "@/utils/analytics"
import { cleanupSessionStorage, cleanupLocalStorage } from "@/utils/window-storage"
import { ACCOUNT_SETTINGS_SMS_IN_PROGRESS_KEY } from "@/store/account-settings/utils"

export const DEFAULT_ACTIVITY_TIMEOUT_MS = 29 * 60 * 1000 // 29 minutes in ms
export const DEFAULT_POPUP_COUNTDOWN_MS = 60 * 1000 // 1 minute in ms
export const DEFAULT_REFRESH_INTERVAL_MS = 25 * 60 * 1000 // 25 minute in ms

interface Props {
    readonly activityTimeoutMs?: number // time in ms
    readonly popupCountdownMs?: number // time in ms
    readonly refreshIntervalMs?: number // time in ms
}

/**
 * TimeoutPopup
 *
 * Tracks whether or not the user is actively using the app.
 *
 * After `activityTimeoutMs` (default 29 minutes) of inactivity,
 * prompts the user to choose whether to sign out or stay signed in.
 *
 * The user has `popupCountdownMs` (default 1 minute) to choose before
 * being automatically signed out.
 */
const TimeoutPopup: React.FC<Props> = ({
    activityTimeoutMs = DEFAULT_ACTIVITY_TIMEOUT_MS,
    popupCountdownMs = DEFAULT_POPUP_COUNTDOWN_MS,
    refreshIntervalMs = DEFAULT_REFRESH_INTERVAL_MS,
}) => {
    /**
     * Application state
     */
    const { profile } = useSelector(state => state.account)
    const [popupIsVisible, setPopupIsVisible] = React.useState(false)
    const [isSigningOut, setIsSigningOut] = React.useState(false)
    const router = useRouter()
    const dispatch = useDispatch()

    const showPopup = () => {
        setPopupIsVisible(true)
    }

    const dismissPopup = () => {
        setPopupIsVisible(false)
    }

    const handleOnIdle = () => {

        if (!popupIsVisible) {
            showPopup()
        }
    }

    const doSignout = async () => {
        /**
         * Sign out tealium event
         */
        tagEvent({
            tealium_event: "signed_out",
            page_title: document.title,
            relationship: profile.relationship,
        })

        /**
         * Check if the user was in the middle of activating SMS phone number, so we can cancel it
         */
        if (window.localStorage.getItem(ACCOUNT_SETTINGS_SMS_IN_PROGRESS_KEY) === "true") {
            const response = await dispatch(actions.cancelSMSActivation())
            if (response) {
                /**
                 * Cleanup window storages required before sign-out.
                 */
                window.localStorage.setItem(ACCOUNT_SETTINGS_SMS_IN_PROGRESS_KEY, "false")
                cleanupSessionStorage()
                cleanupLocalStorage()
            }
        } else {
            /**
             * Cleanup window storages required before sign-out.
             */
            cleanupSessionStorage()
            cleanupLocalStorage()
        }

        // update button state, show loader
        setIsSigningOut(true)

        // signout
        router.push(Routes.signout())
    }

    const { reset: restartIdleTimer } = useIdleTimer({
        timeout: activityTimeoutMs,
        onIdle: handleOnIdle,
        debounce: 250,
    })

    const countdown = React.useRef(null)
    const refreshInterval = React.useRef(null)

    const startCountdown = () => {
        // eslint-disable-next-line functional/immutable-data
        countdown.current = setTimeout(() => {
            // signout
            doSignout()
        }, popupCountdownMs)
    }

    const stopCountdown = () => {
        clearTimeout(countdown.current)
        // eslint-disable-next-line functional/immutable-data
        countdown.current = null
    }

    /**
     * Refresh user session after interval of
     * DEFAULT_REFRESH_INTERVAL_MS = 25 * 60 * 1000 (25 minutes in ms)
     */
    const startRefreshTimer = () => {
        // dispatch session refresh when refresh timer restarts
        dispatch(actions.refreshSession())
        // eslint-disable-next-line functional/immutable-data
        refreshInterval.current = setInterval(() => {
            // dispatch session refresh
            dispatch(actions.refreshSession())
        }, refreshIntervalMs)
    }

    const stopRefreshTimer = () => {
        clearInterval(refreshInterval.current)
        // eslint-disable-next-line functional/immutable-data
        refreshInterval.current = null
    }

    /**
     * Lifecycle
     *
     * Anytime `popupIsVisible` changes, set the signout countdown accordingly.
     */
    React.useEffect(() => {
        // popup was dismissed but countdown is still running
        if (!popupIsVisible && countdown?.current) {
            stopCountdown()

            // popup is visible but countdown has not been started
        } else if (popupIsVisible && !countdown?.current) {
            startCountdown()
        }

        return () => {
            // component is unmounting but countdown is still running
            // cleanup to avoid memory leak
            if (countdown?.current) {
                stopCountdown()
            }
        }
    }, [popupIsVisible])

    /**
     * Restart idle timer when a user interacts with iframed content.
     *
     * Iframes create a separate document context,
     * so the idle timer will not automatically restart when a user interacts with iframed content.
     *
     * To solve this, the ADIF Iframe Element dispatches a custom `IFRAME_ACTIVITY_EVENT_TYPE` event
     * that we listen for here. When an event is received, we manually restart the idle timer.
     */
    const iframeActivityEventListener: EventListenerOrEventListenerObject = _ => {
        restartIdleTimer()
    }

    /**
     * Lifecycle
     *
     * On first mount, attach the iframe event listener and set the session refresh interval.
     */
    React.useEffect(() => {
        document.addEventListener(
            IFRAME_ACTIVITY_EVENT_TYPE,
            iframeActivityEventListener,
        )

        if (!refreshInterval?.current) {
            startRefreshTimer()
        }

        return () => {
            document.removeEventListener(
                IFRAME_ACTIVITY_EVENT_TYPE,
                iframeActivityEventListener,
            )

            if (refreshInterval?.current) {
                stopRefreshTimer()
            }
        }
    }, [])

    React.useEffect(() => {
        restartIdleTimer()
    }, [activityTimeoutMs])

    /**
     *
     * Button click handlers
     */
    const handleOnSignoutClick = _event => {
        // clear timer
        stopCountdown()

        // signout
        doSignout()
    }

    const handleOnStaySignedInClick = _event => {
        // clear timer
        stopCountdown()

        // dispatch session refresh
        dispatch(actions.refreshSession())

        dismissPopup()
    }

    return (
        <Popup name="timeout-popup" isVisible={popupIsVisible} asOverlay={true}>
            <div className="flex flex-col">
                <h5 className="pb-sm m-none">Are you still working?</h5>
                <p className="pb-sm m-none">
                    For your security, we will sign you out shortly.
                </p>
                <div className="flex justify-center space-x-sm">
                    <Button
                        name="timeout-popup-sign-out"
                        variant="link"
                        size="small"
                        label="Sign out"
                        tabIndex={2}
                        onClick={handleOnSignoutClick}
                        loading={isSigningOut}
                        disabled={isSigningOut}
                    />
                    <Button
                        name="timeout-popup-stay-signed-in"
                        variant="primary"
                        size="small"
                        label="Stay signed in"
                        tabIndex={1}
                        onClick={handleOnStaySignedInClick}
                        disabled={isSigningOut}
                    />
                </div>
            </div>
        </Popup>
    )
}

export default TimeoutPopup
