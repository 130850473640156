import * as React from "react"
import { SVGProps } from "react"
const SvgHeartPulse = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Heart with pulse graphic inside"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M5.49999 9.79904H8.16547C8.23257 9.79902 8.29837 9.8171 8.3555 9.85126C8.41263 9.88543 8.45886 9.93433 8.48902 9.99251L10.1964 13.3067C10.2275 13.367 10.2759 13.4173 10.3358 13.4515C10.3957 13.4857 10.4645 13.5025 10.5339 13.4997C10.6034 13.497 10.6705 13.4748 10.7273 13.4359C10.7841 13.397 10.8281 13.3431 10.8541 13.2805L13.1459 7.71949C13.1719 7.65693 13.2159 7.60298 13.2727 7.5641C13.3295 7.52522 13.3966 7.50307 13.4661 7.50031C13.5355 7.49754 13.6043 7.51428 13.6642 7.54852C13.724 7.58276 13.7725 7.63304 13.8036 7.69333L14.7926 9.60557C14.8227 9.66375 14.869 9.71266 14.9261 9.74682C14.9832 9.78098 15.049 9.79906 15.1161 9.79904H18.5M21.9414 5.28139C21.5606 4.50363 21.0094 3.8306 20.3327 3.31695C19.656 2.8033 18.8727 2.46352 18.0466 2.32521C17.2205 2.18689 16.3748 2.25394 15.5781 2.5209C14.7815 2.78786 14.0563 3.24722 13.4617 3.86166L12 5.25411L10.5383 3.86166C9.94346 3.24733 9.21818 2.78814 8.42139 2.5214C7.62461 2.25465 6.77879 2.18787 5.95264 2.32647C5.12649 2.46507 4.34331 2.80516 3.6667 3.3191C2.99009 3.83304 2.43913 4.50634 2.05859 5.28432C1.56184 6.31178 1.39026 7.47733 1.56856 8.6131C1.74687 9.74887 2.26585 10.7963 3.05077 11.6044L12 21.2551L20.9492 11.6015C21.7342 10.7933 22.2531 9.74595 22.4314 8.61017C22.6097 7.4744 22.4382 6.30886 21.9414 5.28139Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
export default SvgHeartPulse
