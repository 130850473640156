/**
 * Medications Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import {
    medicationsQuery,
    rxTreatmentsByIdQuery,
    // medicationStatusMutation,
} from "./queries"
//import { timelineQuery } from "@/store/timeline/queries"
import { transformMedicationsApiData, unifyMedicationData } from "./utils"
import http from "utils/http"

/**
 * Get medications data
 * -----------------------------------------------------------------------------
 */
export const fetchMedications = createAsyncThunk(
    "medications/fetchMedications", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        const { medications } = store.getState()
        dispatch(
            actions.receiveMedications({ ...medications, isLoading: true }),
        )

        /**
         * Request medications data from CTPS via DFIS
         */
        const res = await fetchCareSubjects({ dispatch })

        if (res?.payload?.error) {
            return dispatch(
                actions.receiveMedications({
                    ...medications,
                    isLoading: false,
                    isInitialized: true,
                    isError: true,
                    errorMessage:
                        "Error occured while fetching data",
                }),
            )
        }

        const careSubjects = res?.data?.data?.careSubjects || {}
        if (!careSubjects?.edges?.length) {
            return dispatch(
                actions.receiveMedications({
                    ...medications,
                    isLoading: false,
                    isInitialized: true,
                    errorMessage:
                        "Could not retrieve medications - no careSubjects found",
                }),
            )
        }

        const transformMedications = transformMedicationsApiData(careSubjects)

        const medicationTreatmentIds = transformMedications.map(
            item => item.medicationTreatmentId,
        ).filter(id => id)

        /**
         * Request treatments from Medsavvy
         */

        if (medicationTreatmentIds.length) {
            const results = await http.query(
                "/api/janus/digital-first-information-service/graphql",
                rxTreatmentsByIdQuery(medicationTreatmentIds),
            )

            /**
             * Handle request errors
             */
            if (results.error) {
                console.error(results.error)
                return dispatch(
                    actions.receiveMedications({
                        ...medications,
                        isLoading: false,
                        isInitialized: true,
                        isError: true,
                        errorMessage:
                            "Could not retrieve medications details from MedSavvy",
                    }),
                )
            }

            /**
             * Unify medication data
             */
            const familyMembers = res?.data?.data?.careSubjects?.familyMembers
            const rxTreatments = results?.data?.data
            const items = unifyMedicationData(transformMedications, rxTreatments)

            /**
             * Update state
             */
            dispatch(
                actions.receiveMedications({
                    ...medications,
                    familyMembers,
                    items,
                    isLoading: false,
                    isInitialized: true,
                }),
            )
        } else {
            return dispatch(
                actions.receiveMedications({
                    ...medications,
                    isLoading: false,
                    isInitialized: true,
                    errorMessage:
                        "Could not retrieve medications - no medicationTreatmentIds found",
                }),
            )
        }
    },
)

/**
 * Request medications data from DFIS
 */
async function fetchCareSubjects({ dispatch }) {
    const res = await http.query(
        "/api/janus/digital-first-information-service/graphql",
        medicationsQuery,
        //timelineQuery,
    )

    /**
     * Handle request errors
     */
    if (res.error) {
        console.error(res.error)
        return dispatch(
            actions.receiveMedications({
                ...res,
                isLoading: false,
                isInitialized: true,
                errorMessage: "Could not retrieve medications data from DFIS",
            }),
        )
    }

    return res
}


// This is on hold for feature parity with app

/**
 * Update Medication Status
 * -----------------------------------------------------------------------------
 */

// export const updateMedicationStatus = createAsyncThunk(
//     "medications/updateMedicationStatus", // Reducer name
//     async (params: ReadonlyArray<string>, { dispatch }) => {
//         const [itemId, status] = params
//         const { medications } = store.getState()
//         const item = medications.items.find(item => item.id === itemId)
//         const mutationVariables = {
//             input: { notepadId: item.notepadId, value: status },
//         }

//         /**
//          * Send mutation to digital-first-information
//          */
//         const res = await http.post("/api/janus/digital-first-information-service/graphql", {
//             query: medicationStatusMutation,
//             variables: mutationVariables,
//         })

//         /**
//          * Handle request errors
//          */
//         if (res.error) {
//             console.error(res.error)
//             return dispatch(
//                 actions.receiveMedications({
//                     ...medications,
//                     isLoading: false,
//                     isInitialized: true,
//                     errorMessage: "Could not update medication status on CAS",
//                 }),
//             )
//          }

//          /**
//           * Update state
//           */
//          const medicationStatus = res.data.data.setMedicationStatus

//          const updatedItem = {
//              ...item,
//              notepadId: medicationStatus.notepadId,
//              status: {
//                  ...item.status,
//                  value: medicationStatus.value,
//                  notepadId: medicationStatus.notepadId,
//              },
//          }

//          const items = medications.items.map(item =>
//              item.id === updatedItem.id ? updatedItem : item,
//          )

//          dispatch(
//              actions.receiveMedications({
//                  ...medications,
//                  items,
//                  isLoading: false,
//                  isInitialized: true,
//              }),
//          )
//      },
// )
