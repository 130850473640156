import * as React from "react"
import { SVGProps } from "react"
const SvgInfoCircleSm = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Information sign"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M11.9968 14.6159C11.9968 13.7263 11.9937 12.8366 11.9971 11.9471M18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12Z"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M11.9975 9.49517C12.0539 9.49517 12.0996 9.54101 12.0996 9.59756C12.0996 9.65411 12.0539 9.69995 11.9975 9.69995"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
        />
        <path
            d="M11.9975 9.6999C11.9412 9.6999 11.8955 9.65406 11.8955 9.59751C11.8955 9.54096 11.9412 9.49512 11.9975 9.49512"
            stroke="currentColor"
            strokeWidth={1.5}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgInfoCircleSm
