/**
 * Claims reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"
import * as actions from "./actions"
import { Claim } from "./types"
import { format } from "date-fns"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface NativeClaims {
    // Claims props
    readonly items?: ReadonlyArray<Claim>
    // A flag indicating if store is initialized with all claim headers and lines
    readonly isFullyInitialized?: boolean
    readonly claimFilters?: ClaimFilters
    readonly claimFilterData?: ClaimFilterData
}

interface ClaimFilters {
    // Search by claim number
    readonly claimNumber: string
    // Sort by date/provider
    readonly sortBy: string
    // Family member
    readonly members: ReadonlyArray<string>
    // Datepicker
    readonly startRange: string
    readonly endRange: string
    // Categories
    readonly medical: boolean
    readonly pharmacy: boolean
    readonly dental: boolean
    // Providers
    readonly providers: ReadonlyArray<string>
    // Status
    readonly completed: boolean
    readonly inProcess: boolean
    readonly pending: boolean
    readonly submitted: boolean
    readonly adjusted: boolean
    // Accordion opened states
    readonly sortByOpened: boolean
    readonly memberOpened: boolean
    readonly dateOpened: boolean
    readonly categoryOpened: boolean
    readonly providerOpened: boolean
    readonly statusOpened: boolean
}

interface ClaimFilterData {
    readonly memberFilterValues?: ClaimFilterValue
    readonly providerFilterValues?: ClaimFilterValue
    readonly claimTypeFilterValues?: ClaimFilterValue
    readonly claimStatusFilterValues?: ClaimFilterValue
}

interface ClaimFilterValue {
    readonly fieldName: string
    readonly fieldDisplayName: string
    readonly filterValues: ReadonlyArray<{
        readonly filterValue: string
        readonly filterDisplayName: string
    }>
}

/**
 * Initial states
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<NativeClaims> = {
    status: "pristine",
    items: [],
    claimFilters: {
        claimNumber: "",
        sortBy: "date",
        members: [],
        startRange: "",
        endRange: format(new Date(), "MM/dd/yyyy"),
        medical: false,
        pharmacy: false,
        dental: false,
        providers: [],
        completed: false,
        inProcess: false,
        pending: false,
        submitted: false,
        adjusted: false,
        sortByOpened: true,
        memberOpened: false,
        dateOpened: false,
        categoryOpened: false,
        providerOpened: false,
        statusOpened: false,
    },
    claimFilterData: {
        memberFilterValues: {
            fieldName: "",
            fieldDisplayName: "",
            filterValues: [],
        },
        providerFilterValues: {
            fieldName: "",
            fieldDisplayName: "",
            filterValues: [],
        },
        claimTypeFilterValues: {
            fieldName: "",
            fieldDisplayName: "",
            filterValues: [],
        },
        claimStatusFilterValues: {
            fieldName: "",
            fieldDisplayName: "",
            filterValues: [],
        },
    },
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const nativeClaims = createStatefulSlice({
    name: "nativeClaims",
    initialState,
    reducers: {
        receiveNativeClaims: (
            state: SliceState<NativeClaims>,
            action: PayloadAction<NativeClaims>,
        ) => {
            return {
                ...state,
                status: "completed",
                items: action.payload.items || [],
                claimFilterData: action.payload.claimFilterData || {},
                isFullyInitialized: action.payload.isFullyInitialized || false,
            }
        },
        resetNativeClaims: () => {
            return initialState
        },
        receiveNativeClaimFilters: (
            state: SliceState<NativeClaims>,
            action: PayloadAction<ClaimFilters>,
        ) => {
            return {
                ...state,
                claimFilters: action.payload,
            }
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        resetNativeClaimFilters: (
            state: SliceState<NativeClaims>,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            action: PayloadAction<ClaimFilters>,
        ) => {
            return {
                ...state,
                claimFilters: {
                    claimNumber: "",
                    sortBy: "date",
                    members: [],
                    startRange: "",
                    endRange: format(new Date(), "MM/dd/yyyy"),
                    medical: false,
                    pharmacy: false,
                    dental: false,
                    providers: [],
                    completed: false,
                    inProcess: false,
                    pending: false,
                    submitted: false,
                    adjusted: false,
                    sortByOpened: true,
                    memberOpened: false,
                    dateOpened: false,
                    categoryOpened: false,
                    providerOpened: false,
                    statusOpened: false,
                },
            }
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const nativeClaimsActions = { ...nativeClaims.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default nativeClaims.reducer
