import * as React from "react"
import { SVGProps } from "react"
const SvgBeaker = (props: SVGProps<SVGSVGElement>) => (
    <svg
        role="img"
        aria-label="Laboratory beaker"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M6.49997 10.9L9.99997 11M4.49997 16H19.5M5.79997 22C3.79997 22 2.39997 19.8 3.19997 17.9L8.49997 5.8V3C8.49997 2.44771 8.94769 2 9.49997 2H14.4C14.9523 2 15.4 2.44772 15.4 3V5.9L20.8 18C21.6 19.9 20.3 22 18.2 22H5.79997Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgBeaker
