import * as React from "react"
import { SVGProps } from "react"
const SvgLungs = (props: SVGProps<SVGSVGElement>) => (
    <svg role="img" aria-label="Lungs" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M12 2C12 2 12 8.28561 12 10.2856M12 10.2856C12 12.2855 10.3235 12.2855 10.3235 12.2855M12 10.2856C12 12.2855 13.6765 12.2855 13.6765 12.2855M6.70499 5.99994C7.38049 5.99994 7.82794 6.22799 8.14899 6.56593C8.48618 6.92084 8.72206 7.43865 8.87674 8.0695C9.14453 9.16188 9.13229 10.4144 9.12318 11.3513C9.12162 11.5097 9.12016 11.6592 9.12016 11.7973C9.12016 12.2203 9.13844 12.7233 9.15789 13.2594C9.16571 13.4748 9.17376 13.6957 9.18085 13.9188C9.206 14.7087 9.22081 15.5497 9.18264 16.369C9.14436 17.1904 9.05355 17.9704 8.87484 18.6413C8.69518 19.3157 8.43762 19.8382 8.09478 20.1878C7.29158 21.0069 6.05854 21.6423 4.81421 21.889C3.55736 22.1383 2.41891 21.9693 1.70899 21.366C1.69486 21.354 1.64674 21.3019 1.59093 21.1594C1.53677 21.021 1.48796 20.8297 1.45132 20.5812C1.37801 20.0841 1.36255 19.4228 1.4078 18.6406C1.49807 17.0803 1.82487 15.12 2.33706 13.1986C2.85023 11.2736 3.53986 9.42405 4.33954 8.0695C4.7396 7.39186 5.15319 6.86215 5.56594 6.50747C5.97689 6.15433 6.35583 5.99994 6.70499 5.99994ZM17.295 5.99983C16.6195 5.99983 16.1721 6.22783 15.851 6.56576C15.5138 6.92073 15.278 7.43849 15.1233 8.06934C14.8555 9.16178 14.8677 10.4143 14.8768 11.3511C14.8784 11.5096 14.8798 11.6591 14.8798 11.7972C14.8798 12.2202 14.8616 12.7231 14.8421 13.2593C14.8343 13.4747 14.8263 13.6955 14.8192 13.9187C14.794 14.7085 14.7792 15.5496 14.8174 16.3689C14.8556 17.1902 14.9465 17.9703 15.1252 18.6411C15.3048 19.3156 15.5624 19.8381 15.9052 20.1877C16.7084 21.0067 17.9415 21.6421 19.1858 21.8889C20.4427 22.1381 21.5811 21.9692 22.291 21.3659C22.3052 21.3539 22.3533 21.3018 22.4091 21.1592C22.4633 21.0208 22.512 20.8295 22.5487 20.5811C22.622 20.0839 22.6374 19.4226 22.5922 18.6405C22.5019 17.0802 22.1751 15.1199 21.663 13.1985C21.1498 11.2735 20.4601 9.42395 19.6605 8.06934C19.2604 7.39169 18.8468 6.86205 18.4341 6.50737C18.0231 6.15417 17.6442 5.99983 17.295 5.99983Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
    </svg>
)
export default SvgLungs
