/**
 * Search-starts-with Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import { rxSearchStartsWithQuery } from "./queries"
import http from "utils/http"
import { transformSearchData, generateUID } from "./utils"

export interface MedicationSearchProps {
    readonly searchTerm?: string
    readonly selected?: boolean
}

/**
 * Get search-starts-with data
 * -----------------------------------------------------------------------------
 */
export const fetchSearchStartsWithResults = createAsyncThunk(
    "search/fetchSearchStartsWithResults", // Reducer name

    async (props: MedicationSearchProps, { dispatch }) => {
        /**
         * Set loading state
         */
        const { searchStartsWithResults } = store.getState()

        // Variable to identify the order of search queries to only show newer than most recent query results UUID
        const thisUID = generateUID()

        if (props.searchTerm) {
            const aliveQueryStringIDs = [...searchStartsWithResults.aliveQueryStringIDs]
            aliveQueryStringIDs.push(thisUID)
            if (aliveQueryStringIDs.length > 5) {
                aliveQueryStringIDs.shift()
            }

            dispatch(
                actions.receiveSearchStartsWithResults({
                    ...searchStartsWithResults,
                    aliveQueryStringIDs,
                    isLoading: true,
                }),
            )
        } else {
            // Empty Search String
            return
        }

        /**
         * Request data from digital-first-information-service
         */
        const query = rxSearchStartsWithQuery(props.searchTerm)

        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            query,
        )

        /**
         *  Trim recent search strings up to this latest search string (props.searchTerm)
         */
        const trimSearchStrings = (
            thisUID: string,
            aliveQueryStringIDs: ReadonlyArray<string>,
        ) => {
            const thisIndex = aliveQueryStringIDs.findIndex(
                element => element === thisUID,
            )
            return aliveQueryStringIDs.slice(thisIndex + 1)
        }

        const { searchStartsWithResults: currentSearchResults } = store.getState()

        if (
            currentSearchResults.aliveQueryStringIDs.find(
                element => element === thisUID,
            )
        ) {
            // is in the alive query string list, update the search results based on it's return value",
            dispatch(
                actions.receiveSearchStartsWithResults({
                    ...currentSearchResults,
                    aliveQueryStringIDs: trimSearchStrings(
                        thisUID,
                        currentSearchResults.aliveQueryStringIDs,
                    ),
                }),
            )
        } else {
            // is NOT in the alive query string list, discard it's return value",
            // Search string is outdated (has been trimmed), do nothing
            return dispatch(actions.receiveSearchStartsWithResults({ isLoading: false }))
        }

        const { searchStartsWithResults: refreshedSearchResults } = store.getState()
        /**
         * Handle request errors
         */
        if (res.error) {
            console.error(res.error)
            return dispatch(
                actions.receiveSearchStartsWithResults({
                    ...refreshedSearchResults,
                    isLoading: false,
                    isInitialized: true,
                    errorMessage: "Could not retrieve search data",
                }),
            )
        }

        /**
         * Transform Search Results
         */
        const data = res.data?.data?.rxSuggest[0]

        const item = transformSearchData(data)

        /**
         * Update state
         */
        dispatch(
            actions.receiveSearchStartsWithResults({
                ...refreshedSearchResults,
                item: item?.searchStartsWithResults,
                isLoading: false,
                isInitialized: true,
                errorMessage: undefined,
            }),
        )
    },
)
