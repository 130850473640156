/**
 * Conditions reducer
 * -----------------------------------------------------------------------------
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { Pharmacy } from "./types"


/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Pharmacies {
    // Base props
    readonly isInitialized: boolean
    readonly isLoading: boolean
    readonly isItemInitialized: boolean
    readonly isItemLoading: boolean
    readonly errorMessage?: string

    // Resource props
    readonly items: ReadonlyArray<Pharmacy>
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: Pharmacies = {
    isInitialized: false,
    isLoading: false,
    isItemInitialized: false,
    isItemLoading: false,
    errorMessage: undefined,
    items: [],
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const pharmacies = createSlice({
    name: "pharmacies",
    initialState,
    reducers: {
        updatePharmacies: (
            state: Pharmacies,
            action: PayloadAction<Pharmacies>,
        ) => {
            return {
                ...state,
                ...action.payload,
            }
        },
        resetCoverages: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const pharmaciesActions = { ...pharmacies.actions, ...actions }

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default pharmacies.reducer
