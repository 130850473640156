/**
 * PremiumPay Banner Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions, store } from "store"
import { http } from "utils"
import { premiumPayRegenceQuery } from "./queries"
import { transformPremiumPayApiData } from "./utils"

/**
 * Get PremiumPay Banner data
 * -----------------------------------------------------------------------------
 */
export const fetchPremiumPayBanner = createAsyncThunk(
    "banner/fetchPremiumPayBanner", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        const { premiumPayInfo } = store.getState()
        dispatch(
            actions.receivePremiumPayInfo({
                ...premiumPayInfo,
                isLoading: true,
            }),
        )

        /**
         * Fetch Regence user data
         */
        const res = await http.query(
            "/api/adif/user-model",
            premiumPayRegenceQuery,
        )
        if (res.error) {
            console.error(res.error)
            dispatch(
                actions.receivePremiumPayInfo({
                    ...premiumPayInfo,
                    isLoading: false,
                    isInitialized: true,
                    errorMessage: "Could not retrieve PremiumPay data",
                }),
            )
            return
        }

        /**
         * Transform Regence data
         */
        const data =
            res.data?.data?.member?.enrollment?.premium?.payments || {}
        const isMedicare = res.data?.data?.member?.enrollment?.isMedicare
        const premiumPayInfoItem = transformPremiumPayApiData(data, isMedicare)


        /**
         * Update state
         */
        dispatch(
            actions.receivePremiumPayInfo({
                ...premiumPayInfo,
                premiumPayInfoItem,
                isLoading: false,
                isInitialized: true,
            }),
        )
    },
)
