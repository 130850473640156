import { transformConditionsApiData } from "../conditions/utils"
import { transformTreatmentsApiData } from "../treatments/utils"
import { SearchLikeResult } from "./types"

export const transformSearchLikeData = (
    data: SearchLikeResult,
): {
    readonly searchLikeResults: SearchLikeResult
} => {
    return {
        searchLikeResults: {
            rxTreatments: data?.rxTreatments ? transformTreatmentsApiData(data.rxTreatments) : undefined,
            rxConditions: data?.rxConditions ? transformConditionsApiData(data.rxConditions) : undefined,
        },
    }
}