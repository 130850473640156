import { separateDate } from "@/utils/date"
import { differenceInYears } from "date-fns"
import { VspInfoItem } from "./types"

/**
 * Transform VSP Info data from user model
 * -----------------------------------------------------------------------------
 * All fields are copied as is except suffix
 * Suffix is derived using last 2 digits of vsp mid
 */
export const transformVspInfoData = (
    vspInfo: ReadonlyArray<any>, // eslint-disable-line
): ReadonlyArray<VspInfoItem> => {
    return vspInfo.map(vspInfoItem => {
        return {
            mid: vspInfoItem.mid,
            fimIdpNm: vspInfoItem.fimIdpNm,
            suffix: vspInfoItem.mid.substring(
                vspInfoItem.mid.length - 2,
                vspInfoItem.mid.length,
            ),
        }
    })
}

/**
 * Transform VSP Info data from DFIS
 * -----------------------------------------------------------------------------
 * All fields are copied as is except suffix
 * Suffix is derived using last 2 digits of vsp mid
 */
export const transformVspInfoFromDFISData = (
    vspInfo: ReadonlyArray<any>, // eslint-disable-line
): ReadonlyArray<VspInfoItem> => {
    return vspInfo.map(vspInfoItem => {
        return {
            mid: vspInfoItem.mid,
            fimIdpNm: vspInfoItem.fimIdpNm,
            suffix: vspInfoItem.mid.substring(
                vspInfoItem.mid.length - 2,
                vspInfoItem.mid.length,
            ),
            membershipId: vspInfoItem.membershipId,
            firstName: vspInfoItem.firstName,
            lastName: vspInfoItem.lastName,
            birthDate: vspInfoItem.dateOfBirth,
            relationship: vspInfoItem.relationship
        }
    })
}

/**
 * Get vsp info for a given member suffix
 * -----------------------------------------------------------------------------
 * Matches suffix in vspInfo with member suffix to get corresponding item with mid and fimIdpNm
 */
export const getVspInfoForMember = (
    vspInfo: ReadonlyArray<VspInfoItem>,
    memberSuffix: string,
): VspInfoItem => {
    return vspInfo.find(
        vspInfoItem =>
            "0".concat(memberSuffix) === vspInfoItem.suffix ||
            memberSuffix === vspInfoItem.suffix,
    )
}

/**
 * Calculates age for a date of birth given in 'yyyy-MM-dd' format
 */
export const calculateAge = (dateOfBirth: string): number => {
    // Extract year, month and day from date string
    const dateParts = separateDate(dateOfBirth, {
        day: "dd",
        month: "MM",
        year: "yyyy",
    })

    // Construct date object from extracted year, month and day
    const dateOfBirthObj = new Date(
        parseInt(dateParts.year),
        parseInt(dateParts.month) - 1,
        parseInt(dateParts.day),
    )

    return differenceInYears(new Date(), dateOfBirthObj)
}
