export interface EFTDetails {
    readonly accountHolderName: string
    readonly routingNumber: string
    readonly bankName: string
    readonly accountType: string
    readonly accountNumber: string
}

export interface ClaimsReimbursementOptionConfig {
    readonly isAllowedToChange: boolean
    readonly isAllowedToViewAddress: boolean
    readonly optionTypes: readonly string[]
    readonly availableOptions: readonly string[]
    readonly defaultSelectedOption: string
    readonly hasEFTDetails: boolean
}

export interface ClaimsReimbursementDetails {
    readonly eft: EFTDetails
    readonly options: ClaimsReimbursementOptionConfig
}
export interface OptionTypesParams{
    readonly suffix: string;
    readonly claimType: string;
}
export enum REIMBURSEMENT_OPTIONS {
    EFT = "EFT",
    MANUAL_CHECK = "MANUAL_CHECK",
}
