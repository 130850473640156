export const hsaQuery = `
query {
    member {
        relationship
        coverages { hsaProduct productId }
        preferences {
            hsaDisplayBalance: consumerSetting(setting_name:hsa_account_balance) {
                setting
            }
            heqShare
        }
        heqBalance
    }
}`

