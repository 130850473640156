/**
 * Account utilities
 * -----------------------------------------------------------------------------
 */
import { Profile, Plan, Groups, EmployerGroup } from "./types"
import { formatCase } from "utils/string"

/**
 * Transform Regence account data from ADIF user-model
 * -----------------------------------------------------------------------------
 */
export const transformRegenceAccount = (
    data: any, // eslint-disable-line
): {
    readonly profile: Profile
    readonly plan: Plan
} => {
    return {
        profile: {
            id: data.member.membershipId,  
            firstName: capitalize(data.member.firstName),
            lastName: capitalize(data.member.lastName),
            groupId: data.member.primaryGroupId,
            subscriberId: data.enrollment.subscriberId,
            email: data.accountInformation?.email,
            gender: data.member.gender,
            birthDate: data.member.dateOfBirth,
            relationship: data.member.relationship,
            audience: data.user.context.audience,
            region: data.user.context?.location?.region, // region requires safe operators
            employerGroupName: data.employerGroupInfo.employerGroupName,
            employerGroupCategory: data.employerGroupInfo.employerGroupCategory,
            cca: data.ccaInFamily,
            coverages: data.member.coverages,
            sellingAffiliate: data.member.coverages[0]?.sellingAffiliate,
            dentalSellingAffiliate: getDentalSellingAffiliate(data.member.coverages),
            brandName: data.user.context.brand,
            address: {
                street: data.enrollment.homeAddress?.address1,
                city: data.enrollment.homeAddress?.city,
                state: data.enrollment.homeAddress?.state,
                postalCode: data.enrollment.homeAddress?.zip,
            },
            billingAddress: {
                street: data.enrollment.billingAddress?.address1,
                city: data.enrollment.billingAddress?.city,
                state: data.enrollment.billingAddress?.state,
                postalCode: data.enrollment.billingAddress?.zip,
            },
            memeCk: data.member.memeCk,
        },
        plan: {
            memberId: data.enrollment.subscriberId,
            groupNumber: data.member.primaryGroupId,
            suffix: data.member.suffix,
            brandCode: data.member.coverages[0].brandFamily,
            usableDentalPlanName: getUsableDentalPlanName(data.member.coverages),
        },
    }
}

/**
 * String helpers
 */
export const capitalize = (value: string) => {
    if (!value) return ""
    return formatCase(value, "titlecase")
}

/**
 * Returns member's current email verification status.
 * @param emailData
 * @returns VERIFIED | PENDING | UNKNOWN
 */
export const getEmailVerificationStatus = emailData => {
    const { isActivated } = emailData || {}
    if (isActivated === true) {
        return "VERIFIED"
    } else if (isActivated === false) {
        return "PENDING"
    } else {
        return "UNKNOWN"
    }
}

/**
 * Returns member's current sms verification status if phone number is added to the account.
 * @param smsData Object
 * @returns VERIFIED | PENDING | NOT_FOUND | UNKNOWN
 */
export const getSmsVerificationStatus = smsData => {
    const { processing, activated, suspendedNumber, number, processingType } =
        smsData || {}
    if (!number) {
        return "NOT_FOUND"
    } else if (activated === true) {
        return "VERIFIED"
    } else {
        // Mobile number on profile is not verified.
        // Check if this is member's first phone number in portal.
        // This is to match same behavior of adif member portal
        const firstPhoneNumber = !suspendedNumber

        // Also check only if no transaction is processing or change request is being processed.
        const eligible = processing === false || processingType === "change"
        const verificationPending = firstPhoneNumber && eligible
        return verificationPending ? "PENDING" : "UNKNOWN"
    }
}

/**
 * Return filtered list of family members based on authorized family member IDs
 * Sorts the members with loggedInMember as first and others based on suffix value
 *
 * @param family
 * @param authorizedFamilyMemberIds
 * @returns
 */
export const getAuthorizedFamilyMembers = (
    family: any, // eslint-disable-line
    authorizedFamilyMemberIds: ReadonlyArray<string>,
    memberSuffix: string,
) => {
    // Prepare family members from subscriber and dependents
    const familyMembers = [family.subscriber, ...family.dependents].flatMap(
        dependent => (dependent ? [dependent] : []),
    )

    const loggedInMember = familyMembers.find(
        member => member.suffix === memberSuffix,
    )
    /**
     * Filter members other than logged-in member based on authorized member IDs
     * Sort based on suffix value
     */
    const otherAuthorizedMembers = familyMembers
        .filter(familyMember => {
            return (
                familyMember.suffix !== memberSuffix &&
                authorizedFamilyMemberIds.includes(familyMember.familyMemberId)
            )
        })
        .sort((a, b) => {
            return parseInt(a.suffix) - parseInt(b.suffix)
        })
    // Return authorizedFamilyMembers with loggedInMember first
    // and other members sorted by suffix value
    return [loggedInMember].concat(otherAuthorizedMembers)
}

/**
 * If the current user is the subscriber, returns all family members that don't have a CCA.
 * Return filtered list of family members based on authorized family member IDs
 * Sorts the members with loggedInMember as first and others based on suffix value
 *
 * @param family
 * @param authorizedFamilyMemberIds
 * @returns
 */
export const getAuthorizedFamilyMembersForClaims = (
    family: any, // eslint-disable-line
    authorizedFamilyMemberIds: ReadonlyArray<string>,
    familyMemberWithCCA: ReadonlyArray<string>,
    memberSuffix: string,
) => {
    // Prepare family members from subscriber and dependents
    const familyMembers = [family.subscriber, ...family.dependents].flatMap(
        dependent => (dependent ? [dependent] : []),
    )

    const loggedInMember = familyMembers.find(
        member => member.suffix === memberSuffix,
    )

    const isSubscriber =
        loggedInMember.familyMemberId === family.subscriber.familyMemberId
    if (isSubscriber) {
        /**
         * Return all family members (except for CCA) for subscriber
         * Sort based on suffix value
         */
        const allFamilyMembersExceptCCA = familyMembers
            .filter(familyMember => {
                return (
                    familyMember.suffix !== memberSuffix &&
                    !familyMemberWithCCA.includes(familyMember.familyMemberId)
                )
            })
            .sort((a, b) => {
                return parseInt(a.suffix) - parseInt(b.suffix)
            })
        // Return loggedInMember first
        // all other members without CCA sorted by suffix value
        return [loggedInMember].concat(allFamilyMembersExceptCCA)
    } else {
        /**
         * Filter members other than logged-in member based on authorized member IDs
         * Sort based on suffix value
         */
        const otherAuthorizedMembers = familyMembers
            .filter(familyMember => {
                return (
                    familyMember.suffix !== memberSuffix &&
                    authorizedFamilyMemberIds.includes(
                        familyMember.familyMemberId,
                    )
                )
            })
            .sort((a, b) => {
                return parseInt(a.suffix) - parseInt(b.suffix)
            })
        // Return authorizedFamilyMembers with loggedInMember first
        // and other members sorted by suffix value
        return [loggedInMember].concat(otherAuthorizedMembers)
    }
}

/**
 * Checks if CCA exist on file for member
 */
export const isCCAExistOnFile = (cca: ReadonlyArray<string>): boolean => {
    return cca?.length > 0
}

/**
 * Employer group data
 * -----------------------------------------------------------------------------
 */
export const employerGroups: Record<Groups, EmployerGroup> = {
    asn: {
        id: "asn",
        name: "Asante",
        src: "asante-logo",
    },
    uu: {
        id: "uu",
        name: "University of Utah Health Care",
        src: "university-utah-logo",
    },
    winco: {
        id: "winco",
        name: "WinCo Foods",
        src: "winco-logo",
    },
    ump: {
        id: "ump",
        name: "Uniform Medical Plan",
        src: "ump-logo",
        size: "lg",
    },
    cis: {
        id: "cis",
        name: "CIS Benefits",
        src: "cis-benefits-logo",
        size: "lg",
    },
}

export const employerGroupByNameOrCategory = (
    name: string,
): EmployerGroup | null => {
    if (!employerGroups[name]) return null
    return employerGroups[name]
}

/**
 * @param coverages Get PlanName to pass in the USAble url
 */

export const getUsableDentalPlanName = (coverages: ReadonlyArray<Record<string, unknown>>): string => {
   
    return coverages.find(coverage => coverage.category ==="D" && coverage.status ==="A")?. networkSelectorKey?.toString().slice(0,4)
   
}

/**
 * @param coverages Get the latest dental coverage
 */
export const getLatestDentalCoverage = (
    coverages: ReadonlyArray<Record<string, unknown>>
) => {
    const prioritizedCoverageStatus = ["A", "P", "T"]
    // eslint-disable-next-line functional/no-let
    for (let index = 0; index < prioritizedCoverageStatus.length; index++) {
        const foundDentalCoverage = coverages.find(coverage =>
            coverage.category === "D" &&
            coverage.status === prioritizedCoverageStatus[index]
        )

        if (foundDentalCoverage)
            return foundDentalCoverage
    }
    return null
}

/**
 * @param coverages Get sellingAffiliate from dental coverage
 */
export const getDentalSellingAffiliate = (
    coverages: ReadonlyArray<Record<string, unknown>>
): string => {
    const latestDentalCoverage = getLatestDentalCoverage(coverages)

    if (latestDentalCoverage) {
        return latestDentalCoverage.sellingAffiliate as string
    }

    return ""
}