import { transformConditionsApiData } from "../conditions/utils"
import { transformTreatmentsApiData } from "../treatments/utils"
import { SearchStartsWithResult } from "./types"

export const transformSearchData = (
    data: SearchStartsWithResult,
): {
    readonly searchStartsWithResults: SearchStartsWithResult
} => {
    return {
        searchStartsWithResults: {
            rxTreatments: data?.rxTreatments ? transformTreatmentsApiData(data.rxTreatments) : undefined,
            rxConditions: data?.rxConditions ? transformConditionsApiData(data.rxConditions) : undefined,
        },
    }
}

export const generateUID = () => {
    // I generate the UID from two parts here
    // to ensure the random number provide enough bits.
    const firstPart = (Math.random() * 46656) | 0
    const secondPart = (Math.random() * 46656) | 0
    return (
        ("" + firstPart.toString(36)).slice(-3) +
        ("" + secondPart.toString(36)).slice(-3)
    )
}
