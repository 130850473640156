/**
 * Application Store
 * -----------------------------------------------------------------------------
 */
import { configureStore } from "@reduxjs/toolkit"

/**
 * Import state slices
 * -----------------------------------------------------------------------------
 * 1. Import slice reducer and add to `configureStore` reducers
 * 2. Import slice types and add to `State` interface
 * 3. Import slice actions and spread with `actions` export
 */
import account, { Account, accountActions } from "./account/reducer"
import accumulators, {
    Accumulators,
    accumulatorsActions,
} from "./accumulators/reducer"
import app, { App, appActions } from "./app/reducer"
import claims, { Claims, claimsActions } from "./claims/reducer"
import conditions, {
    Conditions,
    conditionsActions,
} from "./rx/conditions/reducer"
import session, { Session, sessionActions } from "./session/reducer"
import lnDevice, { LnDevice, lnDeviceActions } from "./ln-device/reducer"
import benefits, { Benefits, benefitsActions } from "./benefits/reducer"
import timeline, { Timeline, timelineActions } from "./timeline/reducer"
import insights, { Insights, insightsActions } from "./insights/reducer"
import alerts, { Alerts, alertsActions } from "./alerts/reducer"
import medications, {
    Medications,
    medicationsActions,
} from "./rx/medications/reducer"
import campaigns, { campaignsActions } from "./rx/campaigns/reducer"
import { Campaigns } from "./rx/campaigns/reducer"
import vspInfo, { VspInfo, vspInfoActions } from "./vsp/reducer"
import searchStartsWithResults, {
    SearchStartsWithResults,
    searchStartsWithActions,
} from "./rx/search-starts-with/reducer"
import searchLikeResults, {
    SearchLikeResults,
    searchLikeActions,
} from "./rx/search-like/reducer"
import { SliceState } from "@/store/util/create-stateful-slice"
import premiumPayInfo, {
    PremiumPayInfo,
    premiumPayActions,
} from "./premium-pay/reducer"
import premiumPaymentInfo, {
    PremiumPaymentInfo,
    premiumPaymentActions,
} from "./premium-payment/reducer"
import treatments, {
    Treatments,
    treatmentsActions,
} from "./rx/treatments/reducer"
import providers, {
    Providers,
    ProvidersActions,
} from "./doctors-and-providers/reducer"
import pharmacistInquiry, {
    pharmacistInquiryActions,
    PharmacistInquirySlice,
    PharmacistInquiryTopic,
} from "@/store/rx/pharmacist-inquiries/reducer"
import hsa, { Hsa, hsaActions } from "./hsa/reducer"
import programs, { Programs, programsActions } from "./programs/reducer"
import benefitDocumentsInfo, {
    BenefitDocumentsInfo,
    benefitDocumentsActions,
} from "./benefits-documents/reducer"
import onlife, { Onlife, onlifeActions } from "./onlife/reducer"
import memberCards, {
    MemberCards,
    memberCardsActions,
} from "./member-cards/reducer"
import chat, { Chat, chatActions } from "./chat/reducer"
import myAccount, {
    MyAccount,
    myAccountSettingsActions
} from "./member-settings/reducer"
import careGapsBenefits, {
    CareGapsBenefits,
    careGapsBenefitsActions,
} from "./care-gaps-benefits/reducer"
import careGaps, { CareGaps, careGapsActions } from "./care-gaps/reducer"
import renewal, { Renewal, renewalActions } from "./renewal/reducer"
import cobrowse, { Cobrowse, cobrowseActions } from "./cobrowse/reducer"
import paperless, { Paperless, paperlessActions } from "./paperless/reducer"
import coverages, { Coverages, coveragesActions } from "./rx/coverages/reducer"
import duplicateClaimValidation, {
    DuplicateClaimValidation,
    duplicateClaimValidationServiceActions,
} from "./duplicate-claim-validation/reducer"
import dateValidation, {
    DateValidation,
    dateValidationServiceActions,
} from "./claim-service-date/reducer"
import claimServiceType, {
    ClaimService,
    claimServiceActions,
} from "./claim-service-type/reducer"
import claimHolderDetails, {
    ClaimHolders,
    claimHoldersActions,
} from "./claim-submission-eligible-member/reducer"
import taxFormsInformation, {
    TaxForms,
    taxFormsActions,
} from "./tax-information/reducer"
import eobStatement, { Eobs, eobsActions } from "./eob-statements/reducer"
import FileUploadParameters, {
    FileUploadParams,
    fileUploadParamActions,
} from "./claim-submission-upload-params/reducer"
import taxFormsFaq, { TaxFaqs, taxFaqActions } from "./tax-faq/reducer"
import claimSubmissionFaq, {
    ClaimSubmissions,
    claimSubmissionFaqActions,
} from "./claim-submission-faq/reducer"
import resources, { Resources, resourcesActions } from "./resources/reducer"
import pager, { Pager, pagerActions } from "./pager/reducer"
import claimReimbursementInfo, {
    ClaimReimbursementInfo,
    claimReimbursementDetailsActions,
} from "./claims-reimbursement-details/reducer"
import messageCenter, {
    MessageCenter,
    messageCenterActions,
} from "./message-center/reducer"
import pharmacies, {
    Pharmacies,
    pharmaciesActions,
} from "./rx/pharmacies/reducer"
import additionalMaterials, {
    AdditionalMaterials,
    additionalMaterialsActions,
} from "./usdental-additional-information/reducer"
import opportunities, { Opportunities, opportunitiesActions } from "./rx/opportunities/reducer"
import recentClaims, {
    RecentClaims,
    recentClaimsActions,
} from "./recent-claims/reducer"
import nativeClaims, {
    NativeClaims,
    nativeClaimsActions,
} from "./claims-inquiry/reducer"
import personalInformationDetails, { PersonalInformationInterface, personalInformationActions } from "./personal-information/reducer"


/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface State {
    readonly account: Account
    readonly accumulators: SliceState<Accumulators>
    readonly alerts: SliceState<Alerts>
    readonly app: App
    readonly claims: Claims
    readonly conditions: Conditions
    readonly session: Session
    readonly lnDevice: LnDevice
    readonly benefits: SliceState<Benefits>
    readonly timeline: Timeline
    readonly insights: SliceState<Insights>
    readonly medications: Medications
    readonly campaigns: Campaigns
    readonly vspInfo: VspInfo
    readonly premiumPayInfo: PremiumPayInfo
    readonly premiumPaymentInfo: PremiumPaymentInfo
    readonly treatments: Treatments
    readonly providers: Providers
    readonly searchStartsWithResults: SearchStartsWithResults
    readonly searchLikeResults: SearchLikeResults
    readonly pharmacistInquiry: PharmacistInquirySlice
    readonly hsa: Hsa
    readonly programs: SliceState<Programs>
    readonly benefitDocumentsInfo: SliceState<BenefitDocumentsInfo>
    readonly onlife: Onlife
    readonly memberCards: SliceState<MemberCards>
    readonly chat: SliceState<Chat>
    readonly myAccount: SliceState<MyAccount>
    readonly careGapsBenefits: SliceState<CareGapsBenefits>
    readonly careGaps: SliceState<CareGaps>
    readonly renewal: SliceState<Renewal>
    readonly cobrowse: SliceState<Cobrowse>
    readonly paperless: SliceState<Paperless>
    readonly coverages: Coverages
    readonly resources: SliceState<Resources>
    readonly pager: SliceState<Pager>
    readonly taxFormsInformation: SliceState<TaxForms>
    readonly eobStatement: SliceState<Eobs>
    readonly FileUploadParameters: SliceState<FileUploadParams>
    readonly taxFormsFaq: SliceState<TaxFaqs>
    readonly claimHolderDetails: SliceState<ClaimHolders>
    readonly dateValidation: SliceState<DateValidation>
    readonly duplicateClaimValidation: SliceState<DuplicateClaimValidation>
    readonly claimServiceType: SliceState<ClaimService>
    readonly messageCenter: SliceState<MessageCenter>
    readonly claimSubmissionFaq: SliceState<ClaimSubmissions>
    readonly claimReimbursementInfo: SliceState<ClaimReimbursementInfo>
    readonly pharmacies: Pharmacies
    readonly additionalMaterials: SliceState<AdditionalMaterials>
    readonly opportunities: Opportunities
    readonly recentClaims: SliceState<RecentClaims>
    readonly nativeClaims: SliceState<NativeClaims>
    readonly personalInformationDetails: SliceState<PersonalInformationInterface>
}

/**
 * Actions
 * -----------------------------------------------------------------------------
 */
export const actions = {
    ...accountActions,
    ...accumulatorsActions,
    ...appActions,
    ...alertsActions,
    ...claimsActions,
    ...conditionsActions,
    ...sessionActions,
    ...lnDeviceActions,
    ...benefitsActions,
    ...timelineActions,
    ...insightsActions,
    ...medicationsActions,
    ...campaignsActions,
    ...vspInfoActions,
    ...premiumPayActions,
    ...premiumPaymentActions,
    ...treatmentsActions,
    ...ProvidersActions,
    ...searchStartsWithActions,
    ...searchLikeActions,
    ...pharmacistInquiryActions,
    ...hsaActions,
    ...programsActions,
    ...benefitDocumentsActions,
    ...onlifeActions,
    ...memberCardsActions,
    ...chatActions,
    ...myAccountSettingsActions,
    ...careGapsBenefitsActions,
    ...careGapsActions,
    ...renewalActions,
    ...cobrowseActions,
    ...paperlessActions,
    ...coveragesActions,
    ...taxFormsActions,
    ...eobsActions,
    ...taxFaqActions,
    ...claimHoldersActions,
    ...dateValidationServiceActions,
    ...duplicateClaimValidationServiceActions,
    ...claimServiceActions,
    ...claimSubmissionFaqActions,
    ...resourcesActions,
    ...pagerActions,
    ...claimReimbursementDetailsActions,
    ...messageCenterActions,
    ...fileUploadParamActions,
    ...pharmaciesActions,
    ...additionalMaterialsActions,
    ...opportunitiesActions,
    ...recentClaimsActions,
    ...nativeClaimsActions,
    ...personalInformationActions,
}

/**
 * Configure store
 * -----------------------------------------------------------------------------
 */
export const store = configureStore({
    reducer: {
        account,
        accumulators,
        app,
        alerts,
        claims,
        conditions,
        session,
        lnDevice,
        benefits,
        timeline,
        insights,
        medications,
        campaigns,
        vspInfo,
        premiumPayInfo,
        premiumPaymentInfo,
        treatments,
        providers,
        searchStartsWithResults,
        searchLikeResults,
        pharmacistInquiry,
        hsa,
        programs,
        benefitDocumentsInfo,
        onlife,
        memberCards,
        chat,
        myAccount,
        careGapsBenefits,
        careGaps,
        renewal,
        cobrowse,
        paperless,
        coverages,
        resources,
        pager,
        taxFormsInformation,
        eobStatement,
        FileUploadParameters,
        taxFormsFaq,
        claimHolderDetails,
        dateValidation,
        duplicateClaimValidation,
        claimServiceType,
        messageCenter,
        claimSubmissionFaq,
        claimReimbursementInfo,
        pharmacies,
        additionalMaterials,
        opportunities,
        recentClaims,
        nativeClaims,
        personalInformationDetails,
    },
    devTools: true,
})
