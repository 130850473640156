/**
 * Benefits Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions } from "store"
import http from "utils/http"
import { transformBenefitsAPIData } from "./utils"
import { benefitsQuery } from "./queries"

/**
 * Get benefits data
 * -----------------------------------------------------------------------------
 */
export const fetchBenefits = createAsyncThunk(
    "benefits/fetchBenefits", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.benefitsStatus("pending"))

        /**
         * Request benefits data
         */
        const res = await http.query(
            "/api/janus/digital-first-information-service/graphql",
            benefitsQuery(),
        )

        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.benefitsError({
                    response: res,
                }),
            )
        }

        /**
         * Transform benefit data
         */
        const transformed = transformBenefitsAPIData(res.data?.data)
        const viewableMembers = res.data?.data?.benefits?.familyMembers

        /**
         * Update state
         */
        dispatch(
            actions.receiveBenefits({
                benefits: transformed.benefits || {},
                benefitsByMembershipId: transformed.benefitsByMembershipId || {},
                viewableMembers: viewableMembers  || [],
            }),
        )
    },
)
