/**
 * Smart Banner Meta Tag
 * -----------------------------------------------------------------------------
 * 
 * Adds a meta tag to the dashboard page that will render an iOS smart banner
 * that links to the app, or the app's page in the app store if it's not installed. 
 * The smart banner only appears on an iOS device in the Safari browser.
 */
import React from "react"
import Head from "next/head"
import { useSelector } from "store"
import { useRouter } from "next/router"

const BRAND_APP_STORE_ID_MAP = { regence: "1032114463", asuris: "1020815062" }

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const SmartBannerMetaTag: React.FC = () => {
    const router = useRouter()
    const brand = useSelector(state => state.app.brand)
    const appId = BRAND_APP_STORE_ID_MAP[brand]
    
    // only show smart banner on dashboard route if app exists for brand
    if (!appId || router.pathname !== "/") {
        return null
    }

    return (
        <Head>
            <meta name="apple-itunes-app" content={`app-id=${appId}, app-argument=${brand}://dashboard`} />
        </Head>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default SmartBannerMetaTag