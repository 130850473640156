/**
 * Personal Information reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { PersonalInformationType } from "./types"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface PersonalInformationInterface {
    /**
     * Init states
     */
    readonly personalInfoData: PersonalInformationType
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<PersonalInformationInterface>= {
    status: "pristine",
    personalInfoData: {
        sexualOrientation: undefined,
        genderIdentity: undefined
    },
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const personalInformationDetails = createStatefulSlice({
    name: "personalInformationDetails",
    initialState,
    reducers: {
        receivePersonalInfo: (
            state: SliceState<PersonalInformationInterface>,
            action: PayloadAction<PersonalInformationInterface>,
        ) => {
            return {
                status: "completed",
                personalInfoData: action.payload.personalInfoData || {
                    sexualOrientation: undefined,
                    genderIdentity: undefined
                }
            }
        },
        resetPersonalInfo: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const personalInformationActions = {
    ...personalInformationDetails.actions,
    ...actions,
}

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default personalInformationDetails.reducer
