/* eslint-disable no-console */
/**
 * MTax forms IRS FAQ Actions
 * -----------------------------------------------------------------------------
 */
import { createAsyncThunk } from "@reduxjs/toolkit"
import { actions } from "store"
import http from "utils/http"
import { transformTaxFaq } from "./utils"

/**
 * Get tax forms data
 * -----------------------------------------------------------------------------
 */

export const fetchTaxFormsFaq = createAsyncThunk(
    "taxFormsFaq/fetchTaxFormsFaq", // Reducer name
    async (params: void, { dispatch }) => {
        /**
         * Set loading state
         */
        dispatch(actions.taxFormsFaqStatus("pending"))

        /**
         * Request tax forms data
         */
        const fragmentId = "gJfK7byvTA6Ubp9rvGRthZ"
        const config = { params: { id: fragmentId } }
        const res = await http.get("/api/managed-content-blocks", config).then(({ data }) => {
            if (data && data.length > 0) {
                return data[0].data
            }
        })
        
        /**
         * Handle request errors
         */
        if (res.error || res.data?.errors) {
            return dispatch(
                actions.taxFormsFaqError({
                    response: res,
                }),
            )
        }

        const taxFaqs = transformTaxFaq(res?.sections)
        
        /**
         * Update state
         */
        dispatch(
            actions.receiveTaxFormsFaq({
                taxFormsFaq: taxFaqs || [],
            }),
        )
    },
)