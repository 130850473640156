import { ClaimHolders } from "./reducer"
export const transformClaimHolders = (
    data: any, // eslint-disable-line
): ClaimHolders => {
    return data.map(d => {
        return {
            firstName: d?.firstName,
            lastName: d?.lastName,
            relationship: d?.relationship,
            subscriberId: d?.subscriberId,
            suffix: d?.suffix,
            age: d?.age
        }
    })
}
