export const timelineQuery = `
query {
    careSubjects {
         familyMembers {
          membershipId
          firstName
          lastName
          birthDate
          currentAge
          relationship
          disclosureAllowed
          suffix
        }
        edges {
            node {
                careSubjectId
                membershipId
                name { given family }
                medicineCabinet {
                    ...edgeCollectionInfo
                    edges {
                        ...medicationEdge
                    }
                }
                practitioners {
                    ...edgeCollectionInfo
                    edges {
                        sortDate
                        nodeType
                        node {
                            ... on Practitioner {
                                id 
                                type 
                                careSubjectId 
                            }
                            ... on ClaimsPractitioner {
                                displayName 
                                taxName 
                                specialty 
                                qualification 
                                npi 
                                source
                            }
                        }
                    }
                }
                events {
                    ...edgeCollectionInfo
                    edges {
                        nodeType
                        sortDate
                        node {
                            ...on CareEvent {
                                id
                                type
                                careSubjectId
                                eventDate
                            }
                            ...on MedicationEvent {
                                medicationId
                                supply { unit value }
                                supplyText
                                medication {
                                    ...on Medication {
                                        id
                                        type
                                        displayName
                                        brandStatus
                                        dosage {
                                            description
                                            route
                                            routeCategory
                                        }
                                        lastFillDate
                                        pharmacyName
                                        pharmacyPhone
                                        pharmacyAddress { street city state zip }
                                        prescriberName
                                    }
                                    ...on ClaimsMedication {
                                        medicationNdc
                                        source
                                        pharmacyAddress { street city state zip }
                                        fillCost
                                        claimId
                                    }
                                    ...on CabinetMedication {
                                        medicationNdcs
                                        medicationTreatmentId
                                        source
                                        status
                                    }
                                }
                            }
                            ...on PractitionerEvent {
                                encounterType
                                hospitalCode
                                diagnosisDescriptions
                                procedureDescriptions
                                claimId
                                amountOwed
                                practitioner {
                                    ... on ClaimsPractitioner {
                                        id
                                        type
                                        displayName
                                        taxName
                                        qualification
                                        specialty
                                        source
                                        npi
                                    }
                                }
                            }
                        }
                    }
                }

            }
        }
    }
}

fragment edgeCollectionInfo on EdgeCollection {
    totalCount
    pageInfo { hasNextPage startCursor endCursor }
}

fragment medicationEdge on MedicationEdge {
    nodeType
    sortDate
    node {
        ... on Medication {
            id
            medicationId
            type
            careSubjectId
            brandStatus
            displayName
            supply { unit value }
            dosage { route description routeCategory }
            lastFillDate
            pharmacyName
            pharmacyPhone
            pharmacyAddress { street city state zip }
            prescriberName
        }
        ... on ClaimsMedication {
            medicationId
            medicationNdc
            source
            fillCost
            claimId
        }
        ... on CabinetMedication {
            medicationTreatmentId
            medicationNdcs
            source
            status
        }
    }
}
`
