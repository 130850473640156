/**
 * Action Menu
 * -----------------------------------------------------------------------------
 */
import React from "react"
import { Badge, Button, Icon, IconTypes, Shimmer } from "elements"
import { useFocusNext } from "utils/window"
import { LinkEvent } from "@/utils/analytics";

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface Item {
    // Base props
    readonly name?: string
    readonly label?: string
    readonly badge?: string
    readonly shimmerLabel?: string
    readonly node?: React.ReactNode

    // Interactive props
    readonly onClick?: VoidFunction
    readonly href?: string
    readonly target?: string

    // Visual props
    readonly icon?: IconTypes
    readonly isActive?: boolean
    readonly isDisabled?: boolean
    readonly hasDivider?: boolean

    // Analytics
    readonly analytics?: Partial<LinkEvent>
}
interface Props {
    readonly name: string
    readonly items: ReadonlyArray<Item>

    // Visual props
    readonly appearance?: "default" | "sidebar"
    readonly variant?: "default" | "sidebar" // Note: Start using `appearance`
    readonly className?: string
}

/**
 * Component
 * -----------------------------------------------------------------------------
 */
const ActionMenu: React.FC<Props> = props => {
    /**
     * Hooks
     */
    const focusNext = useFocusNext(`#${props.name} > li > button`)

    /**
     * Lifecycle
     */
    React.useEffect(() => {
        focusNext.addListener()
        return () => focusNext.removeListener()
    }, [])

    /**
     * Define template variables
     * Note: Eventually deprecate `variant` prop
     */
    const isSidebar =
        props.appearance === "sidebar" || props.variant === "sidebar"

    /**
     * Styles
     */
    const sidebar = "bg-gray-50 dark:bg-dark-300 rounded-sm w-[80px] py-xxs"
    const divider =
        "border-t border-light-100 dark:border-dark-50 mt-xxs pt-xxs"
    const className = `${isSidebar ? sidebar : ""} ${props.className || ""}`

    /**
     * Template
     */
    return (
        <div className={className}>
            <ul className="list-none p-none mb-none" id={props.name}>
                {props.items.map((item, index) => (
                    <li key={index} className={item.hasDivider ? divider : ""}>
                        {item.node && (
                            <div className="text-left px-sm py-xs">
                                {item.node}
                            </div>
                        )}

                        {!item.node && !isSidebar && (
                            <Button
                                name={item.name}
                                variant="none"
                                className={`block w-full py-[14px] px-sm text-primary dark:text-accent hover:bg-primary-50 dark:hover:bg-dark-100" ${
                                    item.isActive
                                        ? "bg-primary-50 dark:bg-dark-100"
                                        : ""
                                }`}
                                onClick={item.onClick}
                                href={item.href}
                                target={item.target}
                                disabled={item.isDisabled}
                                analytics={item.analytics}
                            >
                                <div className="flex justify-between space-x-xs">
                                    {item.label}
                                    {item.shimmerLabel && (
                                        <Shimmer
                                            placeholder={item.shimmerLabel}
                                        />
                                    )}
                                    {item.icon && (
                                        <div className="ml-xxs">
                                            <Icon type={item.icon} size={20} />
                                        </div>
                                    )}
                                    {item.badge !== undefined && (
                                        <Badge label={item.badge} />
                                    )}
                                </div>
                            </Button>
                        )}

                        {!item.node && isSidebar && (
                            <Button
                                name={item.name}
                                variant="none"
                                className="block w-full rounded-sm py-[12px] px-xxs text-primary dark:text-accent focus-inset"
                                onClick={item.onClick}
                                href={item.href}
                                target={item.target}
                                disabled={item.isDisabled}
                            >
                                {item.icon && <Icon type={item.icon} />}
                                <div className="text-sm leading-none mt-xxs">
                                    {item.label}
                                </div>
                            </Button>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    )
}

/**
 * Export component
 * -----------------------------------------------------------------------------
 */
export default ActionMenu
