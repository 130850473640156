/**
 * BenefitDocuments reducer
 * -----------------------------------------------------------------------------
 */
import { PayloadAction } from "@reduxjs/toolkit"
import * as actions from "./actions"
import { BenefitDocuments } from "./types"
import {
    createStatefulSlice,
    SliceState,
} from "@/store/util/create-stateful-slice"

/**
 * Types
 * -----------------------------------------------------------------------------
 */
export interface BenefitDocumentsInfo {
    /**
     * Init states
     */
    readonly benefitDocuments?: ReadonlyArray<BenefitDocuments>
    readonly productId?: string
}

/**
 * Initial state
 * -----------------------------------------------------------------------------
 */
export const initialState: SliceState<BenefitDocumentsInfo> = {
    status: "pristine",
    benefitDocuments: [],
    productId: undefined,
}

/**
 * Reducer slice
 * -----------------------------------------------------------------------------
 */
const benefitDocumentsInfo = createStatefulSlice({
    name: "benefitDocumentsInfo",
    initialState,
    reducers: {
        benefitDocumentsInfo: (
            state: SliceState<BenefitDocumentsInfo>,
            action: PayloadAction<BenefitDocumentsInfo>,
        ) => {
            return {
                status: "completed",
                benefitDocuments: action.payload.benefitDocuments || [],
                productId: action.payload.productId || undefined,
            }
        },
        resetBenefitDocumentsInfo: () => {
            return initialState
        },
    },
})

/**
 * Export reducer actions
 * -----------------------------------------------------------------------------
 */
export const benefitDocumentsActions = {
    ...benefitDocumentsInfo.actions,
    ...actions,
}

/**
 * Export reducer
 * -----------------------------------------------------------------------------
 */
export default benefitDocumentsInfo.reducer
